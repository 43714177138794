import React from 'react'
import { motion, AnimatePresence } from 'motion/react'
import {
  Avatar,
  Badge,
  Button,
  Flex,
  Text,
  Textarea,
  Tooltip,
  AlertDialog,
  SimpleSpinner,
} from '@buffer-mono/popcorn'

import type { Channel } from '~publish/gql/graphql'
import { useEscapeKey } from '~publish/hooks/useEscapeKey'

import { SuggestionButtons } from '../CommentsList/SuggestionButtons'
import { useReplyFormState } from './useReplyFormState'
import styles from './CommentReplyForm.module.css'

type DiscardReplyDialogProps = {
  open: boolean
  onOpenChange: (open: boolean) => void
  onDiscard: () => void
  onKeepWriting: () => void
}

const DiscardReplyDialog = ({
  open,
  onOpenChange,
  onDiscard,
  onKeepWriting,
}: DiscardReplyDialogProps): JSX.Element => {
  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialog.Content>
        <AlertDialog.Title>Are you sure?</AlertDialog.Title>
        <AlertDialog.Description>
          Your unsent reply will be lost permanently.
        </AlertDialog.Description>
        <AlertDialog.Actions>
          <AlertDialog.Cancel onClick={onKeepWriting}>
            <Button variant="tertiary">Keep writing</Button>
          </AlertDialog.Cancel>
          <AlertDialog.Action onClick={onDiscard}>
            <Button variant="critical">Discard reply</Button>
          </AlertDialog.Action>
        </AlertDialog.Actions>
      </AlertDialog.Content>
    </AlertDialog>
  )
}

const MAX_CHARS = 500

const ReplyFormFooter = ({
  currentCharsCount,
  disabled,
}: {
  currentCharsCount: number
  disabled: boolean
}): JSX.Element => {
  return (
    <Flex gap="sm" className={styles.replyFooter}>
      <Text size="sm" color="subtle">
        {currentCharsCount}/{MAX_CHARS}
      </Text>
      <Tooltip content="Submit reply" shortcut={'mod+Enter'} delay={800}>
        <Button
          variant="primary"
          size="medium"
          type="submit"
          disabled={disabled}
          className={styles.submitButton}
        >
          Reply
        </Button>
      </Tooltip>
    </Flex>
  )
}

const SubmittingText = ({ replyText }: { replyText: string }): JSX.Element => {
  return (
    <Flex direction="column" gap="sm" className={styles.submittingState}>
      <Flex align="center" gap="xs">
        <SimpleSpinner size="small" />
        <Text>Sending...</Text>
      </Flex>
      <Text color="subtle" className={styles.submittingText}>
        {replyText}
      </Text>
    </Flex>
  )
}

type CommentReplyFormProps = {
  id?: string
  onSubmit: (text: string) => void
  textareaRef: React.RefObject<HTMLTextAreaElement>
  channel: Channel
  isSubmitting?: boolean
  initialExpanded?: boolean
  commentText: string
}

export const CommentReplyForm = ({
  id,
  onSubmit,
  textareaRef,
  channel,
  isSubmitting = false,
  initialExpanded = false,
  commentText,
}: CommentReplyFormProps): JSX.Element => {
  const {
    replyText,
    isExpanded,
    discardDialogVisible,
    formRef,
    handleDiscard,
    handleKeepWriting,
    handleSuggestionClick,
    handleExpand,
    handleFocus,
    showDiscardDialog,
    setReplyText,
  } = useReplyFormState({
    onSubmit,
    textareaRef,
    initialExpanded,
  })

  useEscapeKey(handleDiscard, { isEnabled: isExpanded })

  const showReplyFooter = isExpanded && !isSubmitting

  return (
    <form
      ref={formRef}
      onSubmit={(e): void => {
        e.preventDefault()
        onSubmit(replyText)
      }}
      className={styles.replyTextArea}
    >
      <Flex align="center" gap="xs">
        <div className={styles.avatarWrapper}>
          <Avatar src={channel.avatar} alt={channel.name} size="small" />
        </div>
        <Flex direction="column" gap="sm" className={styles.replyContent}>
          <Badge color="subtle">{channel.name}</Badge>
          <AnimatePresence mode="wait">
            {isSubmitting ? (
              <motion.div
                key="submitting"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                style={{ width: '100%' }}
              >
                <SubmittingText replyText={replyText} />
              </motion.div>
            ) : (
              <motion.div
                key="reply-form"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 'var(--space-xs)',
                  width: '100%',
                }}
              >
                <SuggestionButtons
                  onSuggestionClick={handleSuggestionClick}
                  commentText={commentText}
                />
                <motion.div
                  animate={{
                    height: isExpanded ? '115px' : '33px',
                  }}
                  transition={{
                    duration: 0.2,
                    ease: 'easeInOut',
                  }}
                >
                  <Textarea
                    id={id}
                    ref={textareaRef}
                    name="replyText"
                    placeholder="Reply..."
                    rows={1}
                    maxLength={MAX_CHARS}
                    value={replyText}
                    onChange={(e): void => setReplyText(e.target.value)}
                    required={true}
                    disabled={isSubmitting}
                    onClick={handleExpand}
                    onFocus={handleFocus}
                    className={styles.textarea}
                    resize="none"
                    aria-required
                  />
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>

          {showReplyFooter && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{
                height: isSubmitting ? 0 : 'auto',
                opacity: isSubmitting ? 0 : 1,
              }}
              transition={{
                duration: 0.2,
                ease: 'easeInOut',
              }}
              style={{ overflow: 'hidden', width: '100%' }}
              layout
            >
              <ReplyFormFooter
                currentCharsCount={replyText.length}
                disabled={isSubmitting}
              />
            </motion.div>
          )}
        </Flex>
      </Flex>
      <DiscardReplyDialog
        open={discardDialogVisible}
        onOpenChange={showDiscardDialog}
        onDiscard={handleDiscard}
        onKeepWriting={handleKeepWriting}
      />
    </form>
  )
}
