import type Uppy from '@uppy/core'
import type { UploadResult, UppyFile as BaseUppyFile } from '@uppy/core'
import negate from 'lodash/negate'
import { nanoid } from 'nanoid/non-secure'
import { serializeError } from 'serialize-error'

import type { BufferUpload, S3Upload, Upload } from './entities/Upload'
import logger, { logError } from './logger'
import { fileFromUrl } from './remoteFile'
import type { RemoteFile } from './remoteFile'
import { assertUppyMetadata } from './uppy/assertUppyMetadata'
import { UppyFile } from './uppy/UppyFile'
import type { UppyFileDescriptor } from './uppy/UppyFile'
import { validateFilesForUploads } from './validation/validateFilesForUpload'
import type { FileRejection } from './validation/validateFilesForUpload'
import { hasMixedMediaTypes } from './validation/validationUtils/hasMixedMediaTypes'
import { LocalFile } from './values/LocalFile'
import type { UploaderRestrictions } from './values/UploaderRestrictions'
import { UploadMetadata } from './values/UploadMetadata'
import { UploadProgress } from './values/UploadProgress'
import type { UploadSource } from './values/UploadSource'
import { WebEventEmitter } from './WebEventEmitter'

export type UploadFinishedCallback = (upload: BufferUpload) => void
type EmmittedError = Error & {
  __emmitted?: boolean
  cause?: { message?: string }
}

export type UploaderEvents = {
  'file-fetch-failed': (file: RemoteFile, error: Error) => void
  'file-upload-attempted': (files: LocalFile[]) => void
  'file-validation-failed': (file: LocalFile, error: Error) => void
  'upload-prevented': (error: Error, files?: LocalFile[]) => void
  'upload-started': (upload: Upload) => void
  'upload-progress': (
    event: Pick<Upload, 'id'>,
    progress: UploadProgress,
  ) => void
  'upload-to-s3-finished': (upload: S3Upload) => void
  'upload-finished': UploadFinishedCallback
  'upload-removed': (uploadId: string) => void
  'upload-failed': (event: Upload, error: Error) => void
  'uploader-closed': (uploaderId: string) => void
}

export function isChildUpload(upload: BaseUppyFile): boolean {
  return !!upload.meta.parentUploadId
}

export class Uploader extends WebEventEmitter<UploaderEvents> {
  private metadataMap: WeakMap<File, UploadMetadata> = new WeakMap()
  private readonly getDefaultCount: () => number = () =>
    this.uppyInstance.getFiles().filter(negate(isChildUpload)).length

  constructor(
    private readonly id: string,
    private readonly organizationId: string,
    public readonly uppyInstance: Uppy,
    private readonly fileRestrictions: UploaderRestrictions,
    private readonly getCustomCount?: () => number,
  ) {
    super()

    this.mapUppyEventsToUploaderEvents()
  }

  getId(): string {
    return this.id
  }

  getOrganizationId(): string {
    return this.organizationId
  }

  /*
   * Add file and start upload
   */
  async addFiles(
    files: File[],
    meta?: Pick<UploadMetadata, 'source'>,
  ): Promise<void> {
    logger('Adding %i files', files.length)
    this.generateMetadata(files, meta)

    this.emitFileUploadAttempted(files)

    const fileRestrictions = this.fileRestrictions

    if (fileRestrictions.mixedMediaError && hasMixedMediaTypes(files)) {
      const error = new Error(fileRestrictions.mixedMediaError)
      this.emitUploadPrevented(error, files)

      return
    }

    const { validFiles, invalidFiles } = await validateFilesForUploads(files, {
      fileRestrictions,
      currentFileCount: this.getUploadsCount(),
    })

    this.emitFileValidationFailed(invalidFiles)

    const descriptors = validFiles.map((file) => this.getUppyDescriptor(file))

    this.uppyInstance.addFiles(descriptors)
  }

  /*
   * Add files and start upload with a promise returned
   */
  async upload(
    files: File[],
    meta?: Pick<UploadMetadata, 'source'>,
  ): Promise<UploadResult> {
    await this.addFiles(files, meta)

    return this.uppyInstance.upload()
  }

  /**
   * addFilesFromUrls
   *
   * When urls are provided instead files, we can use this method to get
   * the content of the files and add files to the uploader finally.
   */
  async addFilesFromUrls(
    remoteFiles: RemoteFile | RemoteFile[],
    source: UploadSource,
  ): Promise<UploadResult> {
    if (!Array.isArray(remoteFiles)) {
      remoteFiles = [remoteFiles]
    }

    const downloadedFiles = await Promise.allSettled(
      remoteFiles.map(async (remoteFile) => {
        logger('Fetching file from url: %s', remoteFile.remoteUrl)

        try {
          return await fileFromUrl(remoteFile)
        } catch (error) {
          if (error instanceof Error) {
            this.emit('file-fetch-failed', remoteFile, error)
          }
        }
      }),
    )

    return this.upload(
      downloadedFiles
        .filter(
          (result): result is PromiseFulfilledResult<File> =>
            result.status === 'fulfilled',
        )
        .map((result) => result.value),
      {
        source,
      },
    )
  }

  close(cleanup?: () => void): void {
    this.uppyInstance.cancelAll()
    this.metadataMap = new WeakMap()

    this.uppyInstance.close()
    this.emit('uploader-closed', this.id)
    this.offAll()
    cleanup?.()
  }

  removeUpload(upload: Upload): void {
    this.uppyInstance.removeFile(upload.id)
  }

  reportError<T extends object>(error: Error, file: T): void {
    const shouldDebug = 'cause' in error || 'isNetworkError' in error
    logError(error, {
      context: `uploader.${this.getId()}`,
      metaData: {
        file,
        debugInfo: shouldDebug && serializeError(error),
      },
    })
  }

  private getUploadsCount(): number {
    return this.getCustomCount?.() ?? this.getDefaultCount()
  }

  private emitFileValidationFailed(invalidFiles: FileRejection[]): void {
    invalidFiles.forEach(({ file, message }) => {
      this.emit(
        'file-validation-failed',
        this.getLocalFile(file),
        new Error(message),
      )
    })
  }

  private emitFileUploadAttempted(files: File[]): void {
    this.emit(
      'file-upload-attempted',
      files.map((file) => this.getLocalFile(file)),
    )
  }

  private emitUploadPrevented(error: Error, files: File[]): void {
    this.emit(
      'upload-prevented',
      error,
      files.map((file) => this.getLocalFile(file)),
    )
  }

  private generateMetadata(
    files: File[],
    meta: Pick<UploadMetadata, 'source'> = {},
  ): void {
    files.forEach((file) => {
      if (this.metadataMap.has(file)) {
        // we should only generate metadata once
        logger('Warning: Metadata already exists for file: %s', file.name)
        return
      }

      logger('Generating metadata for file: %s', file.name)
      this.metadataMap.set(
        file,
        UploadMetadata.new({
          ...meta,
          organizationId: this.organizationId,
          trackingId: nanoid(),
          uploaderId: this.id,
        }),
      )
    })
  }

  private getMetadata(file: File): UploadMetadata {
    const metadata = this.metadataMap.get(file)

    if (!metadata) throw new Error('Metadata not found for file')

    // Spreading into a new object because Uppy mutates the metadata in some cases
    return { ...metadata }
  }

  /**
   * Uppy sometimes emits the same error multiple times. This method ensures
   */
  private emitUploadFailed(upload: Upload, error: EmmittedError): void {
    if (!error.__emmitted) {
      error.message = `Upload failed for ${upload.name}. ${
        error.cause?.message || error.message
      }`
      this.emit('upload-failed', upload, error)
      error.__emmitted = true
    }
  }

  /**
   * Translates uppy events into uploader events.
   *
   * This hides implementation details (i.e. uppy dependency) and allows for
   * emitting directly from the uploader instance
   */
  private mapUppyEventsToUploaderEvents(): void {
    this.uppyInstance.on('restriction-failed', (file, error) => {
      logger('Restriction failed for %s: %s', file?.name, error.message)

      if (!file) {
        this.emitUploadPrevented(error, [])

        return
      }

      const fileData: File = file.data as File

      const localFile = this.getLocalFile(fileData)

      this.emit('file-validation-failed', localFile, error)
    })

    this.uppyInstance.on('upload-error', (file, error) => {
      if (!isChildUpload(file)) {
        assertUppyMetadata(file)

        const upload = UppyFile.toUpload(file)
        // eslint-disable-next-line
        this.emitUploadFailed(upload, error as EmmittedError)
      }
    })

    this.uppyInstance.on('file-removed', (file) => {
      if (!isChildUpload(file)) {
        this.emit('upload-removed', file.id)
      }
    })

    this.uppyInstance.on('file-added', (file) => {
      if (!isChildUpload(file)) {
        assertUppyMetadata(file)

        const upload = UppyFile.toUpload(file)
        this.emit('upload-started', upload)
      }
    })

    this.uppyInstance.on('upload-progress', (file, uppyProgress) => {
      if (!isChildUpload(file)) {
        const progress = UploadProgress.new(
          uppyProgress.bytesTotal,
          uppyProgress.bytesUploaded,
        )
        this.emit('upload-progress', { id: file.id }, progress)
      }
    })
  }

  private getLocalFile(file: File): LocalFile {
    if (!this.metadataMap.has(file)) {
      this.generateMetadata([file])
    }

    return LocalFile.new(file, this.getMetadata(file))
  }

  private getUppyDescriptor(file: File): UppyFileDescriptor {
    const { meta, ...fileDetails } = this.getLocalFile(file)

    return {
      ...fileDetails,
      meta: {
        ...meta,
        // we reuse the trackingId which is unique as a relativePath
        // so that Uppy does not complain about duplicate files
        relativePath: meta.trackingId,
      },
    }
  }
}
