import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  pointerWithin,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import type { DragEndEvent, DragStartEvent } from '@dnd-kit/core'
import clsx from 'clsx'
import React, { useCallback, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'

import { useDebounce } from '@buffer-mono/popcorn'

import { DROP_ANIMATION } from '~publish/helpers/dndkit/constants'
import { restrictToRef } from '~publish/helpers/dndkit/restrictToRefModifier'
import { useHasElementScroll } from '~publish/hooks/useHasElementScrolled'

import { calendarKeyboardCoordinateGetter } from './calendarKeyboardCoordinateGetter'
import { CalendarContext } from './context'
import type { CalendarContextType } from './context'
import { DraggableItem } from './DraggableItem'
import { Header } from './Header'
import { calculateCalendarData, getDndKitAnnouncements } from './helpers'
import { useAnimationDirection } from './hooks/useAnimationDirection'
import { useCalendar } from './hooks/useCalendar'
import { Month } from './Month'
import type { AddItemProps, CalendarItem, RenderItemFunction } from './types'
import { Week } from './Week'

import styles from './Calendar.module.css'

type CalendarProps = {
  /** Whether to use 24-hour format */
  is24HourFormat: boolean
  /** Whether the calendar is read-only */
  readOnly: boolean
  /** Array of calendar items */
  items: CalendarItem[] | undefined
  /** Timezone string */
  timezone: string
  /** Day of the week to start on (0 for Sunday, 1 for Monday) */
  weekStartsOn: 0 | 1
  /** Function to render individual calendar items */
  renderItem: RenderItemFunction
  /** Props to be added on the new item button */
  addItemProps: AddItemProps
  /** Callback function for when drag starts */
  onDragStart: (event: DragStartEvent) => void
  /** Callback function for when drag ends */
  onDragEnd: (result: DragEndEvent) => void
  /** Whether the calendar is loading */
  loading: boolean
}

/**
 * Calendar component that displays events in a week or month view.
 *
 * @param {CalendarProps} props - The props for the Calendar component
 * @returns {JSX.Element} The rendered Calendar component
 */
export const Calendar = React.memo(
  ({
    is24HourFormat,
    readOnly,
    items,
    loading,
    timezone,
    weekStartsOn,
    renderItem,
    addItemProps: addItem,
    onDragStart,
    onDragEnd,
  }: CalendarProps): JSX.Element => {
    const { startDate, selectedDate, viewMode } = useCalendar({
      weekStartsOn,
      timezone,
    })
    const { showRightAnimation, showLeftAnimation, handleAnimationEnd } =
      useAnimationDirection(selectedDate)
    const { scrollingElementRef, hasScroll: hasScrolled } =
      useHasElementScroll<HTMLDivElement>()
    const [activeDraggedId, setActiveDraggedId] = useState<string | undefined>(
      undefined,
    )

    // Calculate the calendar data after 100ms of change in selected date, so
    // moving fast between weeks/months do not trigger unnecessary calculations
    const debouncedSelectedDate = useDebounce(selectedDate, 100)
    const { hours, days } = calculateCalendarData(
      debouncedSelectedDate,
      viewMode,
      weekStartsOn,
    )
    const contextValue: CalendarContextType = useMemo(
      () => ({
        renderItem,
        items,
        addItemProps: addItem,
        readOnly,
        selectedDate,
        is24HourFormat,
        timezone,
        loading,
      }),
      [
        renderItem,
        items,
        addItem,
        readOnly,
        selectedDate,
        is24HourFormat,
        timezone,
        loading,
      ],
    )
    const sensors = useSensors(
      useSensor(KeyboardSensor, {
        coordinateGetter: calendarKeyboardCoordinateGetter,
      }),
      useSensor(PointerSensor, { activationConstraint: { distance: 4 } }),
    )

    const handleDragStart = useCallback(
      (event: DragStartEvent): void => {
        const { active } = event
        if (!active.id) return
        setActiveDraggedId(active.id.toString())
        onDragStart?.(event)
      },
      [onDragStart],
    )

    const item = items?.find((item) => item.id === activeDraggedId)

    return (
      <CalendarContext.Provider value={contextValue}>
        <div ref={scrollingElementRef} className={styles.calendar}>
          <DndContext
            autoScroll={false}
            sensors={sensors}
            onDragStart={handleDragStart}
            modifiers={[restrictToRef(scrollingElementRef)]}
            onDragEnd={onDragEnd}
            collisionDetection={pointerWithin}
            accessibility={{
              announcements: getDndKitAnnouncements({
                timezone,
                is24HourFormat,
                viewMode,
                items,
              }),
              screenReaderInstructions: {
                draggable: `
                To pick up a item, press space or enter.
                Use the arrow keys to move the item in the calendar.
                Press space or enter again to drop the item in its new position, or press escape to cancel.
              `,
              },
            }}
          >
            <table
              className={clsx(styles.calendarTable, {
                [styles.animateRight]: showRightAnimation,
                [styles.animateLeft]: showLeftAnimation,
              })}
              onAnimationEnd={handleAnimationEnd}
            >
              <Header
                startDate={startDate}
                withDay={viewMode === 'week'}
                className={clsx({
                  [styles.headerShadow]: hasScrolled,
                })}
              />

              <tbody>
                {viewMode === 'week' ? (
                  <Week hours={hours} />
                ) : (
                  <Month weeks={days} />
                )}
              </tbody>
            </table>
            {item &&
              createPortal(
                <DragOverlay
                  dropAnimation={DROP_ANIMATION}
                  className={styles.dragOverlay}
                >
                  <DraggableItem
                    key={item.id}
                    id={item.id}
                    index={0}
                    onOverlay={true}
                    timestamp={item.timestamp}
                    type={item.type}
                  >
                    {renderItem(item)}
                  </DraggableItem>
                </DragOverlay>,
                document.body,
              )}
          </DndContext>
        </div>
      </CalendarContext.Provider>
    )
  },
)

Calendar.displayName = 'Calendar'
