import mime from 'mime/lite'
import { nanoid } from 'nanoid/non-secure'

import { getFileMimeType } from './getFileMimeType'

export function getFileName(file: File): string {
  if (file.name) return file.name

  const mimeType = getFileMimeType(file)

  return mimeType ? `file-${nanoid}.${mime.getExtension(mimeType)}` : 'file'
}
