import React from 'react'
import {
  Button,
  Card,
  ChannelAvatar,
  Dialog,
  EmptyState,
  Flex,
  Heading,
  LinkBrokenIcon,
  Text,
  type ChannelType as PopcornChannelType,
} from '@buffer-mono/popcorn'

import { useTranslation } from 'react-i18next'
import type { Service } from '~publish/gql/graphql'
import { ChannelDisplayType, type ChannelType, type Profile } from '../../types'
import { useRefreshChannelConnection } from '~publish/hooks/useRefreshChannelConnection'

import styles from './ProfilesDisconnectedModal.module.css'

export function ProfilesDisconnectedModal({
  disconnectedProfiles = [],
  hideModal,
  displayExtraMessage,
}: {
  disconnectedProfiles: (Profile & {
    // set via Redux, not available on Profile
    reconnecting?: boolean
    // according to code it should be string but it is not defined in types
    isAdmin?: string
  })[]
  hideModal: () => void
  displayExtraMessage?: boolean
}): JSX.Element {
  const { t } = useTranslation()
  const refreshChannelConnection = useRefreshChannelConnection()

  const onReconnectClick = (profile: Profile): void => {
    refreshChannelConnection({
      channel: {
        id: profile.id,
        service: profile.service as Service,
        name: profile.formatted_username,
        apiVersion: profile.api_version || undefined,
      },
      cta: `publish-modal-channelRefresh-${profile.service.toLowerCase()}-1`,
    })
  }

  if (!disconnectedProfiles || disconnectedProfiles.length === 0) {
    return <></>
  }

  return (
    <Dialog open onOpenChange={hideModal}>
      <Dialog.Content className={styles.content}>
        <Dialog.Body className={styles.body}>
          <Dialog.CloseButton />

          <EmptyState
            size="large"
            variant="critical"
            className={styles.emptyState}
          >
            <EmptyState.Icon>
              <LinkBrokenIcon />
            </EmptyState.Icon>
            <Heading size="medium">
              {t('profiles-disconnected-modal.headline', {
                count: disconnectedProfiles.length,
              })}
            </Heading>
          </EmptyState>
          <Flex
            direction="column"
            align="center"
            gap="sm"
            className={styles.copyWrapper}
          >
            <Text color="subtle" align="center">
              {t('profiles-disconnected-modal.body1', {
                count: disconnectedProfiles.length,
              })}
            </Text>
            {displayExtraMessage && (
              <Text color="subtle" align="center">
                {t('profiles-disconnected-modal.extraMessage.instagram')}
              </Text>
            )}
          </Flex>

          <Flex
            gap="sm"
            direction="column"
            fullWidth
            className={styles.profileCardsWrapper}
          >
            {disconnectedProfiles.map((p) => (
              <Card key={p.id} className={styles.profileCard}>
                <Flex fullWidth justify="between" align="center">
                  <Flex gap="lg" align="center">
                    <ChannelAvatar
                      size="small"
                      src={
                        p.avatar_https ||
                        'https://s3.amazonaws.com/buffer-ui/Default+Avatar.png'
                      }
                      alt={p.handle}
                      channel={p.service as PopcornChannelType}
                    />

                    <Flex direction="column" gap="3xs">
                      <Text>
                        <strong>{p.formatted_username}</strong>
                      </Text>
                      <Text color="subtle">
                        {ChannelDisplayType[p.service_type as ChannelType]}
                      </Text>
                    </Flex>
                  </Flex>
                  {p.isAdmin && p.isAdmin === 'false' ? (
                    <Text>Contact an org admin</Text>
                  ) : (
                    <Button
                      disabled={p.reconnecting}
                      onClick={(): void => onReconnectClick(p)}
                      size="large"
                    >
                      {p.reconnecting
                        ? t('profiles-disconnected-modal.reconnecting')
                        : t('profiles-disconnected-modal.cta')}
                    </Button>
                  )}
                </Flex>
              </Card>
            ))}
          </Flex>
        </Dialog.Body>
      </Dialog.Content>
    </Dialog>
  )
}
