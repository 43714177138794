import { graphql } from '~publish/gql'

export const CalendarPostCard_Post = graphql(/* GraphQL */ `
  fragment CalendarPostCard_Post on Post {
    id
    status
    tags {
      id
      color
      name
    }
    metadata {
      ... on CommonPostMetadata {
        type
      }
      ... on GoogleBusinessPostMetadata {
        title
      }
    }
    assets {
      __typename
      ...PostMediaAsset_Asset
    }
    dueAt
    schedulingType
    isCustomScheduled
    isPinned
    notificationStatus
    text
    channelId
    channelService
    channel {
      id
      serviceId
      type
      name
    }
  }
`)
