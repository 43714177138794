import React, { useMemo } from 'react'
import {
  ChannelIcon,
  type ChannelType,
  ChevronDownIcon,
  EmptyState,
  SearchIcon,
  Sidebar,
  Skeleton,
  SkeletonText,
} from '@buffer-mono/popcorn'

import type { Service } from '~publish/gql/graphql'
import { useIsAdmin } from '~publish/legacy/accountContext'
import { getChannelColor, getChannelName } from '~publish/helpers/channels'
import { useConnectChannel } from '~publish/hooks/useConnectChannel'
import { useCta } from '~publish/hooks/useCta'

import type { CommentsChannelNav } from '../useCommentsChannels'
import { filterChannels, getChannelSuggestions } from './helpers'
import { NewChannelButton } from './NewChannelButton'
import { ChannelNavItem } from './ChannelNavItem'
import { Service as ServiceEnum } from '~publish/legacy/constants/services/ServiceDefinitions'

import styles from './ChannelNav.module.css'
import { useCommentsConfig } from '~publish/pages/Comments/CommentsChannelConfigContext'
import { useNewCommentsCounts } from '~publish/pages/Comments/hooks/useNewCommentCounts'

interface ChannelNavProps {
  channels: CommentsChannelNav[]
  searching: boolean
  searchQuery: string
}

export const ChannelNavSkeleton = (): JSX.Element => {
  return (
    <Sidebar.List>
      {Array.from({ length: 5 }).map((_, index) => (
        <Sidebar.ListItem key={index}>
          <Sidebar.ButtonSkeleton
            prefix={<Skeleton width={32} height={32} rounded />}
            size="large"
          >
            <SkeletonText lines={1} width={86} />
          </Sidebar.ButtonSkeleton>
        </Sidebar.ListItem>
      ))}
    </Sidebar.List>
  )
}

export function ChannelNav({
  channels,
  searching,
  searchQuery,
}: ChannelNavProps): JSX.Element {
  const isAdmin = useIsAdmin()
  const { config } = useCommentsConfig()

  let channelConnectionSuggestions = getChannelSuggestions(channels)
  const { counts } = useNewCommentsCounts({
    channels,
  })

  const displayedChannels = useMemo(() => {
    if (!searchQuery) {
      return []
    }
    return filterChannels(channels, searchQuery)
  }, [channels, searchQuery])

  const shouldShowCommentsChannelSuggestions =
    !config.hasSupportedCommentChannel(channels)

  if (shouldShowCommentsChannelSuggestions) {
    // @TODO add suggestion for all the Comments supported channels
    channelConnectionSuggestions =
      channelConnectionSuggestions?.filter(
        (service) => service !== ServiceEnum.Threads,
      ) ?? []
  }

  if (searching) {
    return (
      <>
        <Sidebar.List>
          {displayedChannels.map((channel) => (
            <ChannelNavItem
              channel={channel as CommentsChannelNav}
              key={channel.id}
              commentCounts={
                counts?.filter((count) => count.channelId === channel.id)[0]
                  ?.count ?? 0
              }
            />
          ))}
        </Sidebar.List>
        {!searchQuery && (
          <EmptyState size="small">
            <EmptyState.Description color="subtle">
              Start typing to search channels.
            </EmptyState.Description>
          </EmptyState>
        )}
        {searchQuery && displayedChannels.length === 0 && (
          <EmptyState size="small">
            <EmptyState.Icon>
              <SearchIcon />
            </EmptyState.Icon>
            <EmptyState.Heading>No channels found</EmptyState.Heading>
            <EmptyState.Description>
              Try searching for a different channel name.
            </EmptyState.Description>
          </EmptyState>
        )}
      </>
    )
  }

  return (
    <>
      <Sidebar.List>
        {/* TODO: We can add all channels item here once we are ready to support it */}
        {/* <AllChannelsItem /> */}
        {shouldShowCommentsChannelSuggestions && (
          <ChannelSuggestions services={[ServiceEnum.Threads]} />
        )}
        {channels.map((channel) => (
          <ChannelNavItem
            channel={channel}
            key={channel.id}
            commentCounts={
              counts?.filter((count) => count.channelId === channel.id)[0]
                ?.count ?? 0
            }
          />
        ))}
      </Sidebar.List>
      {isAdmin && (
        <>
          {channelConnectionSuggestions && (
            <ChannelSuggestions services={channelConnectionSuggestions} />
          )}
          <Sidebar.List>
            <NewChannelButton />
          </Sidebar.List>
        </>
      )}
    </>
  )
}

const ChannelSuggestions = ({
  services,
}: {
  services: Service[]
}): JSX.Element => {
  const [showAll, setShowAll] = React.useState(false)

  const connectChannel = useConnectChannel({
    cta: 'publish-comments-sidebar-addChannelSuggestions-1',
  })

  const { clicked: ctaClicked } = useCta(
    'publish-comments-sidebar-addChannelSuggestions-1',
    {
      upgradePathName: 'addChannel-upgrade',
    },
  )

  const onChannelClick = (channelService: Service): void => {
    ctaClicked()
    connectChannel(channelService)
  }

  const displayServices = showAll ? services : services.slice(0, 3)
  const showMore = services.length > displayServices.length && !showAll

  return (
    <>
      <Sidebar.List>
        {displayServices.map((service) => (
          <Sidebar.ListItem key={service}>
            <Sidebar.Button
              size="large"
              className={styles.channelSuggestion}
              style={
                {
                  '--channel-color': getChannelColor(service),
                } as React.CSSProperties
              }
              prefix={
                <span className={styles.icon}>
                  <ChannelIcon type={service as ChannelType} />
                </span>
              }
              onClick={(): void => onChannelClick(service)}
            >
              Connect {getChannelName(service)}
            </Sidebar.Button>
          </Sidebar.ListItem>
        ))}
      </Sidebar.List>
      {showMore ? (
        <Sidebar.List>
          <Sidebar.ListItem>
            <Sidebar.Button
              size="large"
              prefix={
                <span className={styles.icon}>
                  <ChevronDownIcon />
                </span>
              }
              className={styles.showMoreSuggestionsButton}
              onClick={(): void => setShowAll(!showAll)}
            >
              Show more channels
            </Sidebar.Button>
          </Sidebar.ListItem>
        </Sidebar.List>
      ) : null}
    </>
  )
}
