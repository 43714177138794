import { getFileExtension, getFileMimeType, getFileName } from '../utils'
import { UploadMetadata } from './UploadMetadata'

type WithLastModified<T> = T & { lastModified?: number }

export type LocalFile = {
  name: string
  type: string
  extension: string
  size: number
  data: WithLastModified<File> | WithLastModified<Blob>
  meta: UploadMetadata
}

export const LocalFile = {
  new(file: File, meta: UploadMetadata): LocalFile {
    const type = getFileMimeType(file)
    const name = getFileName(file)

    return {
      type,
      name,
      size: file.size,
      extension: getFileExtension(name),
      data: file,
      meta: UploadMetadata.new(meta),
    }
  },
}
