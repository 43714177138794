/* eslint-disable camelcase */
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useCurrentOrganization } from '~publish/legacy/accountContext'
import type { Tag } from '~publish/gql/graphql'
import {
  actions as composerPopoverActions,
  type ComposerPopoverActionInput,
} from '~publish/legacy/composer-popover'
import {
  draftDataToRpcUpdate,
  getDraftDataFromLocalStorage,
} from '~publish/legacy/composer/composer/utils/PreviewUtils'
import type { RpcUpdate } from '~publish/legacy/post/types'
import { type RootState, useAppDispatch } from '~publish/legacy/store'

export type OpenComposerCommonOptions = {
  shareAgain?: boolean
  aiPanelOpen?: boolean
  prefillFromLocalStorage?: boolean
}

type EditPostInComposerData = {
  cta?: CTA
  postId: string
}

type DuplicatePostInComposerData = {
  cta?: CTA
  channels?: string[] | 'all'
  prefillPostData: RpcUpdate
  duplicatedFrom: string
}

type CreateNewPinnedPostInComposerData = {
  cta?: CTA
  channels: [string]
  prefillPostData: {
    dueAt: number
    pinned: true
    tags?: Tag[]
  }
}

export type CreateNewPostInComposerData = {
  cta?: CTA
  channels?: string[] | 'all'
  prefillPostData?: Partial<RpcUpdate>
}

export type UseComposerResult = {
  // This is the object that should be spread on the trigger element. It
  // contains all the necessary attributes for the trigger element to be accessible.
  triggerAttributes: {
    'aria-haspopup': 'dialog'
    'aria-expanded': boolean
    'aria-controls': 'composer-root'
    'data-state': 'open' | 'closed'
  }
  isOpen: boolean
  editPostInComposer: (
    data: EditPostInComposerData,
    options?: OpenComposerCommonOptions,
  ) => Promise<void>
  duplicatePostInComposer: (
    data: DuplicatePostInComposerData,
    options?: OpenComposerCommonOptions,
  ) => Promise<void>
  createNewPinnedPostInComposer: (
    data: CreateNewPinnedPostInComposerData,
    options?: OpenComposerCommonOptions,
  ) => Promise<void>
  createNewPostInComposer: (
    data: CreateNewPostInComposerData,
    options?: OpenComposerCommonOptions,
  ) => Promise<void>
  hideComposer: () => void
  discardComposer: () => void
}

/**
 * Provides a hook for managing the opening and configuration of a post composer modal.
 * This hook simplifies the process of configuring and triggering the post composer modal
 * across different parts of the application. It ensures accessibility by managing relevant ARIA attributes
 * and also handles the state of the modal (open or closed).
 *
 * @returns {UseComposerResult} An object containing the trigger attributes for accessibility,
 * the current open state of the composer, and a function to open the composer.
 *
 * @example
 * // Example of using usePostComposer in a component to create a new post
 * const { triggerAttributes, isOpen, createNewPostInComposer } = usePostComposer();
 *
 * <button {...triggerAttributes} onClick={() => createNewPostInComposer({
 *   cta: 'publish-allChannels-topNav-newPost-1',
 * })}>
 *   Open Composer
 * </button>
 *
 * @example
 * // Handling edit post scenario
 * const { triggerAttributes, editPostInComposer } = usePostComposer();
 *
 * <button {...triggerAttributes} onClick={() => editPostInComposer({
 *   cta: 'publish-allChannels-postCard-editPost-1',
 *   postId: '1234',
 * })}>
 *   Edit Post
 * </button>
 *
 * @example
 * // Handling create post scenario with text prefill for all channels
 * const { triggerAttributes, createNewPostInComposer } = usePostComposer();
 *
 * <button {...triggerAttributes} onClick={() => createNewPostInComposer({
 *  cta: 'publish-allChannels-topNav-newPost-1',
 *  channels: 'all',
 *  prefillPostData: {
 *    text: 'This is a pre-filled text for the post'
 *  }
 * })}>
 *  Create Post
 * </button>
 *
 * @example
 * // Handling create post scenario when duplicating a post
 * const { triggerAttributes, duplicatePostInComposer } = usePostComposer();
 *
 * <button {...triggerAttributes} onClick={() => duplicatePostInComposer({
 *  cta: 'publish-allChannels-topNav-newPost-1',
 *  channels: 'all',
 *  prefillPostData: RpcUpdate,
 *  duplicatedFrom: RpcUpdate.id,
 * })}>
 *  Duplicate Post
 * </button>
 *
 * @example
 * // Handling create post scenario from clicking on a queue slot
 * const { triggerAttributes, createNewPinnedPostInComposer } = usePostComposer();
 *
 * <button {...triggerAttributes} onClick={() => createNewPinnedPostInComposer({
 *  cta: 'publish-allChannels-topNav-newPost-1',
 *  channels: ['channelId'],
 *  prefillPostData: {
 *    dueAt: 1721724196,
 *    pinned: true
 *  }
 * })}>
 *  Create Post
 * </button>
 */
export function usePostComposer(): UseComposerResult {
  const dispatch = useAppDispatch()
  const channels = useCurrentOrganization()?.channels
  const open = useSelector(
    (state: RootState) => state.composerPopover.showComposer,
  )

  const triggerAttributes = useMemo(
    () => ({
      'aria-haspopup': 'dialog' as const,
      'aria-expanded': open,
      'aria-controls': 'composer-root' as const,
      'data-state': open ? ('open' as const) : ('closed' as const),
    }),
    [open],
  )

  const editPostInComposer = useCallback(
    async (
      data: EditPostInComposerData,
      options?: OpenComposerCommonOptions,
    ): Promise<void> => {
      dispatch(
        composerPopoverActions.handleEditClick(data.postId, {
          cta: data.cta,
          withAIPanelOpen: options?.aiPanelOpen,
        }),
      )
    },
    [dispatch],
  )

  const duplicatePostInComposer = useCallback(
    async (
      data: DuplicatePostInComposerData,
      options?: OpenComposerCommonOptions,
    ): Promise<void> => {
      const actionData: ComposerPopoverActionInput = {
        selectedProfileIds:
          data.channels === 'all' ? channels?.map((c) => c.id) : data.channels,
        cta: data.cta,
        withAIPanelOpen: options?.aiPanelOpen,
        duplicatedFrom: data?.duplicatedFrom,
        post: data.prefillPostData,
      }

      dispatch(composerPopoverActions.openComposer(actionData))
    },
    [channels, dispatch],
  )

  const createNewPinnedPostInComposer = useCallback(
    async (
      data: CreateNewPinnedPostInComposerData,
      options?: OpenComposerCommonOptions,
    ): Promise<void> => {
      const actionData: ComposerPopoverActionInput = {
        selectedProfileIds: data.channels,
        cta: data.cta,
        withAIPanelOpen: options?.aiPanelOpen,
        emptySlotMode: true,
        post: {
          ...data.prefillPostData,
          due_at: data.prefillPostData.dueAt,
          scheduled_at: data.prefillPostData.dueAt,
          scheduledAt: data.prefillPostData.dueAt,
        },
      }

      dispatch(composerPopoverActions.openComposer(actionData))
    },
    [dispatch],
  )

  const createNewPostInComposer = useCallback(
    async (
      data: CreateNewPostInComposerData,
      options?: OpenComposerCommonOptions,
    ): Promise<void> => {
      const actionData: ComposerPopoverActionInput = {
        selectedProfileIds:
          data.channels === 'all' ? channels?.map((c) => c.id) : data.channels,
        cta: data.cta,
        withAIPanelOpen: options?.aiPanelOpen,
        shareAgainMode: options?.shareAgain,
      }

      if (options?.prefillFromLocalStorage) {
        const draftData = getDraftDataFromLocalStorage()
        if (draftData) {
          data.prefillPostData = {
            ...data.prefillPostData,
            ...draftDataToRpcUpdate(draftData),
          }
        }
      }

      if (data?.prefillPostData) {
        actionData.post = {
          ...data.prefillPostData,
          ...(data.prefillPostData.dueAt && {
            due_at: data.prefillPostData.dueAt,
            scheduled_at: data.prefillPostData.dueAt,
            scheduledAt: data.prefillPostData.dueAt,
          }),
        }

        if (data.prefillPostData.dueAt) {
          actionData.hasDefaultTime = true
        }
      }

      dispatch(composerPopoverActions.openComposer(actionData))
    },
    [channels, dispatch],
  )

  const hideComposer = useCallback((): void => {
    dispatch(composerPopoverActions.hideComposer())
  }, [dispatch])

  const discardComposer = useCallback((): void => {
    dispatch(composerPopoverActions.hideComposer(true))
  }, [dispatch])

  return {
    triggerAttributes,
    isOpen: open,
    editPostInComposer,
    duplicatePostInComposer,
    createNewPinnedPostInComposer,
    createNewPostInComposer,
    hideComposer,
    discardComposer,
  }
}
