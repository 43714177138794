// This client was automatically generated by Segment Typewriter. ** Do Not Edit **
// To update this file, run:
//   npx typewriter

/**
 * When a legacy (multi-product) user views the modal to upgrade their plan for access to
 * the AI Assistant feature.
 */
export interface AIAssistantUpgradeModalViewed {
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * Which call to action did the user click to view the modal
   */
  cta: string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  [property: string]: any
}

/**
 * When was an account's avatar updated?
 */
export interface AccountAvatarUpdated {
  /**
   * The accountId of the user that took the action
   */
  accountId: string
  /**
   * What is the account's new avatar url?
   */
  url: string
  [property: string]: any
}

/**
 * When was the user's global Buffer account firt created? This occurs during their first
 * product signup.
 */
export interface AccountCreated {
  /**
   * The place the user was actually redirected to after signup.
   */
  actualRedirect?: null | string
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * Was the account created with a trial, or was it a free signup?
   */
  createdWithTrial?: boolean | null
  /**
   * Which call to action did the user click to create their Buffer global account
   */
  cta?: null | string
  /**
   * Which website is the CTA located in? Ex, `bufferSite`
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA?  In many cases it makes sense to describe the
   * intended result of the CTA, like `proTrial` or `publishPro`
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be, `cards` for the Pro trial CTA
   * in Publish pricing page main plan comparison section
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA?  If setting the value for the first time, set to
   * `1`
   */
  ctaVersion?: null | string
  /**
   * Which website page is the CTA located?  Ex,  `pricingPublish` for the Publish pricing page
   */
  ctaView?: null | string
  /**
   * The Facebook Click ID. This is used for paid marketing attribution.
   */
  fbclid?: null | string
  /**
   * The Google Click ID. This is used for paid marketing attribution.
   */
  gclid?: null | string
  /**
   * Does the account have an associated Mixpanel anonymous id?
   */
  hasMixpanelAnonymousId?: boolean | null
  /**
   * The redirect param present during account signup, if present.
   */
  intendedRedirect?: null | string
  /**
   * True if the redirect param used during signup was valid and allowed.
   */
  isRedirectValid?: boolean
  /**
   * Was the newly created user referred by another organization?
   */
  isReferredUser?: boolean
  /**
   * Was the account created via a team member invite?
   */
  isTeamMember?: boolean
  /**
   * The Mixpanel anonymous id associated with this account
   */
  mixpanelAnonymousId?: null | string
  /**
   * The organization ID of the account created
   */
  organizationId?: string
  /**
   * Which product was the product signup for that triggered this account creation
   */
  product: string
  /**
   * If the new user was referred by an organization, the unique code of the landing page the
   * user used to sign up.
   */
  referralCode?: null | string
  /**
   * the ID of the organization who referred the new user
   */
  referredByOrganizationId?: null | string
  /**
   * The size of the user's screen when the account was created
   */
  screenSize?: null | string
  /**
   * The user agent at the time the account was created.
   */
  userAgent?: null | string
  /**
   * Identifies a specific product promotion or strategic campaign (e.g. app_store_listing)
   */
  utmCampaign?: null | string
  /**
   * Identifies what specifically was clicked to bring the user to the site, such as a banner
   * ad or a text link. It is often used for A/B testing and content-targeted ads.
   */
  utmContent?: null | string
  /**
   * Identifies what type of link was used (e.g. partnership)
   */
  utmMedium?: null | string
  /**
   * Identifies which site sent the traffic (e.g. twitter)
   */
  utmSource?: null | string
  /**
   * Identifies specific search terms. This parameter is not always necessary.
   */
  utmTerm?: null | string
  [property: string]: any
}

/**
 * When did a user delete their global Buffer account? This event is triggered when the
 * unique identifier for the global account is deleted from the production database. This
 * event will be triggered if the user deletes their own account, or if a Buffer employee
 * deletes the account on the users behalf.
 */
export interface AccountDeleted {
  /**
   * What was the free-form response the user provided about their reason for deleting their
   * account?
   */
  details: null | string
  /**
   * What was the reason the user selected as to why they chose to delete their?
   */
  reason?: null | string
  [property: string]: any
}

/**
 * When did the user login to Buffer?
 */
export interface AccountLogin {
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * The CTA that was clicked to trigger the login
   */
  cta?: string
  /**
   * The organization ID of the account that was logged in
   */
  organizationId?: string
  properties: any
  [property: string]: any
}

/**
 * When did the user logout of Buffer? This will only be triggered when the user explicitly
 * clicks a CTA to log out.
 */
export interface AccountLogout {
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * The organization ID of the account logged out
   */
  organizationId?: string
  /**
   * Which product was the user in when they logged out?
   */
  product: string
  [property: string]: any
}

/**
 * When user update their account name
 */
export interface AccountNameUpdated {
  /**
   * The accountId of the user that took the action
   */
  accountId: string
  [property: string]: any
}

/**
 * This event is used for paid marketing purposes (we need to send a conversion event from
 * the client to Google Ads). The event is sent when a user attempts to sign up for a Buffer
 * account.
 */
export interface AccountSignupAttempted {
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: null | string
  /**
   * Was the account created via a team member invite?
   */
  isTeamMember?: boolean | null
  /**
   * The organizationId assigned to the user.
   */
  organizationId?: null | string
  properties: any
  [property: string]: any
}

/**
 * When was a user's global Buffer account suspended by Buffer?
 */
export interface AccountSuspended {
  /**
   * The organization ID of the account suspended
   */
  organizationId: string
  /**
   * Has the last payment been refunded?
   */
  wasRefunded?: boolean
  /**
   * Has the subscription been cancelled in the payment gateway?
   */
  wasSubscriptionCancelled?: boolean
  [property: string]: any
}

/**
 * When was an account's timezone updated?
 */
export interface AccountTimezoneUpdated {
  /**
   * The accountId of the user that took the action
   */
  accountId: string
  /**
   * What is the account's new timezone?
   */
  timezone: string
  [property: string]: any
}

/**
 * When was a user's global Buffer account unsuspended by Buffer?
 */
export interface AccountUnsuspended {
  /**
   * The organization ID of the user that is no longer suspended
   */
  organizationId: string
  [property: string]: any
}

/**
 * When was an address for a customer updated
 */
export interface AddressUpdated {
  /**
   * The address country of the customer
   */
  country?: null | string
  /**
   * Unique identifier for the Stripe customer
   */
  customerId: string
  /**
   * The ID of the organization for which the tax exempt was updated
   */
  organizationId: string
  /**
   * The address postal code of the customer
   */
  postalCode?: null | string
  /**
   * The previous address country of the customer
   */
  previousCountry?: null | string
  /**
   * The previous address postal code of the customer
   */
  previousPostalCode?: null | string
  /**
   * The previous address state of the customer
   */
  previousState?: null | string
  /**
   * The address state of the customer
   */
  state?: null | string
  /**
   * Stripe event id from the webhook event
   */
  stripeEventId: string
  [property: string]: any
}

/**
 * All Channels view opened with filters
 */
export interface AllChannelsOpened {
  /**
   * The account ID
   */
  accountId: string
  /**
   * Selected channels in filter, if not defined - all channels selected
   */
  channelsSelected?: any[]
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * Selected tab - queue/sent/approvals/drafts
   */
  tab: string
  /**
   * Selected tags in filter, if not defined - all tags selected
   */
  tagsSelected?: any[]
  [property: string]: any
}

/**
 * When a user dismisses all comments on a post.
 */
export interface AllPostCommentsDismissed {
  /**
   * The channel that the comments belong to, e.g. "facebook"
   */
  channel?: string
  /**
   * The database id for the channel.
   */
  channelId: string
  /**
   * The service's own id for this channel.
   */
  channelServiceId?: string
  /**
   * What is the username of the channel?
   */
  channelUsername?: string
  /**
   * Does the post have any labels?
   */
  hasLabel: boolean
  /**
   * Is the post a giveaway?
   */
  isGiveaway?: boolean
  /**
   * The types of lables associated with the post (e.g. "upset/order/question")
   */
  labelTypes: any[]
  /**
   * The global organization id of the user that owns the channel.
   */
  organizationId: string
  /**
   * The unique identifier of the post.
   */
  postId: string
  /**
   * The product in which the event occurred.
   */
  product: string
  /**
   * The total number of comments for the post.
   */
  totalComments: number
  /**
   * The total number of unanswered comments for the post.
   */
  totalUnansweredComments: number
  [property: string]: any
}

/**
 * When there is a failure adding Analytics Chart to a Report
 */
export interface AnalyticsAddChartToReportFailed {
  /**
   * The channel of the selected profile, ex. “instagram”.
   */
  channel?: string
  /**
   * The name of the client in which the event occurred.
   */
  clientName: string
  /**
   * The global organization ID of the user that took the action.
   */
  organizationId: string
  /**
   * The product in which the event occurred, ex. “analyze”.
   */
  product: string
  [property: string]: any
}

/**
 * When there is an error rendering an analytics page.
 */
export interface AnalyticsChartRenderingFailed {
  /**
   * The channel of the selected profile, ex. “instagram”.
   */
  channel?: string
  /**
   * The name of the chart that failed to render.
   */
  chartName?: string
  /**
   * The name of the client in which the event occurred.
   */
  clientName: string
  /**
   * The cause of the failure event.
   */
  failureCause: string
  /**
   * The global organization ID of the user that took the action.
   */
  organizationId: string
  /**
   * The product in which the event occurred, ex. “analyze”.
   */
  product: string
  [property: string]: any
}

/**
 * When there is an error exporting analytics.
 */
export interface AnalyticsExportFailed {
  /**
   * If the export is a CSV or image, what is the channel of the selected profile?
   */
  channel?: null | string
  /**
   * The name of the client in which the event occurred.
   */
  clientName: string
  /**
   * The type of the export, ex. "pdf", "csv", "images"
   */
  exportType: string
  /**
   * The global organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The product in which the event occurred, ex. “analyze”.
   */
  product: string
  /**
   * The id of the report that the user intended to export if it is PDF export.
   */
  reportId?: null | string
  [property: string]: any
}

/**
 * When there is an error fetching analytics or data.
 */
export interface AnalyticsRetrievalFailed {
  /**
   * The channel of the selected profile, ex. “instagram”.
   */
  channel: string
  /**
   * The name of the client in which the event occurred.
   */
  clientName: string
  /**
   * The global organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The product in which the event occurred, ex. “analyze”.
   */
  product: string
  [property: string]: any
}

/**
 * When did the user first open the Buffer mobile app after installation? Note, if the user
 * never opens the Buffer mobile app after installing, this event will not be fired.
 */
export interface ApplicationInstalled {
  /**
   * What was the build number of the installed app the user opened?
   */
  build: any
  /**
   * The mobile client the user is on, `publishIos` or `publishAndroid`.
   */
  clientName?: string
  /**
   * The product in which the event occurred, ex. “publish”.
   */
  product?: string
  /**
   * Which mobile app did the user open?
   */
  version: string
  [property: string]: any
}

/**
 * When did the user open this Buffer mobile application? This event fires when a user
 * launches or foregrounds the Buffer mobile application after the first open. It will fire
 * after the Application Installed event and again after the app is re-opened after being
 * closed. When an App returns from the background this event will fire on iOS only. Apps
 * returning from the background are not currently supported on Android as Application
 * Opened events.
 */
export interface ApplicationOpened {
  /**
   * What was the build number of the installed app the user opened?
   */
  build: any
  /**
   * The mobile client the user is on, `publishIos` or `publishAndroid`.
   */
  clientName?: string
  /**
   * Did the application transition from “Background” to “Inactive” state prior to
   * foregrounding (as opposed to from “Not Running” state)? Collected on iOS only.
   */
  fromBackground?: boolean | null
  /**
   * The product in which the event occurred, ex. “publish”.
   */
  product?: string
  /**
   * What was the value of `UIApplicationLaunchOptionsURLKey` from `launchOptions`? Collected
   * on iOS only.
   */
  url?: null | string
  /**
   * Which mobile app did the user open?
   */
  version: string
  [property: string]: any
}

/**
 * When did the user first open the Buffer mobile application after installing an update?
 */
export interface ApplicationUpdated {
  /**
   * What was the build number of the updated app?
   */
  build: any
  /**
   * The mobile client the user is on, `publishIos` or `publishAndroid`.
   */
  clientName?: string
  /**
   * What was the build number of the previously installed app?
   */
  previousBuild?: any
  /**
   * Which mobile app was previously installed prior to the user installing the update?
   */
  previousVersion?: string
  /**
   * The product in which the event occurred, ex. “publish”.
   */
  product?: string
  /**
   * Which mobile app did the user update to?
   */
  version: string
  [property: string]: any
}

/**
 * When the user has added author attribution from a link URL
 */
export interface AuthorAttributionAdded {
  /**
   * The selected attribution mode
   */
  attribution: string
  /**
   * Which client was the user in when they attached the media item? IE, `publishIos`,
   * `publishWeb`, `publishAndroid`.
   */
  clientName: string
  /**
   * The organization ID of the user that added the media
   */
  organizationId?: string
  /**
   * The product for which the author was attributed in
   */
  product: string
  [property: string]: any
}

/**
 * When the autocrop setting is enabled or disabled
 */
export interface AutoCropToggled {
  /**
   * What was the unique identifier of the client the user was in when they added the widget?
   */
  clientId?: null | string
  /**
   * Which client was the user in when they added the widget? IE, `publishIos` or
   * `publishAndroid`.
   */
  clientName: string
  /**
   * Whether autocrop setting was enabled when it was toggled
   */
  enabled: boolean
  /**
   * The organization ID of the user that added the widget.
   */
  organizationId: string
  /**
   * Where the autocrop setting was toggled
   */
  source: string
  [property: string]: any
}

/**
 * When was the subscription auto renewal at the end of the current billing term disabled?
 * This equates to the moment in time that the user decided to cancel their paid
 * subscription, but it differs from the Subscription Ended event since there could be time
 * remaining on the current billing term.
 */
export interface AutoRenewalDisabled {
  /**
   * Unique identifier for the Stripe customer
   */
  customerId: string
  /**
   * The subscription payment interval (month or year) of the subscription
   */
  cycle?: string
  /**
   * What triggered this event? e.g. Stripe or RevenueCat
   */
  gateway?: string
  /**
   * The ID of the organization for which the subscription was created
   */
  organizationId?: string
  /**
   * Stripe plan id of the subscription
   */
  planId: string
  /**
   * Human-readable plan name of the subscription
   */
  planName?: string
  /**
   * The product of the subscription
   */
  product: string
  /**
   * Stripe event id from the webhook event
   */
  stripeEventId: string
  /**
   * Unique identifier for the Stripe subscription
   */
  subscriptionId: string
  [property: string]: any
}

/**
 * When a user dismisses a banner within the product
 */
export interface BannerDismissed {
  /**
   * The name of the dismissed banner
   */
  bannerName: string
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product: string
  [property: string]: any
}

/**
 * When a user viewed a banner
 */
export interface BannerViewed {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * Which banner was viewed
   */
  bannerViewed?: any[]
  /**
   * The global organization ID of the user
   */
  organizationId: string
  [property: string]: any
}

/**
 * When did a user disenroll from the Buffer beta program?
 */
export interface BetaProgramDisenrolled {
  /**
   * The account ID of the admin that disenrolled the organization from the Beta program.
   */
  adminAccountId?: null | string
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName?: null | string
  /**
   * How was the user disenrolled in the beta program? Did they use the Beta program page or
   * did Advocacy remove them?
   */
  disenrollmentMethod: string
  /**
   * The global organization ID of the user
   */
  organizationId: string
  [property: string]: any
}

/**
 * When did a user enroll from the Buffer beta program?
 */
export interface BetaProgramEnrolled {
  /**
   * The account ID of the admin that enrolled the organization to the Beta program.
   */
  adminAccountId?: null | string
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName?: null | string
  /**
   * How was the user enrolled in the beta program? Did they use the Beta program page or did
   * Advocacy enroll them?
   */
  enrollmentMethod: string
  /**
   * The global organization ID of the user
   */
  organizationId: string
  [property: string]: any
}

/**
 * When did the user clicked on the CTA to update billing info and it opened Billing Portal?
 */
export interface BillingPortalSessionCreated {
  /**
   * If the user navigated to this page from a CTA on another Buffer page, which call to
   * action was it?
   */
  cta?: null | string
  /**
   * Which product or website is the CTA located in? Examples would be either the product,
   * like `publish`, or the website, like `bufferSite`
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA?  In many cases it makes sense to describe the
   * intended result of the CTA, like `proTrial` or `publishPro`
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in
   * Publish app shell menu
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA?  If setting the value for the first time, set to
   * `1`
   */
  ctaVersion?: null | string
  /**
   * What website page or app view is the CTA located?  Examples would be, `composer` or
   * `analyticsOverview` for Publish, and `pricingPublish` for the Publish pricing page on the
   * Buffer marketing site
   */
  ctaView?: null | string
  /**
   * Unique identifier for the billing gateway customer.
   */
  customerId?: null | string
  /**
   * The global organization ID of the user that viewed the billing portal.
   */
  organizationId: null | string
  /**
   * Human readable name of the section of the plan selector viewed (e.g., ""plans"",
   * ""billing_info"", etc.)
   */
  screenName?: null | string
  [property: string]: any
}

/**
 * When the bitly account is succesfully connected
 */
export interface BitlyAccountConnected {
  /**
   * The channel that settings tab was opened for (ex. instagram, pinterest)
   */
  channel: string
  /**
   * The channelId that settings tab was opened for (ex. instagram, pinterest)
   */
  channelId: string
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product: string
  [property: string]: any
}

/**
 * When the bitly account is succesfully disconnected
 */
export interface BitlyAccountDisconnected {
  /**
   * The channel that settings tab was opened for (ex. instagram, pinterest)
   */
  channel: string
  /**
   * The channelId that settings tab was opened for (ex. instagram, pinterest)
   */
  channelId: string
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product: string
  [property: string]: any
}

/**
 * When a user clicks on a CTA button
 */
export interface CTAClicked {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The name of the CTA clicked.
   */
  cta: null | string
  /**
   * (NOTE - deprecated in favor of cta) The name of the CTA (e.g.
   * "add-channel-sidebar-prelimit")
   */
  ctaName?: null | string
  /**
   * The organization ID of the user that took the action
   */
  organizationId?: string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product: null | string
  /**
   * The name of the upgrade path
   */
  upgradePathName: null | string
  [property: string]: any
}

/**
 * When did the user see a specific CTA?
 */
export interface CTAViewed {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The name of the CTA clicked.
   */
  cta?: null | string
  /**
   * (NOTE -  deprecated in favor of CTA) The name of the CTA (e.g.
   * "add-channel-sidebar-prelimit")
   */
  ctaName?: null | string
  /**
   * The number of channels connected to the user's account
   */
  currentChannelQuantity?: number | null
  /**
   * Is the user at their plan limit?
   */
  isAtPlanLimit?: boolean | null
  /**
   * The global organization ID of the user that viewed the plan selector.
   */
  organizationId: null | string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product?: string
  /**
   * The name of the upgrade path
   */
  upgradePathName: null | string
  [property: string]: any
}

/**
 * When a user filters the campaigns displayed in the calendar.
 */
export interface CalendarCampaignsFiltered {
  /**
   * The number of campaigns selected to be viewed in the calendar after they were filtered.
   */
  campaignCount: number
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The number of campaigns selected in the calendar before they were filtered.
   */
  previousCampaignCount: number
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product: string
  /**
   * The calendar view (ex. day, month, week)
   */
  view: string
  [property: string]: any
}

/**
 * When the channels are filtered after the calendar is opened.
 */
export interface CalendarChannelsFiltered {
  /**
   * The channel ids that are available for the user to select.
   */
  availableChannelIDs: any[]
  /**
   * Given the channels filtered in the Calendar and the user's permissions, is the user able
   * to create a post from the Calendar?
   */
  canCreatePost: boolean
  /**
   * The number of channels selected to be viewed in the calendar after they were filtered.
   */
  channelCount: number
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The number of channels selected in the calendar before they were filtered.
   */
  previousChannelCount: number
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product: string
  /**
   * The channel ids that were selected by the user.
   */
  selectedChannelIDs: any[]
  /**
   * The calendar view (ex. day, month, week)
   */
  view: string
  [property: string]: any
}

/**
 * When the user selects the channels to be viewed in the Calendar. This event shows the
 * user intent to filter the channels.
 */
export interface CalendarChannelsSelected {
  /**
   * The service of the selected channel ex. "facebook"
   */
  channel: string
  /**
   * The number of channels selected to be viewed in the calendar after they were filtered.
   */
  channelCount: number
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The number of channels selected in the calendar before they were filtered.
   */
  previousChannelCount: number
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product: string
  /**
   * The calendar view (ex. day, month, week)
   */
  view: string
  [property: string]: any
}

/**
 * When a user changes the dates displayed on the calendar.
 */
export interface CalendarDatesChanged {
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The type of date change selected (ex. next_week, next_month, previous_week,
   * previous_month, custom_range)
   */
  dateChangeType: string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product: string
  /**
   * The calendar view (ex. day, month, week)
   */
  view: string
  [property: string]: any
}

/**
 * When a user opens the calendar
 */
export interface CalendarOpened {
  /**
   * The version of the calendar (ex. classic, new)
   */
  calendarVersion: string
  /**
   * The number of campaigns selected to be viewed in the calendar.
   */
  campaignCount?: number
  /**
   * The number of channels selected to be viewed in the calendar.
   */
  channelCount?: number
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product: string
  /**
   * Where the calendar was opened from. (ex. queue, sidebar)
   */
  source: string
  /**
   * The calendar view (ex. day, month, week)
   */
  view?: string
  [property: string]: any
}

/**
 * When a user switches the calendar view to or from the month view.
 */
export interface CalendarViewSwitched {
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The previous view that the user switched from (ex. day, month, week)
   */
  previousView: string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product: string
  /**
   * The calendar view the user switched to (ex. day, month, week)
   */
  view: string
  [property: string]: any
}

/**
 * When a new campaign is created.
 */
export interface CampaignCreated {
  /**
   * The color selected for the campaign.
   */
  campaignColor: string
  /**
   * The ID of the new campaign.
   */
  campaignId: string
  /**
   * The name given to the campaign.
   */
  campaignName: string
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The global organization ID that the campaign was created for.
   */
  organizationId: string
  /**
   * The product in which the action occurred
   */
  product: string
  /**
   * The ID of the Publish organization that the campaign was created for.
   */
  publishOrganizationId?: string
  [property: string]: any
}

/**
 * When a campaign is deleted.
 */
export interface CampaignDeleted {
  /**
   * The color selected for the campaign.
   */
  campaignColor: string
  /**
   * The unique ID assigned to the campaign.
   */
  campaignId: string
  /**
   * The name given to the campaign.
   */
  campaignName: string
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The global organization ID that the campaign was created for.
   */
  organizationId: string
  /**
   * The product in which the action occurred.
   */
  product: string
  /**
   * The ID of the Publish organization that the campaign was created for.
   */
  publishOrganizationId?: string
  [property: string]: any
}

/**
 * When a campaign is edited.
 */
export interface CampaignEdited {
  /**
   * The color selected for the campaign.
   */
  campaignColor: string
  /**
   * The ID of the campaign.
   */
  campaignId: string
  /**
   * The name given to the campaign.
   */
  campaignName: string
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The global organization ID that the campaign was created for.
   */
  organizationId: string
  /**
   * The product in which the action occurred
   */
  product: string
  /**
   * The ID of the Publish organization that the campaign was created for.
   */
  publishOrganizationId?: string
  [property: string]: any
}

/**
 * When a new item is added to an existing campaign.
 */
export interface CampaignItemAdded {
  /**
   * Where was the item added to the campaign from? Ex. Queue, Campaigns
   */
  addedFrom?: string
  /**
   * The ID of the campaign.
   */
  campaignId: string
  /**
   * The name given to the campaign.
   */
  campaignName: string
  /**
   * The social network for which the item was created, ex. instagram.
   */
  channel: string
  /**
   * The ID of the channel for which the item was created.
   */
  channelId?: string
  /**
   * The type of channel the item was created for, ex. group, page, business.
   */
  channelType?: string
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * If the item added to the campaign is a draft
   */
  isDraft: boolean
  /**
   * If is a sent post
   */
  isSent: boolean
  /**
   * The id of the post or story that was added to the campaign.
   */
  itemId: string
  /**
   * The type of the item that was added to the campaign, ex. post or story.
   */
  itemType: string
  /**
   * The global organization ID that the campaign was created for.
   */
  organizationId: string
  /**
   * The product in which the action occurred.
   */
  product: string
  /**
   * The ID of the Publish organization that the campaign was created for.
   */
  publishOrganizationId?: string
  [property: string]: any
}

/**
 * When an item is removed from an existing campaign.
 */
export interface CampaignItemRemoved {
  /**
   * The ID of the campaign.
   */
  campaignId: string
  /**
   * The name given to the campaign.
   */
  campaignName: string
  /**
   * The social network for which the item was created, ex. instagram.
   */
  channel: string
  /**
   * The ID of the channel for which the item was created.
   */
  channelId?: string
  /**
   * The type of channel the item was created for, ex. group, page, business.
   */
  channelType?: string
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * If the item removed from the campaign is a draft
   */
  isDraft: boolean
  /**
   * If is a sent post
   */
  isSent: boolean
  /**
   * The id of the post or story that was removed from the campaign.
   */
  itemId: string
  /**
   * The type of the item that was removed from the campaign, ex. post or story.
   */
  itemType: string
  /**
   * The global organization ID that the campaign was created for.
   */
  organizationId: string
  /**
   * The product in which the action occurred.
   */
  product: string
  /**
   * The ID of the Publish organization that the campaign was created for.
   */
  publishOrganizationId?: string
  /**
   * Where was the item removed from? Ex. Queue, Campaigns
   */
  removedFrom?: string
  [property: string]: any
}

/**
 * When a report is viewed for an existing campaign.
 */
export interface CampaignReportViewed {
  /**
   * The ID of the campaign.
   */
  campaignId: string
  /**
   * The name given to the campaign.
   */
  campaignName: string
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The ID of the global organization that the campaign was created for.
   */
  organizationId: string
  /**
   * The product in which the action occurred
   */
  product: string
  /**
   * The ID of the Publish organization that the campaign was created for.
   */
  publishOrganizationId?: string
  [property: string]: any
}

/**
 * When a user changes between Active and Completed Campaigns
 */
export interface CampaignsFilterSelected {
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product: string
  /**
   * The campaign filter (active/completed)
   */
  view: string
  [property: string]: any
}

/**
 * When did a user click on the Cancel Plan button on the billing page?
 */
export interface CancelPlanClicked {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The global organization ID of the user
   */
  organizationId: string
  [property: string]: any
}

/**
 * When a user opens the Canva integration from the Publish composer.
 */
export interface CanvaButtonClicked {
  /**
   * The channel that the Canva integration was opened for (ex. instagram, pinterest)
   */
  channel: string
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The identifier of the Canva Design. This is provided when editing a Canva image.
   */
  designId?: null | string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product: string
  [property: string]: any
}

/**
 * When a user clicks the button for the Canva integration from the Publish composer to edit
 * an already created post that already has an attached Canva design.
 */
export interface CanvaButtonEditClicked {
  /**
   * The channel that the Canva integration was opened for (ex. instagram, pinterest)
   */
  channel: string
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The identifier of the Canva Design. This is provided when editing a Canva image.
   */
  designId?: null | string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product: string
  [property: string]: any
}

/**
 * When a user closes one of the Canva designs.
 */
export interface CanvaDesignClosed {
  /**
   * The channel that the Canva integration was originally opened for (ex. instagram,
   * pinterest)
   */
  channel: string
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product: string
  [property: string]: any
}

/**
 * When a user closes one of the Canva designs, while editing an existing post that already
 * has a Canva Image attached.
 */
export interface CanvaDesignEditClosed {
  /**
   * The channel that the Canva integration was originally opened for (ex. instagram,
   * pinterest)
   */
  channel: string
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product: string
  [property: string]: any
}

/**
 * When a user opens one of the Canva designs, while editing an existing post that already
 * has a Canva Image attached.
 */
export interface CanvaDesignEditOpened {
  /**
   * The channel that the Canva integration was opened for (ex. instagram, pinterest)
   */
  channel: string
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The identifier of the Canva Design. This is provided when editing a Canva image.
   */
  designId?: null | string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product: string
  [property: string]: any
}

/**
 * When a user opens one of the Canva designs.
 */
export interface CanvaDesignOpened {
  /**
   * The channel that the Canva integration was opened for (ex. instagram, pinterest)
   */
  channel: string
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The identifier of the Canva Design. This is provided when editing a Canva image.
   */
  designId?: null | string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product: string
  [property: string]: any
}

/**
 * When a user publishes one of the Canva designs.
 */
export interface CanvaDesignPublished {
  /**
   * The channel that the Canva integration was opened for (ex. instagram, pinterest)
   */
  channel: string
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The identifier of the image provided by Canva
   */
  designId: string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product: string
  [property: string]: any
}

/**
 * When a user publishes one of the Canva designs, after editing an existing post that
 * already has a Canva Image attached.
 */
export interface CanvaDesignRepublished {
  /**
   * The channel that the Canva integration was opened for (ex. instagram, pinterest)
   */
  channel: string
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The identifier of the image provided by Canva
   */
  designId: string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product: string
  [property: string]: any
}

/**
 * When an image from Canva is attached to a Buffer post.
 */
export interface CanvaImageAttached {
  /**
   * The channel that the Canva image was created for (ex. instagram, pinterest)
   */
  channel: string
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The identifier of the image provided by Canva
   */
  designId: string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product: string
  [property: string]: any
}

/**
 * When an image from Canva is attached to a Buffer post, while editing an existing post
 * that already has a Canva Image attached.
 */
export interface CanvaImageEditAttached {
  /**
   * The channel that the Canva image was created for (ex. instagram, pinterest)
   */
  channel: string
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The identifier of the image provided by Canva
   */
  designId: string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product: string
  [property: string]: any
}

/**
 * When a user attempts to edit a carousel on mobile
 */
export interface CarouselEditAttempted {
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product: string
  [property: string]: any
}

/**
 * When backfilling has been completed for a channel
 */
export interface ChannelBackfillCompleted {
  /**
   * The backfilling duration
   */
  backfillDuration: number
  /**
   * The timestamp when backfilling ended
   */
  backfillEndedAt: number
  /**
   * The timestamp when backfilling started
   */
  backfillStartedAt: number
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The database id for the channel.
   */
  channelId: string
  /**
   * The service's own id for this channel.
   */
  channelServiceId: string
  /**
   * What is the username of the channel?
   */
  channelUsername: string
  /**
   * Does the channel have any labels?
   */
  hasLabel?: boolean
  /**
   * The types of labels associated with the channel (e.g. "upset/order/question")
   */
  labelTypes?: any[]
  /**
   * The global organization id of the user that owns this channel
   */
  organizationId: string
  /**
   * The product in which the event occurred.
   */
  product: string
  /**
   * The total number of unanswered comments remaining for the channel.
   */
  totalUnansweredComments?: number
  [property: string]: any
}

/**
 * When did the user clicked a specific Channel CTA?
 */
export interface ChannelCTAClicked {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * The name of the CTA clicked.
   */
  cta: string
  /**
   * The number of channels connected to the user's account
   */
  currentChannelQuantity?: number | null
  /**
   * Is the user at their plan limit?
   */
  isAtPlanLimit?: boolean | null
  /**
   * The global organization ID of the user that clicked the Channel CTA.
   */
  organizationId: string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product?: string
  /**
   * The name of the upgrade path
   */
  upgradePathName?: null | string
  [property: string]: any
}

/**
 * When did the user see a specific Channel CTA?
 */
export interface ChannelCTAViewed {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * The name of the CTA viewed.
   */
  cta: string
  /**
   * The number of channels connected to the user's account
   */
  currentChannelQuantity?: number | null
  /**
   * Is the user at their plan limit?
   */
  isAtPlanLimit?: boolean | null
  /**
   * The global organization ID of the user that viewed the Channel CTA.
   */
  organizationId: string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product?: string
  /**
   * The name of the upgrade path
   */
  upgradePathName: null | string
  [property: string]: any
}

/**
 * When a user first connects a channel (aka profile) to the product
 */
export interface ChannelConnected {
  /**
   * API Version this channel uses
   */
  apiVersion?: string
  /**
   * The categories of the Facebook page connected to the Instagram Business account. Only
   * applicable for Instagram Business profiles or Facebook pages.
   */
  businessCategories?: any[] | null
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The service's own id for this channel
   */
  channelServiceId?: string
  /**
   * The channel type, ex. "page", "business", "profile", "group", "creator"
   */
  channelType?: string
  /**
   * What is the username of the channel?
   */
  channelUsername?: string
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * Which call to action did the user click to connect the channel?
   */
  cta?: null | string
  /**
   * Which product was the CTA located in? Example would be `publish`
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA?  In many cases it makes sense to describe the
   * intended result of the CTA, like `proTrial` or `publishPro`
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in
   * Publish app shell menu
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA?  If setting the value for the first time, set to
   * `1`
   */
  ctaVersion?: null | string
  /**
   * Which app view is the CTA located?  Examples would be, `composer` or `analyticsOverview`
   * for Publish
   */
  ctaView?: null | string
  /**
   * The number of followers that the channel has at the time of connection. Only applicable
   * for Instagram Business profiles.
   */
  followers?: number | null
  /**
   * Was the organization at or above their channel limit when the channel was connected.
   */
  isAtPlanLimit?: boolean | null
  /**
   * The media count of the Instagram Business profile at the time of connection.
   */
  mediaCount?: number | null
  /**
   * The ID of the global organization that the channel was connected to.
   */
  organizationId?: string
  /**
   * Used to check if the channel was migrated to new Pinterest API
   */
  pinterestNewApiMigrated?: boolean
  /**
   * The product the channel was connected to.
   */
  product: string
  /**
   * The server url of a Mastodon channel being connected.
   */
  serverUrl?: null | string
  /**
   * Twitter Subscription Type of a connected Twitter/X Channel
   */
  twitterSubscriptionType?: string
  [property: string]: any
}

/**
 * This event is fired when the user closes or cancels the modal to select betwen channel
 * subtypes (i.e. profile or page).
 */
export interface ChannelConnectionAborted {
  /**
   * API Version this channel uses
   */
  apiVersion?: string
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * Which client was the user in when they took this action?
   */
  clientName: string
  /**
   * The ID of the global organization for which the channel connection was attempted.
   */
  organizationId: string
  /**
   * The product for which the channel was connected
   */
  product: string
  [property: string]: any
}

/**
 * This event is currently triggered either when the button is selected, or for channels
 * with a "Choose" page, it is fired after the user has authenticated and authorized Buffer
 * when viewing the page to select a Business Profile, Page or Group.
 */
export interface ChannelConnectionAttempted {
  /**
   * API Version this channel uses
   */
  apiVersion?: string
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The service's own id for this channel
   */
  channelServiceId?: null | string
  /**
   * The type of channel the post is created for, ex. "page", "group"
   */
  channelType?: string
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * Which call to action did the user click to attempt the profile connection?
   */
  cta?: null | string
  /**
   * Which product is the CTA located in? Examples would be either the product, like "publish".
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA? In many cases it makes sense to describe the
   * intended result of the CTA, like `proTrial` or `publishPro`.
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be "menu" for the Pro trial CTA in
   * Publish app shell menu.
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA? If setting the value for the first time, set to
   * "1".
   */
  ctaVersion?: null | string
  /**
   * What app view is the CTA located in? Examples would be, "composer" or "analyticsOverview"
   * for Publish.
   */
  ctaView?: null | string
  /**
   * Was the organization at their channel limit when the channel connection was attempted.
   */
  isAtPlanLimit?: boolean | null
  /**
   * The ID of the global organization for which the channel connection was attempted.
   */
  organizationId?: string
  /**
   * The plan of the current organization for which the channel connection was attempted.
   */
  planNameAtTimeOfAttempt?: null | string
  /**
   * The product for which the channel was connected
   */
  product: string
  [property: string]: any
}

/**
 * When a user cancels a channel connection from the service
 */
export interface ChannelConnectionCanceledByUser {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * The ID of the global organization that the channel was connected to.
   */
  organizationId?: string
  [property: string]: any
}

/**
 * When a channel connection attempt fails.
 */
export interface ChannelConnectionFailed {
  /**
   * API Version this channel uses
   */
  apiVersion?: string
  /**
   * The cause of the failure (i.e. error message).
   */
  cause: string
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The type of channel the post is created for, ex. "page", "group"
   */
  channelType?: string
  /**
   * Which client was the user in when they took this action?
   */
  clientName: string
  /**
   * Which call to action did the user click to attempt the profile connection?
   */
  cta?: null | string
  /**
   * Which product is the CTA located in? Examples would be either the product, like "publish".
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA? In many cases it makes sense to describe the
   * intended result of the CTA, like `proTrial` or `publishPro`.
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be "menu" for the Pro trial CTA in
   * Publish app shell menu.
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA? If setting the value for the first time, set to
   * "1".
   */
  ctaVersion?: null | string
  /**
   * What app view is the CTA located in? Examples would be, "composer" or "analyticsOverview"
   * for Publish.
   */
  ctaView?: null | string
  /**
   * The ID of the global organization for which the channel connection was attempted.
   */
  organizationId: string
  /**
   * The product in which the channel connection attempt occurred.
   */
  product: string
  [property: string]: any
}

/**
 * When a customer views the Channel Connection Goal Modal
 */
export interface ChannelConnectionGoalModalViewed {
  /**
   * The active channel of the user that took the action
   */
  channelId: string
  /**
   * The service of the channel that took the action
   */
  channelService: string
  /**
   * The username of the channel that took the action
   */
  channelUsername: string
  /**
   * Which client was the user in when they took this action?
   */
  clientName: string
  /**
   * The organization ID the channel belongs to
   */
  organizationId: string
  [property: string]: any
}

/**
 * When did the user view the channel connection onboarding modal?
 */
export interface ChannelConnectionOnboardingModalViewed {
  /**
   * Which client was the user in when they opened this modal?
   */
  clientName: null | string
  /**
   * If the user navigated to this page from a CTA on another Buffer page, which call to
   * action was it?
   */
  cta?: null | string
  /**
   * Which product or website is the CTA located in? Examples would be either the product,
   * like `publish`, or the website, like `bufferSite`
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA?
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in
   * Publish app shell menu
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA?  If setting the value for the first time, set to
   * `1`
   */
  ctaVersion?: null | string
  /**
   * What website page or app view is the CTA located?  Examples would be, `composer` or
   * `analyticsOverview` for Publish, and `pricingPublish` for the Publish pricing page on the
   * Buffer marketing site
   */
  ctaView?: null | string
  /**
   * The current plan of the user at time of viewing the channel connection onboarding modal.
   */
  currentPlan?: null | string
  /**
   * The global organization ID of the user that viewed the channel connection onboarding
   * modal.
   */
  organizationId: null | string
  [property: string]: any
}

/**
 * When a user views the Profile Selector
 */
export interface ChannelConnectionProfileSelectorViewed {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * The number of channels connected to the user's account
   */
  currentChannelQuantity?: number | null
  /**
   * Is the user at their plan limit?
   */
  isAtPlanLimit?: boolean | null
  /**
   * The global organization ID of the user that viewed the Profile Selector
   */
  organizationId: string
  /**
   * The product in which the Profile Selector was viewed (ex. publish, buffer)
   */
  product?: string
  [property: string]: any
}

/**
 * When a user views the Profile Type Selection Modal
 */
export interface ChannelConnectionProfileTypeSelectionViewed {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * The number of channels connected to the user's account
   */
  currentChannelQuantity?: number | null
  /**
   * Is the user at their plan limit?
   */
  isAtPlanLimit?: boolean | null
  /**
   * The global organization ID of the user that viewed the Profile Type Selection Modal
   */
  organizationId: string
  /**
   * The product in which the modal was viewed (ex. publish, buffer)
   */
  product?: string
  [property: string]: any
}

/**
 * This event is fired as soon as a user selects a channel when connecting a new channel.
 * This differs from the current implementation of Channel Connection Attempted which is
 * currently fired either when the button is selected, or for channels with a "Choose" page,
 * it is fired after the user has authenticated and authorized Buffer when viewing the page
 * to select a Business Profile, Page or Group.
 */
export interface ChannelConnectionStarted {
  /**
   * API Version this channel uses
   */
  apiVersion?: string
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The type of channel the post is created for, ex. "page", "group"
   */
  channelType?: string
  /**
   * Which client was the user in when they took this action?
   */
  clientName: string
  /**
   * Which call to action did the user click to attempt the profile connection?
   */
  cta?: null | string
  /**
   * Which product is the CTA located in? Examples would be either the product, like "publish".
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA? In many cases it makes sense to describe the
   * intended result of the CTA, like `proTrial` or `publishPro`.
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be "menu" for the Pro trial CTA in
   * Publish app shell menu.
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA? If setting the value for the first time, set to
   * "1".
   */
  ctaVersion?: null | string
  /**
   * What app view is the CTA located in? Examples would be, "composer" or "analyticsOverview"
   * for Publish.
   */
  ctaView?: null | string
  /**
   * The number of channels connected to the user's account
   */
  currentChannelQuantity?: number | null
  /**
   * Is the user at their plan limit?
   */
  isAtPlanLimit?: boolean | null
  /**
   * The ID of the global organization for which the channel connection was attempted.
   */
  organizationId: string
  /**
   * The product for which the channel was connected
   */
  product: string
  /**
   * The name of the upgrade path
   */
  upgradePathName?: null | string
  [property: string]: any
}

/**
 * When a user aborts a channel conversion
 */
export interface ChannelConversionAborted {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The ID of the global organization that the channel was connected to.
   */
  organizationId?: string
  [property: string]: any
}

/**
 * This event is triggered when a channel conversion is attempted.
 */
export interface ChannelConversionAttempted {
  /**
   * The channel's service
   */
  channel: string
  /**
   * The channel id
   */
  channelId: string
  /**
   * The type of the channel
   */
  channelType?: null | string
  /**
   * What is the username of the channel?
   */
  channelUsername?: null | string
  /**
   * The client in which the event occurred.
   */
  clientName?: null | string
  /**
   * The organizationId that the channel belongs to.
   */
  organizationId: string
  /**
   * The service's own id for this channel
   */
  serviceId: string
  [property: string]: any
}

/**
 * When a user cancels a channel conversion from the service
 */
export interface ChannelConversionCanceledByUser {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * The ID of the global organization that the channel was connected to.
   */
  organizationId?: string
  [property: string]: any
}

/**
 * When the convert channel dialog is viewed
 */
export interface ChannelConversionDialogViewed {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The ID of the global organization that the channel was connected to.
   */
  organizationId?: string
  [property: string]: any
}

/**
 * This event is triggered when a channel conversion fails.
 */
export interface ChannelConversionFailed {
  /**
   * The channel's service
   */
  channel: string
  /**
   * The channel id
   */
  channelId: string
  /**
   * The type of the channel
   */
  channelType?: null | string
  /**
   * What is the username of the channel?
   */
  channelUsername?: null | string
  /**
   * The client in which the event occurred.
   */
  clientName?: null | string
  /**
   * The cause of the failure.
   */
  error?: null | string
  /**
   * The organizationId that the channel belongs to.
   */
  organizationId: string
  /**
   * The service's own id for this channel
   */
  serviceId: string
  [property: string]: any
}

/**
 * When a user starts a channel conversion
 */
export interface ChannelConversionStarted {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * Which call to action did the user click to connect the channel?
   */
  cta?: null | string
  /**
   * The ID of the global organization that the channel was connected to.
   */
  organizationId?: string
  [property: string]: any
}

/**
 * This event is triggered when a channel is successfully converted.
 */
export interface ChannelConversionSuccess {
  /**
   * The channel's service
   */
  channel: string
  /**
   * The channel id
   */
  channelId: string
  /**
   * The type of the channel
   */
  channelType?: null | string
  /**
   * What is the username of the channel?
   */
  channelUsername?: null | string
  /**
   * The client in which the event occurred.
   */
  clientName?: null | string
  /**
   * The organizationId that the channel belongs to.
   */
  organizationId: string
  /**
   * The service's own id for this channel
   */
  serviceId: string
  [property: string]: any
}

/**
 * When a channel is deselected by the user in Buffer.
 */
export interface ChannelDeselected {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The database id for the channel
   */
  channelId: string
  /**
   * The service's own id for this channel
   */
  channelServiceId: string
  /**
   * The channel type, ex. "page", "business", "profile", "group"
   */
  channelType: string
  /**
   * What is the username of the channel?
   */
  channelUsername?: string
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * The global organization id of the user that took this action
   */
  organizationId: string
  /**
   * Where the channel was deselected, ex "calendar", "queue", "sidebar"
   */
  page?: string
  /**
   * The product in which the event occurred.
   */
  product: string
  [property: string]: any
}

/**
 * When a user views a channel detail panel
 */
export interface ChannelDetailPanelViewed {
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * The service, ex. "facebook"
   */
  service: string
  [property: string]: any
}

/**
 * When a user explicitly removes a channel from their account (not when we detect an
 * invalid access token)
 */
export interface ChannelDisconnected {
  /**
   * API Version this channel uses
   */
  apiVersion?: string
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The service's own id for this channel
   */
  channelServiceId?: string
  /**
   * The type of channel the post is created for, ex. "page", "group"
   */
  channelType?: string
  /**
   * What is the username of the channel?
   */
  channelUsername?: string
  /**
   * Which client was the user in when the channel was disconnected?
   */
  clientName?: string
  /**
   * The ID of the global organization that the channel was disconnected from.
   */
  organizationId?: string
  /**
   * The product for which the channel was connected
   */
  product: string
  [property: string]: any
}

/**
 * When a user aborts linking an Instagram channel to Facebook
 */
export interface ChannelLinkToFacebookPageAborted {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * The ID of the global organization that the channel was connected to.
   */
  organizationId?: string
  [property: string]: any
}

/**
 * When a user views the dialog to link an Instagram channel to a Facebook page
 */
export interface ChannelLinkToFacebookPageDialogViewed {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * The ID of the global organization that the channel was connected to.
   */
  organizationId?: string
  [property: string]: any
}

/**
 * When a user starts to link an Instagram channel to a Facebook page
 */
export interface ChannelLinkToFacebookPageStarted {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * Which call to action did the user click to trigger linking the channel to a Facebook page?
   */
  cta?: null | string
  /**
   * The ID of the global organization that the channel was connected to.
   */
  organizationId?: string
  [property: string]: any
}

/**
 * When a channel is locked
 */
export interface ChannelLocked {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The service's own id for this channel
   */
  channelServiceId?: string
  /**
   * The channel type, ex. "page", "business", "profile", "group", "creator"
   */
  channelType?: string
  /**
   * What is the username of the channel?
   */
  channelUsername?: string
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * Was the organization at or above their channel limit when the channel was connected.
   */
  isAtPlanLimit?: boolean | null
  /**
   * The ID of the global organization that the channel was connected to.
   */
  organizationId?: string
  /**
   * The product the channel was connected to.
   */
  product: string
  [property: string]: any
}

/**
 * When a channel's (aka profile) queue sends out it's last post, resulting in that
 * channel's queue being newly empty
 */
export interface ChannelQueueEmptied {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The service's own id for this channel
   */
  channelServiceId?: string
  /**
   * The channel type, ex. "page", "business", "profile", "group", "creator"
   */
  channelType?: string
  /**
   * What is the username of the channel?
   */
  channelUsername?: string
  /**
   * The ID of the global organization that the channel was connected to.
   */
  organizationId: string
  [property: string]: any
}

/**
 * When a user reconnects, often after an access token is invalidated
 */
export interface ChannelReconnected {
  /**
   * API Version this channel uses
   */
  apiVersion?: string
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The service's own id for this channel
   */
  channelServiceId?: string
  /**
   * The type of channel the post is created for, ex. "page", "group"
   */
  channelType?: string
  /**
   * What is the username of the channel?
   */
  channelUsername?: string
  /**
   * Which client was the user in when the channel was reconnected?
   */
  clientName?: string
  /**
   * The ID of the global organization that the channel was reconnected to.
   */
  organizationId?: string
  /**
   * Used to check if the channel was migrated to new Pinterest API
   */
  pinterestNewApiMigrated?: boolean
  /**
   * The product for which the channel was connected
   */
  product: string
  /**
   * Twitter Subscription Type of a connected Twitter/X Channel
   */
  twitterSubscriptionType?: string
  [property: string]: any
}

/**
 * This event is currently triggered when the user click on the "Refresh" link for an
 * existing channel.
 */
export interface ChannelRefreshAttempted {
  /**
   * API Version this channel uses
   */
  apiVersion?: string
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The service's own id for this channel
   */
  channelServiceId: string
  /**
   * The type of channel the post is created for, ex. "page", "group"
   */
  channelType?: string
  /**
   * Which client was the user in when they took this action?
   */
  clientName: string
  /**
   * Which call to action brought the user to the channels page befor attempting the refresh?
   * (cta read from channels page URL)
   */
  cta?: null | string
  /**
   * Which product is the CTA located in? Examples would be either the product, like "publish".
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA? In many cases it makes sense to describe the
   * intended result of the CTA, like `proTrial` or `publishPro`.
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be "menu" for the Pro trial CTA in
   * Publish app shell menu.
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA? If setting the value for the first time, set to
   * "1".
   */
  ctaVersion?: null | string
  /**
   * What app view is the CTA located in? Examples would be, "composer" or "analyticsOverview"
   * for Publish.
   */
  ctaView?: null | string
  /**
   * The ID of the global organization for which the channel connection was attempted.
   */
  organizationId: string
  /**
   * The product for which the channel was connected
   */
  product: string
  [property: string]: any
}

/**
 * When a channel refresh attempt fails.
 */
export interface ChannelRefreshFailed {
  /**
   * API Version this channel uses
   */
  apiVersion?: string
  /**
   * The cause of the failure (i.e. error message).
   */
  cause: string
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The service's own id for this channel
   */
  channelServiceId: string
  /**
   * The type of channel the post is created for, ex. "page", "group"
   */
  channelType?: string
  /**
   * Which client was the user in when they took this action?
   */
  clientName: string
  /**
   * Which call to action brought the user to the channels page before refresh attempted? (cta
   * read from channels page URL)
   */
  cta?: null | string
  /**
   * Which product is the CTA located in? Examples would be either the product, like "publish".
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA? In many cases it makes sense to describe the
   * intended result of the CTA, like `proTrial` or `publishPro`.
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be "menu" for the Pro trial CTA in
   * Publish app shell menu.
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA? If setting the value for the first time, set to
   * "1".
   */
  ctaVersion?: null | string
  /**
   * What app view is the CTA located in? Examples would be, "composer" or "analyticsOverview"
   * for Publish.
   */
  ctaView?: null | string
  /**
   * The ID of the global organization for which the channel connection was attempted.
   */
  organizationId: string
  /**
   * The product in which the channel connection attempt occurred.
   */
  product: string
  [property: string]: any
}

/**
 * When the user clicks Refresh or Reconnect to refresh their channel.
 */
export interface ChannelRefreshStarted {
  /**
   * API Version this channel uses
   */
  apiVersion?: string
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * The number of channels connected to the user's account
   */
  currentChannelQuantity?: number | null
  /**
   * Is the user at their plan limit?
   */
  isAtPlanLimit?: boolean | null
  /**
   * The global organization ID of the user that clicked to start the refresh
   */
  organizationId: string
  /**
   * The product in which the refresh was started (ex. publish, buffer)
   */
  product?: string
  [property: string]: any
}

/**
 * When did the user view the channel refresh success modal?
 */
export interface ChannelRefreshSuccessModalViewed {
  /**
   * Which client was the user in when they opened this modal?
   */
  clientName: null | string
  /**
   * If the user navigated to this page from a CTA on another Buffer page, which call to
   * action was it?
   */
  cta?: null | string
  /**
   * Which product or website is the CTA located in? Examples would be either the product,
   * like `publish`, or the website, like `bufferSite`
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA?
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in
   * Publish app shell menu
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA?  If setting the value for the first time, set to
   * `1`
   */
  ctaVersion?: null | string
  /**
   * What website page or app view is the CTA located?  Examples would be, `composer` or
   * `analyticsOverview` for Publish, and `pricingPublish` for the Publish pricing page on the
   * Buffer marketing site
   */
  ctaView?: null | string
  /**
   * The current plan of the user at time of viewing the channel refresh success modal.
   */
  currentPlan?: null | string
  /**
   * The global organization ID of the user that viewed the channel refresh success modal.
   */
  organizationId: null | string
  [property: string]: any
}

/**
 * When a channel is selected by the user in Buffer.
 */
export interface ChannelSelected {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The database id for the channel
   */
  channelId: string
  /**
   * The service's own id for this channel
   */
  channelServiceId: string
  /**
   * The channel type, ex. "page", "business", "profile", "group"
   */
  channelType: string
  /**
   * What is the username of the channel?
   */
  channelUsername?: string
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * The global organization id of the user that took this action
   */
  organizationId: string
  /**
   * Where the channel was selected, ex "calendar", "queue", "sidebar"
   */
  page?: string
  /**
   * The product in which the event occurred.
   */
  product: string
  [property: string]: any
}

/**
 * When did the user click the "More Details" button/icon on the channel storefront?
 */
export interface ChannelStorefrontMoreDetailsClicked {
  /**
   * Which client was the user in when they clicked "More Details"?
   */
  clientName: null | string
  /**
   * The current plan of the user at time of clicking "More Details".
   */
  currentPlan?: null | string
  /**
   * The global organization ID of the user that clicked "More Details".
   */
  organizationId: null | string
  /**
   * The service name of the channel that the user clicked "More Details" for.
   */
  service: string
  [property: string]: any
}

/**
 * When did the user view the channel storefront?
 */
export interface ChannelStorefrontViewed {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * Which client was the user in when they opened this modal?
   */
  clientName: null | string
  /**
   * The number of channels connected to the user's account
   */
  currentChannelQuantity?: number | null
  /**
   * The current plan of the user at time of viewing the channel storefront.
   */
  currentPlan?: null | string
  /**
   * Is the user at their plan limit?
   */
  isAtPlanLimit?: boolean | null
  /**
   * The global organization ID of the user that viewed the channel storefront.
   */
  organizationId: null | string
  [property: string]: any
}

/**
 * When a user clicks on a channel tab filter
 */
export interface ChannelTabFilterClicked {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * The global organization id of the user
   */
  organizationId: string
  /**
   * Which channel filter tab was clicked?
   */
  tabName: string
  [property: string]: any
}

/**
 * When we first detect that a channel’s access token has become invalid. This usually
 * occurs when we try to publish a post or check a channel's analytics.
 */
export interface ChannelUnauthorized {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The service's own id for this channel
   */
  channelServiceId?: string
  /**
   * The type of channel the post is created for, ex. "page", "group"
   */
  channelType?: string
  /**
   * What is the username of the channel?
   */
  channelUsername?: string
  /**
   * Which client was the user in when this event was triggered?
   */
  clientName?: string
  /**
   * The ID of the organization that owns the channel.
   */
  organizationId?: string
  /**
   * The product for which the channel was connected
   */
  product: string
  [property: string]: any
}

/**
 * When did the user view the channel unlock modal?
 */
export interface ChannelUnlockModalViewed {
  /**
   * Which client was the user in when they opened this modal?
   */
  clientName: null | string
  /**
   * The current plan of the user at time of viewing the channel connection onboarding modal.
   */
  currentPlan?: null | string
  /**
   * The global organization ID of the user that viewed the channel connection onboarding
   * modal.
   */
  organizationId: null | string
  [property: string]: any
}

/**
 * When a channel is unlocked
 */
export interface ChannelUnlocked {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The service's own id for this channel
   */
  channelServiceId?: string
  /**
   * The channel type, ex. "page", "business", "profile", "group", "creator"
   */
  channelType?: string
  /**
   * What is the username of the channel?
   */
  channelUsername?: string
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * Was the organization at or above their channel limit when the channel was connected.
   */
  isAtPlanLimit?: boolean | null
  /**
   * The ID of the global organization that the channel was connected to.
   */
  organizationId?: string
  /**
   * The product the channel was connected to.
   */
  product: string
  [property: string]: any
}

/**
 * When a channel service makes a change that requires us to change the information stored
 * on the channel. For example, Instagram changed their internal IDs to a new format, and as
 * a result, we updated the IG service ID we stored for the new ones, and that’s when we
 * track this event.
 */
export interface ChannelUpdated {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The service's own id for this channel
   */
  channelServiceId?: string
  /**
   * The channel type, ex. "page", "business", "profile", "group"
   */
  channelType?: string
  /**
   * What is the username of the channel?
   */
  channelUsername?: string
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * Which call to action did the user click to update the channel?
   */
  cta?: null | string
  /**
   * Which product was the CTA located in? Example would be `publish`
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA?  In many cases it makes sense to describe the
   * intended result of the CTA, like `proTrial` or `publishPro`
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in
   * Publish app shell menu
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA?  If setting the value for the first time, set to
   * `1`
   */
  ctaVersion?: null | string
  /**
   * Which app view is the CTA located?  Examples would be, `composer` or `analyticsOverview`
   * for Publish
   */
  ctaView?: null | string
  /**
   * The ID of the global organization that owns the channel.
   */
  organizationId?: string
  /**
   * What was the previous service id for this channel?
   */
  previousChannelServiceId?: string
  /**
   * What was the previous channel type, ex. "profile", "business"?
   */
  previousChannelType?: null | string
  /**
   * What was the previous username of the channel?
   */
  previousChannelUsername?: string
  /**
   * The product for which the channel was updated
   */
  product: string
  [property: string]: any
}

/**
 * When a list of channels is reordered.
 */
export interface ChannelsReordered {
  /**
   * Which client was the user in when they took this action?
   */
  clientName: string
  /**
   * The global organization id of the user that took this action.
   */
  organizationId: string
  /**
   * The product in which the event occurred.
   */
  product: string
  [property: string]: any
}

/**
 * When a user clicks on a checklist item
 */
export interface ChecklistItemClicked {
  /**
   * The ID of the checklist
   */
  checklistId: number
  /**
   * The checklist item on which the user clicked
   */
  checklistItem: string
  /**
   * The checklist item completion status when clicked
   */
  isCompleted: boolean | null
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * How many checklist items has the user completed?
   */
  qtyItemsCompleted?: number | null
  [property: string]: any
}

/**
 * When a user views the checklist complete modal
 */
export interface ChecklistModalViewed {
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  [property: string]: any
}

/**
 * When did a user click on a CTA on the churn survey modal?
 */
export interface ChurnSurveyCTAClicked {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The CTA clicked on the churn survey modal
   */
  ctaName: string
  /**
   * The global organization ID of the user
   */
  organizationId: string
  [property: string]: any
}

/**
 * When a user completes a churn survey for a particular product
 */
export interface ChurnSurveyCompleted {
  /**
   * The plan id of the subscription that was cancelled.
   */
  billingPlan?: null | string
  /**
   * A free form text field where the user can add more context for them cancelling their
   * subscription
   */
  details?: string
  /**
   * The organization ID of the user that completed the survey
   */
  organizationId?: string
  /**
   * What product did this event occur for?
   */
  product: string
  /**
   * What's the reason for the user churning
   */
  reason: string
  /**
   * Was the user on a trial when the subscription was canceled?
   */
  wasOnTrial?: boolean | null
  [property: string]: any
}

/**
 * When did a user view the churn survey modal?
 */
export interface ChurnSurveyViewed {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The global organization ID of the user
   */
  organizationId: string
  [property: string]: any
}

/**
 * When a comment is deleted in the engagement tool.
 */
export interface CommentDeleted {
  /**
   * The channel that the comment belongs to, e.g. "facebook"
   */
  channel?: string
  /**
   * The database id for the channel.
   */
  channelId: string
  /**
   * The service's own id for this channel.
   */
  channelServiceId?: string
  /**
   * What is the username of the channel?
   */
  channelUsername?: string
  /**
   * The unique identifier of the comment.
   */
  commentId: string
  /**
   * Does the comment have any labels?
   */
  hasLabel: boolean
  /**
   * The types of lables associated with the comment (e.g. "upset/order/question")
   */
  labelTypes: any[]
  /**
   * The global organization id of the user that owns this channel
   */
  organizationId: string
  /**
   * The unique identifier of the post.
   */
  postId: string
  /**
   * The product in which the event occurred.
   */
  product: string
  /**
   * Was a hotkey used to delete this comment?
   */
  usedHotKey?: boolean
  [property: string]: any
}

/**
 * When a comment is marked as dismissed.
 */
export interface CommentDismissed {
  /**
   * The channel that the comment belongs to, e.g. "facebook"
   */
  channel?: string
  /**
   * The database id for the channel.
   */
  channelId: string
  /**
   * The service's own id for this channel.
   */
  channelServiceId?: string
  /**
   * What is the username of the channel?
   */
  channelUsername?: string
  /**
   * The unique identifier of the comment.
   */
  commentId: string
  /**
   * Does the comment have any labels?
   */
  hasLabel: boolean
  /**
   * The types of lables associated with the comment (e.g. "upset/order/question")
   */
  labelTypes: any[]
  /**
   * The global organization id of the user that owns this channel
   */
  organizationId: string
  /**
   * The unique identifier of the post.
   */
  postId: string
  /**
   * The product in which the event occurred.
   */
  product: string
  /**
   * The total number of comments for the post.
   */
  totalComments: number
  /**
   * The total number of unanswered comments remaining for the post.
   */
  totalUnansweredComments: number
  /**
   * Was a hotkey used to mark this comment as dismissed?
   */
  usedHotKey: boolean
  [property: string]: any
}

/**
 * When there is an error replying to a comment
 */
export interface CommentFailed {
  /**
   * The channel of the selected profile, ex. “instagram”.
   */
  channel: string
  /**
   * The name of the client in which the event occurred.
   */
  clientName: string
  /**
   * The global organization ID of the user that triggered the event.
   */
  organizationId: string
  /**
   * The product in which the event occurred, ex. engage.
   */
  product: string
  [property: string]: any
}

/**
 * When a comment is marked as spam in the engagement tool.
 */
export interface CommentMarkedAsSpam {
  /**
   * The channel that the comment belongs to, e.g. "facebook"
   */
  channel?: string
  /**
   * The database id for the channel.
   */
  channelId: string
  /**
   * The service's own id for this channel.
   */
  channelServiceId?: string
  /**
   * What is the username of the channel?
   */
  channelUsername?: string
  /**
   * The unique identifier of the comment.
   */
  commentId: string
  /**
   * Does the comment have any labels?
   */
  hasLabel: boolean
  /**
   * The types of lables associated with the comment (e.g. "upset/order/question")
   */
  labelTypes: any[]
  /**
   * The global organization id of the user that owns this channel
   */
  organizationId: string
  /**
   * The unique identifier of the post.
   */
  postId: string
  /**
   * The product in which the event occurred.
   */
  product: string
  /**
   * Was a hotkey used to mark this comment as spam?
   */
  usedHotKey: boolean
  [property: string]: any
}

/**
 * When a comment is replied to.
 */
export interface CommentReplied {
  /**
   * The channel that the comment belongs to, e.g. "facebook"
   */
  channel?: string
  /**
   * The database id for the channel.
   */
  channelId: string
  /**
   * The service's own id for this channel.
   */
  channelServiceId?: string
  /**
   * What is the username of the channel?
   */
  channelUsername?: string
  /**
   * The unique identifier of the comment.
   */
  commentId: string
  /**
   * Does the response include an emoji?
   */
  hasEmoji: boolean
  /**
   * Does the comment have any labels?
   */
  hasLabel: boolean
  /**
   * The types of lables associated with the comment (e.g. "upset/order/question")
   */
  labelTypes: any[]
  /**
   * The global organization id of the user that took this action
   */
  organizationId: string
  /**
   * The unique identifier of the post.
   */
  postId: string
  /**
   * The product in which the event occurred.
   */
  product: string
  /**
   * The total number of comments for the post.
   */
  totalComments: number
  /**
   * The total number of unanswered comments remaining for the post.
   */
  totalUnansweredComments: number
  /**
   * Did the user use the emoji picker?
   */
  usedEmojiPicker: boolean
  [property: string]: any
}

/**
 * When a comment is selected.
 */
export interface CommentSelected {
  /**
   * The channel that the comment belongs to, e.g. "facebook"
   */
  channel?: string
  /**
   * The database id for the channel.
   */
  channelId: string
  /**
   * The service's own id for this channel.
   */
  channelServiceId?: string
  /**
   * What is the username of the channel?
   */
  channelUsername?: string
  /**
   * The unique identifier of the comment.
   */
  commentId: string
  /**
   * Does the comment have any labels?
   */
  hasLabel: boolean
  /**
   * The types of lables associated with the comment (e.g. "upset/order/question")
   */
  labelTypes: any[]
  /**
   * The global organization id of the user that owns the channel.
   */
  organizationId: string
  /**
   * The unique identifier of the post.
   */
  postId: string
  /**
   * The product in which the event occurred.
   */
  product: string
  /**
   * Was a hotkey used to mark this comment as read?
   */
  usedHotKey: boolean
  [property: string]: any
}

/**
 * A user joined our Mobilize commmunity portal
 */
export interface CommunityJoined {
  /**
   * This property is added as a workaround to generate type for events without properties
   */
  _?: null
  properties: any
  [property: string]: any
}

/**
 * Track when a specific component is rendered, always use with useComponentRendered hook to
 * ensure events are throttled
 */
export interface ComponentRendered {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * Channel of the page, if applicable (e.g., ""facebook"", ""instagram"", etc.)
   */
  channel?: null | string
  /**
   * The database id for the channel document
   */
  channelId?: null | string
  /**
   * The id of the channel on the given service
   */
  channelServiceId?: null | string
  /**
   * What is the type of channel? ex. "page", "group"
   */
  channelType?: null | string
  /**
   * If the user navigated to this page from a CTA on another Buffer page, which call to
   * action was it?
   */
  cta?: null | string
  /**
   * Is the component deprecated?
   */
  isDeprecated?: boolean | null
  /**
   * Human readable name of the page (e.g., ""<Composer />"", ""<LegacyCalendar />"", etc.)
   */
  name?: null | string
  /**
   * The global organization ID of the user that viewed the page.
   */
  organizationId?: null | string
  /**
   * The path typically refers to a file or directory on the web server.
   */
  path: null | string
  /**
   * The platform on which the page view occurred (e.g. ""classic"", ""new_publish"",
   * ""marketing"", ""ios"")
   */
  platform?: null | string
  /**
   * The product in which the page view occurred (e.g. ""publish"", ""analyze"")
   */
  product?: string
  /**
   * At time of event, does the user have a unique ReferralCode cookie in their session?
   */
  referralCode?: null | string
  /**
   * The address of the webpage which is linked to the resource being requested. By checking
   * the referrer, the new webpage can see where the request originated.
   */
  referrer?: null | string
  /**
   * Anything after and including the query string.
   */
  search?: null | string
  /**
   * The text that is visible on the browser title bar (or tab bar) which matches the
   * <title></title> html tag that should be on all pages.
   */
  title?: null | string
  /**
   * The address of the page.
   */
  url?: string
  [property: string]: any
}

/**
 * When a new user opens the Customizer within the composer.
 */
export interface ComposerCustomizerOpened {
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * Which call to action did the user click to get to open the composer?
   */
  cta?: string
  /**
   * Which product is the CTA located in? Examples would be `publish`.
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA? In many cases it makes sense to describe the
   * intended result of the CTA, like `createAPost` or `publishProTrial`.
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in
   * Publish app shell menu.
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA? If setting the value for the first time, set to
   * `1`.
   */
  ctaVersion?: null | string
  /**
   * What app view is the CTA located in? Examples would be `publishDashboard` or
   * `analyticsOverview` for Publish.
   */
  ctaView?: null | string
  /**
   * The organization id of the user that took the action
   */
  organizationId: string
  /**
   * The product in which the action occurred
   */
  product: string
  [property: string]: any
}

/**
 * When a user closes the Publish composer by discarding it.
 */
export interface ComposerDiscarded {
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The organization id of the user that took the action
   */
  organizationId: string
  /**
   * The product in which the action occurred
   */
  product: string
  [property: string]: any
}

/**
 * A user has reordered the media in the composer
 */
export interface ComposerMediaReordered {
  /**
   * The channel type where the media was reordered
   */
  channel: string
  /**
   * The channel id where the media was reordered
   */
  channelId: string
  /**
   * Which client was the user in when the Post was created?
   */
  clientName: string
  /**
   * If the post is a draft
   */
  isDraft: boolean
  /**
   * If the post is a reminder
   */
  isReminder: boolean
  /**
   * Count of attachmed media (images or videos)
   */
  mediaCount: number
  /**
   * Initial position of the reordered image
   */
  orderSource: number
  /**
   * Target position of the reordered image
   */
  orderTarget: number
  /**
   * The global organization id that the channel belongs to.
   */
  organizationId: string
  /**
   * The product in which the action occured
   */
  product: string
  /**
   * Type of the update, e.g. story or reel
   */
  updateType: string
  [property: string]: any
}

/**
 * When a new user opens the Publish composer.
 */
export interface ComposerOpened {
  /**
   * How many channels are connected to the user's account?
   */
  channelsConnected?: number | null
  /**
   * How many channels are selected in the composer?
   */
  channelsSelected?: number | null
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * Which call to action did the user click to get to open the composer?
   */
  cta?: string
  /**
   * Which product is the CTA located in? Examples would be `publish`.
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA? In many cases it makes sense to describe the
   * intended result of the CTA, like `createAPost` or `publishProTrial`.
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in
   * Publish app shell menu.
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA? If setting the value for the first time, set to
   * `1`.
   */
  ctaVersion?: null | string
  /**
   * What app view is the CTA located in? Examples would be `publishDashboard` or
   * `analyticsOverview` for Publish.
   */
  ctaView?: null | string
  /**
   * The organization id of the user that took the action
   */
  organizationId: string
  /**
   * The product in which the action occurred
   */
  product: string
  [property: string]: any
}

/**
 * A tool tip was presented in the initial composer experience.
 */
export interface ComposerToolTipViewed {
  /**
   * What was the unique identifier of the client the user was in when they added the widget?
   */
  clientId?: null | string
  /**
   * Which client was the user in when they added the widget? IE, `publishIos` or
   * `publishAndroid`.
   */
  clientName: string
  /**
   * The organization ID of the user that added the widget.
   */
  organizationId: string
  /**
   * The contents of the tool tip.
   */
  toolTip: string
  [property: string]: any
}

/**
 * When did a user click on a CTA on the confirm cancellation modal?
 */
export interface ConfirmCancellationCTAClicked {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The CTA clicked on the confirm cancellation modal
   */
  ctaName: string
  /**
   * The global organization ID of the user
   */
  organizationId: string
  [property: string]: any
}

/**
 * When did a user click on a CTA on the confirm cancellation modal?
 */
export interface ConfirmCancellationViewed {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The global organization ID of the user
   */
  organizationId: string
  [property: string]: any
}

/**
 * When did the user view the billing connect channel upgrade module?
 */
export interface ConnectChannelUpgradeModalViewed {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * Which client was the user in when they opened this modal?
   */
  clientName: null | string
  /**
   * If the user navigated to this page from a CTA on another Buffer page, which call to
   * action was it?
   */
  cta?: null | string
  /**
   * Which product or website is the CTA located in? Examples would be either the product,
   * like `publish`, or the website, like `bufferSite`
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA?
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in
   * Publish app shell menu
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA?  If setting the value for the first time, set to
   * `1`
   */
  ctaVersion?: null | string
  /**
   * What website page or app view is the CTA located?  Examples would be, `composer` or
   * `analyticsOverview` for Publish, and `pricingPublish` for the Publish pricing page on the
   * Buffer marketing site
   */
  ctaView?: null | string
  /**
   * The current plan of the user at time of viewing the connect channel upgrade modal.
   */
  currentPlan?: null | string
  /**
   * The global organization ID of the user that viewed the connect channel upgrade modal.
   */
  organizationId: null | string
  /**
   * Which product did the user view the modal in?
   */
  product: null | string
  /**
   * The name of the upgrade path
   */
  upgradePathName: null | string
  [property: string]: any
}

/**
 * When a user accepts the content generated with AI
 */
export interface ContentGenerationAccepted {
  /**
   * Which call to action did the user click
   */
  cta?: null | string
  /**
   * The specific AI action that was used to generate the accepted content, e.g. generateText,
   * summarizeText, etc.
   */
  featureAction?: null | string
  /**
   * The active organization of the user that took the action
   */
  organizationId?: string
  /**
   * The type of content that was accepted
   */
  type: string
  [property: string]: any
}

/**
 * When a user cancels the process of content generation
 */
export interface ContentGenerationCancelled {
  /**
   * Which call to action did the user click
   */
  cta?: null | string
  /**
   * The specific AI action that was used to generate before cancelling, e.g. generateText,
   * summarizeText, etc.
   */
  featureAction?: null | string
  /**
   * The active organization of the user that took the action
   */
  organizationId: string
  /**
   * The type of content that was being generated when it got cancelled
   */
  type: string
  [property: string]: any
}

/**
 * When a user receives an error after a content generation request
 */
export interface ContentGenerationFailed {
  /**
   * The AI model used
   */
  aiModel: string
  /**
   * Which call to action did the user click to generate content
   */
  cta?: null | string
  /**
   * The code of the error received
   */
  errorCode?: null | string
  /**
   * The type of the error received
   */
  errorType: string
  /**
   * The specific AI action that trigger the content generation, e.g. generateText,
   * summarizeText, etc.
   */
  featureAction?: null | string
  /**
   * The marketing ID of the user generated from buffer.com
   */
  marketingId?: null | string
  /**
   * The active organization of the user that took the action
   */
  organizationId?: null | string
  /**
   * The 3rd party service that we use to generate content. Initially we will be using
   * "openai", but there could be more, e.g. openai, stabilityai, etc.
   */
  provider: string
  /**
   * From where did the content generation request originate (via free tools, in-product, etc)
   */
  requestSource?: string
  /**
   * The time it took until errored, from request to response, in seconds.
   */
  responseTime?: number
  /**
   * The type of content requested
   */
  type: string
  /**
   * The number of variations requested for this type
   */
  variations: number
  [property: string]: any
}

/**
 * When a user receives AI generated content after a request
 */
export interface ContentGenerationReceived {
  /**
   * The AI model used
   */
  aiModel: string
  /**
   * The number of tokens the content generation used. This is applicable only to completion -
   * text2text
   */
  completionTokens?: number | null
  /**
   * Which call to action did the user click to generate content
   */
  cta?: null | string
  /**
   * The specific AI action that trigger the content generation, e.g. generateText,
   * summarizeText, etc.
   */
  featureAction?: null | string
  /**
   * The size of the generated images, e.g. "256x256", "1024x1024", etc. This is applicable
   * only to images - text2image
   */
  imageSize?: null | string
  /**
   * The marketing ID of the user generated from buffer.com
   */
  marketingId?: null | string
  /**
   * The active organization of the user that took the action
   */
  organizationId?: null | string
  /**
   * The number of tokens the prompt used. This is applicable only to completion - text2text
   */
  promptTokens?: number | null
  /**
   * The 3rd party service that we use to generate content. Initially we will be using
   * "openai", but there could be more, e.g. openai, stabilityai, etc.
   */
  provider: string
  /**
   * From where did the content generation request originate (via free tools, in-product, etc)
   */
  requestSource?: string
  /**
   * The time it took to generate content, from request to response, in seconds.
   */
  responseTime?: number
  /**
   * The number of total tokens the content generation used (prompt + completion). This is
   * applicable only to completion - text2text
   */
  totalTokens?: number | null
  /**
   * The type of content requested
   */
  type: string
  /**
   * The number of variations requested for this type
   */
  variations: number
  [property: string]: any
}

/**
 * When a user requests AI content generation
 */
export interface ContentGenerationRequested {
  /**
   * The AI model used
   */
  aiModel: string
  /**
   * Which call to action did the user click to generate content
   */
  cta?: null | string
  /**
   * The specific AI action that trigger the content generation, e.g. generateText,
   * summarizeText, etc.
   */
  featureAction?: null | string
  /**
   * The marketing ID of the user generated from buffer.com
   */
  marketingId?: null | string
  /**
   * The active organization of the user that took the action
   */
  organizationId?: null | string
  /**
   * The 3rd party service that we use to generate content. Initially we will be using
   * "openai", but there could be more, e.g. openai, stabilityai, etc.
   */
  provider: string
  /**
   * From where did the content generation request originate (via free tools, in-product, etc)
   */
  requestSource?: string
  /**
   * The type of content requested
   */
  type: string
  /**
   * The number of variations requested for this type
   */
  variations: number
  [property: string]: any
}

/**
 * When a new content groups is created in the create space.
 */
export interface ContentGroupCreated {
  /**
   * The client in which the event occurred.
   */
  clientName?: null | string
  /**
   * The ID of the group.
   */
  contentGroupId: string
  /**
   * The name of the group.
   */
  contentGroupName: string
  /**
   * Which source/cta was used to trigger this event
   */
  cta?: string
  /**
   * Which website is the CTA located in? Ex, `bufferSite`
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA?  In many cases it makes sense to describe the
   * intended result of the CTA, like `proTrial` or `publishPro`
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be, `cards` for the Pro trial CTA
   * in Publish pricing page main plan comparison section
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA?  If setting the value for the first time, set to
   * `1`
   */
  ctaVersion?: null | string
  /**
   * Which website page is the CTA located?  Ex,  `pricingPublish` for the Publish pricing page
   */
  ctaView?: null | string
  /**
   * The global organization ID.
   */
  organizationId: string
  /**
   * Which product the event is triggered from
   */
  product: string
  [property: string]: any
}

/**
 * When a new content groups is deleted in the create space.
 */
export interface ContentGroupDeleted {
  /**
   * The client in which the event occurred.
   */
  clientName?: null | string
  /**
   * The ID of the group.
   */
  contentGroupId: string
  /**
   * The name of the group.
   */
  contentGroupName: string
  /**
   * Which source/cta was used to trigger this event
   */
  cta?: string
  /**
   * Which website is the CTA located in? Ex, `bufferSite`
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA?  In many cases it makes sense to describe the
   * intended result of the CTA, like `proTrial` or `publishPro`
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be, `cards` for the Pro trial CTA
   * in Publish pricing page main plan comparison section
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA?  If setting the value for the first time, set to
   * `1`
   */
  ctaVersion?: null | string
  /**
   * Which website page is the CTA located?  Ex,  `pricingPublish` for the Publish pricing page
   */
  ctaView?: null | string
  /**
   * The global organization ID.
   */
  organizationId: string
  /**
   * Which product the event is triggered from
   */
  product: string
  [property: string]: any
}

/**
 * When a content content group is moved.
 */
export interface ContentGroupMoved {
  /**
   * The client in which the event occurred.
   */
  clientName?: null | string
  /**
   * The ID of the group.
   */
  contentGroupId: string
  /**
   * The name of the group.
   */
  contentGroupName: string
  /**
   * Which source/cta was used to trigger this event
   */
  cta?: string
  /**
   * Which website is the CTA located in? Ex, `bufferSite`
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA?  In many cases it makes sense to describe the
   * intended result of the CTA, like `proTrial` or `publishPro`
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be, `cards` for the Pro trial CTA
   * in Publish pricing page main plan comparison section
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA?  If setting the value for the first time, set to
   * `1`
   */
  ctaVersion?: null | string
  /**
   * Which website page is the CTA located?  Ex,  `pricingPublish` for the Publish pricing page
   */
  ctaView?: null | string
  /**
   * The global organization ID.
   */
  organizationId: string
  /**
   * Which product the event is triggered from
   */
  product: string
  [property: string]: any
}

/**
 * When a new content groups is created in the create space.
 */
export interface ContentGroupRenamed {
  /**
   * The client in which the event occurred.
   */
  clientName?: null | string
  /**
   * The ID of the group.
   */
  contentGroupId: string
  /**
   * The new name of the group.
   */
  contentGroupName: string
  /**
   * Which source/cta was used to trigger this event
   */
  cta?: string
  /**
   * Which website is the CTA located in? Ex, `bufferSite`
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA?  In many cases it makes sense to describe the
   * intended result of the CTA, like `proTrial` or `publishPro`
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be, `cards` for the Pro trial CTA
   * in Publish pricing page main plan comparison section
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA?  If setting the value for the first time, set to
   * `1`
   */
  ctaVersion?: null | string
  /**
   * Which website page is the CTA located?  Ex,  `pricingPublish` for the Publish pricing page
   */
  ctaView?: null | string
  /**
   * The global organization ID.
   */
  organizationId: string
  /**
   * The name of the group before it was renamed.
   */
  previousContentGroupName: string
  /**
   * Which product the event is triggered from
   */
  product: string
  [property: string]: any
}

/**
 * Was there a credit note created for this invoice?
 */
export interface CreditNoteCreated {
  /**
   * The dollar value of this credit note
   */
  creditNoteValue?: number
  /**
   * Unique identifier for the Stripe customer
   */
  customerId: string
  /**
   * The subscription payment interval (month or year) of the subscription associated with the
   * invoice
   */
  cycle?: string
  /**
   * The ID of the organization for which the invoice was created
   */
  organizationId: string
  /**
   * Stripe plan id of the subscription associated with the invoice
   */
  planId?: string
  /**
   * Human-readable plan name of the subscription associated with the invoice
   */
  planName?: string
  /**
   * The URL of the credit note PDF
   */
  receiptLink?: string
  /**
   * Stripe event id from the webhook event
   */
  stripeEventId: string
  /**
   * Unique identifier for the Stripe subscription
   */
  subscriptionId: string
  /**
   * The total tax amount (in percent) refunded in this credit note
   */
  taxAmount?: number
  [property: string]: any
}

/**
 * When a user authorizes the bitly connection for custom shorten URLs.
 */
export interface CustomBitlyConnectButtonClicked {
  /**
   * The channel that settings tab was opened for (ex. instagram, pinterest)
   */
  channel: string
  /**
   * The channelId that settings tab was opened for (ex. instagram, pinterest)
   */
  channelId: string
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The name of the CTA clicked.
   */
  cta: string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product: string
  [property: string]: any
}

/**
 * When a user disconnects the bitly integration for custom shorten URLs.
 */
export interface CustomBitlyDisconnectButtonClicked {
  /**
   * The channel that settings tab was opened for (ex. instagram, pinterest)
   */
  channel: string
  /**
   * The channelId that settings tab was opened for (ex. instagram, pinterest)
   */
  channelId: string
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The name of the CTA clicked.
   */
  cta: string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product: string
  [property: string]: any
}

/**
 * When a user completes the Custom Channel input form
 */
export interface CustomChannelSetupCompleted {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * The number of channels connected to the user's account
   */
  currentChannelQuantity?: number | null
  /**
   * Is the user at their plan limit?
   */
  isAtPlanLimit?: boolean | null
  /**
   * The global organization ID of the user that completed the Custom Channel input form
   */
  organizationId: string
  /**
   * The product in which the Custom Channel input form was completes (ex. publish, buffer)
   */
  product?: string
  [property: string]: any
}

/**
 * When a user views the Custom Channel input form
 */
export interface CustomChannelSetupViewed {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * The number of channels connected to the user's account
   */
  currentChannelQuantity?: number | null
  /**
   * Is the user at their plan limit?
   */
  isAtPlanLimit?: boolean | null
  /**
   * The global organization ID of the user that viewed the Custom Channel input form
   */
  organizationId: string
  /**
   * The product in which the Custom Channel input form was viewed (ex. publish, buffer)
   */
  product?: string
  [property: string]: any
}

/**
 * When there is an error fetching comments data.
 */
export interface DataIngestionFailed {
  /**
   * The channel that we attempted to retrieve data from, e.g. "instagram".
   */
  channel: string
  /**
   * The name of the client in which the event occurred.
   */
  clientName: string
  /**
   * The type of data that we failed to ingest, e.g. "post" or "comment".
   */
  dataType: string
  /**
   * What caused the failure to ingest data?
   */
  failureCause: string
  /**
   * The global organization ID of the user that triggered the event.
   */
  organizationId: string
  /**
   * The product in which the event occurred, ex. engage.
   */
  product: string
  [property: string]: any
}

/**
 * When user selects a date range in Analyze
 */
export interface DateRangeSelected {
  /**
   * The channel of the selected profile, ex. “instagram”.
   */
  channel: string
  /**
   * The ID of the channel selected when the date range was selected
   */
  channelId: string
  /**
   * The name of the client in which the action occurred.
   */
  clientName: string
  /**
   * If a custom date range was selected, what were the dates?
   */
  customDateRangeSelected?: null | string
  /**
   * What was the date range selected, ex. “last month”, “last week”, “custom”?
   */
  dateRangeSelected: string
  /**
   * The global organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The product in which the event occurred, ex. “analyze”.
   */
  product: string
  [property: string]: any
}

/**
 * When a user viewed a dialog
 */
export interface DialogViewed {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: null | string
  /**
   * Name of the dialog that was viewed
   */
  dialogName: string
  /**
   * The global organization ID of the user
   */
  organizationId: string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product: null | string
  [property: string]: any
}

/**
 * When did the customer create a dispute on a Stripe charge?
 */
export interface DisputeCreated {
  /**
   * Unique identifier for the Stripe customer
   */
  customerId: string
  /**
   * The ID of the organization for which the tax ID was created
   */
  organizationId: string
  /**
   * The reason for dispute as returned from Stripe
   */
  reason?: null | string
  /**
   * Stripe event id from the webhook event
   */
  stripeEventId: string
  [property: string]: any
}

/**
 * This event is to be used by Customer.io to send an email to the user whose draft has been
 * approved.
 */
export interface DraftApprovalNotified {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The type of channel the post is created for, ex. "page", "group"
   */
  channelType: string
  /**
   * The username of the channel that the draft was created for. This can be used to customize
   * the message in the email.
   */
  channelUsername: string
  /**
   * Which client was the user in when they took this action?
   */
  clientName: string
  /**
   * What type of media did this post have attached? ex. "image", "video"
   */
  mediaType?: null | string
  /**
   * The organization ID of the user whose draft was approved.
   */
  organizationId: string
  /**
   * The database id for the draft document
   */
  postId: string
  /**
   * The product in which the action occured
   */
  product: string
  [property: string]: any
}

/**
 * This event is solely for the purpose of sending an email with Customer.io to those with
 * the permissions to approve or reject a draft.
 */
export interface DraftApprovalRequiredNotified {
  /**
   * The channel's service, ex. "facebook", "instagram"
   */
  channel: string
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The type of channel the post is created for, ex. "page", "group"
   */
  channelType: string
  /**
   * The username of the channel that the draft was created for. This can be used to customize
   * the message in the email.
   */
  channelUsername: string
  /**
   * Which client was the user in when they took this action? ex "publishIos", "publishWeb"
   */
  clientName: string
  /**
   * The email address of the contributor that created the draft
   */
  contributorEmail?: string
  /**
   * The text of the draft that requires approval
   */
  draftText?: string
  /**
   * What type of media did this post have attached? ex. "image", "video"
   */
  mediaType?: null | string
  /**
   * The organization id of the user who is to receive the email.
   */
  organizationId: string
  /**
   * The database id for the draft document
   */
  postId: string
  /**
   * The product in which the draft was created.
   */
  product: string
  [property: string]: any
}

/**
 * A user has approved a draft to be sent out
 */
export interface DraftApproved {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The id of the channel on the given service
   */
  channelServiceId: string
  /**
   * The type of channel the post is created for, ex. "page", "group"
   */
  channelType: string
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * What type of media did this post have attached? ex. "image", "video"
   */
  mediaType?: null | string
  /**
   * The ID of the Global organization that owns the profile that the draft was created for.
   */
  organizationId?: string
  /**
   * The database id for the draft document
   */
  postId: string
  /**
   * The product in which the action occured
   */
  product: string
  [property: string]: any
}

/**
 * A user has added a comment for a draft for a given channel
 */
export interface DraftCommentAdded {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The database id for the comment
   */
  commentId: string
  /**
   * The ID of the Global organization that owns the profile that the draft was created for.
   */
  organizationId?: string
  /**
   * The database id for the draft document
   */
  postId: string
  /**
   * The product in which the action occured
   */
  product: string
  [property: string]: any
}

/**
 * A user has deleted a comment for a draft for a given channel
 */
export interface DraftCommentDeleted {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The database id for the comment
   */
  commentId: string
  /**
   * The ID of the Global organization that owns the profile that the draft was created for.
   */
  organizationId?: string
  /**
   * The database id for the draft document
   */
  postId: string
  /**
   * The product in which the action occured
   */
  product: string
  [property: string]: any
}

/**
 * A user has deleted a draft for a given channel
 */
export interface DraftDeleted {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The id of the channel on the given service
   */
  channelServiceId: string
  /**
   * The type of channel the post is created for, ex. "page", "group"
   */
  channelType: string
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * Whether this is a Thread post for twitter or a regular post
   */
  isThreadPost?: boolean
  /**
   * What type of media did this post have attached? ex. "image", "video"
   */
  mediaType?: null | string
  /**
   * The ID of the Global organization that owns the profile that the draft was created for.
   */
  organizationId?: string
  /**
   * The database id for the draft document
   */
  postId: string
  /**
   * The product in which the action occured
   */
  product: string
  /**
   * If Threads post, how many threaded updates it contains
   */
  threadedUpdatesCount?: number | null
  /**
   * Total number of link attachments for this post (including threaded updates)
   */
  totalLinkCount?: number
  /**
   * Total number of media uploaded for this post (including threaded updates)
   */
  totalMediaCount?: number
  [property: string]: any
}

/**
 * This event occurs whenever a draft is rejected
 */
export interface DraftRejected {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The id of the channel on the given service
   */
  channelServiceId: string
  /**
   * The type of channel the post is created for, ex. "page", "group"
   */
  channelType: string
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * What type of media did this post have attached? ex. "image", "video"
   */
  mediaType?: null | string
  /**
   * The ID of the Global organization that owns the profile that the draft was created for.
   */
  organizationId?: string
  /**
   * The database id for the draft document
   */
  postId: string
  /**
   * The product in which the action occured
   */
  product: string
  [property: string]: any
}

/**
 * This event is to be used by Customer.io to send an email to the user whose draft has been
 * rejected.
 */
export interface DraftRejectionNotified {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The link to the channel avatar to be used in the notification email
   */
  channelAvatar?: string
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The type of channel the post is created for, ex. "page", "group"
   */
  channelType: string
  /**
   * The username of the channel that the draft was created for. This can be used to customize
   * the message in the email.
   */
  channelUsername: string
  /**
   * Which client was the user in when they took this action?
   */
  clientName: string
  /**
   * The text of the rejected draft.
   */
  draftText?: string
  /**
   * What type of media did this post have attached? ex. "image", "video"
   */
  mediaType?: null | string
  /**
   * The organization ID of the user whose draft was rejected.
   */
  organizationId: string
  /**
   * The database id for the draft document
   */
  postId: string
  /**
   * The product in which the action occured, ex "publish".
   */
  product: string
  /**
   * The email address of the person that rejected the draft.
   */
  rejectorEmail?: string
  [property: string]: any
}

/**
 * A user has submitted a draft for approval
 */
export interface DraftSubmitted {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The id of the channel on the given service
   */
  channelServiceId: string
  /**
   * The type of channel the post is created for, ex. "page", "group"
   */
  channelType: string
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * What type of media did this post have attached? ex. "image", "video"
   */
  mediaType?: null | string
  /**
   * The ID of the Global organization that owns the profile that the draft was created for.
   */
  organizationId?: string
  /**
   * The database id for the draft document
   */
  postId: string
  /**
   * The product in which the action occured
   */
  product: string
  [property: string]: any
}

/**
 * When the user interacts with a dynamic product notice.
 */
export interface DynamicNoticeActionPerformed {
  /**
   * What action did the user perform on the notice?
   */
  actionPerformed: string
  /**
   * The channel's service, ex. "instagram"
   */
  channel: string
  /**
   * What was the unique identifier of the client the user was in when they interacted with
   * the notice?
   */
  clientId?: null | string
  /**
   * The mobile client the user is on, `publishIos` or `publishAndroid`.
   */
  clientName: string
  /**
   * Unique identifier of the notice that is interacted with
   */
  id: string
  /**
   * Which Buffer product was the notice for?
   */
  product: string
  /**
   * Type of notice ex. "dialog"
   */
  type: string
  [property: string]: any
}

/**
 * When the user views a dynamic product notice.
 */
export interface DynamicNoticeViewed {
  /**
   * The channel's service, ex. "instagram"
   */
  channel: string
  /**
   * What was the unique identifier of the client the user was in when they viewed the notice?
   */
  clientId?: null | string
  /**
   * The mobile client the user is on, `publishIos` or `publishAndroid`.
   */
  clientName: string
  /**
   * Unique identifier of the notice that is viewed
   */
  id: string
  /**
   * Which Buffer product was the notice for?
   */
  product: string
  /**
   * Type of notice ex. "dialog"
   */
  type: string
  [property: string]: any
}

/**
 * When did the user connect an ecommerce platform?
 */
export interface EcommercePlatformConnected {
  /**
   * The client in which the action occurred.
   */
  clientName: string
  /**
   * What ecommerce platform was the product selected from? ex. "shopify"
   */
  platform: string
  /**
   * The product in which the event occurred, ex. “remix”.
   */
  product: string
  [property: string]: any
}

/**
 * When a user updates their email.
 */
export interface EmailUpdated {
  /**
   * The new email of the user.
   */
  newEmail: string
  [property: string]: any
}

/**
 * When a user starts the email verification process.
 */
export interface EmailVerificationInitiated {
  /**
   * The scenario that started the email verification process.
   */
  action: string
  /**
   * The current email of the user.
   */
  email: string
  /**
   * The new email of the user.
   */
  newEmail?: string
  /**
   * The link that the user has to click to get verified.
   */
  verificationLink: string
  [property: string]: any
}

/**
 * When a user verifies their email address. There are 4 ways to verifiy an email address:
 * - Clicking the link in the email users receive on sign-up.
 * - Clicking the link in the email users receive after asking to resend a new one.
 * - After completing the password change flow.
 * - After completing the email change flow.
 */
export interface EmailVerified {
  /**
   * The current email of the user.
   */
  currentEmail: string
  [property: string]: any
}

/**
 * When Engage an Engage user's data is deleted due to inactivity.
 */
export interface EngageDataDisabled {
  /**
   * The channel whose data was deleted, e.g. "facebook"
   */
  channel?: string
  /**
   * The database id for the channel.
   */
  channelId: string
  /**
   * The service's own id for this channel.
   */
  channelServiceId?: string
  /**
   * What is the username of the channel?
   */
  channelUsername?: string
  /**
   * The global organization id of the user that took this action
   */
  organizationId: string
  /**
   * The product in which the event occurred.
   */
  product: string
  [property: string]: any
}

/**
 * When Engage is disabled after a user has been inactive. At this point we stop collecting
 * data for the user.
 */
export interface EngageDisabled {
  /**
   * The global organization id of the user that took this action
   */
  organizationId: string
  /**
   * The product in which the event occurred.
   */
  product: string
  [property: string]: any
}

/**
 * When Engage is reactivated after a period of inactivity.
 */
export interface EngageReactivated {
  /**
   * Which call to action did the user click to enable Engage?
   */
  cta?: null | string
  /**
   * Which product is the CTA located in? Examples would be `publish` or `analyze`.
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA? In many cases it makes sense to describe the
   * intended result of the CTA, like `proTrial` or `publishPro`.
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in
   * Publish app shell menu.
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA? If setting the value for the first time, set to
   * `1`.
   */
  ctaVersion?: null | string
  /**
   * What app view is the CTA located in? Examples would be, `composer` or `analyticsOverview`
   * for Publish.
   */
  ctaView?: null | string
  /**
   * The global organization id of the user that took this action
   */
  organizationId: string
  /**
   * The product in which the event occurred.
   */
  product: string
  [property: string]: any
}

/**
 * When was the user or visitor enrolled in the experiment?
 */
export interface ExperimentEnrolled {
  /**
   * What was the name of the variation group (Eg. `control`, `enabled`, `variation_1`)
   */
  experimentGroup: string
  /**
   * What was the unique identifier of the experiment?
   */
  experimentId: string
  /**
   * The Global organization ID of the user that was added to the experiment.
   */
  organizationId?: string
  /**
   * Which product was the experiment run in?
   */
  product: null | string
  /**
   * Which experiment framework was the experiment run from (eg, `einstein`, `albert`)?
   */
  sourceFramework: string
  /**
   * What was the page or view the experiment treatment resides in (eg, `/pricing`, or
   * `payday_modal`)?
   */
  view?: null | string
  [property: string]: any
}

/**
 * When did the user or visitor view a variation in the experiment? The property of
 * `firstViewed` will be set to `true` if the event designates an experiment enrollment for
 * the user or visitor.
 */
export interface ExperimentViewed {
  /**
   * What was the name of the variation group (i.e. ""control"" ""experiment"")
   */
  experimentGroup: string
  /**
   * What was the unique identifier of the experiment?
   */
  experimentId: string
  /**
   * Was this the first time the user or visitor interacted with this experiment variation?
   * When true, this designates the experiment enrollment. It is possible for a given visitor
   * or user to be enrolled in a given experiment more than once if they use multiple
   * browsers, multiple devices, or clear their cookies between interactions with a given
   * experiment.
   */
  firstViewed: boolean | null
  /**
   * The Global organization ID of the user that was added to the experiment.
   */
  organizationId?: string
  /**
   * Which product was the experiment run in?  If marketing website, set as null.
   */
  product: null | string
  /**
   * Which experiment framework was the experiment run from (eg, `einstein`, `albert`)?
   */
  sourceFramework: string
  /**
   * What was the page or view the experiment treatment resides in (eg, `/pricing`, or
   * `payday_modal`)?
   */
  view?: null | string
  [property: string]: any
}

/**
 * When a feature flip is disabled for an account. This event should be triggered once per
 * each unique feature flipped, and does not represent multiple features flipped
 * simultaneously.
 */
export interface FeatureFlipDisabled {
  /**
   * The name of the feature that was disabled.
   */
  featureName: string
  /**
   * The organization ID of the account for which the feature was disabled.
   */
  organizationId: string
  [property: string]: any
}

/**
 * When a feature flip is enabled for an account. This event should be triggered once per
 * each unique feature flipped, and does not represent multiple features flipped
 * simultaneously.
 */
export interface FeatureFlipEnabled {
  /**
   * The name of the feature that was enabled.
   */
  featureName: string
  /**
   * The organization ID of the account for which the feature was enabled.
   */
  organizationId: string
  [property: string]: any
}

/**
 * When a feature flip (split) treatment is evaluated for an organization
 */
export interface FeatureFlipEvaluated {
  /**
   * The name of the feature flip.
   */
  featureName: string
  /**
   * The organization ID of the account for which the feature was enabled.
   */
  organizationId: string
  /**
   * The treatment the user or organization is in for the feature flip.
   */
  treatment: string
  /**
   * The version of the split. This comes from split.io and changes when you change the
   * rollout/targeting of a feature. Date time is required to be in ISO-8601 format.
   */
  version: string
  [property: string]: any
}

/**
 * When a new feed collection is created
 */
export interface FeedCollectionCreated {
  /**
   * The client in which the event occurred.
   */
  clientName?: null | string
  /**
   * Which source/cta was used to trigger this event
   */
  cta?: string
  /**
   * The ID of the feed collection.
   */
  feedCollectionId: string
  /**
   * The name of the feed collection.
   */
  feedCollectionName: string
  /**
   * The global organization ID.
   */
  organizationId: string
  /**
   * Which product the event is triggered from
   */
  product: string
  [property: string]: any
}

/**
 * When a user interacts with a feed item
 */
export interface FeedItemInteracted {
  /**
   * The client in which the event occurred.
   */
  clientName?: null | string
  /**
   * Which source/cta was used to trigger this event
   */
  cta?: string
  /**
   * The ID of the collection the feed belongs to.
   */
  feedCollectionId: string
  /**
   * The ID of the feed.
   */
  feedId: string
  /**
   * The URL of the feed item content.
   */
  feedItemContentUrl: string
  /**
   * The ID of the feed item.
   */
  feedItemId: string
  /**
   * The type of interaction (e.g., viewArticle, etc)
   */
  interactionType: string
  /**
   * The global organization ID.
   */
  organizationId: string
  /**
   * Which product the event is triggered from
   */
  product: string
  [property: string]: any
}

/**
 * When a user subscribes to a feed
 */
export interface FeedSubscribed {
  /**
   * The client in which the event occurred.
   */
  clientName?: null | string
  /**
   * Which source/cta was used to trigger this event
   */
  cta?: string
  /**
   * The ID of the collection the feed belongs to.
   */
  feedCollectionId: string
  /**
   * The ID of the feed.
   */
  feedId: string
  /**
   * The query used to subscribe to the feed.
   */
  feedQuery?: string
  /**
   * The type of feed (e.g., rssAtom, news)
   */
  feedType?: string
  /**
   * The URL of the feed.
   */
  feedUrl?: string
  /**
   * The global organization ID.
   */
  organizationId: string
  /**
   * Which product the event is triggered from
   */
  product: string
  [property: string]: any
}

/**
 * When user submits a feedback from within application
 */
export interface FeedbackFormSubmitted {
  /**
   * The name of the client that the event occurred in
   */
  clientName: string
  /**
   * The global organization ID of the user that completed the survey.
   */
  organizationId: string
  /**
   * Rating user submitted
   */
  rating?: number
  /**
   * Source signifying additional location or position of the form
   */
  source?: string
  /**
   * Free form feedback user submitted
   */
  text?: string
  /**
   * User id who sumbitted the form
   */
  userId: string
  /**
   * Unique for id used to differentiate between different forms
   */
  widgetId: string
  [property: string]: any
}

/**
 * When did the user click a CTA on the first post celebration modal?
 */
export interface FirstPostCelebrationCtaClicked {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The CTA string of the cta clicked
   */
  cta?: string
  /**
   * The global organization ID of the user that viewed the Trial Expired modal.
   */
  organizationId: string
  [property: string]: any
}

/**
 * When did the user see the First Post Celebration modal?
 */
export interface FirstPostCelebrationModalViewed {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The global organization ID of the user that viewed the Trial Expired modal.
   */
  organizationId: string
  [property: string]: any
}

/**
 * When a user clicks the Follow Buffer on Mastodon button in the promo banner
 */
export interface FollowMastodonButtonClicked {
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product: string
  [property: string]: any
}

/**
 * When a user dismissed the Follow Buffer on Mastodon promo banner
 */
export interface FollowMastodonDismissed {
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product: string
  [property: string]: any
}

/**
 * When a visitor sees a rate limit message from one of our free tools
 */
export interface FreeToolRateLimitModalViewed {
  /**
   * The name of the Free Tool
   */
  product: string
  [property: string]: any
}

/**
 * Which Getting Started step that the user tapped on to complete
 */
export interface GettingStartedStepTapped {
  /**
   * Which client was the user in when they performed the Getting Started action? IE,
   * `publishIos` or `publishAndroid`.
   */
  clientName: string
  /**
   * The organization ID of the user that performed the Getting Started action.
   */
  organizationId: string
  /**
   * The name of the Getting Started step they took action on
   */
  step: string
  [property: string]: any
}

/**
 * When a user clicks an item in the global plus button dropdown
 */
export interface GlobalPlusButtonItemClicked {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The name of the CTA clicked.
   */
  cta: null | string
  /**
   * Is the user at their plan limit?
   */
  isAtPlanLimit?: boolean | null
  /**
   * The organization ID of the user that took the action
   */
  organizationId?: string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product: null | string
  /**
   * The name of the upgrade path
   */
  upgradePathName: null | string
  [property: string]: any
}

/**
 * When did the user open the Global Plus Button dropdown menu?
 */
export interface GlobalPlusButtonOpened {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The name of the CTA clicked.
   */
  cta?: null | string
  /**
   * The number of channels connected to the user's account
   */
  currentChannelQuantity?: number | null
  /**
   * The global organization ID of the user that viewed the plan selector.
   */
  organizationId: null | string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product?: string
  [property: string]: any
}

/**
 * A user saves a group of commonly used hashtags
 */
export interface HashtagGroupCreated {
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * The number of hashtags in the the group
   */
  hashtagCount: number
  /**
   * The name the user has given to the hashtag group
   */
  hashtagGroupName: string
  /**
   * The ID of the global organization that the hashtag group was created for.
   */
  organizationId?: string
  /**
   * The product in which the action occured
   */
  product: string
  [property: string]: any
}

/**
 * A user deletes one of their hashtag groups
 */
export interface HashtagGroupDeleted {
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * The number of hashtags in the the group
   */
  hashtagCount: number
  /**
   * The name the user has given to the hashtag group
   */
  hashtagGroupName: string
  /**
   * The ID of the global organization that the hashtag group was created for.
   */
  organizationId?: string
  /**
   * The product in which the action occured
   */
  product: string
  [property: string]: any
}

/**
 * A user inserts one of their hashtag groups as a first comment
 */
export interface HashtagGroupInserted {
  /**
   * Which client was the user in when they inserted the hashtag group?
   */
  clientName?: null | string
  /**
   * The number of hashtags in the the group
   */
  hashtagCount: number
  /**
   * The name the user has given to the hashtag group
   */
  hashtagGroupName: string
  /**
   * The ID of the global organization that the hashtag group was created for.
   */
  organizationId?: string
  /**
   * The product in which the action occured
   */
  product: string
  [property: string]: any
}

/**
 * A customer viewed a help article on our help center site
 */
export interface HelpCenterArticleViewed {
  /**
   * The title of the article that was viewed.
   */
  articleTitle: null | string
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The global organization ID of the user that viewed the page.
   */
  organizationId?: null | string
  [property: string]: any
}

/**
 * A customer viewed a help article in the Help Scout Beacon widget
 */
export interface HelpScoutBeaconArticleViewed {
  /**
   * The ID of the article that was viewed.
   */
  articleId: null | string
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The address of the page.
   */
  currentUrl?: null | string
  /**
   * The global organization ID of the user that viewed the page.
   */
  organizationId?: null | string
  [property: string]: any
}

/**
 * A customer started a chat in the Help Scout Beacon widget
 */
export interface HelpScoutBeaconChatStarted {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The address of the page.
   */
  currentUrl?: null | string
  /**
   * The global organization ID of the user that viewed the page.
   */
  organizationId: string
  /**
   * The subject of the chat.
   */
  subject?: null | string
  [property: string]: any
}

/**
 * A customer closed the Help Scout Beacon widget
 */
export interface HelpScoutBeaconClosed {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The address of the page.
   */
  currentUrl?: null | string
  /**
   * The global organization ID of the user that viewed the page.
   */
  organizationId: null | string
  [property: string]: any
}

/**
 * A customer sent an email in the Help Scout Beacon widget
 */
export interface HelpScoutBeaconEmailSent {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The address of the page.
   */
  currentUrl?: null | string
  /**
   * The global organization ID of the user that viewed the page.
   */
  organizationId: string
  /**
   * The subject of the email.
   */
  subject?: null | string
  [property: string]: any
}

/**
 * A customer opened the Help Scout Beacon widget
 */
export interface HelpScoutBeaconOpened {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The address of the page.
   */
  currentUrl?: null | string
  /**
   * The global organization ID of the user that viewed the page.
   */
  organizationId: null | string
  [property: string]: any
}

/**
 * A customer performed a search query in the Help Scout Beacon widget
 */
export interface HelpScoutBeaconSearchQueryPerformed {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The address of the page.
   */
  currentUrl?: null | string
  /**
   * The global organization ID of the user that viewed the page.
   */
  organizationId: null | string
  /**
   * The search term that was used.
   */
  searchTerm?: null | string
  [property: string]: any
}

/**
 * When a visitor clicks on one of the CTAs on the Buffer homepage.
 */
export interface HomepageButtonClicked {
  /**
   * The location of the button that was clicked, e.g. 'top-nav', 'footer'
   */
  buttonLocation: null | string
  /**
   * The name of the button that was clicked, e.g. 'hero-get-started'
   */
  buttonName?: null | string
  /**
   * If someone enters an email address, include it here.
   */
  emailAddress?: null | string
  [property: string]: any
}

/**
 * Click event when an idea action is performed
 */
export interface IdeaActionClicked {
  /**
   * Name of the action performed
   */
  action: string
  /**
   * The idea ID
   */
  ideaId: string
  /**
   * The global organization ID.
   */
  organizationId: string
  /**
   * Which product was the product the event was triggered from.
   */
  product: string
  [property: string]: any
}

/**
 * Idea batch delete is performed
 */
export interface IdeaBatchDeleted {
  /**
   * Number of ideas selected
   */
  count: number
  /**
   * The global organization ID.
   */
  organizationId: string
  /**
   * Which product was the product the event was triggered from.
   */
  product: string
  [property: string]: any
}

/**
 * When a user opens the Idea composer.
 */
export interface IdeaComposerOpened {
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * Which call to action did the user click to get to open the composer?
   */
  cta?: string
  /**
   * Which product is the CTA located in? Examples would be `publish`.
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA? In many cases it makes sense to describe the
   * intended result of the CTA, like `createAPost` or `publishProTrial`.
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in
   * Publish app shell menu.
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA? If setting the value for the first time, set to
   * `1`.
   */
  ctaVersion?: null | string
  /**
   * What app view is the CTA located in? Examples would be `publishDashboard` or
   * `analyticsOverview` for Publish.
   */
  ctaView?: null | string
  /**
   * The id of the Idea that the composer is opened for.
   */
  ideaId?: null | string
  /**
   * The organization id of the user that took the action
   */
  organizationId: string
  /**
   * The source of the Idea that the composer is opened for
   */
  source?: null | string
  [property: string]: any
}

/**
 * When a user has created an Idea
 */
export interface IdeaCreated {
  /**
   * Whether this idea was assisted with AI
   */
  aiAssisted?: boolean | null
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The design id if a Canva image is attached to the post
   */
  canvaDesignId?: null | string
  /**
   * Which client was the user in when the Idea was created?
   */
  clientName?: string
  /**
   * Which source/cta was clicked to create the idea in the UI
   */
  cta?: string
  /**
   * Which website is the CTA located in? Ex, `bufferSite`
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA?  In many cases it makes sense to describe the
   * intended result of the CTA, like `proTrial` or `publishPro`
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be, `cards` for the Pro trial CTA
   * in Publish pricing page main plan comparison section
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA?  If setting the value for the first time, set to
   * `1`
   */
  ctaVersion?: null | string
  /**
   * Which website page is the CTA located?  Ex,  `pricingPublish` for the Publish pricing page
   */
  ctaView?: null | string
  /**
   * Count of attached gifs
   */
  gifCount: number
  /**
   * Does the Idea contain an image from the Canva integration?
   */
  hasCanvaImage?: boolean
  /**
   * Does the Idea contain an image from the Uploadcare integration?
   */
  hasUploadcareImage?: boolean
  /**
   * The database id for the Idea document
   */
  ideaId: string
  /**
   * Count of attached images
   */
  imageCount: number
  /**
   * Count of attached links in the text
   */
  linkCount: number
  /**
   * Count of attached media (images or videos)
   */
  mediaCount: number
  /**
   * What type of media did this Idea have attached? ex. "image", "video"
   */
  mediaType?: null | string
  /**
   * The global organization id that Idea belongs to.
   */
  organizationId: string
  /**
   * Count of tags assigned to the Idea
   */
  tagsCount?: number | null
  /**
   * The number of characters of the text content of the Idea
   */
  textLength: number
  /**
   * Count of attached videos
   */
  videoCount: number
  [property: string]: any
}

/**
 * When a user deletes an Idea.
 */
export interface IdeaDeleted {
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName?: null | string
  /**
   * Which source/cta was used to trigger this event
   */
  cta?: string
  /**
   * Which website is the CTA located in? Ex, `bufferSite`
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA?  In many cases it makes sense to describe the
   * intended result of the CTA, like `proTrial` or `publishPro`
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be, `cards` for the Pro trial CTA
   * in Publish pricing page main plan comparison section
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA?  If setting the value for the first time, set to
   * `1`
   */
  ctaVersion?: null | string
  /**
   * Which website page is the CTA located?  Ex,  `pricingPublish` for the Publish pricing page
   */
  ctaView?: null | string
  /**
   * The id of the Idea in Buffer's database.
   */
  ideaId: string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  [property: string]: any
}

/**
 * When a user discards an idea after converting to post.
 */
export interface IdeaDiscarded {
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * Which call to action did the user click to discard the Idea
   */
  cta?: string
  /**
   * The id of the Idea in Buffer's database.
   */
  ideaId?: string
  /**
   * The organization id of the user that took the action
   */
  organizationId: string
  [property: string]: any
}

/**
 * When a user edits an Idea.
 */
export interface IdeaEdited {
  /**
   * Whether this idea was assisted with AI
   */
  aiAssisted?: boolean | null
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName?: null | string
  /**
   * Which source/cta was used to trigger this event
   */
  cta?: string
  /**
   * Which website is the CTA located in? Ex, `bufferSite`
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA?  In many cases it makes sense to describe the
   * intended result of the CTA, like `proTrial` or `publishPro`
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be, `cards` for the Pro trial CTA
   * in Publish pricing page main plan comparison section
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA?  If setting the value for the first time, set to
   * `1`
   */
  ctaVersion?: null | string
  /**
   * Which website page is the CTA located?  Ex,  `pricingPublish` for the Publish pricing page
   */
  ctaView?: null | string
  /**
   * Count of attached gifs
   */
  gifCount: number
  /**
   * The id of the Idea in Buffer's database.
   */
  ideaId: string
  /**
   * Count of attached images
   */
  imageCount: number
  /**
   * Count of attached links in the text
   */
  linkCount: number
  /**
   * Count of attached media (images or videos)
   */
  mediaCount: number
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * Count of tags assigned to the Idea
   */
  tagsCount?: number | null
  /**
   * The number of characters of the text content of the Idea
   */
  textLength: number
  /**
   * Count of attached videos
   */
  videoCount: number
  [property: string]: any
}

/**
 * When a user keeps an idea after converting to post.
 */
export interface IdeaKept {
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * Which call to action did the user click to discard the Idea
   */
  cta?: string
  /**
   * The id of the Idea in Buffer's database.
   */
  ideaId?: string
  /**
   * The organization id of the user that took the action
   */
  organizationId: string
  [property: string]: any
}

/**
 * When an idea is moved between content groups in the create space.
 */
export interface IdeaMoved {
  /**
   * The client in which the event occurred.
   */
  clientName?: null | string
  /**
   * Which source/cta was used to trigger this event
   */
  cta?: string
  /**
   * Which website is the CTA located in? Ex, `bufferSite`
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA?  In many cases it makes sense to describe the
   * intended result of the CTA, like `proTrial` or `publishPro`
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be, `cards` for the Pro trial CTA
   * in Publish pricing page main plan comparison section
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA?  If setting the value for the first time, set to
   * `1`
   */
  ctaVersion?: null | string
  /**
   * Which website page is the CTA located?  Ex,  `pricingPublish` for the Publish pricing page
   */
  ctaView?: null | string
  /**
   * The ID of the idea moved.
   */
  ideaId: string
  /**
   * The ID of the new group.
   */
  newGroupId: string
  /**
   * The name of the new group.
   */
  newGroupName: string
  /**
   * The global organization ID.
   */
  organizationId: string
  /**
   * The ID of the previous group.
   */
  previousGroupId: string
  /**
   * The name of the previous group.
   */
  previousGroupName: string
  /**
   * Which product was the product the event was triggered from.
   */
  product: string
  [property: string]: any
}

/**
 * A user has opened the share sheet for an Idea
 */
export interface IdeaShareSheetOpened {
  /**
   * The plan id of the subscription
   */
  billingPlan?: null | string
  /**
   * The name of the client in which the action occurred (ex. publishIos)
   */
  clientName: string
  /**
   * The post has either video or image media
   */
  hasMedia?: boolean
  /**
   * If the update has pictures
   */
  hasPictures?: boolean
  /**
   * If the update has video
   */
  hasVideo?: boolean
  /**
   * The post id for the reminder being shared
   */
  ideaId?: null | string
  /**
   * Does the user have an active One Buffer paid subscription?
   */
  isPayingBufferUser?: boolean | null
  /**
   * The global organization id that the channel belongs to
   */
  organizationId: string
  /**
   * The product in which the action occured
   */
  product: string
  [property: string]: any
}

/**
 * When the AI assistant is cancelled by clicking on the close button
 */
export interface IdeasAssistantCancelled {
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * Which call to action did the user click to get to open the composer?
   */
  cta?: null | string
  /**
   * Which product is the CTA located in? Examples would be `publish`.
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA? In many cases it makes sense to describe the
   * intended result of the CTA, like `createAPost` or `publishProTrial`.
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in
   * Publish app shell menu.
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA? If setting the value for the first time, set to
   * `1`.
   */
  ctaVersion?: null | string
  /**
   * What app view is the CTA located in? Examples would be `publishDashboard` or
   * `analyticsOverview` for Publish.
   */
  ctaView?: null | string
  /**
   * The organization id of the user that took the action
   */
  organizationId: string
  [property: string]: any
}

/**
 * When the ideas assistant is initiated in the context of the ideas editor
 */
export interface IdeasAssistantInitiated {
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * Which call to action did the user click to get to open the composer?
   */
  cta?: null | string
  /**
   * Which product is the CTA located in? Examples would be `publish`.
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA? In many cases it makes sense to describe the
   * intended result of the CTA, like `createAPost` or `publishProTrial`.
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in
   * Publish app shell menu.
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA? If setting the value for the first time, set to
   * `1`.
   */
  ctaVersion?: null | string
  /**
   * What app view is the CTA located in? Examples would be `publishDashboard` or
   * `analyticsOverview` for Publish.
   */
  ctaView?: null | string
  /**
   * The organization id of the user that took the action
   */
  organizationId: string
  [property: string]: any
}

/**
 * When a user accepts a variation generated by AI
 */
export interface IdeasAssistantVariationAccepted {
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The type of content a user requests, `text`, `image` or `both`.
   */
  contentType?: null | string
  /**
   * Which call to action did the user click to get to open the composer?
   */
  cta?: string
  /**
   * Which product is the CTA located in? Examples would be `publish`.
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA? In many cases it makes sense to describe the
   * intended result of the CTA, like `createAPost` or `publishProTrial`.
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in
   * Publish app shell menu.
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA? If setting the value for the first time, set to
   * `1`.
   */
  ctaVersion?: null | string
  /**
   * What app view is the CTA located in? Examples would be `publishDashboard` or
   * `analyticsOverview` for Publish.
   */
  ctaView?: null | string
  /**
   * The organization id of the user that took the action
   */
  organizationId: string
  [property: string]: any
}

/**
 * When the user receives an error from the content generation service, after a variation
 * request.
 */
export interface IdeasAssistantVariationErrored {
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The type of content a user requests, `text`, `image` or `both`.
   */
  contentType?: null | string
  /**
   * Which call to action did the user click to get to open the composer?
   */
  cta?: string
  /**
   * Which product is the CTA located in? Examples would be `publish`.
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA? In many cases it makes sense to describe the
   * intended result of the CTA, like `createAPost` or `publishProTrial`.
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in
   * Publish app shell menu.
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA? If setting the value for the first time, set to
   * `1`.
   */
  ctaVersion?: null | string
  /**
   * What app view is the CTA located in? Examples would be `publishDashboard` or
   * `analyticsOverview` for Publish.
   */
  ctaView?: null | string
  /**
   * The error code the user receives from the content generation service (if applicable)
   */
  errorCode?: null | string
  /**
   * The error message the user receives from the content generation service
   */
  errorMessage?: null | string
  /**
   * The organization id of the user that took the action
   */
  organizationId: string
  [property: string]: any
}

/**
 * When the user receives a variation from the content generation service, in the ideas
 * editor.
 */
export interface IdeasAssistantVariationProposed {
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The type of content a user requests, `text`, `image` or `both`.
   */
  contentType?: null | string
  /**
   * Which call to action did the user click to get to open the composer?
   */
  cta?: string
  /**
   * Which product is the CTA located in? Examples would be `publish`.
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA? In many cases it makes sense to describe the
   * intended result of the CTA, like `createAPost` or `publishProTrial`.
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in
   * Publish app shell menu.
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA? If setting the value for the first time, set to
   * `1`.
   */
  ctaVersion?: null | string
  /**
   * What app view is the CTA located in? Examples would be `publishDashboard` or
   * `analyticsOverview` for Publish.
   */
  ctaView?: null | string
  /**
   * The organization id of the user that took the action
   */
  organizationId: string
  /**
   * The number of variations proposed
   */
  variations?: number
  [property: string]: any
}

/**
 * When a user requests for AI assisted content generation in the ideas
 */
export interface IdeasAssistantVariationRequested {
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The type of content a user requests, `text`, `image` or `both`.
   */
  contentType?: null | string
  /**
   * Which call to action did the user click to get to open the composer?
   */
  cta?: string
  /**
   * Which product is the CTA located in? Examples would be `publish`.
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA? In many cases it makes sense to describe the
   * intended result of the CTA, like `createAPost` or `publishProTrial`.
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in
   * Publish app shell menu.
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA? If setting the value for the first time, set to
   * `1`.
   */
  ctaVersion?: null | string
  /**
   * What app view is the CTA located in? Examples would be `publishDashboard` or
   * `analyticsOverview` for Publish.
   */
  ctaView?: null | string
  /**
   * The organization id of the user that took the action
   */
  organizationId: string
  [property: string]: any
}

/**
 * Fitered ideas in the create space.
 */
export interface IdeasFiltered {
  /**
   * The client in which the event occurred.
   */
  clientName?: null | string
  /**
   * Which source/cta was used to trigger this event
   */
  cta?: string
  /**
   * Names of filters used i.e. tags, groups, etc.
   */
  filter: any[]
  /**
   * The global organization ID.
   */
  organizationId: string
  [property: string]: any
}

/**
 * When did the user open Ideas during Oboarding
 */
export interface IdeasOpenedDuringOnboarding {
  /**
   * What was the name of the client the user was in when they opened the Story in the native
   * channel?
   */
  clientName: string
  /**
   * The global organization id that the channel belongs to.
   */
  organizationId?: string
  /**
   * In which product did this event occur?
   */
  product: string
  [property: string]: any
}

/**
 * When did the user select the color of the background of the image?
 */
export interface ImageBackgroundColorSelected {
  /**
   * What was the selected background color?
   */
  backgroundColor: string
  /**
   * The client in which the action occurred.
   */
  clientName: string
  /**
   * The product in which the event occurred, ex. “remix”.
   */
  product: string
  /**
   * How did the user select the chosen background color?
   */
  source: string
  [property: string]: any
}

/**
 * When did the user tap the image background icon within the editor in the remix app?
 */
export interface ImageBackgroundIconTapped {
  /**
   * The client in which the action occurred.
   */
  clientName: string
  /**
   * The product in which the event occurred, ex. “remix”.
   */
  product: string
  [property: string]: any
}

/**
 * When did the user remove the image used in the background?
 */
export interface ImageBackgroundImageRemoved {
  /**
   * The client in which the action occurred.
   */
  clientName: string
  /**
   * The product in which the event occurred, ex. “remix”.
   */
  product: string
  [property: string]: any
}

/**
 * When did the user select the image used in the background?
 */
export interface ImageBackgroundImageSelected {
  /**
   * The client in which the action occurred.
   */
  clientName: string
  /**
   * The source in which the image was selected.
   */
  imageSource?: string
  /**
   * The product in which the event occurred, ex. “remix”.
   */
  product: string
  [property: string]: any
}

/**
 * When an image is cropped within the composer
 */
export interface ImageCropped {
  /**
   * What was the unique identifier of the client the user was in when they attached the media
   * item?
   */
  clientId?: null | string
  /**
   * Which client was the user in when they attached the media item? IE, `publishIos`,
   * `publishWeb`, `publishAndroid`.
   */
  clientName: string
  /**
   * The method used to crop the image
   */
  method: string
  /**
   * The organization ID of the user that added the media
   */
  organizationId?: string
  /**
   * The product for which the media item was attached in
   */
  product: string
  [property: string]: any
}

/**
 * When did the user save the image to the camera roll?
 */
export interface ImageSaved {
  /**
   * The client in which the action occurred.
   */
  clientName: string
  /**
   * The product in which the event occurred, ex. “remix”.
   */
  product: string
  /**
   * The template chosen for the saved image.
   */
  template: string
  [property: string]: any
}

/**
 * When did the user share the image to Publish or another app?
 */
export interface ImageShared {
  /**
   * What was the name of the app that the image was shared to?
   */
  app?: string
  /**
   * The channel of the profile that the image was shared to, ex. “facebook”.
   */
  channel?: string
  /**
   * The unique identifier of the channel that the image was shared to.
   */
  channelId?: string
  /**
   * The channel type of the profile that the image was shared to, ex. “business”.
   */
  channelType?: string
  /**
   * The client in which the action occurred.
   */
  clientName: string
  /**
   * The unique identifier of the post in Publish.
   */
  postId?: string
  /**
   * The product in which the event occurred, ex. “remix”.
   */
  product: string
  /**
   * The id of the Publish user
   */
  publishUserId?: string
  /**
   * Where was the image shared? Ex. "publish", "app".
   */
  target: string
  /**
   * The template chosen for the shared image.
   */
  template: string
  [property: string]: any
}

/**
 * When did the user select the size of the image?
 */
export interface ImageSizeSelected {
  /**
   * The client in which the action occurred.
   */
  clientName: string
  /**
   * What was the selected image size?
   */
  imageSize: string
  /**
   * The product in which the event occurred, ex. “remix”.
   */
  product: string
  [property: string]: any
}

/**
 * When did the user reset the style to default for the image?
 */
export interface ImageStyleResetTapped {
  /**
   * The client in which the action occurred.
   */
  clientName: string
  /**
   * The product in which the event occurred, ex. “remix”.
   */
  product: string
  [property: string]: any
}

/**
 * When did the user select the template for the image?
 */
export interface ImageTemplateSelected {
  /**
   * The client in which the action occurred.
   */
  clientName: string
  /**
   * What was the selected image templagte?
   */
  imageTemplate: string
  /**
   * The product in which the event occurred, ex. “remix”.
   */
  product: string
  [property: string]: any
}

/**
 * Which slide did the user view within the Instagram Auth Explainer flow.
 */
export interface InstagramAuthorizationExplainerSlideViewed {
  /**
   * What was the unique identifier of the client the user was in when they viewed the slide?
   */
  clientId?: null | string
  /**
   * Which client was the user in when they viewed the slide? IE, `publishIos` or
   * `publishAndroid`.
   */
  clientName: string
  /**
   * The organization ID of the user that viewed the slide.
   */
  organizationId: string
  /**
   * Which slide did the user view?
   */
  slide: string
  [property: string]: any
}

/**
 * When did the user tap the button to insert dots into the Composer for Instagram.
 */
export interface InstagramCaptionDotsInserted {
  /**
   * What was the unique identifier of the client the user was in when they updated the story?
   */
  clientId?: null | string
  /**
   * Which client was the user in when they copied the story note? IE, `publishIos`,
   * `publishWeb`, `publishAndroid`.
   */
  clientName: string
  /**
   * The organization ID of the user that copied the story note.
   */
  organizationId?: string
  /**
   * The product for which the story was created
   */
  product: string
  [property: string]: any
}

/**
 * When did the user click the Instagram Grid Preview icon?
 */
export interface InstagramGridPreviewIconClicked {
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The service's own id for this channel
   */
  channelServiceId?: string
  /**
   * What is the username of the channel?
   */
  channelUsername?: string
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * The Global organization ID.
   */
  organizationId?: string
  /**
   * The product in which the action occurred
   */
  product: string
  [property: string]: any
}

/**
 * When did the user load the Instagram Grid Preview page?
 */
export interface InstagramGridPreviewPageLoaded {
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The service's own id for this channel
   */
  channelServiceId?: string
  /**
   * What is the username of the channel?
   */
  channelUsername?: string
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * The Global organization ID.
   */
  organizationId?: string
  /**
   * The product in which the action occurred
   */
  product: string
  [property: string]: any
}

/**
 * When did the user toggle the view of the Instagram Grid Preview page?
 */
export interface InstagramGridPreviewToggleView {
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The service's own id for this channel
   */
  channelServiceId?: string
  /**
   * What is the username of the channel?
   */
  channelUsername?: string
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * The Global organization ID.
   */
  organizationId?: string
  /**
   * The product in which the action occurred
   */
  product: string
  /**
   * The view state of the Instagram Grid Preview.
   */
  view: string
  [property: string]: any
}

/**
 * When did the user save a list of tagged Instagram profiles?
 */
export interface InstagramTagsSaved {
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The service's own id for this channel
   */
  channelServiceId?: string
  /**
   * What is the Instagram profile’s username?
   */
  channelUsername?: string
  /**
   * The client in which the action occurred, `publishIos`, `publishAndroid`or `publishWeb`.
   */
  clientName?: string
  /**
   * The ID of the global organization that the user tags were saved for.
   */
  organizationId?: string
  /**
   * The product in which the tagging action occured
   */
  product: string
  /**
   * How many usernames were tagged in the post?
   */
  taggedUserCount: number
  /**
   * What were the usernames of the profiles that were tagged in the post
   */
  taggedUsernames?: any[]
  [property: string]: any
}

/**
 * When did the user tag another username in an Instagram post?
 */
export interface InstagramUserTagged {
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The service's own id for this channel
   */
  channelServiceId?: string
  /**
   * What is the Instagram profile’s username?
   */
  channelUsername?: string
  /**
   * The client in which the action occurred, `publishIos`, `publishAndroid`or `publishWeb`.
   */
  clientName?: string
  /**
   * The ID of the global organization that is selected in the org switcher.
   */
  organizationId?: string
  /**
   * The product in which the tagging action occured
   */
  product: string
  /**
   * What is the username of the profile that was tagged in the post?
   */
  taggedUsername?: string
  [property: string]: any
}

/**
 * When a user clicks on an Integration button.
 */
export interface IntegrationButtonClicked {
  /**
   * The name of the client in which the action occurred (ex. publishWeb).
   */
  clientName: string
  /**
   * The identifier of the Integration, ex. Unsplash, Giphy
   */
  integration: string
  /**
   * The organization ID of the user that took the action.
   */
  organizationId: string
  /**
   * The product in which the action occurred (ex. publish, analyze).
   */
  product: string
  /**
   * The component where the integration was opened.
   */
  source: string
  [property: string]: any
}

/**
 * When a media from an integrated service is attached to an editor.
 */
export interface IntegrationMediaAttached {
  /**
   * The name of the client in which the action occurred (ex. publishWeb).
   */
  clientName: string
  /**
   * The editor where the media is going to be attached.
   */
  destination: string
  /**
   * The identifier of the Integration, ex. Unsplash, Giphy.
   */
  integration: string
  /**
   * mimetype of the media element, ex. image/png, image/jpg.
   */
  mediaType: string
  /**
   * The organization ID of the user that took the action.
   */
  organizationId: string
  /**
   * The product in which the action occurred (ex. publish, analyze).
   */
  product: string
  [property: string]: any
}

/**
 * When a user launches a query to the integrated service.
 */
export interface IntegrationSearchRun {
  /**
   * The name of the client in which the action occurred (ex. publishWeb).
   */
  clientName: string
  /**
   * The identifier of the Integration, ex. Unsplash, Giphy.
   */
  integration: string
  /**
   * The organization ID of the user that took the action.
   */
  organizationId: string
  /**
   * The product in which the action occurred (ex. publish, buffer).
   */
  product: string
  /**
   * search term used to launches a query to the service.
   */
  query: string
  /**
   * The component where the integration was opened.
   */
  source?: string
  [property: string]: any
}

/**
 * Did this paid subscription invoice payment fail from being collected?
 */
export interface InvoicePaymentFailed {
  /**
   * How many times has Buffer attempted to collect payment for this invoice?
   */
  attemptCount?: number | null
  /**
   * Which country is the credit card from? e.g. US or IN
   */
  creditCardCountry?: null | string
  /**
   * Unique identifier for the Stripe customer
   */
  customerId: string
  /**
   * The subscription payment interval (month or year) of the subscription associated with the
   * invoice
   */
  cycle?: string
  /**
   * What was the error message in the payment gateway for this payment failure?
   */
  errorMessage?: null | string
  /**
   * What triggered this event? e.g. Stripe or RevenueCat
   */
  gateway?: string
  /**
   * Does the user have a tax ID?
   */
  hasTaxId?: boolean | null
  /**
   * The dollar value of the invoice at the time of this attempted payment
   */
  invoiceValue?: number
  /**
   * Is automatic tax collection in Stripe enabled for this user?
   */
  isAutomaticTaxEnabled?: boolean | null
  /**
   * Was the subscription started via Stripe Checkout?
   */
  isStripeCheckout?: boolean | null
  /**
   * Is the user tax exempt?
   */
  isTaxExempt?: boolean | null
  /**
   * When will be the next date that Buffer will attempt to collect payment of this invoice?
   * Data type for this property is a string, but value should be formatted as a timestamp in
   * the ISO-8601 date format.
   */
  nextCollectionAttemptDate?: null | string
  /**
   * The ID of the organization for which the invoice was created
   */
  organizationId: string
  /**
   * Stripe plan id of the subscription associated with the invoice
   */
  planId?: string
  /**
   * Human-readable plan name of the subscription associated with the invoice
   */
  planName?: string
  /**
   * The product of the subscription associated with the invoice
   */
  product?: string
  /**
   * Stripe event id from the webhook event
   */
  stripeEventId: string
  /**
   * Unique identifier for the Stripe subscription
   */
  subscriptionId: string
  /**
   * How much tax was the user charged?
   */
  taxAmount?: number | null
  /**
   * Was the customer charged tax?
   */
  wasChargedTax?: boolean | null
  [property: string]: any
}

/**
 * Was there a refund for this invoice?
 */
export interface InvoicePaymentRefunded {
  /**
   * Unique identifier for the Stripe customer
   */
  customerId: string
  /**
   * The subscription payment interval (month or year) of the subscription associated with the
   * invoice
   */
  cycle?: string
  /**
   * What triggered this event? e.g. Stripe or RevenueCat
   */
  gateway?: string
  /**
   * Does the user have a tax ID?
   */
  hasTaxId?: boolean | null
  /**
   * The dollar value of the invoice prior to this refund
   */
  invoiceValue?: number
  /**
   * Is automatic tax collection in Stripe enabled for this user?
   */
  isAutomaticTaxEnabled?: boolean | null
  /**
   * Is the user tax exempt?
   */
  isTaxExempt?: boolean | null
  /**
   * The ID of the organization for which the invoice was created
   */
  organizationId: string
  /**
   * Stripe plan id of the subscription associated with the invoice
   */
  planId?: string
  /**
   * Human-readable plan name of the subscription associated with the invoice
   */
  planName?: string
  /**
   * The product of the subscription associated with the invoice
   */
  product?: string
  /**
   * The URL of payment refund receipt
   */
  receiptLink?: string
  /**
   * The dollar value of this refund
   */
  refundValue?: number
  /**
   * Stripe event id from the webhook event
   */
  stripeEventId: string
  /**
   * Unique identifier for the Stripe subscription
   */
  subscriptionId: string
  /**
   * How much tax was the user charged?
   */
  taxAmount?: number | null
  /**
   * Was the customer charged tax?
   */
  wasChargedTax?: boolean | null
  [property: string]: any
}

/**
 * Was this invoice payment successfully collected?
 */
export interface InvoicePaymentSucceeded {
  /**
   * Which country is the credit card from? e.g. US or IN
   */
  creditCardCountry?: null | string
  /**
   * Unique identifier for the Stripe customer
   */
  customerId: string
  /**
   * The subscription payment interval (month or year) of the subscription associated with the
   * invoice
   */
  cycle?: string
  /**
   * What triggered this event? e.g. Stripe or RevenueCat
   */
  gateway?: string
  /**
   * Does the user have a tax ID?
   */
  hasTaxId?: boolean | null
  /**
   * The dollar value of the invoice prior to this payment
   */
  invoiceValue?: number
  /**
   * Is automatic tax collection in Stripe enabled for this user?
   */
  isAutomaticTaxEnabled?: boolean | null
  /**
   * Was the subscription started via Stripe Checkout?
   */
  isStripeCheckout?: boolean | null
  /**
   * Is the user tax exempt?
   */
  isTaxExempt?: boolean | null
  /**
   * The ID of the organization for which the invoice was created
   */
  organizationId: string
  /**
   * Stripe plan id of the subscription associated with the invoice
   */
  planId?: string
  /**
   * Human-readable plan name of the subscription associated with the invoice
   */
  planName?: string
  /**
   * The product of the subscription associated with the invoice
   */
  product?: string
  /**
   * Stripe event id from the webhook event
   */
  stripeEventId: string
  /**
   * Unique identifier for the Stripe subscription
   */
  subscriptionId: string
  /**
   * How much tax was the user charged?
   */
  taxAmount?: number | null
  /**
   * Was the customer charged tax?
   */
  wasChargedTax?: boolean | null
  [property: string]: any
}

/**
 * Is there a Stripe invoice coming due in the next 7 days?  This event is triggered by the
 * Stripe invoice.upcoming event, and is configurable via Buffer's Stripe account settings;
 * currently set to trigger 7 days before an invoice is generated.
 */
export interface InvoiceUpcoming {
  /**
   * Unique identifier for the Stripe customer
   */
  customerId: string
  /**
   * The subscription payment interval (month or year) of the subscription associated with the
   * invoice
   */
  cycle?: string
  /**
   * What triggered this event? e.g. Stripe or RevenueCat
   */
  gateway?: string
  /**
   * The dollar value of the invoice amount due
   */
  invoiceValue?: number
  /**
   * The ID of the organization for which the invoice was created
   */
  organizationId?: string
  /**
   * Stripe plan id of the subscription associated with the invoice
   */
  planId?: string
  /**
   * Human-readable plan name of the subscription associated with the invoice
   */
  planName?: string
  /**
   * The product of the subscription associated with the invoice
   */
  product?: string
  /**
   * Stripe event id from the webhook event
   */
  stripeEventId: string
  /**
   * Unique identifier for the Stripe subscription
   */
  subscriptionId: string
  [property: string]: any
}

/**
 * When a user clicks a Join Community button in the beta page
 */
export interface JoinCommunityButtonClicked {
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product: string
  [property: string]: any
}

/**
 * When a link is being shortened and under what conditions
 */
export interface LinkShortened {
  /**
   * The channel that settings tab was opened for (ex. instagram, pinterest)
   */
  channel: string
  /**
   * The channelId that settings tab was opened for (ex. instagram, pinterest)
   */
  channelId: string
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * Whether the user has connected a bitly account
   */
  hasConnectedAccount: boolean
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product: string
  /**
   * The domain of the shortener used
   */
  shortenerDomain?: string
  /**
   * The URL being shortened
   */
  url?: null | string
  [property: string]: any
}

/**
 * When the action button for shortening links in the composer is clicked
 */
export interface LinkShortenedClicked {
  /**
   * The channel that settings tab was opened for (ex. instagram, pinterest)
   */
  channel: string
  /**
   * The channelId that settings tab was opened for (ex. instagram, pinterest)
   */
  channelId: string
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The name of the CTA clicked.
   */
  cta: string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product: string
  [property: string]: any
}

/**
 * When the action button for unshortening links in the composer is clicked
 */
export interface LinkUnshortenedClicked {
  /**
   * The channel that settings tab was opened for (ex. instagram, pinterest)
   */
  channel: string
  /**
   * The channelId that settings tab was opened for (ex. instagram, pinterest)
   */
  channelId: string
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The name of the CTA clicked.
   */
  cta: string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product: string
  [property: string]: any
}

/**
 * A customer created a new live chat conversation
 */
export interface LiveChatCreated {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The name of the advocate assigned to the conversation
   */
  conversationAssigneeName?: null | string
  /**
   * The ID associated with the specific Help Scout conversation
   */
  conversationId: string
  /**
   * A list of tags applied to the conversation
   */
  conversationTags?: any[] | null
  /**
   * A link to the specific Help Scout conversation
   */
  conversationUrl?: null | string
  /**
   * The ID of the mailbox the conversation was created in
   */
  mailboxId: string
  /**
   * The global organization ID of the user that viewed the page.
   */
  organizationId?: null | string
  [property: string]: any
}

/**
 * A customer replied to a live chat conversation
 */
export interface LiveChatCustomerReplied {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The name of the advocate assigned to the conversation
   */
  conversationAssigneeName?: null | string
  /**
   * The ID associated with the specific Help Scout conversation
   */
  conversationId: string
  /**
   * A list of tags applied to the conversation
   */
  conversationTags?: any[] | null
  /**
   * A link to the specific Help Scout conversation
   */
  conversationUrl?: null | string
  /**
   * The ID of the mailbox the conversation was created in
   */
  mailboxId: string
  /**
   * The global organization ID of the user that viewed the page.
   */
  organizationId?: null | string
  [property: string]: any
}

/**
 * When a login verification flow is abandonded (e.g. the user did not validate their JWT,
 * and instead tried to login again)
 */
export interface LoginVerificationAbandoned {
  /**
   * The accountId of the user
   */
  accountId: string
  /**
   * The current email of the user.
   */
  email: string
  /**
   * The time (in minutes) since the most recent login verification email was sent to the user
   */
  minutesSinceLoginVerificationRequested: number
  /**
   * The current organizationID of the user
   */
  organizationId: null | string
  [property: string]: any
}

/**
 * When a login verification flow is completed.
 */
export interface LoginVerificationComplete {
  /**
   * The accountId of the user
   */
  accountId: string
  /**
   * The current email of the user.
   */
  email: string
  /**
   * The number of login attempts cleaned from the database after successful verification.
   */
  loginAttemptsCleaned?: number
  /**
   * The current organizationID of the user
   */
  organizationId: null | string
  [property: string]: any
}

/**
 * When a login verification flow is initiated.
 */
export interface LoginVerificationInitiated {
  /**
   * The accountId of the user
   */
  accountId: string
  /**
   * The current email of the user.
   */
  email: string
  /**
   * The current organizationID of the user
   */
  organizationId: null | string
  [property: string]: any
}

/**
 * When did the user opt-out of receiving marketing emails?
 */
export interface MarketingEmailsDisabled {
  /**
   * The organization ID of the user that disabled marketing emails
   */
  organizationId?: string
  properties: any
  [property: string]: any
}

/**
 * When did the user opt-in to receiving marketing emails?
 */
export interface MarketingEmailsEnabled {
  /**
   * The organization ID of the user that enabled marketing emails
   */
  organizationId?: string
  properties: any
  [property: string]: any
}

/**
 * When a user dismisses the Mastodon Channel promo banner
 */
export interface MastodonChannelPromoDismissed {
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product: string
  [property: string]: any
}

/**
 * Where did the user attach a media item from
 */
export interface MediaAdded {
  /**
   * What was the unique identifier of the client the user was in when they attached the media
   * item?
   */
  clientId?: null | string
  /**
   * Which client was the user in when they attached the media item? IE, `publishIos`,
   * `publishWeb`, `publishAndroid`.
   */
  clientName: string
  /**
   * The organization ID of the user that added the media
   */
  organizationId?: string
  /**
   * The product for which the media item was attached in
   */
  product: string
  /**
   * The selected source for media
   */
  source: string
  [property: string]: any
}

/**
 * What source did the user select to attach media from
 */
export interface MediaSourceSelected {
  /**
   * What was the unique identifier of the client the user was in when they attached the media
   * item?
   */
  clientId?: null | string
  /**
   * Which client was the user in when they attached the media item? IE, `publishIos`,
   * `publishWeb`, `publishAndroid`.
   */
  clientName: string
  /**
   * The organization ID of the user that selected the media source
   */
  organizationId?: string
  /**
   * The product for which the media source was selected
   */
  product: string
  /**
   * The selected source for media
   */
  source: string
  [property: string]: any
}

/**
 * The warning message displayed when media validation fails
 */
export interface MediaValidationWarningMessageViewed {
  /**
   * The channel that the media was intended for.
   */
  channel: string
  /**
   * Which client was the user in when the banner was viewed?
   */
  clientName?: string
  /**
   * The message displayed to the user.
   */
  message?: null | string
  [property: string]: any
}

/**
 * When did the user view the migration checkout modal?
 */
export interface MigrationCheckoutModalViewed {
  /**
   * If the user navigated to this page from a CTA on another Buffer page, which call to
   * action was it?
   */
  cta?: null | string
  /**
   * Which product or website is the CTA located in? Examples would be either the product,
   * like `publish`, or the website, like `bufferSite`
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA?  In many cases it makes sense to describe the
   * intended result of the CTA, like `proTrial` or `publishPro`
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in
   * Publish app shell menu
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA?  If setting the value for the first time, set to
   * `1`
   */
  ctaVersion?: null | string
  /**
   * What website page or app view is the CTA located?  Examples would be, `composer` or
   * `analyticsOverview` for Publish, and `pricingPublish` for the Publish pricing page on the
   * Buffer marketing site
   */
  ctaView?: null | string
  /**
   * The plan price interval a customer migrates to.
   */
  interval?: null | string
  /**
   * The price of the legacy Multi Product plan
   */
  legacyPlanPrice?: number | null
  /**
   * The global organization ID of the user that viewed the migration page.
   */
  organizationId: null | string
  /**
   * The plan name the customer migrates to.
   */
  plan?: null | string
  /**
   * The plan quantity (channel slots) that the customer is purchasing with the migration.
   */
  quantity?: number | null
  [property: string]: any
}

/**
 * When did the user view the migration page?
 */
export interface MigrationPageViewed {
  /**
   * If the user can migrate the legacy organization to New Buffer or not.
   */
  canMigrate?: boolean | null
  /**
   * If the user navigated to this page from a CTA on another Buffer page, which call to
   * action was it?
   */
  cta?: null | string
  /**
   * Which product or website is the CTA located in? Examples would be either the product,
   * like `publish`, or the website, like `bufferSite`
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA?  In many cases it makes sense to describe the
   * intended result of the CTA, like `proTrial` or `publishPro`
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in
   * Publish app shell menu
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA?  If setting the value for the first time, set to
   * `1`
   */
  ctaVersion?: null | string
  /**
   * What website page or app view is the CTA located?  Examples would be, `composer` or
   * `analyticsOverview` for Publish, and `pricingPublish` for the Publish pricing page on the
   * Buffer marketing site
   */
  ctaView?: null | string
  /**
   * The Stripe customer discount id, if any.
   */
  discountId?: null | string
  /**
   * The global organization ID of the user that viewed the migration page.
   */
  organizationId: null | string
  /**
   * A list of the reasons an organization cannot migrate, if applicable.
   */
  reasons?: any[] | null
  [property: string]: any
}

/**
 * When did the user dismiss a modal?
 */
export interface ModalDismissed {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * At time of event, did the user have payment details saved?
   */
  hasPaymentDetails: boolean | null
  /**
   * The method by which the user dismissed the modal.
   */
  method?: null | string
  /**
   * The name of the modal that the user dismissed.
   */
  modalName: null | string
  /**
   * The global organization ID of the user that viewed the plan selector.
   */
  organizationId: null | string
  [property: string]: any
}

/**
 * When did the user open the payment modal?
 */
export interface ModalPaymentOpened {
  /**
   * Which call to action did the user click to get to the payment modal?
   */
  cta?: null | string
  /**
   * Which product is the CTA located in? Examples would be either the product, like `publish`.
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA?  In many cases it makes sense to describe the
   * intended result of the CTA, like `proTrial` or `publishPro`.
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in
   * Publish app shell menu.
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA?  If setting the value for the first time, set to
   * `1`.
   */
  ctaVersion?: null | string
  /**
   * What app view is the CTA located in?  Examples would be, `composer` or
   * `analyticsOverview` for Publish.
   */
  ctaView?: null | string
  /**
   * The Global organization ID.
   */
  organizationId?: string
  /**
   * What is the ID of the billing plan that the user selected to purchase?
   */
  planId?: null | string
  /**
   * What is the name of the billing plan that the user selected to purchase?
   */
  planName?: null | string
  /**
   * What product did this event occur in or for?
   */
  product: string
  [property: string]: any
}

/**
 * When a user submits an NPS survey through Wootric.
 */
export interface NPSSurveyCompleted {
  /**
   * The email address of the user that completed the survey
   */
  email?: string
  /**
   * The global organization ID of the user that completed the survey.
   */
  organizationId: string
  /**
   * The plan of the user that completed the survey (ex. "Pro")
   */
  plan: string
  /**
   * The platform the user was in when they completed the survey (ex. "web").
   */
  platform?: string
  /**
   * Which product did the user fill out the survey in?
   */
  product: string
  /**
   * The NPS score given by the respondent.
   */
  score: number
  /**
   * The provider of the NPS survey (ex. "Wootric").
   */
  surveyProvider: string
  /**
   * The reason given by the user for the NPS score.
   */
  text: null | string
  [property: string]: any
}

/**
 * When a user has added a Note
 */
export interface NoteAdded {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: null | string
  /**
   * The database id for the channel document
   */
  channelId: null | string
  /**
   * The type of channel the note is created for, ex. "page", "group"
   */
  channelType: null | string
  /**
   * What is the username of the channel?
   */
  channelUsername: null | string
  /**
   * Which client was the user in when the Note was created?
   */
  clientName: string
  /**
   * The list of email of all persons involved in the thread who are not the not author
   */
  collaborators: any[]
  /**
   * The email for the account of the creator of the entity
   */
  entityCreatorEmail: string
  /**
   * The database id for the account of the creator of the entity
   */
  entityCreatorId: string
  /**
   * The database id for the entity document where the Note was added (postId, ideaId, ...)
   */
  entityId: string
  /**
   * the state of the parent entity at the moment of the Note (e.g.,
   * draft|scheduled|sent|rejected|archived|deleted)
   */
  entityStatus: null | string
  /**
   * The content of the parent entity
   */
  entityText: string
  /**
   * The type of the parent entity where the Note was added
   */
  entityType: string
  /**
   * The link to the Note in the app
   */
  noteDeepLink: string
  /**
   * The database id for the Note document
   */
  noteId: string
  /**
   * The content of the Note
   */
  noteText: string
  /**
   * The type of Note (userGenerated, bufferGenerated, ...)
   */
  noteType: string
  /**
   * The global organization id that Note belongs to.
   */
  organizationId: string
  [property: string]: any
}

/**
 * When a user has deleted a Note
 */
export interface NoteDeleted {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: null | string
  /**
   * The database id for the channel document
   */
  channelId: null | string
  /**
   * The type of channel the note is created for, ex. "page", "group"
   */
  channelType: null | string
  /**
   * What is the username of the channel?
   */
  channelUsername: null | string
  /**
   * Which client was the user in when the Note was created?
   */
  clientName: string
  /**
   * The list of email of all persons involved in the thread who are not the not author
   */
  collaborators: any[]
  /**
   * The email for the account of the creator of the entity
   */
  entityCreatorEmail: string
  /**
   * The database id for the account of the creator of the entity
   */
  entityCreatorId: string
  /**
   * The database id for the entity document where the Note was deleted (postId, ideaId, ...)
   */
  entityId: string
  /**
   * the state of the parent entity at the moment of the Note (e.g.,
   * draft|scheduled|sent|rejected|archived|deleted)
   */
  entityStatus: null | string
  /**
   * The content of the parent entity
   */
  entityText: string
  /**
   * The type of the parent entity where the Note was deleted
   */
  entityType: string
  /**
   * The link to the Note in the app
   */
  noteDeepLink: string
  /**
   * The database id for the Note document
   */
  noteId: string
  /**
   * The content of the Note
   */
  noteText: string
  /**
   * The type of Note (userGenerated, bufferGenerated, ...)
   */
  noteType: string
  /**
   * The global organization id that Note belongs to.
   */
  organizationId: string
  [property: string]: any
}

/**
 * When a user has edited a Note
 */
export interface NoteEdited {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: null | string
  /**
   * The database id for the channel document
   */
  channelId: null | string
  /**
   * The type of channel the note is created for, ex. "page", "group"
   */
  channelType: null | string
  /**
   * What is the username of the channel?
   */
  channelUsername: null | string
  /**
   * Which client was the user in when the Note was created?
   */
  clientName: string
  /**
   * The list of email of all persons involved in the thread who are not the not author
   */
  collaborators: any[]
  /**
   * The email for the account of the creator of the entity
   */
  entityCreatorEmail: string
  /**
   * The database id for the account of the creator of the entity
   */
  entityCreatorId: string
  /**
   * The database id for the entity document where the Note was edited (postId, ideaId, ...)
   */
  entityId: string
  /**
   * the state of the parent entity at the moment of the Note (e.g.,
   * draft|scheduled|sent|rejected|archived|deleted)
   */
  entityStatus: null | string
  /**
   * The content of the parent entity
   */
  entityText: string
  /**
   * The type of the parent entity where the Note was edited
   */
  entityType: string
  /**
   * The link to the Note in the app
   */
  noteDeepLink: string
  /**
   * The database id for the Note document
   */
  noteId: string
  /**
   * The content of the Note
   */
  noteText: string
  /**
   * The type of Note (userGenerated, bufferGenerated, ...)
   */
  noteType: string
  /**
   * The global organization id that Note belongs to.
   */
  organizationId: string
  [property: string]: any
}

/**
 * When a the Note Feed is viewed by a user
 */
export interface NoteFeedViewed {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: null | string
  /**
   * The database id for the channel document
   */
  channelId: null | string
  /**
   * The type of channel for, ex. "page", "group"
   */
  channelType: null | string
  /**
   * What is the username of the channel?
   */
  channelUsername: null | string
  /**
   * Which client was the user in when the Note Feed was viewed?
   */
  clientName: string
  /**
   * The database id for the entity document (postId, ideaId, ...) of the Note Feed
   */
  entityId: string
  /**
   * the state of the parent entity at the moment of the Note Feed is opened (e.g.,
   * draft|scheduled|sent|rejected|archived|deleted)
   */
  entityStatus: null | string
  /**
   * The content of the parent entity
   */
  entityText: string
  /**
   * The type of the parent entity of the Note Feed
   */
  entityType: string
  /**
   * The global organization id that Note Feed belongs to.
   */
  organizationId: string
  [property: string]: any
}

/**
 * When a user interacts with the notifications permission screen during onboarding.
 */
export interface OnboardingNotificationPermissionSelected {
  /**
   * The mobile client the user is on, `publishIos` or `publishAndroid`.
   */
  clientName: string
  /**
   * Did the user select to enable notifications?
   */
  enabled: boolean
  /**
   * Which Buffer product was the notification for?
   */
  product: string
  [property: string]: any
}

/**
 * When did this user skip the onboarding experience?
 */
export interface OnboardingSkipped {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The ID of the organization for which the onboarding was skipped
   */
  organizationId?: string
  /**
   * Which product was the onboarding skipped for?
   */
  product: string
  /**
   * Which onboarding step was the user in when they decided to skip?
   */
  step?: string
  [property: string]: any
}

/**
 * When a prospective user views a slide within the Onboarding.
 */
export interface OnboardingSlideViewed {
  /**
   * The mobile client the user is on, `publishIos` or `publishAndroid`.
   */
  clientName: string
  /**
   * Which Buffer product was the notification for?
   */
  product: string
  /**
   * Which slide did the user view?
   */
  slide: string
  [property: string]: any
}

/**
 * When a user completes the onboarding survey and clicks in Done button.
 */
export interface OnboardingSurveyCompleted {
  /**
   * The name of the client that the event occurred in.
   */
  clientName: string
  /**
   * The global organization ID of the user that viewed the product solutions.
   */
  organizationId: string
  /**
   * The product in which this action occurred.
   */
  product: string
  /**
   * The answers selected by the user for the first question.
   */
  questionOneAnswers: any[]
  /**
   * The id of the first question of the survey. It is a meaningful id that identifies the
   * question.
   */
  questionOneId: string
  /**
   * The answers selected by the user for the third question.
   */
  questionThreeAnswers?: any[]
  /**
   * The id of the third question of the survey. It is a meaningful id that identifies the
   * question.
   */
  questionThreeId?: string
  /**
   * The answers selected by the user for the second question.
   */
  questionTwoAnswers?: any[]
  /**
   * The id of the second question of the survey. It is a meaningful id that identifies the
   * question.
   */
  questionTwoId?: string
  /**
   * Identifies the survey answered by the user.
   */
  surveyId: string
  [property: string]: any
}

/**
 * When a user clicks in the Skip button in the onboarding survey.
 */
export interface OnboardingSurveySkipped {
  /**
   * The name of the client that the event occurred in.
   */
  clientName: string
  /**
   * The global organization ID of the user that viewed the product solutions.
   */
  organizationId: string
  /**
   * The product in which this action occurred.
   */
  product: string
  /**
   * Identifies the survey answered by the user.
   */
  surveyId: string
  [property: string]: any
}

/**
 * When a user views the onboarding survey.
 */
export interface OnboardingSurveyViewed {
  /**
   * The name of the client that the event occurred in.
   */
  clientName: string
  /**
   * The global organization ID of the user that viewed the product solutions.
   */
  organizationId: string
  /**
   * The product in which this action occurred.
   */
  product: string
  /**
   * Identifies the survey answered by the user.
   */
  surveyId: string
  [property: string]: any
}

/**
 * When a user views the onboarding trial prompt.
 */
export interface OnboardingTrialPromptViewed {
  /**
   * The name of the client that the event occurred in.
   */
  clientName: string
  /**
   * The global organization ID of the user that viewed the product solutions.
   */
  organizationId: string
  /**
   * The product in which this action occurred.
   */
  product: string
  [property: string]: any
}

/**
 * When was the organization migrated from multi-product Buffer to One Buffer? This event
 * will be attributed to the org owner in all three migration methods (advocacy, self
 * service, and scripts). This event tracks the migration to One Buffer at the organization
 * level, as the organization is the determining factor for a user to access One Buffer.  A
 * user can be a member of more than one organization, and those organizations can be a
 * mixture of One Buffer enabled / not-enabled.
 */
export interface OneBufferMigrated {
  /**
   * What was the Buffer global account id that created the organization? This is the
   * organization owner in the multi-product organization structure.
   */
  createdByAccountId: string
  /**
   * Binary option if their price went up. Same price or lower price == false.
   */
  didPriceIncrease?: boolean | null
  /**
   * How was the user migrated to One Buffer?  Did a Buffer Customer Advocate help the org
   * owner migrate, did the org owner migrate themselves via a button in-app, or was the org
   * migrated via a database script?
   */
  migrationMethod: string
  /**
   * The id of the Global organization that was created.
   */
  organizationId: string
  /**
   * What was the One Buffer plan the organization was migrated to? Plan names the
   * organization was migrated to should be free, essentials, essentials + team, or a stripe
   * plan id.
   */
  planMigratedTo?: null | string
  /**
   * The absolute difference in old price vs new price. May be positive (they pay more on New
   * Buffer) or negative (they pay less on New Buffer).
   */
  priceDifferential?: number | null
  [property: string]: any
}

/**
 * When was the organization id created in the production database?
 */
export interface OrganizationCreated {
  /**
   * What was the Buffer global account id that created the organization?
   */
  createdByAccountId?: string
  /**
   * The id of the Global organization that was created.
   */
  organizationId: string
  /**
   * Which plan was the organization on at time of being created?
   */
  organizationPlanId?: string
  /**
   * Which product was the organization created for?
   */
  product?: string
  [property: string]: any
}

/**
 * When was the organization id deleted in the production database?
 */
export interface OrganizationDeleted {
  /**
   * What was the Buffer global account id that deleted the organization?
   */
  createdByAccountId?: string
  /**
   * The id of the Global organization that was deleted.
   */
  organizationId: string
  /**
   * Which plan was the organization on at time of being deleted? This will only be populated
   * for One Buffer organizations, as multi-product organizations can have multiple
   * subscriptions.
   */
  organizationPlanId?: string
  [property: string]: any
}

/**
 * A user switches organizations
 */
export interface OrganizationSwitched {
  /**
   * The client in which the action occurred, `publishIos`, `publishAndroid`or `publishWeb`.
   */
  clientName?: string
  /**
   * The ID of the global organization that the user switched to.
   */
  organizationId: string
  /**
   * The product in which the action occured
   */
  product: string
  [property: string]: any
}

/**
 * When was an organization's timezone updated?
 */
export interface OrganizationTimezoneUpdated {
  /**
   * The active organization of the user that took the action
   */
  organizationId: string
  /**
   * What was the organization's previous timezone?
   */
  previousTimezone?: null | string
  /**
   * What is the organization's new timezone?
   */
  timezone: string
  /**
   * How was the timezone updated? (e.g. user-initiated, backend via first post, or frontend
   * via appshell)
   */
  updateMethod: string
  [property: string]: any
}

/**
 * When the tokens of an organization are over due to usage or subscription downgrade.
 */
export interface OrganizationTokensExhausted {
  /**
   * What is the number of the purchased channels of the organization?
   */
  currentChannelSlots: number
  /**
   * What was the One Buffer plan of the the organization?
   */
  currentPlan: string
  /**
   * Was the organization on a a Free trial when the tokens were exhausted?
   */
  isTrial: boolean
  /**
   * The organization ID
   */
  organizationId: string
  /**
   * What is the number of the purchased channels of the organization?
   */
  previousChannelSlots?: number | null
  /**
   * What was the One Buffer plan of the the organization?
   */
  previoustPlan?: null | string
  /**
   * Did the customer run out of tokens based on usage, due to a downgrade?
   */
  reason: string
  /**
   * What is the limit of the current plan?
   */
  tokensLimit: number
  /**
   * What was the value of the Organization tokens used at the moment that they were exhausted?
   */
  usedTokensCount: number
  [property: string]: any
}

/**
 * When did the user export their data from the Overview report?
 */
export interface OverviewAnalyticsDataExported {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The id of the channel on the given service
   */
  channelServiceId: string
  /**
   * The type of channel the post is created for, ex. "page", "group"
   */
  channelType: string
  /**
   * What was the username of the channel?
   */
  channelUsername?: string
  /**
   * Did the user export a CSV or image files?
   */
  fileType: string
  /**
   * The ID of the Global organization that owns the profile.
   */
  organizationId?: string
  /**
   * The product in which the action occured
   */
  product: string
  [property: string]: any
}

/**
 * When did the user update the metric displayed in the Overview report?
 */
export interface OverviewAnalyticsMetricUpdated {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The id of the channel on the given service
   */
  channelServiceId: string
  /**
   * The type of channel the post is created for, ex. "page", "group"
   */
  channelType: string
  /**
   * What was the username of the channel?
   */
  channelUsername?: string
  /**
   * What metric did the user switch to?
   */
  metric: string
  /**
   * The ID of the Global organization that owns the profile.
   */
  organizationId?: string
  /**
   * The product in which the action occured
   */
  product: string
  [property: string]: any
}

/**
 * When a user selects a metric in the overview tab in Analyze
 */
export interface OverviewInsightsMetricSelected {
  /**
   * The channel of the selected profile, ex. “instagram”.
   */
  channel: string
  /**
   * The ID of the channel selected when the metric was selected.
   */
  channelId: string
  /**
   * The name of the client in which the action occurred.
   */
  clientName: string
  /**
   * Which metric was selected?
   */
  metric: string
  /**
   * The global organization ID of the user that took the action.
   */
  organizationId: string
  /**
   * The product in which the event occurred, ex. “analyze”.
   */
  product: string
  [property: string]: any
}

/**
 * When a user toggles previous period in Analyze.
 */
export interface OverviewInsightsPreviousPeriodToggled {
  /**
   * The channel of the selected profile, ex. “instagram”.
   */
  channel: string
  /**
   * The ID of the channel selected when the previous period was toggled.
   */
  channelId: string
  /**
   * The name of the client in which the action occurred.
   */
  clientName: string
  /**
   * The global organization ID of the user that took the action.
   */
  organizationId: string
  /**
   * The product in which the event occurred, ex. “analyze”.
   */
  product: string
  [property: string]: any
}

/**
 * When an image created in Pablo is downloaded
 */
export interface PabloImageDownloaded {
  /**
   * The size of the downloaded image
   */
  imageSize?: null | string
  /**
   * The global organization id of the user that took this action
   */
  organizationId?: null | string
  properties: any
  [property: string]: any
}

/**
 * When an image created in Pablo is shared to Buffer
 */
export interface PabloImageShared {
  /**
   * The size of the downloaded image
   */
  imageSize?: null | string
  /**
   * The global organization id of the user that took this action
   */
  organizationId?: null | string
  properties: any
  [property: string]: any
}

/**
 * How are people using the data export?
 */
export interface PageExported {
  /**
   * Channel of the page, if any (e.g., ""facebook"", ""instagram"", etc.)
   */
  channel: string
  /**
   * Number of days the report is showing
   */
  days?: number
  /**
   * The format of the export (ex. "csv", "images")
   */
  exportType?: string
  /**
   * Human readable name of the page (e.g., ""overview"", ""posts"", etc.)
   */
  name?: string
  /**
   * The id of the Global organization that owns the channel.
   */
  organizationId?: string
  /**
   * The product in which the event happened
   */
  product?: string
  [property: string]: any
}

/**
 * When did the user view this specific page? This event is triggered when a user opens a
 * new section of the application that is considered a distinct page, which may or may not
 * change the URL.
 */
export interface PageViewed {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * Channel of the page, if applicable (e.g., ""facebook"", ""instagram"", etc.)
   */
  channel?: null | string
  /**
   * The database id for the channel document
   */
  channelId?: null | string
  /**
   * The id of the channel on the given service
   */
  channelServiceId?: null | string
  /**
   * What is the type of channel? ex. "page", "group"
   */
  channelType?: null | string
  /**
   * If the user navigated to this page from a CTA on another Buffer page, which call to
   * action was it?
   */
  cta?: null | string
  /**
   * Which product or website is the CTA located in? Examples would be either the product,
   * like `publish`, or the website, like `bufferSite`
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA?  In many cases it makes sense to describe the
   * intended result of the CTA, like `proTrial` or `publishPro`
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in
   * Publish app shell menu
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA?  If setting the value for the first time, set to
   * `1`
   */
  ctaVersion?: null | string
  /**
   * What website page or app view is the CTA located?  Examples would be, `composer` or
   * `analyticsOverview` for Publish, and `pricingPublish` for the Publish pricing page on the
   * Buffer marketing site
   */
  ctaView?: null | string
  /**
   * Human readable name of the page (e.g., ""overview"", ""posts"", etc.)
   */
  name?: null | string
  /**
   * The global organization ID of the user that viewed the page.
   */
  organizationId?: null | string
  /**
   * The path typically refers to a file or directory on the web server.
   */
  path: null | string
  /**
   * The platform on which the page view occurred (e.g. ""classic"", ""new_publish"",
   * ""marketing"", ""ios"")
   */
  platform?: null | string
  /**
   * The product in which the page view occurred (e.g. ""publish"", ""analyze"")
   */
  product?: string
  /**
   * At time of event, does the user have a unique ReferralCode cookie in their session?
   */
  referralCode?: null | string
  /**
   * The address of the webpage which is linked to the resource being requested. By checking
   * the referrer, the new webpage can see where the request originated.
   */
  referrer?: null | string
  /**
   * Anything after and including the query string.
   */
  search?: null | string
  /**
   * The text that is visible on the browser title bar (or tab bar) which matches the
   * <title></title> html tag that should be on all pages.
   */
  title?: null | string
  /**
   * The address of the page.
   */
  url?: string
  [property: string]: any
}

/**
 * When a user updates their Buffer password.
 */
export interface PasswordUpdated {
  /**
   * The name of the client that the event occurred in
   */
  clientName: string
  /**
   * The organization ID of the user that completed the action
   */
  organizationId: string
  /**
   * Which product did the user complete this action in? (e.g. core)
   */
  product: string
  [property: string]: any
}

/**
 * When did the user access the filter for past story groups in the Past Reminders tab?
 */
export interface PastRemindersStoryGroupsFilterClicked {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The service's own id for this channel
   */
  channelServiceId: string
  /**
   * What was the unique identifier of the client the user was in when they accessed the past
   * story groups filter?
   */
  clientId?: null | string
  /**
   * Which client was the user in when they dragged the story? IE, `publishIos`, `publishWeb`,
   * `publishAndroid`.
   */
  clientName: string
  /**
   * The Global organization ID.
   */
  organizationId: string
  /**
   * The product in which the past story groups were viewed
   */
  product: string
  [property: string]: any
}

/**
 * Understand when a user adds a payment method
 */
export interface PaymentMethodAdded {
  /**
   * Uniquely identifies the particular card number
   */
  cardFingerprint?: string
  /**
   * The cta which prompted the payment method update
   */
  cta?: null | string
  /**
   * Unique identifier for the Stripe customer
   */
  customerId?: string
  /**
   * The date the payment method expires on (ISO-8601)
   */
  expirationDate?: string
  /**
   * What triggered this event? e.g. Stripe or RevenueCat
   */
  gateway?: string
  /**
   * Was the payment method added via Stripe Checkout?
   */
  isStripeCheckout?: boolean | null
  /**
   * The ID of the organization for which the payment method was added.
   */
  organizationId?: string
  /**
   * The type of payment method added. Typically this would be 'visa', 'mastercard', etc.
   */
  paymentMethod: string
  /**
   * Stripe event id from the webhook event
   */
  stripeEventId: string
  /**
   * The upgrade path which prompted the payment method update
   */
  upgradePathName?: null | string
  [property: string]: any
}

/**
 * Is this payment method expiring in the next 30 days?
 */
export interface PaymentMethodExpiring {
  /**
   * Uniquely identifies the particular card number
   */
  cardFingerprint?: string
  /**
   * Unique identifier for the Stripe customer
   */
  customerId?: string
  /**
   * The number of days until this payment method expires
   */
  daysToExpiry?: number
  /**
   * The date the payment method expires on (ISO-8601)
   */
  expirationDate?: string
  /**
   * What triggered this event? e.g. Stripe or RevenueCat
   */
  gateway?: string
  /**
   * The ID of the organization for which the payment method was added.
   */
  organizationId: string
  /**
   * The type of payment method expiring. Typically this would be 'visa', 'mastercard', etc.
   */
  paymentMethod: string
  /**
   * Stripe event id from the webhook event
   */
  stripeEventId: string
  [property: string]: any
}

/**
 * Understand when a user updates a payment method
 */
export interface PaymentMethodUpdated {
  /**
   * Uniquely identifies the particular card number
   */
  cardFingerprint?: string
  /**
   * The cta which prompted the payment method update
   */
  cta?: null | string
  /**
   * Unique identifier for the Stripe customer
   */
  customerId?: string
  /**
   * The date the payment method expires on (ISO-8601)
   */
  expirationDate?: string
  /**
   * What triggered this event? e.g. Stripe or RevenueCat
   */
  gateway?: string
  /**
   * Was the payment method added via Stripe Checkout?
   */
  isStripeCheckout?: boolean | null
  /**
   * The ID of the organization for which the payment method was added.
   */
  organizationId?: string
  /**
   * The type of payment method added. Typically this would be 'visa', 'mastercard', etc.
   */
  paymentMethod: string
  /**
   * Stripe event id from the webhook event
   */
  stripeEventId: string
  /**
   * The upgrade path which prompted the payment method update
   */
  upgradePathName?: null | string
  [property: string]: any
}

/**
 * When did the user play a video?
 */
export interface PaywallVideoPlayed {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * The global organization ID of the user that viewed the plan selector.
   */
  organizationId: null | string
  /**
   * Which product was the user in when they logged out?
   */
  product: string
  /**
   * Percentage of video watched
   */
  progress?: number | null
  /**
   * The name of the upgrade path
   */
  upgradePathName: null | string
  [property: string]: any
}

/**
 * When the user is presented with our plan limit alert.
 */
export interface PlanLimitAlertViewed {
  /**
   * The channel's service, ex. "facebook".
   */
  channel?: string
  /**
   * The database id of the channel for which the story was created.
   */
  channelId?: string
  /**
   * The service's own id for this channel.
   */
  channelServiceId?: string
  /**
   * The type of channel ex. "page", "group".
   */
  channelType?: string
  /**
   * The mobile client the user is on, `publishIos` or `publishAndroid`.
   */
  clientName: string
  /**
   * The action the user took to produce the upgrade view, such as "profile_limit".
   */
  prompt?: string
  [property: string]: any
}

/**
 * How many channels did the user select on the plan selector?
 */
export interface PlanSelectorChannelQuantityClicked {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The number of channels the user selected in the plan selector.
   */
  channelQuantity?: number | null
  /**
   * At time of event, did the user have payment details saved?
   */
  hasPaymentDetails: boolean | null
  /**
   * The global organization ID of the user that viewed the plan selector.
   */
  organizationId: null | string
  [property: string]: any
}

/**
 * When did the user click the plan selector main cta?
 */
export interface PlanSelectorMainCtaClicked {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The selected number of channels at the time the CTA button was clicked.
   */
  channelQuantity?: number | null
  /**
   * The cta that opened the plan selector.
   */
  cta?: null | string
  /**
   * The text on the plan selector CTA button.
   */
  ctaButtonText?: null | string
  /**
   * The selected billing cycle at the time the CTA button was clicked.
   */
  cycle?: null | string
  /**
   * At time of event, did the user have payment details saved?
   */
  hasPaymentDetails: boolean | null
  /**
   * What was the initial channel quantity shown on the plan selector when opened?
   */
  initialChannelQuantity?: number | null
  /**
   * What was the initial billing cycle shown on the plan selector when opened?
   */
  initialCycle?: null | string
  /**
   * What was the initial selected plan shown on the plan selector when opened?
   */
  initialSelectedPlan?: null | string
  /**
   * Is automatic tax collection in Stripe enabled for this user?
   */
  isAutomaticTaxEnabled?: boolean | null
  /**
   * The global organization ID of the user that viewed the plan selector.
   */
  organizationId: null | string
  /**
   * The projected cost of the selected plan at the time the CTA button was clicked.
   */
  projectedCost?: number | null
  /**
   * The selected plan at the time the CTA button was clicked.
   */
  selectedPlanName?: null | string
  /**
   * The name of the upgrade path
   */
  upgradePathName?: null | string
  [property: string]: any
}

/**
 * Which plan did the user click within the Plan Selector?
 */
export interface PlanSelectorPlanClicked {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The selected number of channels at the time the CTA button was clicked.
   */
  channelQuantity?: number | null
  /**
   * At time of event, did the user have payment details saved?
   */
  hasPaymentDetails: boolean | null
  /**
   * The global organization ID of the user that viewed the plan selector.
   */
  organizationId: null | string
  /**
   * The name of the plan on which the user clicked.
   */
  selectedPlanName?: null | string
  [property: string]: any
}

/**
 * Which cycle did the user toggle within the Plan Selector?
 */
export interface PlanSelectorPlanCycleClicked {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The selected number of channels at the time the CTA button was clicked.
   */
  channelQuantity?: number | null
  /**
   * The name of the billing cycle the user toggled.
   */
  cycle?: null | string
  /**
   * At time of event, did the user have payment details saved?
   */
  hasPaymentDetails: boolean | null
  /**
   * The global organization ID of the user that viewed the plan selector.
   */
  organizationId: null | string
  [property: string]: any
}

/**
 * When did the user view the billing plan selector module, and which screen of the module
 * did they view?
 */
export interface PlanSelectorViewed {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * What was the name of the client the user was in when they viewed the plan selector
   */
  clientName?: null | string
  /**
   * If the user navigated to this page from a CTA on another Buffer page, which call to
   * action was it?
   */
  cta?: null | string
  /**
   * Which product or website is the CTA located in? Examples would be either the product,
   * like `publish`, or the website, like `bufferSite`
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA?  In many cases it makes sense to describe the
   * intended result of the CTA, like `proTrial` or `publishPro`
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in
   * Publish app shell menu
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA?  If setting the value for the first time, set to
   * `1`
   */
  ctaVersion?: null | string
  /**
   * What website page or app view is the CTA located?  Examples would be, `composer` or
   * `analyticsOverview` for Publish, and `pricingPublish` for the Publish pricing page on the
   * Buffer marketing site
   */
  ctaView?: null | string
  /**
   * The current plan of the user at time of viewing the plan selector.
   */
  currentPlan?: null | string
  /**
   * At time of event, did the user have payment details saved?
   */
  hasPaymentDetails?: boolean | null
  /**
   * What was the initial channel quantity shown on the plan selector when opened?
   */
  initialChannelQuantity?: number | null
  /**
   * What was the initial billing cycle shown on the plan selector when opened?
   */
  initialCycle?: null | string
  /**
   * What was the initial selected plan shown on the plan selector when opened?
   */
  initialSelectedPlan?: null | string
  /**
   * Is automatic tax collection in Stripe enabled for this user?
   */
  isAutomaticTaxEnabled?: boolean | null
  /**
   * Will the user be sent to Stripe Checkout for payment?
   */
  isStripeCheckout?: boolean | null
  /**
   * The global organization ID of the user that viewed the plan selector.
   */
  organizationId: null | string
  /**
   * Human readable name of the section of the plan selector viewed (e.g., ""plans"",
   * ""billing_info"", etc.)
   */
  screenName?: null | string
  /**
   * The name of the upgrade path
   */
  upgradePathName?: null | string
  [property: string]: any
}

/**
 * When a user clicks on a key action in the post
 */
export interface PostCardActionClicked {
  /**
   * The account ID
   */
  accountId: string
  /**
   * The action name the user clicked on
   */
  action: string
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName?: string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The post ID with the copied link
   */
  postId: string
  [property: string]: any
}

/**
 * A user has created a post
 */
export interface PostCreated {
  /**
   * Whether this post was assisted with AI
   */
  aiAssisted?: boolean | null
  /**
   * The design id if a Canva image is attached to the post
   */
  canvaDesignId?: null | string
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The id of the channel on the given service
   */
  channelServiceId: string
  /**
   * The type of channel the post is created for, ex. "page", "group"
   */
  channelType: string
  /**
   * What client sent the post
   */
  client?: null | string
  /**
   * Which client was the user in when the Post was created?
   */
  clientName?: string
  /**
   * Where the composer was opened from
   */
  composerSource?: null | string
  /**
   * Post id of the original post that was duplicated
   */
  duplicatedFrom?: string
  /**
   * Post Type when the post is for Google Business Profile channel (e.g. What's New)
   */
  googleBusinessPostType?: null | string
  /**
   * Whether this post includes a Buffer hashtag
   */
  hasBufferHashtag?: boolean
  /**
   * Does the post contain an image from the Canva intergration?
   */
  hasCanvaImage?: boolean
  /**
   * Mastodon content warning added to this update
   */
  hasContentWarning?: boolean | null
  /**
   * If a first comment added to this post
   */
  hasFirstComment: boolean
  /**
   * Whether the post has LinkedIn annotations
   */
  hasLinkAnnotations?: boolean
  /**
   * If a geolocation tag was added to the post
   */
  hasLocation: boolean
  /**
   * The post has either video or image media
   */
  hasMedia?: boolean
  /**
   * Whether the post has mentions
   */
  hasMentions?: boolean
  /**
   * If the update has pictures
   */
  hasPictures?: boolean
  /**
   * If a link was added for the channel's Shop Grid page
   */
  hasShopGridLink: boolean
  /**
   * Does the post contain an image from the Uploadcare intergration?
   */
  hasUploadcareImage?: boolean
  /**
   * Does this post include a user tag?
   */
  hasUserTag?: boolean
  /**
   * If the update has video
   */
  hasVideo?: boolean
  /**
   * The idea id from which the post was created
   */
  ideaId?: string
  /**
   * The dimensions of the image or images attached to the post
   */
  imageDimensions?: any[] | null
  /**
   * Image file extension
   */
  imageFileExtension?: string
  /**
   * If the post created as a draft
   */
  isDraft: boolean
  /**
   * Whether this is a Reel post for instagram or a regular post
   */
  isReelPost?: boolean
  /**
   * Whether this is a Thread post for twitter or a regular post
   */
  isThreadPost?: boolean
  /**
   * Count of attachmed media (images or videos)
   */
  mediaCount?: number
  /**
   * What type of media did this post have attached? ex. "image", "video"
   */
  mediaType?: null | string
  /**
   * The global organization id that the channel belongs to.
   */
  organizationId: string
  /**
   * Where was this post created from? (ex. calendar, queue, extension, third-party)
   */
  postCreatedSource?: string
  /**
   * The database id for the update document
   */
  postId: string
  /**
   * The product in which the action occured
   */
  product: string
  /**
   * The scheduling type of the update
   */
  schedulingType?: null | string
  /**
   * The date when the post was scheduled to be sent (ISO-8601)
   */
  shareDate?: null | string
  /**
   * How the post was scheduled, ex. "queue", "share_now"
   */
  shareType: string
  /**
   * Stickers added to this update
   */
  stickers?: any[]
  /**
   * Count of tags assigned to the Post
   */
  tagsCount?: number | null
  /**
   * Length of text on this update
   */
  textLength?: number
  /**
   * If Threads post, how many threaded updates it contains
   */
  threadedUpdatesCount?: number | null
  /**
   * Total number of link attachments for this post (including threaded updates)
   */
  totalLinkCount?: number
  /**
   * Total number of media uploaded for this post (including threaded updates)
   */
  totalMediaCount?: number
  /**
   * Type of the post created (e.g. Event for GBP, Threads for Twitter)
   */
  updateType?: null | string
  /**
   * Video audio codec
   */
  videoAudioCodec?: string
  /**
   * Video bitrate
   */
  videoBitrate?: number
  /**
   * Video duration in milliseconds
   */
  videoDurationMillis?: number
  /**
   * Video file extension
   */
  videoFileExtension?: string
  /**
   * Video framerate
   */
  videoFramerate?: number
  /**
   * Video height
   */
  videoHeight?: number
  /**
   * Video initial format
   */
  videoInitialFormat?: string
  /**
   * Video size in MB
   */
  videoSizeMB?: number
  /**
   * Video format after transcoding
   */
  videoTranscodedFormat?: string
  /**
   * Video width
   */
  videoWidth?: number
  [property: string]: any
}

/**
 * When a user deletes a previously scheduled post.
 */
export interface PostDeleted {
  /**
   * The channel that the post was scheduled for.
   */
  channel: string
  /**
   * The id of the channel in Buffer's database.
   */
  channelId: string
  /**
   * The id of the channel on the given social network or service.
   */
  channelServiceId: string
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName?: null | string
  /**
   * Where in the product was the post delected? (ex. calendar)
   */
  deletionSource?: null | string
  /**
   * Post Type when the post is for Google Business Profile channel (e.g. What's New)
   */
  googleBusinessPostType?: null | string
  /**
   * Whether this is a Thread post for twitter or a regular post
   */
  isThreadPost?: boolean
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The id of the post in Buffer's database.
   */
  postId: string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product: string
  /**
   * The raw error message for the post, if one exists.
   */
  rawErrorMessage: null | string
  /**
   * If Threads post, how many threaded updates it contains
   */
  threadedUpdatesCount?: number | null
  /**
   * Total number of link attachments for this post (including threaded updates)
   */
  totalLinkCount?: number
  /**
   * Total number of media uploaded for this post (including threaded updates)
   */
  totalMediaCount?: number
  [property: string]: any
}

/**
 * When a post is dragged to update it's scheduled time.
 */
export interface PostDragged {
  /**
   * The database ID for the channel the post belongs to.
   */
  channelId: string
  /**
   * The service's own ID for the channel.
   */
  channelServiceId?: string
  /**
   * The channel type, ex. "page", "business", "profile", "group", "creator".
   */
  channelType?: string
  /**
   * The username of the channel.
   */
  channelUsername?: string
  /**
   * The client in which the event occurred.
   */
  clientName?: null | string
  /**
   * The time that the post is scheduled to be sent when dragged.
   */
  dueAt: string
  /**
   * The database ID of the organization the post belongs to.
   */
  organizationId: string
  /**
   * The database ID of the post that was dragged.
   */
  postId: string
  /**
   * The status of the post when dragged.
   */
  postStatus: string
  [property: string]: any
}

/**
 * When a user edits a post.
 */
export interface PostEdited {
  /**
   * Whether this post was assisted with AI
   */
  aiAssisted?: boolean | null
  /**
   * The design id if a Canva image is attached to the post
   */
  canvaDesignId?: null | string
  /**
   * The channel that the post was scheduled for.
   */
  channel: string
  /**
   * The id of the channel in Buffer's database.
   */
  channelId: string
  /**
   * The id of the channel on the given social network or service.
   */
  channelServiceId: string
  /**
   * The type of channel the post is created for, ex. "page", "group"
   */
  channelType: string
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName?: null | string
  /**
   * Where the composer was opened from
   */
  composerSource?: null | string
  /**
   * How has the user edited the post? Ex. "image removed", "schedule changed", etc.
   */
  editType: any[]
  /**
   * Post Type when the post is for Google Business Profile channel (e.g. What's New)
   */
  googleBusinessPostType?: null | string
  /**
   * Whether this post includes a Buffer hashtag
   */
  hasBufferHashtag?: boolean
  /**
   * Does the post contain an image from the Canva intergration?
   */
  hasCanvaImage?: boolean
  /**
   * Mastodon content warning added to this update
   */
  hasContentWarning?: boolean | null
  /**
   * If a first comment added to this post
   */
  hasFirstComment: boolean
  /**
   * Whether the post has LinkedIn annotations
   */
  hasLinkAnnotations?: boolean
  /**
   * If a geolocation tag was added to the post
   */
  hasLocation: boolean
  /**
   * The post has either video or image media
   */
  hasMedia?: boolean
  /**
   * Whether the post has mentions
   */
  hasMentions?: boolean
  /**
   * If the update has pictures
   */
  hasPictures?: boolean
  /**
   * If a link was added for the channel's Shop Grid page
   */
  hasShopGridLink: boolean
  /**
   * Does the post contain an image from the Uploadcare intergration?
   */
  hasUploadcareImage?: boolean
  /**
   * Does this post include a user tag?
   */
  hasUserTag?: boolean
  /**
   * If the update has video
   */
  hasVideo?: boolean
  /**
   * The idea id from which the post was created
   */
  ideaId?: string
  /**
   * The dimensions of the image or images attached to the post
   */
  imageDimensions?: any[] | null
  /**
   * Image file extension
   */
  imageFileExtension?: string
  /**
   * If the post created as a draft
   */
  isDraft: boolean
  /**
   * Whether this is a Reel post for instagram or a regular post
   */
  isReelPost?: boolean
  /**
   * Whether this is a Thread post for twitter or a regular post
   */
  isThreadPost?: boolean
  /**
   * What type of media did this post have attached? ex. "image", "video"
   */
  mediaType?: null | string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The id of the post in Buffer's database.
   */
  postId: string
  /**
   * The product in which the action occured
   */
  product: string
  /**
   * The scheduling type of the update
   */
  schedulingType?: null | string
  /**
   * The date when the post was scheduled to be sent (ISO-8601)
   */
  shareDate?: null | string
  /**
   * How the post was scheduled, ex. "queue", "share_now"
   */
  shareType: string
  /**
   * Count of tags assigned to the Post
   */
  tagsCount?: number | null
  /**
   * Length of text on this update
   */
  textLength?: number
  /**
   * If Threads post, how many threaded updates it contains
   */
  threadedUpdatesCount?: number | null
  /**
   * Total number of link attachments for this post (including threaded updates)
   */
  totalLinkCount?: number
  /**
   * Total number of media uploaded for this post (including threaded updates)
   */
  totalMediaCount?: number
  /**
   * Type of the post created (e.g. Event for GBP, Threads for Twitter)
   */
  updateType?: null | string
  /**
   * Video audio codec
   */
  videoAudioCodec?: string
  /**
   * Video bitrate
   */
  videoBitrate?: number
  /**
   * Video duration in milliseconds
   */
  videoDurationMillis?: number
  /**
   * Video file extension
   */
  videoFileExtension?: string
  /**
   * Video framerate
   */
  videoFramerate?: number
  /**
   * Video height
   */
  videoHeight?: number
  /**
   * Video initial format
   */
  videoInitialFormat?: string
  /**
   * Video size in MB
   */
  videoSizeMB?: number
  /**
   * Video format after transcoding
   */
  videoTranscodedFormat?: string
  /**
   * Video width
   */
  videoWidth?: number
  [property: string]: any
}

/**
 * When a post is attempted to be sent to the channel service but fails
 */
export interface PostFailed {
  /**
   * The error code returned by the third party API. If it's negative, we prevent the post to
   * be sent
   */
  apiErrorCode?: number | null
  /**
   * The error message returned by the third party API.
   */
  apiErrorMessage?: null | string
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The service's own id for this channel
   */
  channelServiceId?: string
  /**
   * The channel type, ex. "page", "business", "profile", "group", "creator"
   */
  channelType?: string
  /**
   * What is the username of the channel?
   */
  channelUsername?: string
  /**
   * Which client was the user in when the Post was initially created?
   */
  clientName?: string
  /**
   * What is category of error for why the update failed to be sent?
   */
  errorAbbreviation: string
  /**
   * What is a user facing description of why the update failed to be sent? This property is
   * used in CIO.
   */
  errorMessage: string
  /**
   * If the update is marked as failed on purpose by Buffer, e.g. when the posting limit is
   * reached
   */
  failedOnPurpose?: boolean
  /**
   * Post Type when the post is for Google Business Profile channel (e.g. What's New)
   */
  googleBusinessPostType?: null | string
  /**
   * Whether the post has LinkedIn annotations
   */
  hasLinkAnnotations?: boolean | null
  /**
   * The post has either video or image media
   */
  hasMedia?: boolean
  /**
   * Whether the post has mentions
   */
  hasMentions?: boolean | null
  /**
   * If the update has pictures
   */
  hasPictures?: boolean
  /**
   * If the update has video
   */
  hasVideo?: boolean
  /**
   * The dimensions of the image or images attached to the post
   */
  imageDimensions?: any[] | null
  /**
   * Image file extension
   */
  imageFileExtension?: string
  /**
   * The full API error message that we use to map for the friendly error message
   */
  internalErrorMessage?: string
  /**
   * Whether this is a Reel post for Facebook or other type of post
   */
  isFacebookReelPost?: boolean | null
  /**
   * Whether this is a Story post for Facebook or other type of post
   */
  isFacebookStoryPost?: boolean | null
  /**
   * Whether this is a Reel post for instagram or a regular post
   */
  isReelPost?: boolean | null
  /**
   * Whether this is a Story post for instagram or a regular post
   */
  isStoryPost?: boolean | null
  /**
   * Whether this is a Thread post for twitter or a regular post
   */
  isThreadPost?: boolean | null
  /**
   * Count of attachmed media (images or videos)
   */
  mediaCount?: number | null
  /**
   * What type of media did this post have attached? ex. "image", "video"
   */
  mediaType?: null | string
  /**
   * The ID of the global organization that the channel was connected to.
   */
  organizationId: string
  /**
   * The database id for the update document
   */
  postId: string
  /**
   * The scheduling type of the update
   */
  schedulingType?: null | string
  /**
   * Was the update shared now or scheduled?
   */
  sharedNow?: boolean
  /**
   * Length of text on this update
   */
  textLength?: number
  /**
   * If Threads post, how many threaded updates it contains
   */
  threadedUpdatesCount?: number | null
  /**
   * Total number of link attachments for this post (including threaded updates)
   */
  totalLinkCount?: number | null
  /**
   * Total number of media uploaded for this post (including threaded updates)
   */
  totalMediaCount?: number | null
  /**
   * What was the text intended to be sent in the update? This property is used in CIO.
   */
  updateContent: string
  /**
   * When did the attempted sending of this update fail (ISO-8601)?  This property is needed
   * for use in CIO.
   */
  updateSentAt: string
  /**
   * Type of the post created (e.g. Event for GBP, Threads for Twitter)
   */
  updateType?: null | string
  /**
   * The description used in the failed post email to the user. This property is needed for
   * use in CIO.
   */
  updateVerb: string
  /**
   * Video audio codec
   */
  videoAudioCodec?: string
  /**
   * Video bitrate
   */
  videoBitrate?: number
  /**
   * Video duration in milliseconds
   */
  videoDurationMillis?: number
  /**
   * Video file extension
   */
  videoFileExtension?: string
  /**
   * Video framerate
   */
  videoFramerate?: number
  /**
   * Video height
   */
  videoHeight?: number
  /**
   * Video initial format
   */
  videoInitialFormat?: string
  /**
   * Video size in MB
   */
  videoSizeMB?: number
  /**
   * Video format after transcoding
   */
  videoTranscodedFormat?: string
  /**
   * Video width
   */
  videoWidth?: number
  [property: string]: any
}

/**
 * Used for notifying users about failures - sent for every recipient of a Post Failed
 * notification
 */
export interface PostFailedNotified {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The service's own id for this channel
   */
  channelServiceId?: string
  /**
   * The channel type, ex. "page", "business", "profile", "group", "creator"
   */
  channelType?: string
  /**
   * What is the username of the channel?
   */
  channelUsername?: string
  /**
   * What is a user facing description of why the update failed to be sent? This property is
   * used in CIO.
   */
  errorMessage: string
  /**
   * The ID of the global organization that the channel was connected to.
   */
  organizationId: string
  /**
   * The database id for the update document
   */
  postId: string
  /**
   * What was the text intended to be sent in the update? This property is used in CIO.
   */
  updateContent: string
  /**
   * When did the attempted sending of this update fail (ISO-8601)?  This property is needed
   * for use in CIO.
   */
  updateSentAt: string
  /**
   * Is this a post or a story? This property is used in CIO.
   */
  updateType: string
  /**
   * The description used in the failed post email to the user. This property is needed for
   * use in CIO.
   */
  updateVerb: string
  [property: string]: any
}

/**
 * When a user reverses the order of posts in Post Insights.
 */
export interface PostInsightsOrderReversed {
  /**
   * The channel of the selected profile, ex. “instagram”.
   */
  channel: string
  /**
   * The ID of the channel selected when the order was reversed.
   */
  channelId: string
  /**
   * The name of the client in which the action occurred.
   */
  clientName: string
  /**
   * The global organization ID of the user that took the action.
   */
  organizationId: string
  /**
   * The product in which the event occurred, ex. “analyze”.
   */
  product: string
  [property: string]: any
}

/**
 * When a user sorts the Post Insights.
 */
export interface PostInsightsSorted {
  /**
   * The channel of the selected profile, ex. “instagram”.
   */
  channel: string
  /**
   * The ID of the channel selected when the posts were sorted.
   */
  channelId: string
  /**
   * The name of the client in which the action occurred.
   */
  clientName: string
  /**
   * What were the posts sorted by, ex. “likes”, “comments”, “reach”, “date”?
   */
  metric: string
  /**
   * The global organization ID of the user that took the action.
   */
  organizationId: string
  /**
   * The product in which the event occurred, ex. “analyze”.
   */
  product: string
  [property: string]: any
}

/**
 * A user has moved a post to drafts
 */
export interface PostMadeDraft {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The id of the channel on the given service
   */
  channelServiceId?: string
  /**
   * The type of channel the post is created for, ex. "page", "group"
   */
  channelType?: string
  /**
   * What was the username of the channel?
   */
  channelUsername?: string
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * The ID of the global organization selected in the org switcher.
   */
  organizationId: string
  /**
   * The database id for the post document
   */
  postId: string
  /**
   * The product in which the action occured
   */
  product: string
  [property: string]: any
}

/**
 * A user's attempt to move a post to drafts has failed
 */
export interface PostMadeDraftFailed {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The id of the channel on the given service
   */
  channelServiceId?: string
  /**
   * The type of channel the post is created for, ex. "page", "group"
   */
  channelType?: string
  /**
   * What was the username of the channel?
   */
  channelUsername?: string
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * The ID of the global organization selected in the org switcher.
   */
  organizationId: string
  /**
   * The database id for the post document
   */
  postId: string
  /**
   * The product in which the action occured
   */
  product: string
  [property: string]: any
}

/**
 * When a user's attempt to reschedule a post fails.
 */
export interface PostRescheduleFailed {
  /**
   * The client in which the event occurred.
   */
  clientName?: null | string
  /**
   * The database ID of the post that was rescheduled.
   */
  postId: string
  /**
   * The method used to reschedule the post, ex. "drag"
   */
  rescheduleMethod: string
  /**
   * Whether or not the post was swapped with another post by drag and drop.
   */
  swap?: boolean | null
  /**
   * The database ID of the post that the dragged post was dropped on.
   */
  swappedPostId?: null | string
  [property: string]: any
}

/**
 * When a user successfully reschedules a post.
 */
export interface PostRescheduled {
  /**
   * The database ID for the channel the post belongs to.
   */
  channelId: string
  /**
   * The service's own id for the channel.
   */
  channelServiceId?: string
  /**
   * The channel type, ex. "page", "business", "profile", "group", "creator".
   */
  channelType?: string
  /**
   * The username of the channel.
   */
  channelUsername?: string
  /**
   * The client in which the event occurred.
   */
  clientName?: null | string
  /**
   * The time that the post is scheduled to be sent after being rescheduled.
   */
  dueAt: string
  /**
   * The database ID of the organization the post belongs to.
   */
  organizationId: string
  /**
   * The database ID of the post that was rescheduled.
   */
  postId: string
  /**
   * The status of the post when dragging starts.
   */
  postStatus: string
  /**
   * The time that the post was scheduled to be sent before it was rescheduled.
   */
  previousDueAt: string
  /**
   * The method used to reschedule the post, ex. "drag"
   */
  rescheduleMethod: string
  /**
   * Whether or not the post was swapped with another post by drag and drop.
   */
  swap?: boolean | null
  /**
   * The database ID of the post that the dragged post was dropped on.
   */
  swappedPostId?: null | string
  [property: string]: any
}

/**
 * A user has created a finish later post
 */
export interface PostSavedToFinishLater {
  /**
   * Where was this save initiated? (ex. cancel, save)
   */
  buttonSource?: string
  /**
   * What client sent the post
   */
  client?: null | string
  /**
   * Which client was the user in when the Post was created?
   */
  clientName?: string
  /**
   * The global organization id that the channel belongs to.
   */
  organizationId: string
  /**
   * Where was this post created from? (ex. calendar, queue, extension, third-party)
   */
  postCreatedSource?: string
  /**
   * The product in which the action occured
   */
  product: string
  [property: string]: any
}

/**
 * When a post is selected from the grid on the frontend.
 */
export interface PostSelected {
  /**
   * The channel that the post belongs to, e.g. "facebook"
   */
  channel?: string
  /**
   * The database id for the channel.
   */
  channelId: string
  /**
   * The service's own id for this channel.
   */
  channelServiceId?: string
  /**
   * What is the username of the channel?
   */
  channelUsername?: string
  /**
   * Does the post have any labels?
   */
  hasLabel: boolean
  /**
   * The types of lables associated with the post (e.g. "upset/order/question")
   */
  labelTypes: any[]
  /**
   * The global organization id of the user that owns the channel.
   */
  organizationId: string
  /**
   * The unique identifier of the post.
   */
  postId: string
  /**
   * Were posts filtered when the post was selected?
   */
  postsFiltered?: boolean
  /**
   * The product in which the event occurred.
   */
  product: string
  /**
   * The total number of comments for the post.
   */
  totalComments: number
  /**
   * The total number of unanswered comments for the post.
   */
  totalUnansweredComments: number
  [property: string]: any
}

/**
 * This event tracks when an update has been finally published after being created on a
 * platform (like Facebook, TikTok, LinkedIn, Twitter, ...)
 */
export interface PostSent {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The id of the channel on the given service
   */
  channelServiceId: string
  /**
   * The type of channel the update is created for, ex. "page", "group"
   */
  channelType: string
  /**
   * The username of the channel
   */
  channelUsername: string
  /**
   * Which client was the user in when the Post was initially created?
   */
  clientName?: string
  /**
   * The post has either video or image media
   */
  hasMedia?: boolean
  /**
   * The post has one or more images
   */
  hasPictures?: boolean
  /**
   * The post has a video
   */
  hasVideo?: boolean
  /**
   * Whether this is a Thread post for twitter or a regular post
   */
  isThreadPost?: boolean
  /**
   * The global organization id that the channel belongs to.
   */
  organizationId: string
  /**
   * The database id for the update document in our database
   */
  postId: string
  /**
   * The scheduling type of the update
   */
  schedulingType?: null | string
  /**
   * The date when the update was scheduled to be sent (ISO-8601)
   */
  shareDate?: string
  /**
   * Was the update shared now or scheduled?
   */
  sharedNow?: boolean
  /**
   * Stickers added to this update
   */
  stickers?: any[]
  /**
   * Length of text on this update
   */
  textLength?: number
  /**
   * If Threads post, how many threaded updates it contains
   */
  threadedUpdatesCount?: number | null
  /**
   * Total number of link attachments for this post (including threaded updates)
   */
  totalLinkCount?: number
  /**
   * Total number of media uploaded for this post (including threaded updates)
   */
  totalMediaCount?: number
  /**
   * The date when the update was sent (ISO-8601)
   */
  updateSentAt: string
  /**
   * It helps to differentiate between different post types within the same channel, ex.
   * "reels", "stories" and "post".
   */
  updateType: null | string
  [property: string]: any
}

/**
 * This event is used to send emails via Customer.io letting users know that posts have been
 * sent.
 */
export interface PostSentNotified {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The id of the channel on the given service
   */
  channelServiceId: string
  /**
   * The type of channel the post is created for, ex. "page", "group"
   */
  channelType: string
  /**
   * The username of the channel that the draft was created for. This can be used to customize
   * the message in the email.
   */
  channelUsername: string
  /**
   * Which client was the user in when the Post was created?
   */
  clientName: string
  /**
   * Whether the post has LinkedIn annotations
   */
  hasLinkAnnotations?: boolean
  /**
   * Whether the post has mentions
   */
  hasMentions?: boolean
  /**
   * Whether the post has thread
   */
  hasThread?: boolean
  /**
   * Whether this is a Reel post for instagram or a regular post
   */
  isReelPost?: boolean
  /**
   * The global organization id that the channel belongs to.
   */
  organizationId: string
  /**
   * The database id for the update document
   */
  postId: string
  /**
   * The text included in the post
   */
  postText?: string
  /**
   * The link to the post on the network
   */
  postUrl?: string
  /**
   * The product in which the action occured
   */
  product: string
  /**
   * The local time at which the post was sent
   */
  sharedAt?: string
  /**
   * The date when the post was scheduled to be sent (ISO-8601)
   */
  shareDate?: null | string
  /**
   * What we would call the post in an email (ex. tweet, pin, post, etc)
   */
  updateType?: string
  [property: string]: any
}

/**
 * When user shares a post now
 */
export interface PostSharedNow {
  /**
   * The account ID
   */
  accountId?: string
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The type of channel the post is created for, ex. "page", "group"
   */
  channelType?: string
  /**
   * What was the username of the channel?
   */
  channelUsername?: string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The post ID shared now
   */
  postId: string
  /**
   * The scheduling type of the update
   */
  schedulingType?: null | string
  /**
   * It helps to differentiate between different post types within the same channel, ex.
   * "reels", "stories" and "post".
   */
  updateType?: string
  [property: string]: any
}

/**
 * When a post gets stuck in processing.
 */
export interface PostStuckInProcessing {
  /**
   * The error code returned by the third party API. If it's negative, we prevent the post to
   * be sent
   */
  apiErrorCode?: number | null
  /**
   * The error message returned by the third party API.
   */
  apiErrorMessage?: null | string
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The service's own id for this channel
   */
  channelServiceId?: string
  /**
   * The channel type, ex. "page", "business", "profile", "group", "creator"
   */
  channelType?: string
  /**
   * What is the username of the channel?
   */
  channelUsername?: string
  /**
   * What is category of error for why the update failed to be sent?
   */
  errorAbbreviation: null | string
  /**
   * What is a user facing description of why the update failed to be sent? This property is
   * used in CIO.
   */
  errorMessage: null | string
  /**
   * If the update has pictures
   */
  hasPictures?: boolean
  /**
   * If the update has video
   */
  hasVideo?: boolean
  /**
   * The ID of the global organization that the channel was connected to.
   */
  organizationId: string
  /**
   * Total number of pictures attached
   */
  picturesCount?: number
  /**
   * The database id for the update document
   */
  postId: string
  /**
   * What was the text intended to be sent in the update? This property is used in CIO.
   */
  updateContent: null | string
  /**
   * When did the attempted sending of this update fail (ISO-8601)?  This property is needed
   * for use in CIO.
   */
  updateDueAt: null | string
  /**
   * Is this a post or a story? This property is used in CIO.
   */
  updateType: string
  /**
   * Video audio codec
   */
  videoAudioCodec?: string
  /**
   * Video bitrate
   */
  videoBitrate?: number
  /**
   * Video duration in milliseconds
   */
  videoDurationMillis?: number
  /**
   * Video file extension
   */
  videoFileExtension?: string
  /**
   * Video framerate
   */
  videoFramerate?: number
  /**
   * Video height
   */
  videoHeight?: number
  /**
   * Video initial format
   */
  videoInitialFormat?: string
  /**
   * Video size in MB
   */
  videoSizeMB?: number
  /**
   * Video format after transcoding
   */
  videoTranscodedFormat?: string
  /**
   * Video width
   */
  videoWidth?: number
  [property: string]: any
}

/**
 * When a user reschedules a post by swapping it with another post.
 */
export interface PostSwapped {
  /**
   * The database ID for the channel the post belongs to.
   */
  channelId: string
  /**
   * The service's own id for the channel.
   */
  channelServiceId?: string
  /**
   * The channel type, ex. "page", "business", "profile", "group", "creator".
   */
  channelType?: string
  /**
   * The username of the channel.
   */
  channelUsername?: string
  /**
   * The client in which the event occurred.
   */
  clientName?: null | string
  /**
   * The database ID of the organization the post belongs to.
   */
  organizationId: string
  /**
   * The updated time that the source post is scheduled to be sent after the swap.
   */
  sourcePostDueAt: string
  /**
   * The database ID of the source post that was dragged.
   */
  sourcePostId: string
  /**
   * The previous time that the source post was scheduled to be sent before the swap.
   */
  sourcePostPreviousDueAt: string
  /**
   * The status of the source post when dragging starts.
   */
  sourcePostStatus: string
  /**
   * The updated time that the target post is scheduled to be sent after the swap.
   */
  targetPostDueAt: string
  /**
   * The database ID of the target post that the source post was dropped on.
   */
  targetPostId: string
  /**
   * The previous time that the target post was scheduled to be sent before the swap.
   */
  targetPostPreviousDueAt: string
  /**
   * The status of the target post when the source post is dropped on it.
   */
  targetPostStatus: string
  [property: string]: any
}

/**
 * Client-side validation for post saving failed
 */
export interface PostValidationFailed {
  /**
   * What was the unique identifier of the client the user was in when they added the widget?
   */
  clientId?: null | string
  /**
   * Which client was the user in when they added the widget? IE, `publishIos` or
   * `publishAndroid`.
   */
  clientName: string
  /**
   * The number of GIFs attached
   */
  gifCount: number
  /**
   * The number of images attached
   */
  imageCount: number
  /**
   * whether the post is a draft
   */
  isDraft: boolean
  /**
   * The message displayed to the user for the validation error
   */
  message: string
  /**
   * The network that was selected when the validation failure occured
   */
  network: string
  /**
   * The organization ID of the user that added the widget.
   */
  organizationId: string
  /**
   * the posting type being used for the post
   */
  postType: string
  /**
   * The product in which the action occured
   */
  product: string
  /**
   * the scheduling type for the post
   */
  schedulingType: string
  /**
   * A unique ID representing a current composer session
   */
  sessionId: string
  /**
   * number of tags assigned to the post
   */
  tagCount: number
  /**
   * The currently composed text
   */
  text: string
  /**
   * the context that the composer is currently being used for
   */
  type: string
  /**
   * The number of videos attached
   */
  videoCount: number
  [property: string]: any
}

/**
 * When the user toggles a posting day on or off in their schedule.
 */
export interface PostingDayToggled {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The identifier of the channel for which the attempt was made.
   */
  channelId: string
  /**
   * The service's own id for this channel
   */
  channelServiceId?: string
  /**
   * The channel type, ex. "page", "business", "profile", "group", "creator"
   */
  channelType?: string
  /**
   * What is the username of the channel?
   */
  channelUsername?: string
  /**
   * The client in which the action occurred.
   */
  clientName: string
  /**
   * The day toggled in the schedule.
   */
  dayToggled: string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The product in which the event occurred, ex. “publish”.
   */
  product: string
  /**
   * The state of the day in the schedule.
   */
  state: string
  [property: string]: any
}

/**
 * When a channel achieved a posting goal
 */
export interface PostingGoalAchieved {
  /**
   * The frequency of the posting goal at the time of the event
   */
  atEventFrequency: string
  /**
   * The posting goal count at the time of the event
   */
  atEventGoal: number
  /**
   * The active channel of the user that took the action
   */
  channelId: string
  /**
   * The service of the channel that took the action
   */
  channelService: string
  /**
   * The username of the channel that took the action
   */
  channelUsername: string
  /**
   * The organization ID the channel belongs to
   */
  organizationId: string
  /**
   * The end of the period of the posting goal
   */
  periodEnd: string
  /**
   * The start of the period of the posting goal
   */
  periodStart: string
  [property: string]: any
}

/**
 * When a channel missed a posting goal
 */
export interface PostingGoalMissed {
  /**
   * The frequency of the posting goal at the time of the event
   */
  atEventFrequency: string
  /**
   * The posting goal count at the time of the event
   */
  atEventGoal: number
  /**
   * The sent count at the time of the event
   */
  atEventSentCount: number
  /**
   * The active channel of the user that took the action
   */
  channelId: string
  /**
   * The service of the channel that took the action
   */
  channelService: string
  /**
   * The username of the channel that took the action
   */
  channelUsername: string
  /**
   * The organization ID the channel belongs to
   */
  organizationId: string
  /**
   * The end of the period of the posting goal
   */
  periodEnd: string
  /**
   * The start of the period of the posting goal
   */
  periodStart: string
  [property: string]: any
}

/**
 * When a channel set a posting goal
 */
export interface PostingGoalSet {
  /**
   * The active channel of the user that took the action
   */
  channelId: string
  /**
   * The service of the channel that took the action
   */
  channelService: string
  /**
   * The username of the channel that took the action
   */
  channelUsername: string
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * The frequency of the posting goal
   */
  frequency: string
  /**
   * The posting goal count
   */
  goal: number
  /**
   * The organization ID the channel belongs to
   */
  organizationId: string
  /**
   * The end of the period of the posting goal
   */
  periodEnd: string
  /**
   * The start of the period of the posting goal
   */
  periodStart: string
  [property: string]: any
}

/**
 * When a customer skips the Channel Posting Goal Modal Configuration
 */
export interface PostingGoalSkipped {
  /**
   * The active channel of the user that took the action
   */
  channelId: string
  /**
   * The service of the channel that took the action
   */
  channelService: string
  /**
   * The username of the channel that took the action
   */
  channelUsername: string
  /**
   * Which client was the user in when they took this action?
   */
  clientName: string
  /**
   * The organization ID the channel belongs to
   */
  organizationId: string
  [property: string]: any
}

/**
 * When a user creates a posting plan
 */
export interface PostingPlanCreated {
  /**
   * The current channel that the user is creating a posting plan for
   */
  channel: string
  /**
   * The channel type, ex. "page", "business", "profile", "group", "creator"
   */
  channelType?: null | string
  /**
   * The username of the channel that the user is creating a posting plan for
   */
  channelUsername: string
  /**
   * Which client was the user in when they created the posting plan?
   */
  clientName: null | string
  /**
   * The current plan of the user at time of creating the posting plan.
   */
  currentPlan?: null | string
  /**
   * The days that the user has selected for their posting plan (e.g they selected Mo, We, Fr)
   */
  days: any[]
  /**
   * The global organization ID of the user that created the posting plan.
   */
  organizationId: null | string
  /**
   * The number of times per day that the user has selected for their posting plan (e.g they
   * selected they want to post 4 times a day)
   */
  timesPerDay: number
  [property: string]: any
}

/**
 * When the user clears their posting schedule.
 */
export interface PostingScheduleCleared {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The identifier of the channel for which the attempt was made.
   */
  channelId: string
  /**
   * The service's own id for this channel
   */
  channelServiceId?: string
  /**
   * The channel type, ex. "page", "business", "profile", "group", "creator"
   */
  channelType?: string
  /**
   * What is the username of the channel?
   */
  channelUsername?: string
  /**
   * The client in which the action occurred.
   */
  clientName: string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The product in which the event occurred, ex. “publish”.
   */
  product: string
  [property: string]: any
}

/**
 * When the user added a posting time to their schedule.
 */
export interface PostingTimeAdded {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The identifier of the channel for which the attempt was made.
   */
  channelId: string
  /**
   * The service's own id for this channel
   */
  channelServiceId?: string
  /**
   * The channel type, ex. "page", "business", "profile", "group", "creator"
   */
  channelType?: string
  /**
   * What is the username of the channel?
   */
  channelUsername?: string
  /**
   * The client in which the action occurred.
   */
  clientName: string
  /**
   * The number of days added to the schedule.
   */
  daysAdded: string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The product in which the event occurred, ex. “publish”.
   */
  product: string
  /**
   * The time added to the schedule.
   */
  timeAdded: string
  [property: string]: any
}

/**
 * When the user removed a posting time to their schedule.
 */
export interface PostingTimeRemoved {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The identifier of the channel for which the attempt was made.
   */
  channelId: string
  /**
   * The service's own id for this channel
   */
  channelServiceId?: string
  /**
   * The channel type, ex. "page", "business", "profile", "group", "creator"
   */
  channelType?: string
  /**
   * What is the username of the channel?
   */
  channelUsername?: string
  /**
   * The client in which the action occurred.
   */
  clientName: string
  /**
   * The day removed from the schedule.
   */
  dayRemoved: string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The product in which the event occurred, ex. “publish”.
   */
  product: string
  /**
   * The time removed from the schedule.
   */
  timeRemoved: string
  [property: string]: any
}

/**
 * When the user updated a posting time to their schedule.
 */
export interface PostingTimeUpdated {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The identifier of the channel for which the attempt was made.
   */
  channelId: string
  /**
   * The service's own id for this channel
   */
  channelServiceId?: string
  /**
   * The channel type, ex. "page", "business", "profile", "group", "creator"
   */
  channelType?: string
  /**
   * What is the username of the channel?
   */
  channelUsername?: string
  /**
   * The client in which the action occurred.
   */
  clientName: string
  /**
   * The day changed on to the schedule.
   */
  dayChanged: string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The product in which the event occurred, ex. “publish”.
   */
  product: string
  /**
   * The time added to the schedule.
   */
  timeAdded: string
  /**
   * The time removed from the schedule.
   */
  timeRemoved: string
  [property: string]: any
}

/**
 * When the posts for a specific Instagram account are loaded on the frontend.
 */
export interface PostsLoaded {
  /**
   * The channel that the posts belong to, e.g. "facebook"
   */
  channel?: string
  /**
   * The database id for the channel.
   */
  channelId: string
  /**
   * The service's own id for this channel.
   */
  channelServiceId?: string
  /**
   * What is the username of the channel?
   */
  channelUsername?: string
  /**
   * Do any of the channel's posts include comments with labels?
   */
  hasLabels: boolean
  /**
   * The types of lables included in the comments (e.g. "upset/question/order").
   */
  labelTypes: any[]
  /**
   * The global organization id of the user that owns the channel.
   */
  organizationId: string
  /**
   * The number of posts that include at least one comment with a label.
   */
  postsWithLabel: number | null
  /**
   * The product in which the event occurred.
   */
  product: string
  /**
   * The total number of comments for the channel's posts.
   */
  totalComments: number | null
  /**
   * The total number of unanswered comments for the channel's posts.
   */
  totalUnansweredComments: number | null
  [property: string]: any
}

/**
 * A user has reordered the posts in their queue
 */
export interface PostsReordered {
  /**
   * Which client was the user in when the Post was created?
   */
  clientName: string
  /**
   * The global organization id that the channel belongs to.
   */
  organizationId: string
  /**
   * The product in which the action occured
   */
  product: string
  /**
   * Where were the posts reordered from? (ex. calendar, queue)
   */
  reorderSource: string
  [property: string]: any
}

/**
 * When did the user select a product in Remix?
 */
export interface ProductSelected {
  /**
   * The client in which the action occurred.
   */
  clientName: string
  /**
   * If the url scraped contains ratings
   */
  hasRatings?: boolean
  /**
   * What ecommerce platform was the product selected from? ex. "shopify"
   */
  platform: string
  /**
   * The product in which the event occurred, ex. “remix”.
   */
  product: string
  /**
   * What was the title of the product that was selected?
   */
  productText?: string
  /**
   * What was the url of the product that was selected?
   */
  productUrl: string
  [property: string]: any
}

/**
 * When a user views the product selector in the onboarding flow.
 */
export interface ProductSelectorViewed {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The name of the client that the event occurred in.
   */
  clientName: string
  /**
   * Is the organization on a Free plan?
   */
  onFreePlan: boolean
  /**
   * The global organization ID of the user that viewed the product solutions.
   */
  organizationId: string
  /**
   * The product in which this action occurred.
   */
  product: string
  [property: string]: any
}

/**
 * When did the product signup occur? A user can have multiple product signups, but only one
 * per product per global Buffer account.  This event is triggered when Buffer's product
 * specific unique user identifier is generated in that product's production database.
 */
export interface ProductSignup {
  /**
   * Which client did the user signup for this product from?
   */
  clientName?: string
  /**
   * Which call to action did the user click to signup for this product
   */
  cta?: null | string
  /**
   * Which product or website is the CTA located in? Examples would be either the product,
   * like `publish`, or the website, like `bufferSite`
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA?  In many cases it makes sense to describe the
   * intended result of the CTA, like `proTrial` or `publishPro`
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in
   * Publish app shell menu
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA?  If setting the value for the first time, set to
   * `1`
   */
  ctaVersion?: null | string
  /**
   * What website page or app view is the CTA located?  Examples would be, `composer` or
   * `analyticsOverview` for Publish, and `pricingPublish` for the Publish pricing page on the
   * Buffer marketing site
   */
  ctaView?: null | string
  /**
   * The organization ID of the user that signed up
   */
  organizationId?: string
  /**
   * Which product did the signup occur for?
   */
  product: string
  /**
   * Did this product signup also trigger a trial start during the same work flow?
   */
  signupWithTrial?: boolean
  /**
   * If the signup did also trigger a trial start during the same work flow, what was the
   * common name of the plan they are trialing (Eg, `Pro`)?
   */
  trialPlanName?: null | string
  [property: string]: any
}

/**
 * When a user selects a product solution from the product selector.
 */
export interface ProductSolutionSelected {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The name of the client that the event occurred in.
   */
  clientName: string
  /**
   * The global organization ID of the user that selected the product.
   */
  organizationId: string
  /**
   * The product in which this action occurred (e.g. buffer).
   */
  product: string
  /**
   * Which product solution was selected (e.g. analytics)?
   */
  productSolutionSelected: string
  [property: string]: any
}

/**
 * Publish Revamp Opt-In/Out action
 */
export interface PublishRevampToggle {
  /**
   * The account ID
   */
  accountId: string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * Status of the opt-in/out action
   */
  status: string
  [property: string]: any
}

/**
 * When did the push notification fail to be delivered?
 */
export interface PushNotificationBounced {
  /**
   * What was the content included in the push notification?
   */
  campaignContent: string
  /**
   * What was the type of link in the notification (ex, push notification)?
   */
  campaignMedium: string
  /**
   * What was the name of the push notification?
   */
  campaignName: string
  /**
   * Which source app sent the notification?
   */
  campaignSource: string
  /**
   * The channel's service, ex. "facebook"
   */
  channel?: null | string
  /**
   * The database id for the channel document
   */
  channelId?: null | string
  /**
   * The service's own id for this channel
   */
  channelServiceId?: null | string
  /**
   * What was the application unique identifier for where the notification came from?
   */
  clientId?: null | string
  /**
   * What was the client name of the application?
   */
  clientName: string
  /**
   * Which Buffer product was the notification for?
   */
  product: string
  [property: string]: any
}

/**
 * When was the push notification received in the mobile app?
 */
export interface PushNotificationReceived {
  /**
   * What was the content included in the push notification?
   */
  campaignContent?: string
  /**
   * What was the type of link in the notification (ex, push notification)?
   */
  campaignMedium?: string
  /**
   * What was the name of the push notification?
   */
  campaignName?: string
  /**
   * Which source app sent the notification?
   */
  campaignSource?: string
  /**
   * The channel's service, ex. "facebook"
   */
  channel?: null | string
  /**
   * The database id for the channel document
   */
  channelId?: null | string
  /**
   * The service's own id for this channel
   */
  channelServiceId?: null | string
  /**
   * What was the application unique identifier for where the notification came from?
   */
  clientId?: null | string
  /**
   * What was the client name of the application?
   */
  clientName: string
  /**
   * Whether we have permission to show notifications on the receiving device
   */
  hasGrantedNotificationPermission?: boolean | null
  /**
   * Which Buffer product was the notification for?
   */
  product: string
  [property: string]: any
}

/**
 * When was the push notification tapped by the user, opening the mobile app?
 */
export interface PushNotificationTapped {
  /**
   * What was the content included in the push notification?
   */
  campaignContent?: string
  /**
   * What was the type of link in the notification (ex, push notification)?
   */
  campaignMedium?: string
  /**
   * What was the name of the push notification?
   */
  campaignName?: string
  /**
   * Which source app sent the notification?
   */
  campaignSource?: string
  /**
   * The channel's service, ex. "facebook"
   */
  channel?: null | string
  /**
   * The database id for the channel document
   */
  channelId?: null | string
  /**
   * The service's own id for this channel
   */
  channelServiceId?: null | string
  /**
   * What was the application unique identifier for where the notification came from?
   */
  clientId?: null | string
  /**
   * What was the client name of the application?
   */
  clientName: string
  /**
   * Which Buffer product was the notification for?
   */
  product: string
  [property: string]: any
}

/**
 * When did the user view the billing quantity update module?
 */
export interface QuantityUpdateModalViewed {
  /**
   * If the user navigated to this page from a CTA on another Buffer page, which call to
   * action was it?
   */
  cta?: null | string
  /**
   * Which product or website is the CTA located in? Examples would be either the product,
   * like `publish`, or the website, like `bufferSite`
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA?
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in
   * Publish app shell menu
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA?  If setting the value for the first time, set to
   * `1`
   */
  ctaVersion?: null | string
  /**
   * What website page or app view is the CTA located?  Examples would be, `composer` or
   * `analyticsOverview` for Publish, and `pricingPublish` for the Publish pricing page on the
   * Buffer marketing site
   */
  ctaView?: null | string
  /**
   * The current plan of the user at time of viewing the quantity update modal.
   */
  currentPlan?: null | string
  /**
   * The global organization ID of the user that viewed the quantity update modal.
   */
  organizationId: null | string
  [property: string]: any
}

/**
 * User clicked the shuffle button to shuffle posting queue / CT-677
 */
export interface QueueShuffled {
  /**
   * The channel's service, ex. "facebook"
   */
  channel?: null | string
  /**
   * Which client was the user in when they dragged the story? IE, `publishIos`, `publishWeb`,
   * `publishAndroid`.
   */
  clientName: string
  /**
   * The Global organization ID.
   */
  organizationId?: null | string
  [property: string]: any
}

/**
 * When a user selects an action in the quick navigator
 */
export interface QuickNavigatorActionSelected {
  /**
   * The name of the selected action
   */
  actionName: string
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The global organization ID of the user that viewed the plan selector.
   */
  organizationId: null | string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product?: string
  [property: string]: any
}

/**
 * When a user opens the quick navigator (cmd + k / ctrl + k)
 */
export interface QuickNavigatorOpened {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The global organization ID of the user that viewed the plan selector.
   */
  organizationId: null | string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product?: string
  [property: string]: any
}

/**
 * When the user chose to Refer someone to Buffer, did they copy their unique link?
 */
export interface ReferralLinkCopied {
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * The organization ID of the referring user
   */
  organizationId: string
  [property: string]: any
}

/**
 * When the user chose to Refer someone to Buffer, did they click "Share Now" to start a
 * draft Post containing the link?
 */
export interface ReferralPostStarted {
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * The organization ID of the referring user
   */
  organizationId: string
  [property: string]: any
}

/**
 * When a user clicks the Refresh Pinterest Boards button.
 */
export interface RefreshPinterestBoardsButtonClicked {
  /**
   * The database id for the channel document
   */
  channelId?: string
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The ID of the current organization
   */
  organizationId: string
  /**
   * The product in which the action occurred
   */
  product: string
  [property: string]: any
}

/**
 * A user has clicked the button to open the corresponding app for a reminder
 */
export interface ReminderAppButtonTapped {
  /**
   * The channel that the post was scheduled for
   */
  channel: string
  /**
   * The name of the client in which the action occurred (ex. publishIos)
   */
  clientName: string
  /**
   * The size of the file (KB) being shared in the reminder
   */
  fileSize?: null | string
  /**
   * The media type contained in the reminder
   */
  mediaType: string
  /**
   * The global organization id that the channel belongs to
   */
  organizationId: string
  /**
   * The post id for the reminder being shared
   */
  postId: string
  /**
   * The product in which the action occured
   */
  product: string
  /**
   * Whether the reminder is a repost of an existing post
   */
  repost: boolean
  [property: string]: any
}

/**
 * A user has opened the corresponding app for a reminder
 */
export interface ReminderAppOpened {
  /**
   * The channel that the post was scheduled for
   */
  channel: string
  /**
   * The name of the client in which the action occurred (ex. publishIos)
   */
  clientName: string
  /**
   * The global organization id that the channel belongs to
   */
  organizationId: string
  /**
   * The post id for the reminder being shared
   */
  postId?: null | string
  /**
   * The product in which the action occured
   */
  product: string
  [property: string]: any
}

/**
 * A user has completed a reminder
 */
export interface ReminderCompleted {
  /**
   * The channel that the post was scheduled for.
   */
  channel: string
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName?: null | string
  /**
   * The global organization id that the channel belongs to.
   */
  organizationId: string
  /**
   * The product in which the action occured
   */
  product: string
  /**
   * The date when the post was scheduled to be sent (ISO-8601)
   */
  scheduledDate?: null | string
  [property: string]: any
}

/**
 * A user has created a reminder
 */
export interface ReminderCreated {
  /**
   * The channel that the post was scheduled for.
   */
  channel: string
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName?: null | string
  /**
   * The global organization id that the channel belongs to.
   */
  organizationId: string
  /**
   * The product in which the action occured
   */
  product: string
  /**
   * The date when the post was scheduled to be sent (ISO-8601)
   */
  scheduledDate?: null | string
  [property: string]: any
}

/**
 * When a user deletes a previously scheduled reminder.
 */
export interface ReminderDeleted {
  /**
   * The channel that the post was scheduled for.
   */
  channel: string
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName?: null | string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product: string
  [property: string]: any
}

/**
 * Which view the user clicked in Reminders Post Preview
 */
export interface RemindersPostPreviewViewClicked {
  /**
   * Which client was the user in when they took this action?
   */
  clientName: string
  /**
   * The global organization ID of the user that clicked the button.
   */
  organizationId: null | string
  /**
   * The service, ex. "instagram"
   */
  service: string
  /**
   * The name of the view clicked.
   */
  view: string
  [property: string]: any
}

/**
 * What button did the user clicked in the Reminders Setup Wizard
 */
export interface RemindersSetupButtonClicked {
  /**
   * The name of the button that was clicked
   */
  buttonName: string
  /**
   * Which client was the user in when the step was viewed?
   */
  clientName?: string
  /**
   * The global organization ID of the user that clicked the button.
   */
  organizationId: null | string
  /**
   * Where in the product this event took place
   */
  placement?: string
  [property: string]: any
}

/**
 * When did the user clicked a CTA to set up Reminders
 */
export interface RemindersSetupCTAClicked {
  /**
   * Which client was the user in when the CTA was clicked?
   */
  clientName?: string
  /**
   * The name of the CTA clicked.
   */
  cta?: string
  /**
   * The global organization ID of the user that clicked the CTA.
   */
  organizationId: null | string
  /**
   * Where in the product this event took place
   */
  placement?: string
  [property: string]: any
}

/**
 * When did the user see a CTA to set up Reminders
 */
export interface RemindersSetupCTAViewed {
  /**
   * Which client was the user in when the CTA was viewed?
   */
  clientName?: string
  /**
   * The name of the CTA viewed.
   */
  cta?: string
  /**
   * The global organization ID of the user that viewed the CTA.
   */
  organizationId: null | string
  /**
   * Where in the product this event took place
   */
  placement?: string
  [property: string]: any
}

/**
 * When the user completed the setup of reminders for their Instagram profile.
 */
export interface RemindersSetupCompleted {
  /**
   * The identifier of the channel for which reminders were set up.
   */
  channelId: string
  /**
   * The client in which the action occurred.
   */
  clientName: string
  /**
   * Which call to action did the user click to get to the reminders setup?
   */
  cta?: null | string
  /**
   * Which product is the CTA located in? Examples would be either the product, like `publish`.
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA? In many cases it makes sense to describe the
   * intended result of the CTA, like `proTrial` or `publishPro`.
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in
   * Publish app shell menu.
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA? If setting the value for the first time, set to
   * `1`.
   */
  ctaVersion?: null | string
  /**
   * What app view is the CTA located in? Examples would be, `composer` or `analyticsOverview`
   * for Publish.
   */
  ctaView?: null | string
  /**
   * The ID of the Global organization that owns the profile.
   */
  organizationId?: string
  /**
   * The product in which the event occurred, ex. “publish”.
   */
  product: string
  [property: string]: any
}

/**
 * When the user attempted to set up reminders for their Instagram profile.
 */
export interface RemindersSetupStarted {
  /**
   * The identifier of the channel for which the attempt was made.
   */
  channelId: string
  /**
   * The client in which the action occurred.
   */
  clientName: string
  /**
   * Which call to action did the user click to get to the reminders setup?
   */
  cta?: null | string
  /**
   * Which product is the CTA located in? Examples would be either the product, like `publish`.
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA? In many cases it makes sense to describe the
   * intended result of the CTA, like `proTrial` or `publishPro`.
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in
   * Publish app shell menu.
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA? If setting the value for the first time, set to
   * `1`.
   */
  ctaVersion?: null | string
  /**
   * What app view is the CTA located in? Examples would be, `composer` or `analyticsOverview`
   * for Publish.
   */
  ctaView?: null | string
  /**
   * The ID of the global organization that owns the channel.
   */
  organizationId?: string
  /**
   * The product in which the event occurred, ex. “publish”.
   */
  product: string
  [property: string]: any
}

/**
 * When did the user see a step of the Reminders Setup Wizard
 */
export interface RemindersSetupStepViewed {
  /**
   * Which client was the user in when the step was viewed?
   */
  clientName?: string
  /**
   * The global organization ID of the user that viewed the step.
   */
  organizationId: null | string
  /**
   * Where in the product this event took place
   */
  placement?: string
  /**
   * The number of the step
   */
  stepNumber: number
  [property: string]: any
}

/**
 * When is a report chart note added or edited?
 */
export interface ReportChartNoteEdited {
  /**
   * Human readable name of the module
   */
  module?: string
  /**
   * The id of the Global organization that owns the channel.
   */
  organizationId?: string
  /**
   * The product in which the event happened
   */
  product: string
  /**
   * Id for the new report
   */
  reportId?: string
  [property: string]: any
}

/**
 * A key action in Analyze, it's valuable to know how many and how often users are using
 * this feature.
 */
export interface ReportCreated {
  /**
   * Number of days the report is showing
   */
  days?: number
  /**
   * The id of the Global organization that owns the channel.
   */
  organizationId?: string
  /**
   * The product in which the event happened
   */
  product?: string
  /**
   * Id for the new report
   */
  reportId?: string
  /**
   * User-generated name of the report
   */
  title: string
  [property: string]: any
}

/**
 * When did the user export a report?
 */
export interface ReportExported {
  /**
   * Number of days the report is showing
   */
  days?: number | null
  /**
   * Written description, if any
   */
  description?: null | string
  /**
   * Did the user export the report with their logo added
   */
  hasLogo?: boolean | null
  /**
   * How many modules on the report when exported
   */
  numberOfModules?: number | null
  /**
   * The organization ID of the user that exported the report
   */
  organizationId?: string
  /**
   * Which product did the user export a report from?
   */
  product: string
  /**
   * Id for the new report
   */
  reportId?: null | string
  [property: string]: any
}

/**
 * Reports are made up of multiple modules. It's helpful to understand how many, and of
 * which type.
 */
export interface ReportModuleAdded {
  /**
   * Channel of the page, if any (e.g., ""facebook"", ""instagram"", etc.)
   */
  channel?: null | string
  /**
   * If this was the initial module added to a new report
   */
  isFirstModuleOnReport?: boolean
  /**
   * Human readable name of the module
   */
  module: string
  /**
   * Type of metric the module is showing, if any
   */
  moduleMetric?: string
  /**
   * If the module has a previous period toggle, otherwise null
   */
  modulePreviousPeriod?: boolean
  /**
   * The id of the Global organization that owns the channel.
   */
  organizationId?: string
  /**
   * The product in which the event happened
   */
  product?: string
  /**
   * Id for the new report
   */
  reportId?: string
  [property: string]: any
}

/**
 * User changed scheduling type for a post in Composer
 */
export interface SchedulingTypeChanged {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * Which client was the user in when they dragged the story? IE, `publishIos`, `publishWeb`,
   * `publishAndroid`.
   */
  clientName: string
  /**
   * The Global organization ID.
   */
  organizationId: string
  /**
   * Scheduling Type that the update was changed to (target)
   */
  schedulingType: string
  /**
   * Type of the update, e.g. "post" (default) or "reel"
   */
  updateType: string
  [property: string]: any
}

/**
 * When did the user view this specific screen? This event is triggered when a user opens a
 * new screen in the application that is considered a distinct screen.
 */
export interface ScreenViewed {
  /**
   * Channel of the page, if applicable (e.g., ""facebook"", ""instagram"", etc.)
   */
  channel?: null | string
  /**
   * The database id for the channel document
   */
  channelId?: null | string
  /**
   * The id of the channel on the given service
   */
  channelServiceId?: null | string
  /**
   * What is the type of channel? ex. "page", "group"
   */
  channelType?: null | string
  /**
   * Which client was the user in when they copied the story note? IE, `publishIos`,
   * `publishWeb`, `publishAndroid`.
   */
  clientName: string
  /**
   * Human readable name of the page (e.g., ""overview"", ""posts"", etc.)
   */
  name?: null | string
  /**
   * The global organization ID of the user that viewed the screen.
   */
  organizationId?: null | string
  /**
   * The product in which the page view occurred (e.g. ""publish"", ""analyze"")
   */
  product?: string
  [property: string]: any
}

/**
 * When did user click the Share Again button in Post analytics?
 */
export interface SentPostsShareAgainClicked {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The id of the channel on the given service
   */
  channelServiceId: string
  /**
   * The type of channel the post is created for, ex. "page", "group"
   */
  channelType: string
  /**
   * What was the username of the channel?
   */
  channelUsername?: string
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * The ID of the global organization selected in the org switcher.
   */
  organizationId?: string
  /**
   * What is the id of the post that was shared again?
   */
  postId: string
  /**
   * The product in which the action occured
   */
  product: string
  [property: string]: any
}

/**
 * When did the user add a CTA to their shop grid page?
 */
export interface ShopGridLinkAdded {
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The service's own id for this channel
   */
  channelServiceId?: string
  /**
   * What is the username of the channel?
   */
  channelUsername?: string
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * What was the color of the CTA?
   */
  linkColor: string
  /**
   * What was the copy used in the CTA?
   */
  linkText: string
  /**
   * What was the URL linked to in the CTA?
   */
  linkURL: string
  /**
   * The Global organization ID.
   */
  organizationId?: string
  /**
   * The product in which the action occurred
   */
  product: string
  [property: string]: any
}

/**
 * When did the user remove a CTA from their shop grid page?
 */
export interface ShopGridLinkRemoved {
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The service's own id for this channel
   */
  channelServiceId?: string
  /**
   * What is the username of the channel?
   */
  channelUsername?: string
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * What was the copy used in the CTA?
   */
  linkText: string
  /**
   * What was the URL linked to in the CTA?
   */
  linkURL: string
  /**
   * The Global organization ID.
   */
  organizationId?: string
  /**
   * The product in which the action occurred
   */
  product: string
  [property: string]: any
}

/**
 * When did the user copy the link to their Shop Grid page?
 */
export interface ShopGridPageLinkCopied {
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The service's own id for this channel
   */
  channelServiceId?: string
  /**
   * What is the username of the channel?
   */
  channelUsername?: string
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * The Global organization ID.
   */
  organizationId?: string
  /**
   * What was the url of the Shop Grid page that was copied?
   */
  shopGridUrl: string
  [property: string]: any
}

/**
 * When did the user preview the Shop Grid page by clicking the Preview button?
 */
export interface ShopGridPagePreviewed {
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The service's own id for this channel
   */
  channelServiceId?: string
  /**
   * What is the username of the channel?
   */
  channelUsername?: string
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * The Global organization ID.
   */
  organizationId?: string
  [property: string]: any
}

/**
 * When did the user update the url of a post in the Shop Grid page?
 */
export interface ShopGridPostURLUpdated {
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The service's own id for this channel
   */
  channelServiceId?: string
  /**
   * What is the username of the channel?
   */
  channelUsername?: string
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * The Global organization ID.
   */
  organizationId?: string
  /**
   * The database id for the post object
   */
  postId: string
  /**
   * What was the url that was added to the Shop Grid post?
   */
  url: string
  [property: string]: any
}

/**
 * When did the user click on the Add Channel button on the sidebar?
 */
export interface SidebarAddChannelClicked {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The number of channels connected to the user's account
   */
  currentChannelQuantity: number
  /**
   * At time of event, did the user have payment details saved?
   */
  hasPaymentDetails: boolean | null
  /**
   * Is the user at their plan limit?
   */
  isAtPlanLimit: boolean | null
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  [property: string]: any
}

/**
 * When a user filters channels in the sidebar using the search functionality
 */
export interface SidebarChannelsFiltered {
  /**
   * The account ID of the user performing the search
   */
  accountId: string
  /**
   * The total number of channels on the account
   */
  channelCount: number
  /**
   * The channel ids that are available for the user
   */
  channelIDs: any[]
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The number of channels after applying the filter
   */
  filteredChannelCount: number
  /**
   * The channel ids that were filtered by the user
   */
  filteredChannelIDs: any[]
  /**
   * The organization ID of the user performing the search
   */
  organizationId: string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product: string
  /**
   * The search query entered by the user
   */
  searchQuery: string
  [property: string]: any
}

/**
 * Single Channel view opened with filters
 */
export interface SingleChannelOpened {
  /**
   * The account ID
   */
  accountId: string
  /**
   * Selected channel
   */
  channelSelected: string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * Selected tab - queue/sent/approvals/drafts
   */
  tab: string
  /**
   * Selected tags in filter, if not defined - all tags selected
   */
  tagsSelected?: any[]
  [property: string]: any
}

/**
 * When a user edits the avatar of their Start Page.
 */
export interface StartPageAvatarEdited {
  /**
   * The background type (e.g. 'uploaded', 'unsplash')
   */
  backgroundType: string
  /**
   * The client that the event occurred in
   */
  clientName: string
  /**
   * The active organization of the user that took the action
   */
  organizationId: string
  /**
   * Did this event occur before the user signed up for a Buffer account?
   */
  preSignup: boolean
  /**
   * Which product did the event take place in?
   */
  product: string
  /**
   * The total number of blocks currently in the Start Page
   */
  totalBlocks: number
  [property: string]: any
}

/**
 * When a user updates the background of their Start Page
 */
export interface StartPageBackgroundEdited {
  /**
   * The type of background (e.g. 'uploaded', 'unsplash')
   */
  backgroundType: string
  /**
   * The client that the event occurred in
   */
  clientName: string
  /**
   * The active organization of the user that took the action
   */
  organizationId: string
  /**
   * Did this event occur before the user signed up for a Buffer account?
   */
  preSignup: boolean
  /**
   * Which product did the event take place in?
   */
  product: string
  /**
   * The total number of blocks currently in the Start Page
   */
  totalBlocks: number
  [property: string]: any
}

/**
 * When Start Page is banned.
 */
export interface StartPageBanned {
  /**
   * The client that the event occurred in
   */
  clientName: string
  /**
   * The active organization of the user that took the action
   */
  organizationId: string
  /**
   * Did this event occur before the user signed up for a Buffer account?
   */
  preSignup: boolean
  /**
   * Which product did the event take place in?
   */
  product: string
  /**
   * The total number of blocks currently in the Start Page
   */
  totalBlocks?: number
  /**
   * The username selected for the Start Page
   */
  username: string
  [property: string]: any
}

/**
 * When a user adds a block to their Start Page
 */
export interface StartPageBlockAdded {
  /**
   * The type of the block that was added (e.g. 'link', 'video', 'sell', 'text', 'social
   * links'... )
   */
  blockType: string
  /**
   * The client that the event occurred in.
   */
  clientName: string
  /**
   * The active organization of the user that took the action
   */
  organizationId: string
  /**
   * Did this event occur before the user signed up for a Buffer account?
   */
  preSignup: boolean
  /**
   * Which product did the event take place in?
   */
  product: string
  /**
   * The total number of blocks currently in the Start Page
   */
  totalBlocks: number
  [property: string]: any
}

/**
 * When a user deletes a block from their Start Page
 */
export interface StartPageBlockDeleted {
  /**
   * The type of the block that was added (e.g. 'link', 'video', 'sell', 'text', 'social
   * links'... )
   */
  blockType: string
  /**
   * The client that the event occurred in.
   */
  clientName: string
  /**
   * The active organization of the user that took the action
   */
  organizationId: string
  /**
   * Did this event occur before the user signed up for a Buffer account?
   */
  preSignup: boolean
  /**
   * Which product did the event take place in?
   */
  product: string
  /**
   * The total number of blocks currently in the Start Page
   */
  totalBlocks: number
  [property: string]: any
}

/**
 * When a user hides one of the blocks in their Start Page.
 */
export interface StartPageBlockHidden {
  /**
   * The type of the block that was moved (e.g. 'link', 'video', 'sell', 'text', 'social
   * links'... )
   */
  blockType: string
  /**
   * The client that the event occurred in.
   */
  clientName: string
  /**
   * The active organization of the user that took the action
   */
  organizationId: string
  /**
   * Did this event occur before the user signed up for a Buffer account?
   */
  preSignup: boolean
  /**
   * Which product did the event take place in?
   */
  product: string
  /**
   * The total number of blocks currently in the Start Page
   */
  totalBlocks: number
  [property: string]: any
}

/**
 * When a user made one of the blocks visible in their Start Page.
 */
export interface StartPageBlockMadeVisible {
  /**
   * The type of the block that was moved (e.g. 'link', 'video', 'sell', 'text', 'social
   * links'... )
   */
  blockType: string
  /**
   * The client that the event occurred in.
   */
  clientName: string
  /**
   * The active organization of the user that took the action
   */
  organizationId: string
  /**
   * Did this event occur before the user signed up for a Buffer account?
   */
  preSignup: boolean
  /**
   * Which product did the event take place in?
   */
  product: string
  /**
   * The total number of blocks currently in the Start Page
   */
  totalBlocks: number
  [property: string]: any
}

/**
 * When a user moves one of the blocks in their Start Page.
 */
export interface StartPageBlockMoved {
  /**
   * The type of the block that was moved (e.g. 'link', 'video', 'sell', 'text', 'social
   * links'... )
   */
  blockType: string
  /**
   * The client that the event occurred in.
   */
  clientName: string
  /**
   * The active organization of the user that took the action
   */
  organizationId: string
  /**
   * Did this event occur before the user signed up for a Buffer account?
   */
  preSignup: boolean
  /**
   * Which product did the event take place in?
   */
  product: string
  /**
   * The total number of blocks currently in the Start Page
   */
  totalBlocks: number
  [property: string]: any
}

/**
 * When a user creates a Start Page.
 */
export interface StartPageCreated {
  /**
   * The client that the event occurred in
   */
  clientName: string
  /**
   * Name of the template the user started with in Start Page
   */
  defaultTemplate: string
  /**
   * The active organization of the user that took the action
   */
  organizationId: string
  /**
   * Did this event occur before the user signed up for a Buffer account?
   */
  preSignup: boolean
  /**
   * Which product did the event take place in?
   */
  product: string
  /**
   * The total number of Start Pages the user has created
   */
  totalStartPages: number
  /**
   * The username selected for the Start Page
   */
  username: string
  [property: string]: any
}

/**
 * When a Start Page is deleted.
 */
export interface StartPageDeleted {
  /**
   * The client that the event occurred in
   */
  clientName: string
  /**
   * The active organization of the user that took the action
   */
  organizationId: string
  /**
   * Did this event occur before the user signed up for a Buffer account?
   */
  preSignup: boolean
  /**
   * Which product did the event take place in?
   */
  product: string
  /**
   * The total number of Start Pages the user has created
   */
  totalStartPages: number
  /**
   * If the user initiated the action, could be a bot/script otherwise
   */
  userInitiatedAction: boolean
  /**
   * The username selected for the Start Page
   */
  username: string
  [property: string]: any
}

/**
 * Which CTA did the user take action on during the explainer?
 */
export interface StartPageExplainerCTATapped {
  /**
   * Which client was the user in when they performed the Getting Started action? IE,
   * `publishIos` or `publishAndroid`.
   */
  clientName: string
  /**
   * The name of the CTA they took action on
   */
  cta: string
  /**
   * The organization ID of the user that performed the Getting Started action.
   */
  organizationId?: string
  [property: string]: any
}

/**
 * When did the user open the app tour?
 */
export interface StartPageExplainerOpened {
  /**
   * What was the name of the client the user was in when they opened the Story in the native
   * channel?
   */
  clientName: string
  /**
   * The global organization id that the channel belongs to.
   */
  organizationId?: string
  /**
   * Where did the user open the explainer from?
   */
  placement: string
  /**
   * In which product did this event occur?
   */
  product: string
  [property: string]: any
}

/**
 * When a prospective user views a slide within the Onboarding.
 */
export interface StartPageExplainerViewed {
  /**
   * The mobile client the user is on, `publishIos` or `publishAndroid`.
   */
  clientName: string
  /**
   * In which product did this event occur?
   */
  product: string
  /**
   * Which slide did the user view?
   */
  slide: string
  [property: string]: any
}

/**
 * When a user selects a GIF.
 */
export interface StartPageGIFSelected {
  /**
   * The client that the event occurred in
   */
  clientName: string
  /**
   * The unique name or url for the selected GIF
   */
  gifId: string
  /**
   * The active organization of the user that took the action
   */
  organizationId: string
  /**
   * Did this event occur before the user signed up for a Buffer account?
   */
  preSignup: boolean
  /**
   * Which product did the event take place in?
   */
  product: string
  /**
   * The total number of blocks currently in the Start Page
   */
  totalBlocks: number
  [property: string]: any
}

/**
 * When a user edits the header of their Start Page.
 */
export interface StartPageHeaderEdited {
  /**
   * The client that the event occurred in
   */
  clientName: string
  /**
   * Unique ID for the chosen layout
   */
  headerLayout: string
  /**
   * The active organization of the user that took the action
   */
  organizationId: string
  /**
   * Which product did the event take place in?
   */
  product: string
  /**
   * Unique ID for the start page theme
   */
  themeID?: string
  /**
   * The total number of blocks currently in the Start Page
   */
  totalBlocks: number
  [property: string]: any
}

/**
 * When a user wants to publish a Start Page but they hit their plan limit
 */
export interface StartPagePlanLimitHit {
  /**
   * The client that the event occurred in
   */
  clientName: string
  /**
   * The total number of Start Pages the user has created
   */
  createdStartPages: number
  /**
   * Name of the template the user started with in Start Page
   */
  defaultTemplate?: string
  /**
   * The active organization of the user that took the action
   */
  organizationId: string
  /**
   * Which product did the event take place in?
   */
  product: string
  [property: string]: any
}

/**
 * When a scheduled post cant trigger re-publishing the Start Page.
 */
export interface StartPagePostBlockedUserActionRequired {
  /**
   * The client that the event occurred in
   */
  clientName: string
  /**
   * Content of the Post
   */
  content: string
  /**
   * The active organization of the user that took the action
   */
  organizationId: string
  /**
   * Which product did the event take place in?
   */
  product: string
  /**
   * The time when the post was supposed to go out
   */
  scheduledAt: string
  /**
   * The total number of blocks currently in the Start Page
   */
  totalBlocks?: number
  /**
   * The username selected for the Start Page
   */
  username: string
  [property: string]: any
}

/**
 * When a user publishes their Start Page.
 */
export interface StartPagePublished {
  /**
   * The client that the event occurred in
   */
  clientName: string
  /**
   * The active organization of the user that took the action
   */
  organizationId: string
  /**
   * Did this event occur before the user signed up for a Buffer account?
   */
  preSignup: boolean
  /**
   * Which product did the event take place in?
   */
  product: string
  /**
   * The total number of blocks currently in the Start Page
   */
  totalBlocks?: number
  /**
   * The username selected for the Start Page
   */
  username: string
  [property: string]: any
}

/**
 * When a user loads the Statistics tab.
 */
export interface StartPageStatisticsViewed {
  /**
   * The client that the event occurred in
   */
  clientName: string
  /**
   * The active organization of the user that took the action
   */
  organizationId: string
  /**
   * Did this event occur before the user signed up for a Buffer account?
   */
  preSignup: boolean
  /**
   * Which product did the event take place in?
   */
  product: string
  /**
   * The total number of blocks currently in the Start Page
   */
  totalBlocks?: number
  [property: string]: any
}

/**
 * When a user edits the theme of their Start Page.
 */
export interface StartPageThemeEdited {
  /**
   * The client that the event occurred in
   */
  clientName: string
  /**
   * The active organization of the user that took the action
   */
  organizationId: string
  /**
   * Did this event occur before the user signed up for a Buffer account?
   */
  preSignup: boolean
  /**
   * Which product did the event take place in?
   */
  product: string
  /**
   * The unique identifier of the theme that was edited
   */
  themeId: string
  /**
   * The total number of blocks currently in the Start Page
   */
  totalBlocks: number
  [property: string]: any
}

/**
 * When a user selects a theme for their Start Page.
 */
export interface StartPageThemeSelected {
  /**
   * The client that the event occurred in
   */
  clientName: string
  /**
   * The active organization of the user that took the action
   */
  organizationId: string
  /**
   * Did this event occur before the user signed up for a Buffer account?
   */
  preSignup: boolean
  /**
   * Which product did the event take place in?
   */
  product: string
  /**
   * The unique identifier of the theme that was selected
   */
  themeId: string
  /**
   * The total number of blocks currently in the Start Page
   */
  totalBlocks: number
  [property: string]: any
}

/**
 * When a user copies the URL for their Start Page.
 */
export interface StartPageURLCopied {
  /**
   * The client that the event occurred in
   */
  clientName: string
  /**
   * The active organization of the user that took the action
   */
  organizationId: string
  /**
   * Did this event occur before the user signed up for a Buffer account?
   */
  preSignup: boolean
  /**
   * Which product did the event take place in?
   */
  product: string
  /**
   * The total number of blocks currently in the Start Page
   */
  totalBlocks?: number
  /**
   * The url of the Start Page
   */
  url: string
  [property: string]: any
}

/**
 * When a Start Page is unpublished.
 */
export interface StartPageUnpublished {
  /**
   * The client that the event occurred in
   */
  clientName: string
  /**
   * The active organization of the user that took the action
   */
  organizationId: string
  /**
   * Did this event occur before the user signed up for a Buffer account?
   */
  preSignup: boolean
  /**
   * Which product did the event take place in?
   */
  product: string
  /**
   * The total number of blocks currently in the Start Page
   */
  totalBlocks?: number
  /**
   * If the user initiated the action, could be a bot/script otherwise
   */
  userInitiatedAction: boolean
  /**
   * The username selected for the Start Page
   */
  username: string
  [property: string]: any
}

/**
 * When a user confirms a username for their Start Page.
 */
export interface StartPageUsernameConfirmed {
  /**
   * The client that the event occurred in
   */
  clientName: string
  /**
   * The active organization of the user that took the action
   */
  organizationId: string
  /**
   * Did this event occur before the user signed up for a Buffer account?
   */
  preSignup: boolean
  /**
   * Which product did the event take place in?
   */
  product: string
  /**
   * The total number of blocks currently in the Start Page
   */
  totalBlocks?: number
  /**
   * The username confirmed for the Start Page
   */
  username: string
  [property: string]: any
}

/**
 * When a user selects a username for their Start Page.
 */
export interface StartPageUsernameSeleted {
  /**
   * The client that the event occurred in
   */
  clientName: string
  /**
   * The active organization of the user that took the action
   */
  organizationId: string
  /**
   * Did this event occur before the user signed up for a Buffer account?
   */
  preSignup: boolean
  /**
   * Which product did the event take place in?
   */
  product: string
  /**
   * The total number of blocks currently in the Start Page
   */
  totalBlocks?: number
  /**
   * The username selected for the Start Page
   */
  username: string
  [property: string]: any
}

/**
 * When a user updates a username for their Start Page.
 */
export interface StartPageUsernameUpdated {
  /**
   * The client that the event occurred in
   */
  clientName: string
  /**
   * The active organization of the user that took the action
   */
  organizationId: string
  /**
   * Did this event occur before the user signed up for a Buffer account?
   */
  preSignup: boolean
  /**
   * Which product did the event take place in?
   */
  product: string
  /**
   * The total number of blocks currently in the Start Page
   */
  totalBlocks?: number
  /**
   * The updated username
   */
  username: string
  [property: string]: any
}

/**
 * When did the user click the Start Trial Modal main cta?
 */
export interface StartTrialModalMainCtaClicked {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * Which client was the user in when they opened this modal?
   */
  clientName: null | string
  /**
   * The name of the CTA clicked.
   */
  cta: null | string
  /**
   * Is the user at their plan limit?
   */
  isAtPlanLimit?: boolean | null
  /**
   * The global organization ID of the user that clicked the Start Trial main cta.
   */
  organizationId: null | string
  /**
   * Which product did the user click the main cta in?
   */
  product: null | string
  /**
   * The name of the upgrade path
   */
  upgradePathName: null | string
  [property: string]: any
}

/**
 * When did the user view the Start Trial modal?
 */
export interface StartTrialModalViewed {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * Which client was the user in when they opened this modal?
   */
  clientName: null | string
  /**
   * The name of the CTA clicked.
   */
  cta: null | string
  /**
   * Is the user at their plan limit?
   */
  isAtPlanLimit?: boolean | null
  /**
   * The global organization ID of the user that viewed the Start Trial modal.
   */
  organizationId: null | string
  /**
   * Which product did the user view the modal in?
   */
  product: null | string
  /**
   * The name of the upgrade path
   */
  upgradePathName: null | string
  [property: string]: any
}

/**
 * A user has copied the content of a sticker on the reminder post preview screen
 */
export interface StickerCopied {
  /**
   * The channel that the post was scheduled for
   */
  channel: string
  /**
   * The name of the client in which the action occurred (ex. publishIos)
   */
  clientName: string
  /**
   * The global organization id that the channel belongs to
   */
  organizationId: string
  /**
   * The post id for the reminder being shared
   */
  postId: string
  /**
   * The product in which the action occured
   */
  product: string
  /**
   * The post id for the reminder being shared
   */
  sticker: string
  [property: string]: any
}

/**
 * User deselected a sticker in Composer/Reminders
 */
export interface StickerDeselected {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * Which client was the user in when they dragged the story? IE, `publishIos`, `publishWeb`,
   * `publishAndroid`.
   */
  clientName: string
  /**
   * The Global organization ID.
   */
  organizationId: string
  /**
   * Name of the sticker selected
   */
  sticker: string
  /**
   * Type of the update, e.g. "post" (default) or "reel"
   */
  updateType: string
  [property: string]: any
}

/**
 * User selected a sticker in Composer/Reminders
 */
export interface StickerSelected {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * Which client was the user in when they dragged the story? IE, `publishIos`, `publishWeb`,
   * `publishAndroid`.
   */
  clientName: string
  /**
   * The Global organization ID.
   */
  organizationId: string
  /**
   * Name of the sticker selected
   */
  sticker: string
  /**
   * Type of the update, e.g. "post" (default) or "reel"
   */
  updateType: string
  [property: string]: any
}

/**
 * When a user compares metrics in Stories Insights.
 */
export interface StoriesInsightsMetricsCompared {
  /**
   * The channel of the selected profile, ex. “instagram”.
   */
  channel: string
  /**
   * The ID of the channel selected when the metrics were compared.
   */
  channelId: string
  /**
   * The name of the client in which the action occurred.
   */
  clientName: string
  /**
   * The first metric selected in the comparison.
   */
  metricOne: string
  /**
   * The second metric selected in the comparison.
   */
  metricTwo: string
  /**
   * The global organization ID of the user that took the action.
   */
  organizationId: string
  /**
   * The product in which the event occurred, ex. “analyze”.
   */
  product: string
  [property: string]: any
}

/**
 * When a user sorts the Stories Insights.
 */
export interface StoriesInsightsSorted {
  /**
   * The channel of the selected profile, ex. “instagram”.
   */
  channel: string
  /**
   * The ID of the channel selected when the story insights were sorted.
   */
  channelId: string
  /**
   * The name of the client in which the action occurred.
   */
  clientName: string
  /**
   * What were the stories sorted by?
   */
  metric: string
  /**
   * The global organization ID of the user that took the action.
   */
  organizationId: string
  /**
   * The product in which the event occurred, ex. “analyze”.
   */
  product: string
  [property: string]: any
}

/**
 * When did the user drag and drop a story in the storygroup?
 */
export interface StoryDragged {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The service's own id for this channel
   */
  channelServiceId: string
  /**
   * What was the unique identifier of the client the user was in when they dragged the story?
   */
  clientId?: null | string
  /**
   * Which client was the user in when they dragged the story? IE, `publishIos`, `publishWeb`,
   * `publishAndroid`.
   */
  clientName: string
  /**
   * The Global organization ID.
   */
  organizationId?: string
  /**
   * The product for which the storygroup was created
   */
  product: string
  [property: string]: any
}

/**
 * When did the user open the story group composer?
 */
export interface StoryGroupComposerOpened {
  /**
   * The channel's service, ex. "instagram"
   */
  channel: string
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The service's own id for this channel
   */
  channelServiceId: string
  /**
   * What was the unique identifier of the client the user was in when they opened the story
   * group composer?
   */
  clientId?: null | string
  /**
   * Which client was the user in when they opened the story group composer? IE, `publishIos`,
   * `publishWeb`, `publishAndroid`.
   */
  clientName: string
  /**
   * The Global organization ID.
   */
  organizationId?: string
  /**
   * In which product did this event occur?
   */
  product: string
  [property: string]: any
}

/**
 * When did the user create a story group reminder? This event is triggered when the story
 * group is originally created in the production database, which will differ from the
 * timestamp of any updates to the story group or when the story group is sent.
 */
export interface StoryGroupCreated {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The service's own id for this channel
   */
  channelServiceId: string
  /**
   * What was the unique identifier of the client the user was in when they created the story
   * group?
   */
  clientId?: null | string
  /**
   * Which client was the user in when they created the story group? IE, `publishIos`,
   * `publishWeb`, `publishAndroid`.
   */
  clientName: string
  /**
   * Which call to action did the user click to get to the story group composer?
   */
  cta?: null | string
  /**
   * Which product is the CTA located in? Examples would be either the product, like "publish".
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA? In many cases it makes sense to describe the
   * intended result of the CTA, like `proTrial` or `publishPro`.
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be "menu" for the Pro trial CTA in
   * Publish app shell menu.
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA? If setting the value for the first time, set to
   * "1".
   */
  ctaVersion?: null | string
  /**
   * What app view is the CTA located in? Examples would be, "composer" or "analyticsOverview"
   * for Publish.
   */
  ctaView?: null | string
  /**
   * The number of images included in the story group.
   */
  imageCount: number
  /**
   * The number of media included in the story group.
   */
  mediaCount: number
  /**
   * The number of notes included in the story group.
   */
  noteCount: number
  /**
   * The Global organization ID.
   */
  organizationId?: string
  /**
   * The product for which the story was created
   */
  product: string
  /**
   * The time at which the reminder is scheduled to be sent.
   */
  scheduledAt: string
  /**
   * The unique identifier for this story group in our production database.
   */
  storyGroupId: string
  /**
   * The number of videos included in the story group.
   */
  videoCount: number
  [property: string]: any
}

/**
 * When did the user Preview a story group reminder? This event is triggered each time the
 * story group is previewed. There can be many preview events for a single story group.
 */
export interface StoryGroupPreviewed {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The service's own id for this channel
   */
  channelServiceId: string
  /**
   * What was the unique identifier of the client the user was in when they previewed the
   * story group?
   */
  clientId?: null | string
  /**
   * Which client was the user in when they previewed the story group? IE, `publishIos`,
   * `publishWeb`, `publishAndroid`.
   */
  clientName: string
  /**
   * Which call to action did the user click to preview the story group?
   */
  cta?: null | string
  /**
   * Which product is the CTA located in? Examples would be either the product, like "publish".
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA? In many cases it makes sense to describe the
   * intended result of the CTA, like `proTrial` or `publishPro`.
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be "menu" for the Pro trial CTA in
   * Publish app shell menu.
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA? If setting the value for the first time, set to
   * "1".
   */
  ctaVersion?: null | string
  /**
   * What app view is the CTA located in? Examples would be, "composer" or "analyticsOverview"
   * for Publish.
   */
  ctaView?: null | string
  /**
   * The number of images included in the story group.
   */
  imageCount: number
  /**
   * The number of media included in the story group.
   */
  mediaCount: number
  /**
   * The number of notes included in the story group.
   */
  noteCount: number
  /**
   * The Global organization ID.
   */
  organizationId?: string
  /**
   * The product for which the story group was created
   */
  product: string
  /**
   * The time at which the reminder is scheduled to be sent.
   */
  scheduledAt?: string
  /**
   * The unique identifier for this story group in our production database.
   */
  storyGroupId?: string
  /**
   * The number of videos included in the story group.
   */
  videoCount: number
  [property: string]: any
}

/**
 * When did the user update a story group reminder? This event is triggered each time the
 * story group is updated after it was originally created in the production database.
 */
export interface StoryGroupUpdated {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The service's own id for this channel
   */
  channelServiceId: string
  /**
   * What was the unique identifier of the client the user was in when they updated the story
   * group?
   */
  clientId?: null | string
  /**
   * Which client was the user in when they updated the story group? IE, `publishIos`,
   * `publishWeb`, `publishAndroid`.
   */
  clientName: string
  /**
   * Which call to action did the user click to get to the story group composer?
   */
  cta?: null | string
  /**
   * Which product is the CTA located in? Examples would be either the product, like "publish".
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA? In many cases it makes sense to describe the
   * intended result of the CTA, like `proTrial` or `publishPro`.
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be "menu" for the Pro trial CTA in
   * Publish app shell menu.
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA? If setting the value for the first time, set to
   * "1".
   */
  ctaVersion?: null | string
  /**
   * What app view is the CTA located in? Examples would be, "composer" or "analyticsOverview"
   * for Publish.
   */
  ctaView?: null | string
  /**
   * The number of images included in the story group.
   */
  imageCount: number
  /**
   * The number of media included in the story group.
   */
  mediaCount: number
  /**
   * The number of notes included in the story group.
   */
  noteCount: number
  /**
   * The Global organization ID.
   */
  organizationId?: string
  /**
   * The product for which the story group was created
   */
  product: string
  /**
   * The time at which the reminder is scheduled to be sent.
   */
  scheduledAt: string
  /**
   * The unique identifier for this story group in our production database.
   */
  storyGroupId: string
  /**
   * The number of videos included in the story group.
   */
  videoCount: number
  [property: string]: any
}

/**
 * When did the user crop the image for this story? This event is triggered each time an
 * image is cropped, so it is possible to have multiple events for one given story. The
 * image preview crop is iOS specific.
 */
export interface StoryImageCropped {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The service's own id for this channel
   */
  channelServiceId: string
  /**
   * What was the unique identifier of the client the user was in when they cropped the Story
   * image?
   */
  clientId?: null | string
  /**
   * Which client was the user in when they cropped the image from the story preview? IE,
   * `publishIos`, `publishWeb`, `publishAndroid`.
   */
  clientName: string
  /**
   * Where did the cropping take place? Was it in the composer or did it happen in the "time
   * to post" screen?
   */
  cropLocation: string
  /**
   * Which call to action did the user click to get to crop the image?
   */
  cta?: null | string
  /**
   * Which product is the CTA located in? Examples would be either the product, like "publish".
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA? In many cases it makes sense to describe the
   * intended result of the CTA, like `proTrial` or `publishPro`.
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be "menu" for the Pro trial CTA in
   * Publish app shell menu.
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA? If setting the value for the first time, set to
   * "1".
   */
  ctaVersion?: null | string
  /**
   * What app view is the CTA located in? Examples would be, "composer" or "analyticsOverview"
   * for Publish.
   */
  ctaView?: null | string
  /**
   * The Global organization ID.
   */
  organizationId?: string
  /**
   * The product for which the story was created
   */
  product: string
  /**
   * The time at which the reminder is scheduled to be sent.
   */
  scheduledAt?: string
  /**
   * The unique identifier for this story group in our production database.
   */
  storyGroupId?: string
  [property: string]: any
}

/**
 * When a user downloads an image from the stories creator.
 */
export interface StoryImageDownloaded {
  /**
   * The client that the event occurred in
   */
  clientName?: string
  /**
   * The active organization of the user that took the action
   */
  organizationId?: string
  /**
   * Which product did the event take place in?
   */
  product?: string
  properties: any
  [property: string]: any
}

/**
 * When did the user upload an image to a story, where the image did not conform to the 9:16
 * ratio required by Instagram?
 */
export interface StoryNonconformingImageUploaded {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The service's own id for this channel
   */
  channelServiceId: string
  /**
   * What was the unique identifier of the client the user was in when they uploaded a
   * nonconforming image?
   */
  clientId?: null | string
  /**
   * Which client was the user in when they uploaded a nonconforming image? IE, `publishIos`,
   * `publishWeb`, `publishAndroid`.
   */
  clientName: string
  /**
   * Which call to action did the user click to get to the Story composer?
   */
  cta?: null | string
  /**
   * Which product is the CTA located in? Examples would be either the product, like "publish".
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA? In many cases it makes sense to describe the
   * intended result of the CTA, like `proTrial` or `publishPro`.
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be "menu" for the Pro trial CTA in
   * Publish app shell menu.
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA? If setting the value for the first time, set to
   * "1".
   */
  ctaVersion?: null | string
  /**
   * What app view is the CTA located in? Examples would be, "composer" or "analyticsOverview"
   * for Publish.
   */
  ctaView?: null | string
  /**
   * The actual aspect ratio of the uploaded image.
   */
  imageAspectRatio?: string
  /**
   * The Global organization ID.
   */
  organizationId?: string
  /**
   * The product for which the story was created
   */
  product: string
  /**
   * The time at which the reminder is scheduled to be sent.
   */
  scheduledAt?: string
  /**
   * The unique identifier for this story group in our production database.
   */
  storyGroupId?: string
  [property: string]: any
}

/**
 * When did the user add a note to the story?
 */
export interface StoryNoteAdded {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The service's own id for this channel
   */
  channelServiceId: string
  /**
   * How many characters are included in the note.
   */
  characterCount?: number
  /**
   * What was the unique identifier of the client the user was in when they updated the story?
   */
  clientId?: null | string
  /**
   * Which client was the user in when they added a note to the story? IE, `publishIos`,
   * `publishWeb`, `publishAndroid`.
   */
  clientName: string
  /**
   * Which call to action did the user click to get to the Story composer?
   */
  cta?: null | string
  /**
   * Which product is the CTA located in? Examples would be either the product, like "publish".
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA? In many cases it makes sense to describe the
   * intended result of the CTA, like `proTrial` or `publishPro`.
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be "menu" for the Pro trial CTA in
   * Publish app shell menu.
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA? If setting the value for the first time, set to
   * "1".
   */
  ctaVersion?: null | string
  /**
   * What app view is the CTA located in? Examples would be, "composer" or "analyticsOverview"
   * for Publish.
   */
  ctaView?: null | string
  /**
   * What text value is included in the note field.
   */
  noteText?: string
  /**
   * The Global organization ID.
   */
  organizationId?: string
  /**
   * The product for which the story was created
   */
  product: string
  /**
   * The unique identifier for this story group in our production database.
   */
  storyGroupId?: string
  [property: string]: any
}

/**
 * When did the user copy a note in one of the mobile apps? We use this as a proxy to let us
 * know when the user actually posts the Story.
 */
export interface StoryNoteCopied {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The service's own id for this channel
   */
  channelServiceId: string
  /**
   * What was the unique identifier of the client the user was in when they updated the story?
   */
  clientId?: null | string
  /**
   * Which client was the user in when they copied the story note? IE, `publishIos`,
   * `publishWeb`, `publishAndroid`.
   */
  clientName: string
  /**
   * The organization ID of the user that copied the story note.
   */
  organizationId?: string
  /**
   * The product for which the story was created
   */
  product: string
  [property: string]: any
}

/**
 * When did the user open the Story in the channel (e.g. Instagram)? This event is triggered
 * when a user taps "Open in Instagram".
 */
export interface StoryOpenedInChannel {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The database id of the channel for which the story was created.
   */
  channelId: string
  /**
   * The service's own id for this channel.
   */
  channelServiceId: string
  /**
   * What was the unique identifier of the client the user was in when they opened the Story
   * in the native channel?
   */
  clientId?: null | string
  /**
   * What was the name of the client the user was in when they opened the Story in the native
   * channel?
   */
  clientName: string
  /**
   * In which product did this event occur?
   */
  product: string
  /**
   * The unique identifier for this story group in our production database.
   */
  storyGroupId: string
  [property: string]: any
}

/**
 * When did the user choose to resume the story creating process in the channel?
 */
export interface StoryResumedInChannel {
  /**
   * The channel's service, ex. "facebook"
   */
  channel: string
  /**
   * The database id for the channel document
   */
  channelId: string
  /**
   * The service's own id for this channel
   */
  channelServiceId: string
  /**
   * What was the unique identifier of the client the user was in when they updated the story?
   */
  clientId?: null | string
  /**
   * Which client was the user in when they copied the story note? IE, `publishIos`,
   * `publishWeb`, `publishAndroid`.
   */
  clientName: string
  /**
   * The product in which the story was created
   */
  product: string
  [property: string]: any
}

/**
 * When did the user click a CTA on the streak widget?
 */
export interface StreakButtonClicked {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * Which client was the user in when they took this action?
   */
  clientName?: string
  /**
   * The CTA string of the cta clicked
   */
  cta: string
  /**
   * The global organization ID of the user that clicked the streak widget.
   */
  organizationId: string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product: string
  /**
   * The number of days the user has posted in a row
   */
  streakCount: number
  /**
   * The status of the streak
   */
  streakStatus: string
  [property: string]: any
}

/**
 * When an organization already in a streak increased their streak count
 */
export interface StreakCountIncreased {
  /**
   * Is this a new maximum streak length for the user?
   */
  isNewMaxStreak: boolean
  /**
   * What is this organization's maximum streak count?
   */
  maxStreakLength: number
  /**
   * The new streak count
   */
  newStreakCount: number
  /**
   * The active organization of the user that took the action
   */
  organizationId: string
  /**
   * When did the current streak start?
   */
  streakStartDate: null | string
  /**
   * The type of streak
   */
  streakType: string
  [property: string]: any
}

/**
 * When an organization loses a streak
 */
export interface StreakCountReset {
  /**
   * The active organization of the user that took the action
   */
  organizationId: string
  /**
   * What was the streak count before it was reset?
   */
  previousStreakCount: number
  /**
   * Was the streak reset via the cron or by posting after midnight, before the cron processed
   * this org?
   */
  resetMethod?: string
  /**
   * The type of streak
   */
  streakType: string
  /**
   * What is the organization's timezone?
   */
  timezone?: string
  /**
   * Was the most recent streak the organization's longest?
   */
  wasMaxStreak: boolean
  [property: string]: any
}

/**
 * When an organization performed a streak action two days in a row, starting a new streak
 */
export interface StreakStarted {
  /**
   * The active organization of the user that took the action
   */
  organizationId: string
  /**
   * The type of streak
   */
  streakType: string
  [property: string]: any
}

/**
 * When a streak was updated for an organization
 */
export interface StreakUpdated {
  /**
   * The newly calculated streak value
   */
  newStreakCount: number
  /**
   * The newly calculated streak status (e.g. Hit, AtRisk)
   */
  newStreakStatus: string
  /**
   * The organization to which the streak belongs
   */
  organizationId: string
  /**
   * The previous streak value
   */
  previousStreakCount: number
  /**
   * The previous streak status (e.g. OnTrack)
   */
  previousStreakStatus: string
  /**
   * The type of streak
   */
  streakType: string
  [property: string]: any
}

/**
 * When did the user clicked on the CTA to pay for a subscription and it opened Stripe
 * Checkout?
 */
export interface StripeCheckoutSessionCreated {
  /**
   * Count of channels associated with this subscription.
   */
  channelQuantity?: number | null
  /**
   * If the user navigated to this page from a CTA on another Buffer page, which call to
   * action was it?
   */
  cta?: null | string
  /**
   * Which product or website is the CTA located in? Examples would be either the product,
   * like `publish`, or the website, like `bufferSite`
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA?  In many cases it makes sense to describe the
   * intended result of the CTA, like `proTrial` or `publishPro`
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in
   * Publish app shell menu
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA?  If setting the value for the first time, set to
   * `1`
   */
  ctaVersion?: null | string
  /**
   * What website page or app view is the CTA located?  Examples would be, `composer` or
   * `analyticsOverview` for Publish, and `pricingPublish` for the Publish pricing page on the
   * Buffer marketing site
   */
  ctaView?: null | string
  /**
   * What was the initial channel quantity shown on the plan selector when opened?
   */
  initialChannelQuantity?: number | null
  /**
   * What was the initial billing cycle shown on the plan selector when opened?
   */
  initialCycle?: null | string
  /**
   * What was the initial selected plan shown on the plan selector when opened?
   */
  initialSelectedPlan?: null | string
  /**
   * Will the user be sent to Stripe Checkout for payment?
   */
  isStripeCheckout?: boolean | null
  /**
   * The global organization ID of the user that viewed the plan selector.
   */
  organizationId: null | string
  /**
   * Human readable name of the section of the plan selector viewed (e.g., ""plans"",
   * ""billing_info"", etc.)
   */
  screenName?: null | string
  /**
   * The selected plan of the user at time of Stripe Checkout creation.
   */
  selectedPlan?: null | string
  [property: string]: any
}

/**
 * When was the subscription-level tax status changed?
 */
export interface SubscriptionAutomaticTaxUpdated {
  /**
   * Unique identifier for the Stripe customer
   */
  customerId: string
  /**
   * What is the Stripe customer's current tax status?
   */
  customerTaxStatus: string
  /**
   * From which billing event was the tax status updated?
   */
  eventSource: string
  /**
   * Does the subscription now have automatic tax collection enabled?
   */
  newAutomaticTaxStatus: boolean
  /**
   * The ID of the organization for which the tax exempt was updated
   */
  organizationId: string
  /**
   * Did the subscription previously have automoatic tax collection enabled?
   */
  previousAutomaticTaxStatus: boolean
  /**
   * Stripe event id from the webhook event
   */
  stripeEventId: string
  /**
   * Unique identifier for the Stripe subscription being updated
   */
  subscriptionId: string
  [property: string]: any
}

/**
 * When the number of channels associated with the subscription changes. This event will
 * trigger each time a user adds or removes a channel during a paid subscription, but it is
 * possible for multiple channel adds to be represented by one event as a result of a bulk
 * add UI option.
 */
export interface SubscriptionChannelQuantityChanged {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The absolute dollar amount per month that this channel change is worth. If the cost per
   * channel per month is $10, and the user removed a channel, this value would be `10`. If
   * the channel count went from 1 to 4 (as a result of a bulk add) and the subscription was
   * for $5 per channel, this value would be `15`.
   */
  changeValue?: number
  /**
   * Count of channels associated with this subscription after this change was made.
   */
  channelQuantity?: number
  /**
   * Channels added to the subscription
   */
  channelsAdded?: any[]
  /**
   * Channels removed from the subscription
   */
  channelsRemoved?: any[]
  /**
   * Unique identifier for the billing gateway customer.
   */
  customerId?: string
  /**
   * What triggered this event? e.g. Stripe or RevenueCat
   */
  gateway?: string
  /**
   * The ID of the organization whose plan changed.
   */
  organizationId: string
  /**
   * Billing gateway plan id.
   */
  planId?: string
  /**
   * Human-readable plan name.
   */
  planName?: string
  /**
   * Count of channels associated with this subscription before this change was made.
   */
  previousChannelQuantity?: number
  /**
   * The product which the subscription is for.
   */
  product?: string
  /**
   * What is the difference in quantity between the current and previous quantity
   */
  quantityDiff?: number | null
  /**
   * The type of impact to Buffer MRR as a result of this channel quantity change.
   */
  revenueEffect?: string
  /**
   * Stripe event id from the webhook event.
   */
  stripeEventId?: string
  /**
   * Unique identifier for the subscription.
   */
  subscriptionId?: string
  [property: string]: any
}

/**
 * Understand when a user modifies the plan or billing quantity of a paid subscription that
 * results in a lower revenue amount or projected lower LTV
 */
export interface SubscriptionDowngraded {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The client in which the event occurred.
   */
  clientName?: string
  /**
   * Which call to action did the user click to downgrade their paid subscription
   */
  cta?: null | string
  /**
   * Which product or website is the CTA located in? Examples would be either the product,
   * like `publish`, or the website, like `bufferSite`
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA?  In many cases it makes sense to describe the
   * intended result of the CTA, like `proTrial` or `publishPro`
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in
   * Publish app shell menu
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA?  If setting the value for the first time, set to
   * `1`
   */
  ctaVersion?: null | string
  /**
   * What website page or app view is the CTA located?  Examples would be, `composer` or
   * `analyticsOverview` for Publish, and `pricingPublish` for the Publish pricing page on the
   * Buffer marketing site
   */
  ctaView?: null | string
  /**
   * Unique identifier for the Stripe customer
   */
  customerId?: string
  /**
   * What was the payment interval for this subscription (month or year) after the downgrade
   * event?
   */
  cycle?: string
  /**
   * What triggered this event? e.g. Stripe or RevenueCat
   */
  gateway?: string
  /**
   * Does the user have a tax ID?
   */
  hasTaxId?: boolean | null
  /**
   * Is automatic tax collection in Stripe enabled for this user?
   */
  isAutomaticTaxEnabled?: boolean | null
  /**
   * Was this subscription downgrade event the result of a change only to the billing cycle
   * changing to a shorter duration?  For example, this would be true if the subscription plan
   * changed from `Premium Business v1 - Yearly` to `Premium Business v1 - Monthly`.
   */
  isPlanCycleChangeOnly?: boolean
  /**
   * Was the subscription downgraded via Stripe Checkout?
   */
  isStripeCheckout?: boolean | null
  /**
   * Is the user tax exempt?
   */
  isTaxExempt?: boolean | null
  /**
   * What was the monthly recurring value of the subscription after the downgrade event? If
   * the billing cycle is yearly, this amount will be the annual amount divided by 12.
   */
  mrrValue?: number
  /**
   * The ID of the organization whose subscription was downgraded.
   */
  organizationId?: string
  /**
   * What is the unique identifier in Stripe of the billing plan for the subscription after
   * the downgrade event?
   */
  planId?: string
  /**
   * What was the human-readable plan name for the subscription after the downgrade event?
   */
  planName?: string
  /**
   * What was the user's previous billing state before making this change? (e.g. free, trial,
   * paid)
   */
  previousBillingState?: null | string
  /**
   * What was the payment interval for this subscription (month or year) before the downgrade
   * event?
   */
  previousCycle?: string
  /**
   * What was the monthly recurring value of the subscription before the downgrade event? If
   * the billing cycle is yearly, this amount will be the annual amount divided by 12.
   */
  previousMrrValue?: number
  /**
   * What is the unique identifier in Stripe of the billing plan for the subscription before
   * the downgrade event?
   */
  previousPlanId?: string
  /**
   * What was the human-readable plan name for the subscription before the downgrade event?
   */
  previousPlanName?: string
  /**
   * What was the previous quantity of the subscription?
   */
  previousQuantity?: number | null
  /**
   * Which product was this subscription for?
   */
  product: string
  /**
   * What is the current quantity of the subscription?
   */
  quantity?: number | null
  /**
   * What is the difference in quantity between the current and previous quantity
   */
  quantityDiff?: number | null
  /**
   * What was the unique identifier for the downgrade event from the Stripe webhook?
   */
  stripeEventId: string
  /**
   * What was the unique identifier for the subscription in Stripe?
   */
  subscriptionId: string
  [property: string]: any
}

/**
 * When did the user's paid subscription end for this product and plan?
 */
export interface SubscriptionEnded {
  /**
   * Cost of the plan after trial
   */
  amount?: number
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The client in which the event occurred.
   */
  clientName?: string
  /**
   * Unique identifier for the Stripe customer
   */
  customerId?: string
  /**
   * The subscription payment interval (month or year)
   */
  cycle?: string
  /**
   * End date of the subscription (ISO-8601)
   */
  endDate?: string
  /**
   * What caused this event to be triggered?  If this event was triggered via normal user
   * behavior, the property value will be null.  Examples of values that might be populated in
   * this property are `system_migration` and `bug_fix_backfill`
   */
  eventTrigger?: null | string
  /**
   * What triggered this event? e.g. Stripe or RevenueCat
   */
  gateway?: string
  /**
   * What was the name of the multi product bundle that this subscription is associated with?
   * For example, 'analyze_publish_oct2019'
   */
  multiProductBundleName?: null | string
  /**
   * The ID of the organization whose subscription ended.
   */
  organizationId?: string
  /**
   * Stripe plan id
   */
  planId?: string
  /**
   * Human-readable plan name
   */
  planName?: string
  /**
   * The product which the subscription is for
   */
  product: string
  /**
   * MRR Value (if monthly then amount, if yearly, divide yearly amount by 12)
   */
  revenue?: number
  /**
   * Stripe event id from the webhook event
   */
  stripeEventId?: string
  /**
   * Unique identifier for trial/subscription (Stripe sub id)
   */
  subscriptionId?: string
  [property: string]: any
}

/**
 * When the plan associated with the subscription changes.
 */
export interface SubscriptionPlanChanged {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * Which call to action did the user click to start their paid subscription
   */
  cta?: null | string
  /**
   * Which product or website is the CTA located in? Examples would be either the product,
   * like `publish`, or the website, like `bufferSite`
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA?  In many cases it makes sense to describe the
   * intended result of the CTA, like `proTrial` or `publishPro`
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in
   * Publish app shell menu
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA?  If setting the value for the first time, set to
   * `1`
   */
  ctaVersion?: null | string
  /**
   * What website page or app view is the CTA located?  Examples would be, `composer` or
   * `analyticsOverview` for Publish, and `pricingPublish` for the Publish pricing page on the
   * Buffer marketing site
   */
  ctaView?: null | string
  /**
   * Unique identifier for the Stripe customer
   */
  customerId?: string
  /**
   * The subscription payment interval (month or year)
   */
  cycle?: string
  /**
   * What triggered this event? e.g. Stripe or RevenueCat
   */
  gateway?: string
  /**
   * Was the subscription plan changed via Stripe Checkout?
   */
  isStripeCheckout?: boolean | null
  /**
   * The ID of the organization whose plan changed.
   */
  organizationId?: string
  /**
   * Stripe plan id
   */
  planId?: string
  /**
   * Human-readable plan name
   */
  planName?: string
  /**
   * The billing interval of the previous plan.
   */
  previousCycle?: string
  /**
   * Stripe plan id of the previous plan, if exists
   */
  previousPlanId?: string
  /**
   * Human-readable plan name of the previous plan, if exists
   */
  previousPlanName?: string
  /**
   * The product which the subscription is for
   */
  product: string
  /**
   * Stripe event id from the webhook event
   */
  stripeEventId?: string
  /**
   * Unique identifier for the subscription
   */
  subscriptionId?: string
  [property: string]: any
}

/**
 * When was a new subscription schedule created for a subscription?
 */
export interface SubscriptionScheduleCreated {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * Unique identifier for the Stripe customer
   */
  customerId: string
  /**
   * The active organization of the user that took the action
   */
  organizationId: string
  /**
   * Stripe event id from the webhook event
   */
  stripeEventId: string
  /**
   * Unique identifier for trial/subscription (Stripe sub id)
   */
  subscriptionId: string
  /**
   * Unique identifier for the subscription schedule
   */
  subscriptionScheduleId: string
  [property: string]: any
}

/**
 * When was a new subscription schedule removed for a subscription?
 */
export interface SubscriptionScheduleRemoved {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * Unique identifier for the Stripe customer
   */
  customerId: string
  /**
   * The active organization of the user that took the action
   */
  organizationId: string
  /**
   * Reason for schedule removal (customer-initiated or automatic due to upgrade?)
   */
  reason: string
  /**
   * Stripe event id from the webhook event
   */
  stripeEventId: string
  /**
   * Unique identifier for trial/subscription (Stripe sub id)
   */
  subscriptionId: string
  /**
   * Unique identifier for the subscription schedule
   */
  subscriptionScheduleId: string
  [property: string]: any
}

/**
 * When was a new subscription schedule updated for a subscription?
 */
export interface SubscriptionScheduleUpdated {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * Unique identifier for the Stripe customer
   */
  customerId: string
  /**
   * When are the subscription changes set to take effect?
   */
  effectiveDate: string
  /**
   * At the next subscription schedule phase, what is the new billing cycle?
   */
  newBillingCycle: string
  /**
   * At the next subscription schedule phase, to what plan is the user moving?
   */
  newPlan: string
  /**
   * At the next subscription schedule phase, what is the new channel count?
   */
  newQuantity: number
  /**
   * The active organization of the user that took the action
   */
  organizationId: string
  /**
   * Stripe event id from the webhook event
   */
  stripeEventId: string
  /**
   * Unique identifier for trial/subscription (Stripe sub id)
   */
  subscriptionId: string
  /**
   * Unique identifier for the subscription schedule
   */
  subscriptionScheduleId: string
  [property: string]: any
}

/**
 * When did the paid subscription start?  This event is triggered when the first invoice
 * that is greater than $0 is paid for the given subscription.
 */
export interface SubscriptionStarted {
  /**
   * Cost of the plan after trial
   */
  amount?: number
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * At the time of this event, which channels did the user have connected?
   */
  channelsConnected?: any[]
  /**
   * The client in which the event occurred.
   */
  clientName?: string
  /**
   * Which call to action did the user click to start their paid subscription
   */
  cta?: null | string
  /**
   * Which product or website is the CTA located in? Examples would be either the product,
   * like `publish`, or the website, like `bufferSite`
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA?  In many cases it makes sense to describe the
   * intended result of the CTA, like `proTrial` or `publishPro`
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in
   * Publish app shell menu
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA?  If setting the value for the first time, set to
   * `1`
   */
  ctaVersion?: null | string
  /**
   * What website page or app view is the CTA located?  Examples would be, `composer` or
   * `analyticsOverview` for Publish, and `pricingPublish` for the Publish pricing page on the
   * Buffer marketing site
   */
  ctaView?: null | string
  /**
   * Unique identifier for the Stripe customer
   */
  customerId?: string
  /**
   * The subscription payment interval (month or year)
   */
  cycle?: string
  /**
   * What caused this event to be triggered?  If this event was triggered via normal user
   * behavior, the property value will be null.  Examples of values that might be populated in
   * this property are `system_migration` and `bug_fix_backfill`
   */
  eventTrigger?: null | string
  /**
   * What triggered this event? e.g. Stripe or RevenueCat
   */
  gateway?: string
  /**
   * Does the user have a tax ID?
   */
  hasTaxId?: boolean | null
  /**
   * Is automatic tax collection in Stripe enabled for this user?
   */
  isAutomaticTaxEnabled?: boolean | null
  /**
   * Was the subscription started via Stripe Checkout?
   */
  isStripeCheckout?: boolean | null
  /**
   * Is the user tax exempt?
   */
  isTaxExempt?: boolean | null
  /**
   * What was the name of the multi product bundle that this subscription is associated with?
   * For example, 'analyze_publish_oct2019'
   */
  multiProductBundleName?: null | string
  /**
   * The ID of the organization whose subscription was created.
   */
  organizationId?: string
  /**
   * Stripe plan id
   */
  planId?: string
  /**
   * Human-readable plan name
   */
  planName?: string
  /**
   * The product which the subscription is for
   */
  product: string
  /**
   * MRR Value (if monthly then amount, if yearly, divide yearly amount by 12)
   */
  revenue?: number
  /**
   * Stripe event id from the webhook event
   */
  stripeEventId?: string
  /**
   * Unique identifier for trial/subscription (Stripe sub id)
   */
  subscriptionId?: string
  /**
   * How much tax was the user charged?
   */
  taxAmount?: number | null
  /**
   * The name of the upgrade path
   */
  upgradePathName?: null | string
  /**
   * What was the specific campaign (e.g. product launch, new feature, partnership, etc.) that
   * resulted in a subscription start?
   */
  utmCampaign?: null | string
  /**
   * What was the specific content or content that the subscription start is attributed to.
   * For example a specific CTA or part of an email that is being A/B tested.
   */
  utmContent?: null | string
  /**
   * What is the medium of the link that was used to start a subscription? For example email,
   * CPC, or other method of sharing.
   */
  utmMedium?: null | string
  /**
   * What is the source of traffic that resulted in a subscription start? For example search
   * engine, newsletter, or other referral.
   */
  utmSource?: null | string
  /**
   * What is the paid search term or keyword used to drive user to the page on which the
   * subscription was started? For example DTC, Instagram scheduling.
   */
  utmTerm?: null | string
  /**
   * Was the customer charged tax?
   */
  wasChargedTax?: boolean | null
  [property: string]: any
}

/**
 * Understand when a user modifies the plan or billing quantity of a paid subscription that
 * results in a higher revenue amount or projected higher LTV
 */
export interface SubscriptionUpgraded {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * At the time of this event, which channels did the user have connected?
   */
  channelsConnected?: any[]
  /**
   * The client in which the event occurred.
   */
  clientName?: string
  /**
   * Which call to action did the user click to upgrade their paid subscription
   */
  cta?: null | string
  /**
   * Which product or website is the CTA located in? Examples would be either the product,
   * like `publish`, or the website, like `bufferSite`
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA?  In many cases it makes sense to describe the
   * intended result of the CTA, like `proTrial` or `publishPro`
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in
   * Publish app shell menu
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA?  If setting the value for the first time, set to
   * `1`
   */
  ctaVersion?: null | string
  /**
   * What website page or app view is the CTA located?  Examples would be, `composer` or
   * `analyticsOverview` for Publish, and `pricingPublish` for the Publish pricing page on the
   * Buffer marketing site
   */
  ctaView?: null | string
  /**
   * Unique identifier for the Stripe customer
   */
  customerId?: string
  /**
   * What was the payment interval for this subscription (month or year) after the upgrade
   * event?
   */
  cycle?: string
  /**
   * What triggered this event? e.g. Stripe or RevenueCat
   */
  gateway?: string
  /**
   * Does the user have a tax ID?
   */
  hasTaxId?: boolean | null
  /**
   * Is automatic tax collection in Stripe enabled for this user?
   */
  isAutomaticTaxEnabled?: boolean | null
  /**
   * Was this subscription upgrade event the result of a change only to the billing cycle
   * changing to a longer duration?  For example, this would be true if the subscription plan
   * changed from `Premium Business v1 - Monthly` to `Premium Business v1 - Yearly`.
   */
  isPlanCycleChangeOnly?: boolean
  /**
   * Was the subscription upgraded via Stripe Checkout?
   */
  isStripeCheckout?: boolean | null
  /**
   * Is the user tax exempt?
   */
  isTaxExempt?: boolean | null
  /**
   * What was the monthly recurring value of the subscription after the upgrade event? If the
   * billing cycle is yearly, this amount will be the annual amount divided by 12.
   */
  mrrValue?: number
  /**
   * The ID of the organization whose subscription was upgraded.
   */
  organizationId?: string
  /**
   * What is the unique identifier in Stripe of the billing plan for the subscription after
   * the upgrade event?
   */
  planId?: string
  /**
   * What was the human-readable plan name for the subscription after the upgrade event?
   */
  planName?: string
  /**
   * What was the user's previous billing state before making this change? (e.g. free, trial,
   * paid)
   */
  previousBillingState?: null | string
  /**
   * What was the payment interval for this subscription (month or year) before the upgrade
   * event?
   */
  previousCycle?: string
  /**
   * What was the monthly recurring value of the subscription before the upgrade event? If the
   * billing cycle is yearly, this amount will be the annual amount divided by 12.
   */
  previousMrrValue?: number
  /**
   * What is the unique identifier in Stripe of the billing plan for the subscription was
   * before the upgrade event?
   */
  previousPlanId?: string
  /**
   * What was the human-readable plan name for the subscription before the upgrade event?
   */
  previousPlanName?: string
  /**
   * What was the previous quantity of the subscription?
   */
  previousQuantity?: number | null
  /**
   * Which product was this subscription for?
   */
  product: string
  /**
   * What is the current quantity of the subscription?
   */
  quantity?: number | null
  /**
   * What is the difference in quantity between the current and previous quantity
   */
  quantityDiff?: number | null
  /**
   * What was the unique identifier for the upgrade event from the Stripe webhook?
   */
  stripeEventId: string
  /**
   * What was the unique identifier for the subscription in Stripe?
   */
  subscriptionId: string
  /**
   * How much tax was the user charged?
   */
  taxAmount?: number | null
  /**
   * The name of the upgrade path
   */
  upgradePathName?: null | string
  /**
   * Was the customer charged tax?
   */
  wasChargedTax?: boolean | null
  [property: string]: any
}

/**
 * A customer submitted a satisfaction rating for a support conversation
 */
export interface SupportSatisfactionRatingSubmitted {
  /**
   * The name of the advocate whose reply was rated
   */
  assigneeRated?: null | string
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The comment left by the customer
   */
  comment?: null | string
  /**
   * The ID associated with the specific Help Scout conversation
   */
  conversationId: string
  /**
   * A link to the specific Help Scout conversation
   */
  conversationUrl?: null | string
  /**
   * The ID of the mailbox the conversation was created in
   */
  mailboxId: string
  /**
   * The global organization ID of the user that viewed the page.
   */
  organizationId?: null | string
  /**
   * The rating left by the customer
   */
  rating?: null | string
  /**
   * The ID associated with the specific Help Scout rating
   */
  ratingId: string
  /**
   * The ID associated with the specific Help Scout thread
   */
  threadId?: null | string
  [property: string]: any
}

/**
 * A customer created a new support ticket
 */
export interface SupportTicketCreated {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The name of the advocate assigned to the ticket
   */
  ticketAssigneeName?: string
  /**
   * The name of the group assigned to the ticket, ex. "Customer Engagement"
   */
  ticketGroupName?: string
  /**
   * The Id associated with the specific Zendesk support conversation
   */
  ticketId: string
  /**
   * The urgency assigned to the ticket by the customer.
   */
  ticketPriority?: string
  /**
   * How the ticket was created, ex. "Web Widget", "Mail", "Ticket Form", ...
   */
  ticketSource?: string
  /**
   * The subject message of the ticket
   */
  ticketSubject?: string
  /**
   * A list of tags applied to the ticket
   */
  ticketTags?: any[]
  /**
   * A link to the the specific Zendesk support conversation
   */
  ticketUrl?: string
  [property: string]: any
}

/**
 * A Customer Advocate replied to a Support Ticket
 */
export interface SupportTicketReplySent {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The name of the advocate assigned to the ticket
   */
  ticketAssigneeName?: string
  /**
   * The name of the group assigned to the ticket, ex. "Customer Engagement"
   */
  ticketGroupName?: string
  /**
   * The Id associated with the specific Zendesk support conversation
   */
  ticketId: string
  /**
   * The comment a customer gave with their satisfaction rating
   */
  ticketSatisfactionComment?: string
  /**
   * The satisfaction rating a customer gave from their support experience
   */
  ticketSatisfactionRating?: string
  /**
   * How the ticket was created, ex. "Web Widget", "Mail", "Ticket Form", ...
   */
  ticketSource?: string
  /**
   * The current status of the ticket, ex. “open”, “pending”, “solved”
   */
  ticketStatus?: string
  /**
   * The subject message of the ticket
   */
  ticketSubject?: string
  /**
   * A list of tags applied to the ticket
   */
  ticketTags?: any[]
  /**
   * A link to the the specific Zendesk support conversation
   */
  ticketUrl?: string
  [property: string]: any
}

/**
 * A ticket was updated modifying some of its information
 */
export interface SupportTicketUpdated {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * The name of the advocate assigned to the ticket
   */
  ticketAssigneeName?: string
  /**
   * The name of the group assigned to the ticket, ex. "Customer Engagement"
   */
  ticketGroupName?: string
  /**
   * The Id associated with the specific Zendesk support conversation
   */
  ticketId: string
  /**
   * ​​The urgency assigned to the ticket by the customer.
   */
  ticketPriority?: string
  /**
   * The comment a customer gave with their statifaction rating
   */
  ticketSatisfactionComment?: string
  /**
   * The satisfaction rating a customer gave from their support experience
   */
  ticketSatisfactionRating?: string
  /**
   * How the ticket was created, ex. "Web Widget", "Mail", "Ticket Form", ...
   */
  ticketSource?: string
  /**
   * The current status of the ticket, ex. “open”, “pending”, “solved”
   */
  ticketStatus?: string
  /**
   * The subject message of the ticket
   */
  ticketSubject?: string
  /**
   * A list of tags applied to the ticket
   */
  ticketTags?: any[]
  /**
   * Was the ticket updated by an agent or a customer?
   */
  ticketUpdatedBy?: null | string
  /**
   * A link to the the specific Zendesk support conversation
   */
  ticketUrl?: string
  [property: string]: any
}

/**
 * When a new tag is created.
 */
export interface TagCreated {
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The global organization ID that the tag was created for.
   */
  organizationId: string
  /**
   * The product in which the action occurred
   */
  product: string
  /**
   * The ID of the Publish organization that the tag was created for.
   */
  publishOrganizationId?: string
  /**
   * The color selected for the tag.
   */
  tagColor: string
  /**
   * The ID of the new tag.
   */
  tagId: string
  /**
   * The name given to the tag.
   */
  tagName: string
  [property: string]: any
}

/**
 * When a tag is deleted.
 */
export interface TagDeleted {
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The global organization ID that the tag was created for.
   */
  organizationId: string
  /**
   * The product in which the action occurred.
   */
  product: string
  /**
   * The ID of the Publish organization that the tag was created for.
   */
  publishOrganizationId?: string
  /**
   * The color selected for the tag.
   */
  tagColor: string
  /**
   * The unique ID assigned to the tag.
   */
  tagId: string
  /**
   * The name given to the tag.
   */
  tagName: string
  [property: string]: any
}

/**
 * When a tag is edited.
 */
export interface TagEdited {
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The global organization ID that the tag was created for.
   */
  organizationId: string
  /**
   * The product in which the action occurred
   */
  product: string
  /**
   * The ID of the Publish organization that the tag was created for.
   */
  publishOrganizationId?: string
  /**
   * The color selected for the tag.
   */
  tagColor: string
  /**
   * The ID of the tag.
   */
  tagId: string
  /**
   * The name given to the tag.
   */
  tagName: string
  [property: string]: any
}

/**
 * When a new item is added to an existing tag.
 */
export interface TagItemAdded {
  /**
   * Where was the item added to the tag from? Ex. Queue, tags
   */
  addedFrom?: string
  /**
   * The social network for which the item was created, ex. instagram.
   */
  channel?: string
  /**
   * The ID of the channel for which the item was created.
   */
  channelId?: string
  /**
   * The type of channel the item was created for, ex. group, page, business.
   */
  channelType?: string
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * If the item added to the tag is a draft
   */
  isDraft?: boolean
  /**
   * If is a sent post
   */
  isSent?: boolean
  /**
   * The id of the post or idea that was added to the tag.
   */
  itemId: string
  /**
   * The type of the item that was added to the tag, ex. post or idea.
   */
  itemType: string
  /**
   * The global organization ID that the tag was created for.
   */
  organizationId: string
  /**
   * The product in which the action occurred.
   */
  product: string
  /**
   * The ID of the Publish organization that the tag was created for.
   */
  publishOrganizationId?: string
  /**
   * The ID of the tag.
   */
  tagId: string
  /**
   * The name given to the tag.
   */
  tagName: string
  [property: string]: any
}

/**
 * When an item is removed from an existing tag.
 */
export interface TagItemRemoved {
  /**
   * The social network for which the item was created, ex. instagram.
   */
  channel?: string
  /**
   * The ID of the channel for which the item was created.
   */
  channelId?: string
  /**
   * The type of channel the item was created for, ex. group, page, business.
   */
  channelType?: string
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * If the item removed from the tag is a draft
   */
  isDraft?: boolean
  /**
   * If is a sent post
   */
  isSent?: boolean
  /**
   * The id of the post or idea that was removed from the tag.
   */
  itemId: string
  /**
   * The type of the item that was removed from the tag, ex. post or idea.
   */
  itemType: string
  /**
   * The global organization ID that the tag was created for.
   */
  organizationId: string
  /**
   * The product in which the action occurred.
   */
  product: string
  /**
   * The ID of the Publish organization that the tag was created for.
   */
  publishOrganizationId?: string
  /**
   * Where was the item removed from? Ex. Queue, tags
   */
  removedFrom?: string
  /**
   * The ID of the tag.
   */
  tagId: string
  /**
   * The name given to the tag.
   */
  tagName: string
  [property: string]: any
}

/**
 * When a report is viewed for an existing tag.
 */
export interface TagReportViewed {
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The ID of the global organization that the tag was created for.
   */
  organizationId: string
  /**
   * The product in which the action occurred
   */
  product: string
  /**
   * The ID of the Publish organization that the tag was created for.
   */
  publishOrganizationId?: string
  /**
   * The ID of the tag.
   */
  tagId: string
  /**
   * The name given to the tag.
   */
  tagName: string
  [property: string]: any
}

/**
 * When was a tax exemption for a customer updated
 */
export interface TaxExemptUpdated {
  /**
   * Unique identifier for the Stripe customer
   */
  customerId: string
  /**
   * The ID of the organization for which the tax exempt was updated
   */
  organizationId: string
  /**
   * The previous tax exempt status of the customer
   */
  previousTaxExempt: string
  /**
   * Stripe event id from the webhook event
   */
  stripeEventId: string
  /**
   * The tax exempt status of the customer
   */
  taxExempt: string
  [property: string]: any
}

/**
 * When was a tax ID added for a customer
 */
export interface TaxIDCreated {
  /**
   * The country of the tax ID
   */
  country: string
  /**
   * Unique identifier for the Stripe customer
   */
  customerId: string
  /**
   * The ID of the organization for which the tax ID was created
   */
  organizationId: string
  /**
   * The verification status of the tax ID
   */
  status: string
  /**
   * Stripe event id from the webhook event
   */
  stripeEventId: string
  /**
   * The tax ID
   */
  taxId?: null | string
  /**
   * The type of tax ID
   */
  taxType: string
  [property: string]: any
}

/**
 * When was a tax ID deleted for a customer
 */
export interface TaxIDDeleted {
  /**
   * The country of the tax ID
   */
  country: string
  /**
   * Unique identifier for the Stripe customer
   */
  customerId: string
  /**
   * The ID of the organization for which the tax ID was deleted
   */
  organizationId: string
  /**
   * The verification status of the tax ID
   */
  status: string
  /**
   * Stripe event id from the webhook event
   */
  stripeEventId: string
  /**
   * The tax ID
   */
  taxId?: null | string
  /**
   * The type of tax ID
   */
  taxType: string
  [property: string]: any
}

/**
 * When was a tax ID updated for a customer
 */
export interface TaxIDUpdated {
  /**
   * The country of the tax ID
   */
  country: string
  /**
   * Unique identifier for the Stripe customer
   */
  customerId: string
  /**
   * The ID of the organization for which the tax ID was updated
   */
  organizationId: string
  /**
   * The verification status of the tax ID
   */
  status: string
  /**
   * Stripe event id from the webhook event
   */
  stripeEventId: string
  /**
   * The tax ID
   */
  taxId?: null | string
  /**
   * The type of tax ID
   */
  taxType: string
  [property: string]: any
}

/**
 * When was the team member added to the organization?
 */
export interface TeamMemberAdded {
  /**
   * The id of the Global organization that the team member was added to.
   */
  organizationId: string
  /**
   * Which plan was the organization's owner on at time of adding the team member?
   */
  organizationOwnerPlanId?: string
  /**
   * What was the Buffer user id of the organization's owner for the organization this team
   * member was added to?
   */
  organizationOwnerUserId?: null | string
  /**
   * Which plan was the organization subscribed to?
   */
  organizationPlanId?: null | string
  /**
   * Which product was the team member added to for the organization?
   */
  product?: string
  /**
   * What is the email address of the team member added to the organization?
   */
  teamMemberEmail?: string
  /**
   * Which plan was the team member on at time of being added to the organization?
   */
  teamMemberPlanId?: null | string
  /**
   * Which organization role was the team member given at the time of being added?
   */
  teamMemberRole?: string
  /**
   * What was the Buffer user id of the team member added to the organization?
   */
  teamMemberUserId?: string
  [property: string]: any
}

/**
 * This event is triggered when the user clicks the link in the invite email.
 */
export interface TeamMemberInviteLinkClicked {
  /**
   * The id of the Global organization that the team member was invited to.
   */
  organizationId: string
  /**
   * Which plan was the organization's owner on at time the invite link was clicked?
   */
  organizationOwnerPlanId?: string
  /**
   * What was the Buffer user id of the organization's owner for the organization this team
   * member was invited to?
   */
  organizationOwnerUserId?: null | string
  /**
   * Which plan was the organization subscribed to when the team member was invited?
   */
  organizationPlanId?: null | string
  /**
   * Which product was the team member invited to?
   */
  product?: string
  /**
   * What is the email address of the team member added to the organization?
   */
  teamMemberEmail?: string
  /**
   * Which plan was the team member on at time of clicking the invite link?
   */
  teamMemberPlanId?: null | string
  /**
   * Which organization role was the team member given when invited?
   */
  teamMemberRole?: string
  /**
   * What was the Buffer user id of the team member that was invited?
   */
  teamMemberUserId?: string
  [property: string]: any
}

/**
 * When was the team member invited to join the organization?
 */
export interface TeamMemberInvited {
  /**
   * Does the user being invited already have channels connected?
   */
  hasChannels?: boolean
  /**
   * A user is new if there isn't already an account associated with the email.
   */
  isNewUser?: boolean
  /**
   * Is the user being invited to be an admin of the organization?
   */
  isUserAdmin?: boolean
  /**
   * The link a new user uses to set their Buffer password
   */
  newUserPasswordResetLink?: string
  /**
   * The id of the Global organization that the team member was added to.
   */
  organizationId: string
  /**
   * The name of the organization that the user is being invited to
   */
  organizationName?: string
  /**
   * The email address of the organization owner.
   */
  organizationOwnerEmail?: string
  /**
   * Which plan was the organization on at time of inviting the team member?
   */
  organizationPlanId?: string
  /**
   * Which product was the team member added to for the organization?
   */
  product?: string
  /**
   * What was the Buffer global account id of the team member added to the organization?
   */
  teamMemberAccountId?: string
  /**
   * What is the email address of the team member invited to the organization?
   */
  teamMemberEmail?: string
  /**
   * Which plan was the team member on at time of being invited to the organization?
   */
  teamMemberPlanId?: null | string
  [property: string]: any
}

/**
 * When was the team member removed from the organization?
 */
export interface TeamMemberRemoved {
  /**
   * The id of the Global organization that the team member was removed from.
   */
  organizationId?: string
  /**
   * Which plan was the organization's owner on at time of removing the team member?
   */
  organizationOwnerPlanId?: string
  /**
   * What was the Buffer user id of the organization's owner for the organization this team
   * member was removed from?
   */
  organizationOwnerUserId?: null | string
  /**
   * Which plan was the organization subscribed to at the time the event was triggered?
   */
  organizationPlanId?: null | string
  /**
   * Which product was the team member removed from for the organization?
   */
  product?: string
  /**
   * What is the email address of the team member removed from the organization?
   */
  teamMemberEmail?: string
  /**
   * Which plan was the team member on at time of being removed from the organization?
   */
  teamMemberPlanId?: null | string
  /**
   * Which organization role was the team member given at the time of being added?
   */
  teamMemberRole?: string
  /**
   * What was the Buffer user id of the team member removed from the organization?
   */
  teamMemberUserId?: string
  properties: any
  [property: string]: any
}

/**
 * When did the user select the image used within a template?
 */
export interface TemplateImageSelected {
  /**
   * The client in which the action occurred.
   */
  clientName: string
  /**
   * The source in which the image was selected.
   */
  imageSource?: string
  /**
   * The product in which the event occurred, ex. “remix”.
   */
  product: string
  [property: string]: any
}

/**
 * This event is only for use while migrating the tracking plan - it acts as a place to test
 * gh workflows based on event changes without needing to modify any active events.
 */
export interface TestEventDoNotUse {
  /**
   * A test id
   */
  anotherId?: number
  /**
   * A test id
   */
  anotherId2?: number
  /**
   * A test id with an updated description
   */
  anotherId3: number
  /**
   * A test id
   */
  anotherId5: number
  /**
   * A test id
   */
  anotherId6: number
  /**
   * A test id
   */
  anotherId7: number
  /**
   * A test id
   */
  anotherId8: number
  /**
   * A test id with an updated description
   */
  anotherId9: number
  /**
   * The organization ID of the user that completed the action
   */
  organizationId: string
  /**
   * A test id
   */
  someId?: string
  [property: string]: any
}

/**
 * This event is only for use while migrating the tracking plan - it acts as a place to test
 * gh workflows based on event changes without needing to modify any active events.
 */
export interface TestEventNumberTwoDoNotUse {
  /**
   * Another test field
   */
  anotherField: string
  /**
   * Another test field
   */
  anotherField2: string
  /**
   * Another test field
   */
  anotherField4: string
  /**
   * Another test field
   */
  anotherField5: string
  /**
   * Another test field
   */
  anotherField6?: string
  /**
   * Another test field
   */
  anotherField7?: string
  /**
   * Another test field that is now required
   */
  anotherField8: string
  /**
   * Another test field that is now required
   */
  anotherField9: string
  /**
   * The organization ID of the user that completed the action
   */
  organizationId: string
  /**
   * The organization ID of the user that completed the action
   */
  organizationId2: string
  [property: string]: any
}

/**
 * When a user clicks 'Add Thread' to start a new Thread post
 */
export interface ThreadsAddThreadClicked {
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product: string
  [property: string]: any
}

/**
 * When a user clicks 'Add Tweet' to add more Tweets to the Thread
 */
export interface ThreadsAddTweetClicked {
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product: string
  [property: string]: any
}

/**
 * When a user attempts to edit a thread on mobile
 */
export interface ThreadsEditAttempted {
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product: string
  [property: string]: any
}

/**
 * When was tiered pricing disabled for the user?
 */
export interface TieredPricingDisabled {
  /**
   * The global organization ID of the user.
   */
  organizationId: null | string
  [property: string]: any
}

/**
 * When was tiered pricing enabled for the user?
 */
export interface TieredPricingEnabled {
  /**
   * The global organization ID of the user.
   */
  organizationId: null | string
  [property: string]: any
}

/**
 * When did the user exit the app tour?
 */
export interface TourExited {
  /**
   * What was the name of the client the user was in when they opened the Story in the native
   * channel?
   */
  clientName: string
  /**
   * The global organization id that the channel belongs to.
   */
  organizationId?: string
  /**
   * In which product did this event occur?
   */
  product: string
  [property: string]: any
}

/**
 * When did the user open the app tour?
 */
export interface TourOpened {
  /**
   * What was the name of the client the user was in when they opened the Story in the native
   * channel?
   */
  clientName: string
  /**
   * The global organization id that the channel belongs to.
   */
  organizationId?: string
  /**
   * Where did the user open the tour from?
   */
  placement: string
  /**
   * In which product did this event occur?
   */
  product: string
  [property: string]: any
}

/**
 * When did the user view this specific screen? This event is triggered when a user opens a
 * new screen in the app tour that is considered a distinct screen.
 */
export interface TourScreenViewed {
  /**
   * What was the name of the client the user was in when they opened the Story in the native
   * channel?
   */
  clientName: string
  /**
   * The global organization id that the channel belongs to.
   */
  organizationId?: string
  /**
   * In which product did this event occur?
   */
  product: string
  /**
   * What was the name of the screen that was viewed?
   */
  screenName: string
  [property: string]: any
}

/**
 * When the number of channels associated with the trial changes. This event will trigger
 * each time a user adds or removes a channel during a trial, but it is possible for
 * multiple channel adds to be represented by one event as a result of a bulk add UI option.
 */
export interface TrialChannelQuantityChanged {
  /**
   * The absolute dollar amount per month that this channel change is worth. If the cost per
   * channel per month is $10, and the user removed a channel, this value would be `10`. If
   * the channel count went from 1 to 4 (as a result of a bulk add) and the plan being trialed
   * is for $5 per channel, this value would be `15`.
   */
  changeValue?: number
  /**
   * Count of channels associated with this trial after this change was made.
   */
  channelQuantity?: number
  /**
   * Unique identifier for the billing gateway customer.
   */
  customerId?: string
  /**
   * What triggered this event? e.g. Stripe or RevenueCat
   */
  gateway?: string
  /**
   * The ID of the organization whose plan changed.
   */
  organizationId: string
  /**
   * Billing gateway plan id.
   */
  planId?: string
  /**
   * Human-readable plan name.
   */
  planName?: string
  /**
   * Count of channels associated with this trial before this change was made.
   */
  previousChannelQuantity?: number
  /**
   * The product which the trial is for.
   */
  product?: string
  /**
   * The type of expected impact to Buffer MRR as a result of this channel quantity change,
   * should this trial eventually convert to a paid subscription.
   */
  revenueEffect?: string
  /**
   * Stripe event id from the webhook event.
   */
  stripeEventId?: string
  /**
   * Unique identifier for the trial.
   */
  subscriptionId?: string
  [property: string]: any
}

/**
 * When did the user's trial end for this product and plan?  This event is triggered when
 * the trial end timestamp is reached; it is not user initiated.
 */
export interface TrialEnded {
  /**
   * Cost of the plan after trial
   */
  amount?: number
  /**
   * true || false
   */
  cancelled?: boolean
  /**
   * The client in which the event occurred.
   */
  clientName?: null | string
  /**
   * Unique identifier for the Stripe customer
   */
  customerId?: string
  /**
   * The subscription payment interval (month or year)
   */
  cycle?: string
  /**
   * What caused this event to be triggered?  Examples of values that might be populated in
   * this property are `userCanceled`, `bufferCanceled`, `completedDuration`,
   * `convertedEarly`, `startedNewTrial`, `userDeletedAccount`, `bufferDeletedAccount`, and
   * `systemBackfill`
   */
  eventTrigger?: null | string
  /**
   * What triggered this event? e.g. Stripe or RevenueCat
   */
  gateway?: string
  /**
   * What was the name of the multi product bundle that this trial is associated with? For
   * example, 'analyze_publish_oct2019'
   */
  multiProductBundleName?: null | string
  /**
   * The ID of the organization whose trial ended.
   */
  organizationId?: string
  /**
   * Stripe plan id
   */
  planId?: string
  /**
   * Human-readable plan name
   */
  planName?: string
  /**
   * The product which the trial is for
   */
  product: string
  /**
   * Stripe event id from the webhook event
   */
  stripeEventId?: string
  /**
   * Unique identifier for trial/subscription (Stripe sub id)
   */
  subscriptionId?: string
  /**
   * End date of the trial (ISO-8601)
   */
  trialEndDate?: string
  /**
   * Start date of the trial (ISO-8601)
   */
  trialStartDate?: string
  [property: string]: any
}

/**
 * When did the user click the Trial Expired Modal main cta?
 */
export interface TrialExpiredModalMainCtaClicked {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * Which client was the user in when they opened this modal?
   */
  clientName: null | string
  /**
   * The name of the CTA clicked.
   */
  cta: null | string
  /**
   * Is the user at their plan limit?
   */
  isAtPlanLimit?: boolean | null
  /**
   * The global organization ID of the user that clicked the Trial Expired Modal main cta.
   */
  organizationId: null | string
  /**
   * Which product did the user click the main cta in?
   */
  product: null | string
  /**
   * The name of the upgrade path
   */
  upgradePathName: null | string
  [property: string]: any
}

/**
 * When did the user view the Trial Expired modal?
 */
export interface TrialExpiredModalViewed {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * Which client was the user in when they opened this modal?
   */
  clientName: null | string
  /**
   * The name of the CTA clicked.
   */
  cta: null | string
  /**
   * Is the user at their plan limit?
   */
  isAtPlanLimit?: boolean | null
  /**
   * The global organization ID of the user that viewed the Trial Expired modal.
   */
  organizationId: null | string
  /**
   * Which product did the user view the modal in?
   */
  product: null | string
  /**
   * The name of the upgrade path
   */
  upgradePathName: null | string
  [property: string]: any
}

/**
 * When was this trial extended beyond the default trial duration?  This event is typically
 * triggered a Buffer customer advocate giving additional trial duration to a user; it is
 * not user initiated.
 */
export interface TrialExtended {
  /**
   * Cost of the plan after trial
   */
  amount?: number
  /**
   * Unique identifier for the Stripe customer
   */
  customerId?: string
  /**
   * The subscription payment interval (month or year)
   */
  cycle?: string
  /**
   * New end date of the trial (ISO-8601)
   */
  extendedTrialEndDate?: string
  /**
   * What triggered this event? e.g. Stripe or RevenueCat
   */
  gateway?: string
  /**
   * The ID of the organization whose trial ended.
   */
  organizationId: string
  /**
   * Stripe plan id
   */
  planId?: string
  /**
   * Human-readable plan name
   */
  planName?: string
  /**
   * The product which the trial is for
   */
  product?: string
  /**
   * Stripe event id from the webhook event
   */
  stripeEventId?: string
  /**
   * Unique identifier for trial/subscription (Stripe sub id)
   */
  subscriptionId?: string
  [property: string]: any
}

/**
 * When the plan associated with the trial changes. This will trigger when a trial is
 * already in progress for the user but has not yet reached the trial duration end, and a
 * trial for a different Buffer plan is initiated.
 */
export interface TrialPlanChanged {
  /**
   * Which call to action did the user click to start their trial?
   */
  cta?: null | string
  /**
   * Which product or website is the CTA located in? Examples would be either the product,
   * like `publish`, or the website, like `bufferSite`
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA?  In many cases it makes sense to describe the
   * intended result of the CTA, like `proTrial` or `publishPro`
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in
   * Publish app shell menu
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA?  If setting the value for the first time, set to
   * `1`
   */
  ctaVersion?: null | string
  /**
   * What website page or app view is the CTA located?  Examples would be, `composer` or
   * `analyticsOverview` for Publish, and `pricingPublish` for the Publish pricing page on the
   * Buffer marketing site
   */
  ctaView?: null | string
  /**
   * Unique identifier for the billing gateway customer
   */
  customerId?: string
  /**
   * The billing interval (month or year) of the plan being trialed
   */
  cycle?: string
  /**
   * What triggered this event? e.g. Stripe or RevenueCat
   */
  gateway?: string
  /**
   * The ID of the organization whose plan changed.
   */
  organizationId: string
  /**
   * Billing gateway plan id
   */
  planId?: string
  /**
   * Human-readable plan name
   */
  planName?: string
  /**
   * The billing interval (month or year) of the previous plan
   */
  previousCycle?: string
  /**
   * Billing gateway plan id of the previous plan, if exists
   */
  previousPlanId?: string
  /**
   * Human-readable plan name of the previous plan, if exists
   */
  previousPlanName?: string
  /**
   * The product which the trial is for
   */
  product?: string
  /**
   * Stripe event id from the webhook event
   */
  stripeEventId?: string
  /**
   * Unique identifier for the trial
   */
  subscriptionId?: string
  [property: string]: any
}

/**
 * When did the user view the Trial Signup Welcome modal?
 */
export interface TrialSignupWelcomeModalViewed {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * Which client was the user in when they opened this modal?
   */
  clientName: string
  /**
   * Is the user at their plan limit?
   */
  isAtPlanLimit?: boolean | null
  /**
   * The global organization ID of the user that viewed the Trial Signup Welcome modal.
   */
  organizationId: null | string
  [property: string]: any
}

/**
 * When did the user start the trial for this product and specific plan?  This event is
 * triggered when the user completes the trial start flow, or if when they complete a signup
 * flow that has a trial included.
 */
export interface TrialStarted {
  /**
   * Cost of the plan after trial
   */
  amount?: number
  /**
   * The client in which the event occurred.
   */
  clientName?: null | string
  /**
   * Which call to action did the user click to start their trial
   */
  cta?: null | string
  /**
   * Which product or website is the CTA located in? Examples would be either the product,
   * like `publish`, or the website, like `bufferSite`
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA?  In many cases it makes sense to describe the
   * intended result of the CTA, like `proTrial` or `publishPro`
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in
   * Publish app shell menu
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA?  If setting the value for the first time, set to
   * `1`
   */
  ctaVersion?: null | string
  /**
   * What website page or app view is the CTA located?  Examples would be, `composer` or
   * `analyticsOverview` for Publish, and `pricingPublish` for the Publish pricing page on the
   * Buffer marketing site
   */
  ctaView?: null | string
  /**
   * Unique identifier for the Stripe customer
   */
  customerId?: string
  /**
   * The subscription payment interval (month or year)
   */
  cycle?: string
  /**
   * What caused this event to be triggered?  If this event was triggered via normal user
   * behavior, the property value will be null.  Examples of values that might be populated in
   * this property are `system_migration` and `bug_fix_backfill`
   */
  eventTrigger?: null | string
  /**
   * What triggered this event? e.g. Stripe or RevenueCat
   */
  gateway?: string
  /**
   * What was the name of the multi product bundle that this trial is associated with? For
   * example, 'analyze_publish_oct2019'
   */
  multiProductBundleName?: null | string
  /**
   * The ID of the organization whose trial started.
   */
  organizationId?: string
  /**
   * Stripe plan id
   */
  planId?: string
  /**
   * Human-readable plan name
   */
  planName?: string
  /**
   * The product which the trial is for
   */
  product: string
  /**
   * MRR Value (if monthly then amount, if yearly, divide yearly amount by 12)
   */
  revenue?: number
  /**
   * Stripe event id from the webhook event
   */
  stripeEventId?: string
  /**
   * Unique identifier for trial/subscription (Stripe sub id)
   */
  subscriptionId?: string
  /**
   * Was the trial started during the product signup?
   */
  trialAtSignup?: boolean
  /**
   * End date of the trial (ISO-8601)
   */
  trialEndDate?: string
  /**
   * Start date of the trial (ISO-8601)
   */
  trialStartDate?: string
  /**
   * The name of the upgrade path
   */
  upgradePathName?: null | string
  /**
   * What was the specific campaign (e.g. product launch, new feature, partnership, etc.) that
   * resulted in a trial start?
   */
  utmCampaign?: null | string
  /**
   * What was the specific content or content that the trial start is attributed to. For
   * example a specific CTA or part of an email that is being A/B tested.
   */
  utmContent?: null | string
  /**
   * What is the medium of the link that was used to start a trial? For example email, CPC, or
   * other method of sharing.
   */
  utmMedium?: null | string
  /**
   * What is the source of traffic that resulted in a trial start? For example search engine,
   * newsletter, or other referral.
   */
  utmSource?: null | string
  /**
   * What is the paid search term or keyword used to drive the trial start? For example DTC,
   * Instagram scheduling.
   */
  utmTerm?: null | string
  [property: string]: any
}

/**
 * When did the user view the Trial Welcome modal?
 */
export interface TrialWelcomeModalViewed {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * Which client was the user in when they opened this modal?
   */
  clientName: null | string
  /**
   * The name of the CTA clicked.
   */
  cta?: null | string
  /**
   * Is the user at their plan limit?
   */
  isAtPlanLimit?: boolean | null
  /**
   * The global organization ID of the user that viewed the Trial Welcome modal.
   */
  organizationId: null | string
  /**
   * Which product did the user view the modal in?
   */
  product: null | string
  /**
   * The name of the upgrade path
   */
  upgradePathName: null | string
  [property: string]: any
}

/**
 * When did the user tap a button to insert a links Twitter author into the update text.
 */
export interface TwitterAuthorCreditInserted {
  /**
   * What was the unique identifier of the client the user was in when they updated the story?
   */
  clientId?: null | string
  /**
   * Which client was the user in when they copied the story note? IE, `publishIos`,
   * `publishWeb`, `publishAndroid`.
   */
  clientName: string
  /**
   * The organization ID of the user that copied the story note.
   */
  organizationId?: string
  /**
   * The product for which the story was created
   */
  product: string
  [property: string]: any
}

/**
 * When a user updates their two factor authentication backup.
 */
export interface TwoFactorAuthenticationBackupUpdated {
  /**
   * The name of the client that the event occurred in
   */
  clientName: string
  /**
   * The type of two factor authentication that was enabled
   */
  method?: string
  /**
   * The organization ID of the user that completed the action
   */
  organizationId: string
  /**
   * Which product did the user complete this action in? (e.g. core)
   */
  product: string
  [property: string]: any
}

/**
 * When a user disables two factor authentication
 */
export interface TwoFactorAuthenticationDisabled {
  /**
   * The name of the client that the event occurred in
   */
  clientName: string
  /**
   * The organization ID of the user that completed the action
   */
  organizationId: string
  /**
   * Which product did the user complete this action in? (e.g. core)
   */
  product: string
  [property: string]: any
}

/**
 * When a user enabled two factor authentication
 */
export interface TwoFactorAuthenticationEnabled {
  /**
   * The name of the client that the event occurred in
   */
  clientName: string
  /**
   * The type of two factor authentication that was enabled
   */
  method?: string
  /**
   * The organization ID of the user that completed the action
   */
  organizationId: string
  /**
   * Which product did the user complete this action in? (e.g. core)
   */
  product: string
  [property: string]: any
}

/**
 * When did the user open the image editor in the remix app?
 */
export interface URLPasted {
  /**
   * The client in which the action occurred.
   */
  clientName: string
  /**
   * If the url scraped contains ratings
   */
  hasRatings?: boolean
  /**
   * The product in which the event occurred, ex. “remix”.
   */
  product: string
  /**
   * What was the text contained in the URL that is being shared?
   */
  text?: string
  /**
   * What was the url that was pasted?
   */
  url: string
  /**
   * What url type was pasted? ex. "product", "link" or "tweet" etc...
   */
  urlType: string
  [property: string]: any
}

/**
 * When a visitor clicks on the copy button within the UTM Generator.
 */
export interface UTMGeneratorCopyButtonClicked {
  /**
   * What was the specific campaign (e.g. product launch, new feature, partnership, etc.) that
   * resulted in this video play?
   */
  utmCampaign?: null | string
  /**
   * What was the specific content or content that this video play is attributed to. For
   * example a specific CTA or part of an email that is being A/B tested.
   */
  utmContent?: null | string
  /**
   * What is the medium of the link that lead to this video play? For example email, CPC, or
   * other method of sharing.
   */
  utmMedium?: null | string
  /**
   * What is the source of traffic that resulted in this video play? For example search
   * engine, newsletter, or other referral.
   */
  utmSource: null | string
  /**
   * What is the paid search term or keyword used to drive this video play? For example DTC,
   * Instagram scheduling.
   */
  utmTerm?: null | string
  [property: string]: any
}

/**
 * When an unsupported channel type combination is selected in the composer.
 */
export interface UnsupportedChannelSelectionAttempt {
  /**
   * The channel service that triggered the unsupported selection event
   */
  channel: string
  /**
   * The ids of the selected channels for the network that has triggered the unsupported
   * event, including the channel that was attempted for selection
   */
  channelIds: any[]
  /**
   * The channel types of the selected channels for the network that has triggered the
   * unsupported event, including the channel that was attempted for selection
   */
  channelTypes: any[]
  /**
   * Which client was the user in when they took this action?
   */
  clientName: string
  /**
   * The global organization id of the user that took this action
   */
  organizationId: string
  /**
   * The product in which the event occurred.
   */
  product: string
  [property: string]: any
}

/**
 * When a user clicks on a prompt to upgrade that leads to a plan selector.
 */
export interface UpgradePathViewed {
  /**
   * At time of event, what was the user's billing cycle, if applicable?
   */
  atEventBillingCycle?: null | string
  /**
   * At time of event, what was the user's billing gateway, if applicable?
   */
  atEventBillingGateway?: null | string
  /**
   * At time of event, what was the user's plan?
   */
  atEventBillingPlan?: null | string
  /**
   * At time of event, what was the user's billing status (e.g. free/trial/paid)?
   */
  atEventBillingState?: null | string
  /**
   * At time of event, how many channels is the user paying for?
   */
  atEventChannelQty?: number | null
  /**
   * At time of event, was the user on New Buffer?
   */
  atEventIsNewBuffer?: boolean | null
  /**
   * Which client was the user in when the Post was initially created?
   */
  clientName?: string
  /**
   * The organization ID of the user that viewed the upgrade path.
   */
  organizationId: string
  /**
   * Which product did the user view the upgrade path in?
   */
  product: string
  /**
   * The name of the upgrade path
   */
  upgradePathName: string
  [property: string]: any
}

/**
 * When the user is presented with our plan upgrade view within the mobile apps.
 */
export interface UpgradePlanOptionsScreenViewed {
  /**
   * The channel's service, ex. "facebook".
   */
  channel?: string
  /**
   * The database id of the channel for which the story was created.
   */
  channelId?: string
  /**
   * The service's own id for this channel.
   */
  channelServiceId?: string
  /**
   * The type of channel ex. "page", "group".
   */
  channelType?: string
  /**
   * The mobile client the user is on, `publishIos` or `publishAndroid`.
   */
  clientName: string
  /**
   * The organization ID of the user that viewed the plan options.
   */
  organizationId?: string
  /**
   * The plan we proposed within the upgrade view.
   */
  plan: string
  /**
   * The action the user took to produce the upgrade view, such as "profile_limit".
   */
  prompt?: null | string
  [property: string]: any
}

/**
 * When a user tries to upload a file.
 */
export interface UploadAttempted {
  /**
   * The name of the client that the event occurred in.
   */
  clientName: string
  /**
   * The global organization ID of the user that viewed the product solutions.
   */
  organizationId: string
  /**
   * The product in which this action occurred.
   */
  product: string
  /**
   * The source of the upload.
   */
  source: string
  /**
   * The trigger that caused the upload to start.
   */
  trigger: string
  /**
   * The ID of the uploader that was used to upload the file.
   */
  uploaderId: string
  /**
   * The extension of the file that was uploaded.
   */
  uploadExtension?: string
  /**
   * The media type of the file that was uploaded.
   */
  uploadMediaType?: string
  /**
   * The size of the file that was uploaded.
   */
  uploadSize?: number
  /**
   * Internal ID of the file upload attempt. Used only to link tracking events.
   */
  uploadTrackingId: string
  [property: string]: any
}

/**
 * When an upload is completed successfully.
 */
export interface UploadCompleted {
  /**
   * The name of the client that the event occurred in.
   */
  clientName: string
  /**
   * The global organization ID of the user that viewed the product solutions.
   */
  organizationId: string
  /**
   * The product in which this action occurred.
   */
  product: string
  /**
   * The source of the upload.
   */
  source: string
  /**
   * The trigger that caused the upload to start.
   */
  trigger: string
  /**
   * For video files only, the duration in milliseconds.
   */
  uploadDuration?: number
  /**
   * The ID of the uploader that was used to upload the file.
   */
  uploaderId: string
  /**
   * The extension of the file that was uploaded.
   */
  uploadExtension?: string
  /**
   * Dopcument ID in the uploads collection.
   */
  uploadId: string
  /**
   * The media type of the file that was uploaded.
   */
  uploadMediaType?: string
  /**
   * The size of the file that was uploaded.
   */
  uploadSize?: number
  /**
   * Internal ID of the file upload attempt. Used only to link tracking events.
   */
  uploadTrackingId?: string
  [property: string]: any
}

/**
 * When an upload is fails.
 */
export interface UploadFailed {
  /**
   * The name of the client that the event occurred in.
   */
  clientName: string
  /**
   * The global organization ID of the user that viewed the product solutions.
   */
  organizationId: string
  /**
   * The product in which this action occurred.
   */
  product: string
  /**
   * The source of the upload.
   */
  source: string
  /**
   * The trigger that caused the upload to start.
   */
  trigger: string
  /**
   * The ID of the uploader that was used to upload the file.
   */
  uploaderId: string
  /**
   * The error that caused the upload to fail.
   */
  uploadError: string
  /**
   * The extension of the file that was uploaded.
   */
  uploadExtension?: string
  /**
   * The media type of the file that was uploaded.
   */
  uploadMediaType?: string
  /**
   * The size of the file that was uploaded.
   */
  uploadSize?: number
  /**
   * Internal ID of the file upload attempt. Used only to link tracking events.
   */
  uploadTrackingId: string
  [property: string]: any
}

/**
 * When an attempted upload passes validation and start the upload.
 */
export interface UploadStarted {
  /**
   * The name of the client that the event occurred in.
   */
  clientName: string
  /**
   * The global organization ID of the user that viewed the product solutions.
   */
  organizationId: string
  /**
   * The product in which this action occurred.
   */
  product: string
  /**
   * The source of the upload.
   */
  source: string
  /**
   * The trigger that caused the upload to start.
   */
  trigger: string
  /**
   * The ID of the uploader that was used to upload the file.
   */
  uploaderId: string
  /**
   * The extension of the file that was uploaded.
   */
  uploadExtension?: string
  /**
   * The media type of the file that was uploaded.
   */
  uploadMediaType?: string
  /**
   * The size of the file that was uploaded.
   */
  uploadSize?: number
  /**
   * Internal ID of the file upload attempt. Used only to link tracking events.
   */
  uploadTrackingId: string
  [property: string]: any
}

/**
 * When an attempted upload fails validation.
 */
export interface UploadValidationFailed {
  /**
   * The name of the client that the event occurred in.
   */
  clientName: string
  /**
   * The global organization ID of the user that viewed the product solutions.
   */
  organizationId: string
  /**
   * The product in which this action occurred.
   */
  product: string
  /**
   * The source of the upload.
   */
  source: string
  /**
   * The trigger that caused the upload to start.
   */
  trigger: string
  /**
   * The ID of the uploader that was used to upload the file.
   */
  uploaderId: string
  /**
   * The extension of the file that was uploaded.
   */
  uploadExtension?: string
  /**
   * The media type of the file that was uploaded.
   */
  uploadMediaType?: string
  /**
   * The size of the file that was uploaded.
   */
  uploadSize?: number
  /**
   * Internal ID of the file upload attempt. Used only to link tracking events.
   */
  uploadTrackingId: string
  /**
   * The validation error that caused the upload to fail.
   */
  uploadValidationError: string
  [property: string]: any
}

/**
 * When a user clicks the Edit button from the Publish composer.
 */
export interface UploadcareEditClicked {
  /**
   * The channel that the Edit was opened for (ex. instagram, pinterest). Some times it is not
   * needed as in Ideas
   */
  channel?: string
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The component where the integration was opened
   */
  context?: string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product: string
  [property: string]: any
}

/**
 * When a user clicks the Uploadcare integration button from the Publish composer.
 */
export interface UploadcareIntegrationButtonClicked {
  /**
   * The channel that the integration was opened for (ex. instagram, pinterest). Some times it
   * is not needed as in Ideas
   */
  channel?: string
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The component where the integration was opened
   */
  context?: string
  /**
   * What button was clicked
   */
  integration: string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product: string
  /**
   * Source of the click from AB Tests, either integrations bar or dropdown
   */
  source: string
  [property: string]: any
}

/**
 * When a user opens the Uploadcare modal via available integrations.
 */
export interface UploadcareModalOpened {
  /**
   * The channel that the integration was opened for (ex. instagram, pinterest). Some times it
   * is not needed as in Ideas
   */
  channel?: string
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The component where the integration was opened
   */
  context?: string
  /**
   * What integration loaded the Uploadcare modal
   */
  integration: string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product: string
  /**
   * Source of the click from AB Tests, either integrations bar or dropdown
   */
  source: string
  [property: string]: any
}

/**
 * When the upload within Uploadcare is finished and the media is attached to the Composer.
 */
export interface UploadcareUploadFinished {
  /**
   * The channel that the integration was opened for (ex. instagram, pinterest). Some times it
   * is not needed as in Ideas
   */
  channel?: string
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The component where the integration was opened
   */
  context?: string
  /**
   * What integration loaded the Uploadcare modal
   */
  integration: string
  /**
   * Type of media
   */
  media: string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product: string
  /**
   * Source of the click from AB Tests, either integrations bar or dropdown
   */
  source: string
  [property: string]: any
}

/**
 * When a user starts the upload of the file to Uploadcare.
 */
export interface UploadcareUploadStarted {
  /**
   * The channel that the integration was opened for (ex. instagram, pinterest). Some times it
   * is not needed as in Ideas
   */
  channel?: string
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The component where the integration was opened
   */
  context?: string
  /**
   * What integration loaded the Uploadcare modal
   */
  integration: string
  /**
   * Type of media
   */
  media?: string
  /**
   * The organization ID of the user that took the action
   */
  organizationId: string
  /**
   * The product in which the action occurred (ex. publish, buffer)
   */
  product: string
  /**
   * Source of the click from AB Tests, either integrations bar or dropdown
   */
  source: string
  [property: string]: any
}

/**
 * When did the user play this video on the Buffer owned website?
 */
export interface VideoPlayed {
  /**
   * Which call to action did the user click to play this video?
   */
  cta?: null | string
  /**
   * Which product or website is the CTA located in? Examples would be either the product,
   * like `publish`, or the website, like `bufferSite`
   */
  ctaApp?: null | string
  /**
   * What is the name or action of the CTA?  In many cases it makes sense to describe the
   * intended result of the CTA, like `proTrial` or `publishPro`
   */
  ctaButton?: null | string
  /**
   * Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in
   * Publish app shell menu
   */
  ctaLocation?: null | string
  /**
   * What is the version number of the CTA?  If setting the value for the first time, set to
   * `1`
   */
  ctaVersion?: null | string
  /**
   * What website page or app view is the CTA located?  Examples would be, `composer` or
   * `analyticsOverview` for Publish, and `pricingPublish` for the Publish pricing page on the
   * Buffer marketing site
   */
  ctaView?: null | string
  /**
   * The organization ID of the account that was logged in. This will be null if we can not
   * identify the person playing the video as an existing Buffer user.
   */
  organizationId?: null | string
  /**
   * The full url of the page with the embedded video that was played
   */
  url?: string
  /**
   * What was the specific campaign (e.g. product launch, new feature, partnership, etc.) that
   * resulted in this video play?
   */
  utmCampaign?: null | string
  /**
   * What was the specific content or content that this video play is attributed to. For
   * example a specific CTA or part of an email that is being A/B tested.
   */
  utmContent?: null | string
  /**
   * What is the medium of the link that lead to this video play? For example email, CPC, or
   * other method of sharing.
   */
  utmMedium?: null | string
  /**
   * What is the source of traffic that resulted in this video play? For example search
   * engine, newsletter, or other referral.
   */
  utmSource?: null | string
  /**
   * What is the paid search term or keyword used to drive this video play? For example DTC,
   * Instagram scheduling.
   */
  utmTerm?: null | string
  /**
   * What is the unique name of the video, by which we identify it internally at Buffer? For
   * example, `multi-product explainer video`.
   */
  videoTitle: string
  [property: string]: any
}

/**
 * When a user clicks the View Report button.
 */
export interface ViewCampaignReportButtonClicked {
  /**
   * The color selected for the campaign.
   */
  campaignColor: string
  /**
   * The ID of the new campaign.
   */
  campaignId: string
  /**
   * The name given to the campaign.
   */
  campaignName: string
  /**
   * The name of the client in which the action occurred (ex. publishWeb)
   */
  clientName: string
  /**
   * The ID of the organization for which the campaign was created.
   */
  organizationId: string
  /**
   * The product in which the action occurred
   */
  product: string
  [property: string]: any
}

/**
 * When did the user see the welcome checklist?
 */
export interface WelcomeChecklistViewed {
  /**
   * The global organization ID of the user that viewed the plan selector.
   */
  organizationId: null | string
  /**
   * How many checklist items has the user completed?
   */
  qtyItemsCompleted?: number | null
  [property: string]: any
}

/**
 * When did the open the Whats New Dialog CTA
 */
export interface WhatsNewDialogTapped {
  /**
   * The client in which the action occurred.
   */
  clientName: string
  /**
   * The feature shown within the Whats New Dialog, ex. "shopify"
   */
  feature: string
  /**
   * The product in which the event occurred, ex. “remix”.
   */
  product: string
  [property: string]: any
}

/**
 * Which widget did the user add to their home screen
 */
export interface WidgetAdded {
  /**
   * What was the unique identifier of the client the user was in when they added the widget?
   */
  clientId?: null | string
  /**
   * Which client was the user in when they added the widget? IE, `publishIos` or
   * `publishAndroid`.
   */
  clientName: string
  /**
   * The organization ID of the user that added the widget.
   */
  organizationId: string
  /**
   * The name of the widget the user added
   */
  widgetName: string
  /**
   * The size of the widget the user added
   */
  widgetSize?: null | string
  [property: string]: any
}

/**
 * Which widget did the user remove from their home screen
 */
export interface WidgetRemoved {
  /**
   * What was the unique identifier of the client the user was in when they removed the widget?
   */
  clientId?: null | string
  /**
   * Which client was the user in when they removed the widget? IE, `publishIos` or
   * `publishAndroid`.
   */
  clientName: string
  /**
   * The organization ID of the user that removed the widget.
   */
  organizationId: string
  /**
   * The name of the widget the user removed
   */
  widgetName: string
  /**
   * The size of the widget the user removed
   */
  widgetSize?: null | string
  [property: string]: any
}

/**
 * Ajv is a peer dependency for development builds. It's used to apply run-time validation
 * to message payloads before passing them on to the underlying analytics instance.
 *
 * Note that the production bundle does not depend on Ajv.
 *
 * You can install it with: `npm install --save-dev ajv`.
 */
import type {
  Analytics,
  AnalyticsBrowser,
  AnalyticsSnippet,
  Options,
} from '@segment/analytics-next'
import type { ErrorObject } from 'ajv'
import Ajv from 'ajv'

/**
 * The analytics.js snippet should be available via window.analytics.
 * You can install it by following instructions at: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/
 * Make sure to also include the TypeScript declarations with: `npm install @segment/analytics-next` (install with --save-dev for type definitions only).
 *
 * If you don't want to use the snippet, you can also install the `@segment/analytics-next` library as a *production* dependency and use it like this:
 * ```ts
 * import { AnalyticsBrowser } from '@segment/analytics-next'
 * import { setTypewriterOptions } from './analytics'
 *
 * const analytics = AnalyticsBrowser.load({ writeKey: 'SEGMENT_WRITE_KEY' })
 *
 * setTypewriterOptions({ analytics: analytics })
 */

declare global {
  interface Window {
    analytics: AnalyticsSnippet
  }
}

type AnyAnalytics = AnalyticsSnippet | Analytics | AnalyticsBrowser

/** The callback exposed by analytics.js. */
export type Callback = () => void

export type ViolationHandler = (
  message: Record<string, any>,
  violations: ErrorObject[],
) => void

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (
  message,
  violations,
) => {
  const msg = JSON.stringify(
    {
      type: 'Typewriter JSON Schema Validation Error',
      description:
        `You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
        'Tracking Plan spec.',
      errors: violations,
    },
    undefined,
    2,
  )

  console.warn(msg)
}

let onViolation = defaultValidationErrorHandler

let analytics: () => AnyAnalytics | undefined = () => {
  return window.analytics
}

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
  /**
   * Underlying analytics instance where analytics calls are forwarded on to.
   * Defaults to window.analytics.
   */
  analytics?: AnyAnalytics
  /**
   * Handler fired when if an event does not match its spec. This handler
   * does not fire in production mode, because it requires inlining the full
   * JSON Schema spec for each event in your Tracking Plan.
   *
   * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
   * if a message does not match the spec. Otherwise, errors will be logged to stderr.
   */
  onViolation?: ViolationHandler
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {AnyAnalytics} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
  analytics = options.analytics
    ? () => options.analytics || window.analytics
    : analytics
  onViolation = options.onViolation || onViolation
}

/**
 * Validates a message against a JSON Schema using Ajv. If the message
 * is invalid, the `onViolation` handler will be called.
 */
function validateAgainstSchema(message: Record<string, any>, schema: object) {
  const ajv = new Ajv({ allErrors: true, verbose: true })

  if (!ajv.validate(schema, message) && ajv.errors) {
    onViolation(message, ajv.errors)
  }
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Options = {}): Options {
  return {
    ...message,
    context: {
      ...(message.context || {}),
      typewriter: {
        language: 'typescript',
        version: '9.2.0',
      },
    },
  }
}

/**
 * Fires a 'AIAssistantUpgradeModalViewed' track call.
 *
 * @param AIAssistantUpgradeModalViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function aIAssistantUpgradeModalViewed(
  props: AIAssistantUpgradeModalViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'AI_Assistant_Upgrade_Modal_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When a legacy (multi-product) user views the modal to upgrade their plan for access to the AI Assistant feature.',
    properties: {
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      cta: {
        description:
          'Which call to action did the user click to view the modal',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
    },
    required: ['organizationId', 'clientName', 'cta'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'AI Assistant Upgrade Modal Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'AccountAvatarUpdated' track call.
 *
 * @param AccountAvatarUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function accountAvatarUpdated(
  props: AccountAvatarUpdated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Account_Avatar_Updated',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: "When was an account's avatar updated?",
    properties: {
      accountId: {
        description: 'The accountId of the user that took the action',
        type: ['string'],
      },
      url: {
        description: "What is the account's new avatar url?",
        type: ['string'],
      },
    },
    required: ['accountId', 'url'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Account Avatar Updated',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'AccountCreated' track call.
 *
 * @param AccountCreated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function accountCreated(
  props: AccountCreated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Account_Created',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      "When was the user's global Buffer account firt created? This occurs during their first product signup.",
    properties: {
      actualRedirect: {
        description:
          'The place the user was actually redirected to after signup.',
        type: ['string', 'null'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        type: ['string'],
      },
      createdWithTrial: {
        description:
          'Was the account created with a trial, or was it a free signup?',
        type: ['boolean', 'null'],
      },
      cta: {
        description:
          'Which call to action did the user click to create their Buffer global account',
        type: ['string', 'null'],
      },
      ctaApp: {
        description: 'Which website is the CTA located in? Ex, `bufferSite`',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA?  In many cases it makes sense to describe the intended result of the CTA, like `proTrial` or `publishPro`',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be, `cards` for the Pro trial CTA in Publish pricing page main plan comparison section',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA?  If setting the value for the first time, set to `1`',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'Which website page is the CTA located?  Ex,  `pricingPublish` for the Publish pricing page',
        type: ['string', 'null'],
      },
      fbclid: {
        description:
          'The Facebook Click ID. This is used for paid marketing attribution.',
        type: ['string', 'null'],
      },
      gclid: {
        description:
          'The Google Click ID. This is used for paid marketing attribution.',
        type: ['string', 'null'],
      },
      hasMixpanelAnonymousId: {
        description:
          'Does the account have an associated Mixpanel anonymous id?',
        type: ['boolean', 'null'],
      },
      intendedRedirect: {
        description:
          'The redirect param present during account signup, if present.',
        type: ['string', 'null'],
      },
      isRedirectValid: {
        description:
          'True if the redirect param used during signup was valid and allowed.',
        type: ['boolean'],
      },
      isReferredUser: {
        description:
          'Was the newly created user referred by another organization?',
        type: ['boolean'],
      },
      isTeamMember: {
        description: 'Was the account created via a team member invite?',
        type: ['boolean'],
      },
      mixpanelAnonymousId: {
        description: 'The Mixpanel anonymous id associated with this account',
        type: ['string', 'null'],
      },
      organizationId: {
        description: 'The organization ID of the account created',
        type: ['string'],
      },
      product: {
        description:
          'Which product was the product signup for that triggered this account creation',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      referralCode: {
        description:
          'If the new user was referred by an organization, the unique code of the landing page the user used to sign up.',
        type: ['string', 'null'],
      },
      referredByOrganizationId: {
        description: 'the ID of the organization who referred the new user',
        type: ['string', 'null'],
      },
      screenSize: {
        description:
          "The size of the user's screen when the account was created",
        pattern: 'xs|s|m|l|xl|xxl',
        type: ['string', 'null'],
      },
      userAgent: {
        description: 'The user agent at the time the account was created.',
        type: ['string', 'null'],
      },
      utmCampaign: {
        description:
          'Identifies a specific product promotion or strategic campaign (e.g. app_store_listing)',
        type: ['string', 'null'],
      },
      utmContent: {
        description:
          'Identifies what specifically was clicked to bring the user to the site, such as a banner ad or a text link. It is often used for A/B testing and content-targeted ads.',
        type: ['string', 'null'],
      },
      utmMedium: {
        description: 'Identifies what type of link was used (e.g. partnership)',
        type: ['string', 'null'],
      },
      utmSource: {
        description: 'Identifies which site sent the traffic (e.g. twitter)',
        type: ['string', 'null'],
      },
      utmTerm: {
        description:
          'Identifies specific search terms. This parameter is not always necessary.',
        type: ['string', 'null'],
      },
    },
    required: ['product'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Account Created',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'AccountDeleted' track call.
 *
 * @param AccountDeleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function accountDeleted(
  props: AccountDeleted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Account_Deleted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When did a user delete their global Buffer account? This event is triggered when the unique identifier for the global account is deleted from the production database. This event will be triggered if the user deletes their own account, or if a Buffer employee deletes the account on the users behalf.',
    properties: {
      details: {
        description:
          'What was the free-form response the user provided about their reason for deleting their account?',
        type: ['string', 'null'],
      },
      reason: {
        description:
          'What was the reason the user selected as to why they chose to delete their?',
        type: ['string', 'null'],
      },
    },
    required: ['details'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Account Deleted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'AccountLogin' track call.
 *
 * @param AccountLogin props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function accountLogin(
  props: AccountLogin,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Account_Login',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user login to Buffer?',
    properties: {
      clientName: {
        description: 'Which client was the user in when they took this action?',
        type: ['string'],
      },
      cta: {
        description: 'The CTA that was clicked to trigger the login',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the account that was logged in',
        type: ['string'],
      },
    },
    required: ['properties'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Account Login',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'AccountLogout' track call.
 *
 * @param AccountLogout props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function accountLogout(
  props: AccountLogout,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Account_Logout',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When did the user logout of Buffer? This will only be triggered when the user explicitly clicks a CTA to log out.',
    properties: {
      clientName: {
        description: 'Which client was the user in when they took this action?',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the account logged out',
        type: ['string'],
      },
      product: {
        description: 'Which product was the user in when they logged out?',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: ['product'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Account Logout',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'AccountNameUpdated' track call.
 *
 * @param AccountNameUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function accountNameUpdated(
  props: AccountNameUpdated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Account_Name_Updated',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When user update their account name',
    properties: {
      accountId: {
        description: 'The accountId of the user that took the action',
        type: ['string'],
      },
    },
    required: ['accountId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Account Name Updated',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'AccountSignupAttempted' track call.
 *
 * @param AccountSignupAttempted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function accountSignupAttempted(
  props: AccountSignupAttempted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Account_Signup_Attempted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'This event is used for paid marketing purposes (we need to send a conversion event from the client to Google Ads). The event is sent when a user attempts to sign up for a Buffer account.',
    properties: {
      clientName: {
        description: 'Which client was the user in when they took this action?',
        type: ['string', 'null'],
      },
      isTeamMember: {
        description: 'Was the account created via a team member invite?',
        type: ['boolean', 'null'],
      },
      organizationId: {
        description: 'The organizationId assigned to the user.',
        type: ['string', 'null'],
      },
    },
    required: ['properties'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Account Signup Attempted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'AccountSuspended' track call.
 *
 * @param AccountSuspended props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function accountSuspended(
  props: AccountSuspended,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Account_Suspended',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: "When was a user's global Buffer account suspended by Buffer?",
    properties: {
      organizationId: {
        description: 'The organization ID of the account suspended',
        type: ['string'],
      },
      wasRefunded: {
        description: 'Has the last payment been refunded?',
        type: ['boolean'],
      },
      wasSubscriptionCancelled: {
        description:
          'Has the subscription been cancelled in the payment gateway?',
        type: ['boolean'],
      },
    },
    required: ['organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Account Suspended',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'AccountTimezoneUpdated' track call.
 *
 * @param AccountTimezoneUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function accountTimezoneUpdated(
  props: AccountTimezoneUpdated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Account_Timezone_Updated',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: "When was an account's timezone updated?",
    properties: {
      accountId: {
        description: 'The accountId of the user that took the action',
        type: ['string'],
      },
      timezone: {
        description: "What is the account's new timezone?",
        type: ['string'],
      },
    },
    required: ['accountId', 'timezone'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Account Timezone Updated',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'AccountUnsuspended' track call.
 *
 * @param AccountUnsuspended props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function accountUnsuspended(
  props: AccountUnsuspended,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Account_Unsuspended',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      "When was a user's global Buffer account unsuspended by Buffer?",
    properties: {
      organizationId: {
        description:
          'The organization ID of the user that is no longer suspended',
        type: ['string'],
      },
    },
    required: ['organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Account Unsuspended',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'AddressUpdated' track call.
 *
 * @param AddressUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function addressUpdated(
  props: AddressUpdated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Address_Updated',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When was an address for a customer updated',
    properties: {
      country: {
        description: 'The address country of the customer',
        type: ['string', 'null'],
      },
      customerId: {
        description: 'Unique identifier for the Stripe customer',
        type: ['string'],
      },
      organizationId: {
        description:
          'The ID of the organization for which the tax exempt was updated',
        type: ['string'],
      },
      postalCode: {
        description: 'The address postal code of the customer',
        type: ['string', 'null'],
      },
      previousCountry: {
        description: 'The previous address country of the customer',
        type: ['string', 'null'],
      },
      previousPostalCode: {
        description: 'The previous address postal code of the customer',
        type: ['string', 'null'],
      },
      previousState: {
        description: 'The previous address state of the customer',
        type: ['string', 'null'],
      },
      state: {
        description: 'The address state of the customer',
        type: ['string', 'null'],
      },
      stripeEventId: {
        description: 'Stripe event id from the webhook event',
        type: ['string'],
      },
    },
    required: ['organizationId', 'stripeEventId', 'customerId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Address Updated',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'AllChannelsOpened' track call.
 *
 * @param AllChannelsOpened props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function allChannelsOpened(
  props: AllChannelsOpened,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'All_Channels_Opened',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'All Channels view opened with filters',
    properties: {
      accountId: { description: 'The account ID', type: ['string'] },
      channelsSelected: {
        description:
          'Selected channels in filter, if not defined - all channels selected',
        type: ['array'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      tab: {
        description: 'Selected tab - queue/sent/approvals/drafts',
        type: ['string'],
      },
      tagsSelected: {
        description:
          'Selected tags in filter, if not defined - all tags selected',
        type: ['array'],
      },
    },
    required: ['organizationId', 'accountId', 'tab'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'All Channels Opened',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'AllPostCommentsDismissed' track call.
 *
 * @param AllPostCommentsDismissed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function allPostCommentsDismissed(
  props: AllPostCommentsDismissed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'All_Post_Comments_Dismissed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user dismisses all comments on a post.',
    properties: {
      channel: {
        description: 'The channel that the comments belong to, e.g. "facebook"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel.',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel.",
        type: ['string'],
      },
      channelUsername: {
        description: 'What is the username of the channel?',
        type: ['string'],
      },
      hasLabel: {
        description: 'Does the post have any labels?',
        type: ['boolean'],
      },
      isGiveaway: { description: 'Is the post a giveaway?', type: ['boolean'] },
      labelTypes: {
        description:
          'The types of lables associated with the post (e.g. "upset/order/question")',
        type: ['array'],
      },
      organizationId: {
        description:
          'The global organization id of the user that owns the channel.',
        type: ['string'],
      },
      postId: {
        description: 'The unique identifier of the post.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred.',
        pattern: 'engage',
        type: ['string'],
      },
      totalComments: {
        description: 'The total number of comments for the post.',
        type: ['number'],
      },
      totalUnansweredComments: {
        description: 'The total number of unanswered comments for the post.',
        type: ['number'],
      },
    },
    required: [
      'product',
      'organizationId',
      'postId',
      'totalComments',
      'totalUnansweredComments',
      'hasLabel',
      'labelTypes',
      'channelId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'All Post Comments Dismissed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'AnalyticsAddChartToReportFailed' track call.
 *
 * @param AnalyticsAddChartToReportFailed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function analyticsAddChartToReportFailed(
  props: AnalyticsAddChartToReportFailed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Analytics_Add_Chart_To_Report_Failed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When there is a failure adding Analytics Chart to a Report',
    properties: {
      channel: {
        description: 'The channel of the selected profile, ex. “instagram”.',
        pattern: 'instagram|facebook|twitter|linkedin|aggregates',
        type: ['string'],
      },
      clientName: {
        description: 'The name of the client in which the event occurred.',
        type: ['string'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that took the action.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred, ex. “analyze”.',
        type: ['string'],
      },
    },
    required: ['product', 'organizationId', 'clientName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Analytics Add Chart To Report Failed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'AnalyticsChartRenderingFailed' track call.
 *
 * @param AnalyticsChartRenderingFailed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function analyticsChartRenderingFailed(
  props: AnalyticsChartRenderingFailed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Analytics_Chart_Rendering_Failed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When there is an error rendering an analytics page.',
    properties: {
      channel: {
        description: 'The channel of the selected profile, ex. “instagram”.',
        pattern: 'instagram|facebook|twitter|linkedin|aggregates',
        type: ['string'],
      },
      chartName: {
        description: 'The name of the chart that failed to render.',
        type: ['string'],
      },
      clientName: {
        description: 'The name of the client in which the event occurred.',
        type: ['string'],
      },
      failureCause: {
        description: 'The cause of the failure event.',
        pattern: 'error|noData',
        type: ['string'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that took the action.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred, ex. “analyze”.',
        type: ['string'],
      },
    },
    required: ['product', 'organizationId', 'clientName', 'failureCause'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Analytics Chart Rendering Failed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'AnalyticsExportFailed' track call.
 *
 * @param AnalyticsExportFailed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function analyticsExportFailed(
  props: AnalyticsExportFailed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Analytics_Export_Failed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When there is an error exporting analytics.',
    properties: {
      channel: {
        description:
          'If the export is a CSV or image, what is the channel of the selected profile?',
        type: ['string', 'null'],
      },
      clientName: {
        description: 'The name of the client in which the event occurred.',
        type: ['string'],
      },
      exportType: {
        description: 'The type of the export, ex. "pdf", "csv", "images"',
        type: ['string'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that took the action',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred, ex. “analyze”.',
        type: ['string'],
      },
      reportId: {
        description:
          'The id of the report that the user intended to export if it is PDF export.',
        type: ['string', 'null'],
      },
    },
    required: ['product', 'organizationId', 'clientName', 'exportType'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Analytics Export Failed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'AnalyticsRetrievalFailed' track call.
 *
 * @param AnalyticsRetrievalFailed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function analyticsRetrievalFailed(
  props: AnalyticsRetrievalFailed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Analytics_Retrieval_Failed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When there is an error fetching analytics or data.',
    properties: {
      channel: {
        description: 'The channel of the selected profile, ex. “instagram”.',
        type: ['string'],
      },
      clientName: {
        description: 'The name of the client in which the event occurred.',
        type: ['string'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that took the action',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred, ex. “analyze”.',
        type: ['string'],
      },
    },
    required: ['product', 'organizationId', 'clientName', 'channel'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Analytics Retrieval Failed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ApplicationInstalled' track call.
 *
 * @param ApplicationInstalled props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function applicationInstalled(
  props: ApplicationInstalled,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Application_Installed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When did the user first open the Buffer mobile app after installation? Note, if the user never opens the Buffer mobile app after installing, this event will not be fired.',
    properties: {
      build: {
        description:
          'What was the build number of the installed app the user opened?',
      },
      clientName: {
        description:
          'The mobile client the user is on, `publishIos` or `publishAndroid`.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred, ex. “publish”.',
        pattern: 'publish',
        type: ['string'],
      },
      version: {
        description: 'Which mobile app did the user open?',
        type: ['string'],
      },
    },
    required: ['version', 'build'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Application Installed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ApplicationOpened' track call.
 *
 * @param ApplicationOpened props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function applicationOpened(
  props: ApplicationOpened,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Application_Opened',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When did the user open this Buffer mobile application? This event fires when a user launches or foregrounds the Buffer mobile application after the first open. It will fire after the Application Installed event and again after the app is re-opened after being closed. When an App returns from the background this event will fire on iOS only. Apps returning from the background are not currently supported on Android as Application Opened events.',
    properties: {
      build: {
        description:
          'What was the build number of the installed app the user opened?',
      },
      clientName: {
        description:
          'The mobile client the user is on, `publishIos` or `publishAndroid`.',
        type: ['string'],
      },
      fromBackground: {
        description:
          'Did the application transition from “Background” to “Inactive” state prior to foregrounding (as opposed to from “Not Running” state)? Collected on iOS only.',
        type: ['boolean', 'null'],
      },
      product: {
        description: 'The product in which the event occurred, ex. “publish”.',
        pattern: 'publish',
        type: ['string'],
      },
      url: {
        description:
          'What was the value of `UIApplicationLaunchOptionsURLKey` from `launchOptions`? Collected on iOS only.',
        type: ['string', 'null'],
      },
      version: {
        description: 'Which mobile app did the user open?',
        type: ['string'],
      },
    },
    required: ['version', 'build'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Application Opened',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ApplicationUpdated' track call.
 *
 * @param ApplicationUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function applicationUpdated(
  props: ApplicationUpdated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Application_Updated',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When did the user first open the Buffer mobile application after installing an update?',
    properties: {
      build: { description: 'What was the build number of the updated app?' },
      clientName: {
        description:
          'The mobile client the user is on, `publishIos` or `publishAndroid`.',
        type: ['string'],
      },
      previousBuild: {
        description:
          'What was the build number of the previously installed app?',
      },
      previousVersion: {
        description:
          'Which mobile app was previously installed prior to the user installing the update?',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred, ex. “publish”.',
        pattern: 'publish',
        type: ['string'],
      },
      version: {
        description: 'Which mobile app did the user update to?',
        type: ['string'],
      },
    },
    required: ['version', 'build'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Application Updated',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'AuthorAttributionAdded' track call.
 *
 * @param AuthorAttributionAdded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function authorAttributionAdded(
  props: AuthorAttributionAdded,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Author_Attribution_Added',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When the user has added author attribution from a link URL',
    properties: {
      attribution: {
        description: 'The selected attribution mode',
        pattern: 'from|by|handle',
        type: ['string'],
      },
      clientName: {
        description:
          'Which client was the user in when they attached the media item? IE, `publishIos`, `publishWeb`, `publishAndroid`.',
        pattern: 'publishIos|publishAndroid',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that added the media',
        type: ['string'],
      },
      product: {
        description: 'The product for which the author was attributed in',
        pattern: 'publish',
        type: ['string'],
      },
    },
    required: ['product', 'clientName', 'attribution'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Author Attribution Added',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'AutoCropToggled' track call.
 *
 * @param AutoCropToggled props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function autoCropToggled(
  props: AutoCropToggled,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Auto_Crop_Toggled',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When the autocrop setting is enabled or disabled',
    properties: {
      clientId: {
        description:
          'What was the unique identifier of the client the user was in when they added the widget?',
        type: ['string', 'null'],
      },
      clientName: {
        description:
          'Which client was the user in when they added the widget? IE, `publishIos` or `publishAndroid`.',
        pattern: 'publishIos|publishAndroid',
        type: ['string'],
      },
      enabled: {
        description: 'Whether autocrop setting was enabled when it was toggled',
        type: ['boolean'],
      },
      organizationId: {
        description: 'The organization ID of the user that added the widget.',
        type: ['string'],
      },
      source: {
        description: 'Where the autocrop setting was toggled',
        pattern: 'settings|composer',
        type: ['string'],
      },
    },
    required: ['enabled', 'clientName', 'organizationId', 'source'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Auto Crop Toggled',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'AutoRenewalDisabled' track call.
 *
 * @param AutoRenewalDisabled props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function autoRenewalDisabled(
  props: AutoRenewalDisabled,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Auto_Renewal_Disabled',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When was the subscription auto renewal at the end of the current billing term disabled? This equates to the moment in time that the user decided to cancel their paid subscription, but it differs from the Subscription Ended event since there could be time remaining on the current billing term.',
    properties: {
      customerId: {
        description: 'Unique identifier for the Stripe customer',
        type: ['string'],
      },
      cycle: {
        description:
          'The subscription payment interval (month or year) of the subscription',
        type: ['string'],
      },
      gateway: {
        description: 'What triggered this event? e.g. Stripe or RevenueCat',
        type: ['string'],
      },
      organizationId: {
        description:
          'The ID of the organization for which the subscription was created',
        type: ['string'],
      },
      planId: {
        description: 'Stripe plan id of the subscription',
        type: ['string'],
      },
      planName: {
        description: 'Human-readable plan name of the subscription',
        type: ['string'],
      },
      product: {
        description: 'The product of the subscription',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      stripeEventId: {
        description: 'Stripe event id from the webhook event',
        type: ['string'],
      },
      subscriptionId: {
        description: 'Unique identifier for the Stripe subscription',
        type: ['string'],
      },
    },
    required: [
      'stripeEventId',
      'customerId',
      'subscriptionId',
      'planId',
      'product',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Auto Renewal Disabled',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'BannerDismissed' track call.
 *
 * @param BannerDismissed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bannerDismissed(
  props: BannerDismissed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Banner_Dismissed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user dismisses a banner within the product',
    properties: {
      bannerName: {
        description: 'The name of the dismissed banner',
        type: ['string'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern: 'publish|buffer|analyze|engage',
        type: ['string'],
      },
    },
    required: ['organizationId', 'clientName', 'product', 'bannerName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Banner Dismissed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'BannerViewed' track call.
 *
 * @param BannerViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bannerViewed(
  props: BannerViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Banner_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user viewed a banner',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      bannerViewed: { description: 'Which banner was viewed', type: ['array'] },
      organizationId: {
        description: 'The global organization ID of the user',
        type: ['string'],
      },
    },
    required: ['organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Banner Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'BetaProgramDisenrolled' track call.
 *
 * @param BetaProgramDisenrolled props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function betaProgramDisenrolled(
  props: BetaProgramDisenrolled,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Beta_Program_Disenrolled',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did a user disenroll from the Buffer beta program?',
    properties: {
      adminAccountId: {
        description:
          'The account ID of the admin that disenrolled the organization from the Beta program.',
        type: ['string', 'null'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string', 'null'],
      },
      disenrollmentMethod: {
        description:
          'How was the user disenrolled in the beta program? Did they use the Beta program page or did Advocacy remove them?',
        pattern: 'advocacy|selfService',
        type: ['string'],
      },
      organizationId: {
        description: 'The global organization ID of the user',
        type: ['string'],
      },
    },
    required: ['organizationId', 'disenrollmentMethod'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Beta Program Disenrolled',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'BetaProgramEnrolled' track call.
 *
 * @param BetaProgramEnrolled props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function betaProgramEnrolled(
  props: BetaProgramEnrolled,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Beta_Program_Enrolled',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did a user enroll from the Buffer beta program?',
    properties: {
      adminAccountId: {
        description:
          'The account ID of the admin that enrolled the organization to the Beta program.',
        type: ['string', 'null'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string', 'null'],
      },
      enrollmentMethod: {
        description:
          'How was the user enrolled in the beta program? Did they use the Beta program page or did Advocacy enroll them?',
        pattern: 'advocacy|selfService',
        type: ['string'],
      },
      organizationId: {
        description: 'The global organization ID of the user',
        type: ['string'],
      },
    },
    required: ['organizationId', 'enrollmentMethod'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Beta Program Enrolled',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'BillingPortalSessionCreated' track call.
 *
 * @param BillingPortalSessionCreated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function billingPortalSessionCreated(
  props: BillingPortalSessionCreated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Billing_Portal_Session_Created',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When did the user clicked on the CTA to update billing info and it opened Billing Portal?',
    properties: {
      cta: {
        description:
          'If the user navigated to this page from a CTA on another Buffer page, which call to action was it?',
        type: ['string', 'null'],
      },
      ctaApp: {
        description:
          'Which product or website is the CTA located in? Examples would be either the product, like `publish`, or the website, like `bufferSite`',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA?  In many cases it makes sense to describe the intended result of the CTA, like `proTrial` or `publishPro`',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in Publish app shell menu',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA?  If setting the value for the first time, set to `1`',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'What website page or app view is the CTA located?  Examples would be, `composer` or `analyticsOverview` for Publish, and `pricingPublish` for the Publish pricing page on the Buffer marketing site',
        type: ['string', 'null'],
      },
      customerId: {
        description: 'Unique identifier for the billing gateway customer.',
        type: ['string', 'null'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the billing portal.',
        type: ['string', 'null'],
      },
      screenName: {
        description:
          'Human readable name of the section of the plan selector viewed (e.g., ""plans"", ""billing_info"", etc.)',
        type: ['string', 'null'],
      },
    },
    required: ['organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Billing Portal Session Created',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'BitlyAccountConnected' track call.
 *
 * @param BitlyAccountConnected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bitlyAccountConnected(
  props: BitlyAccountConnected,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Bitly_Account_Connected',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When the bitly account is succesfully connected',
    properties: {
      channel: {
        description:
          'The channel that settings tab was opened for (ex. instagram, pinterest)',
        pattern:
          'twitter|facebook|instagram|instagramStory|pinterest|linkedin|tiktok|googlebusiness|mastodon|multiple|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      channelId: {
        description:
          'The channelId that settings tab was opened for (ex. instagram, pinterest)',
        type: ['string'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern: 'publish|buffer|analyze|engage',
        type: ['string'],
      },
    },
    required: [
      'organizationId',
      'clientName',
      'product',
      'channel',
      'channelId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Bitly Account Connected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'BitlyAccountDisconnected' track call.
 *
 * @param BitlyAccountDisconnected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function bitlyAccountDisconnected(
  props: BitlyAccountDisconnected,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Bitly_Account_Disconnected',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When the bitly account is succesfully disconnected',
    properties: {
      channel: {
        description:
          'The channel that settings tab was opened for (ex. instagram, pinterest)',
        pattern:
          'twitter|facebook|instagram|instagramStory|pinterest|linkedin|tiktok|googlebusiness|mastodon|multiple|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      channelId: {
        description:
          'The channelId that settings tab was opened for (ex. instagram, pinterest)',
        type: ['string'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern: 'publish|buffer|analyze|engage',
        type: ['string'],
      },
    },
    required: [
      'organizationId',
      'clientName',
      'product',
      'channel',
      'channelId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Bitly Account Disconnected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'CTAClicked' track call.
 *
 * @param CTAClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function cTAClicked(
  props: CTAClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'CTA_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user clicks on a CTA button',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      cta: {
        description: 'The name of the CTA clicked.',
        type: ['string', 'null'],
      },
      ctaName: {
        description:
          '(NOTE - deprecated in favor of cta) The name of the CTA (e.g. "add-channel-sidebar-prelimit")',
        type: ['string', 'null'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string', 'null'],
      },
      upgradePathName: {
        description: 'The name of the upgrade path',
        type: ['string', 'null'],
      },
    },
    required: ['clientName', 'product', 'cta', 'upgradePathName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'CTA Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'CTAViewed' track call.
 *
 * @param CTAViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function cTAViewed(
  props: CTAViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'CTA_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user see a specific CTA?',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      cta: {
        description: 'The name of the CTA clicked.',
        type: ['string', 'null'],
      },
      ctaName: {
        description:
          '(NOTE -  deprecated in favor of CTA) The name of the CTA (e.g. "add-channel-sidebar-prelimit")',
        type: ['string', 'null'],
      },
      currentChannelQuantity: {
        description: "The number of channels connected to the user's account",
        type: ['number', 'null'],
      },
      isAtPlanLimit: {
        description: 'Is the user at their plan limit?',
        type: ['boolean', 'null'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the plan selector.',
        type: ['string', 'null'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      upgradePathName: {
        description: 'The name of the upgrade path',
        type: ['string', 'null'],
      },
    },
    required: ['organizationId', 'upgradePathName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'CTA Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'CalendarCampaignsFiltered' track call.
 *
 * @param CalendarCampaignsFiltered props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function calendarCampaignsFiltered(
  props: CalendarCampaignsFiltered,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Calendar_Campaigns_Filtered',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user filters the campaigns displayed in the calendar.',
    properties: {
      campaignCount: {
        description:
          'The number of campaigns selected to be viewed in the calendar after they were filtered.',
        type: ['number'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      previousCampaignCount: {
        description:
          'The number of campaigns selected in the calendar before they were filtered.',
        type: ['number'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern: 'publish|buffer|analyze|engage',
        type: ['string'],
      },
      view: {
        description: 'The calendar view (ex. day, month, week)',
        pattern: 'day|week|month',
        type: ['string'],
      },
    },
    required: [
      'organizationId',
      'clientName',
      'product',
      'view',
      'campaignCount',
      'previousCampaignCount',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Calendar Campaigns Filtered',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'CalendarChannelsFiltered' track call.
 *
 * @param CalendarChannelsFiltered props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function calendarChannelsFiltered(
  props: CalendarChannelsFiltered,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Calendar_Channels_Filtered',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When the channels are filtered after the calendar is opened.',
    properties: {
      availableChannelIDs: {
        description:
          'The channel ids that are available for the user to select.',
        type: ['array'],
      },
      canCreatePost: {
        description:
          "Given the channels filtered in the Calendar and the user's permissions, is the user able to create a post from the Calendar?",
        type: ['boolean'],
      },
      channelCount: {
        description:
          'The number of channels selected to be viewed in the calendar after they were filtered.',
        type: ['number'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      previousChannelCount: {
        description:
          'The number of channels selected in the calendar before they were filtered.',
        type: ['number'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern: 'publish|buffer|analyze|engage',
        type: ['string'],
      },
      selectedChannelIDs: {
        description: 'The channel ids that were selected by the user.',
        type: ['array'],
      },
      view: {
        description: 'The calendar view (ex. day, month, week)',
        pattern: 'day|week|month',
        type: ['string'],
      },
    },
    required: [
      'organizationId',
      'clientName',
      'product',
      'view',
      'channelCount',
      'previousChannelCount',
      'canCreatePost',
      'selectedChannelIDs',
      'availableChannelIDs',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Calendar Channels Filtered',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'CalendarChannelsSelected' track call.
 *
 * @param CalendarChannelsSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function calendarChannelsSelected(
  props: CalendarChannelsSelected,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Calendar_Channels_Selected',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When the user selects the channels to be viewed in the Calendar. This event shows the user intent to filter the channels.',
    properties: {
      channel: {
        description: 'The service of the selected channel ex. "facebook"',
        type: ['string'],
      },
      channelCount: {
        description:
          'The number of channels selected to be viewed in the calendar after they were filtered.',
        type: ['number'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      previousChannelCount: {
        description:
          'The number of channels selected in the calendar before they were filtered.',
        type: ['number'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern: 'publish|buffer|analyze|engage',
        type: ['string'],
      },
      view: {
        description: 'The calendar view (ex. day, month, week)',
        pattern: 'day|week|month',
        type: ['string'],
      },
    },
    required: [
      'organizationId',
      'clientName',
      'product',
      'channel',
      'view',
      'channelCount',
      'previousChannelCount',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Calendar Channels Selected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'CalendarDatesChanged' track call.
 *
 * @param CalendarDatesChanged props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function calendarDatesChanged(
  props: CalendarDatesChanged,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Calendar_Dates_Changed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user changes the dates displayed on the calendar.',
    properties: {
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      dateChangeType: {
        description:
          'The type of date change selected (ex. next_week, next_month, previous_week, previous_month, custom_range)',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern: 'publish|buffer|analyze|engage',
        type: ['string'],
      },
      view: {
        description: 'The calendar view (ex. day, month, week)',
        pattern: 'day|week|month',
        type: ['string'],
      },
    },
    required: [
      'organizationId',
      'clientName',
      'product',
      'view',
      'dateChangeType',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Calendar Dates Changed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'CalendarOpened' track call.
 *
 * @param CalendarOpened props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function calendarOpened(
  props: CalendarOpened,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Calendar_Opened',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user opens the calendar',
    properties: {
      calendarVersion: {
        description: 'The version of the calendar (ex. classic, new)',
        type: ['string'],
      },
      campaignCount: {
        description:
          'The number of campaigns selected to be viewed in the calendar.',
        type: ['number'],
      },
      channelCount: {
        description:
          'The number of channels selected to be viewed in the calendar.',
        type: ['number'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern: 'publish|buffer|analyze|engage',
        type: ['string'],
      },
      source: {
        description: 'Where the calendar was opened from. (ex. queue, sidebar)',
        type: ['string'],
      },
      view: {
        description: 'The calendar view (ex. day, month, week)',
        pattern: 'day|week|month',
        type: ['string'],
      },
    },
    required: [
      'organizationId',
      'clientName',
      'product',
      'calendarVersion',
      'source',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Calendar Opened',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'CalendarViewSwitched' track call.
 *
 * @param CalendarViewSwitched props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function calendarViewSwitched(
  props: CalendarViewSwitched,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Calendar_View_Switched',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When a user switches the calendar view to or from the month view.',
    properties: {
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      previousView: {
        description:
          'The previous view that the user switched from (ex. day, month, week)',
        pattern: 'day|week|month',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern: 'publish|buffer|analyze|engage',
        type: ['string'],
      },
      view: {
        description:
          'The calendar view the user switched to (ex. day, month, week)',
        pattern: 'day|week|month',
        type: ['string'],
      },
    },
    required: [
      'organizationId',
      'clientName',
      'product',
      'view',
      'previousView',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Calendar View Switched',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'CampaignCreated' track call.
 *
 * @param CampaignCreated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function campaignCreated(
  props: CampaignCreated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Campaign_Created',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a new campaign is created.',
    properties: {
      campaignColor: {
        description: 'The color selected for the campaign.',
        type: ['string'],
      },
      campaignId: {
        description: 'The ID of the new campaign.',
        type: ['string'],
      },
      campaignName: {
        description: 'The name given to the campaign.',
        type: ['string'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        type: ['string'],
      },
      organizationId: {
        description:
          'The global organization ID that the campaign was created for.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occurred',
        pattern: 'publish',
        type: ['string'],
      },
      publishOrganizationId: {
        description:
          'The ID of the Publish organization that the campaign was created for.',
        type: ['string'],
      },
    },
    required: [
      'product',
      'campaignId',
      'campaignName',
      'campaignColor',
      'clientName',
      'organizationId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Campaign Created',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'CampaignDeleted' track call.
 *
 * @param CampaignDeleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function campaignDeleted(
  props: CampaignDeleted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Campaign_Deleted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a campaign is deleted.',
    properties: {
      campaignColor: {
        description: 'The color selected for the campaign.',
        type: ['string'],
      },
      campaignId: {
        description: 'The unique ID assigned to the campaign.',
        type: ['string'],
      },
      campaignName: {
        description: 'The name given to the campaign.',
        type: ['string'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        type: ['string'],
      },
      organizationId: {
        description:
          'The global organization ID that the campaign was created for.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occurred.',
        pattern: 'publish',
        type: ['string'],
      },
      publishOrganizationId: {
        description:
          'The ID of the Publish organization that the campaign was created for.',
        type: ['string'],
      },
    },
    required: [
      'product',
      'campaignId',
      'campaignName',
      'campaignColor',
      'clientName',
      'organizationId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Campaign Deleted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'CampaignEdited' track call.
 *
 * @param CampaignEdited props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function campaignEdited(
  props: CampaignEdited,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Campaign_Edited',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a campaign is edited.',
    properties: {
      campaignColor: {
        description: 'The color selected for the campaign.',
        type: ['string'],
      },
      campaignId: { description: 'The ID of the campaign.', type: ['string'] },
      campaignName: {
        description: 'The name given to the campaign.',
        type: ['string'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        type: ['string'],
      },
      organizationId: {
        description:
          'The global organization ID that the campaign was created for.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occurred',
        pattern: 'publish',
        type: ['string'],
      },
      publishOrganizationId: {
        description:
          'The ID of the Publish organization that the campaign was created for.',
        type: ['string'],
      },
    },
    required: [
      'product',
      'campaignId',
      'campaignName',
      'campaignColor',
      'clientName',
      'organizationId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Campaign Edited',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'CampaignItemAdded' track call.
 *
 * @param CampaignItemAdded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function campaignItemAdded(
  props: CampaignItemAdded,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Campaign_Item_Added',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a new item is added to an existing campaign.',
    properties: {
      addedFrom: {
        description:
          'Where was the item added to the campaign from? Ex. Queue, Campaigns',
        type: ['string'],
      },
      campaignId: { description: 'The ID of the campaign.', type: ['string'] },
      campaignName: {
        description: 'The name given to the campaign.',
        type: ['string'],
      },
      channel: {
        description:
          'The social network for which the item was created, ex. instagram.',
        type: ['string'],
      },
      channelId: {
        description: 'The ID of the channel for which the item was created.',
        type: ['string'],
      },
      channelType: {
        description:
          'The type of channel the item was created for, ex. group, page, business.',
        type: ['string'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        type: ['string'],
      },
      isDraft: {
        description: 'If the item added to the campaign is a draft',
        type: ['boolean'],
      },
      isSent: { description: 'If is a sent post', type: ['boolean'] },
      itemId: {
        description:
          'The id of the post or story that was added to the campaign.',
        type: ['string'],
      },
      itemType: {
        description:
          'The type of the item that was added to the campaign, ex. post or story.',
        pattern: 'post|story',
        type: ['string'],
      },
      organizationId: {
        description:
          'The global organization ID that the campaign was created for.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occurred.',
        pattern: 'publish',
        type: ['string'],
      },
      publishOrganizationId: {
        description:
          'The ID of the Publish organization that the campaign was created for.',
        type: ['string'],
      },
    },
    required: [
      'product',
      'itemType',
      'itemId',
      'campaignId',
      'campaignName',
      'channel',
      'clientName',
      'organizationId',
      'isSent',
      'isDraft',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Campaign Item Added',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'CampaignItemRemoved' track call.
 *
 * @param CampaignItemRemoved props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function campaignItemRemoved(
  props: CampaignItemRemoved,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Campaign_Item_Removed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When an item is removed from an existing campaign.',
    properties: {
      campaignId: { description: 'The ID of the campaign.', type: ['string'] },
      campaignName: {
        description: 'The name given to the campaign.',
        type: ['string'],
      },
      channel: {
        description:
          'The social network for which the item was created, ex. instagram.',
        type: ['string'],
      },
      channelId: {
        description: 'The ID of the channel for which the item was created.',
        type: ['string'],
      },
      channelType: {
        description:
          'The type of channel the item was created for, ex. group, page, business.',
        type: ['string'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        type: ['string'],
      },
      isDraft: {
        description: 'If the item removed from the campaign is a draft',
        type: ['boolean'],
      },
      isSent: { description: 'If is a sent post', type: ['boolean'] },
      itemId: {
        description:
          'The id of the post or story that was removed from the campaign.',
        type: ['string'],
      },
      itemType: {
        description:
          'The type of the item that was removed from the campaign, ex. post or story.',
        pattern: 'post|story',
        type: ['string'],
      },
      organizationId: {
        description:
          'The global organization ID that the campaign was created for.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occurred.',
        pattern: 'publish',
        type: ['string'],
      },
      publishOrganizationId: {
        description:
          'The ID of the Publish organization that the campaign was created for.',
        type: ['string'],
      },
      removedFrom: {
        description: 'Where was the item removed from? Ex. Queue, Campaigns',
        type: ['string'],
      },
    },
    required: [
      'product',
      'itemType',
      'itemId',
      'campaignId',
      'campaignName',
      'channel',
      'clientName',
      'organizationId',
      'isSent',
      'isDraft',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Campaign Item Removed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'CampaignReportViewed' track call.
 *
 * @param CampaignReportViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function campaignReportViewed(
  props: CampaignReportViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Campaign_Report_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a report is viewed for an existing campaign.',
    properties: {
      campaignId: { description: 'The ID of the campaign.', type: ['string'] },
      campaignName: {
        description: 'The name given to the campaign.',
        type: ['string'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        type: ['string'],
      },
      organizationId: {
        description:
          'The ID of the global organization that the campaign was created for.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occurred',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      publishOrganizationId: {
        description:
          'The ID of the Publish organization that the campaign was created for.',
        type: ['string'],
      },
    },
    required: [
      'product',
      'campaignId',
      'campaignName',
      'clientName',
      'organizationId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Campaign Report Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'CampaignsFilterSelected' track call.
 *
 * @param CampaignsFilterSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function campaignsFilterSelected(
  props: CampaignsFilterSelected,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Campaigns_Filter_Selected',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user changes between Active and Completed Campaigns',
    properties: {
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern: 'publish|buffer|analyze|engage',
        type: ['string'],
      },
      view: {
        description: 'The campaign filter (active/completed)',
        pattern: 'active|completed',
        type: ['string'],
      },
    },
    required: ['organizationId', 'clientName', 'product', 'view'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Campaigns Filter Selected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'CancelPlanClicked' track call.
 *
 * @param CancelPlanClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function cancelPlanClicked(
  props: CancelPlanClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Cancel_Plan_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When did a user click on the Cancel Plan button on the billing page?',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      organizationId: {
        description: 'The global organization ID of the user',
        type: ['string'],
      },
    },
    required: ['organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Cancel Plan Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'CanvaButtonClicked' track call.
 *
 * @param CanvaButtonClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function canvaButtonClicked(
  props: CanvaButtonClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Canva_Button_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When a user opens the Canva integration from the Publish composer.',
    properties: {
      channel: {
        description:
          'The channel that the Canva integration was opened for (ex. instagram, pinterest)',
        pattern:
          'twitter|facebook|instagram|instagramStory|pinterest|linkedin|tiktok|googlebusiness|mastodon|multiple|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      designId: {
        description:
          'The identifier of the Canva Design. This is provided when editing a Canva image.',
        type: ['string', 'null'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern: 'publish|buffer|analyze|engage',
        type: ['string'],
      },
    },
    required: ['organizationId', 'clientName', 'product', 'channel'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Canva Button Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'CanvaButtonEditClicked' track call.
 *
 * @param CanvaButtonEditClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function canvaButtonEditClicked(
  props: CanvaButtonEditClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Canva_Button_Edit_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When a user clicks the button for the Canva integration from the Publish composer to edit an already created post that already has an attached Canva design.',
    properties: {
      channel: {
        description:
          'The channel that the Canva integration was opened for (ex. instagram, pinterest)',
        pattern:
          'twitter|facebook|instagram|instagramStory|pinterest|linkedin|tiktok|googlebusiness|mastodon|multiple|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      designId: {
        description:
          'The identifier of the Canva Design. This is provided when editing a Canva image.',
        type: ['string', 'null'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern: 'publish|buffer|analyze|engage',
        type: ['string'],
      },
    },
    required: ['organizationId', 'clientName', 'product', 'channel'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Canva Button Edit Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'CanvaDesignClosed' track call.
 *
 * @param CanvaDesignClosed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function canvaDesignClosed(
  props: CanvaDesignClosed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Canva_Design_Closed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user closes one of the Canva designs.',
    properties: {
      channel: {
        description:
          'The channel that the Canva integration was originally opened for (ex. instagram, pinterest)',
        pattern:
          'twitter|facebook|instagram|instagramStory|pinterest|linkedin|tiktok|googlebusiness|mastodon|multiple|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern: 'publish|buffer|analyze|engage',
        type: ['string'],
      },
    },
    required: ['organizationId', 'clientName', 'product', 'channel'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Canva Design Closed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'CanvaDesignEditClosed' track call.
 *
 * @param CanvaDesignEditClosed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function canvaDesignEditClosed(
  props: CanvaDesignEditClosed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Canva_Design_Edit_Closed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When a user closes one of the Canva designs, while editing an existing post that already has a Canva Image attached.',
    properties: {
      channel: {
        description:
          'The channel that the Canva integration was originally opened for (ex. instagram, pinterest)',
        pattern:
          'twitter|facebook|instagram|instagramStory|pinterest|linkedin|tiktok|googlebusiness|mastodon|multiple|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern: 'publish|buffer|analyze|engage',
        type: ['string'],
      },
    },
    required: ['organizationId', 'clientName', 'product', 'channel'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Canva Design Edit Closed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'CanvaDesignEditOpened' track call.
 *
 * @param CanvaDesignEditOpened props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function canvaDesignEditOpened(
  props: CanvaDesignEditOpened,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Canva_Design_Edit_Opened',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When a user opens one of the Canva designs, while editing an existing post that already has a Canva Image attached.',
    properties: {
      channel: {
        description:
          'The channel that the Canva integration was opened for (ex. instagram, pinterest)',
        pattern:
          'twitter|facebook|instagram|instagramStory|pinterest|linkedin|tiktok|googlebusiness|mastodon|multiple|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      designId: {
        description:
          'The identifier of the Canva Design. This is provided when editing a Canva image.',
        type: ['string', 'null'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern: 'publish|buffer|analyze|engage',
        type: ['string'],
      },
    },
    required: ['organizationId', 'clientName', 'product', 'channel'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Canva Design Edit Opened',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'CanvaDesignOpened' track call.
 *
 * @param CanvaDesignOpened props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function canvaDesignOpened(
  props: CanvaDesignOpened,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Canva_Design_Opened',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user opens one of the Canva designs.',
    properties: {
      channel: {
        description:
          'The channel that the Canva integration was opened for (ex. instagram, pinterest)',
        pattern:
          'twitter|facebook|instagram|instagramStory|pinterest|linkedin|tiktok|googlebusiness|mastodon|multiple|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      designId: {
        description:
          'The identifier of the Canva Design. This is provided when editing a Canva image.',
        type: ['string', 'null'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern: 'publish|buffer|analyze|engage',
        type: ['string'],
      },
    },
    required: ['organizationId', 'clientName', 'product', 'channel'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Canva Design Opened',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'CanvaDesignPublished' track call.
 *
 * @param CanvaDesignPublished props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function canvaDesignPublished(
  props: CanvaDesignPublished,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Canva_Design_Published',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user publishes one of the Canva designs.',
    properties: {
      channel: {
        description:
          'The channel that the Canva integration was opened for (ex. instagram, pinterest)',
        pattern:
          'twitter|facebook|instagram|instagramStory|pinterest|linkedin|tiktok|googlebusiness|mastodon|multiple|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      designId: {
        description: 'The identifier of the image provided by Canva',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern: 'publish|buffer|analyze|engage',
        type: ['string'],
      },
    },
    required: [
      'organizationId',
      'clientName',
      'product',
      'channel',
      'designId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Canva Design Published',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'CanvaDesignRepublished' track call.
 *
 * @param CanvaDesignRepublished props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function canvaDesignRepublished(
  props: CanvaDesignRepublished,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Canva_Design_Republished',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When a user publishes one of the Canva designs, after editing an existing post that already has a Canva Image attached.',
    properties: {
      channel: {
        description:
          'The channel that the Canva integration was opened for (ex. instagram, pinterest)',
        pattern:
          'twitter|facebook|instagram|instagramStory|pinterest|linkedin|tiktok|googlebusiness|mastodon|multiple|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      designId: {
        description: 'The identifier of the image provided by Canva',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern: 'publish|buffer|analyze|engage',
        type: ['string'],
      },
    },
    required: [
      'organizationId',
      'clientName',
      'product',
      'channel',
      'designId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Canva Design Republished',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'CanvaImageAttached' track call.
 *
 * @param CanvaImageAttached props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function canvaImageAttached(
  props: CanvaImageAttached,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Canva_Image_Attached',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When an image from Canva is attached to a Buffer post.',
    properties: {
      channel: {
        description:
          'The channel that the Canva image was created for (ex. instagram, pinterest)',
        pattern:
          'twitter|facebook|instagram|instagramStory|pinterest|linkedin|tiktok|googlebusiness|mastodon|multiple|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      designId: {
        description: 'The identifier of the image provided by Canva',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern: 'publish|buffer|analyze|engage',
        type: ['string'],
      },
    },
    required: [
      'organizationId',
      'clientName',
      'product',
      'channel',
      'designId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Canva Image Attached',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'CanvaImageEditAttached' track call.
 *
 * @param CanvaImageEditAttached props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function canvaImageEditAttached(
  props: CanvaImageEditAttached,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Canva_Image_Edit_Attached',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When an image from Canva is attached to a Buffer post, while editing an existing post that already has a Canva Image attached.',
    properties: {
      channel: {
        description:
          'The channel that the Canva image was created for (ex. instagram, pinterest)',
        pattern:
          'twitter|facebook|instagram|instagramStory|pinterest|linkedin|tiktok|googlebusiness|mastodon|multiple|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      designId: {
        description: 'The identifier of the image provided by Canva',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern: 'publish|buffer|analyze|engage',
        type: ['string'],
      },
    },
    required: [
      'organizationId',
      'clientName',
      'product',
      'channel',
      'designId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Canva Image Edit Attached',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'CarouselEditAttempted' track call.
 *
 * @param CarouselEditAttempted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function carouselEditAttempted(
  props: CarouselEditAttempted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Carousel_Edit_Attempted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user attempts to edit a carousel on mobile',
    properties: {
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishIos|publishAndroid',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern: 'publish',
        type: ['string'],
      },
    },
    required: ['organizationId', 'clientName', 'product'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Carousel Edit Attempted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChannelBackfillCompleted' track call.
 *
 * @param ChannelBackfillCompleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function channelBackfillCompleted(
  props: ChannelBackfillCompleted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Channel_Backfill_Completed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When backfilling has been completed for a channel',
    properties: {
      backfillDuration: {
        description: 'The backfilling duration',
        type: ['number'],
      },
      backfillEndedAt: {
        description: 'The timestamp when backfilling ended',
        type: ['number'],
      },
      backfillStartedAt: {
        description: 'The timestamp when backfilling started',
        type: ['number'],
      },
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel.',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel.",
        type: ['string'],
      },
      channelUsername: {
        description: 'What is the username of the channel?',
        type: ['string'],
      },
      hasLabel: {
        description: 'Does the channel have any labels?',
        type: ['boolean'],
      },
      labelTypes: {
        description:
          'The types of labels associated with the channel (e.g. "upset/order/question")',
        type: ['array'],
      },
      organizationId: {
        description:
          'The global organization id of the user that owns this channel',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred.',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      totalUnansweredComments: {
        description:
          'The total number of unanswered comments remaining for the channel.',
        type: ['number'],
      },
    },
    required: [
      'product',
      'organizationId',
      'channel',
      'channelId',
      'channelServiceId',
      'channelUsername',
      'backfillStartedAt',
      'backfillEndedAt',
      'backfillDuration',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Channel Backfill Completed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChannelCTAClicked' track call.
 *
 * @param ChannelCTAClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function channelCTAClicked(
  props: ChannelCTAClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Channel_CTA_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user clicked a specific Channel CTA?',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        pattern:
          'facebook|twitter|tiktok|googlebusiness|mastodon|linkedin|instagram|pinterest|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        pattern: 'publishWeb|publishIos|publishAndroid|core|bufferWeb',
        type: ['string'],
      },
      cta: { description: 'The name of the CTA clicked.', type: ['string'] },
      currentChannelQuantity: {
        description: "The number of channels connected to the user's account",
        type: ['number', 'null'],
      },
      isAtPlanLimit: {
        description: 'Is the user at their plan limit?',
        type: ['boolean', 'null'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that clicked the Channel CTA.',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      upgradePathName: {
        description: 'The name of the upgrade path',
        type: ['string', 'null'],
      },
    },
    required: ['organizationId', 'cta', 'channel'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Channel CTA Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChannelCTAViewed' track call.
 *
 * @param ChannelCTAViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function channelCTAViewed(
  props: ChannelCTAViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Channel_CTA_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user see a specific Channel CTA?',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        pattern:
          'facebook|twitter|tiktok|googlebusiness|mastodon|linkedin|instagram|pinterest|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        pattern: 'publishWeb|publishIos|publishAndroid|core|bufferWeb',
        type: ['string'],
      },
      cta: { description: 'The name of the CTA viewed.', type: ['string'] },
      currentChannelQuantity: {
        description: "The number of channels connected to the user's account",
        type: ['number', 'null'],
      },
      isAtPlanLimit: {
        description: 'Is the user at their plan limit?',
        type: ['boolean', 'null'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the Channel CTA.',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      upgradePathName: {
        description: 'The name of the upgrade path',
        type: ['string', 'null'],
      },
    },
    required: ['organizationId', 'cta', 'channel', 'upgradePathName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Channel CTA Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChannelConnected' track call.
 *
 * @param ChannelConnected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function channelConnected(
  props: ChannelConnected,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Channel_Connected',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When a user first connects a channel (aka profile) to the product',
    properties: {
      apiVersion: {
        description: 'API Version this channel uses',
        type: ['string'],
      },
      businessCategories: {
        description:
          'The categories of the Facebook page connected to the Instagram Business account. Only applicable for Instagram Business profiles or Facebook pages.',
        type: ['array', 'null'],
      },
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        pattern:
          'facebook|twitter|tiktok|googlebusiness|mastodon|linkedin|instagram|pinterest|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel",
        type: ['string'],
      },
      channelType: {
        description:
          'The channel type, ex. "page", "business", "profile", "group", "creator"',
        type: ['string'],
      },
      channelUsername: {
        description: 'What is the username of the channel?',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        pattern: 'publishWeb|publishIos|publishAndroid|core|bufferWeb',
        type: ['string'],
      },
      cta: {
        description:
          'Which call to action did the user click to connect the channel?',
        type: ['string', 'null'],
      },
      ctaApp: {
        description:
          'Which product was the CTA located in? Example would be `publish`',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA?  In many cases it makes sense to describe the intended result of the CTA, like `proTrial` or `publishPro`',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in Publish app shell menu',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA?  If setting the value for the first time, set to `1`',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'Which app view is the CTA located?  Examples would be, `composer` or `analyticsOverview` for Publish',
        type: ['string', 'null'],
      },
      followers: {
        description:
          'The number of followers that the channel has at the time of connection. Only applicable for Instagram Business profiles.',
        type: ['number', 'null'],
      },
      isAtPlanLimit: {
        description:
          'Was the organization at or above their channel limit when the channel was connected.',
        type: ['boolean', 'null'],
      },
      mediaCount: {
        description:
          'The media count of the Instagram Business profile at the time of connection.',
        type: ['number', 'null'],
      },
      organizationId: {
        description:
          'The ID of the global organization that the channel was connected to.',
        type: ['string'],
      },
      pinterestNewApiMigrated: {
        description:
          'Used to check if the channel was migrated to new Pinterest API',
        type: ['boolean'],
      },
      product: {
        description: 'The product the channel was connected to.',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      serverUrl: {
        description: 'The server url of a Mastodon channel being connected.',
        type: ['string', 'null'],
      },
      twitterSubscriptionType: {
        description:
          'Twitter Subscription Type of a connected Twitter/X Channel',
        type: ['string'],
      },
    },
    required: ['channel', 'channelId', 'product'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Channel Connected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChannelConnectionAborted' track call.
 *
 * @param ChannelConnectionAborted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function channelConnectionAborted(
  props: ChannelConnectionAborted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Channel_Connection_Aborted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'This event is fired when the user closes or cancels the modal to select betwen channel subtypes (i.e. profile or page).',
    properties: {
      apiVersion: {
        description: 'API Version this channel uses',
        type: ['string'],
      },
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        pattern: 'publishWeb|publishIos|publishAndroid|core|bufferWeb',
        type: ['string'],
      },
      organizationId: {
        description:
          'The ID of the global organization for which the channel connection was attempted.',
        type: ['string'],
      },
      product: {
        description: 'The product for which the channel was connected',
        pattern: 'publish|analyze|engage|account|marketing|buffer',
        type: ['string'],
      },
    },
    required: ['channel', 'product', 'organizationId', 'clientName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Channel Connection Aborted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChannelConnectionAttempted' track call.
 *
 * @param ChannelConnectionAttempted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function channelConnectionAttempted(
  props: ChannelConnectionAttempted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Channel_Connection_Attempted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'This event is currently triggered either when the button is selected, or for channels with a "Choose" page, it is fired after the user has authenticated and authorized Buffer when viewing the page to select a Business Profile, Page or Group.',
    properties: {
      apiVersion: {
        description: 'API Version this channel uses',
        type: ['string'],
      },
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel",
        type: ['string', 'null'],
      },
      channelType: {
        description:
          'The type of channel the post is created for, ex. "page", "group"',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        pattern: 'publishWeb|publishIos|publishAndroid|core|bufferWeb',
        type: ['string'],
      },
      cta: {
        description:
          'Which call to action did the user click to attempt the profile connection?',
        type: ['string', 'null'],
      },
      ctaApp: {
        description:
          'Which product is the CTA located in? Examples would be either the product, like "publish".',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA? In many cases it makes sense to describe the intended result of the CTA, like `proTrial` or `publishPro`.',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be "menu" for the Pro trial CTA in Publish app shell menu.',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA? If setting the value for the first time, set to "1".',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'What app view is the CTA located in? Examples would be, "composer" or "analyticsOverview" for Publish.',
        type: ['string', 'null'],
      },
      isAtPlanLimit: {
        description:
          'Was the organization at their channel limit when the channel connection was attempted.',
        type: ['boolean', 'null'],
      },
      organizationId: {
        description:
          'The ID of the global organization for which the channel connection was attempted.',
        type: ['string'],
      },
      planNameAtTimeOfAttempt: {
        description:
          'The plan of the current organization for which the channel connection was attempted.',
        type: ['string', 'null'],
      },
      product: {
        description: 'The product for which the channel was connected',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: ['channel', 'product'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Channel Connection Attempted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChannelConnectionCanceledByUser' track call.
 *
 * @param ChannelConnectionCanceledByUser props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function channelConnectionCanceledByUser(
  props: ChannelConnectionCanceledByUser,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Channel_Connection_Canceled_By_User',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user cancels a channel connection from the service',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        pattern: 'instagram',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        pattern: 'publishWeb|publishIos|publishAndroid',
        type: ['string'],
      },
      organizationId: {
        description:
          'The ID of the global organization that the channel was connected to.',
        type: ['string'],
      },
    },
    required: ['channel'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Channel Connection Canceled By User',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChannelConnectionFailed' track call.
 *
 * @param ChannelConnectionFailed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function channelConnectionFailed(
  props: ChannelConnectionFailed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Channel_Connection_Failed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a channel connection attempt fails.',
    properties: {
      apiVersion: {
        description: 'API Version this channel uses',
        type: ['string'],
      },
      cause: {
        description: 'The cause of the failure (i.e. error message).',
        type: ['string'],
      },
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string'],
      },
      channelType: {
        description:
          'The type of channel the post is created for, ex. "page", "group"',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        pattern: 'publishWeb|publishIos|publishAndroid|core|bufferWeb',
        type: ['string'],
      },
      cta: {
        description:
          'Which call to action did the user click to attempt the profile connection?',
        type: ['string', 'null'],
      },
      ctaApp: {
        description:
          'Which product is the CTA located in? Examples would be either the product, like "publish".',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA? In many cases it makes sense to describe the intended result of the CTA, like `proTrial` or `publishPro`.',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be "menu" for the Pro trial CTA in Publish app shell menu.',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA? If setting the value for the first time, set to "1".',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'What app view is the CTA located in? Examples would be, "composer" or "analyticsOverview" for Publish.',
        type: ['string', 'null'],
      },
      organizationId: {
        description:
          'The ID of the global organization for which the channel connection was attempted.',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the channel connection attempt occurred.',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: ['channel', 'cause', 'product', 'clientName', 'organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Channel Connection Failed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChannelConnectionGoalModalViewed' track call.
 *
 * @param ChannelConnectionGoalModalViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function channelConnectionGoalModalViewed(
  props: ChannelConnectionGoalModalViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Channel_Connection_Goal_Modal_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a customer views the Channel Connection Goal Modal',
    properties: {
      channelId: {
        description: 'The active channel of the user that took the action',
        type: ['string'],
      },
      channelService: {
        description: 'The service of the channel that took the action',
        pattern:
          'facebook|twitter|tiktok|googlebusiness|mastodon|linkedin|instagram|pinterest|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      channelUsername: {
        description: 'The username of the channel that took the action',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        pattern: 'publishWeb|publishIos|publishAndroid|core|bufferWeb',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID the channel belongs to',
        type: ['string'],
      },
    },
    required: [
      'organizationId',
      'channelId',
      'channelUsername',
      'channelService',
      'clientName',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Channel Connection Goal Modal Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChannelConnectionOnboardingModalViewed' track call.
 *
 * @param ChannelConnectionOnboardingModalViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function channelConnectionOnboardingModalViewed(
  props: ChannelConnectionOnboardingModalViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Channel_Connection_Onboarding_Modal_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When did the user view the channel connection onboarding modal?',
    properties: {
      clientName: {
        description:
          'Which client was the user in when they opened this modal?',
        type: ['string', 'null'],
      },
      cta: {
        description:
          'If the user navigated to this page from a CTA on another Buffer page, which call to action was it?',
        type: ['string', 'null'],
      },
      ctaApp: {
        description:
          'Which product or website is the CTA located in? Examples would be either the product, like `publish`, or the website, like `bufferSite`',
        type: ['string', 'null'],
      },
      ctaButton: {
        description: 'What is the name or action of the CTA?',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in Publish app shell menu',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA?  If setting the value for the first time, set to `1`',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'What website page or app view is the CTA located?  Examples would be, `composer` or `analyticsOverview` for Publish, and `pricingPublish` for the Publish pricing page on the Buffer marketing site',
        type: ['string', 'null'],
      },
      currentPlan: {
        description:
          'The current plan of the user at time of viewing the channel connection onboarding modal.',
        type: ['string', 'null'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the channel connection onboarding modal.',
        type: ['string', 'null'],
      },
    },
    required: ['organizationId', 'clientName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Channel Connection Onboarding Modal Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChannelConnectionProfileSelectorViewed' track call.
 *
 * @param ChannelConnectionProfileSelectorViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function channelConnectionProfileSelectorViewed(
  props: ChannelConnectionProfileSelectorViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Channel_Connection_Profile_Selector_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user views the Profile Selector',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        pattern:
          'facebook|twitter|tiktok|googlebusiness|mastodon|linkedin|instagram|pinterest|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        pattern: 'publishWeb|publishIos|publishAndroid|core|bufferWeb',
        type: ['string'],
      },
      currentChannelQuantity: {
        description: "The number of channels connected to the user's account",
        type: ['number', 'null'],
      },
      isAtPlanLimit: {
        description: 'Is the user at their plan limit?',
        type: ['boolean', 'null'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the Profile Selector',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the Profile Selector was viewed (ex. publish, buffer)',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: ['organizationId', 'channel'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Channel Connection Profile Selector Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChannelConnectionProfileTypeSelectionViewed' track call.
 *
 * @param ChannelConnectionProfileTypeSelectionViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function channelConnectionProfileTypeSelectionViewed(
  props: ChannelConnectionProfileTypeSelectionViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Channel_Connection_Profile_Type_Selection_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user views the Profile Type Selection Modal',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        pattern:
          'facebook|twitter|tiktok|googlebusiness|mastodon|linkedin|instagram|pinterest|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        pattern: 'publishWeb|publishIos|publishAndroid|core|bufferWeb',
        type: ['string'],
      },
      currentChannelQuantity: {
        description: "The number of channels connected to the user's account",
        type: ['number', 'null'],
      },
      isAtPlanLimit: {
        description: 'Is the user at their plan limit?',
        type: ['boolean', 'null'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the Profile Type Selection Modal',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the modal was viewed (ex. publish, buffer)',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: ['organizationId', 'channel'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Channel Connection Profile Type Selection Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChannelConnectionStarted' track call.
 *
 * @param ChannelConnectionStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function channelConnectionStarted(
  props: ChannelConnectionStarted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Channel_Connection_Started',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'This event is fired as soon as a user selects a channel when connecting a new channel. This differs from the current implementation of Channel Connection Attempted which is currently fired either when the button is selected, or for channels with a "Choose" page, it is fired after the user has authenticated and authorized Buffer when viewing the page to select a Business Profile, Page or Group.',
    properties: {
      apiVersion: {
        description: 'API Version this channel uses',
        type: ['string'],
      },
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string'],
      },
      channelType: {
        description:
          'The type of channel the post is created for, ex. "page", "group"',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        pattern: 'publishWeb|publishIos|publishAndroid|core|bufferWeb',
        type: ['string'],
      },
      cta: {
        description:
          'Which call to action did the user click to attempt the profile connection?',
        type: ['string', 'null'],
      },
      ctaApp: {
        description:
          'Which product is the CTA located in? Examples would be either the product, like "publish".',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA? In many cases it makes sense to describe the intended result of the CTA, like `proTrial` or `publishPro`.',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be "menu" for the Pro trial CTA in Publish app shell menu.',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA? If setting the value for the first time, set to "1".',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'What app view is the CTA located in? Examples would be, "composer" or "analyticsOverview" for Publish.',
        type: ['string', 'null'],
      },
      currentChannelQuantity: {
        description: "The number of channels connected to the user's account",
        type: ['number', 'null'],
      },
      isAtPlanLimit: {
        description: 'Is the user at their plan limit?',
        type: ['boolean', 'null'],
      },
      organizationId: {
        description:
          'The ID of the global organization for which the channel connection was attempted.',
        type: ['string'],
      },
      product: {
        description: 'The product for which the channel was connected',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      upgradePathName: {
        description: 'The name of the upgrade path',
        type: ['string', 'null'],
      },
    },
    required: ['channel', 'product', 'clientName', 'organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Channel Connection Started',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChannelConversionAborted' track call.
 *
 * @param ChannelConversionAborted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function channelConversionAborted(
  props: ChannelConversionAborted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Channel_Conversion_Aborted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user aborts a channel conversion',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        pattern: 'instagram',
        type: ['string'],
      },
      organizationId: {
        description:
          'The ID of the global organization that the channel was connected to.',
        type: ['string'],
      },
    },
    required: ['channel'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Channel Conversion Aborted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChannelConversionAttempted' track call.
 *
 * @param ChannelConversionAttempted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function channelConversionAttempted(
  props: ChannelConversionAttempted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Channel_Conversion_Attempted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'This event is triggered when a channel conversion is attempted.',
    properties: {
      channel: {
        description: "The channel's service",
        pattern: 'instagram',
        type: ['string'],
      },
      channelId: { description: 'The channel id', type: ['string'] },
      channelType: {
        description: 'The type of the channel',
        pattern: 'business',
        type: ['string', 'null'],
      },
      channelUsername: {
        description: 'What is the username of the channel?',
        type: ['string', 'null'],
      },
      clientName: {
        description: 'The client in which the event occurred.',
        pattern: 'core|publishIos|publishAndroid',
        type: ['string', 'null'],
      },
      organizationId: {
        description: 'The organizationId that the channel belongs to.',
        type: ['string'],
      },
      serviceId: {
        description: "The service's own id for this channel",
        type: ['string'],
      },
    },
    required: ['channel', 'channelId', 'serviceId', 'organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Channel Conversion Attempted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChannelConversionCanceledByUser' track call.
 *
 * @param ChannelConversionCanceledByUser props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function channelConversionCanceledByUser(
  props: ChannelConversionCanceledByUser,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Channel_Conversion_Canceled_By_User',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user cancels a channel conversion from the service',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        pattern: 'instagram',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        pattern: 'publishWeb|publishIos|publishAndroid',
        type: ['string'],
      },
      organizationId: {
        description:
          'The ID of the global organization that the channel was connected to.',
        type: ['string'],
      },
    },
    required: ['channel'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Channel Conversion Canceled By User',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChannelConversionDialogViewed' track call.
 *
 * @param ChannelConversionDialogViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function channelConversionDialogViewed(
  props: ChannelConversionDialogViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Channel_Conversion_Dialog_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When the convert channel dialog is viewed',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        pattern: 'instagram',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      organizationId: {
        description:
          'The ID of the global organization that the channel was connected to.',
        type: ['string'],
      },
    },
    required: ['channel', 'channelId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Channel Conversion Dialog Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChannelConversionFailed' track call.
 *
 * @param ChannelConversionFailed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function channelConversionFailed(
  props: ChannelConversionFailed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Channel_Conversion_Failed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'This event is triggered when a channel conversion fails.',
    properties: {
      channel: {
        description: "The channel's service",
        pattern: 'instagram',
        type: ['string'],
      },
      channelId: { description: 'The channel id', type: ['string'] },
      channelType: {
        description: 'The type of the channel',
        pattern: 'business',
        type: ['string', 'null'],
      },
      channelUsername: {
        description: 'What is the username of the channel?',
        type: ['string', 'null'],
      },
      clientName: {
        description: 'The client in which the event occurred.',
        pattern: 'core|publishIos|publishAndroid',
        type: ['string', 'null'],
      },
      error: {
        description: 'The cause of the failure.',
        type: ['string', 'null'],
      },
      organizationId: {
        description: 'The organizationId that the channel belongs to.',
        type: ['string'],
      },
      serviceId: {
        description: "The service's own id for this channel",
        type: ['string'],
      },
    },
    required: ['channel', 'channelId', 'serviceId', 'organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Channel Conversion Failed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChannelConversionStarted' track call.
 *
 * @param ChannelConversionStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function channelConversionStarted(
  props: ChannelConversionStarted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Channel_Conversion_Started',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user starts a channel conversion',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        pattern: 'instagram',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        pattern: 'publishWeb|publishIos|publishAndroid',
        type: ['string'],
      },
      cta: {
        description:
          'Which call to action did the user click to connect the channel?',
        type: ['string', 'null'],
      },
      organizationId: {
        description:
          'The ID of the global organization that the channel was connected to.',
        type: ['string'],
      },
    },
    required: ['channel'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Channel Conversion Started',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChannelConversionSuccess' track call.
 *
 * @param ChannelConversionSuccess props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function channelConversionSuccess(
  props: ChannelConversionSuccess,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Channel_Conversion_Success',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'This event is triggered when a channel is successfully converted.',
    properties: {
      channel: {
        description: "The channel's service",
        pattern: 'instagram',
        type: ['string'],
      },
      channelId: { description: 'The channel id', type: ['string'] },
      channelType: {
        description: 'The type of the channel',
        pattern: 'business',
        type: ['string', 'null'],
      },
      channelUsername: {
        description: 'What is the username of the channel?',
        type: ['string', 'null'],
      },
      clientName: {
        description: 'The client in which the event occurred.',
        pattern: 'core|publishIos|publishAndroid',
        type: ['string', 'null'],
      },
      organizationId: {
        description: 'The organizationId that the channel belongs to.',
        type: ['string'],
      },
      serviceId: {
        description: "The service's own id for this channel",
        type: ['string'],
      },
    },
    required: ['channel', 'channelId', 'serviceId', 'organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Channel Conversion Success',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChannelDeselected' track call.
 *
 * @param ChannelDeselected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function channelDeselected(
  props: ChannelDeselected,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Channel_Deselected',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a channel is deselected by the user in Buffer.',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel",
        type: ['string'],
      },
      channelType: {
        description:
          'The channel type, ex. "page", "business", "profile", "group"',
        type: ['string'],
      },
      channelUsername: {
        description: 'What is the username of the channel?',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        pattern: 'publishWeb|publishIos|publishAndroid|core|bufferWeb',
        type: ['string'],
      },
      organizationId: {
        description:
          'The global organization id of the user that took this action',
        type: ['string'],
      },
      page: {
        description:
          'Where the channel was deselected, ex "calendar", "queue", "sidebar"',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred.',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: [
      'product',
      'organizationId',
      'channelId',
      'channelServiceId',
      'channelType',
      'channel',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Channel Deselected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChannelDetailPanelViewed' track call.
 *
 * @param ChannelDetailPanelViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function channelDetailPanelViewed(
  props: ChannelDetailPanelViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Channel_Detail_Panel_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user views a channel detail panel',
    properties: {
      clientName: {
        description: 'Which client was the user in when they took this action?',
        pattern: 'publishWeb|publishIos|publishAndroid|core|bufferWeb',
        type: ['string'],
      },
      service: {
        description: 'The service, ex. "facebook"',
        pattern:
          'facebook|twitter|tiktok|googlebusiness|mastodon|linkedin|instagram|pinterest|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
    },
    required: ['service'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Channel Detail Panel Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChannelDisconnected' track call.
 *
 * @param ChannelDisconnected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function channelDisconnected(
  props: ChannelDisconnected,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Channel_Disconnected',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When a user explicitly removes a channel from their account (not when we detect an invalid access token)',
    properties: {
      apiVersion: {
        description: 'API Version this channel uses',
        type: ['string'],
      },
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel",
        type: ['string'],
      },
      channelType: {
        description:
          'The type of channel the post is created for, ex. "page", "group"',
        type: ['string'],
      },
      channelUsername: {
        description: 'What is the username of the channel?',
        type: ['string'],
      },
      clientName: {
        description:
          'Which client was the user in when the channel was disconnected?',
        pattern: 'publishWeb|publishIos|publishAndroid|core|bufferWeb',
        type: ['string'],
      },
      organizationId: {
        description:
          'The ID of the global organization that the channel was disconnected from.',
        type: ['string'],
      },
      product: {
        description: 'The product for which the channel was connected',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: ['channel', 'channelId', 'product'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Channel Disconnected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChannelLinkToFacebookPageAborted' track call.
 *
 * @param ChannelLinkToFacebookPageAborted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function channelLinkToFacebookPageAborted(
  props: ChannelLinkToFacebookPageAborted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Channel_Link_To_Facebook_Page_Aborted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user aborts linking an Instagram channel to Facebook',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        pattern: 'instagram',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        pattern: 'publishWeb|publishIos|publishAndroid',
        type: ['string'],
      },
      organizationId: {
        description:
          'The ID of the global organization that the channel was connected to.',
        type: ['string'],
      },
    },
    required: ['channel', 'channelId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Channel Link To Facebook Page Aborted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChannelLinkToFacebookPageDialogViewed' track call.
 *
 * @param ChannelLinkToFacebookPageDialogViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function channelLinkToFacebookPageDialogViewed(
  props: ChannelLinkToFacebookPageDialogViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Channel_Link_To_Facebook_Page_Dialog_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When a user views the dialog to link an Instagram channel to a Facebook page',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        pattern: 'instagram',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        pattern: 'publishWeb|publishIos|publishAndroid',
        type: ['string'],
      },
      organizationId: {
        description:
          'The ID of the global organization that the channel was connected to.',
        type: ['string'],
      },
    },
    required: ['channel'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Channel Link To Facebook Page Dialog Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChannelLinkToFacebookPageStarted' track call.
 *
 * @param ChannelLinkToFacebookPageStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function channelLinkToFacebookPageStarted(
  props: ChannelLinkToFacebookPageStarted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Channel_Link_To_Facebook_Page_Started',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When a user starts to link an Instagram channel to a Facebook page',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        pattern: 'instagram',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        pattern: 'publishWeb|publishIos|publishAndroid',
        type: ['string'],
      },
      cta: {
        description:
          'Which call to action did the user click to trigger linking the channel to a Facebook page?',
        type: ['string', 'null'],
      },
      organizationId: {
        description:
          'The ID of the global organization that the channel was connected to.',
        type: ['string'],
      },
    },
    required: ['channel'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Channel Link To Facebook Page Started',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChannelLocked' track call.
 *
 * @param ChannelLocked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function channelLocked(
  props: ChannelLocked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Channel_Locked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a channel is locked',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        pattern:
          'facebook|twitter|tiktok|googlebusiness|mastodon|linkedin|instagram|pinterest|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel",
        type: ['string'],
      },
      channelType: {
        description:
          'The channel type, ex. "page", "business", "profile", "group", "creator"',
        type: ['string'],
      },
      channelUsername: {
        description: 'What is the username of the channel?',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        pattern: 'publishWeb|publishIos|publishAndroid|core|bufferWeb',
        type: ['string'],
      },
      isAtPlanLimit: {
        description:
          'Was the organization at or above their channel limit when the channel was connected.',
        type: ['boolean', 'null'],
      },
      organizationId: {
        description:
          'The ID of the global organization that the channel was connected to.',
        type: ['string'],
      },
      product: {
        description: 'The product the channel was connected to.',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: ['channel', 'channelId', 'product'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Channel Locked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChannelQueueEmptied' track call.
 *
 * @param ChannelQueueEmptied props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function channelQueueEmptied(
  props: ChannelQueueEmptied,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Channel_Queue_Emptied',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      "When a channel's (aka profile) queue sends out it's last post, resulting in that channel's queue being newly empty",
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel",
        type: ['string'],
      },
      channelType: {
        description:
          'The channel type, ex. "page", "business", "profile", "group", "creator"',
        type: ['string'],
      },
      channelUsername: {
        description: 'What is the username of the channel?',
        type: ['string'],
      },
      organizationId: {
        description:
          'The ID of the global organization that the channel was connected to.',
        type: ['string'],
      },
    },
    required: ['channel', 'channelId', 'organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Channel Queue Emptied',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChannelReconnected' track call.
 *
 * @param ChannelReconnected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function channelReconnected(
  props: ChannelReconnected,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Channel_Reconnected',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When a user reconnects, often after an access token is invalidated',
    properties: {
      apiVersion: {
        description: 'API Version this channel uses',
        type: ['string'],
      },
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel",
        type: ['string'],
      },
      channelType: {
        description:
          'The type of channel the post is created for, ex. "page", "group"',
        type: ['string'],
      },
      channelUsername: {
        description: 'What is the username of the channel?',
        type: ['string'],
      },
      clientName: {
        description:
          'Which client was the user in when the channel was reconnected?',
        pattern: 'publishWeb|publishIos|publishAndroid|core|bufferWeb',
        type: ['string'],
      },
      organizationId: {
        description:
          'The ID of the global organization that the channel was reconnected to.',
        type: ['string'],
      },
      pinterestNewApiMigrated: {
        description:
          'Used to check if the channel was migrated to new Pinterest API',
        type: ['boolean'],
      },
      product: {
        description: 'The product for which the channel was connected',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      twitterSubscriptionType: {
        description:
          'Twitter Subscription Type of a connected Twitter/X Channel',
        type: ['string'],
      },
    },
    required: ['channel', 'channelId', 'product'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Channel Reconnected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChannelRefreshAttempted' track call.
 *
 * @param ChannelRefreshAttempted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function channelRefreshAttempted(
  props: ChannelRefreshAttempted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Channel_Refresh_Attempted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'This event is currently triggered when the user click on the "Refresh" link for an existing channel.',
    properties: {
      apiVersion: {
        description: 'API Version this channel uses',
        type: ['string'],
      },
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel",
        type: ['string'],
      },
      channelType: {
        description:
          'The type of channel the post is created for, ex. "page", "group"',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        pattern: 'publishWeb|publishIos|publishAndroid|core|bufferWeb',
        type: ['string'],
      },
      cta: {
        description:
          'Which call to action brought the user to the channels page befor attempting the refresh? (cta read from channels page URL)',
        type: ['string', 'null'],
      },
      ctaApp: {
        description:
          'Which product is the CTA located in? Examples would be either the product, like "publish".',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA? In many cases it makes sense to describe the intended result of the CTA, like `proTrial` or `publishPro`.',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be "menu" for the Pro trial CTA in Publish app shell menu.',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA? If setting the value for the first time, set to "1".',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'What app view is the CTA located in? Examples would be, "composer" or "analyticsOverview" for Publish.',
        type: ['string', 'null'],
      },
      organizationId: {
        description:
          'The ID of the global organization for which the channel connection was attempted.',
        type: ['string'],
      },
      product: {
        description: 'The product for which the channel was connected',
        pattern: 'publish|analyze|engage|account|marketing|buffer',
        type: ['string'],
      },
    },
    required: [
      'channel',
      'channelId',
      'channelServiceId',
      'product',
      'clientName',
      'organizationId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Channel Refresh Attempted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChannelRefreshFailed' track call.
 *
 * @param ChannelRefreshFailed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function channelRefreshFailed(
  props: ChannelRefreshFailed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Channel_Refresh_Failed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a channel refresh attempt fails.',
    properties: {
      apiVersion: {
        description: 'API Version this channel uses',
        type: ['string'],
      },
      cause: {
        description: 'The cause of the failure (i.e. error message).',
        type: ['string'],
      },
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel",
        type: ['string'],
      },
      channelType: {
        description:
          'The type of channel the post is created for, ex. "page", "group"',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        pattern: 'publishWeb|publishIos|publishAndroid|core|bufferWeb',
        type: ['string'],
      },
      cta: {
        description:
          'Which call to action brought the user to the channels page before refresh attempted? (cta read from channels page URL)',
        type: ['string', 'null'],
      },
      ctaApp: {
        description:
          'Which product is the CTA located in? Examples would be either the product, like "publish".',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA? In many cases it makes sense to describe the intended result of the CTA, like `proTrial` or `publishPro`.',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be "menu" for the Pro trial CTA in Publish app shell menu.',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA? If setting the value for the first time, set to "1".',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'What app view is the CTA located in? Examples would be, "composer" or "analyticsOverview" for Publish.',
        type: ['string', 'null'],
      },
      organizationId: {
        description:
          'The ID of the global organization for which the channel connection was attempted.',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the channel connection attempt occurred.',
        pattern: 'publish|analyze|engage|account|marketing|buffer',
        type: ['string'],
      },
    },
    required: [
      'channel',
      'channelId',
      'channelServiceId',
      'cause',
      'product',
      'clientName',
      'organizationId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Channel Refresh Failed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChannelRefreshStarted' track call.
 *
 * @param ChannelRefreshStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function channelRefreshStarted(
  props: ChannelRefreshStarted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Channel_Refresh_Started',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When the user clicks Refresh or Reconnect to refresh their channel.',
    properties: {
      apiVersion: {
        description: 'API Version this channel uses',
        type: ['string'],
      },
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        pattern:
          'facebook|twitter|tiktok|googlebusiness|mastodon|linkedin|instagram|pinterest|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        pattern: 'publishWeb|publishIos|publishAndroid|core|bufferWeb',
        type: ['string'],
      },
      currentChannelQuantity: {
        description: "The number of channels connected to the user's account",
        type: ['number', 'null'],
      },
      isAtPlanLimit: {
        description: 'Is the user at their plan limit?',
        type: ['boolean', 'null'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that clicked to start the refresh',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the refresh was started (ex. publish, buffer)',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: ['organizationId', 'channel'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Channel Refresh Started',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChannelRefreshSuccessModalViewed' track call.
 *
 * @param ChannelRefreshSuccessModalViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function channelRefreshSuccessModalViewed(
  props: ChannelRefreshSuccessModalViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Channel_Refresh_Success_Modal_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user view the channel refresh success modal?',
    properties: {
      clientName: {
        description:
          'Which client was the user in when they opened this modal?',
        type: ['string', 'null'],
      },
      cta: {
        description:
          'If the user navigated to this page from a CTA on another Buffer page, which call to action was it?',
        type: ['string', 'null'],
      },
      ctaApp: {
        description:
          'Which product or website is the CTA located in? Examples would be either the product, like `publish`, or the website, like `bufferSite`',
        type: ['string', 'null'],
      },
      ctaButton: {
        description: 'What is the name or action of the CTA?',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in Publish app shell menu',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA?  If setting the value for the first time, set to `1`',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'What website page or app view is the CTA located?  Examples would be, `composer` or `analyticsOverview` for Publish, and `pricingPublish` for the Publish pricing page on the Buffer marketing site',
        type: ['string', 'null'],
      },
      currentPlan: {
        description:
          'The current plan of the user at time of viewing the channel refresh success modal.',
        type: ['string', 'null'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the channel refresh success modal.',
        type: ['string', 'null'],
      },
    },
    required: ['organizationId', 'clientName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Channel Refresh Success Modal Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChannelSelected' track call.
 *
 * @param ChannelSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function channelSelected(
  props: ChannelSelected,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Channel_Selected',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a channel is selected by the user in Buffer.',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel",
        type: ['string'],
      },
      channelType: {
        description:
          'The channel type, ex. "page", "business", "profile", "group"',
        type: ['string'],
      },
      channelUsername: {
        description: 'What is the username of the channel?',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        pattern: 'publishWeb|publishIos|publishAndroid|core|bufferWeb',
        type: ['string'],
      },
      organizationId: {
        description:
          'The global organization id of the user that took this action',
        type: ['string'],
      },
      page: {
        description:
          'Where the channel was selected, ex "calendar", "queue", "sidebar"',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred.',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: [
      'product',
      'organizationId',
      'channelId',
      'channelServiceId',
      'channelType',
      'channel',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Channel Selected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChannelStorefrontMoreDetailsClicked' track call.
 *
 * @param ChannelStorefrontMoreDetailsClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function channelStorefrontMoreDetailsClicked(
  props: ChannelStorefrontMoreDetailsClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Channel_Storefront_More_Details_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When did the user click the "More Details" button/icon on the channel storefront?',
    properties: {
      clientName: {
        description:
          'Which client was the user in when they clicked "More Details"?',
        type: ['string', 'null'],
      },
      currentPlan: {
        description:
          'The current plan of the user at time of clicking "More Details".',
        type: ['string', 'null'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that clicked "More Details".',
        type: ['string', 'null'],
      },
      service: {
        description:
          'The service name of the channel that the user clicked "More Details" for.',
        pattern:
          'facebook|twitter|tiktok|googlebusiness|mastodon|linkedin|instagram|pinterest|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
    },
    required: ['organizationId', 'service', 'clientName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Channel Storefront More Details Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChannelStorefrontViewed' track call.
 *
 * @param ChannelStorefrontViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function channelStorefrontViewed(
  props: ChannelStorefrontViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Channel_Storefront_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user view the channel storefront?',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      clientName: {
        description:
          'Which client was the user in when they opened this modal?',
        type: ['string', 'null'],
      },
      currentChannelQuantity: {
        description: "The number of channels connected to the user's account",
        type: ['number', 'null'],
      },
      currentPlan: {
        description:
          'The current plan of the user at time of viewing the channel storefront.',
        type: ['string', 'null'],
      },
      isAtPlanLimit: {
        description: 'Is the user at their plan limit?',
        type: ['boolean', 'null'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the channel storefront.',
        type: ['string', 'null'],
      },
    },
    required: ['organizationId', 'clientName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Channel Storefront Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChannelTabFilterClicked' track call.
 *
 * @param ChannelTabFilterClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function channelTabFilterClicked(
  props: ChannelTabFilterClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Channel_Tab_Filter_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user clicks on a channel tab filter',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        pattern: 'publishWeb|publishIos|publishAndroid|core|bufferWeb',
        type: ['string'],
      },
      organizationId: {
        description: 'The global organization id of the user',
        type: ['string'],
      },
      tabName: {
        description: 'Which channel filter tab was clicked?',
        type: ['string'],
      },
    },
    required: ['organizationId', 'tabName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Channel Tab Filter Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChannelUnauthorized' track call.
 *
 * @param ChannelUnauthorized props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function channelUnauthorized(
  props: ChannelUnauthorized,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Channel_Unauthorized',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      "When we first detect that a channel’s access token has become invalid. This usually occurs when we try to publish a post or check a channel's analytics.",
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel",
        type: ['string'],
      },
      channelType: {
        description:
          'The type of channel the post is created for, ex. "page", "group"',
        type: ['string'],
      },
      channelUsername: {
        description: 'What is the username of the channel?',
        type: ['string'],
      },
      clientName: {
        description:
          'Which client was the user in when this event was triggered?',
        pattern: 'publishWeb|publishIos|publishAndroid|core|bufferWeb',
        type: ['string'],
      },
      organizationId: {
        description: 'The ID of the organization that owns the channel.',
        type: ['string'],
      },
      product: {
        description: 'The product for which the channel was connected',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: ['channel', 'channelId', 'product'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Channel Unauthorized',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChannelUnlockModalViewed' track call.
 *
 * @param ChannelUnlockModalViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function channelUnlockModalViewed(
  props: ChannelUnlockModalViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Channel_Unlock_Modal_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user view the channel unlock modal?',
    properties: {
      clientName: {
        description:
          'Which client was the user in when they opened this modal?',
        type: ['string', 'null'],
      },
      currentPlan: {
        description:
          'The current plan of the user at time of viewing the channel connection onboarding modal.',
        type: ['string', 'null'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the channel connection onboarding modal.',
        type: ['string', 'null'],
      },
    },
    required: ['organizationId', 'clientName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Channel Unlock Modal Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChannelUnlocked' track call.
 *
 * @param ChannelUnlocked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function channelUnlocked(
  props: ChannelUnlocked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Channel_Unlocked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a channel is unlocked',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        pattern:
          'facebook|twitter|tiktok|googlebusiness|mastodon|linkedin|instagram|pinterest|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel",
        type: ['string'],
      },
      channelType: {
        description:
          'The channel type, ex. "page", "business", "profile", "group", "creator"',
        type: ['string'],
      },
      channelUsername: {
        description: 'What is the username of the channel?',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        pattern: 'publishWeb|publishIos|publishAndroid|core|bufferWeb',
        type: ['string'],
      },
      isAtPlanLimit: {
        description:
          'Was the organization at or above their channel limit when the channel was connected.',
        type: ['boolean', 'null'],
      },
      organizationId: {
        description:
          'The ID of the global organization that the channel was connected to.',
        type: ['string'],
      },
      product: {
        description: 'The product the channel was connected to.',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: ['channel', 'channelId', 'product'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Channel Unlocked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChannelUpdated' track call.
 *
 * @param ChannelUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function channelUpdated(
  props: ChannelUpdated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Channel_Updated',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When a channel service makes a change that requires us to change the information stored on the channel. For example, Instagram changed their internal IDs to a new format, and as a result, we updated the IG service ID we stored for the new ones, and that’s when we track this event.',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel",
        type: ['string'],
      },
      channelType: {
        description:
          'The channel type, ex. "page", "business", "profile", "group"',
        type: ['string'],
      },
      channelUsername: {
        description: 'What is the username of the channel?',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        pattern: 'publishWeb|publishIos|publishAndroid|core|bufferWeb',
        type: ['string'],
      },
      cta: {
        description:
          'Which call to action did the user click to update the channel?',
        type: ['string', 'null'],
      },
      ctaApp: {
        description:
          'Which product was the CTA located in? Example would be `publish`',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA?  In many cases it makes sense to describe the intended result of the CTA, like `proTrial` or `publishPro`',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in Publish app shell menu',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA?  If setting the value for the first time, set to `1`',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'Which app view is the CTA located?  Examples would be, `composer` or `analyticsOverview` for Publish',
        type: ['string', 'null'],
      },
      organizationId: {
        description: 'The ID of the global organization that owns the channel.',
        type: ['string'],
      },
      previousChannelServiceId: {
        description: 'What was the previous service id for this channel?',
        type: ['string'],
      },
      previousChannelType: {
        description:
          'What was the previous channel type, ex. "profile", "business"?',
        type: ['string', 'null'],
      },
      previousChannelUsername: {
        description: 'What was the previous username of the channel?',
        type: ['string'],
      },
      product: {
        description: 'The product for which the channel was updated',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: ['channel', 'channelId', 'product'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Channel Updated',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChannelsReordered' track call.
 *
 * @param ChannelsReordered props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function channelsReordered(
  props: ChannelsReordered,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Channels_Reordered',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a list of channels is reordered.',
    properties: {
      clientName: {
        description: 'Which client was the user in when they took this action?',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      organizationId: {
        description:
          'The global organization id of the user that took this action.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred.',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: ['product', 'organizationId', 'clientName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Channels Reordered',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChecklistItemClicked' track call.
 *
 * @param ChecklistItemClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function checklistItemClicked(
  props: ChecklistItemClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Checklist_Item_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user clicks on a checklist item',
    properties: {
      checklistId: { description: 'The ID of the checklist', type: ['number'] },
      checklistItem: {
        description: 'The checklist item on which the user clicked',
        type: ['string'],
      },
      isCompleted: {
        description: 'The checklist item completion status when clicked',
        type: ['boolean', 'null'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      qtyItemsCompleted: {
        description: 'How many checklist items has the user completed?',
        type: ['number', 'null'],
      },
    },
    required: ['organizationId', 'checklistId', 'checklistItem', 'isCompleted'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Checklist Item Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChecklistModalViewed' track call.
 *
 * @param ChecklistModalViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function checklistModalViewed(
  props: ChecklistModalViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Checklist_Modal_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user views the checklist complete modal',
    properties: {
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
    },
    required: ['organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Checklist Modal Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChurnSurveyCTAClicked' track call.
 *
 * @param ChurnSurveyCTAClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function churnSurveyCTAClicked(
  props: ChurnSurveyCTAClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Churn_Survey_CTA_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did a user click on a CTA on the churn survey modal?',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      ctaName: {
        description: 'The CTA clicked on the churn survey modal',
        type: ['string'],
      },
      organizationId: {
        description: 'The global organization ID of the user',
        type: ['string'],
      },
    },
    required: ['organizationId', 'ctaName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Churn Survey CTA Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChurnSurveyCompleted' track call.
 *
 * @param ChurnSurveyCompleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function churnSurveyCompleted(
  props: ChurnSurveyCompleted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Churn_Survey_Completed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When a user completes a churn survey for a particular product',
    properties: {
      billingPlan: {
        description: 'The plan id of the subscription that was cancelled.',
        type: ['string', 'null'],
      },
      details: {
        description:
          'A free form text field where the user can add more context for them cancelling their subscription',
        type: ['string'],
      },
      organizationId: {
        description:
          'The organization ID of the user that completed the survey',
        type: ['string'],
      },
      product: {
        description: 'What product did this event occur for?',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      reason: {
        description: "What's the reason for the user churning",
        type: ['string'],
      },
      wasOnTrial: {
        description:
          'Was the user on a trial when the subscription was canceled?',
        type: ['boolean', 'null'],
      },
    },
    required: ['product', 'reason'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Churn Survey Completed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ChurnSurveyViewed' track call.
 *
 * @param ChurnSurveyViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function churnSurveyViewed(
  props: ChurnSurveyViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Churn_Survey_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did a user view the churn survey modal?',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      organizationId: {
        description: 'The global organization ID of the user',
        type: ['string'],
      },
    },
    required: ['organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Churn Survey Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'CommentDeleted' track call.
 *
 * @param CommentDeleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function commentDeleted(
  props: CommentDeleted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Comment_Deleted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a comment is deleted in the engagement tool.',
    properties: {
      channel: {
        description: 'The channel that the comment belongs to, e.g. "facebook"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel.',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel.",
        type: ['string'],
      },
      channelUsername: {
        description: 'What is the username of the channel?',
        type: ['string'],
      },
      commentId: {
        description: 'The unique identifier of the comment.',
        type: ['string'],
      },
      hasLabel: {
        description: 'Does the comment have any labels?',
        type: ['boolean'],
      },
      labelTypes: {
        description:
          'The types of lables associated with the comment (e.g. "upset/order/question")',
        type: ['array'],
      },
      organizationId: {
        description:
          'The global organization id of the user that owns this channel',
        type: ['string'],
      },
      postId: {
        description: 'The unique identifier of the post.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred.',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      usedHotKey: {
        description: 'Was a hotkey used to delete this comment?',
        type: ['boolean'],
      },
    },
    required: [
      'product',
      'organizationId',
      'postId',
      'commentId',
      'hasLabel',
      'labelTypes',
      'channelId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Comment Deleted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'CommentDismissed' track call.
 *
 * @param CommentDismissed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function commentDismissed(
  props: CommentDismissed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Comment_Dismissed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a comment is marked as dismissed.',
    properties: {
      channel: {
        description: 'The channel that the comment belongs to, e.g. "facebook"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel.',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel.",
        type: ['string'],
      },
      channelUsername: {
        description: 'What is the username of the channel?',
        type: ['string'],
      },
      commentId: {
        description: 'The unique identifier of the comment.',
        type: ['string'],
      },
      hasLabel: {
        description: 'Does the comment have any labels?',
        type: ['boolean'],
      },
      labelTypes: {
        description:
          'The types of lables associated with the comment (e.g. "upset/order/question")',
        type: ['array'],
      },
      organizationId: {
        description:
          'The global organization id of the user that owns this channel',
        type: ['string'],
      },
      postId: {
        description: 'The unique identifier of the post.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred.',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      totalComments: {
        description: 'The total number of comments for the post.',
        type: ['number'],
      },
      totalUnansweredComments: {
        description:
          'The total number of unanswered comments remaining for the post.',
        type: ['number'],
      },
      usedHotKey: {
        description: 'Was a hotkey used to mark this comment as dismissed?',
        type: ['boolean'],
      },
    },
    required: [
      'product',
      'organizationId',
      'postId',
      'commentId',
      'totalComments',
      'totalUnansweredComments',
      'hasLabel',
      'labelTypes',
      'usedHotKey',
      'channelId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Comment Dismissed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'CommentFailed' track call.
 *
 * @param CommentFailed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function commentFailed(
  props: CommentFailed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Comment_Failed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When there is an error replying to a comment',
    properties: {
      channel: {
        description: 'The channel of the selected profile, ex. “instagram”.',
        type: ['string'],
      },
      clientName: {
        description: 'The name of the client in which the event occurred.',
        type: ['string'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that triggered the event.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred, ex. engage.',
        type: ['string'],
      },
    },
    required: ['product', 'organizationId', 'clientName', 'channel'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Comment Failed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'CommentMarkedAsSpam' track call.
 *
 * @param CommentMarkedAsSpam props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function commentMarkedAsSpam(
  props: CommentMarkedAsSpam,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Comment_Marked_As_Spam',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a comment is marked as spam in the engagement tool.',
    properties: {
      channel: {
        description: 'The channel that the comment belongs to, e.g. "facebook"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel.',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel.",
        type: ['string'],
      },
      channelUsername: {
        description: 'What is the username of the channel?',
        type: ['string'],
      },
      commentId: {
        description: 'The unique identifier of the comment.',
        type: ['string'],
      },
      hasLabel: {
        description: 'Does the comment have any labels?',
        type: ['boolean'],
      },
      labelTypes: {
        description:
          'The types of lables associated with the comment (e.g. "upset/order/question")',
        type: ['array'],
      },
      organizationId: {
        description:
          'The global organization id of the user that owns this channel',
        type: ['string'],
      },
      postId: {
        description: 'The unique identifier of the post.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred.',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      usedHotKey: {
        description: 'Was a hotkey used to mark this comment as spam?',
        type: ['boolean'],
      },
    },
    required: [
      'product',
      'organizationId',
      'postId',
      'commentId',
      'hasLabel',
      'labelTypes',
      'usedHotKey',
      'channelId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Comment Marked As Spam',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'CommentReplied' track call.
 *
 * @param CommentReplied props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function commentReplied(
  props: CommentReplied,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Comment_Replied',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a comment is replied to.',
    properties: {
      channel: {
        description: 'The channel that the comment belongs to, e.g. "facebook"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel.',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel.",
        type: ['string'],
      },
      channelUsername: {
        description: 'What is the username of the channel?',
        type: ['string'],
      },
      commentId: {
        description: 'The unique identifier of the comment.',
        type: ['string'],
      },
      hasEmoji: {
        description: 'Does the response include an emoji?',
        type: ['boolean'],
      },
      hasLabel: {
        description: 'Does the comment have any labels?',
        type: ['boolean'],
      },
      labelTypes: {
        description:
          'The types of lables associated with the comment (e.g. "upset/order/question")',
        type: ['array'],
      },
      organizationId: {
        description:
          'The global organization id of the user that took this action',
        type: ['string'],
      },
      postId: {
        description: 'The unique identifier of the post.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred.',
        pattern: 'engage',
        type: ['string'],
      },
      totalComments: {
        description: 'The total number of comments for the post.',
        type: ['number'],
      },
      totalUnansweredComments: {
        description:
          'The total number of unanswered comments remaining for the post.',
        type: ['number'],
      },
      usedEmojiPicker: {
        description: 'Did the user use the emoji picker?',
        type: ['boolean'],
      },
    },
    required: [
      'product',
      'organizationId',
      'postId',
      'commentId',
      'totalComments',
      'totalUnansweredComments',
      'hasLabel',
      'labelTypes',
      'hasEmoji',
      'usedEmojiPicker',
      'channelId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Comment Replied',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'CommentSelected' track call.
 *
 * @param CommentSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function commentSelected(
  props: CommentSelected,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Comment_Selected',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a comment is selected.',
    properties: {
      channel: {
        description: 'The channel that the comment belongs to, e.g. "facebook"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel.',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel.",
        type: ['string'],
      },
      channelUsername: {
        description: 'What is the username of the channel?',
        type: ['string'],
      },
      commentId: {
        description: 'The unique identifier of the comment.',
        type: ['string'],
      },
      hasLabel: {
        description: 'Does the comment have any labels?',
        type: ['boolean'],
      },
      labelTypes: {
        description:
          'The types of lables associated with the comment (e.g. "upset/order/question")',
        type: ['array'],
      },
      organizationId: {
        description:
          'The global organization id of the user that owns the channel.',
        type: ['string'],
      },
      postId: {
        description: 'The unique identifier of the post.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred.',
        pattern: 'engage',
        type: ['string'],
      },
      usedHotKey: {
        description: 'Was a hotkey used to mark this comment as read?',
        type: ['boolean'],
      },
    },
    required: [
      'product',
      'organizationId',
      'postId',
      'commentId',
      'hasLabel',
      'labelTypes',
      'usedHotKey',
      'channelId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Comment Selected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'CommunityJoined' track call.
 *
 * @param CommunityJoined props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function communityJoined(
  props: CommunityJoined,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Community_Joined',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'A user joined our Mobilize commmunity portal',
    properties: {
      _: {
        type: 'null',
        $id: '/properties/_',
        description:
          'This property is added as a workaround to generate type for events without properties',
      },
    },
    required: ['properties'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Community Joined',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ComponentRendered' track call.
 *
 * @param ComponentRendered props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function componentRendered(
  props: ComponentRendered,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Component_Rendered',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Track when a specific component is rendered, always use with useComponentRendered hook to ensure events are throttled',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      channel: {
        description:
          'Channel of the page, if applicable (e.g., ""facebook"", ""instagram"", etc.)',
        type: ['string', 'null'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string', 'null'],
      },
      channelServiceId: {
        description: 'The id of the channel on the given service',
        type: ['string', 'null'],
      },
      channelType: {
        description: 'What is the type of channel? ex. "page", "group"',
        type: ['string', 'null'],
      },
      cta: {
        description:
          'If the user navigated to this page from a CTA on another Buffer page, which call to action was it?',
        type: ['string', 'null'],
      },
      isDeprecated: {
        description: 'Is the component deprecated?',
        type: ['boolean', 'null'],
      },
      name: {
        description:
          'Human readable name of the page (e.g., ""<Composer />"", ""<LegacyCalendar />"", etc.)',
        type: ['string', 'null'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the page.',
        type: ['string', 'null'],
      },
      path: {
        description:
          'The path typically refers to a file or directory on the web server.',
        type: ['string', 'null'],
      },
      platform: {
        description:
          'The platform on which the page view occurred (e.g. ""classic"", ""new_publish"", ""marketing"", ""ios"")',
        type: ['string', 'null'],
      },
      product: {
        description:
          'The product in which the page view occurred (e.g. ""publish"", ""analyze"")',
        type: ['string'],
      },
      referralCode: {
        description:
          'At time of event, does the user have a unique ReferralCode cookie in their session?',
        type: ['string', 'null'],
      },
      referrer: {
        description:
          'The address of the webpage which is linked to the resource being requested. By checking the referrer, the new webpage can see where the request originated.',
        type: ['string', 'null'],
      },
      search: {
        description: 'Anything after and including the query string.',
        type: ['string', 'null'],
      },
      title: {
        description:
          'The text that is visible on the browser title bar (or tab bar) which matches the <title></title> html tag that should be on all pages.',
        type: ['string', 'null'],
      },
      url: { description: 'The address of the page.', type: ['string'] },
    },
    required: ['path'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Component Rendered',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ComposerCustomizerOpened' track call.
 *
 * @param ComposerCustomizerOpened props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function composerCustomizerOpened(
  props: ComposerCustomizerOpened,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Composer_Customizer_Opened',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a new user opens the Customizer within the composer.',
    properties: {
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        type: ['string'],
      },
      cta: {
        description:
          'Which call to action did the user click to get to open the composer?',
        type: ['string'],
      },
      ctaApp: {
        description:
          'Which product is the CTA located in? Examples would be `publish`.',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA? In many cases it makes sense to describe the intended result of the CTA, like `createAPost` or `publishProTrial`.',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in Publish app shell menu.',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA? If setting the value for the first time, set to `1`.',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'What app view is the CTA located in? Examples would be `publishDashboard` or `analyticsOverview` for Publish.',
        type: ['string', 'null'],
      },
      organizationId: {
        description: 'The organization id of the user that took the action',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occurred',
        pattern: 'publish',
        type: ['string'],
      },
    },
    required: ['product', 'organizationId', 'clientName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Composer Customizer Opened',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ComposerDiscarded' track call.
 *
 * @param ComposerDiscarded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function composerDiscarded(
  props: ComposerDiscarded,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Composer_Discarded',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user closes the Publish composer by discarding it.',
    properties: {
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization id of the user that took the action',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occurred',
        pattern: 'publish',
        type: ['string'],
      },
    },
    required: ['product', 'organizationId', 'clientName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Composer Discarded',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ComposerMediaReordered' track call.
 *
 * @param ComposerMediaReordered props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function composerMediaReordered(
  props: ComposerMediaReordered,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Composer_Media_Reordered',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'A user has reordered the media in the composer',
    properties: {
      channel: {
        description: 'The channel type where the media was reordered',
        type: ['string'],
      },
      channelId: {
        description: 'The channel id where the media was reordered',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when the Post was created?',
        type: ['string'],
      },
      isDraft: { description: 'If the post is a draft', type: ['boolean'] },
      isReminder: {
        description: 'If the post is a reminder',
        type: ['boolean'],
      },
      mediaCount: {
        description: 'Count of attachmed media (images or videos)',
        type: ['number'],
      },
      orderSource: {
        description: 'Initial position of the reordered image',
        type: ['number'],
      },
      orderTarget: {
        description: 'Target position of the reordered image',
        type: ['number'],
      },
      organizationId: {
        description: 'The global organization id that the channel belongs to.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occured',
        pattern: 'publish',
        type: ['string'],
      },
      updateType: {
        description: 'Type of the update, e.g. story or reel',
        type: ['string'],
      },
    },
    required: [
      'product',
      'clientName',
      'organizationId',
      'channelId',
      'channel',
      'isDraft',
      'isReminder',
      'updateType',
      'mediaCount',
      'orderSource',
      'orderTarget',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Composer Media Reordered',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ComposerOpened' track call.
 *
 * @param ComposerOpened props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function composerOpened(
  props: ComposerOpened,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Composer_Opened',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a new user opens the Publish composer.',
    properties: {
      channelsConnected: {
        description: "How many channels are connected to the user's account?",
        type: ['integer', 'null'],
      },
      channelsSelected: {
        description: 'How many channels are selected in the composer?',
        type: ['integer', 'null'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        type: ['string'],
      },
      cta: {
        description:
          'Which call to action did the user click to get to open the composer?',
        type: ['string'],
      },
      ctaApp: {
        description:
          'Which product is the CTA located in? Examples would be `publish`.',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA? In many cases it makes sense to describe the intended result of the CTA, like `createAPost` or `publishProTrial`.',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in Publish app shell menu.',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA? If setting the value for the first time, set to `1`.',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'What app view is the CTA located in? Examples would be `publishDashboard` or `analyticsOverview` for Publish.',
        type: ['string', 'null'],
      },
      organizationId: {
        description: 'The organization id of the user that took the action',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occurred',
        pattern: 'publish',
        type: ['string'],
      },
    },
    required: ['product', 'organizationId', 'clientName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Composer Opened',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ComposerToolTipViewed' track call.
 *
 * @param ComposerToolTipViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function composerToolTipViewed(
  props: ComposerToolTipViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Composer_Tool_Tip_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'A tool tip was presented in the initial composer experience.',
    properties: {
      clientId: {
        description:
          'What was the unique identifier of the client the user was in when they added the widget?',
        type: ['string', 'null'],
      },
      clientName: {
        description:
          'Which client was the user in when they added the widget? IE, `publishIos` or `publishAndroid`.',
        pattern: 'publishIos|publishAndroid',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that added the widget.',
        type: ['string'],
      },
      toolTip: {
        description: 'The contents of the tool tip.',
        type: ['string'],
      },
    },
    required: ['toolTip', 'clientName', 'organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Composer Tool Tip Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ConfirmCancellationCTAClicked' track call.
 *
 * @param ConfirmCancellationCTAClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function confirmCancellationCTAClicked(
  props: ConfirmCancellationCTAClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Confirm_Cancellation_CTA_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When did a user click on a CTA on the confirm cancellation modal?',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      ctaName: {
        description: 'The CTA clicked on the confirm cancellation modal',
        type: ['string'],
      },
      organizationId: {
        description: 'The global organization ID of the user',
        type: ['string'],
      },
    },
    required: ['organizationId', 'ctaName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Confirm Cancellation CTA Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ConfirmCancellationViewed' track call.
 *
 * @param ConfirmCancellationViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function confirmCancellationViewed(
  props: ConfirmCancellationViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Confirm_Cancellation_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When did a user click on a CTA on the confirm cancellation modal?',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      organizationId: {
        description: 'The global organization ID of the user',
        type: ['string'],
      },
    },
    required: ['organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Confirm Cancellation Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ConnectChannelUpgradeModalViewed' track call.
 *
 * @param ConnectChannelUpgradeModalViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function connectChannelUpgradeModalViewed(
  props: ConnectChannelUpgradeModalViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Connect_Channel_Upgrade_Modal_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When did the user view the billing connect channel upgrade module?',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      clientName: {
        description:
          'Which client was the user in when they opened this modal?',
        type: ['string', 'null'],
      },
      cta: {
        description:
          'If the user navigated to this page from a CTA on another Buffer page, which call to action was it?',
        type: ['string', 'null'],
      },
      ctaApp: {
        description:
          'Which product or website is the CTA located in? Examples would be either the product, like `publish`, or the website, like `bufferSite`',
        type: ['string', 'null'],
      },
      ctaButton: {
        description: 'What is the name or action of the CTA?',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in Publish app shell menu',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA?  If setting the value for the first time, set to `1`',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'What website page or app view is the CTA located?  Examples would be, `composer` or `analyticsOverview` for Publish, and `pricingPublish` for the Publish pricing page on the Buffer marketing site',
        type: ['string', 'null'],
      },
      currentPlan: {
        description:
          'The current plan of the user at time of viewing the connect channel upgrade modal.',
        type: ['string', 'null'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the connect channel upgrade modal.',
        type: ['string', 'null'],
      },
      product: {
        description: 'Which product did the user view the modal in?',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string', 'null'],
      },
      upgradePathName: {
        description: 'The name of the upgrade path',
        type: ['string', 'null'],
      },
    },
    required: ['organizationId', 'product', 'clientName', 'upgradePathName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Connect Channel Upgrade Modal Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ContentGenerationAccepted' track call.
 *
 * @param ContentGenerationAccepted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function contentGenerationAccepted(
  props: ContentGenerationAccepted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Content_Generation_Accepted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user accepts the content generated with AI',
    properties: {
      cta: {
        description: 'Which call to action did the user click',
        type: ['string', 'null'],
      },
      featureAction: {
        description:
          'The specific AI action that was used to generate the accepted content, e.g. generateText, summarizeText, etc.',
        type: ['string', 'null'],
      },
      organizationId: {
        description: 'The active organization of the user that took the action',
        type: ['string'],
      },
      type: {
        description: 'The type of content that was accepted',
        pattern: 'text|image',
        type: ['string'],
      },
    },
    required: ['type'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Content Generation Accepted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ContentGenerationCancelled' track call.
 *
 * @param ContentGenerationCancelled props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function contentGenerationCancelled(
  props: ContentGenerationCancelled,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Content_Generation_Cancelled',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user cancels the process of content generation',
    properties: {
      cta: {
        description: 'Which call to action did the user click',
        type: ['string', 'null'],
      },
      featureAction: {
        description:
          'The specific AI action that was used to generate before cancelling, e.g. generateText, summarizeText, etc.',
        type: ['string', 'null'],
      },
      organizationId: {
        description: 'The active organization of the user that took the action',
        type: ['string'],
      },
      type: {
        description:
          'The type of content that was being generated when it got cancelled',
        pattern: 'text|image',
        type: ['string'],
      },
    },
    required: ['organizationId', 'type'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Content Generation Cancelled',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ContentGenerationFailed' track call.
 *
 * @param ContentGenerationFailed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function contentGenerationFailed(
  props: ContentGenerationFailed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Content_Generation_Failed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When a user receives an error after a content generation request',
    properties: {
      aiModel: { description: 'The AI model used', type: ['string'] },
      cta: {
        description:
          'Which call to action did the user click to generate content',
        type: ['string', 'null'],
      },
      errorCode: {
        description: 'The code of the error received',
        type: ['string', 'null'],
      },
      errorType: {
        description: 'The type of the error received',
        type: ['string'],
      },
      featureAction: {
        description:
          'The specific AI action that trigger the content generation, e.g. generateText, summarizeText, etc.',
        type: ['string', 'null'],
      },
      marketingId: {
        description: 'The marketing ID of the user generated from buffer.com',
        type: ['string', 'null'],
      },
      organizationId: {
        description: 'The active organization of the user that took the action',
        type: ['string', 'null'],
      },
      provider: {
        description:
          'The 3rd party service that we use to generate content. Initially we will be using "openai", but there could be more, e.g. openai, stabilityai, etc.',
        type: ['string'],
      },
      requestSource: {
        description:
          'From where did the content generation request originate (via free tools, in-product, etc)',
        type: ['string'],
      },
      responseTime: {
        description:
          'The time it took until errored, from request to response, in seconds.',
        type: ['number'],
      },
      type: {
        description: 'The type of content requested',
        pattern: 'text|image',
        type: ['string'],
      },
      variations: {
        description: 'The number of variations requested for this type',
        type: ['number'],
      },
    },
    required: ['type', 'provider', 'variations', 'aiModel', 'errorType'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Content Generation Failed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ContentGenerationReceived' track call.
 *
 * @param ContentGenerationReceived props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function contentGenerationReceived(
  props: ContentGenerationReceived,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Content_Generation_Received',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user receives AI generated content after a request',
    properties: {
      aiModel: { description: 'The AI model used', type: ['string'] },
      completionTokens: {
        description:
          'The number of tokens the content generation used. This is applicable only to completion - text2text',
        type: ['number', 'null'],
      },
      cta: {
        description:
          'Which call to action did the user click to generate content',
        type: ['string', 'null'],
      },
      featureAction: {
        description:
          'The specific AI action that trigger the content generation, e.g. generateText, summarizeText, etc.',
        type: ['string', 'null'],
      },
      imageSize: {
        description:
          'The size of the generated images, e.g. "256x256", "1024x1024", etc. This is applicable only to images - text2image',
        type: ['string', 'null'],
      },
      marketingId: {
        description: 'The marketing ID of the user generated from buffer.com',
        type: ['string', 'null'],
      },
      organizationId: {
        description: 'The active organization of the user that took the action',
        type: ['string', 'null'],
      },
      promptTokens: {
        description:
          'The number of tokens the prompt used. This is applicable only to completion - text2text',
        type: ['number', 'null'],
      },
      provider: {
        description:
          'The 3rd party service that we use to generate content. Initially we will be using "openai", but there could be more, e.g. openai, stabilityai, etc.',
        type: ['string'],
      },
      requestSource: {
        description:
          'From where did the content generation request originate (via free tools, in-product, etc)',
        type: ['string'],
      },
      responseTime: {
        description:
          'The time it took to generate content, from request to response, in seconds.',
        type: ['number'],
      },
      totalTokens: {
        description:
          'The number of total tokens the content generation used (prompt + completion). This is applicable only to completion - text2text',
        type: ['number', 'null'],
      },
      type: {
        description: 'The type of content requested',
        pattern: 'text|image',
        type: ['string'],
      },
      variations: {
        description: 'The number of variations requested for this type',
        type: ['number'],
      },
    },
    required: ['type', 'provider', 'variations', 'aiModel'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Content Generation Received',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ContentGenerationRequested' track call.
 *
 * @param ContentGenerationRequested props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function contentGenerationRequested(
  props: ContentGenerationRequested,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Content_Generation_Requested',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user requests AI content generation',
    properties: {
      aiModel: { description: 'The AI model used', type: ['string'] },
      cta: {
        description:
          'Which call to action did the user click to generate content',
        type: ['string', 'null'],
      },
      featureAction: {
        description:
          'The specific AI action that trigger the content generation, e.g. generateText, summarizeText, etc.',
        type: ['string', 'null'],
      },
      marketingId: {
        description: 'The marketing ID of the user generated from buffer.com',
        type: ['string', 'null'],
      },
      organizationId: {
        description: 'The active organization of the user that took the action',
        type: ['string', 'null'],
      },
      provider: {
        description:
          'The 3rd party service that we use to generate content. Initially we will be using "openai", but there could be more, e.g. openai, stabilityai, etc.',
        type: ['string'],
      },
      requestSource: {
        description:
          'From where did the content generation request originate (via free tools, in-product, etc)',
        type: ['string'],
      },
      type: {
        description: 'The type of content requested',
        pattern: 'text|image',
        type: ['string'],
      },
      variations: {
        description: 'The number of variations requested for this type',
        type: ['number'],
      },
    },
    required: ['type', 'provider', 'variations', 'aiModel'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Content Generation Requested',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ContentGroupCreated' track call.
 *
 * @param ContentGroupCreated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function contentGroupCreated(
  props: ContentGroupCreated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Content_Group_Created',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a new content groups is created in the create space.',
    properties: {
      clientName: {
        description: 'The client in which the event occurred.',
        pattern:
          'publishWeb|publishIos|publishAndroid|core|bufferWeb|ios|android',
        type: ['string', 'null'],
      },
      contentGroupId: { description: 'The ID of the group.', type: ['string'] },
      contentGroupName: {
        description: 'The name of the group.',
        type: ['string'],
      },
      cta: {
        description: 'Which source/cta was used to trigger this event',
        type: ['string'],
      },
      ctaApp: {
        description: 'Which website is the CTA located in? Ex, `bufferSite`',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA?  In many cases it makes sense to describe the intended result of the CTA, like `proTrial` or `publishPro`',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be, `cards` for the Pro trial CTA in Publish pricing page main plan comparison section',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA?  If setting the value for the first time, set to `1`',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'Which website page is the CTA located?  Ex,  `pricingPublish` for the Publish pricing page',
        type: ['string', 'null'],
      },
      organizationId: {
        description: 'The global organization ID.',
        type: ['string'],
      },
      product: {
        description: 'Which product the event is triggered from',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: [
      'product',
      'organizationId',
      'contentGroupId',
      'contentGroupName',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Content Group Created',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ContentGroupDeleted' track call.
 *
 * @param ContentGroupDeleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function contentGroupDeleted(
  props: ContentGroupDeleted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Content_Group_Deleted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a new content groups is deleted in the create space.',
    properties: {
      clientName: {
        description: 'The client in which the event occurred.',
        pattern:
          'publishWeb|publishIos|publishAndroid|core|bufferWeb|ios|android',
        type: ['string', 'null'],
      },
      contentGroupId: { description: 'The ID of the group.', type: ['string'] },
      contentGroupName: {
        description: 'The name of the group.',
        type: ['string'],
      },
      cta: {
        description: 'Which source/cta was used to trigger this event',
        type: ['string'],
      },
      ctaApp: {
        description: 'Which website is the CTA located in? Ex, `bufferSite`',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA?  In many cases it makes sense to describe the intended result of the CTA, like `proTrial` or `publishPro`',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be, `cards` for the Pro trial CTA in Publish pricing page main plan comparison section',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA?  If setting the value for the first time, set to `1`',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'Which website page is the CTA located?  Ex,  `pricingPublish` for the Publish pricing page',
        type: ['string', 'null'],
      },
      organizationId: {
        description: 'The global organization ID.',
        type: ['string'],
      },
      product: {
        description: 'Which product the event is triggered from',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: [
      'product',
      'organizationId',
      'contentGroupId',
      'contentGroupName',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Content Group Deleted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ContentGroupMoved' track call.
 *
 * @param ContentGroupMoved props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function contentGroupMoved(
  props: ContentGroupMoved,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Content_Group_Moved',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a content content group is moved.',
    properties: {
      clientName: {
        description: 'The client in which the event occurred.',
        pattern:
          'publishWeb|publishIos|publishAndroid|core|bufferWeb|ios|android',
        type: ['string', 'null'],
      },
      contentGroupId: { description: 'The ID of the group.', type: ['string'] },
      contentGroupName: {
        description: 'The name of the group.',
        type: ['string'],
      },
      cta: {
        description: 'Which source/cta was used to trigger this event',
        type: ['string'],
      },
      ctaApp: {
        description: 'Which website is the CTA located in? Ex, `bufferSite`',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA?  In many cases it makes sense to describe the intended result of the CTA, like `proTrial` or `publishPro`',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be, `cards` for the Pro trial CTA in Publish pricing page main plan comparison section',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA?  If setting the value for the first time, set to `1`',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'Which website page is the CTA located?  Ex,  `pricingPublish` for the Publish pricing page',
        type: ['string', 'null'],
      },
      organizationId: {
        description: 'The global organization ID.',
        type: ['string'],
      },
      product: {
        description: 'Which product the event is triggered from',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: [
      'product',
      'organizationId',
      'contentGroupId',
      'contentGroupName',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Content Group Moved',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ContentGroupRenamed' track call.
 *
 * @param ContentGroupRenamed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function contentGroupRenamed(
  props: ContentGroupRenamed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Content_Group_Renamed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a new content groups is created in the create space.',
    properties: {
      clientName: {
        description: 'The client in which the event occurred.',
        pattern:
          'publishWeb|publishIos|publishAndroid|core|bufferWeb|ios|android',
        type: ['string', 'null'],
      },
      contentGroupId: { description: 'The ID of the group.', type: ['string'] },
      contentGroupName: {
        description: 'The new name of the group.',
        type: ['string'],
      },
      cta: {
        description: 'Which source/cta was used to trigger this event',
        type: ['string'],
      },
      ctaApp: {
        description: 'Which website is the CTA located in? Ex, `bufferSite`',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA?  In many cases it makes sense to describe the intended result of the CTA, like `proTrial` or `publishPro`',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be, `cards` for the Pro trial CTA in Publish pricing page main plan comparison section',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA?  If setting the value for the first time, set to `1`',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'Which website page is the CTA located?  Ex,  `pricingPublish` for the Publish pricing page',
        type: ['string', 'null'],
      },
      organizationId: {
        description: 'The global organization ID.',
        type: ['string'],
      },
      previousContentGroupName: {
        description: 'The name of the group before it was renamed.',
        type: ['string'],
      },
      product: {
        description: 'Which product the event is triggered from',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: [
      'product',
      'organizationId',
      'contentGroupId',
      'contentGroupName',
      'previousContentGroupName',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Content Group Renamed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'CreditNoteCreated' track call.
 *
 * @param CreditNoteCreated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function creditNoteCreated(
  props: CreditNoteCreated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Credit_Note_Created',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Was there a credit note created for this invoice?',
    properties: {
      creditNoteValue: {
        description: 'The dollar value of this credit note',
        type: ['number'],
      },
      customerId: {
        description: 'Unique identifier for the Stripe customer',
        type: ['string'],
      },
      cycle: {
        description:
          'The subscription payment interval (month or year) of the subscription associated with the invoice',
        type: ['string'],
      },
      organizationId: {
        description:
          'The ID of the organization for which the invoice was created',
        type: ['string'],
      },
      planId: {
        description:
          'Stripe plan id of the subscription associated with the invoice',
        type: ['string'],
      },
      planName: {
        description:
          'Human-readable plan name of the subscription associated with the invoice',
        type: ['string'],
      },
      receiptLink: {
        description: 'The URL of the credit note PDF',
        type: ['string'],
      },
      stripeEventId: {
        description: 'Stripe event id from the webhook event',
        type: ['string'],
      },
      subscriptionId: {
        description: 'Unique identifier for the Stripe subscription',
        type: ['string'],
      },
      taxAmount: {
        description:
          'The total tax amount (in percent) refunded in this credit note',
        type: ['number'],
      },
    },
    required: [
      'stripeEventId',
      'customerId',
      'subscriptionId',
      'organizationId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Credit Note Created',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'CustomBitlyConnectButtonClicked' track call.
 *
 * @param CustomBitlyConnectButtonClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function customBitlyConnectButtonClicked(
  props: CustomBitlyConnectButtonClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Custom_Bitly_Connect_Button_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When a user authorizes the bitly connection for custom shorten URLs.',
    properties: {
      channel: {
        description:
          'The channel that settings tab was opened for (ex. instagram, pinterest)',
        pattern:
          'twitter|facebook|instagram|instagramStory|pinterest|linkedin|tiktok|googlebusiness|mastodon|multiple|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      channelId: {
        description:
          'The channelId that settings tab was opened for (ex. instagram, pinterest)',
        type: ['string'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      cta: { description: 'The name of the CTA clicked.', type: ['string'] },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern: 'publish|buffer|analyze|engage',
        type: ['string'],
      },
    },
    required: [
      'organizationId',
      'clientName',
      'product',
      'channel',
      'channelId',
      'cta',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Custom Bitly Connect Button Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'CustomBitlyDisconnectButtonClicked' track call.
 *
 * @param CustomBitlyDisconnectButtonClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function customBitlyDisconnectButtonClicked(
  props: CustomBitlyDisconnectButtonClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Custom_Bitly_Disconnect_Button_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When a user disconnects the bitly integration for custom shorten URLs.',
    properties: {
      channel: {
        description:
          'The channel that settings tab was opened for (ex. instagram, pinterest)',
        pattern:
          'twitter|facebook|instagram|instagramStory|pinterest|linkedin|tiktok|googlebusiness|mastodon|multiple|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      channelId: {
        description:
          'The channelId that settings tab was opened for (ex. instagram, pinterest)',
        type: ['string'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      cta: { description: 'The name of the CTA clicked.', type: ['string'] },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern: 'publish|buffer|analyze|engage',
        type: ['string'],
      },
    },
    required: [
      'organizationId',
      'clientName',
      'product',
      'channel',
      'channelId',
      'cta',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Custom Bitly Disconnect Button Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'CustomChannelSetupCompleted' track call.
 *
 * @param CustomChannelSetupCompleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function customChannelSetupCompleted(
  props: CustomChannelSetupCompleted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Custom_Channel_Setup_Completed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user completes the Custom Channel input form',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        pattern:
          'facebook|twitter|tiktok|googlebusiness|mastodon|linkedin|instagram|pinterest|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        pattern: 'publishWeb|publishIos|publishAndroid|core|bufferWeb',
        type: ['string'],
      },
      currentChannelQuantity: {
        description: "The number of channels connected to the user's account",
        type: ['number', 'null'],
      },
      isAtPlanLimit: {
        description: 'Is the user at their plan limit?',
        type: ['boolean', 'null'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that completed the Custom Channel input form',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the Custom Channel input form was completes (ex. publish, buffer)',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: ['organizationId', 'channel'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Custom Channel Setup Completed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'CustomChannelSetupViewed' track call.
 *
 * @param CustomChannelSetupViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function customChannelSetupViewed(
  props: CustomChannelSetupViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Custom_Channel_Setup_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user views the Custom Channel input form',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        pattern:
          'facebook|twitter|tiktok|googlebusiness|mastodon|linkedin|instagram|pinterest|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        pattern: 'publishWeb|publishIos|publishAndroid|core|bufferWeb',
        type: ['string'],
      },
      currentChannelQuantity: {
        description: "The number of channels connected to the user's account",
        type: ['number', 'null'],
      },
      isAtPlanLimit: {
        description: 'Is the user at their plan limit?',
        type: ['boolean', 'null'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the Custom Channel input form',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the Custom Channel input form was viewed (ex. publish, buffer)',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: ['organizationId', 'channel'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Custom Channel Setup Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'DataIngestionFailed' track call.
 *
 * @param DataIngestionFailed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function dataIngestionFailed(
  props: DataIngestionFailed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Data_Ingestion_Failed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When there is an error fetching comments data.',
    properties: {
      channel: {
        description:
          'The channel that we attempted to retrieve data from, e.g. "instagram".',
        type: ['string'],
      },
      clientName: {
        description: 'The name of the client in which the event occurred.',
        type: ['string'],
      },
      dataType: {
        description:
          'The type of data that we failed to ingest, e.g. "post" or "comment".',
        type: ['string'],
      },
      failureCause: {
        description: 'What caused the failure to ingest data?',
        type: ['string'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that triggered the event.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred, ex. engage.',
        type: ['string'],
      },
    },
    required: [
      'product',
      'organizationId',
      'clientName',
      'channel',
      'dataType',
      'failureCause',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Data Ingestion Failed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'DateRangeSelected' track call.
 *
 * @param DateRangeSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function dateRangeSelected(
  props: DateRangeSelected,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Date_Range_Selected',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When user selects a date range in Analyze',
    properties: {
      channel: {
        description: 'The channel of the selected profile, ex. “instagram”.',
        type: ['string'],
      },
      channelId: {
        description:
          'The ID of the channel selected when the date range was selected',
        type: ['string'],
      },
      clientName: {
        description: 'The name of the client in which the action occurred.',
        type: ['string'],
      },
      customDateRangeSelected: {
        description:
          'If a custom date range was selected, what were the dates?',
        type: ['string', 'null'],
      },
      dateRangeSelected: {
        description:
          'What was the date range selected, ex. “last month”, “last week”, “custom”?',
        type: ['string'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that took the action',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred, ex. “analyze”.',
        type: ['string'],
      },
    },
    required: [
      'product',
      'organizationId',
      'clientName',
      'channel',
      'channelId',
      'dateRangeSelected',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Date Range Selected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'DialogViewed' track call.
 *
 * @param DialogViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function dialogViewed(
  props: DialogViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Dialog_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user viewed a dialog',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string', 'null'],
      },
      dialogName: {
        description: 'Name of the dialog that was viewed',
        type: ['string'],
      },
      organizationId: {
        description: 'The global organization ID of the user',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string', 'null'],
      },
    },
    required: ['organizationId', 'dialogName', 'clientName', 'product'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Dialog Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'DisputeCreated' track call.
 *
 * @param DisputeCreated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function disputeCreated(
  props: DisputeCreated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Dispute_Created',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the customer create a dispute on a Stripe charge?',
    properties: {
      customerId: {
        description: 'Unique identifier for the Stripe customer',
        type: ['string'],
      },
      organizationId: {
        description:
          'The ID of the organization for which the tax ID was created',
        type: ['string'],
      },
      reason: {
        description: 'The reason for dispute as returned from Stripe',
        type: ['string', 'null'],
      },
      stripeEventId: {
        description: 'Stripe event id from the webhook event',
        type: ['string'],
      },
    },
    required: ['organizationId', 'customerId', 'stripeEventId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Dispute Created',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'DraftApprovalNotified' track call.
 *
 * @param DraftApprovalNotified props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function draftApprovalNotified(
  props: DraftApprovalNotified,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Draft_Approval_Notified',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'This event is to be used by Customer.io to send an email to the user whose draft has been approved.',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelType: {
        description:
          'The type of channel the post is created for, ex. "page", "group"',
        type: ['string'],
      },
      channelUsername: {
        description:
          'The username of the channel that the draft was created for. This can be used to customize the message in the email.',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        type: ['string'],
      },
      mediaType: {
        description:
          'What type of media did this post have attached? ex. "image", "video"',
        type: ['string', 'null'],
      },
      organizationId: {
        description:
          'The organization ID of the user whose draft was approved.',
        type: ['string'],
      },
      postId: {
        description: 'The database id for the draft document',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occured',
        pattern: 'publish',
        type: ['string'],
      },
    },
    required: [
      'product',
      'channel',
      'channelId',
      'channelUsername',
      'channelType',
      'postId',
      'clientName',
      'organizationId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Draft Approval Notified',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'DraftApprovalRequiredNotified' track call.
 *
 * @param DraftApprovalRequiredNotified props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function draftApprovalRequiredNotified(
  props: DraftApprovalRequiredNotified,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Draft_Approval_Required_Notified',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'This event is solely for the purpose of sending an email with Customer.io to those with the permissions to approve or reject a draft.',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook", "instagram"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelType: {
        description:
          'The type of channel the post is created for, ex. "page", "group"',
        type: ['string'],
      },
      channelUsername: {
        description:
          'The username of the channel that the draft was created for. This can be used to customize the message in the email.',
        type: ['string'],
      },
      clientName: {
        description:
          'Which client was the user in when they took this action? ex "publishIos", "publishWeb"',
        type: ['string'],
      },
      contributorEmail: {
        description:
          'The email address of the contributor that created the draft',
        type: ['string'],
      },
      draftText: {
        description: 'The text of the draft that requires approval',
        type: ['string'],
      },
      mediaType: {
        description:
          'What type of media did this post have attached? ex. "image", "video"',
        type: ['string', 'null'],
      },
      organizationId: {
        description:
          'The organization id of the user who is to receive the email.',
        type: ['string'],
      },
      postId: {
        description: 'The database id for the draft document',
        type: ['string'],
      },
      product: {
        description: 'The product in which the draft was created.',
        pattern: 'publish',
        type: ['string'],
      },
    },
    required: [
      'product',
      'channel',
      'channelId',
      'channelUsername',
      'channelType',
      'postId',
      'clientName',
      'organizationId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Draft Approval Required Notified',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'DraftApproved' track call.
 *
 * @param DraftApproved props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function draftApproved(
  props: DraftApproved,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Draft_Approved',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'A user has approved a draft to be sent out',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelServiceId: {
        description: 'The id of the channel on the given service',
        type: ['string'],
      },
      channelType: {
        description:
          'The type of channel the post is created for, ex. "page", "group"',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        type: ['string'],
      },
      mediaType: {
        description:
          'What type of media did this post have attached? ex. "image", "video"',
        type: ['string', 'null'],
      },
      organizationId: {
        description:
          'The ID of the Global organization that owns the profile that the draft was created for.',
        type: ['string'],
      },
      postId: {
        description: 'The database id for the draft document',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occured',
        pattern: 'publish',
        type: ['string'],
      },
    },
    required: [
      'product',
      'channel',
      'channelId',
      'channelServiceId',
      'channelType',
      'postId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Draft Approved',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'DraftCommentAdded' track call.
 *
 * @param DraftCommentAdded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function draftCommentAdded(
  props: DraftCommentAdded,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Draft_Comment_Added',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'A user has added a comment for a draft for a given channel',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      commentId: {
        description: 'The database id for the comment',
        type: ['string'],
      },
      organizationId: {
        description:
          'The ID of the Global organization that owns the profile that the draft was created for.',
        type: ['string'],
      },
      postId: {
        description: 'The database id for the draft document',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occured',
        pattern: 'publish',
        type: ['string'],
      },
    },
    required: ['product', 'channel', 'channelId', 'postId', 'commentId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Draft Comment Added',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'DraftCommentDeleted' track call.
 *
 * @param DraftCommentDeleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function draftCommentDeleted(
  props: DraftCommentDeleted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Draft_Comment_Deleted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'A user has deleted a comment for a draft for a given channel',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      commentId: {
        description: 'The database id for the comment',
        type: ['string'],
      },
      organizationId: {
        description:
          'The ID of the Global organization that owns the profile that the draft was created for.',
        type: ['string'],
      },
      postId: {
        description: 'The database id for the draft document',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occured',
        pattern: 'publish',
        type: ['string'],
      },
    },
    required: ['product', 'channel', 'channelId', 'postId', 'commentId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Draft Comment Deleted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'DraftDeleted' track call.
 *
 * @param DraftDeleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function draftDeleted(
  props: DraftDeleted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Draft_Deleted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'A user has deleted a draft for a given channel',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelServiceId: {
        description: 'The id of the channel on the given service',
        type: ['string'],
      },
      channelType: {
        description:
          'The type of channel the post is created for, ex. "page", "group"',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        type: ['string'],
      },
      isThreadPost: {
        description:
          'Whether this is a Thread post for twitter or a regular post',
        type: ['boolean'],
      },
      mediaType: {
        description:
          'What type of media did this post have attached? ex. "image", "video"',
        type: ['string', 'null'],
      },
      organizationId: {
        description:
          'The ID of the Global organization that owns the profile that the draft was created for.',
        type: ['string'],
      },
      postId: {
        description: 'The database id for the draft document',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occured',
        pattern: 'publish',
        type: ['string'],
      },
      threadedUpdatesCount: {
        description: 'If Threads post, how many threaded updates it contains',
        type: ['number', 'null'],
      },
      totalLinkCount: {
        description:
          'Total number of link attachments for this post (including threaded updates)',
        type: ['number'],
      },
      totalMediaCount: {
        description:
          'Total number of media uploaded for this post (including threaded updates)',
        type: ['number'],
      },
    },
    required: [
      'product',
      'channel',
      'channelId',
      'channelServiceId',
      'channelType',
      'postId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Draft Deleted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'DraftRejected' track call.
 *
 * @param DraftRejected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function draftRejected(
  props: DraftRejected,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Draft_Rejected',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'This event occurs whenever a draft is rejected',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelServiceId: {
        description: 'The id of the channel on the given service',
        type: ['string'],
      },
      channelType: {
        description:
          'The type of channel the post is created for, ex. "page", "group"',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        type: ['string'],
      },
      mediaType: {
        description:
          'What type of media did this post have attached? ex. "image", "video"',
        type: ['string', 'null'],
      },
      organizationId: {
        description:
          'The ID of the Global organization that owns the profile that the draft was created for.',
        type: ['string'],
      },
      postId: {
        description: 'The database id for the draft document',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occured',
        pattern: 'publish',
        type: ['string'],
      },
    },
    required: [
      'product',
      'channel',
      'channelId',
      'channelServiceId',
      'channelType',
      'postId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Draft Rejected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'DraftRejectionNotified' track call.
 *
 * @param DraftRejectionNotified props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function draftRejectionNotified(
  props: DraftRejectionNotified,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Draft_Rejection_Notified',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'This event is to be used by Customer.io to send an email to the user whose draft has been rejected.',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string'],
      },
      channelAvatar: {
        description:
          'The link to the channel avatar to be used in the notification email',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelType: {
        description:
          'The type of channel the post is created for, ex. "page", "group"',
        type: ['string'],
      },
      channelUsername: {
        description:
          'The username of the channel that the draft was created for. This can be used to customize the message in the email.',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        type: ['string'],
      },
      draftText: {
        description: 'The text of the rejected draft.',
        type: ['string'],
      },
      mediaType: {
        description:
          'What type of media did this post have attached? ex. "image", "video"',
        type: ['string', 'null'],
      },
      organizationId: {
        description:
          'The organization ID of the user whose draft was rejected.',
        type: ['string'],
      },
      postId: {
        description: 'The database id for the draft document',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occured, ex "publish".',
        pattern: 'publish',
        type: ['string'],
      },
      rejectorEmail: {
        description: 'The email address of the person that rejected the draft.',
        type: ['string'],
      },
    },
    required: [
      'product',
      'channel',
      'channelId',
      'channelUsername',
      'channelType',
      'postId',
      'clientName',
      'organizationId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Draft Rejection Notified',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'DraftSubmitted' track call.
 *
 * @param DraftSubmitted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function draftSubmitted(
  props: DraftSubmitted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Draft_Submitted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'A user has submitted a draft for approval',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelServiceId: {
        description: 'The id of the channel on the given service',
        type: ['string'],
      },
      channelType: {
        description:
          'The type of channel the post is created for, ex. "page", "group"',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        type: ['string'],
      },
      mediaType: {
        description:
          'What type of media did this post have attached? ex. "image", "video"',
        type: ['string', 'null'],
      },
      organizationId: {
        description:
          'The ID of the Global organization that owns the profile that the draft was created for.',
        type: ['string'],
      },
      postId: {
        description: 'The database id for the draft document',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occured',
        pattern: 'publish',
        type: ['string'],
      },
    },
    required: [
      'product',
      'channel',
      'channelId',
      'channelServiceId',
      'channelType',
      'postId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Draft Submitted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'DynamicNoticeActionPerformed' track call.
 *
 * @param DynamicNoticeActionPerformed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function dynamicNoticeActionPerformed(
  props: DynamicNoticeActionPerformed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Dynamic_Notice_Action_Performed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When the user interacts with a dynamic product notice.',
    properties: {
      actionPerformed: {
        description: 'What action did the user perform on the notice?',
        type: ['string'],
      },
      channel: {
        description: 'The channel\'s service, ex. "instagram"',
        type: ['string'],
      },
      clientId: {
        description:
          'What was the unique identifier of the client the user was in when they interacted with the notice?',
        type: ['string', 'null'],
      },
      clientName: {
        description:
          'The mobile client the user is on, `publishIos` or `publishAndroid`.',
        type: ['string'],
      },
      id: {
        description: 'Unique identifier of the notice that is interacted with',
        type: ['string'],
      },
      product: {
        description: 'Which Buffer product was the notice for?',
        pattern: 'publish',
        type: ['string'],
      },
      type: { description: 'Type of notice ex. "dialog"', type: ['string'] },
    },
    required: [
      'id',
      'channel',
      'type',
      'clientName',
      'product',
      'actionPerformed',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Dynamic Notice Action Performed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'DynamicNoticeViewed' track call.
 *
 * @param DynamicNoticeViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function dynamicNoticeViewed(
  props: DynamicNoticeViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Dynamic_Notice_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When the user views a dynamic product notice.',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "instagram"',
        type: ['string'],
      },
      clientId: {
        description:
          'What was the unique identifier of the client the user was in when they viewed the notice?',
        type: ['string', 'null'],
      },
      clientName: {
        description:
          'The mobile client the user is on, `publishIos` or `publishAndroid`.',
        type: ['string'],
      },
      id: {
        description: 'Unique identifier of the notice that is viewed',
        type: ['string'],
      },
      product: {
        description: 'Which Buffer product was the notice for?',
        pattern: 'publish',
        type: ['string'],
      },
      type: { description: 'Type of notice ex. "dialog"', type: ['string'] },
    },
    required: ['id', 'channel', 'type', 'clientName', 'product'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Dynamic Notice Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'EcommercePlatformConnected' track call.
 *
 * @param EcommercePlatformConnected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function ecommercePlatformConnected(
  props: EcommercePlatformConnected,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Ecommerce_Platform_Connected',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user connect an ecommerce platform?',
    properties: {
      clientName: {
        description: 'The client in which the action occurred.',
        type: ['string'],
      },
      platform: {
        description:
          'What ecommerce platform was the product selected from? ex. "shopify"',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred, ex. “remix”.',
        pattern: 'remix',
        type: ['string'],
      },
    },
    required: ['product', 'clientName', 'platform'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Ecommerce Platform Connected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'EmailUpdated' track call.
 *
 * @param EmailUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function emailUpdated(
  props: EmailUpdated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Email_Updated',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user updates their email.',
    properties: {
      newEmail: { description: 'The new email of the user.', type: ['string'] },
    },
    required: ['newEmail'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Email Updated',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'EmailVerificationInitiated' track call.
 *
 * @param EmailVerificationInitiated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function emailVerificationInitiated(
  props: EmailVerificationInitiated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Email_Verification_Initiated',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user starts the email verification process.',
    properties: {
      action: {
        description:
          'The scenario that started the email verification process.',
        pattern: 'signup|resend|email-update-requested',
        type: ['string'],
      },
      email: {
        description: 'The current email of the user.',
        type: ['string'],
      },
      newEmail: { description: 'The new email of the user.', type: ['string'] },
      verificationLink: {
        description: 'The link that the user has to click to get verified.',
        type: ['string'],
      },
    },
    required: ['action', 'verificationLink', 'email'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Email Verification Initiated',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'EmailVerified' track call.
 *
 * @param EmailVerified props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function emailVerified(
  props: EmailVerified,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Email_Verified',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When a user verifies their email address. There are 4 ways to verifiy an email address:\n  - Clicking the link in the email users receive on sign-up.\n  - Clicking the link in the email users receive after asking to resend a new one.\n  - After completing the password change flow.\n  - After completing the email change flow.\n',
    properties: {
      currentEmail: {
        description: 'The current email of the user.',
        type: ['string'],
      },
    },
    required: ['currentEmail'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Email Verified',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'EngageDataDisabled' track call.
 *
 * @param EngageDataDisabled props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function engageDataDisabled(
  props: EngageDataDisabled,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Engage_Data_Disabled',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      "When Engage an Engage user's data is deleted due to inactivity.",
    properties: {
      channel: {
        description: 'The channel whose data was deleted, e.g. "facebook"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel.',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel.",
        type: ['string'],
      },
      channelUsername: {
        description: 'What is the username of the channel?',
        type: ['string'],
      },
      organizationId: {
        description:
          'The global organization id of the user that took this action',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred.',
        pattern: 'engage',
        type: ['string'],
      },
    },
    required: ['product', 'organizationId', 'channelId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Engage Data Disabled',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'EngageDisabled' track call.
 *
 * @param EngageDisabled props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function engageDisabled(
  props: EngageDisabled,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Engage_Disabled',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When Engage is disabled after a user has been inactive. At this point we stop collecting data for the user.',
    properties: {
      organizationId: {
        description:
          'The global organization id of the user that took this action',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred.',
        pattern: 'engage',
        type: ['string'],
      },
    },
    required: ['product', 'organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Engage Disabled',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'EngageReactivated' track call.
 *
 * @param EngageReactivated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function engageReactivated(
  props: EngageReactivated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Engage_Reactivated',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When Engage is reactivated after a period of inactivity.',
    properties: {
      cta: {
        description:
          'Which call to action did the user click to enable Engage?',
        type: ['string', 'null'],
      },
      ctaApp: {
        description:
          'Which product is the CTA located in? Examples would be `publish` or `analyze`.',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA? In many cases it makes sense to describe the intended result of the CTA, like `proTrial` or `publishPro`.',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in Publish app shell menu.',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA? If setting the value for the first time, set to `1`.',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'What app view is the CTA located in? Examples would be, `composer` or `analyticsOverview` for Publish.',
        type: ['string', 'null'],
      },
      organizationId: {
        description:
          'The global organization id of the user that took this action',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred.',
        pattern: 'engage',
        type: ['string'],
      },
    },
    required: ['product', 'organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Engage Reactivated',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ExperimentEnrolled' track call.
 *
 * @param ExperimentEnrolled props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function experimentEnrolled(
  props: ExperimentEnrolled,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Experiment_Enrolled',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When was the user or visitor enrolled in the experiment?',
    properties: {
      experimentGroup: {
        description:
          'What was the name of the variation group (Eg. `control`, `enabled`, `variation_1`)',
        type: ['string'],
      },
      experimentId: {
        description: 'What was the unique identifier of the experiment?',
        type: ['string'],
      },
      organizationId: {
        description:
          'The Global organization ID of the user that was added to the experiment.',
        type: ['string'],
      },
      product: {
        description: 'Which product was the experiment run in?',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string', 'null'],
      },
      sourceFramework: {
        description:
          'Which experiment framework was the experiment run from (eg, `einstein`, `albert`)?',
        type: ['string'],
      },
      view: {
        description:
          'What was the page or view the experiment treatment resides in (eg, `/pricing`, or `payday_modal`)?',
        type: ['string', 'null'],
      },
    },
    required: ['experimentId', 'experimentGroup', 'product', 'sourceFramework'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Experiment Enrolled',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ExperimentViewed' track call.
 *
 * @param ExperimentViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function experimentViewed(
  props: ExperimentViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Experiment_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When did the user or visitor view a variation in the experiment? The property of `firstViewed` will be set to `true` if the event designates an experiment enrollment for the user or visitor.',
    properties: {
      experimentGroup: {
        description:
          'What was the name of the variation group (i.e. ""control"" ""experiment"")',
        type: ['string'],
      },
      experimentId: {
        description: 'What was the unique identifier of the experiment?',
        type: ['string'],
      },
      firstViewed: {
        description:
          'Was this the first time the user or visitor interacted with this experiment variation? When true, this designates the experiment enrollment. It is possible for a given visitor or user to be enrolled in a given experiment more than once if they use multiple browsers, multiple devices, or clear their cookies between interactions with a given experiment.',
        type: ['boolean', 'null'],
      },
      organizationId: {
        description:
          'The Global organization ID of the user that was added to the experiment.',
        type: ['string'],
      },
      product: {
        description:
          'Which product was the experiment run in?  If marketing website, set as null.',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string', 'null'],
      },
      sourceFramework: {
        description:
          'Which experiment framework was the experiment run from (eg, `einstein`, `albert`)?',
        type: ['string'],
      },
      view: {
        description:
          'What was the page or view the experiment treatment resides in (eg, `/pricing`, or `payday_modal`)?',
        type: ['string', 'null'],
      },
    },
    required: [
      'experimentId',
      'experimentGroup',
      'product',
      'sourceFramework',
      'firstViewed',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Experiment Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'FeatureFlipDisabled' track call.
 *
 * @param FeatureFlipDisabled props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function featureFlipDisabled(
  props: FeatureFlipDisabled,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Feature_Flip_Disabled',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When a feature flip is disabled for an account. This event should be triggered once per each unique feature flipped, and does not represent multiple features flipped simultaneously.',
    properties: {
      featureName: {
        description: 'The name of the feature that was disabled.',
        type: ['string'],
      },
      organizationId: {
        description:
          'The organization ID of the account for which the feature was disabled.',
        type: ['string'],
      },
    },
    required: ['organizationId', 'featureName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Feature Flip Disabled',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'FeatureFlipEnabled' track call.
 *
 * @param FeatureFlipEnabled props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function featureFlipEnabled(
  props: FeatureFlipEnabled,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Feature_Flip_Enabled',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When a feature flip is enabled for an account. This event should be triggered once per each unique feature flipped, and does not represent multiple features flipped simultaneously.',
    properties: {
      featureName: {
        description: 'The name of the feature that was enabled.',
        type: ['string'],
      },
      organizationId: {
        description:
          'The organization ID of the account for which the feature was enabled.',
        type: ['string'],
      },
    },
    required: ['organizationId', 'featureName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Feature Flip Enabled',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'FeatureFlipEvaluated' track call.
 *
 * @param FeatureFlipEvaluated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function featureFlipEvaluated(
  props: FeatureFlipEvaluated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Feature_Flip_Evaluated',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When a feature flip (split) treatment is evaluated for an organization',
    properties: {
      featureName: {
        description: 'The name of the feature flip.',
        type: ['string'],
      },
      organizationId: {
        description:
          'The organization ID of the account for which the feature was enabled.',
        type: ['string'],
      },
      treatment: {
        description:
          'The treatment the user or organization is in for the feature flip.',
        type: ['string'],
      },
      version: {
        description:
          'The version of the split. This comes from split.io and changes when you change the rollout/targeting of a feature. Date time is required to be in ISO-8601 format.',
        type: ['string'],
      },
    },
    required: ['organizationId', 'featureName', 'treatment', 'version'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Feature Flip Evaluated',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'FeedCollectionCreated' track call.
 *
 * @param FeedCollectionCreated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function feedCollectionCreated(
  props: FeedCollectionCreated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Feed_Collection_Created',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a new feed collection is created',
    properties: {
      clientName: {
        description: 'The client in which the event occurred.',
        pattern:
          'publishWeb|publishIos|publishAndroid|core|bufferWeb|ios|android',
        type: ['string', 'null'],
      },
      cta: {
        description: 'Which source/cta was used to trigger this event',
        type: ['string'],
      },
      feedCollectionId: {
        description: 'The ID of the feed collection.',
        type: ['string'],
      },
      feedCollectionName: {
        description: 'The name of the feed collection.',
        type: ['string'],
      },
      organizationId: {
        description: 'The global organization ID.',
        type: ['string'],
      },
      product: {
        description: 'Which product the event is triggered from',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: [
      'product',
      'organizationId',
      'feedCollectionId',
      'feedCollectionName',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Feed Collection Created',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'FeedItemInteracted' track call.
 *
 * @param FeedItemInteracted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function feedItemInteracted(
  props: FeedItemInteracted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Feed_Item_Interacted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user interacts with a feed item',
    properties: {
      clientName: {
        description: 'The client in which the event occurred.',
        pattern:
          'publishWeb|publishIos|publishAndroid|core|bufferWeb|ios|android',
        type: ['string', 'null'],
      },
      cta: {
        description: 'Which source/cta was used to trigger this event',
        type: ['string'],
      },
      feedCollectionId: {
        description: 'The ID of the collection the feed belongs to.',
        type: ['string'],
      },
      feedId: { description: 'The ID of the feed.', type: ['string'] },
      feedItemContentUrl: {
        description: 'The URL of the feed item content.',
        type: ['string'],
      },
      feedItemId: { description: 'The ID of the feed item.', type: ['string'] },
      interactionType: {
        description: 'The type of interaction (e.g., viewArticle, etc)',
        type: ['string'],
      },
      organizationId: {
        description: 'The global organization ID.',
        type: ['string'],
      },
      product: {
        description: 'Which product the event is triggered from',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: [
      'product',
      'organizationId',
      'feedItemId',
      'feedId',
      'feedCollectionId',
      'interactionType',
      'feedItemContentUrl',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Feed Item Interacted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'FeedSubscribed' track call.
 *
 * @param FeedSubscribed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function feedSubscribed(
  props: FeedSubscribed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Feed_Subscribed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user subscribes to a feed',
    properties: {
      clientName: {
        description: 'The client in which the event occurred.',
        pattern:
          'publishWeb|publishIos|publishAndroid|core|bufferWeb|ios|android',
        type: ['string', 'null'],
      },
      cta: {
        description: 'Which source/cta was used to trigger this event',
        type: ['string'],
      },
      feedCollectionId: {
        description: 'The ID of the collection the feed belongs to.',
        type: ['string'],
      },
      feedId: { description: 'The ID of the feed.', type: ['string'] },
      feedQuery: {
        description: 'The query used to subscribe to the feed.',
        type: ['string'],
      },
      feedType: {
        description: 'The type of feed (e.g., rssAtom, news)',
        type: ['string'],
      },
      feedUrl: { description: 'The URL of the feed.', type: ['string'] },
      organizationId: {
        description: 'The global organization ID.',
        type: ['string'],
      },
      product: {
        description: 'Which product the event is triggered from',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: ['product', 'organizationId', 'feedId', 'feedCollectionId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Feed Subscribed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'FeedbackFormSubmitted' track call.
 *
 * @param FeedbackFormSubmitted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function feedbackFormSubmitted(
  props: FeedbackFormSubmitted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Feedback_Form_Submitted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When user submits a feedback from within application',
    properties: {
      clientName: {
        description: 'The name of the client that the event occurred in',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that completed the survey.',
        type: ['string'],
      },
      rating: { description: 'Rating user submitted', type: ['integer'] },
      source: {
        description:
          'Source signifying additional location or position of the form',
        type: ['string'],
      },
      text: {
        description: 'Free form feedback user submitted',
        type: ['string'],
      },
      userId: {
        description: 'User id who sumbitted the form',
        type: ['string'],
      },
      widgetId: {
        description:
          'Unique for id used to differentiate between different forms',
        type: ['string'],
      },
    },
    required: ['widgetId', 'clientName', 'organizationId', 'userId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Feedback Form Submitted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'FirstPostCelebrationCtaClicked' track call.
 *
 * @param FirstPostCelebrationCtaClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function firstPostCelebrationCtaClicked(
  props: FirstPostCelebrationCtaClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'First_Post_Celebration_Cta_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When did the user click a CTA on the first post celebration modal?',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      cta: {
        description: 'The CTA string of the cta clicked',
        type: ['string'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the Trial Expired modal.',
        type: ['string'],
      },
    },
    required: ['organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'First Post Celebration Cta Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'FirstPostCelebrationModalViewed' track call.
 *
 * @param FirstPostCelebrationModalViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function firstPostCelebrationModalViewed(
  props: FirstPostCelebrationModalViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'First_Post_Celebration_Modal_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user see the First Post Celebration modal?',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the Trial Expired modal.',
        type: ['string'],
      },
    },
    required: ['organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'First Post Celebration Modal Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'FollowMastodonButtonClicked' track call.
 *
 * @param FollowMastodonButtonClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function followMastodonButtonClicked(
  props: FollowMastodonButtonClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Follow_Mastodon_Button_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When a user clicks the Follow Buffer on Mastodon button in the promo banner',
    properties: {
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern: 'publish|buffer|analyze|engage',
        type: ['string'],
      },
    },
    required: ['organizationId', 'clientName', 'product'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Follow Mastodon Button Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'FollowMastodonDismissed' track call.
 *
 * @param FollowMastodonDismissed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function followMastodonDismissed(
  props: FollowMastodonDismissed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Follow_Mastodon_Dismissed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When a user dismissed the Follow Buffer on Mastodon promo banner',
    properties: {
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern: 'publish|buffer|analyze|engage',
        type: ['string'],
      },
    },
    required: ['organizationId', 'clientName', 'product'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Follow Mastodon Dismissed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'FreeToolRateLimitModalViewed' track call.
 *
 * @param FreeToolRateLimitModalViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function freeToolRateLimitModalViewed(
  props: FreeToolRateLimitModalViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Free_Tool_Rate_Limit_Modal_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When a visitor sees a rate limit message from one of our free tools',
    properties: {
      product: {
        description: 'The name of the Free Tool',
        pattern: 'post_creator',
        type: ['string'],
      },
    },
    required: ['product'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Free Tool Rate Limit Modal Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'GettingStartedStepTapped' track call.
 *
 * @param GettingStartedStepTapped props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function gettingStartedStepTapped(
  props: GettingStartedStepTapped,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Getting_Started_Step_Tapped',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Which Getting Started step that the user tapped on to complete',
    properties: {
      clientName: {
        description:
          'Which client was the user in when they performed the Getting Started action? IE, `publishIos` or `publishAndroid`.',
        pattern: 'publishIos|publishAndroid',
        type: ['string'],
      },
      organizationId: {
        description:
          'The organization ID of the user that performed the Getting Started action.',
        type: ['string'],
      },
      step: {
        description: 'The name of the Getting Started step they took action on',
        pattern: 'createdPost|schedulePosts|finishLater|startPage',
        type: ['string'],
      },
    },
    required: ['step', 'clientName', 'organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Getting Started Step Tapped',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'GlobalPlusButtonItemClicked' track call.
 *
 * @param GlobalPlusButtonItemClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function globalPlusButtonItemClicked(
  props: GlobalPlusButtonItemClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Global_Plus_Button_Item_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When a user clicks an item in the global plus button dropdown',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      cta: {
        description: 'The name of the CTA clicked.',
        type: ['string', 'null'],
      },
      isAtPlanLimit: {
        description: 'Is the user at their plan limit?',
        type: ['boolean', 'null'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string', 'null'],
      },
      upgradePathName: {
        description: 'The name of the upgrade path',
        type: ['string', 'null'],
      },
    },
    required: ['clientName', 'product', 'cta', 'upgradePathName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Global Plus Button Item Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'GlobalPlusButtonOpened' track call.
 *
 * @param GlobalPlusButtonOpened props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function globalPlusButtonOpened(
  props: GlobalPlusButtonOpened,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Global_Plus_Button_Opened',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user open the Global Plus Button dropdown menu?',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      cta: {
        description: 'The name of the CTA clicked.',
        type: ['string', 'null'],
      },
      currentChannelQuantity: {
        description: "The number of channels connected to the user's account",
        type: ['number', 'null'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the plan selector.',
        type: ['string', 'null'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: ['organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Global Plus Button Opened',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'HashtagGroupCreated' track call.
 *
 * @param HashtagGroupCreated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function hashtagGroupCreated(
  props: HashtagGroupCreated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Hashtag_Group_Created',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'A user saves a group of commonly used hashtags',
    properties: {
      clientName: {
        description: 'Which client was the user in when they took this action?',
        type: ['string'],
      },
      hashtagCount: {
        description: 'The number of hashtags in the the group',
        type: ['integer'],
      },
      hashtagGroupName: {
        description: 'The name the user has given to the hashtag group',
        type: ['string'],
      },
      organizationId: {
        description:
          'The ID of the global organization that the hashtag group was created for.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occured',
        pattern: 'publish',
        type: ['string'],
      },
    },
    required: ['product', 'hashtagGroupName', 'hashtagCount'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Hashtag Group Created',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'HashtagGroupDeleted' track call.
 *
 * @param HashtagGroupDeleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function hashtagGroupDeleted(
  props: HashtagGroupDeleted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Hashtag_Group_Deleted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'A user deletes one of their hashtag groups',
    properties: {
      clientName: {
        description: 'Which client was the user in when they took this action?',
        type: ['string'],
      },
      hashtagCount: {
        description: 'The number of hashtags in the the group',
        type: ['integer'],
      },
      hashtagGroupName: {
        description: 'The name the user has given to the hashtag group',
        type: ['string'],
      },
      organizationId: {
        description:
          'The ID of the global organization that the hashtag group was created for.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occured',
        pattern: 'publish',
        type: ['string'],
      },
    },
    required: ['product', 'hashtagGroupName', 'hashtagCount'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Hashtag Group Deleted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'HashtagGroupInserted' track call.
 *
 * @param HashtagGroupInserted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function hashtagGroupInserted(
  props: HashtagGroupInserted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Hashtag_Group_Inserted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'A user inserts one of their hashtag groups as a first comment',
    properties: {
      clientName: {
        description:
          'Which client was the user in when they inserted the hashtag group?',
        type: ['string', 'null'],
      },
      hashtagCount: {
        description: 'The number of hashtags in the the group',
        type: ['integer'],
      },
      hashtagGroupName: {
        description: 'The name the user has given to the hashtag group',
        type: ['string'],
      },
      organizationId: {
        description:
          'The ID of the global organization that the hashtag group was created for.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occured',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: ['product', 'hashtagGroupName', 'hashtagCount'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Hashtag Group Inserted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'HelpCenterArticleViewed' track call.
 *
 * @param HelpCenterArticleViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function helpCenterArticleViewed(
  props: HelpCenterArticleViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Help_Center_Article_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'A customer viewed a help article on our help center site',
    properties: {
      articleTitle: {
        description: 'The title of the article that was viewed.',
        type: ['string', 'null'],
      },
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the page.',
        type: ['string', 'null'],
      },
    },
    required: ['articleTitle'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Help Center Article Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'HelpScoutBeaconArticleViewed' track call.
 *
 * @param HelpScoutBeaconArticleViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function helpScoutBeaconArticleViewed(
  props: HelpScoutBeaconArticleViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Help_Scout_Beacon_Article_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'A customer viewed a help article in the Help Scout Beacon widget',
    properties: {
      articleId: {
        description: 'The ID of the article that was viewed.',
        type: ['string', 'null'],
      },
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      currentUrl: {
        description: 'The address of the page.',
        type: ['string', 'null'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the page.',
        type: ['string', 'null'],
      },
    },
    required: ['articleId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Help Scout Beacon Article Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'HelpScoutBeaconChatStarted' track call.
 *
 * @param HelpScoutBeaconChatStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function helpScoutBeaconChatStarted(
  props: HelpScoutBeaconChatStarted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Help_Scout_Beacon_Chat_Started',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'A customer started a chat in the Help Scout Beacon widget',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      currentUrl: {
        description: 'The address of the page.',
        type: ['string', 'null'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the page.',
        type: ['string'],
      },
      subject: {
        description: 'The subject of the chat.',
        type: ['string', 'null'],
      },
    },
    required: ['organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Help Scout Beacon Chat Started',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'HelpScoutBeaconClosed' track call.
 *
 * @param HelpScoutBeaconClosed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function helpScoutBeaconClosed(
  props: HelpScoutBeaconClosed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Help_Scout_Beacon_Closed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'A customer closed the Help Scout Beacon widget',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      currentUrl: {
        description: 'The address of the page.',
        type: ['string', 'null'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the page.',
        type: ['string', 'null'],
      },
    },
    required: ['organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Help Scout Beacon Closed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'HelpScoutBeaconEmailSent' track call.
 *
 * @param HelpScoutBeaconEmailSent props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function helpScoutBeaconEmailSent(
  props: HelpScoutBeaconEmailSent,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Help_Scout_Beacon_Email_Sent',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'A customer sent an email in the Help Scout Beacon widget',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      currentUrl: {
        description: 'The address of the page.',
        type: ['string', 'null'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the page.',
        type: ['string'],
      },
      subject: {
        description: 'The subject of the email.',
        type: ['string', 'null'],
      },
    },
    required: ['organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Help Scout Beacon Email Sent',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'HelpScoutBeaconOpened' track call.
 *
 * @param HelpScoutBeaconOpened props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function helpScoutBeaconOpened(
  props: HelpScoutBeaconOpened,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Help_Scout_Beacon_Opened',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'A customer opened the Help Scout Beacon widget',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      currentUrl: {
        description: 'The address of the page.',
        type: ['string', 'null'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the page.',
        type: ['string', 'null'],
      },
    },
    required: ['organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Help Scout Beacon Opened',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'HelpScoutBeaconSearchQueryPerformed' track call.
 *
 * @param HelpScoutBeaconSearchQueryPerformed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function helpScoutBeaconSearchQueryPerformed(
  props: HelpScoutBeaconSearchQueryPerformed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Help_Scout_Beacon_Search_Query_Performed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'A customer performed a search query in the Help Scout Beacon widget',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      currentUrl: {
        description: 'The address of the page.',
        type: ['string', 'null'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the page.',
        type: ['string', 'null'],
      },
      searchTerm: {
        description: 'The search term that was used.',
        type: ['string', 'null'],
      },
    },
    required: ['organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Help Scout Beacon Search Query Performed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'HomepageButtonClicked' track call.
 *
 * @param HomepageButtonClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function homepageButtonClicked(
  props: HomepageButtonClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Homepage_Button_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When a visitor clicks on one of the CTAs on the Buffer homepage.',
    properties: {
      buttonLocation: {
        description:
          "The location of the button that was clicked, e.g. 'top-nav', 'footer'",
        type: ['string', 'null'],
      },
      buttonName: {
        description:
          "The name of the button that was clicked, e.g. 'hero-get-started'",
        type: ['string', 'null'],
      },
      emailAddress: {
        description: 'If someone enters an email address, include it here.',
        type: ['string', 'null'],
      },
    },
    required: ['buttonLocation'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Homepage Button Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'IdeaActionClicked' track call.
 *
 * @param IdeaActionClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function ideaActionClicked(
  props: IdeaActionClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Idea_Action_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Click event when an idea action is performed',
    properties: {
      action: { description: 'Name of the action performed', type: ['string'] },
      ideaId: { description: 'The idea ID', type: ['string'] },
      organizationId: {
        description: 'The global organization ID.',
        type: ['string'],
      },
      product: {
        description:
          'Which product was the product the event was triggered from.',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: ['product', 'organizationId', 'action', 'ideaId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Idea Action Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'IdeaBatchDeleted' track call.
 *
 * @param IdeaBatchDeleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function ideaBatchDeleted(
  props: IdeaBatchDeleted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Idea_Batch_Deleted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Idea batch delete is performed',
    properties: {
      count: { description: 'Number of ideas selected', type: ['number'] },
      organizationId: {
        description: 'The global organization ID.',
        type: ['string'],
      },
      product: {
        description:
          'Which product was the product the event was triggered from.',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: ['product', 'organizationId', 'count'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Idea Batch Deleted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'IdeaComposerOpened' track call.
 *
 * @param IdeaComposerOpened props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function ideaComposerOpened(
  props: IdeaComposerOpened,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Idea_Composer_Opened',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user opens the Idea composer.',
    properties: {
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        type: ['string'],
      },
      cta: {
        description:
          'Which call to action did the user click to get to open the composer?',
        type: ['string'],
      },
      ctaApp: {
        description:
          'Which product is the CTA located in? Examples would be `publish`.',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA? In many cases it makes sense to describe the intended result of the CTA, like `createAPost` or `publishProTrial`.',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in Publish app shell menu.',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA? If setting the value for the first time, set to `1`.',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'What app view is the CTA located in? Examples would be `publishDashboard` or `analyticsOverview` for Publish.',
        type: ['string', 'null'],
      },
      ideaId: {
        description: 'The id of the Idea that the composer is opened for.',
        type: ['string', 'null'],
      },
      organizationId: {
        description: 'The organization id of the user that took the action',
        type: ['string'],
      },
      source: {
        description: 'The source of the Idea that the composer is opened for',
        type: ['string', 'null'],
      },
    },
    required: ['organizationId', 'clientName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Idea Composer Opened',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'IdeaCreated' track call.
 *
 * @param IdeaCreated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function ideaCreated(
  props: IdeaCreated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Idea_Created',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user has created an Idea',
    properties: {
      aiAssisted: {
        description: 'Whether this idea was assisted with AI',
        type: ['boolean', 'null'],
      },
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      canvaDesignId: {
        description: 'The design id if a Canva image is attached to the post',
        type: ['string', 'null'],
      },
      clientName: {
        description: 'Which client was the user in when the Idea was created?',
        pattern:
          'publishWeb|publishIos|publishAndroid|publishExtension|core|unknown',
        type: ['string'],
      },
      cta: {
        description:
          'Which source/cta was clicked to create the idea in the UI',
        type: ['string'],
      },
      ctaApp: {
        description: 'Which website is the CTA located in? Ex, `bufferSite`',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA?  In many cases it makes sense to describe the intended result of the CTA, like `proTrial` or `publishPro`',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be, `cards` for the Pro trial CTA in Publish pricing page main plan comparison section',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA?  If setting the value for the first time, set to `1`',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'Which website page is the CTA located?  Ex,  `pricingPublish` for the Publish pricing page',
        type: ['string', 'null'],
      },
      gifCount: { description: 'Count of attached gifs', type: ['number'] },
      hasCanvaImage: {
        description:
          'Does the Idea contain an image from the Canva integration?',
        type: ['boolean'],
      },
      hasUploadcareImage: {
        description:
          'Does the Idea contain an image from the Uploadcare integration?',
        type: ['boolean'],
      },
      ideaId: {
        description: 'The database id for the Idea document',
        type: ['string'],
      },
      imageCount: { description: 'Count of attached images', type: ['number'] },
      linkCount: {
        description: 'Count of attached links in the text',
        type: ['number'],
      },
      mediaCount: {
        description: 'Count of attached media (images or videos)',
        type: ['number'],
      },
      mediaType: {
        description:
          'What type of media did this Idea have attached? ex. "image", "video"',
        type: ['string', 'null'],
      },
      organizationId: {
        description: 'The global organization id that Idea belongs to.',
        type: ['string'],
      },
      tagsCount: {
        description: 'Count of tags assigned to the Idea',
        type: ['number', 'null'],
      },
      textLength: {
        description: 'The number of characters of the text content of the Idea',
        type: ['number'],
      },
      videoCount: { description: 'Count of attached videos', type: ['number'] },
    },
    required: [
      'ideaId',
      'mediaCount',
      'imageCount',
      'linkCount',
      'videoCount',
      'gifCount',
      'organizationId',
      'textLength',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Idea Created',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'IdeaDeleted' track call.
 *
 * @param IdeaDeleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function ideaDeleted(
  props: IdeaDeleted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Idea_Deleted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user deletes an Idea.',
    properties: {
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string', 'null'],
      },
      cta: {
        description: 'Which source/cta was used to trigger this event',
        type: ['string'],
      },
      ctaApp: {
        description: 'Which website is the CTA located in? Ex, `bufferSite`',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA?  In many cases it makes sense to describe the intended result of the CTA, like `proTrial` or `publishPro`',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be, `cards` for the Pro trial CTA in Publish pricing page main plan comparison section',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA?  If setting the value for the first time, set to `1`',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'Which website page is the CTA located?  Ex,  `pricingPublish` for the Publish pricing page',
        type: ['string', 'null'],
      },
      ideaId: {
        description: "The id of the Idea in Buffer's database.",
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
    },
    required: ['organizationId', 'ideaId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Idea Deleted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'IdeaDiscarded' track call.
 *
 * @param IdeaDiscarded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function ideaDiscarded(
  props: IdeaDiscarded,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Idea_Discarded',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user discards an idea after converting to post.',
    properties: {
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        type: ['string'],
      },
      cta: {
        description:
          'Which call to action did the user click to discard the Idea',
        type: ['string'],
      },
      ideaId: {
        description: "The id of the Idea in Buffer's database.",
        type: ['string'],
      },
      organizationId: {
        description: 'The organization id of the user that took the action',
        type: ['string'],
      },
    },
    required: ['organizationId', 'clientName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Idea Discarded',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'IdeaEdited' track call.
 *
 * @param IdeaEdited props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function ideaEdited(
  props: IdeaEdited,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Idea_Edited',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user edits an Idea.',
    properties: {
      aiAssisted: {
        description: 'Whether this idea was assisted with AI',
        type: ['boolean', 'null'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string', 'null'],
      },
      cta: {
        description: 'Which source/cta was used to trigger this event',
        type: ['string'],
      },
      ctaApp: {
        description: 'Which website is the CTA located in? Ex, `bufferSite`',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA?  In many cases it makes sense to describe the intended result of the CTA, like `proTrial` or `publishPro`',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be, `cards` for the Pro trial CTA in Publish pricing page main plan comparison section',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA?  If setting the value for the first time, set to `1`',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'Which website page is the CTA located?  Ex,  `pricingPublish` for the Publish pricing page',
        type: ['string', 'null'],
      },
      gifCount: { description: 'Count of attached gifs', type: ['number'] },
      ideaId: {
        description: "The id of the Idea in Buffer's database.",
        type: ['string'],
      },
      imageCount: { description: 'Count of attached images', type: ['number'] },
      linkCount: {
        description: 'Count of attached links in the text',
        type: ['number'],
      },
      mediaCount: {
        description: 'Count of attached media (images or videos)',
        type: ['number'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      tagsCount: {
        description: 'Count of tags assigned to the Idea',
        type: ['number', 'null'],
      },
      textLength: {
        description: 'The number of characters of the text content of the Idea',
        type: ['number'],
      },
      videoCount: { description: 'Count of attached videos', type: ['number'] },
    },
    required: [
      'organizationId',
      'ideaId',
      'mediaCount',
      'imageCount',
      'linkCount',
      'videoCount',
      'gifCount',
      'textLength',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Idea Edited',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'IdeaKept' track call.
 *
 * @param IdeaKept props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function ideaKept(
  props: IdeaKept,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Idea_Kept',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user keeps an idea after converting to post.',
    properties: {
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        type: ['string'],
      },
      cta: {
        description:
          'Which call to action did the user click to discard the Idea',
        type: ['string'],
      },
      ideaId: {
        description: "The id of the Idea in Buffer's database.",
        type: ['string'],
      },
      organizationId: {
        description: 'The organization id of the user that took the action',
        type: ['string'],
      },
    },
    required: ['organizationId', 'clientName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Idea Kept',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'IdeaMoved' track call.
 *
 * @param IdeaMoved props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function ideaMoved(
  props: IdeaMoved,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Idea_Moved',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When an idea is moved between content groups in the create space.',
    properties: {
      clientName: {
        description: 'The client in which the event occurred.',
        pattern:
          'publishWeb|publishIos|publishAndroid|core|bufferWeb|ios|android',
        type: ['string', 'null'],
      },
      cta: {
        description: 'Which source/cta was used to trigger this event',
        type: ['string'],
      },
      ctaApp: {
        description: 'Which website is the CTA located in? Ex, `bufferSite`',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA?  In many cases it makes sense to describe the intended result of the CTA, like `proTrial` or `publishPro`',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be, `cards` for the Pro trial CTA in Publish pricing page main plan comparison section',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA?  If setting the value for the first time, set to `1`',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'Which website page is the CTA located?  Ex,  `pricingPublish` for the Publish pricing page',
        type: ['string', 'null'],
      },
      ideaId: { description: 'The ID of the idea moved.', type: ['string'] },
      newGroupId: { description: 'The ID of the new group.', type: ['string'] },
      newGroupName: {
        description: 'The name of the new group.',
        type: ['string'],
      },
      organizationId: {
        description: 'The global organization ID.',
        type: ['string'],
      },
      previousGroupId: {
        description: 'The ID of the previous group.',
        type: ['string'],
      },
      previousGroupName: {
        description: 'The name of the previous group.',
        type: ['string'],
      },
      product: {
        description:
          'Which product was the product the event was triggered from.',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: [
      'product',
      'organizationId',
      'ideaId',
      'previousGroupId',
      'newGroupId',
      'previousGroupName',
      'newGroupName',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Idea Moved',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'IdeaShareSheetOpened' track call.
 *
 * @param IdeaShareSheetOpened props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function ideaShareSheetOpened(
  props: IdeaShareSheetOpened,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Idea_Share_Sheet_Opened',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'A user has opened the share sheet for an Idea',
    properties: {
      billingPlan: {
        description: 'The plan id of the subscription',
        type: ['string', 'null'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishIos)',
        pattern: 'publishIos|publishAndroid',
        type: ['string'],
      },
      hasMedia: {
        description: 'The post has either video or image media',
        type: ['boolean'],
      },
      hasPictures: {
        description: 'If the update has pictures',
        type: ['boolean'],
      },
      hasVideo: { description: 'If the update has video', type: ['boolean'] },
      ideaId: {
        description: 'The post id for the reminder being shared',
        type: ['string', 'null'],
      },
      isPayingBufferUser: {
        description:
          'Does the user have an active One Buffer paid subscription?',
        type: ['boolean', 'null'],
      },
      organizationId: {
        description: 'The global organization id that the channel belongs to',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occured',
        pattern: 'publish',
        type: ['string'],
      },
    },
    required: ['product', 'clientName', 'organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Idea Share Sheet Opened',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'IdeasAssistantCancelled' track call.
 *
 * @param IdeasAssistantCancelled props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function ideasAssistantCancelled(
  props: IdeasAssistantCancelled,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Ideas_Assistant_Cancelled',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When the AI assistant is cancelled by clicking on the close button',
    properties: {
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        type: ['string'],
      },
      cta: {
        description:
          'Which call to action did the user click to get to open the composer?',
        type: ['string', 'null'],
      },
      ctaApp: {
        description:
          'Which product is the CTA located in? Examples would be `publish`.',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA? In many cases it makes sense to describe the intended result of the CTA, like `createAPost` or `publishProTrial`.',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in Publish app shell menu.',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA? If setting the value for the first time, set to `1`.',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'What app view is the CTA located in? Examples would be `publishDashboard` or `analyticsOverview` for Publish.',
        type: ['string', 'null'],
      },
      organizationId: {
        description: 'The organization id of the user that took the action',
        type: ['string'],
      },
    },
    required: ['organizationId', 'clientName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Ideas Assistant Cancelled',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'IdeasAssistantInitiated' track call.
 *
 * @param IdeasAssistantInitiated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function ideasAssistantInitiated(
  props: IdeasAssistantInitiated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Ideas_Assistant_Initiated',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When the ideas assistant is initiated in the context of the ideas editor',
    properties: {
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        type: ['string'],
      },
      cta: {
        description:
          'Which call to action did the user click to get to open the composer?',
        type: ['string', 'null'],
      },
      ctaApp: {
        description:
          'Which product is the CTA located in? Examples would be `publish`.',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA? In many cases it makes sense to describe the intended result of the CTA, like `createAPost` or `publishProTrial`.',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in Publish app shell menu.',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA? If setting the value for the first time, set to `1`.',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'What app view is the CTA located in? Examples would be `publishDashboard` or `analyticsOverview` for Publish.',
        type: ['string', 'null'],
      },
      organizationId: {
        description: 'The organization id of the user that took the action',
        type: ['string'],
      },
    },
    required: ['organizationId', 'clientName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Ideas Assistant Initiated',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'IdeasAssistantVariationAccepted' track call.
 *
 * @param IdeasAssistantVariationAccepted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function ideasAssistantVariationAccepted(
  props: IdeasAssistantVariationAccepted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Ideas_Assistant_Variation_Accepted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user accepts a variation generated by AI',
    properties: {
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        type: ['string'],
      },
      contentType: {
        description:
          'The type of content a user requests, `text`, `image` or `both`.',
        type: ['string', 'null'],
      },
      cta: {
        description:
          'Which call to action did the user click to get to open the composer?',
        type: ['string'],
      },
      ctaApp: {
        description:
          'Which product is the CTA located in? Examples would be `publish`.',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA? In many cases it makes sense to describe the intended result of the CTA, like `createAPost` or `publishProTrial`.',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in Publish app shell menu.',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA? If setting the value for the first time, set to `1`.',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'What app view is the CTA located in? Examples would be `publishDashboard` or `analyticsOverview` for Publish.',
        type: ['string', 'null'],
      },
      organizationId: {
        description: 'The organization id of the user that took the action',
        type: ['string'],
      },
    },
    required: ['organizationId', 'clientName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Ideas Assistant Variation Accepted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'IdeasAssistantVariationErrored' track call.
 *
 * @param IdeasAssistantVariationErrored props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function ideasAssistantVariationErrored(
  props: IdeasAssistantVariationErrored,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Ideas_Assistant_Variation_Errored',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When the user receives an error from the content generation service, after a variation request.',
    properties: {
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        type: ['string'],
      },
      contentType: {
        description:
          'The type of content a user requests, `text`, `image` or `both`.',
        type: ['string', 'null'],
      },
      cta: {
        description:
          'Which call to action did the user click to get to open the composer?',
        type: ['string'],
      },
      ctaApp: {
        description:
          'Which product is the CTA located in? Examples would be `publish`.',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA? In many cases it makes sense to describe the intended result of the CTA, like `createAPost` or `publishProTrial`.',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in Publish app shell menu.',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA? If setting the value for the first time, set to `1`.',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'What app view is the CTA located in? Examples would be `publishDashboard` or `analyticsOverview` for Publish.',
        type: ['string', 'null'],
      },
      errorCode: {
        description:
          'The error code the user receives from the content generation service (if applicable)',
        type: ['string', 'null'],
      },
      errorMessage: {
        description:
          'The error message the user receives from the content generation service',
        type: ['string', 'null'],
      },
      organizationId: {
        description: 'The organization id of the user that took the action',
        type: ['string'],
      },
    },
    required: ['organizationId', 'clientName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Ideas Assistant Variation Errored',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'IdeasAssistantVariationProposed' track call.
 *
 * @param IdeasAssistantVariationProposed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function ideasAssistantVariationProposed(
  props: IdeasAssistantVariationProposed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Ideas_Assistant_Variation_Proposed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When the user receives a variation from the content generation service, in the ideas editor.',
    properties: {
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        type: ['string'],
      },
      contentType: {
        description:
          'The type of content a user requests, `text`, `image` or `both`.',
        type: ['string', 'null'],
      },
      cta: {
        description:
          'Which call to action did the user click to get to open the composer?',
        type: ['string'],
      },
      ctaApp: {
        description:
          'Which product is the CTA located in? Examples would be `publish`.',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA? In many cases it makes sense to describe the intended result of the CTA, like `createAPost` or `publishProTrial`.',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in Publish app shell menu.',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA? If setting the value for the first time, set to `1`.',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'What app view is the CTA located in? Examples would be `publishDashboard` or `analyticsOverview` for Publish.',
        type: ['string', 'null'],
      },
      organizationId: {
        description: 'The organization id of the user that took the action',
        type: ['string'],
      },
      variations: {
        description: 'The number of variations proposed',
        type: ['number'],
      },
    },
    required: ['organizationId', 'clientName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Ideas Assistant Variation Proposed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'IdeasAssistantVariationRequested' track call.
 *
 * @param IdeasAssistantVariationRequested props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function ideasAssistantVariationRequested(
  props: IdeasAssistantVariationRequested,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Ideas_Assistant_Variation_Requested',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When a user requests for AI assisted content generation in the ideas',
    properties: {
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        type: ['string'],
      },
      contentType: {
        description:
          'The type of content a user requests, `text`, `image` or `both`.',
        type: ['string', 'null'],
      },
      cta: {
        description:
          'Which call to action did the user click to get to open the composer?',
        type: ['string'],
      },
      ctaApp: {
        description:
          'Which product is the CTA located in? Examples would be `publish`.',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA? In many cases it makes sense to describe the intended result of the CTA, like `createAPost` or `publishProTrial`.',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in Publish app shell menu.',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA? If setting the value for the first time, set to `1`.',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'What app view is the CTA located in? Examples would be `publishDashboard` or `analyticsOverview` for Publish.',
        type: ['string', 'null'],
      },
      organizationId: {
        description: 'The organization id of the user that took the action',
        type: ['string'],
      },
    },
    required: ['organizationId', 'clientName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Ideas Assistant Variation Requested',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'IdeasFiltered' track call.
 *
 * @param IdeasFiltered props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function ideasFiltered(
  props: IdeasFiltered,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Ideas_Filtered',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Fitered ideas in the create space.',
    properties: {
      clientName: {
        description: 'The client in which the event occurred.',
        pattern:
          'publishWeb|publishIos|publishAndroid|core|bufferWeb|ios|android',
        type: ['string', 'null'],
      },
      cta: {
        description: 'Which source/cta was used to trigger this event',
        type: ['string'],
      },
      filter: {
        description: 'Names of filters used i.e. tags, groups, etc.',
        type: ['array'],
      },
      organizationId: {
        description: 'The global organization ID.',
        type: ['string'],
      },
    },
    required: ['organizationId', 'filter'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Ideas Filtered',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'IdeasOpenedDuringOnboarding' track call.
 *
 * @param IdeasOpenedDuringOnboarding props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function ideasOpenedDuringOnboarding(
  props: IdeasOpenedDuringOnboarding,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Ideas_Opened_During_Onboarding',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user open Ideas during Oboarding',
    properties: {
      clientName: {
        description:
          'What was the name of the client the user was in when they opened the Story in the native channel?',
        type: ['string'],
      },
      organizationId: {
        description: 'The global organization id that the channel belongs to.',
        type: ['string'],
      },
      product: {
        description: 'In which product did this event occur?',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: ['product', 'clientName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Ideas Opened During Onboarding',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ImageBackgroundColorSelected' track call.
 *
 * @param ImageBackgroundColorSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function imageBackgroundColorSelected(
  props: ImageBackgroundColorSelected,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Image_Background_Color_Selected',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When did the user select the color of the background of the image?',
    properties: {
      backgroundColor: {
        description: 'What was the selected background color?',
        type: ['string'],
      },
      clientName: {
        description: 'The client in which the action occurred.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred, ex. “remix”.',
        pattern: 'remix',
        type: ['string'],
      },
      source: {
        description: 'How did the user select the chosen background color?',
        type: ['string'],
      },
    },
    required: ['product', 'clientName', 'backgroundColor', 'source'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Image Background Color Selected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ImageBackgroundIconTapped' track call.
 *
 * @param ImageBackgroundIconTapped props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function imageBackgroundIconTapped(
  props: ImageBackgroundIconTapped,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Image_Background_Icon_Tapped',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When did the user tap the image background icon within the editor in the remix app?',
    properties: {
      clientName: {
        description: 'The client in which the action occurred.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred, ex. “remix”.',
        pattern: 'remix',
        type: ['string'],
      },
    },
    required: ['product', 'clientName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Image Background Icon Tapped',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ImageBackgroundImageRemoved' track call.
 *
 * @param ImageBackgroundImageRemoved props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function imageBackgroundImageRemoved(
  props: ImageBackgroundImageRemoved,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Image_Background_Image_Removed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user remove the image used in the background?',
    properties: {
      clientName: {
        description: 'The client in which the action occurred.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred, ex. “remix”.',
        pattern: 'remix',
        type: ['string'],
      },
    },
    required: ['product', 'clientName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Image Background Image Removed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ImageBackgroundImageSelected' track call.
 *
 * @param ImageBackgroundImageSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function imageBackgroundImageSelected(
  props: ImageBackgroundImageSelected,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Image_Background_Image_Selected',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user select the image used in the background?',
    properties: {
      clientName: {
        description: 'The client in which the action occurred.',
        type: ['string'],
      },
      imageSource: {
        description: 'The source in which the image was selected.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred, ex. “remix”.',
        pattern: 'remix',
        type: ['string'],
      },
    },
    required: ['product', 'clientName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Image Background Image Selected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ImageCropped' track call.
 *
 * @param ImageCropped props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function imageCropped(
  props: ImageCropped,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Image_Cropped',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When an image is cropped within the composer',
    properties: {
      clientId: {
        description:
          'What was the unique identifier of the client the user was in when they attached the media item?',
        type: ['string', 'null'],
      },
      clientName: {
        description:
          'Which client was the user in when they attached the media item? IE, `publishIos`, `publishWeb`, `publishAndroid`.',
        pattern: 'publishIos|publishAndroid',
        type: ['string'],
      },
      method: {
        description: 'The method used to crop the image',
        pattern: 'autoCrop|editor',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that added the media',
        type: ['string'],
      },
      product: {
        description: 'The product for which the media item was attached in',
        pattern: 'publish',
        type: ['string'],
      },
    },
    required: ['product', 'method', 'clientName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Image Cropped',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ImageSaved' track call.
 *
 * @param ImageSaved props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function imageSaved(
  props: ImageSaved,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Image_Saved',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user save the image to the camera roll?',
    properties: {
      clientName: {
        description: 'The client in which the action occurred.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred, ex. “remix”.',
        pattern: 'remix',
        type: ['string'],
      },
      template: {
        description: 'The template chosen for the saved image.',
        type: ['string'],
      },
    },
    required: ['product', 'template', 'clientName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Image Saved',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ImageShared' track call.
 *
 * @param ImageShared props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function imageShared(
  props: ImageShared,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Image_Shared',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user share the image to Publish or another app?',
    properties: {
      app: {
        description:
          'What was the name of the app that the image was shared to?',
        type: ['string'],
      },
      channel: {
        description:
          'The channel of the profile that the image was shared to, ex. “facebook”.',
        type: ['string'],
      },
      channelId: {
        description:
          'The unique identifier of the channel that the image was shared to.',
        type: ['string'],
      },
      channelType: {
        description:
          'The channel type of the profile that the image was shared to, ex. “business”.',
        type: ['string'],
      },
      clientName: {
        description: 'The client in which the action occurred.',
        type: ['string'],
      },
      postId: {
        description: 'The unique identifier of the post in Publish.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred, ex. “remix”.',
        pattern: 'remix',
        type: ['string'],
      },
      publishUserId: {
        description: 'The id of the Publish user',
        type: ['string'],
      },
      target: {
        description: 'Where was the image shared? Ex. "publish", "app".',
        type: ['string'],
      },
      template: {
        description: 'The template chosen for the shared image.',
        type: ['string'],
      },
    },
    required: ['product', 'target', 'template', 'clientName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Image Shared',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ImageSizeSelected' track call.
 *
 * @param ImageSizeSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function imageSizeSelected(
  props: ImageSizeSelected,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Image_Size_Selected',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user select the size of the image?',
    properties: {
      clientName: {
        description: 'The client in which the action occurred.',
        type: ['string'],
      },
      imageSize: {
        description: 'What was the selected image size?',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred, ex. “remix”.',
        pattern: 'remix',
        type: ['string'],
      },
    },
    required: ['product', 'clientName', 'imageSize'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Image Size Selected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ImageStyleResetTapped' track call.
 *
 * @param ImageStyleResetTapped props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function imageStyleResetTapped(
  props: ImageStyleResetTapped,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Image_Style_Reset_Tapped',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user reset the style to default for the image?',
    properties: {
      clientName: {
        description: 'The client in which the action occurred.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred, ex. “remix”.',
        pattern: 'remix',
        type: ['string'],
      },
    },
    required: ['product', 'clientName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Image Style Reset Tapped',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ImageTemplateSelected' track call.
 *
 * @param ImageTemplateSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function imageTemplateSelected(
  props: ImageTemplateSelected,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Image_Template_Selected',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user select the template for the image?',
    properties: {
      clientName: {
        description: 'The client in which the action occurred.',
        type: ['string'],
      },
      imageTemplate: {
        description: 'What was the selected image templagte?',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred, ex. “remix”.',
        pattern: 'remix',
        type: ['string'],
      },
    },
    required: ['product', 'clientName', 'imageTemplate'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Image Template Selected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'InstagramAuthorizationExplainerSlideViewed' track call.
 *
 * @param InstagramAuthorizationExplainerSlideViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function instagramAuthorizationExplainerSlideViewed(
  props: InstagramAuthorizationExplainerSlideViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Instagram_Authorization_Explainer_Slide_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Which slide did the user view within the Instagram Auth Explainer flow.',
    properties: {
      clientId: {
        description:
          'What was the unique identifier of the client the user was in when they viewed the slide?',
        type: ['string', 'null'],
      },
      clientName: {
        description:
          'Which client was the user in when they viewed the slide? IE, `publishIos` or `publishAndroid`.',
        pattern: 'publishIos|publishAndroid',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that viewed the slide.',
        type: ['string'],
      },
      slide: {
        description: 'Which slide did the user view?',
        pattern:
          'whyLinkWithFacebook|instagramBusinessFeatures|loginToFacebook|selectProfile',
        type: ['string'],
      },
    },
    required: ['slide', 'clientName', 'organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Instagram Authorization Explainer Slide Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'InstagramCaptionDotsInserted' track call.
 *
 * @param InstagramCaptionDotsInserted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function instagramCaptionDotsInserted(
  props: InstagramCaptionDotsInserted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Instagram_Caption_Dots_Inserted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When did the user tap the button to insert dots into the Composer for Instagram.',
    properties: {
      clientId: {
        description:
          'What was the unique identifier of the client the user was in when they updated the story?',
        type: ['string', 'null'],
      },
      clientName: {
        description:
          'Which client was the user in when they copied the story note? IE, `publishIos`, `publishWeb`, `publishAndroid`.',
        type: ['string'],
      },
      organizationId: {
        description:
          'The organization ID of the user that copied the story note.',
        type: ['string'],
      },
      product: {
        description: 'The product for which the story was created',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: ['product', 'clientName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Instagram Caption Dots Inserted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'InstagramGridPreviewIconClicked' track call.
 *
 * @param InstagramGridPreviewIconClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function instagramGridPreviewIconClicked(
  props: InstagramGridPreviewIconClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Instagram_Grid_Preview_Icon_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user click the Instagram Grid Preview icon?',
    properties: {
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel",
        type: ['string'],
      },
      channelUsername: {
        description: 'What is the username of the channel?',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        type: ['string'],
      },
      organizationId: {
        description: 'The Global organization ID.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occurred',
        pattern: 'publish',
        type: ['string'],
      },
    },
    required: ['product', 'channelId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Instagram Grid Preview Icon Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'InstagramGridPreviewPageLoaded' track call.
 *
 * @param InstagramGridPreviewPageLoaded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function instagramGridPreviewPageLoaded(
  props: InstagramGridPreviewPageLoaded,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Instagram_Grid_Preview_Page_Loaded',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user load the Instagram Grid Preview page?',
    properties: {
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel",
        type: ['string'],
      },
      channelUsername: {
        description: 'What is the username of the channel?',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        type: ['string'],
      },
      organizationId: {
        description: 'The Global organization ID.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occurred',
        pattern: 'publish',
        type: ['string'],
      },
    },
    required: ['product', 'channelId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Instagram Grid Preview Page Loaded',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'InstagramGridPreviewToggleView' track call.
 *
 * @param InstagramGridPreviewToggleView props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function instagramGridPreviewToggleView(
  props: InstagramGridPreviewToggleView,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Instagram_Grid_Preview_Toggle_View',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When did the user toggle the view of the Instagram Grid Preview page?',
    properties: {
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel",
        type: ['string'],
      },
      channelUsername: {
        description: 'What is the username of the channel?',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        type: ['string'],
      },
      organizationId: {
        description: 'The Global organization ID.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occurred',
        pattern: 'publish',
        type: ['string'],
      },
      view: {
        description: 'The view state of the Instagram Grid Preview.',
        type: ['string'],
      },
    },
    required: ['product', 'channelId', 'view'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Instagram Grid Preview Toggle View',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'InstagramTagsSaved' track call.
 *
 * @param InstagramTagsSaved props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function instagramTagsSaved(
  props: InstagramTagsSaved,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Instagram_Tags_Saved',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user save a list of tagged Instagram profiles?',
    properties: {
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel",
        type: ['string'],
      },
      channelUsername: {
        description: 'What is the Instagram profile’s username?',
        type: ['string'],
      },
      clientName: {
        description:
          'The client in which the action occurred, `publishIos`, `publishAndroid`or `publishWeb`.',
        type: ['string'],
      },
      organizationId: {
        description:
          'The ID of the global organization that the user tags were saved for.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the tagging action occured',
        pattern: 'publish',
        type: ['string'],
      },
      taggedUserCount: {
        description: 'How many usernames were tagged in the post?',
        type: ['number'],
      },
      taggedUsernames: {
        description:
          'What were the usernames of the profiles that were tagged in the post',
        type: ['array'],
      },
    },
    required: ['product', 'channelId', 'taggedUserCount'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Instagram Tags Saved',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'InstagramUserTagged' track call.
 *
 * @param InstagramUserTagged props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function instagramUserTagged(
  props: InstagramUserTagged,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Instagram_User_Tagged',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user tag another username in an Instagram post?',
    properties: {
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel",
        type: ['string'],
      },
      channelUsername: {
        description: 'What is the Instagram profile’s username?',
        type: ['string'],
      },
      clientName: {
        description:
          'The client in which the action occurred, `publishIos`, `publishAndroid`or `publishWeb`.',
        type: ['string'],
      },
      organizationId: {
        description:
          'The ID of the global organization that is selected in the org switcher.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the tagging action occured',
        pattern: 'publish',
        type: ['string'],
      },
      taggedUsername: {
        description:
          'What is the username of the profile that was tagged in the post?',
        type: ['string'],
      },
    },
    required: ['product', 'channelId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Instagram User Tagged',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'IntegrationButtonClicked' track call.
 *
 * @param IntegrationButtonClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function integrationButtonClicked(
  props: IntegrationButtonClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Integration_Button_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user clicks on an Integration button.',
    properties: {
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb).',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      integration: {
        description: 'The identifier of the Integration, ex. Unsplash, Giphy',
        pattern:
          'unsplash|giphy|canva|dropbox|googledrive|onedrive|googlephotos',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action.',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, analyze).',
        pattern: 'publish|buffer|analyze|engage',
        type: ['string'],
      },
      source: {
        description: 'The component where the integration was opened.',
        pattern: 'ideasEditor|publishComposer',
        type: ['string'],
      },
    },
    required: [
      'organizationId',
      'clientName',
      'product',
      'source',
      'integration',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Integration Button Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'IntegrationMediaAttached' track call.
 *
 * @param IntegrationMediaAttached props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function integrationMediaAttached(
  props: IntegrationMediaAttached,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Integration_Media_Attached',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When a media from an integrated service is attached to an editor.',
    properties: {
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb).',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      destination: {
        description: 'The editor where the media is going to be attached.',
        pattern: 'ideasEditor|publishComposer',
        type: ['string'],
      },
      integration: {
        description: 'The identifier of the Integration, ex. Unsplash, Giphy.',
        pattern:
          'unsplash|giphy|canva|dropbox|googledrive|onedrive|googlephotos',
        type: ['string'],
      },
      mediaType: {
        description: 'mimetype of the media element, ex. image/png, image/jpg.',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action.',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, analyze).',
        pattern: 'publish|buffer|analyze|engage',
        type: ['string'],
      },
    },
    required: [
      'organizationId',
      'clientName',
      'product',
      'destination',
      'integration',
      'mediaType',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Integration Media Attached',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'IntegrationSearchRun' track call.
 *
 * @param IntegrationSearchRun props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function integrationSearchRun(
  props: IntegrationSearchRun,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Integration_Search_Run',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user launches a query to the integrated service.',
    properties: {
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb).',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      integration: {
        description: 'The identifier of the Integration, ex. Unsplash, Giphy.',
        pattern:
          'unsplash|giphy|canva|dropbox|googledrive|onedrive|googlephotos',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action.',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer).',
        pattern: 'publish|buffer|analyze|engage',
        type: ['string'],
      },
      query: {
        description: 'search term used to launches a query to the service.',
        type: ['string'],
      },
      source: {
        description: 'The component where the integration was opened.',
        pattern: 'ideasEditor|publishComposer',
        type: ['string'],
      },
    },
    required: [
      'organizationId',
      'clientName',
      'product',
      'integration',
      'query',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Integration Search Run',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'InvoicePaymentFailed' track call.
 *
 * @param InvoicePaymentFailed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function invoicePaymentFailed(
  props: InvoicePaymentFailed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Invoice_Payment_Failed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Did this paid subscription invoice payment fail from being collected?',
    properties: {
      attemptCount: {
        description:
          'How many times has Buffer attempted to collect payment for this invoice?',
        type: ['number', 'null'],
      },
      creditCardCountry: {
        description: 'Which country is the credit card from? e.g. US or IN',
        type: ['string', 'null'],
      },
      customerId: {
        description: 'Unique identifier for the Stripe customer',
        type: ['string'],
      },
      cycle: {
        description:
          'The subscription payment interval (month or year) of the subscription associated with the invoice',
        type: ['string'],
      },
      errorMessage: {
        description:
          'What was the error message in the payment gateway for this payment failure?',
        type: ['string', 'null'],
      },
      gateway: {
        description: 'What triggered this event? e.g. Stripe or RevenueCat',
        type: ['string'],
      },
      hasTaxId: {
        description: 'Does the user have a tax ID?',
        type: ['boolean', 'null'],
      },
      invoiceValue: {
        description:
          'The dollar value of the invoice at the time of this attempted payment',
        type: ['number'],
      },
      isAutomaticTaxEnabled: {
        description:
          'Is automatic tax collection in Stripe enabled for this user?',
        type: ['boolean', 'null'],
      },
      isStripeCheckout: {
        description: 'Was the subscription started via Stripe Checkout?',
        type: ['boolean', 'null'],
      },
      isTaxExempt: {
        description: 'Is the user tax exempt?',
        type: ['boolean', 'null'],
      },
      nextCollectionAttemptDate: {
        description:
          'When will be the next date that Buffer will attempt to collect payment of this invoice?  Data type for this property is a string, but value should be formatted as a timestamp in the ISO-8601 date format.',
        type: ['string', 'null'],
      },
      organizationId: {
        description:
          'The ID of the organization for which the invoice was created',
        type: ['string'],
      },
      planId: {
        description:
          'Stripe plan id of the subscription associated with the invoice',
        type: ['string'],
      },
      planName: {
        description:
          'Human-readable plan name of the subscription associated with the invoice',
        type: ['string'],
      },
      product: {
        description:
          'The product of the subscription associated with the invoice',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      stripeEventId: {
        description: 'Stripe event id from the webhook event',
        type: ['string'],
      },
      subscriptionId: {
        description: 'Unique identifier for the Stripe subscription',
        type: ['string'],
      },
      taxAmount: {
        description: 'How much tax was the user charged?',
        type: ['number', 'null'],
      },
      wasChargedTax: {
        description: 'Was the customer charged tax?',
        type: ['boolean', 'null'],
      },
    },
    required: [
      'stripeEventId',
      'customerId',
      'subscriptionId',
      'organizationId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Invoice Payment Failed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'InvoicePaymentRefunded' track call.
 *
 * @param InvoicePaymentRefunded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function invoicePaymentRefunded(
  props: InvoicePaymentRefunded,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Invoice_Payment_Refunded',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Was there a refund for this invoice?',
    properties: {
      customerId: {
        description: 'Unique identifier for the Stripe customer',
        type: ['string'],
      },
      cycle: {
        description:
          'The subscription payment interval (month or year) of the subscription associated with the invoice',
        type: ['string'],
      },
      gateway: {
        description: 'What triggered this event? e.g. Stripe or RevenueCat',
        type: ['string'],
      },
      hasTaxId: {
        description: 'Does the user have a tax ID?',
        type: ['boolean', 'null'],
      },
      invoiceValue: {
        description: 'The dollar value of the invoice prior to this refund',
        type: ['number'],
      },
      isAutomaticTaxEnabled: {
        description:
          'Is automatic tax collection in Stripe enabled for this user?',
        type: ['boolean', 'null'],
      },
      isTaxExempt: {
        description: 'Is the user tax exempt?',
        type: ['boolean', 'null'],
      },
      organizationId: {
        description:
          'The ID of the organization for which the invoice was created',
        type: ['string'],
      },
      planId: {
        description:
          'Stripe plan id of the subscription associated with the invoice',
        type: ['string'],
      },
      planName: {
        description:
          'Human-readable plan name of the subscription associated with the invoice',
        type: ['string'],
      },
      product: {
        description:
          'The product of the subscription associated with the invoice',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      receiptLink: {
        description: 'The URL of payment refund receipt',
        type: ['string'],
      },
      refundValue: {
        description: 'The dollar value of this refund',
        type: ['number'],
      },
      stripeEventId: {
        description: 'Stripe event id from the webhook event',
        type: ['string'],
      },
      subscriptionId: {
        description: 'Unique identifier for the Stripe subscription',
        type: ['string'],
      },
      taxAmount: {
        description: 'How much tax was the user charged?',
        type: ['number', 'null'],
      },
      wasChargedTax: {
        description: 'Was the customer charged tax?',
        type: ['boolean', 'null'],
      },
    },
    required: [
      'stripeEventId',
      'customerId',
      'subscriptionId',
      'organizationId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Invoice Payment Refunded',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'InvoicePaymentSucceeded' track call.
 *
 * @param InvoicePaymentSucceeded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function invoicePaymentSucceeded(
  props: InvoicePaymentSucceeded,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Invoice_Payment_Succeeded',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Was this invoice payment successfully collected?',
    properties: {
      creditCardCountry: {
        description: 'Which country is the credit card from? e.g. US or IN',
        type: ['string', 'null'],
      },
      customerId: {
        description: 'Unique identifier for the Stripe customer',
        type: ['string'],
      },
      cycle: {
        description:
          'The subscription payment interval (month or year) of the subscription associated with the invoice',
        type: ['string'],
      },
      gateway: {
        description: 'What triggered this event? e.g. Stripe or RevenueCat',
        type: ['string'],
      },
      hasTaxId: {
        description: 'Does the user have a tax ID?',
        type: ['boolean', 'null'],
      },
      invoiceValue: {
        description: 'The dollar value of the invoice prior to this payment',
        type: ['number'],
      },
      isAutomaticTaxEnabled: {
        description:
          'Is automatic tax collection in Stripe enabled for this user?',
        type: ['boolean', 'null'],
      },
      isStripeCheckout: {
        description: 'Was the subscription started via Stripe Checkout?',
        type: ['boolean', 'null'],
      },
      isTaxExempt: {
        description: 'Is the user tax exempt?',
        type: ['boolean', 'null'],
      },
      organizationId: {
        description:
          'The ID of the organization for which the invoice was created',
        type: ['string'],
      },
      planId: {
        description:
          'Stripe plan id of the subscription associated with the invoice',
        type: ['string'],
      },
      planName: {
        description:
          'Human-readable plan name of the subscription associated with the invoice',
        type: ['string'],
      },
      product: {
        description:
          'The product of the subscription associated with the invoice',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      stripeEventId: {
        description: 'Stripe event id from the webhook event',
        type: ['string'],
      },
      subscriptionId: {
        description: 'Unique identifier for the Stripe subscription',
        type: ['string'],
      },
      taxAmount: {
        description: 'How much tax was the user charged?',
        type: ['number', 'null'],
      },
      wasChargedTax: {
        description: 'Was the customer charged tax?',
        type: ['boolean', 'null'],
      },
    },
    required: [
      'stripeEventId',
      'customerId',
      'subscriptionId',
      'organizationId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Invoice Payment Succeeded',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'InvoiceUpcoming' track call.
 *
 * @param InvoiceUpcoming props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function invoiceUpcoming(
  props: InvoiceUpcoming,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Invoice_Upcoming',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      "Is there a Stripe invoice coming due in the next 7 days?  This event is triggered by the Stripe invoice.upcoming event, and is configurable via Buffer's Stripe account settings; currently set to trigger 7 days before an invoice is generated.",
    properties: {
      customerId: {
        description: 'Unique identifier for the Stripe customer',
        type: ['string'],
      },
      cycle: {
        description:
          'The subscription payment interval (month or year) of the subscription associated with the invoice',
        type: ['string'],
      },
      gateway: {
        description: 'What triggered this event? e.g. Stripe or RevenueCat',
        type: ['string'],
      },
      invoiceValue: {
        description: 'The dollar value of the invoice amount due',
        type: ['number'],
      },
      organizationId: {
        description:
          'The ID of the organization for which the invoice was created',
        type: ['string'],
      },
      planId: {
        description:
          'Stripe plan id of the subscription associated with the invoice',
        type: ['string'],
      },
      planName: {
        description:
          'Human-readable plan name of the subscription associated with the invoice',
        type: ['string'],
      },
      product: {
        description:
          'The product of the subscription associated with the invoice',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      stripeEventId: {
        description: 'Stripe event id from the webhook event',
        type: ['string'],
      },
      subscriptionId: {
        description: 'Unique identifier for the Stripe subscription',
        type: ['string'],
      },
    },
    required: ['stripeEventId', 'customerId', 'subscriptionId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Invoice Upcoming',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'JoinCommunityButtonClicked' track call.
 *
 * @param JoinCommunityButtonClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function joinCommunityButtonClicked(
  props: JoinCommunityButtonClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Join_Community_Button_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user clicks a Join Community button in the beta page',
    properties: {
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: ['organizationId', 'clientName', 'product'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Join Community Button Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'LinkShortened' track call.
 *
 * @param LinkShortened props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function linkShortened(
  props: LinkShortened,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Link_Shortened',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a link is being shortened and under what conditions',
    properties: {
      channel: {
        description:
          'The channel that settings tab was opened for (ex. instagram, pinterest)',
        pattern:
          'twitter|facebook|instagram|instagramStory|pinterest|linkedin|tiktok|googlebusiness|mastodon|multiple|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      channelId: {
        description:
          'The channelId that settings tab was opened for (ex. instagram, pinterest)',
        type: ['string'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      hasConnectedAccount: {
        description: 'Whether the user has connected a bitly account',
        type: ['boolean'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern: 'publish|buffer|analyze|engage',
        type: ['string'],
      },
      shortenerDomain: {
        description: 'The domain of the shortener used',
        type: ['string'],
      },
      url: { description: 'The URL being shortened', type: ['string', 'null'] },
    },
    required: [
      'organizationId',
      'clientName',
      'product',
      'channel',
      'channelId',
      'hasConnectedAccount',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Link Shortened',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'LinkShortenedClicked' track call.
 *
 * @param LinkShortenedClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function linkShortenedClicked(
  props: LinkShortenedClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Link_Shortened_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When the action button for shortening links in the composer is clicked',
    properties: {
      channel: {
        description:
          'The channel that settings tab was opened for (ex. instagram, pinterest)',
        pattern:
          'twitter|facebook|instagram|instagramStory|pinterest|linkedin|tiktok|googlebusiness|mastodon|multiple|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      channelId: {
        description:
          'The channelId that settings tab was opened for (ex. instagram, pinterest)',
        type: ['string'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      cta: { description: 'The name of the CTA clicked.', type: ['string'] },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern: 'publish|buffer|analyze|engage',
        type: ['string'],
      },
    },
    required: [
      'organizationId',
      'clientName',
      'product',
      'channel',
      'channelId',
      'cta',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Link Shortened Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'LinkUnshortenedClicked' track call.
 *
 * @param LinkUnshortenedClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function linkUnshortenedClicked(
  props: LinkUnshortenedClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Link_Unshortened_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When the action button for unshortening links in the composer is clicked',
    properties: {
      channel: {
        description:
          'The channel that settings tab was opened for (ex. instagram, pinterest)',
        pattern:
          'twitter|facebook|instagram|instagramStory|pinterest|linkedin|tiktok|googlebusiness|mastodon|multiple|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      channelId: {
        description:
          'The channelId that settings tab was opened for (ex. instagram, pinterest)',
        type: ['string'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      cta: { description: 'The name of the CTA clicked.', type: ['string'] },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern: 'publish|buffer|analyze|engage',
        type: ['string'],
      },
    },
    required: [
      'organizationId',
      'clientName',
      'product',
      'channel',
      'channelId',
      'cta',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Link Unshortened Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'LiveChatCreated' track call.
 *
 * @param LiveChatCreated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function liveChatCreated(
  props: LiveChatCreated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Live_Chat_Created',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'A customer created a new live chat conversation',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      conversationAssigneeName: {
        description: 'The name of the advocate assigned to the conversation',
        type: ['string', 'null'],
      },
      conversationId: {
        description:
          'The ID associated with the specific Help Scout conversation',
        type: ['string'],
      },
      conversationTags: {
        description: 'A list of tags applied to the conversation',
        type: ['array', 'null'],
      },
      conversationUrl: {
        description: 'A link to the specific Help Scout conversation',
        type: ['string', 'null'],
      },
      mailboxId: {
        description: 'The ID of the mailbox the conversation was created in',
        type: ['string'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the page.',
        type: ['string', 'null'],
      },
    },
    required: ['conversationId', 'mailboxId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Live Chat Created',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'LiveChatCustomerReplied' track call.
 *
 * @param LiveChatCustomerReplied props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function liveChatCustomerReplied(
  props: LiveChatCustomerReplied,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Live_Chat_Customer_Replied',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'A customer replied to a live chat conversation',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      conversationAssigneeName: {
        description: 'The name of the advocate assigned to the conversation',
        type: ['string', 'null'],
      },
      conversationId: {
        description:
          'The ID associated with the specific Help Scout conversation',
        type: ['string'],
      },
      conversationTags: {
        description: 'A list of tags applied to the conversation',
        type: ['array', 'null'],
      },
      conversationUrl: {
        description: 'A link to the specific Help Scout conversation',
        type: ['string', 'null'],
      },
      mailboxId: {
        description: 'The ID of the mailbox the conversation was created in',
        type: ['string'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the page.',
        type: ['string', 'null'],
      },
    },
    required: ['conversationId', 'mailboxId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Live Chat Customer Replied',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'LoginVerificationAbandoned' track call.
 *
 * @param LoginVerificationAbandoned props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function loginVerificationAbandoned(
  props: LoginVerificationAbandoned,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Login_Verification_Abandoned',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When a login verification flow is abandonded (e.g. the user did not validate their JWT, and instead tried to login again)',
    properties: {
      accountId: { description: 'The accountId of the user', type: ['string'] },
      email: {
        description: 'The current email of the user.',
        type: ['string'],
      },
      minutesSinceLoginVerificationRequested: {
        description:
          'The time (in minutes) since the most recent login verification email was sent to the user',
        type: ['number'],
      },
      organizationId: {
        description: 'The current organizationID of the user',
        type: ['string', 'null'],
      },
    },
    required: [
      'organizationId',
      'accountId',
      'email',
      'minutesSinceLoginVerificationRequested',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Login Verification Abandoned',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'LoginVerificationComplete' track call.
 *
 * @param LoginVerificationComplete props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function loginVerificationComplete(
  props: LoginVerificationComplete,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Login_Verification_Complete',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a login verification flow is completed.',
    properties: {
      accountId: { description: 'The accountId of the user', type: ['string'] },
      email: {
        description: 'The current email of the user.',
        type: ['string'],
      },
      loginAttemptsCleaned: {
        description:
          'The number of login attempts cleaned from the database after successful verification.',
        type: ['number'],
      },
      organizationId: {
        description: 'The current organizationID of the user',
        type: ['string', 'null'],
      },
    },
    required: ['organizationId', 'accountId', 'email'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Login Verification Complete',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'LoginVerificationInitiated' track call.
 *
 * @param LoginVerificationInitiated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function loginVerificationInitiated(
  props: LoginVerificationInitiated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Login_Verification_Initiated',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a login verification flow is initiated.',
    properties: {
      accountId: { description: 'The accountId of the user', type: ['string'] },
      email: {
        description: 'The current email of the user.',
        type: ['string'],
      },
      organizationId: {
        description: 'The current organizationID of the user',
        type: ['string', 'null'],
      },
    },
    required: ['organizationId', 'accountId', 'email'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Login Verification Initiated',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'MarketingEmailsDisabled' track call.
 *
 * @param MarketingEmailsDisabled props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function marketingEmailsDisabled(
  props: MarketingEmailsDisabled,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Marketing_Emails_Disabled',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user opt-out of receiving marketing emails?',
    properties: {
      organizationId: {
        description:
          'The organization ID of the user that disabled marketing emails',
        type: ['string'],
      },
    },
    required: ['properties'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Marketing Emails Disabled',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'MarketingEmailsEnabled' track call.
 *
 * @param MarketingEmailsEnabled props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function marketingEmailsEnabled(
  props: MarketingEmailsEnabled,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Marketing_Emails_Enabled',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user opt-in to receiving marketing emails?',
    properties: {
      organizationId: {
        description:
          'The organization ID of the user that enabled marketing emails',
        type: ['string'],
      },
    },
    required: ['properties'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Marketing Emails Enabled',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'MastodonChannelPromoDismissed' track call.
 *
 * @param MastodonChannelPromoDismissed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function mastodonChannelPromoDismissed(
  props: MastodonChannelPromoDismissed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Mastodon_Channel_Promo_Dismissed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user dismisses the Mastodon Channel promo banner',
    properties: {
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern: 'publish|buffer|analyze|engage',
        type: ['string'],
      },
    },
    required: ['organizationId', 'clientName', 'product'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Mastodon Channel Promo Dismissed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'MediaAdded' track call.
 *
 * @param MediaAdded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function mediaAdded(
  props: MediaAdded,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Media_Added',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Where did the user attach a media item from',
    properties: {
      clientId: {
        description:
          'What was the unique identifier of the client the user was in when they attached the media item?',
        type: ['string', 'null'],
      },
      clientName: {
        description:
          'Which client was the user in when they attached the media item? IE, `publishIos`, `publishWeb`, `publishAndroid`.',
        pattern: 'publishIos|publishAndroid',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that added the media',
        type: ['string'],
      },
      product: {
        description: 'The product for which the media item was attached in',
        pattern: 'publish',
        type: ['string'],
      },
      source: {
        description: 'The selected source for media',
        pattern: 'camera|gallery|documents|unsplash|url|giphy|imagePlayground',
        type: ['string'],
      },
    },
    required: ['product', 'source', 'clientName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Media Added',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'MediaSourceSelected' track call.
 *
 * @param MediaSourceSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function mediaSourceSelected(
  props: MediaSourceSelected,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Media_Source_Selected',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'What source did the user select to attach media from',
    properties: {
      clientId: {
        description:
          'What was the unique identifier of the client the user was in when they attached the media item?',
        type: ['string', 'null'],
      },
      clientName: {
        description:
          'Which client was the user in when they attached the media item? IE, `publishIos`, `publishWeb`, `publishAndroid`.',
        pattern: 'publishIos|publishAndroid',
        type: ['string'],
      },
      organizationId: {
        description:
          'The organization ID of the user that selected the media source',
        type: ['string'],
      },
      product: {
        description: 'The product for which the media source was selected',
        pattern: 'publish',
        type: ['string'],
      },
      source: {
        description: 'The selected source for media',
        pattern: 'camera|gallery|documents|unsplash|url|giphy|imagePlayground',
        type: ['string'],
      },
    },
    required: ['product', 'source', 'clientName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Media Source Selected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'MediaValidationWarningMessageViewed' track call.
 *
 * @param MediaValidationWarningMessageViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function mediaValidationWarningMessageViewed(
  props: MediaValidationWarningMessageViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Media_Validation_Warning_Message_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'The warning message displayed when media validation fails',
    properties: {
      channel: {
        description: 'The channel that the media was intended for.',
        pattern:
          'instagram|facebook|linkedin|twitter|tiktok|pinterest|googlebusiness|mastodon|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when the banner was viewed?',
        type: ['string'],
      },
      message: {
        description: 'The message displayed to the user.',
        type: ['string', 'null'],
      },
    },
    required: ['channel'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Media Validation Warning Message Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'MigrationCheckoutModalViewed' track call.
 *
 * @param MigrationCheckoutModalViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function migrationCheckoutModalViewed(
  props: MigrationCheckoutModalViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Migration_Checkout_Modal_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user view the migration checkout modal?',
    properties: {
      cta: {
        description:
          'If the user navigated to this page from a CTA on another Buffer page, which call to action was it?',
        type: ['string', 'null'],
      },
      ctaApp: {
        description:
          'Which product or website is the CTA located in? Examples would be either the product, like `publish`, or the website, like `bufferSite`',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA?  In many cases it makes sense to describe the intended result of the CTA, like `proTrial` or `publishPro`',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in Publish app shell menu',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA?  If setting the value for the first time, set to `1`',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'What website page or app view is the CTA located?  Examples would be, `composer` or `analyticsOverview` for Publish, and `pricingPublish` for the Publish pricing page on the Buffer marketing site',
        type: ['string', 'null'],
      },
      interval: {
        description: 'The plan price interval a customer migrates to.',
        type: ['string', 'null'],
      },
      legacyPlanPrice: {
        description: 'The price of the legacy Multi Product plan',
        type: ['number', 'null'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the migration page.',
        type: ['string', 'null'],
      },
      plan: {
        description: 'The plan name the customer migrates to.',
        type: ['string', 'null'],
      },
      quantity: {
        description:
          'The plan quantity (channel slots) that the customer is purchasing with the migration.',
        type: ['number', 'null'],
      },
    },
    required: ['organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Migration Checkout Modal Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'MigrationPageViewed' track call.
 *
 * @param MigrationPageViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function migrationPageViewed(
  props: MigrationPageViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Migration_Page_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user view the migration page?',
    properties: {
      canMigrate: {
        description:
          'If the user can migrate the legacy organization to New Buffer or not.',
        type: ['boolean', 'null'],
      },
      cta: {
        description:
          'If the user navigated to this page from a CTA on another Buffer page, which call to action was it?',
        type: ['string', 'null'],
      },
      ctaApp: {
        description:
          'Which product or website is the CTA located in? Examples would be either the product, like `publish`, or the website, like `bufferSite`',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA?  In many cases it makes sense to describe the intended result of the CTA, like `proTrial` or `publishPro`',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in Publish app shell menu',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA?  If setting the value for the first time, set to `1`',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'What website page or app view is the CTA located?  Examples would be, `composer` or `analyticsOverview` for Publish, and `pricingPublish` for the Publish pricing page on the Buffer marketing site',
        type: ['string', 'null'],
      },
      discountId: {
        description: 'The Stripe customer discount id, if any.',
        type: ['string', 'null'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the migration page.',
        type: ['string', 'null'],
      },
      reasons: {
        description:
          'A list of the reasons an organization cannot migrate, if applicable.',
        type: ['array', 'null'],
      },
    },
    required: ['organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Migration Page Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ModalDismissed' track call.
 *
 * @param ModalDismissed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function modalDismissed(
  props: ModalDismissed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Modal_Dismissed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user dismiss a modal?',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      hasPaymentDetails: {
        description:
          'At time of event, did the user have payment details saved?',
        type: ['boolean', 'null'],
      },
      method: {
        description: 'The method by which the user dismissed the modal.',
        type: ['string', 'null'],
      },
      modalName: {
        description: 'The name of the modal that the user dismissed.',
        type: ['string', 'null'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the plan selector.',
        type: ['string', 'null'],
      },
    },
    required: ['organizationId', 'hasPaymentDetails', 'modalName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Modal Dismissed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ModalPaymentOpened' track call.
 *
 * @param ModalPaymentOpened props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function modalPaymentOpened(
  props: ModalPaymentOpened,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Modal_Payment_Opened',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user open the payment modal?',
    properties: {
      cta: {
        description:
          'Which call to action did the user click to get to the payment modal?',
        type: ['string', 'null'],
      },
      ctaApp: {
        description:
          'Which product is the CTA located in? Examples would be either the product, like `publish`.',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA?  In many cases it makes sense to describe the intended result of the CTA, like `proTrial` or `publishPro`.',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in Publish app shell menu.',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA?  If setting the value for the first time, set to `1`.',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'What app view is the CTA located in?  Examples would be, `composer` or `analyticsOverview` for Publish.',
        type: ['string', 'null'],
      },
      organizationId: {
        description: 'The Global organization ID.',
        type: ['string'],
      },
      planId: {
        description:
          'What is the ID of the billing plan that the user selected to purchase?',
        type: ['string', 'null'],
      },
      planName: {
        description:
          'What is the name of the billing plan that the user selected to purchase?',
        type: ['string', 'null'],
      },
      product: {
        description: 'What product did this event occur in or for?',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: ['product'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Modal Payment Opened',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'NPSSurveyCompleted' track call.
 *
 * @param NPSSurveyCompleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function nPSSurveyCompleted(
  props: NPSSurveyCompleted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'NPS_Survey_Completed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user submits an NPS survey through Wootric.',
    properties: {
      email: {
        description: 'The email address of the user that completed the survey',
        type: ['string'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that completed the survey.',
        type: ['string'],
      },
      plan: {
        description:
          'The plan of the user that completed the survey (ex. "Pro")',
        type: ['string'],
      },
      platform: {
        description:
          'The platform the user was in when they completed the survey (ex. "web").',
        type: ['string'],
      },
      product: {
        description: 'Which product did the user fill out the survey in?',
        type: ['string'],
      },
      score: {
        description: 'The NPS score given by the respondent.',
        type: ['integer'],
      },
      surveyProvider: {
        description: 'The provider of the NPS survey (ex. "Wootric").',
        type: ['string'],
      },
      text: {
        description: 'The reason given by the user for the NPS score.',
        type: ['string', 'null'],
      },
    },
    required: [
      'product',
      'organizationId',
      'surveyProvider',
      'plan',
      'score',
      'text',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'NPS Survey Completed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'NoteAdded' track call.
 *
 * @param NoteAdded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function noteAdded(
  props: NoteAdded,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Note_Added',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user has added a Note',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string', 'null'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string', 'null'],
      },
      channelType: {
        description:
          'The type of channel the note is created for, ex. "page", "group"',
        type: ['string', 'null'],
      },
      channelUsername: {
        description: 'What is the username of the channel?',
        type: ['string', 'null'],
      },
      clientName: {
        description: 'Which client was the user in when the Note was created?',
        pattern:
          'publishWeb|publishIos|publishAndroid|publishExtension|core|unknown',
        type: ['string'],
      },
      collaborators: {
        description:
          'The list of email of all persons involved in the thread who are not the not author',
        type: ['array'],
      },
      entityCreatorEmail: {
        description: 'The email for the account of the creator of the entity',
        type: ['string'],
      },
      entityCreatorId: {
        description:
          'The database id for the account of the creator of the entity',
        type: ['string'],
      },
      entityId: {
        description:
          'The database id for the entity document where the Note was added (postId, ideaId, ...)',
        type: ['string'],
      },
      entityStatus: {
        description:
          'the state of the parent entity at the moment of the Note (e.g., draft|scheduled|sent|rejected|archived|deleted)',
        type: ['string', 'null'],
      },
      entityText: {
        description: 'The content of the parent entity',
        type: ['string'],
      },
      entityType: {
        description: 'The type of the parent entity where the Note was added',
        pattern: 'post|idea',
        type: ['string'],
      },
      noteDeepLink: {
        description: 'The link to the Note in the app',
        type: ['string'],
      },
      noteId: {
        description: 'The database id for the Note document',
        type: ['string'],
      },
      noteText: { description: 'The content of the Note', type: ['string'] },
      noteType: {
        description: 'The type of Note (userGenerated, bufferGenerated, ...)',
        pattern: 'userGenerated|bufferGenerated',
        type: ['string'],
      },
      organizationId: {
        description: 'The global organization id that Note belongs to.',
        type: ['string'],
      },
    },
    required: [
      'noteId',
      'noteType',
      'noteText',
      'noteDeepLink',
      'entityId',
      'entityType',
      'entityStatus',
      'entityText',
      'organizationId',
      'clientName',
      'channelId',
      'channel',
      'channelUsername',
      'channelType',
      'entityCreatorId',
      'entityCreatorEmail',
      'collaborators',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Note Added',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'NoteDeleted' track call.
 *
 * @param NoteDeleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function noteDeleted(
  props: NoteDeleted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Note_Deleted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user has deleted a Note',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string', 'null'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string', 'null'],
      },
      channelType: {
        description:
          'The type of channel the note is created for, ex. "page", "group"',
        type: ['string', 'null'],
      },
      channelUsername: {
        description: 'What is the username of the channel?',
        type: ['string', 'null'],
      },
      clientName: {
        description: 'Which client was the user in when the Note was created?',
        pattern:
          'publishWeb|publishIos|publishAndroid|publishExtension|core|unknown',
        type: ['string'],
      },
      collaborators: {
        description:
          'The list of email of all persons involved in the thread who are not the not author',
        type: ['array'],
      },
      entityCreatorEmail: {
        description: 'The email for the account of the creator of the entity',
        type: ['string'],
      },
      entityCreatorId: {
        description:
          'The database id for the account of the creator of the entity',
        type: ['string'],
      },
      entityId: {
        description:
          'The database id for the entity document where the Note was deleted (postId, ideaId, ...)',
        type: ['string'],
      },
      entityStatus: {
        description:
          'the state of the parent entity at the moment of the Note (e.g., draft|scheduled|sent|rejected|archived|deleted)',
        type: ['string', 'null'],
      },
      entityText: {
        description: 'The content of the parent entity',
        type: ['string'],
      },
      entityType: {
        description: 'The type of the parent entity where the Note was deleted',
        pattern: 'post|idea',
        type: ['string'],
      },
      noteDeepLink: {
        description: 'The link to the Note in the app',
        type: ['string'],
      },
      noteId: {
        description: 'The database id for the Note document',
        type: ['string'],
      },
      noteText: { description: 'The content of the Note', type: ['string'] },
      noteType: {
        description: 'The type of Note (userGenerated, bufferGenerated, ...)',
        pattern: 'userGenerated|bufferGenerated',
        type: ['string'],
      },
      organizationId: {
        description: 'The global organization id that Note belongs to.',
        type: ['string'],
      },
    },
    required: [
      'noteId',
      'noteType',
      'noteText',
      'noteDeepLink',
      'entityId',
      'entityType',
      'entityStatus',
      'entityText',
      'organizationId',
      'clientName',
      'channelId',
      'channel',
      'channelUsername',
      'channelType',
      'entityCreatorId',
      'entityCreatorEmail',
      'collaborators',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Note Deleted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'NoteEdited' track call.
 *
 * @param NoteEdited props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function noteEdited(
  props: NoteEdited,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Note_Edited',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user has edited a Note',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string', 'null'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string', 'null'],
      },
      channelType: {
        description:
          'The type of channel the note is created for, ex. "page", "group"',
        type: ['string', 'null'],
      },
      channelUsername: {
        description: 'What is the username of the channel?',
        type: ['string', 'null'],
      },
      clientName: {
        description: 'Which client was the user in when the Note was created?',
        pattern:
          'publishWeb|publishIos|publishAndroid|publishExtension|core|unknown',
        type: ['string'],
      },
      collaborators: {
        description:
          'The list of email of all persons involved in the thread who are not the not author',
        type: ['array'],
      },
      entityCreatorEmail: {
        description: 'The email for the account of the creator of the entity',
        type: ['string'],
      },
      entityCreatorId: {
        description:
          'The database id for the account of the creator of the entity',
        type: ['string'],
      },
      entityId: {
        description:
          'The database id for the entity document where the Note was edited (postId, ideaId, ...)',
        type: ['string'],
      },
      entityStatus: {
        description:
          'the state of the parent entity at the moment of the Note (e.g., draft|scheduled|sent|rejected|archived|deleted)',
        type: ['string', 'null'],
      },
      entityText: {
        description: 'The content of the parent entity',
        type: ['string'],
      },
      entityType: {
        description: 'The type of the parent entity where the Note was edited',
        pattern: 'post|idea',
        type: ['string'],
      },
      noteDeepLink: {
        description: 'The link to the Note in the app',
        type: ['string'],
      },
      noteId: {
        description: 'The database id for the Note document',
        type: ['string'],
      },
      noteText: { description: 'The content of the Note', type: ['string'] },
      noteType: {
        description: 'The type of Note (userGenerated, bufferGenerated, ...)',
        pattern: 'userGenerated|bufferGenerated',
        type: ['string'],
      },
      organizationId: {
        description: 'The global organization id that Note belongs to.',
        type: ['string'],
      },
    },
    required: [
      'noteId',
      'noteType',
      'noteText',
      'noteDeepLink',
      'entityId',
      'entityType',
      'entityStatus',
      'entityText',
      'organizationId',
      'clientName',
      'channelId',
      'channel',
      'channelUsername',
      'channelType',
      'entityCreatorId',
      'entityCreatorEmail',
      'collaborators',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Note Edited',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'NoteFeedViewed' track call.
 *
 * @param NoteFeedViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function noteFeedViewed(
  props: NoteFeedViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Note_Feed_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a the Note Feed is viewed by a user',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string', 'null'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string', 'null'],
      },
      channelType: {
        description: 'The type of channel for, ex. "page", "group"',
        type: ['string', 'null'],
      },
      channelUsername: {
        description: 'What is the username of the channel?',
        type: ['string', 'null'],
      },
      clientName: {
        description:
          'Which client was the user in when the Note Feed was viewed?',
        type: ['string'],
      },
      entityId: {
        description:
          'The database id for the entity document (postId, ideaId, ...) of the Note Feed',
        type: ['string'],
      },
      entityStatus: {
        description:
          'the state of the parent entity at the moment of the Note Feed is opened (e.g., draft|scheduled|sent|rejected|archived|deleted)',
        type: ['string', 'null'],
      },
      entityText: {
        description: 'The content of the parent entity',
        type: ['string'],
      },
      entityType: {
        description: 'The type of the parent entity of the Note Feed',
        pattern: 'post|idea',
        type: ['string'],
      },
      organizationId: {
        description: 'The global organization id that Note Feed belongs to.',
        type: ['string'],
      },
    },
    required: [
      'entityId',
      'entityType',
      'entityStatus',
      'entityText',
      'organizationId',
      'clientName',
      'channelId',
      'channel',
      'channelUsername',
      'channelType',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Note Feed Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'OnboardingNotificationPermissionSelected' track call.
 *
 * @param OnboardingNotificationPermissionSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function onboardingNotificationPermissionSelected(
  props: OnboardingNotificationPermissionSelected,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Onboarding_Notification_Permission_Selected',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When a user interacts with the notifications permission screen during onboarding.',
    properties: {
      clientName: {
        description:
          'The mobile client the user is on, `publishIos` or `publishAndroid`.',
        pattern: 'publishIos|publishAndroid',
        type: ['string'],
      },
      enabled: {
        description: 'Did the user select to enable notifications?',
        type: ['boolean'],
      },
      product: {
        description: 'Which Buffer product was the notification for?',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: ['clientName', 'enabled', 'product'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Onboarding Notification Permission Selected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'OnboardingSkipped' track call.
 *
 * @param OnboardingSkipped props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function onboardingSkipped(
  props: OnboardingSkipped,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Onboarding_Skipped',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did this user skip the onboarding experience?',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      organizationId: {
        description:
          'The ID of the organization for which the onboarding was skipped',
        type: ['string'],
      },
      product: {
        description: 'Which product was the onboarding skipped for?',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create|core',
        type: ['string'],
      },
      step: {
        description:
          'Which onboarding step was the user in when they decided to skip?',
        pattern: 'productSelection|channelConnection',
        type: ['string'],
      },
    },
    required: ['product'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Onboarding Skipped',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'OnboardingSlideViewed' track call.
 *
 * @param OnboardingSlideViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function onboardingSlideViewed(
  props: OnboardingSlideViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Onboarding_Slide_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a prospective user views a slide within the Onboarding.',
    properties: {
      clientName: {
        description:
          'The mobile client the user is on, `publishIos` or `publishAndroid`.',
        type: ['string'],
      },
      product: {
        description: 'Which Buffer product was the notification for?',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      slide: {
        description: 'Which slide did the user view?',
        pattern:
          'grow_audience|manage_social|build_following|advanced_features',
        type: ['string'],
      },
    },
    required: ['clientName', 'slide', 'product'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Onboarding Slide Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'OnboardingSurveyCompleted' track call.
 *
 * @param OnboardingSurveyCompleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function onboardingSurveyCompleted(
  props: OnboardingSurveyCompleted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Onboarding_Survey_Completed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When a user completes the onboarding survey and clicks in Done button.',
    properties: {
      clientName: {
        description: 'The name of the client that the event occurred in.',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the product solutions.',
        type: ['string'],
      },
      product: {
        description: 'The product in which this action occurred.',
        type: ['string'],
      },
      questionOneAnswers: {
        description: 'The answers selected by the user for the first question.',
        type: ['array'],
      },
      questionOneId: {
        description:
          'The id of the first question of the survey. It is a meaningful id that identifies the question.',
        type: ['string'],
      },
      questionThreeAnswers: {
        description: 'The answers selected by the user for the third question.',
        type: ['array'],
      },
      questionThreeId: {
        description:
          'The id of the third question of the survey. It is a meaningful id that identifies the question.',
        type: ['string'],
      },
      questionTwoAnswers: {
        description:
          'The answers selected by the user for the second question.',
        type: ['array'],
      },
      questionTwoId: {
        description:
          'The id of the second question of the survey. It is a meaningful id that identifies the question.',
        type: ['string'],
      },
      surveyId: {
        description: 'Identifies the survey answered by the user.',
        type: ['string'],
      },
    },
    required: [
      'product',
      'organizationId',
      'clientName',
      'surveyId',
      'questionOneId',
      'questionOneAnswers',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Onboarding Survey Completed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'OnboardingSurveySkipped' track call.
 *
 * @param OnboardingSurveySkipped props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function onboardingSurveySkipped(
  props: OnboardingSurveySkipped,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Onboarding_Survey_Skipped',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When a user clicks in the Skip button in the onboarding survey.',
    properties: {
      clientName: {
        description: 'The name of the client that the event occurred in.',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the product solutions.',
        type: ['string'],
      },
      product: {
        description: 'The product in which this action occurred.',
        type: ['string'],
      },
      surveyId: {
        description: 'Identifies the survey answered by the user.',
        type: ['string'],
      },
    },
    required: ['product', 'organizationId', 'clientName', 'surveyId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Onboarding Survey Skipped',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'OnboardingSurveyViewed' track call.
 *
 * @param OnboardingSurveyViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function onboardingSurveyViewed(
  props: OnboardingSurveyViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Onboarding_Survey_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user views the onboarding survey.',
    properties: {
      clientName: {
        description: 'The name of the client that the event occurred in.',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the product solutions.',
        type: ['string'],
      },
      product: {
        description: 'The product in which this action occurred.',
        type: ['string'],
      },
      surveyId: {
        description: 'Identifies the survey answered by the user.',
        type: ['string'],
      },
    },
    required: ['product', 'organizationId', 'clientName', 'surveyId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Onboarding Survey Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'OnboardingTrialPromptViewed' track call.
 *
 * @param OnboardingTrialPromptViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function onboardingTrialPromptViewed(
  props: OnboardingTrialPromptViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Onboarding_Trial_Prompt_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user views the onboarding trial prompt.',
    properties: {
      clientName: {
        description: 'The name of the client that the event occurred in.',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the product solutions.',
        type: ['string'],
      },
      product: {
        description: 'The product in which this action occurred.',
        type: ['string'],
      },
    },
    required: ['product', 'organizationId', 'clientName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Onboarding Trial Prompt Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'OneBufferMigrated' track call.
 *
 * @param OneBufferMigrated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function oneBufferMigrated(
  props: OneBufferMigrated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'One_Buffer_Migrated',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When was the organization migrated from multi-product Buffer to One Buffer? This event will be attributed to the org owner in all three migration methods (advocacy, self service, and scripts). This event tracks the migration to One Buffer at the organization level, as the organization is the determining factor for a user to access One Buffer.  A user can be a member of more than one organization, and those organizations can be a mixture of One Buffer enabled / not-enabled.',
    properties: {
      createdByAccountId: {
        description:
          'What was the Buffer global account id that created the organization? This is the organization owner in the multi-product organization structure.',
        type: ['string'],
      },
      didPriceIncrease: {
        description:
          'Binary option if their price went up. Same price or lower price == false.',
        type: ['boolean', 'null'],
      },
      migrationMethod: {
        description:
          'How was the user migrated to One Buffer?  Did a Buffer Customer Advocate help the org owner migrate, did the org owner migrate themselves via a button in-app, or was the org migrated via a database script?',
        pattern: 'advocacy|selfService|script|hub',
        type: ['string'],
      },
      organizationId: {
        description: 'The id of the Global organization that was created.',
        type: ['string'],
      },
      planMigratedTo: {
        description:
          'What was the One Buffer plan the organization was migrated to? Plan names the organization was migrated to should be free, essentials, essentials + team, or a stripe plan id.',
        type: ['string', 'null'],
      },
      priceDifferential: {
        description:
          'The absolute difference in old price vs new price. May be positive (they pay more on New Buffer) or negative (they pay less on New Buffer).',
        type: ['number', 'null'],
      },
    },
    required: ['organizationId', 'createdByAccountId', 'migrationMethod'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'One Buffer Migrated',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'OrganizationCreated' track call.
 *
 * @param OrganizationCreated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function organizationCreated(
  props: OrganizationCreated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Organization_Created',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When was the organization id created in the production database?',
    properties: {
      createdByAccountId: {
        description:
          'What was the Buffer global account id that created the organization?',
        type: ['string'],
      },
      organizationId: {
        description: 'The id of the Global organization that was created.',
        type: ['string'],
      },
      organizationPlanId: {
        description:
          'Which plan was the organization on at time of being created?',
        type: ['string'],
      },
      product: {
        description: 'Which product was the organization created for?',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: ['organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Organization Created',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'OrganizationDeleted' track call.
 *
 * @param OrganizationDeleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function organizationDeleted(
  props: OrganizationDeleted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Organization_Deleted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When was the organization id deleted in the production database?',
    properties: {
      createdByAccountId: {
        description:
          'What was the Buffer global account id that deleted the organization?',
        type: ['string'],
      },
      organizationId: {
        description: 'The id of the Global organization that was deleted.',
        type: ['string'],
      },
      organizationPlanId: {
        description:
          'Which plan was the organization on at time of being deleted? This will only be populated for One Buffer organizations, as multi-product organizations can have multiple subscriptions.',
        type: ['string'],
      },
    },
    required: ['organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Organization Deleted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'OrganizationSwitched' track call.
 *
 * @param OrganizationSwitched props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function organizationSwitched(
  props: OrganizationSwitched,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Organization_Switched',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'A user switches organizations',
    properties: {
      clientName: {
        description:
          'The client in which the action occurred, `publishIos`, `publishAndroid`or `publishWeb`.',
        type: ['string'],
      },
      organizationId: {
        description:
          'The ID of the global organization that the user switched to.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occured',
        pattern: 'publish|analyze|engage|start_pages|account|marketing|buffer',
        type: ['string'],
      },
    },
    required: ['product', 'organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Organization Switched',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'OrganizationTimezoneUpdated' track call.
 *
 * @param OrganizationTimezoneUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function organizationTimezoneUpdated(
  props: OrganizationTimezoneUpdated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Organization_Timezone_Updated',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: "When was an organization's timezone updated?",
    properties: {
      organizationId: {
        description: 'The active organization of the user that took the action',
        type: ['string'],
      },
      previousTimezone: {
        description: "What was the organization's previous timezone?",
        type: ['string', 'null'],
      },
      timezone: {
        description: "What is the organization's new timezone?",
        type: ['string'],
      },
      updateMethod: {
        description:
          'How was the timezone updated? (e.g. user-initiated, backend via first post, or frontend via appshell)',
        pattern: 'user|backend|frontend',
        type: ['string'],
      },
    },
    required: ['organizationId', 'updateMethod', 'timezone'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Organization Timezone Updated',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'OrganizationTokensExhausted' track call.
 *
 * @param OrganizationTokensExhausted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function organizationTokensExhausted(
  props: OrganizationTokensExhausted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Organization_Tokens_Exhausted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When the tokens of an organization are over due to usage or subscription downgrade.',
    properties: {
      currentChannelSlots: {
        description:
          'What is the number of the purchased channels of the organization?',
        type: ['number'],
      },
      currentPlan: {
        description: 'What was the One Buffer plan of the the organization?',
        type: ['string'],
      },
      isTrial: {
        description:
          'Was the organization on a a Free trial when the tokens were exhausted?',
        type: ['boolean'],
      },
      organizationId: { description: 'The organization ID', type: ['string'] },
      previousChannelSlots: {
        description:
          'What is the number of the purchased channels of the organization?',
        type: ['number', 'null'],
      },
      previoustPlan: {
        description: 'What was the One Buffer plan of the the organization?',
        type: ['string', 'null'],
      },
      reason: {
        description:
          'Did the customer run out of tokens based on usage, due to a downgrade?',
        pattern: 'usage|downgrade',
        type: ['string'],
      },
      tokensLimit: {
        description: 'What is the limit of the current plan?',
        type: ['number'],
      },
      usedTokensCount: {
        description:
          'What was the value of the Organization tokens used at the moment that they were exhausted?',
        type: ['number'],
      },
    },
    required: [
      'organizationId',
      'currentPlan',
      'isTrial',
      'usedTokensCount',
      'tokensLimit',
      'currentChannelSlots',
      'reason',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Organization Tokens Exhausted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'OverviewAnalyticsDataExported' track call.
 *
 * @param OverviewAnalyticsDataExported props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function overviewAnalyticsDataExported(
  props: OverviewAnalyticsDataExported,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Overview_Analytics_Data_Exported',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When did the user export their data from the Overview report?',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelServiceId: {
        description: 'The id of the channel on the given service',
        type: ['string'],
      },
      channelType: {
        description:
          'The type of channel the post is created for, ex. "page", "group"',
        type: ['string'],
      },
      channelUsername: {
        description: 'What was the username of the channel?',
        type: ['string'],
      },
      fileType: {
        description: 'Did the user export a CSV or image files?',
        type: ['string'],
      },
      organizationId: {
        description: 'The ID of the Global organization that owns the profile.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occured',
        pattern: 'publish',
        type: ['string'],
      },
    },
    required: [
      'product',
      'channel',
      'channelId',
      'channelServiceId',
      'channelType',
      'fileType',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Overview Analytics Data Exported',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'OverviewAnalyticsMetricUpdated' track call.
 *
 * @param OverviewAnalyticsMetricUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function overviewAnalyticsMetricUpdated(
  props: OverviewAnalyticsMetricUpdated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Overview_Analytics_Metric_Updated',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When did the user update the metric displayed in the Overview report?',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelServiceId: {
        description: 'The id of the channel on the given service',
        type: ['string'],
      },
      channelType: {
        description:
          'The type of channel the post is created for, ex. "page", "group"',
        type: ['string'],
      },
      channelUsername: {
        description: 'What was the username of the channel?',
        type: ['string'],
      },
      metric: {
        description: 'What metric did the user switch to?',
        type: ['string'],
      },
      organizationId: {
        description: 'The ID of the Global organization that owns the profile.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occured',
        pattern: 'publish',
        type: ['string'],
      },
    },
    required: [
      'product',
      'channel',
      'channelId',
      'channelServiceId',
      'channelType',
      'metric',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Overview Analytics Metric Updated',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'OverviewInsightsMetricSelected' track call.
 *
 * @param OverviewInsightsMetricSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function overviewInsightsMetricSelected(
  props: OverviewInsightsMetricSelected,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Overview_Insights_Metric_Selected',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user selects a metric in the overview tab in Analyze',
    properties: {
      channel: {
        description: 'The channel of the selected profile, ex. “instagram”.',
        type: ['string'],
      },
      channelId: {
        description:
          'The ID of the channel selected when the metric was selected.',
        type: ['string'],
      },
      clientName: {
        description: 'The name of the client in which the action occurred.',
        type: ['string'],
      },
      metric: { description: 'Which metric was selected?', type: ['string'] },
      organizationId: {
        description:
          'The global organization ID of the user that took the action.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred, ex. “analyze”.',
        type: ['string'],
      },
    },
    required: [
      'product',
      'organizationId',
      'clientName',
      'channel',
      'channelId',
      'metric',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Overview Insights Metric Selected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'OverviewInsightsPreviousPeriodToggled' track call.
 *
 * @param OverviewInsightsPreviousPeriodToggled props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function overviewInsightsPreviousPeriodToggled(
  props: OverviewInsightsPreviousPeriodToggled,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Overview_Insights_Previous_Period_Toggled',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user toggles previous period in Analyze.',
    properties: {
      channel: {
        description: 'The channel of the selected profile, ex. “instagram”.',
        type: ['string'],
      },
      channelId: {
        description:
          'The ID of the channel selected when the previous period was toggled.',
        type: ['string'],
      },
      clientName: {
        description: 'The name of the client in which the action occurred.',
        type: ['string'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that took the action.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred, ex. “analyze”.',
        type: ['string'],
      },
    },
    required: [
      'product',
      'organizationId',
      'clientName',
      'channel',
      'channelId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Overview Insights Previous Period Toggled',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PabloImageDownloaded' track call.
 *
 * @param PabloImageDownloaded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function pabloImageDownloaded(
  props: PabloImageDownloaded,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Pablo_Image_Downloaded',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When an image created in Pablo is downloaded',
    properties: {
      imageSize: {
        description: 'The size of the downloaded image',
        type: ['string', 'null'],
      },
      organizationId: {
        description:
          'The global organization id of the user that took this action',
        type: ['string', 'null'],
      },
    },
    required: ['properties'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Pablo Image Downloaded',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PabloImageShared' track call.
 *
 * @param PabloImageShared props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function pabloImageShared(
  props: PabloImageShared,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Pablo_Image_Shared',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When an image created in Pablo is shared to Buffer',
    properties: {
      imageSize: {
        description: 'The size of the downloaded image',
        type: ['string', 'null'],
      },
      organizationId: {
        description:
          'The global organization id of the user that took this action',
        type: ['string', 'null'],
      },
    },
    required: ['properties'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Pablo Image Shared',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PageExported' track call.
 *
 * @param PageExported props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function pageExported(
  props: PageExported,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Page_Exported',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'How are people using the data export?',
    properties: {
      channel: {
        description:
          'Channel of the page, if any (e.g., ""facebook"", ""instagram"", etc.)',
        type: ['string'],
      },
      days: {
        description: 'Number of days the report is showing',
        type: ['integer'],
      },
      exportType: {
        description: 'The format of the export (ex. "csv", "images")',
        type: ['string'],
      },
      name: {
        description:
          'Human readable name of the page (e.g., ""overview"", ""posts"", etc.)',
        type: ['string'],
      },
      organizationId: {
        description: 'The id of the Global organization that owns the channel.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event happened',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: ['channel'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Page Exported',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PageViewed' track call.
 *
 * @param PageViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function pageViewed(
  props: PageViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Page_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When did the user view this specific page? This event is triggered when a user opens a new section of the application that is considered a distinct page, which may or may not change the URL.',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      channel: {
        description:
          'Channel of the page, if applicable (e.g., ""facebook"", ""instagram"", etc.)',
        type: ['string', 'null'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string', 'null'],
      },
      channelServiceId: {
        description: 'The id of the channel on the given service',
        type: ['string', 'null'],
      },
      channelType: {
        description: 'What is the type of channel? ex. "page", "group"',
        type: ['string', 'null'],
      },
      cta: {
        description:
          'If the user navigated to this page from a CTA on another Buffer page, which call to action was it?',
        type: ['string', 'null'],
      },
      ctaApp: {
        description:
          'Which product or website is the CTA located in? Examples would be either the product, like `publish`, or the website, like `bufferSite`',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA?  In many cases it makes sense to describe the intended result of the CTA, like `proTrial` or `publishPro`',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in Publish app shell menu',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA?  If setting the value for the first time, set to `1`',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'What website page or app view is the CTA located?  Examples would be, `composer` or `analyticsOverview` for Publish, and `pricingPublish` for the Publish pricing page on the Buffer marketing site',
        type: ['string', 'null'],
      },
      name: {
        description:
          'Human readable name of the page (e.g., ""overview"", ""posts"", etc.)',
        type: ['string', 'null'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the page.',
        type: ['string', 'null'],
      },
      path: {
        description:
          'The path typically refers to a file or directory on the web server.',
        type: ['string', 'null'],
      },
      platform: {
        description:
          'The platform on which the page view occurred (e.g. ""classic"", ""new_publish"", ""marketing"", ""ios"")',
        type: ['string', 'null'],
      },
      product: {
        description:
          'The product in which the page view occurred (e.g. ""publish"", ""analyze"")',
        type: ['string'],
      },
      referralCode: {
        description:
          'At time of event, does the user have a unique ReferralCode cookie in their session?',
        type: ['string', 'null'],
      },
      referrer: {
        description:
          'The address of the webpage which is linked to the resource being requested. By checking the referrer, the new webpage can see where the request originated.',
        type: ['string', 'null'],
      },
      search: {
        description: 'Anything after and including the query string.',
        type: ['string', 'null'],
      },
      title: {
        description:
          'The text that is visible on the browser title bar (or tab bar) which matches the <title></title> html tag that should be on all pages.',
        type: ['string', 'null'],
      },
      url: { description: 'The address of the page.', type: ['string'] },
    },
    required: ['path'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Page Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PasswordUpdated' track call.
 *
 * @param PasswordUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function passwordUpdated(
  props: PasswordUpdated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Password_Updated',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user updates their Buffer password.',
    properties: {
      clientName: {
        description: 'The name of the client that the event occurred in',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      organizationId: {
        description:
          'The organization ID of the user that completed the action',
        type: ['string'],
      },
      product: {
        description:
          'Which product did the user complete this action in? (e.g. core)',
        type: ['string'],
      },
    },
    required: ['product', 'organizationId', 'clientName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Password Updated',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PastRemindersStoryGroupsFilterClicked' track call.
 *
 * @param PastRemindersStoryGroupsFilterClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function pastRemindersStoryGroupsFilterClicked(
  props: PastRemindersStoryGroupsFilterClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Past_Reminders_Story_Groups_Filter_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When did the user access the filter for past story groups in the Past Reminders tab?',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        pattern: 'instagram',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel",
        type: ['string'],
      },
      clientId: {
        description:
          'What was the unique identifier of the client the user was in when they accessed the past story groups filter?',
        type: ['string', 'null'],
      },
      clientName: {
        description:
          'Which client was the user in when they dragged the story? IE, `publishIos`, `publishWeb`, `publishAndroid`.',
        type: ['string'],
      },
      organizationId: {
        description: 'The Global organization ID.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the past story groups were viewed',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: [
      'product',
      'channel',
      'channelId',
      'channelServiceId',
      'clientName',
      'organizationId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Past Reminders Story Groups Filter Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PaymentMethodAdded' track call.
 *
 * @param PaymentMethodAdded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function paymentMethodAdded(
  props: PaymentMethodAdded,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Payment_Method_Added',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Understand when a user adds a payment method',
    properties: {
      cardFingerprint: {
        description: 'Uniquely identifies the particular card number',
        type: ['string'],
      },
      cta: {
        description: 'The cta which prompted the payment method update',
        type: ['string', 'null'],
      },
      customerId: {
        description: 'Unique identifier for the Stripe customer',
        type: ['string'],
      },
      expirationDate: {
        description: 'The date the payment method expires on (ISO-8601)',
        type: ['string'],
      },
      gateway: {
        description: 'What triggered this event? e.g. Stripe or RevenueCat',
        type: ['string'],
      },
      isStripeCheckout: {
        description: 'Was the payment method added via Stripe Checkout?',
        type: ['boolean', 'null'],
      },
      organizationId: {
        description:
          'The ID of the organization for which the payment method was added.',
        type: ['string'],
      },
      paymentMethod: {
        description:
          "The type of payment method added. Typically this would be 'visa', 'mastercard', etc.",
        type: ['string'],
      },
      stripeEventId: {
        description: 'Stripe event id from the webhook event',
        type: ['string'],
      },
      upgradePathName: {
        description:
          'The upgrade path which prompted the payment method update',
        type: ['string', 'null'],
      },
    },
    required: ['paymentMethod', 'stripeEventId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Payment Method Added',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PaymentMethodExpiring' track call.
 *
 * @param PaymentMethodExpiring props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function paymentMethodExpiring(
  props: PaymentMethodExpiring,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Payment_Method_Expiring',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Is this payment method expiring in the next 30 days?',
    properties: {
      cardFingerprint: {
        description: 'Uniquely identifies the particular card number',
        type: ['string'],
      },
      customerId: {
        description: 'Unique identifier for the Stripe customer',
        type: ['string'],
      },
      daysToExpiry: {
        description: 'The number of days until this payment method expires',
        type: ['number'],
      },
      expirationDate: {
        description: 'The date the payment method expires on (ISO-8601)',
        type: ['string'],
      },
      gateway: {
        description: 'What triggered this event? e.g. Stripe or RevenueCat',
        type: ['string'],
      },
      organizationId: {
        description:
          'The ID of the organization for which the payment method was added.',
        type: ['string'],
      },
      paymentMethod: {
        description:
          "The type of payment method expiring. Typically this would be 'visa', 'mastercard', etc.",
        type: ['string'],
      },
      stripeEventId: {
        description: 'Stripe event id from the webhook event',
        type: ['string'],
      },
    },
    required: ['paymentMethod', 'stripeEventId', 'organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Payment Method Expiring',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PaymentMethodUpdated' track call.
 *
 * @param PaymentMethodUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function paymentMethodUpdated(
  props: PaymentMethodUpdated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Payment_Method_Updated',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Understand when a user updates a payment method',
    properties: {
      cardFingerprint: {
        description: 'Uniquely identifies the particular card number',
        type: ['string'],
      },
      cta: {
        description: 'The cta which prompted the payment method update',
        type: ['string', 'null'],
      },
      customerId: {
        description: 'Unique identifier for the Stripe customer',
        type: ['string'],
      },
      expirationDate: {
        description: 'The date the payment method expires on (ISO-8601)',
        type: ['string'],
      },
      gateway: {
        description: 'What triggered this event? e.g. Stripe or RevenueCat',
        type: ['string'],
      },
      isStripeCheckout: {
        description: 'Was the payment method added via Stripe Checkout?',
        type: ['boolean', 'null'],
      },
      organizationId: {
        description:
          'The ID of the organization for which the payment method was added.',
        type: ['string'],
      },
      paymentMethod: {
        description:
          "The type of payment method added. Typically this would be 'visa', 'mastercard', etc.",
        type: ['string'],
      },
      stripeEventId: {
        description: 'Stripe event id from the webhook event',
        type: ['string'],
      },
      upgradePathName: {
        description:
          'The upgrade path which prompted the payment method update',
        type: ['string', 'null'],
      },
    },
    required: ['paymentMethod', 'stripeEventId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Payment Method Updated',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PaywallVideoPlayed' track call.
 *
 * @param PaywallVideoPlayed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function paywallVideoPlayed(
  props: PaywallVideoPlayed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Paywall_Video_Played',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user play a video?',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        type: ['string'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the plan selector.',
        type: ['string', 'null'],
      },
      product: {
        description: 'Which product was the user in when they logged out?',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      progress: {
        description: 'Percentage of video watched',
        type: ['number', 'null'],
      },
      upgradePathName: {
        description: 'The name of the upgrade path',
        type: ['string', 'null'],
      },
    },
    required: ['organizationId', 'upgradePathName', 'product'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Paywall Video Played',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PlanLimitAlertViewed' track call.
 *
 * @param PlanLimitAlertViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function planLimitAlertViewed(
  props: PlanLimitAlertViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Plan_Limit_Alert_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When the user is presented with our plan limit alert.',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook".',
        type: ['string'],
      },
      channelId: {
        description:
          'The database id of the channel for which the story was created.',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel.",
        type: ['string'],
      },
      channelType: {
        description: 'The type of channel ex. "page", "group".',
        type: ['string'],
      },
      clientName: {
        description:
          'The mobile client the user is on, `publishIos` or `publishAndroid`.',
        type: ['string'],
      },
      prompt: {
        description:
          'The action the user took to produce the upgrade view, such as "profile_limit".',
        type: ['string'],
      },
    },
    required: ['clientName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Plan Limit Alert Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PlanSelectorChannelQuantityClicked' track call.
 *
 * @param PlanSelectorChannelQuantityClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function planSelectorChannelQuantityClicked(
  props: PlanSelectorChannelQuantityClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Plan_Selector_Channel_Quantity_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'How many channels did the user select on the plan selector?',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      channelQuantity: {
        description:
          'The number of channels the user selected in the plan selector.',
        type: ['number', 'null'],
      },
      hasPaymentDetails: {
        description:
          'At time of event, did the user have payment details saved?',
        type: ['boolean', 'null'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the plan selector.',
        type: ['string', 'null'],
      },
    },
    required: ['organizationId', 'hasPaymentDetails'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Plan Selector Channel Quantity Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PlanSelectorMainCtaClicked' track call.
 *
 * @param PlanSelectorMainCtaClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function planSelectorMainCtaClicked(
  props: PlanSelectorMainCtaClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Plan_Selector_Main_Cta_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user click the plan selector main cta?',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      channelQuantity: {
        description:
          'The selected number of channels at the time the CTA button was clicked.',
        type: ['number', 'null'],
      },
      cta: {
        description: 'The cta that opened the plan selector.',
        type: ['string', 'null'],
      },
      ctaButtonText: {
        description: 'The text on the plan selector CTA button.',
        type: ['string', 'null'],
      },
      cycle: {
        description:
          'The selected billing cycle at the time the CTA button was clicked.',
        type: ['string', 'null'],
      },
      hasPaymentDetails: {
        description:
          'At time of event, did the user have payment details saved?',
        type: ['boolean', 'null'],
      },
      initialChannelQuantity: {
        description:
          'What was the initial channel quantity shown on the plan selector when opened?',
        type: ['number', 'null'],
      },
      initialCycle: {
        description:
          'What was the initial billing cycle shown on the plan selector when opened?',
        type: ['string', 'null'],
      },
      initialSelectedPlan: {
        description:
          'What was the initial selected plan shown on the plan selector when opened?',
        type: ['string', 'null'],
      },
      isAutomaticTaxEnabled: {
        description:
          'Is automatic tax collection in Stripe enabled for this user?',
        type: ['boolean', 'null'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the plan selector.',
        type: ['string', 'null'],
      },
      projectedCost: {
        description:
          'The projected cost of the selected plan at the time the CTA button was clicked.',
        type: ['number', 'null'],
      },
      selectedPlanName: {
        description:
          'The selected plan at the time the CTA button was clicked.',
        type: ['string', 'null'],
      },
      upgradePathName: {
        description: 'The name of the upgrade path',
        type: ['string', 'null'],
      },
    },
    required: ['organizationId', 'hasPaymentDetails'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Plan Selector Main Cta Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PlanSelectorPlanClicked' track call.
 *
 * @param PlanSelectorPlanClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function planSelectorPlanClicked(
  props: PlanSelectorPlanClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Plan_Selector_Plan_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Which plan did the user click within the Plan Selector?',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      channelQuantity: {
        description:
          'The selected number of channels at the time the CTA button was clicked.',
        type: ['number', 'null'],
      },
      hasPaymentDetails: {
        description:
          'At time of event, did the user have payment details saved?',
        type: ['boolean', 'null'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the plan selector.',
        type: ['string', 'null'],
      },
      selectedPlanName: {
        description: 'The name of the plan on which the user clicked.',
        type: ['string', 'null'],
      },
    },
    required: ['organizationId', 'hasPaymentDetails'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Plan Selector Plan Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PlanSelectorPlanCycleClicked' track call.
 *
 * @param PlanSelectorPlanCycleClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function planSelectorPlanCycleClicked(
  props: PlanSelectorPlanCycleClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Plan_Selector_Plan_Cycle_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Which cycle did the user toggle within the Plan Selector?',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      channelQuantity: {
        description:
          'The selected number of channels at the time the CTA button was clicked.',
        type: ['number', 'null'],
      },
      cycle: {
        description: 'The name of the billing cycle the user toggled.',
        type: ['string', 'null'],
      },
      hasPaymentDetails: {
        description:
          'At time of event, did the user have payment details saved?',
        type: ['boolean', 'null'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the plan selector.',
        type: ['string', 'null'],
      },
    },
    required: ['organizationId', 'hasPaymentDetails'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Plan Selector Plan Cycle Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PlanSelectorViewed' track call.
 *
 * @param PlanSelectorViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function planSelectorViewed(
  props: PlanSelectorViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Plan_Selector_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When did the user view the billing plan selector module, and which screen of the module did they view?',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      clientName: {
        description:
          'What was the name of the client the user was in when they viewed the plan selector',
        type: ['string', 'null'],
      },
      cta: {
        description:
          'If the user navigated to this page from a CTA on another Buffer page, which call to action was it?',
        type: ['string', 'null'],
      },
      ctaApp: {
        description:
          'Which product or website is the CTA located in? Examples would be either the product, like `publish`, or the website, like `bufferSite`',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA?  In many cases it makes sense to describe the intended result of the CTA, like `proTrial` or `publishPro`',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in Publish app shell menu',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA?  If setting the value for the first time, set to `1`',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'What website page or app view is the CTA located?  Examples would be, `composer` or `analyticsOverview` for Publish, and `pricingPublish` for the Publish pricing page on the Buffer marketing site',
        type: ['string', 'null'],
      },
      currentPlan: {
        description:
          'The current plan of the user at time of viewing the plan selector.',
        type: ['string', 'null'],
      },
      hasPaymentDetails: {
        description:
          'At time of event, did the user have payment details saved?',
        type: ['boolean', 'null'],
      },
      initialChannelQuantity: {
        description:
          'What was the initial channel quantity shown on the plan selector when opened?',
        type: ['number', 'null'],
      },
      initialCycle: {
        description:
          'What was the initial billing cycle shown on the plan selector when opened?',
        type: ['string', 'null'],
      },
      initialSelectedPlan: {
        description:
          'What was the initial selected plan shown on the plan selector when opened?',
        type: ['string', 'null'],
      },
      isAutomaticTaxEnabled: {
        description:
          'Is automatic tax collection in Stripe enabled for this user?',
        type: ['boolean', 'null'],
      },
      isStripeCheckout: {
        description: 'Will the user be sent to Stripe Checkout for payment?',
        type: ['boolean', 'null'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the plan selector.',
        type: ['string', 'null'],
      },
      screenName: {
        description:
          'Human readable name of the section of the plan selector viewed (e.g., ""plans"", ""billing_info"", etc.)',
        type: ['string', 'null'],
      },
      upgradePathName: {
        description: 'The name of the upgrade path',
        type: ['string', 'null'],
      },
    },
    required: ['organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Plan Selector Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PostCardActionClicked' track call.
 *
 * @param PostCardActionClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function postCardActionClicked(
  props: PostCardActionClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Post_Card_Action_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user clicks on a key action in the post',
    properties: {
      accountId: { description: 'The account ID', type: ['string'] },
      action: {
        description: 'The action name the user clicked on',
        type: ['string'],
      },
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      postId: {
        description: 'The post ID with the copied link',
        type: ['string'],
      },
    },
    required: [
      'organizationId',
      'accountId',
      'postId',
      'action',
      'channel',
      'channelId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Post Card Action Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PostCreated' track call.
 *
 * @param PostCreated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function postCreated(
  props: PostCreated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Post_Created',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'A user has created a post',
    properties: {
      aiAssisted: {
        description: 'Whether this post was assisted with AI',
        type: ['boolean', 'null'],
      },
      canvaDesignId: {
        description: 'The design id if a Canva image is attached to the post',
        type: ['string', 'null'],
      },
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelServiceId: {
        description: 'The id of the channel on the given service',
        type: ['string'],
      },
      channelType: {
        description:
          'The type of channel the post is created for, ex. "page", "group"',
        type: ['string'],
      },
      client: {
        description: 'What client sent the post',
        type: ['string', 'null'],
      },
      clientName: {
        description: 'Which client was the user in when the Post was created?',
        type: ['string'],
      },
      composerSource: {
        description: 'Where the composer was opened from',
        type: ['string', 'null'],
      },
      duplicatedFrom: {
        description: 'Post id of the original post that was duplicated',
        type: ['string'],
      },
      googleBusinessPostType: {
        description:
          "Post Type when the post is for Google Business Profile channel (e.g. What's New)",
        pattern: 'whats_new|offer|event|post',
        type: ['string', 'null'],
      },
      hasBufferHashtag: {
        description: 'Whether this post includes a Buffer hashtag',
        type: ['boolean'],
      },
      hasCanvaImage: {
        description:
          'Does the post contain an image from the Canva intergration?',
        type: ['boolean'],
      },
      hasContentWarning: {
        description: 'Mastodon content warning added to this update',
        type: ['boolean', 'null'],
      },
      hasFirstComment: {
        description: 'If a first comment added to this post',
        type: ['boolean'],
      },
      hasLinkAnnotations: {
        description: 'Whether the post has LinkedIn annotations',
        type: ['boolean'],
      },
      hasLocation: {
        description: 'If a geolocation tag was added to the post',
        type: ['boolean'],
      },
      hasMedia: {
        description: 'The post has either video or image media',
        type: ['boolean'],
      },
      hasMentions: {
        description: 'Whether the post has mentions',
        type: ['boolean'],
      },
      hasPictures: {
        description: 'If the update has pictures',
        type: ['boolean'],
      },
      hasShopGridLink: {
        description: "If a link was added for the channel's Shop Grid page",
        type: ['boolean'],
      },
      hasUploadcareImage: {
        description:
          'Does the post contain an image from the Uploadcare intergration?',
        type: ['boolean'],
      },
      hasUserTag: {
        description: 'Does this post include a user tag?',
        type: ['boolean'],
      },
      hasVideo: { description: 'If the update has video', type: ['boolean'] },
      ideaId: {
        description: 'The idea id from which the post was created',
        type: ['string'],
      },
      imageDimensions: {
        description:
          'The dimensions of the image or images attached to the post',
        type: ['array', 'null'],
      },
      imageFileExtension: {
        description: 'Image file extension',
        type: ['string'],
      },
      isDraft: {
        description: 'If the post created as a draft',
        type: ['boolean'],
      },
      isReelPost: {
        description:
          'Whether this is a Reel post for instagram or a regular post',
        type: ['boolean'],
      },
      isThreadPost: {
        description:
          'Whether this is a Thread post for twitter or a regular post',
        type: ['boolean'],
      },
      mediaCount: {
        description: 'Count of attachmed media (images or videos)',
        type: ['number'],
      },
      mediaType: {
        description:
          'What type of media did this post have attached? ex. "image", "video"',
        type: ['string', 'null'],
      },
      organizationId: {
        description: 'The global organization id that the channel belongs to.',
        type: ['string'],
      },
      postCreatedSource: {
        description:
          'Where was this post created from? (ex. calendar, queue, extension, third-party)',
        type: ['string'],
      },
      postId: {
        description: 'The database id for the update document',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occured',
        pattern: 'publish',
        type: ['string'],
      },
      schedulingType: {
        description: 'The scheduling type of the update',
        pattern: 'default|direct|reminder',
        type: ['string', 'null'],
      },
      shareDate: {
        description:
          'The date when the post was scheduled to be sent (ISO-8601)',
        type: ['string', 'null'],
      },
      shareType: {
        description: 'How the post was scheduled, ex. "queue", "share_now"',
        type: ['string'],
      },
      stickers: {
        description: 'Stickers added to this update',
        type: ['array'],
      },
      tagsCount: {
        description: 'Count of tags assigned to the Post',
        type: ['number', 'null'],
      },
      textLength: {
        description: 'Length of text on this update',
        type: ['number'],
      },
      threadedUpdatesCount: {
        description: 'If Threads post, how many threaded updates it contains',
        type: ['number', 'null'],
      },
      totalLinkCount: {
        description:
          'Total number of link attachments for this post (including threaded updates)',
        type: ['number'],
      },
      totalMediaCount: {
        description:
          'Total number of media uploaded for this post (including threaded updates)',
        type: ['number'],
      },
      updateType: {
        description:
          'Type of the post created (e.g. Event for GBP, Threads for Twitter)',
        type: ['string', 'null'],
      },
      videoAudioCodec: { description: 'Video audio codec', type: ['string'] },
      videoBitrate: { description: 'Video bitrate', type: ['number'] },
      videoDurationMillis: {
        description: 'Video duration in milliseconds',
        type: ['number'],
      },
      videoFileExtension: {
        description: 'Video file extension',
        type: ['string'],
      },
      videoFramerate: { description: 'Video framerate', type: ['number'] },
      videoHeight: { description: 'Video height', type: ['number'] },
      videoInitialFormat: {
        description: 'Video initial format',
        type: ['string'],
      },
      videoSizeMB: { description: 'Video size in MB', type: ['number'] },
      videoTranscodedFormat: {
        description: 'Video format after transcoding',
        type: ['string'],
      },
      videoWidth: { description: 'Video width', type: ['number'] },
    },
    required: [
      'product',
      'channel',
      'channelId',
      'channelServiceId',
      'channelType',
      'postId',
      'shareType',
      'isDraft',
      'hasFirstComment',
      'hasLocation',
      'hasShopGridLink',
      'organizationId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Post Created',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PostDeleted' track call.
 *
 * @param PostDeleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function postDeleted(
  props: PostDeleted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Post_Deleted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user deletes a previously scheduled post.',
    properties: {
      channel: {
        description: 'The channel that the post was scheduled for.',
        pattern:
          'instagram|facebook|linkedin|twitter|tiktok|pinterest|googlebusiness|mastodon|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      channelId: {
        description: "The id of the channel in Buffer's database.",
        type: ['string'],
      },
      channelServiceId: {
        description:
          'The id of the channel on the given social network or service.',
        type: ['string'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string', 'null'],
      },
      deletionSource: {
        description:
          'Where in the product was the post delected? (ex. calendar)',
        type: ['string', 'null'],
      },
      googleBusinessPostType: {
        description:
          "Post Type when the post is for Google Business Profile channel (e.g. What's New)",
        pattern: 'whats_new|offer|event|post',
        type: ['string', 'null'],
      },
      isThreadPost: {
        description:
          'Whether this is a Thread post for twitter or a regular post',
        type: ['boolean'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      postId: {
        description: "The id of the post in Buffer's database.",
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern: 'publish|buffer|analyze|engage',
        type: ['string'],
      },
      rawErrorMessage: {
        description: 'The raw error message for the post, if one exists.',
        type: ['string', 'null'],
      },
      threadedUpdatesCount: {
        description: 'If Threads post, how many threaded updates it contains',
        type: ['number', 'null'],
      },
      totalLinkCount: {
        description:
          'Total number of link attachments for this post (including threaded updates)',
        type: ['number'],
      },
      totalMediaCount: {
        description:
          'Total number of media uploaded for this post (including threaded updates)',
        type: ['number'],
      },
    },
    required: [
      'organizationId',
      'product',
      'channel',
      'channelId',
      'channelServiceId',
      'postId',
      'rawErrorMessage',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Post Deleted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PostDragged' track call.
 *
 * @param PostDragged props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function postDragged(
  props: PostDragged,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Post_Dragged',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: "When a post is dragged to update it's scheduled time.",
    properties: {
      channelId: {
        description: 'The database ID for the channel the post belongs to.',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own ID for the channel.",
        type: ['string'],
      },
      channelType: {
        description:
          'The channel type, ex. "page", "business", "profile", "group", "creator".',
        type: ['string'],
      },
      channelUsername: {
        description: 'The username of the channel.',
        type: ['string'],
      },
      clientName: {
        description: 'The client in which the event occurred.',
        pattern:
          'publishWeb|publishIos|publishAndroid|core|bufferWeb|ios|android',
        type: ['string', 'null'],
      },
      dueAt: {
        description:
          'The time that the post is scheduled to be sent when dragged.',
        type: ['string'],
      },
      organizationId: {
        description: 'The database ID of the organization the post belongs to.',
        type: ['string'],
      },
      postId: {
        description: 'The database ID of the post that was dragged.',
        type: ['string'],
      },
      postStatus: {
        description: 'The status of the post when dragged.',
        type: ['string'],
      },
    },
    required: ['organizationId', 'channelId', 'postId', 'dueAt', 'postStatus'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Post Dragged',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PostEdited' track call.
 *
 * @param PostEdited props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function postEdited(
  props: PostEdited,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Post_Edited',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user edits a post.',
    properties: {
      aiAssisted: {
        description: 'Whether this post was assisted with AI',
        type: ['boolean', 'null'],
      },
      canvaDesignId: {
        description: 'The design id if a Canva image is attached to the post',
        type: ['string', 'null'],
      },
      channel: {
        description: 'The channel that the post was scheduled for.',
        pattern:
          'instagram|facebook|linkedin|twitter|tiktok|pinterest|googlebusiness|mastodon|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      channelId: {
        description: "The id of the channel in Buffer's database.",
        type: ['string'],
      },
      channelServiceId: {
        description:
          'The id of the channel on the given social network or service.',
        type: ['string'],
      },
      channelType: {
        description:
          'The type of channel the post is created for, ex. "page", "group"',
        type: ['string'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string', 'null'],
      },
      composerSource: {
        description: 'Where the composer was opened from',
        type: ['string', 'null'],
      },
      editType: {
        description:
          'How has the user edited the post? Ex. "image removed", "schedule changed", etc.',
        type: ['array'],
      },
      googleBusinessPostType: {
        description:
          "Post Type when the post is for Google Business Profile channel (e.g. What's New)",
        pattern: 'whats_new|offer|event|post',
        type: ['string', 'null'],
      },
      hasBufferHashtag: {
        description: 'Whether this post includes a Buffer hashtag',
        type: ['boolean'],
      },
      hasCanvaImage: {
        description:
          'Does the post contain an image from the Canva intergration?',
        type: ['boolean'],
      },
      hasContentWarning: {
        description: 'Mastodon content warning added to this update',
        type: ['boolean', 'null'],
      },
      hasFirstComment: {
        description: 'If a first comment added to this post',
        type: ['boolean'],
      },
      hasLinkAnnotations: {
        description: 'Whether the post has LinkedIn annotations',
        type: ['boolean'],
      },
      hasLocation: {
        description: 'If a geolocation tag was added to the post',
        type: ['boolean'],
      },
      hasMedia: {
        description: 'The post has either video or image media',
        type: ['boolean'],
      },
      hasMentions: {
        description: 'Whether the post has mentions',
        type: ['boolean'],
      },
      hasPictures: {
        description: 'If the update has pictures',
        type: ['boolean'],
      },
      hasShopGridLink: {
        description: "If a link was added for the channel's Shop Grid page",
        type: ['boolean'],
      },
      hasUploadcareImage: {
        description:
          'Does the post contain an image from the Uploadcare intergration?',
        type: ['boolean'],
      },
      hasUserTag: {
        description: 'Does this post include a user tag?',
        type: ['boolean'],
      },
      hasVideo: { description: 'If the update has video', type: ['boolean'] },
      ideaId: {
        description: 'The idea id from which the post was created',
        type: ['string'],
      },
      imageDimensions: {
        description:
          'The dimensions of the image or images attached to the post',
        type: ['array', 'null'],
      },
      imageFileExtension: {
        description: 'Image file extension',
        type: ['string'],
      },
      isDraft: {
        description: 'If the post created as a draft',
        type: ['boolean'],
      },
      isReelPost: {
        description:
          'Whether this is a Reel post for instagram or a regular post',
        type: ['boolean'],
      },
      isThreadPost: {
        description:
          'Whether this is a Thread post for twitter or a regular post',
        type: ['boolean'],
      },
      mediaType: {
        description:
          'What type of media did this post have attached? ex. "image", "video"',
        type: ['string', 'null'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      postId: {
        description: "The id of the post in Buffer's database.",
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occured',
        pattern: 'publish',
        type: ['string'],
      },
      schedulingType: {
        description: 'The scheduling type of the update',
        pattern: 'default|direct|reminder',
        type: ['string', 'null'],
      },
      shareDate: {
        description:
          'The date when the post was scheduled to be sent (ISO-8601)',
        type: ['string', 'null'],
      },
      shareType: {
        description: 'How the post was scheduled, ex. "queue", "share_now"',
        type: ['string'],
      },
      tagsCount: {
        description: 'Count of tags assigned to the Post',
        type: ['number', 'null'],
      },
      textLength: {
        description: 'Length of text on this update',
        type: ['number'],
      },
      threadedUpdatesCount: {
        description: 'If Threads post, how many threaded updates it contains',
        type: ['number', 'null'],
      },
      totalLinkCount: {
        description:
          'Total number of link attachments for this post (including threaded updates)',
        type: ['number'],
      },
      totalMediaCount: {
        description:
          'Total number of media uploaded for this post (including threaded updates)',
        type: ['number'],
      },
      updateType: {
        description:
          'Type of the post created (e.g. Event for GBP, Threads for Twitter)',
        type: ['string', 'null'],
      },
      videoAudioCodec: { description: 'Video audio codec', type: ['string'] },
      videoBitrate: { description: 'Video bitrate', type: ['number'] },
      videoDurationMillis: {
        description: 'Video duration in milliseconds',
        type: ['number'],
      },
      videoFileExtension: {
        description: 'Video file extension',
        type: ['string'],
      },
      videoFramerate: { description: 'Video framerate', type: ['number'] },
      videoHeight: { description: 'Video height', type: ['number'] },
      videoInitialFormat: {
        description: 'Video initial format',
        type: ['string'],
      },
      videoSizeMB: { description: 'Video size in MB', type: ['number'] },
      videoTranscodedFormat: {
        description: 'Video format after transcoding',
        type: ['string'],
      },
      videoWidth: { description: 'Video width', type: ['number'] },
    },
    required: [
      'product',
      'organizationId',
      'channel',
      'channelId',
      'channelServiceId',
      'channelType',
      'postId',
      'editType',
      'shareType',
      'isDraft',
      'hasFirstComment',
      'hasLocation',
      'hasShopGridLink',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Post Edited',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PostFailed' track call.
 *
 * @param PostFailed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function postFailed(
  props: PostFailed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Post_Failed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When a post is attempted to be sent to the channel service but fails',
    properties: {
      apiErrorCode: {
        description:
          "The error code returned by the third party API. If it's negative, we prevent the post to be sent",
        type: ['integer', 'null'],
      },
      apiErrorMessage: {
        description: 'The error message returned by the third party API.',
        type: ['string', 'null'],
      },
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel",
        type: ['string'],
      },
      channelType: {
        description:
          'The channel type, ex. "page", "business", "profile", "group", "creator"',
        type: ['string'],
      },
      channelUsername: {
        description: 'What is the username of the channel?',
        type: ['string'],
      },
      clientName: {
        description:
          'Which client was the user in when the Post was initially created?',
        type: ['string'],
      },
      errorAbbreviation: {
        description:
          'What is category of error for why the update failed to be sent?',
        type: ['string'],
      },
      errorMessage: {
        description:
          'What is a user facing description of why the update failed to be sent? This property is used in CIO.',
        type: ['string'],
      },
      failedOnPurpose: {
        description:
          'If the update is marked as failed on purpose by Buffer, e.g. when the posting limit is reached',
        type: ['boolean'],
      },
      googleBusinessPostType: {
        description:
          "Post Type when the post is for Google Business Profile channel (e.g. What's New)",
        type: ['string', 'null'],
      },
      hasLinkAnnotations: {
        description: 'Whether the post has LinkedIn annotations',
        type: ['boolean', 'null'],
      },
      hasMedia: {
        description: 'The post has either video or image media',
        type: ['boolean'],
      },
      hasMentions: {
        description: 'Whether the post has mentions',
        type: ['boolean', 'null'],
      },
      hasPictures: {
        description: 'If the update has pictures',
        type: ['boolean'],
      },
      hasVideo: { description: 'If the update has video', type: ['boolean'] },
      imageDimensions: {
        description:
          'The dimensions of the image or images attached to the post',
        type: ['array', 'null'],
      },
      imageFileExtension: {
        description: 'Image file extension',
        type: ['string'],
      },
      internalErrorMessage: {
        description:
          'The full API error message that we use to map for the friendly error message',
        type: ['string'],
      },
      isFacebookReelPost: {
        description:
          'Whether this is a Reel post for Facebook or other type of post',
        type: ['boolean', 'null'],
      },
      isFacebookStoryPost: {
        description:
          'Whether this is a Story post for Facebook or other type of post',
        type: ['boolean', 'null'],
      },
      isReelPost: {
        description:
          'Whether this is a Reel post for instagram or a regular post',
        type: ['boolean', 'null'],
      },
      isStoryPost: {
        description:
          'Whether this is a Story post for instagram or a regular post',
        type: ['boolean', 'null'],
      },
      isThreadPost: {
        description:
          'Whether this is a Thread post for twitter or a regular post',
        type: ['boolean', 'null'],
      },
      mediaCount: {
        description: 'Count of attachmed media (images or videos)',
        type: ['number', 'null'],
      },
      mediaType: {
        description:
          'What type of media did this post have attached? ex. "image", "video"',
        type: ['string', 'null'],
      },
      organizationId: {
        description:
          'The ID of the global organization that the channel was connected to.',
        type: ['string'],
      },
      postId: {
        description: 'The database id for the update document',
        type: ['string'],
      },
      schedulingType: {
        description: 'The scheduling type of the update',
        pattern: 'default|direct|reminder',
        type: ['string', 'null'],
      },
      sharedNow: {
        description: 'Was the update shared now or scheduled?',
        type: ['boolean'],
      },
      textLength: {
        description: 'Length of text on this update',
        type: ['number'],
      },
      threadedUpdatesCount: {
        description: 'If Threads post, how many threaded updates it contains',
        type: ['number', 'null'],
      },
      totalLinkCount: {
        description:
          'Total number of link attachments for this post (including threaded updates)',
        type: ['number', 'null'],
      },
      totalMediaCount: {
        description:
          'Total number of media uploaded for this post (including threaded updates)',
        type: ['number', 'null'],
      },
      updateContent: {
        description:
          'What was the text intended to be sent in the update? This property is used in CIO.',
        type: ['string'],
      },
      updateSentAt: {
        description:
          'When did the attempted sending of this update fail (ISO-8601)?  This property is needed for use in CIO.',
        type: ['string'],
      },
      updateType: {
        description:
          'Type of the post created (e.g. Event for GBP, Threads for Twitter)',
        type: ['string', 'null'],
      },
      updateVerb: {
        description:
          'The description used in the failed post email to the user. This property is needed for use in CIO.',
        type: ['string'],
      },
      videoAudioCodec: { description: 'Video audio codec', type: ['string'] },
      videoBitrate: { description: 'Video bitrate', type: ['number'] },
      videoDurationMillis: {
        description: 'Video duration in milliseconds',
        type: ['number'],
      },
      videoFileExtension: {
        description: 'Video file extension',
        type: ['string'],
      },
      videoFramerate: { description: 'Video framerate', type: ['number'] },
      videoHeight: { description: 'Video height', type: ['number'] },
      videoInitialFormat: {
        description: 'Video initial format',
        type: ['string'],
      },
      videoSizeMB: { description: 'Video size in MB', type: ['number'] },
      videoTranscodedFormat: {
        description: 'Video format after transcoding',
        type: ['string'],
      },
      videoWidth: { description: 'Video width', type: ['number'] },
    },
    required: [
      'channel',
      'channelId',
      'organizationId',
      'postId',
      'updateVerb',
      'updateSentAt',
      'errorMessage',
      'errorAbbreviation',
      'updateContent',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Post Failed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PostFailedNotified' track call.
 *
 * @param PostFailedNotified props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function postFailedNotified(
  props: PostFailedNotified,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Post_Failed_Notified',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Used for notifying users about failures - sent for every recipient of a Post Failed notification',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel",
        type: ['string'],
      },
      channelType: {
        description:
          'The channel type, ex. "page", "business", "profile", "group", "creator"',
        type: ['string'],
      },
      channelUsername: {
        description: 'What is the username of the channel?',
        type: ['string'],
      },
      errorMessage: {
        description:
          'What is a user facing description of why the update failed to be sent? This property is used in CIO.',
        type: ['string'],
      },
      organizationId: {
        description:
          'The ID of the global organization that the channel was connected to.',
        type: ['string'],
      },
      postId: {
        description: 'The database id for the update document',
        type: ['string'],
      },
      updateContent: {
        description:
          'What was the text intended to be sent in the update? This property is used in CIO.',
        type: ['string'],
      },
      updateSentAt: {
        description:
          'When did the attempted sending of this update fail (ISO-8601)?  This property is needed for use in CIO.',
        type: ['string'],
      },
      updateType: {
        description: 'Is this a post or a story? This property is used in CIO.',
        type: ['string'],
      },
      updateVerb: {
        description:
          'The description used in the failed post email to the user. This property is needed for use in CIO.',
        type: ['string'],
      },
    },
    required: [
      'channel',
      'channelId',
      'organizationId',
      'postId',
      'updateVerb',
      'updateType',
      'updateSentAt',
      'errorMessage',
      'updateContent',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Post Failed Notified',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PostInsightsOrderReversed' track call.
 *
 * @param PostInsightsOrderReversed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function postInsightsOrderReversed(
  props: PostInsightsOrderReversed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Post_Insights_Order_Reversed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user reverses the order of posts in Post Insights.',
    properties: {
      channel: {
        description: 'The channel of the selected profile, ex. “instagram”.',
        type: ['string'],
      },
      channelId: {
        description:
          'The ID of the channel selected when the order was reversed.',
        type: ['string'],
      },
      clientName: {
        description: 'The name of the client in which the action occurred.',
        type: ['string'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that took the action.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred, ex. “analyze”.',
        type: ['string'],
      },
    },
    required: [
      'product',
      'organizationId',
      'clientName',
      'channel',
      'channelId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Post Insights Order Reversed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PostInsightsSorted' track call.
 *
 * @param PostInsightsSorted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function postInsightsSorted(
  props: PostInsightsSorted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Post_Insights_Sorted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user sorts the Post Insights.',
    properties: {
      channel: {
        description: 'The channel of the selected profile, ex. “instagram”.',
        type: ['string'],
      },
      channelId: {
        description:
          'The ID of the channel selected when the posts were sorted.',
        type: ['string'],
      },
      clientName: {
        description: 'The name of the client in which the action occurred.',
        type: ['string'],
      },
      metric: {
        description:
          'What were the posts sorted by, ex. “likes”, “comments”, “reach”, “date”?',
        type: ['string'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that took the action.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred, ex. “analyze”.',
        type: ['string'],
      },
    },
    required: [
      'product',
      'organizationId',
      'clientName',
      'channel',
      'channelId',
      'metric',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Post Insights Sorted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PostMadeDraft' track call.
 *
 * @param PostMadeDraft props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function postMadeDraft(
  props: PostMadeDraft,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Post_Made_Draft',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'A user has moved a post to drafts',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelServiceId: {
        description: 'The id of the channel on the given service',
        type: ['string'],
      },
      channelType: {
        description:
          'The type of channel the post is created for, ex. "page", "group"',
        type: ['string'],
      },
      channelUsername: {
        description: 'What was the username of the channel?',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        type: ['string'],
      },
      organizationId: {
        description:
          'The ID of the global organization selected in the org switcher.',
        type: ['string'],
      },
      postId: {
        description: 'The database id for the post document',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occured',
        pattern: 'publish',
        type: ['string'],
      },
    },
    required: ['product', 'postId', 'channel', 'channelId', 'organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Post Made Draft',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PostMadeDraftFailed' track call.
 *
 * @param PostMadeDraftFailed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function postMadeDraftFailed(
  props: PostMadeDraftFailed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Post_Made_Draft_Failed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: "A user's attempt to move a post to drafts has failed",
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelServiceId: {
        description: 'The id of the channel on the given service',
        type: ['string'],
      },
      channelType: {
        description:
          'The type of channel the post is created for, ex. "page", "group"',
        type: ['string'],
      },
      channelUsername: {
        description: 'What was the username of the channel?',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        type: ['string'],
      },
      organizationId: {
        description:
          'The ID of the global organization selected in the org switcher.',
        type: ['string'],
      },
      postId: {
        description: 'The database id for the post document',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occured',
        pattern: 'publish',
        type: ['string'],
      },
    },
    required: ['product', 'postId', 'channel', 'channelId', 'organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Post Made Draft Failed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PostRescheduleFailed' track call.
 *
 * @param PostRescheduleFailed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function postRescheduleFailed(
  props: PostRescheduleFailed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Post_Reschedule_Failed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: "When a user's attempt to reschedule a post fails.",
    properties: {
      clientName: {
        description: 'The client in which the event occurred.',
        pattern:
          'publishWeb|publishIos|publishAndroid|core|bufferWeb|ios|android',
        type: ['string', 'null'],
      },
      postId: {
        description: 'The database ID of the post that was rescheduled.',
        type: ['string'],
      },
      rescheduleMethod: {
        description: 'The method used to reschedule the post, ex. "drag"',
        type: ['string'],
      },
      swap: {
        description:
          'Whether or not the post was swapped with another post by drag and drop.',
        type: ['boolean', 'null'],
      },
      swappedPostId: {
        description:
          'The database ID of the post that the dragged post was dropped on.',
        type: ['string', 'null'],
      },
    },
    required: ['postId', 'rescheduleMethod'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Post Reschedule Failed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PostRescheduled' track call.
 *
 * @param PostRescheduled props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function postRescheduled(
  props: PostRescheduled,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Post_Rescheduled',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user successfully reschedules a post.',
    properties: {
      channelId: {
        description: 'The database ID for the channel the post belongs to.',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for the channel.",
        type: ['string'],
      },
      channelType: {
        description:
          'The channel type, ex. "page", "business", "profile", "group", "creator".',
        type: ['string'],
      },
      channelUsername: {
        description: 'The username of the channel.',
        type: ['string'],
      },
      clientName: {
        description: 'The client in which the event occurred.',
        pattern:
          'publishWeb|publishIos|publishAndroid|core|bufferWeb|ios|android',
        type: ['string', 'null'],
      },
      dueAt: {
        description:
          'The time that the post is scheduled to be sent after being rescheduled.',
        type: ['string'],
      },
      organizationId: {
        description: 'The database ID of the organization the post belongs to.',
        type: ['string'],
      },
      postId: {
        description: 'The database ID of the post that was rescheduled.',
        type: ['string'],
      },
      postStatus: {
        description: 'The status of the post when dragging starts.',
        type: ['string'],
      },
      previousDueAt: {
        description:
          'The time that the post was scheduled to be sent before it was rescheduled.',
        type: ['string'],
      },
      rescheduleMethod: {
        description: 'The method used to reschedule the post, ex. "drag"',
        type: ['string'],
      },
      swap: {
        description:
          'Whether or not the post was swapped with another post by drag and drop.',
        type: ['boolean', 'null'],
      },
      swappedPostId: {
        description:
          'The database ID of the post that the dragged post was dropped on.',
        type: ['string', 'null'],
      },
    },
    required: [
      'organizationId',
      'channelId',
      'postId',
      'dueAt',
      'previousDueAt',
      'postStatus',
      'rescheduleMethod',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Post Rescheduled',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PostSavedToFinishLater' track call.
 *
 * @param PostSavedToFinishLater props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function postSavedToFinishLater(
  props: PostSavedToFinishLater,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Post_Saved_To_Finish_Later',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'A user has created a finish later post',
    properties: {
      buttonSource: {
        description: 'Where was this save initiated? (ex. cancel, save)',
        type: ['string'],
      },
      client: {
        description: 'What client sent the post',
        type: ['string', 'null'],
      },
      clientName: {
        description: 'Which client was the user in when the Post was created?',
        type: ['string'],
      },
      organizationId: {
        description: 'The global organization id that the channel belongs to.',
        type: ['string'],
      },
      postCreatedSource: {
        description:
          'Where was this post created from? (ex. calendar, queue, extension, third-party)',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occured',
        pattern: 'publish',
        type: ['string'],
      },
    },
    required: ['product', 'organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Post Saved To Finish Later',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PostSelected' track call.
 *
 * @param PostSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function postSelected(
  props: PostSelected,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Post_Selected',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a post is selected from the grid on the frontend.',
    properties: {
      channel: {
        description: 'The channel that the post belongs to, e.g. "facebook"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel.',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel.",
        type: ['string'],
      },
      channelUsername: {
        description: 'What is the username of the channel?',
        type: ['string'],
      },
      hasLabel: {
        description: 'Does the post have any labels?',
        type: ['boolean'],
      },
      labelTypes: {
        description:
          'The types of lables associated with the post (e.g. "upset/order/question")',
        type: ['array'],
      },
      organizationId: {
        description:
          'The global organization id of the user that owns the channel.',
        type: ['string'],
      },
      postId: {
        description: 'The unique identifier of the post.',
        type: ['string'],
      },
      postsFiltered: {
        description: 'Were posts filtered when the post was selected?',
        type: ['boolean'],
      },
      product: {
        description: 'The product in which the event occurred.',
        pattern: 'engage',
        type: ['string'],
      },
      totalComments: {
        description: 'The total number of comments for the post.',
        type: ['number'],
      },
      totalUnansweredComments: {
        description: 'The total number of unanswered comments for the post.',
        type: ['number'],
      },
    },
    required: [
      'product',
      'organizationId',
      'postId',
      'totalComments',
      'totalUnansweredComments',
      'hasLabel',
      'labelTypes',
      'channelId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Post Selected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PostSent' track call.
 *
 * @param PostSent props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function postSent(
  props: PostSent,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Post_Sent',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'This event tracks when an update has been finally published after being created on a platform (like Facebook, TikTok, LinkedIn, Twitter, ...)',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        pattern:
          'facebook|instagram|twitter|linkedin|pinterest|tiktok|googlebusiness|mastodon|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelServiceId: {
        description: 'The id of the channel on the given service',
        type: ['string'],
      },
      channelType: {
        description:
          'The type of channel the update is created for, ex. "page", "group"',
        type: ['string'],
      },
      channelUsername: {
        description: 'The username of the channel',
        type: ['string'],
      },
      clientName: {
        description:
          'Which client was the user in when the Post was initially created?',
        type: ['string'],
      },
      hasMedia: {
        description: 'The post has either video or image media',
        type: ['boolean'],
      },
      hasPictures: {
        description: 'The post has one or more images',
        type: ['boolean'],
      },
      hasVideo: { description: 'The post has a video', type: ['boolean'] },
      isThreadPost: {
        description:
          'Whether this is a Thread post for twitter or a regular post',
        type: ['boolean'],
      },
      organizationId: {
        description: 'The global organization id that the channel belongs to.',
        type: ['string'],
      },
      postId: {
        description: 'The database id for the update document in our database',
        type: ['string'],
      },
      schedulingType: {
        description: 'The scheduling type of the update',
        pattern: 'default|direct|reminder',
        type: ['string', 'null'],
      },
      shareDate: {
        description:
          'The date when the update was scheduled to be sent (ISO-8601)',
        type: ['string'],
      },
      sharedNow: {
        description: 'Was the update shared now or scheduled?',
        type: ['boolean'],
      },
      stickers: {
        description: 'Stickers added to this update',
        type: ['array'],
      },
      textLength: {
        description: 'Length of text on this update',
        type: ['number'],
      },
      threadedUpdatesCount: {
        description: 'If Threads post, how many threaded updates it contains',
        type: ['number', 'null'],
      },
      totalLinkCount: {
        description:
          'Total number of link attachments for this post (including threaded updates)',
        type: ['number'],
      },
      totalMediaCount: {
        description:
          'Total number of media uploaded for this post (including threaded updates)',
        type: ['number'],
      },
      updateSentAt: {
        description: 'The date when the update was sent (ISO-8601)',
        type: ['string'],
      },
      updateType: {
        description:
          'It helps to differentiate between different post types within the same channel, ex. "reels", "stories" and "post".',
        type: ['string', 'null'],
      },
    },
    required: [
      'channel',
      'channelId',
      'channelServiceId',
      'channelUsername',
      'channelType',
      'organizationId',
      'postId',
      'updateType',
      'updateSentAt',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Post Sent',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PostSentNotified' track call.
 *
 * @param PostSentNotified props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function postSentNotified(
  props: PostSentNotified,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Post_Sent_Notified',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'This event is used to send emails via Customer.io letting users know that posts have been sent.',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        pattern:
          'facebook|instagram|twitter|linkedin|pinterest|tiktok|googlebusiness|mastodon|startPage|youtube|threads|bluesky|x',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelServiceId: {
        description: 'The id of the channel on the given service',
        type: ['string'],
      },
      channelType: {
        description:
          'The type of channel the post is created for, ex. "page", "group"',
        type: ['string'],
      },
      channelUsername: {
        description:
          'The username of the channel that the draft was created for. This can be used to customize the message in the email.',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when the Post was created?',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      hasLinkAnnotations: {
        description: 'Whether the post has LinkedIn annotations',
        type: ['boolean'],
      },
      hasMentions: {
        description: 'Whether the post has mentions',
        type: ['boolean'],
      },
      hasThread: {
        description: 'Whether the post has thread',
        type: ['boolean'],
      },
      isReelPost: {
        description:
          'Whether this is a Reel post for instagram or a regular post',
        type: ['boolean'],
      },
      organizationId: {
        description: 'The global organization id that the channel belongs to.',
        type: ['string'],
      },
      postId: {
        description: 'The database id for the update document',
        type: ['string'],
      },
      postText: {
        description: 'The text included in the post',
        type: ['string'],
      },
      postUrl: {
        description: 'The link to the post on the network',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occured',
        pattern: 'publish',
        type: ['string'],
      },
      shareDate: {
        description:
          'The date when the post was scheduled to be sent (ISO-8601)',
        type: ['string', 'null'],
      },
      sharedAt: {
        description: 'The local time at which the post was sent',
        type: ['string'],
      },
      updateType: {
        description:
          'What we would call the post in an email (ex. tweet, pin, post, etc)',
        type: ['string'],
      },
    },
    required: [
      'product',
      'channel',
      'channelId',
      'channelServiceId',
      'channelUsername',
      'channelType',
      'postId',
      'clientName',
      'organizationId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Post Sent Notified',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PostSharedNow' track call.
 *
 * @param PostSharedNow props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function postSharedNow(
  props: PostSharedNow,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Post_Shared_Now',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When user shares a post now',
    properties: {
      accountId: { description: 'The account ID', type: ['string'] },
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelType: {
        description:
          'The type of channel the post is created for, ex. "page", "group"',
        type: ['string'],
      },
      channelUsername: {
        description: 'What was the username of the channel?',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      postId: { description: 'The post ID shared now', type: ['string'] },
      schedulingType: {
        description: 'The scheduling type of the update',
        pattern: 'default|direct|reminder',
        type: ['string', 'null'],
      },
      updateType: {
        description:
          'It helps to differentiate between different post types within the same channel, ex. "reels", "stories" and "post".',
        type: ['string'],
      },
    },
    required: ['organizationId', 'postId', 'channel', 'channelId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Post Shared Now',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PostStuckInProcessing' track call.
 *
 * @param PostStuckInProcessing props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function postStuckInProcessing(
  props: PostStuckInProcessing,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Post_Stuck_In_Processing',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a post gets stuck in processing.',
    properties: {
      apiErrorCode: {
        description:
          "The error code returned by the third party API. If it's negative, we prevent the post to be sent",
        type: ['integer', 'null'],
      },
      apiErrorMessage: {
        description: 'The error message returned by the third party API.',
        type: ['string', 'null'],
      },
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel",
        type: ['string'],
      },
      channelType: {
        description:
          'The channel type, ex. "page", "business", "profile", "group", "creator"',
        type: ['string'],
      },
      channelUsername: {
        description: 'What is the username of the channel?',
        type: ['string'],
      },
      errorAbbreviation: {
        description:
          'What is category of error for why the update failed to be sent?',
        type: ['string', 'null'],
      },
      errorMessage: {
        description:
          'What is a user facing description of why the update failed to be sent? This property is used in CIO.',
        type: ['string', 'null'],
      },
      hasPictures: {
        description: 'If the update has pictures',
        type: ['boolean'],
      },
      hasVideo: { description: 'If the update has video', type: ['boolean'] },
      organizationId: {
        description:
          'The ID of the global organization that the channel was connected to.',
        type: ['string'],
      },
      picturesCount: {
        description: 'Total number of pictures attached',
        type: ['number'],
      },
      postId: {
        description: 'The database id for the update document',
        type: ['string'],
      },
      updateContent: {
        description:
          'What was the text intended to be sent in the update? This property is used in CIO.',
        type: ['string', 'null'],
      },
      updateDueAt: {
        description:
          'When did the attempted sending of this update fail (ISO-8601)?  This property is needed for use in CIO.',
        type: ['string', 'null'],
      },
      updateType: {
        description: 'Is this a post or a story? This property is used in CIO.',
        pattern: 'post|story',
        type: ['string'],
      },
      videoAudioCodec: { description: 'Video audio codec', type: ['string'] },
      videoBitrate: { description: 'Video bitrate', type: ['number'] },
      videoDurationMillis: {
        description: 'Video duration in milliseconds',
        type: ['number'],
      },
      videoFileExtension: {
        description: 'Video file extension',
        type: ['string'],
      },
      videoFramerate: { description: 'Video framerate', type: ['number'] },
      videoHeight: { description: 'Video height', type: ['number'] },
      videoInitialFormat: {
        description: 'Video initial format',
        type: ['string'],
      },
      videoSizeMB: { description: 'Video size in MB', type: ['number'] },
      videoTranscodedFormat: {
        description: 'Video format after transcoding',
        type: ['string'],
      },
      videoWidth: { description: 'Video width', type: ['number'] },
    },
    required: [
      'channel',
      'channelId',
      'organizationId',
      'postId',
      'updateType',
      'updateDueAt',
      'errorMessage',
      'errorAbbreviation',
      'updateContent',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Post Stuck In Processing',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PostSwapped' track call.
 *
 * @param PostSwapped props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function postSwapped(
  props: PostSwapped,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Post_Swapped',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When a user reschedules a post by swapping it with another post.',
    properties: {
      channelId: {
        description: 'The database ID for the channel the post belongs to.',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for the channel.",
        type: ['string'],
      },
      channelType: {
        description:
          'The channel type, ex. "page", "business", "profile", "group", "creator".',
        type: ['string'],
      },
      channelUsername: {
        description: 'The username of the channel.',
        type: ['string'],
      },
      clientName: {
        description: 'The client in which the event occurred.',
        pattern:
          'publishWeb|publishIos|publishAndroid|core|bufferWeb|ios|android',
        type: ['string', 'null'],
      },
      organizationId: {
        description: 'The database ID of the organization the post belongs to.',
        type: ['string'],
      },
      sourcePostDueAt: {
        description:
          'The updated time that the source post is scheduled to be sent after the swap.',
        type: ['string'],
      },
      sourcePostId: {
        description: 'The database ID of the source post that was dragged.',
        type: ['string'],
      },
      sourcePostPreviousDueAt: {
        description:
          'The previous time that the source post was scheduled to be sent before the swap.',
        type: ['string'],
      },
      sourcePostStatus: {
        description: 'The status of the source post when dragging starts.',
        type: ['string'],
      },
      targetPostDueAt: {
        description:
          'The updated time that the target post is scheduled to be sent after the swap.',
        type: ['string'],
      },
      targetPostId: {
        description:
          'The database ID of the target post that the source post was dropped on.',
        type: ['string'],
      },
      targetPostPreviousDueAt: {
        description:
          'The previous time that the target post was scheduled to be sent before the swap.',
        type: ['string'],
      },
      targetPostStatus: {
        description:
          'The status of the target post when the source post is dropped on it.',
        type: ['string'],
      },
    },
    required: [
      'organizationId',
      'channelId',
      'sourcePostId',
      'sourcePostDueAt',
      'sourcePostPreviousDueAt',
      'sourcePostStatus',
      'targetPostId',
      'targetPostDueAt',
      'targetPostPreviousDueAt',
      'targetPostStatus',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Post Swapped',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PostValidationFailed' track call.
 *
 * @param PostValidationFailed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function postValidationFailed(
  props: PostValidationFailed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Post_Validation_Failed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Client-side validation for post saving failed',
    properties: {
      clientId: {
        description:
          'What was the unique identifier of the client the user was in when they added the widget?',
        type: ['string', 'null'],
      },
      clientName: {
        description:
          'Which client was the user in when they added the widget? IE, `publishIos` or `publishAndroid`.',
        pattern: 'publishIos|publishAndroid',
        type: ['string'],
      },
      gifCount: {
        description: 'The number of GIFs attached',
        type: ['integer'],
      },
      imageCount: {
        description: 'The number of images attached',
        type: ['integer'],
      },
      isDraft: {
        description: 'whether the post is a draft',
        type: ['boolean'],
      },
      message: {
        description:
          'The message displayed to the user for the validation error',
        type: ['string'],
      },
      network: {
        description:
          'The network that was selected when the validation failure occured',
        pattern:
          'facebook|instagram|twitter|tiktok|youtube|linkedin|google|mastodon|pinterest|startpage|threads|bluesky',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that added the widget.',
        type: ['string'],
      },
      postType: {
        description: 'the posting type being used for the post',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occured',
        pattern: 'publish',
        type: ['string'],
      },
      schedulingType: {
        description: 'the scheduling type for the post',
        type: ['string'],
      },
      sessionId: {
        description: 'A unique ID representing a current composer session',
        type: ['string'],
      },
      tagCount: {
        description: 'number of tags assigned to the post',
        type: ['integer'],
      },
      text: { description: 'The currently composed text', type: ['string'] },
      type: {
        description:
          'the context that the composer is currently being used for',
        type: ['string'],
      },
      videoCount: {
        description: 'The number of videos attached',
        type: ['integer'],
      },
    },
    required: [
      'product',
      'clientName',
      'organizationId',
      'sessionId',
      'network',
      'message',
      'text',
      'imageCount',
      'videoCount',
      'gifCount',
      'postType',
      'schedulingType',
      'tagCount',
      'type',
      'isDraft',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Post Validation Failed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PostingDayToggled' track call.
 *
 * @param PostingDayToggled props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function postingDayToggled(
  props: PostingDayToggled,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Posting_Day_Toggled',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When the user toggles a posting day on or off in their schedule.',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        pattern:
          'facebook|twitter|tiktok|googlebusiness|mastodon|linkedin|instagram|pinterest|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      channelId: {
        description:
          'The identifier of the channel for which the attempt was made.',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel",
        type: ['string'],
      },
      channelType: {
        description:
          'The channel type, ex. "page", "business", "profile", "group", "creator"',
        type: ['string'],
      },
      channelUsername: {
        description: 'What is the username of the channel?',
        type: ['string'],
      },
      clientName: {
        description: 'The client in which the action occurred.',
        type: ['string'],
      },
      dayToggled: {
        description: 'The day toggled in the schedule.',
        pattern: 'monday|tuesday|wednesday|thursday|friday|saturday|sunday',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred, ex. “publish”.',
        pattern: 'publish',
        type: ['string'],
      },
      state: {
        description: 'The state of the day in the schedule.',
        pattern: 'enabled|disabled',
        type: ['string'],
      },
    },
    required: [
      'organizationId',
      'product',
      'clientName',
      'channel',
      'channelId',
      'dayToggled',
      'state',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Posting Day Toggled',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PostingGoalAchieved' track call.
 *
 * @param PostingGoalAchieved props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function postingGoalAchieved(
  props: PostingGoalAchieved,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Posting_Goal_Achieved',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a channel achieved a posting goal',
    properties: {
      atEventFrequency: {
        description:
          'The frequency of the posting goal at the time of the event',
        pattern: 'weekly',
        type: ['string'],
      },
      atEventGoal: {
        description: 'The posting goal count at the time of the event',
        type: ['number'],
      },
      channelId: {
        description: 'The active channel of the user that took the action',
        type: ['string'],
      },
      channelService: {
        description: 'The service of the channel that took the action',
        pattern:
          'facebook|twitter|tiktok|googlebusiness|mastodon|linkedin|instagram|pinterest|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      channelUsername: {
        description: 'The username of the channel that took the action',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID the channel belongs to',
        type: ['string'],
      },
      periodEnd: {
        description: 'The end of the period of the posting goal',
        type: ['string'],
      },
      periodStart: {
        description: 'The start of the period of the posting goal',
        type: ['string'],
      },
    },
    required: [
      'organizationId',
      'channelId',
      'channelUsername',
      'channelService',
      'atEventGoal',
      'atEventFrequency',
      'periodStart',
      'periodEnd',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Posting Goal Achieved',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PostingGoalMissed' track call.
 *
 * @param PostingGoalMissed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function postingGoalMissed(
  props: PostingGoalMissed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Posting_Goal_Missed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a channel missed a posting goal',
    properties: {
      atEventFrequency: {
        description:
          'The frequency of the posting goal at the time of the event',
        pattern: 'weekly',
        type: ['string'],
      },
      atEventGoal: {
        description: 'The posting goal count at the time of the event',
        type: ['number'],
      },
      atEventSentCount: {
        description: 'The sent count at the time of the event',
        type: ['number'],
      },
      channelId: {
        description: 'The active channel of the user that took the action',
        type: ['string'],
      },
      channelService: {
        description: 'The service of the channel that took the action',
        pattern:
          'facebook|twitter|tiktok|googlebusiness|mastodon|linkedin|instagram|pinterest|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      channelUsername: {
        description: 'The username of the channel that took the action',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID the channel belongs to',
        type: ['string'],
      },
      periodEnd: {
        description: 'The end of the period of the posting goal',
        type: ['string'],
      },
      periodStart: {
        description: 'The start of the period of the posting goal',
        type: ['string'],
      },
    },
    required: [
      'organizationId',
      'channelId',
      'channelUsername',
      'channelService',
      'atEventGoal',
      'atEventFrequency',
      'atEventSentCount',
      'periodStart',
      'periodEnd',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Posting Goal Missed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PostingGoalSet' track call.
 *
 * @param PostingGoalSet props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function postingGoalSet(
  props: PostingGoalSet,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Posting_Goal_Set',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a channel set a posting goal',
    properties: {
      channelId: {
        description: 'The active channel of the user that took the action',
        type: ['string'],
      },
      channelService: {
        description: 'The service of the channel that took the action',
        pattern:
          'facebook|twitter|tiktok|googlebusiness|mastodon|linkedin|instagram|pinterest|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      channelUsername: {
        description: 'The username of the channel that took the action',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        pattern: 'publishWeb|publishIos|publishAndroid',
        type: ['string'],
      },
      frequency: {
        description: 'The frequency of the posting goal',
        pattern: 'weekly',
        type: ['string'],
      },
      goal: { description: 'The posting goal count', type: ['number'] },
      organizationId: {
        description: 'The organization ID the channel belongs to',
        type: ['string'],
      },
      periodEnd: {
        description: 'The end of the period of the posting goal',
        type: ['string'],
      },
      periodStart: {
        description: 'The start of the period of the posting goal',
        type: ['string'],
      },
    },
    required: [
      'organizationId',
      'channelId',
      'channelUsername',
      'channelService',
      'goal',
      'frequency',
      'periodStart',
      'periodEnd',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Posting Goal Set',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PostingGoalSkipped' track call.
 *
 * @param PostingGoalSkipped props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function postingGoalSkipped(
  props: PostingGoalSkipped,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Posting_Goal_Skipped',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When a customer skips the Channel Posting Goal Modal Configuration',
    properties: {
      channelId: {
        description: 'The active channel of the user that took the action',
        type: ['string'],
      },
      channelService: {
        description: 'The service of the channel that took the action',
        pattern:
          'facebook|twitter|tiktok|googlebusiness|mastodon|linkedin|instagram|pinterest|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      channelUsername: {
        description: 'The username of the channel that took the action',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        pattern: 'publishWeb|publishIos|publishAndroid|core|bufferWeb',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID the channel belongs to',
        type: ['string'],
      },
    },
    required: [
      'organizationId',
      'channelId',
      'channelUsername',
      'channelService',
      'clientName',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Posting Goal Skipped',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PostingPlanCreated' track call.
 *
 * @param PostingPlanCreated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function postingPlanCreated(
  props: PostingPlanCreated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Posting_Plan_Created',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user creates a posting plan',
    properties: {
      channel: {
        description:
          'The current channel that the user is creating a posting plan for',
        type: ['string'],
      },
      channelType: {
        description:
          'The channel type, ex. "page", "business", "profile", "group", "creator"',
        type: ['string', 'null'],
      },
      channelUsername: {
        description:
          'The username of the channel that the user is creating a posting plan for',
        type: ['string'],
      },
      clientName: {
        description:
          'Which client was the user in when they created the posting plan?',
        type: ['string', 'null'],
      },
      currentPlan: {
        description:
          'The current plan of the user at time of creating the posting plan.',
        type: ['string', 'null'],
      },
      days: {
        description:
          'The days that the user has selected for their posting plan (e.g they selected Mo, We, Fr)',
        type: ['array'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that created the posting plan.',
        type: ['string', 'null'],
      },
      timesPerDay: {
        description:
          'The number of times per day that the user has selected for their posting plan (e.g they selected they want to post 4 times a day)',
        type: ['number'],
      },
    },
    required: [
      'organizationId',
      'clientName',
      'channel',
      'channelUsername',
      'days',
      'timesPerDay',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Posting Plan Created',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PostingScheduleCleared' track call.
 *
 * @param PostingScheduleCleared props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function postingScheduleCleared(
  props: PostingScheduleCleared,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Posting_Schedule_Cleared',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When the user clears their posting schedule.',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        pattern:
          'facebook|twitter|tiktok|googlebusiness|mastodon|linkedin|instagram|pinterest|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      channelId: {
        description:
          'The identifier of the channel for which the attempt was made.',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel",
        type: ['string'],
      },
      channelType: {
        description:
          'The channel type, ex. "page", "business", "profile", "group", "creator"',
        type: ['string'],
      },
      channelUsername: {
        description: 'What is the username of the channel?',
        type: ['string'],
      },
      clientName: {
        description: 'The client in which the action occurred.',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred, ex. “publish”.',
        pattern: 'publish',
        type: ['string'],
      },
    },
    required: [
      'organizationId',
      'product',
      'clientName',
      'channel',
      'channelId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Posting Schedule Cleared',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PostingTimeAdded' track call.
 *
 * @param PostingTimeAdded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function postingTimeAdded(
  props: PostingTimeAdded,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Posting_Time_Added',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When the user added a posting time to their schedule.',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        pattern:
          'facebook|twitter|tiktok|googlebusiness|mastodon|linkedin|instagram|pinterest|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      channelId: {
        description:
          'The identifier of the channel for which the attempt was made.',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel",
        type: ['string'],
      },
      channelType: {
        description:
          'The channel type, ex. "page", "business", "profile", "group", "creator"',
        type: ['string'],
      },
      channelUsername: {
        description: 'What is the username of the channel?',
        type: ['string'],
      },
      clientName: {
        description: 'The client in which the action occurred.',
        type: ['string'],
      },
      daysAdded: {
        description: 'The number of days added to the schedule.',
        pattern:
          'monday|tuesday|wednesday|thursday|friday|saturday|sunday|weekdays|weekends|every day',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred, ex. “publish”.',
        pattern: 'publish',
        type: ['string'],
      },
      timeAdded: {
        description: 'The time added to the schedule.',
        type: ['string'],
      },
    },
    required: [
      'organizationId',
      'product',
      'clientName',
      'channel',
      'channelId',
      'daysAdded',
      'timeAdded',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Posting Time Added',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PostingTimeRemoved' track call.
 *
 * @param PostingTimeRemoved props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function postingTimeRemoved(
  props: PostingTimeRemoved,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Posting_Time_Removed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When the user removed a posting time to their schedule.',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        pattern:
          'facebook|twitter|tiktok|googlebusiness|mastodon|linkedin|instagram|pinterest|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      channelId: {
        description:
          'The identifier of the channel for which the attempt was made.',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel",
        type: ['string'],
      },
      channelType: {
        description:
          'The channel type, ex. "page", "business", "profile", "group", "creator"',
        type: ['string'],
      },
      channelUsername: {
        description: 'What is the username of the channel?',
        type: ['string'],
      },
      clientName: {
        description: 'The client in which the action occurred.',
        type: ['string'],
      },
      dayRemoved: {
        description: 'The day removed from the schedule.',
        pattern: 'monday|tuesday|wednesday|thursday|friday|saturday|sunday',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred, ex. “publish”.',
        pattern: 'publish',
        type: ['string'],
      },
      timeRemoved: {
        description: 'The time removed from the schedule.',
        type: ['string'],
      },
    },
    required: [
      'organizationId',
      'product',
      'clientName',
      'channel',
      'channelId',
      'dayRemoved',
      'timeRemoved',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Posting Time Removed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PostingTimeUpdated' track call.
 *
 * @param PostingTimeUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function postingTimeUpdated(
  props: PostingTimeUpdated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Posting_Time_Updated',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When the user updated a posting time to their schedule.',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        pattern:
          'facebook|twitter|tiktok|googlebusiness|mastodon|linkedin|instagram|pinterest|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      channelId: {
        description:
          'The identifier of the channel for which the attempt was made.',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel",
        type: ['string'],
      },
      channelType: {
        description:
          'The channel type, ex. "page", "business", "profile", "group", "creator"',
        type: ['string'],
      },
      channelUsername: {
        description: 'What is the username of the channel?',
        type: ['string'],
      },
      clientName: {
        description: 'The client in which the action occurred.',
        type: ['string'],
      },
      dayChanged: {
        description: 'The day changed on to the schedule.',
        pattern: 'monday|tuesday|wednesday|thursday|friday|saturday|sunday',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred, ex. “publish”.',
        pattern: 'publish',
        type: ['string'],
      },
      timeAdded: {
        description: 'The time added to the schedule.',
        type: ['string'],
      },
      timeRemoved: {
        description: 'The time removed from the schedule.',
        type: ['string'],
      },
    },
    required: [
      'organizationId',
      'product',
      'clientName',
      'channel',
      'channelId',
      'dayChanged',
      'timeRemoved',
      'timeAdded',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Posting Time Updated',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PostsLoaded' track call.
 *
 * @param PostsLoaded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function postsLoaded(
  props: PostsLoaded,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Posts_Loaded',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When the posts for a specific Instagram account are loaded on the frontend.',
    properties: {
      channel: {
        description: 'The channel that the posts belong to, e.g. "facebook"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel.',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel.",
        type: ['string'],
      },
      channelUsername: {
        description: 'What is the username of the channel?',
        type: ['string'],
      },
      hasLabels: {
        description:
          "Do any of the channel's posts include comments with labels?",
        type: ['boolean'],
      },
      labelTypes: {
        description:
          'The types of lables included in the comments (e.g. "upset/question/order").',
        type: ['array'],
      },
      organizationId: {
        description:
          'The global organization id of the user that owns the channel.',
        type: ['string'],
      },
      postsWithLabel: {
        description:
          'The number of posts that include at least one comment with a label.',
        type: ['number', 'null'],
      },
      product: {
        description: 'The product in which the event occurred.',
        pattern: 'engage',
        type: ['string'],
      },
      totalComments: {
        description: "The total number of comments for the channel's posts.",
        type: ['number', 'null'],
      },
      totalUnansweredComments: {
        description:
          "The total number of unanswered comments for the channel's posts.",
        type: ['number', 'null'],
      },
    },
    required: [
      'product',
      'organizationId',
      'totalComments',
      'totalUnansweredComments',
      'hasLabels',
      'postsWithLabel',
      'labelTypes',
      'channelId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Posts Loaded',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PostsReordered' track call.
 *
 * @param PostsReordered props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function postsReordered(
  props: PostsReordered,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Posts_Reordered',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'A user has reordered the posts in their queue',
    properties: {
      clientName: {
        description: 'Which client was the user in when the Post was created?',
        type: ['string'],
      },
      organizationId: {
        description: 'The global organization id that the channel belongs to.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occured',
        pattern: 'publish',
        type: ['string'],
      },
      reorderSource: {
        description:
          'Where were the posts reordered from? (ex. calendar, queue)',
        type: ['string'],
      },
    },
    required: ['product', 'clientName', 'organizationId', 'reorderSource'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Posts Reordered',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ProductSelected' track call.
 *
 * @param ProductSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function productSelected(
  props: ProductSelected,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Product_Selected',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user select a product in Remix?',
    properties: {
      clientName: {
        description: 'The client in which the action occurred.',
        type: ['string'],
      },
      hasRatings: {
        description: 'If the url scraped contains ratings',
        type: ['boolean'],
      },
      platform: {
        description:
          'What ecommerce platform was the product selected from? ex. "shopify"',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred, ex. “remix”.',
        pattern: 'remix',
        type: ['string'],
      },
      productText: {
        description: 'What was the title of the product that was selected?',
        type: ['string'],
      },
      productUrl: {
        description: 'What was the url of the product that was selected?',
        type: ['string'],
      },
    },
    required: ['product', 'clientName', 'productUrl', 'platform'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Product Selected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ProductSelectorViewed' track call.
 *
 * @param ProductSelectorViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function productSelectorViewed(
  props: ProductSelectorViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Product_Selector_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When a user views the product selector in the onboarding flow.',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      clientName: {
        description: 'The name of the client that the event occurred in.',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      onFreePlan: {
        description: 'Is the organization on a Free plan?',
        type: ['boolean'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the product solutions.',
        type: ['string'],
      },
      product: {
        description: 'The product in which this action occurred.',
        type: ['string'],
      },
    },
    required: ['product', 'organizationId', 'clientName', 'onFreePlan'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Product Selector Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ProductSignup' track call.
 *
 * @param ProductSignup props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function productSignup(
  props: ProductSignup,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Product_Signup',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      "When did the product signup occur? A user can have multiple product signups, but only one per product per global Buffer account.  This event is triggered when Buffer's product specific unique user identifier is generated in that product's production database.",
    properties: {
      clientName: {
        description: 'Which client did the user signup for this product from?',
        type: ['string'],
      },
      cta: {
        description:
          'Which call to action did the user click to signup for this product',
        type: ['string', 'null'],
      },
      ctaApp: {
        description:
          'Which product or website is the CTA located in? Examples would be either the product, like `publish`, or the website, like `bufferSite`',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA?  In many cases it makes sense to describe the intended result of the CTA, like `proTrial` or `publishPro`',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in Publish app shell menu',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA?  If setting the value for the first time, set to `1`',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'What website page or app view is the CTA located?  Examples would be, `composer` or `analyticsOverview` for Publish, and `pricingPublish` for the Publish pricing page on the Buffer marketing site',
        type: ['string', 'null'],
      },
      organizationId: {
        description: 'The organization ID of the user that signed up',
        type: ['string'],
      },
      product: {
        description: 'Which product did the signup occur for?',
        type: ['string'],
      },
      signupWithTrial: {
        description:
          'Did this product signup also trigger a trial start during the same work flow?',
        type: ['boolean'],
      },
      trialPlanName: {
        description:
          'If the signup did also trigger a trial start during the same work flow, what was the common name of the plan they are trialing (Eg, `Pro`)?',
        type: ['string', 'null'],
      },
    },
    required: ['product'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Product Signup',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ProductSolutionSelected' track call.
 *
 * @param ProductSolutionSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function productSolutionSelected(
  props: ProductSolutionSelected,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Product_Solution_Selected',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When a user selects a product solution from the product selector.',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      clientName: {
        description: 'The name of the client that the event occurred in.',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that selected the product.',
        type: ['string'],
      },
      product: {
        description: 'The product in which this action occurred (e.g. buffer).',
        type: ['string'],
      },
      productSolutionSelected: {
        description: 'Which product solution was selected (e.g. analytics)?',
        type: ['string'],
      },
    },
    required: [
      'product',
      'productSolutionSelected',
      'organizationId',
      'clientName',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Product Solution Selected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PublishRevampToggle' track call.
 *
 * @param PublishRevampToggle props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function publishRevampToggle(
  props: PublishRevampToggle,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Publish_Revamp_Toggle',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Publish Revamp Opt-In/Out action',
    properties: {
      accountId: { description: 'The account ID', type: ['string'] },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      status: {
        description: 'Status of the opt-in/out action',
        pattern: 'enabled|disabled',
        type: ['string'],
      },
    },
    required: ['organizationId', 'accountId', 'status'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Publish Revamp Toggle',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PushNotificationBounced' track call.
 *
 * @param PushNotificationBounced props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function pushNotificationBounced(
  props: PushNotificationBounced,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Push_Notification_Bounced',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the push notification fail to be delivered?',
    properties: {
      campaignContent: {
        description: 'What was the content included in the push notification?',
        type: ['string'],
      },
      campaignMedium: {
        description:
          'What was the type of link in the notification (ex, push notification)?',
        type: ['string'],
      },
      campaignName: {
        description: 'What was the name of the push notification?',
        type: ['string'],
      },
      campaignSource: {
        description: 'Which source app sent the notification?',
        type: ['string'],
      },
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string', 'null'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string', 'null'],
      },
      channelServiceId: {
        description: "The service's own id for this channel",
        type: ['string', 'null'],
      },
      clientId: {
        description:
          'What was the application unique identifier for where the notification came from?',
        type: ['string', 'null'],
      },
      clientName: {
        description: 'What was the client name of the application?',
        type: ['string'],
      },
      product: {
        description: 'Which Buffer product was the notification for?',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: [
      'campaignName',
      'campaignMedium',
      'campaignContent',
      'campaignSource',
      'clientName',
      'product',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Push Notification Bounced',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PushNotificationReceived' track call.
 *
 * @param PushNotificationReceived props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function pushNotificationReceived(
  props: PushNotificationReceived,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Push_Notification_Received',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When was the push notification received in the mobile app?',
    properties: {
      campaignContent: {
        description: 'What was the content included in the push notification?',
        type: ['string'],
      },
      campaignMedium: {
        description:
          'What was the type of link in the notification (ex, push notification)?',
        type: ['string'],
      },
      campaignName: {
        description: 'What was the name of the push notification?',
        type: ['string'],
      },
      campaignSource: {
        description: 'Which source app sent the notification?',
        type: ['string'],
      },
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string', 'null'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string', 'null'],
      },
      channelServiceId: {
        description: "The service's own id for this channel",
        type: ['string', 'null'],
      },
      clientId: {
        description:
          'What was the application unique identifier for where the notification came from?',
        type: ['string', 'null'],
      },
      clientName: {
        description: 'What was the client name of the application?',
        type: ['string'],
      },
      hasGrantedNotificationPermission: {
        description:
          'Whether we have permission to show notifications on the receiving device',
        type: ['boolean', 'null'],
      },
      product: {
        description: 'Which Buffer product was the notification for?',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: ['clientName', 'product'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Push Notification Received',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PushNotificationTapped' track call.
 *
 * @param PushNotificationTapped props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function pushNotificationTapped(
  props: PushNotificationTapped,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Push_Notification_Tapped',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When was the push notification tapped by the user, opening the mobile app?',
    properties: {
      campaignContent: {
        description: 'What was the content included in the push notification?',
        type: ['string'],
      },
      campaignMedium: {
        description:
          'What was the type of link in the notification (ex, push notification)?',
        type: ['string'],
      },
      campaignName: {
        description: 'What was the name of the push notification?',
        type: ['string'],
      },
      campaignSource: {
        description: 'Which source app sent the notification?',
        type: ['string'],
      },
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string', 'null'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string', 'null'],
      },
      channelServiceId: {
        description: "The service's own id for this channel",
        type: ['string', 'null'],
      },
      clientId: {
        description:
          'What was the application unique identifier for where the notification came from?',
        type: ['string', 'null'],
      },
      clientName: {
        description: 'What was the client name of the application?',
        type: ['string'],
      },
      product: {
        description: 'Which Buffer product was the notification for?',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: ['clientName', 'product'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Push Notification Tapped',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'QuantityUpdateModalViewed' track call.
 *
 * @param QuantityUpdateModalViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function quantityUpdateModalViewed(
  props: QuantityUpdateModalViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Quantity_Update_Modal_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user view the billing quantity update module?',
    properties: {
      cta: {
        description:
          'If the user navigated to this page from a CTA on another Buffer page, which call to action was it?',
        type: ['string', 'null'],
      },
      ctaApp: {
        description:
          'Which product or website is the CTA located in? Examples would be either the product, like `publish`, or the website, like `bufferSite`',
        type: ['string', 'null'],
      },
      ctaButton: {
        description: 'What is the name or action of the CTA?',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in Publish app shell menu',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA?  If setting the value for the first time, set to `1`',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'What website page or app view is the CTA located?  Examples would be, `composer` or `analyticsOverview` for Publish, and `pricingPublish` for the Publish pricing page on the Buffer marketing site',
        type: ['string', 'null'],
      },
      currentPlan: {
        description:
          'The current plan of the user at time of viewing the quantity update modal.',
        type: ['string', 'null'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the quantity update modal.',
        type: ['string', 'null'],
      },
    },
    required: ['organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Quantity Update Modal Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'QueueShuffled' track call.
 *
 * @param QueueShuffled props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function queueShuffled(
  props: QueueShuffled,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Queue_Shuffled',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'User clicked the shuffle button to shuffle posting queue / CT-677',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        pattern:
          'twitter|facebook|instagram|pinterest|linkedin|tiktok|googlebusiness|mastodon|startPage|youtube|threads|bluesky',
        type: ['string', 'null'],
      },
      clientName: {
        description:
          'Which client was the user in when they dragged the story? IE, `publishIos`, `publishWeb`, `publishAndroid`.',
        pattern: 'publishIos|publishWeb|publishAndroid',
        type: ['string'],
      },
      organizationId: {
        description: 'The Global organization ID.',
        type: ['string', 'null'],
      },
    },
    required: ['clientName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Queue Shuffled',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'QuickNavigatorActionSelected' track call.
 *
 * @param QuickNavigatorActionSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function quickNavigatorActionSelected(
  props: QuickNavigatorActionSelected,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Quick_Navigator_Action_Selected',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user selects an action in the quick navigator',
    properties: {
      actionName: {
        description: 'The name of the selected action',
        type: ['string'],
      },
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the plan selector.',
        type: ['string', 'null'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: ['organizationId', 'actionName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Quick Navigator Action Selected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'QuickNavigatorOpened' track call.
 *
 * @param QuickNavigatorOpened props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function quickNavigatorOpened(
  props: QuickNavigatorOpened,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Quick_Navigator_Opened',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user opens the quick navigator (cmd + k / ctrl + k)',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the plan selector.',
        type: ['string', 'null'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: ['organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Quick Navigator Opened',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ReferralLinkCopied' track call.
 *
 * @param ReferralLinkCopied props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function referralLinkCopied(
  props: ReferralLinkCopied,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Referral_Link_Copied',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When the user chose to Refer someone to Buffer, did they copy their unique link?',
    properties: {
      clientName: {
        description: 'Which client was the user in when they took this action?',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the referring user',
        type: ['string'],
      },
    },
    required: ['organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Referral Link Copied',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ReferralPostStarted' track call.
 *
 * @param ReferralPostStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function referralPostStarted(
  props: ReferralPostStarted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Referral_Post_Started',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When the user chose to Refer someone to Buffer, did they click "Share Now" to start a draft Post containing the link?',
    properties: {
      clientName: {
        description: 'Which client was the user in when they took this action?',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the referring user',
        type: ['string'],
      },
    },
    required: ['organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Referral Post Started',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'RefreshPinterestBoardsButtonClicked' track call.
 *
 * @param RefreshPinterestBoardsButtonClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function refreshPinterestBoardsButtonClicked(
  props: RefreshPinterestBoardsButtonClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Refresh_Pinterest_Boards_Button_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user clicks the Refresh Pinterest Boards button.',
    properties: {
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        type: ['string'],
      },
      organizationId: {
        description: 'The ID of the current organization',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occurred',
        pattern: 'publish',
        type: ['string'],
      },
    },
    required: ['product', 'clientName', 'organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Refresh Pinterest Boards Button Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ReminderAppButtonTapped' track call.
 *
 * @param ReminderAppButtonTapped props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function reminderAppButtonTapped(
  props: ReminderAppButtonTapped,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Reminder_App_Button_Tapped',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'A user has clicked the button to open the corresponding app for a reminder',
    properties: {
      channel: {
        description: 'The channel that the post was scheduled for',
        pattern: 'instagram|facebook|tiktok|youtube',
        type: ['string'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishIos)',
        pattern: 'publishIos|publishAndroid',
        type: ['string'],
      },
      fileSize: {
        description: 'The size of the file (KB) being shared in the reminder',
        type: ['string', 'null'],
      },
      mediaType: {
        description: 'The media type contained in the reminder',
        pattern: 'video|image',
        type: ['string'],
      },
      organizationId: {
        description: 'The global organization id that the channel belongs to',
        type: ['string'],
      },
      postId: {
        description: 'The post id for the reminder being shared',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occured',
        pattern: 'publish',
        type: ['string'],
      },
      repost: {
        description: 'Whether the reminder is a repost of an existing post',
        type: ['boolean'],
      },
    },
    required: [
      'product',
      'channel',
      'clientName',
      'organizationId',
      'postId',
      'mediaType',
      'repost',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Reminder App Button Tapped',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ReminderAppOpened' track call.
 *
 * @param ReminderAppOpened props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function reminderAppOpened(
  props: ReminderAppOpened,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Reminder_App_Opened',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'A user has opened the corresponding app for a reminder',
    properties: {
      channel: {
        description: 'The channel that the post was scheduled for',
        pattern: 'instagram|facebook|tiktok|youtube',
        type: ['string'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishIos)',
        pattern: 'publishIos|publishAndroid',
        type: ['string'],
      },
      organizationId: {
        description: 'The global organization id that the channel belongs to',
        type: ['string'],
      },
      postId: {
        description: 'The post id for the reminder being shared',
        type: ['string', 'null'],
      },
      product: {
        description: 'The product in which the action occured',
        pattern: 'publish',
        type: ['string'],
      },
    },
    required: ['product', 'channel', 'clientName', 'organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Reminder App Opened',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ReminderCompleted' track call.
 *
 * @param ReminderCompleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function reminderCompleted(
  props: ReminderCompleted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Reminder_Completed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'A user has completed a reminder',
    properties: {
      channel: {
        description: 'The channel that the post was scheduled for.',
        pattern: 'tiktok',
        type: ['string'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string', 'null'],
      },
      organizationId: {
        description: 'The global organization id that the channel belongs to.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occured',
        pattern: 'publish',
        type: ['string'],
      },
      scheduledDate: {
        description:
          'The date when the post was scheduled to be sent (ISO-8601)',
        type: ['string', 'null'],
      },
    },
    required: ['product', 'channel', 'organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Reminder Completed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ReminderCreated' track call.
 *
 * @param ReminderCreated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function reminderCreated(
  props: ReminderCreated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Reminder_Created',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'A user has created a reminder',
    properties: {
      channel: {
        description: 'The channel that the post was scheduled for.',
        pattern: 'tiktok',
        type: ['string'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string', 'null'],
      },
      organizationId: {
        description: 'The global organization id that the channel belongs to.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occured',
        pattern: 'publish',
        type: ['string'],
      },
      scheduledDate: {
        description:
          'The date when the post was scheduled to be sent (ISO-8601)',
        type: ['string', 'null'],
      },
    },
    required: ['product', 'channel', 'organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Reminder Created',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ReminderDeleted' track call.
 *
 * @param ReminderDeleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function reminderDeleted(
  props: ReminderDeleted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Reminder_Deleted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user deletes a previously scheduled reminder.',
    properties: {
      channel: {
        description: 'The channel that the post was scheduled for.',
        pattern: 'tiktok',
        type: ['string'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string', 'null'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern: 'publish|buffer|analyze|engage',
        type: ['string'],
      },
    },
    required: ['organizationId', 'product', 'channel'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Reminder Deleted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'RemindersPostPreviewViewClicked' track call.
 *
 * @param RemindersPostPreviewViewClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function remindersPostPreviewViewClicked(
  props: RemindersPostPreviewViewClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Reminders_Post_Preview_View_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Which view the user clicked in Reminders Post Preview',
    properties: {
      clientName: {
        description: 'Which client was the user in when they took this action?',
        pattern: 'publishWeb|publishIos|publishAndroid',
        type: ['string'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that clicked the button.',
        type: ['string', 'null'],
      },
      service: {
        description: 'The service, ex. "instagram"',
        pattern: 'instagram',
        type: ['string'],
      },
      view: {
        description: 'The name of the view clicked.',
        pattern: 'reminder|post',
        type: ['string'],
      },
    },
    required: ['organizationId', 'view', 'clientName', 'service'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Reminders Post Preview View Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'RemindersSetupButtonClicked' track call.
 *
 * @param RemindersSetupButtonClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function remindersSetupButtonClicked(
  props: RemindersSetupButtonClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Reminders_Setup_Button_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'What button did the user clicked in the Reminders Setup Wizard',
    properties: {
      buttonName: {
        description: 'The name of the button that was clicked',
        pattern:
          'get-ios-app|get-android-app|next|skip|finish|try-notifications|try-again|notification-received',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when the step was viewed?',
        type: ['string'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that clicked the button.',
        type: ['string', 'null'],
      },
      placement: {
        description: 'Where in the product this event took place',
        type: ['string'],
      },
    },
    required: ['organizationId', 'buttonName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Reminders Setup Button Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'RemindersSetupCTAClicked' track call.
 *
 * @param RemindersSetupCTAClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function remindersSetupCTAClicked(
  props: RemindersSetupCTAClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Reminders_Setup_CTA_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user clicked a CTA to set up Reminders',
    properties: {
      clientName: {
        description: 'Which client was the user in when the CTA was clicked?',
        type: ['string'],
      },
      cta: { description: 'The name of the CTA clicked.', type: ['string'] },
      organizationId: {
        description:
          'The global organization ID of the user that clicked the CTA.',
        type: ['string', 'null'],
      },
      placement: {
        description: 'Where in the product this event took place',
        type: ['string'],
      },
    },
    required: ['organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Reminders Setup CTA Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'RemindersSetupCTAViewed' track call.
 *
 * @param RemindersSetupCTAViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function remindersSetupCTAViewed(
  props: RemindersSetupCTAViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Reminders_Setup_CTA_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user see a CTA to set up Reminders',
    properties: {
      clientName: {
        description: 'Which client was the user in when the CTA was viewed?',
        type: ['string'],
      },
      cta: { description: 'The name of the CTA viewed.', type: ['string'] },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the CTA.',
        type: ['string', 'null'],
      },
      placement: {
        description: 'Where in the product this event took place',
        type: ['string'],
      },
    },
    required: ['organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Reminders Setup CTA Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'RemindersSetupCompleted' track call.
 *
 * @param RemindersSetupCompleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function remindersSetupCompleted(
  props: RemindersSetupCompleted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Reminders_Setup_Completed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When the user completed the setup of reminders for their Instagram profile.',
    properties: {
      channelId: {
        description:
          'The identifier of the channel for which reminders were set up.',
        type: ['string'],
      },
      clientName: {
        description: 'The client in which the action occurred.',
        type: ['string'],
      },
      cta: {
        description:
          'Which call to action did the user click to get to the reminders setup?',
        type: ['string', 'null'],
      },
      ctaApp: {
        description:
          'Which product is the CTA located in? Examples would be either the product, like `publish`.',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA? In many cases it makes sense to describe the intended result of the CTA, like `proTrial` or `publishPro`.',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in Publish app shell menu.',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA? If setting the value for the first time, set to `1`.',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'What app view is the CTA located in? Examples would be, `composer` or `analyticsOverview` for Publish.',
        type: ['string', 'null'],
      },
      organizationId: {
        description: 'The ID of the Global organization that owns the profile.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred, ex. “publish”.',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: ['product', 'clientName', 'channelId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Reminders Setup Completed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'RemindersSetupStarted' track call.
 *
 * @param RemindersSetupStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function remindersSetupStarted(
  props: RemindersSetupStarted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Reminders_Setup_Started',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When the user attempted to set up reminders for their Instagram profile.',
    properties: {
      channelId: {
        description:
          'The identifier of the channel for which the attempt was made.',
        type: ['string'],
      },
      clientName: {
        description: 'The client in which the action occurred.',
        type: ['string'],
      },
      cta: {
        description:
          'Which call to action did the user click to get to the reminders setup?',
        type: ['string', 'null'],
      },
      ctaApp: {
        description:
          'Which product is the CTA located in? Examples would be either the product, like `publish`.',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA? In many cases it makes sense to describe the intended result of the CTA, like `proTrial` or `publishPro`.',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in Publish app shell menu.',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA? If setting the value for the first time, set to `1`.',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'What app view is the CTA located in? Examples would be, `composer` or `analyticsOverview` for Publish.',
        type: ['string', 'null'],
      },
      organizationId: {
        description: 'The ID of the global organization that owns the channel.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred, ex. “publish”.',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: ['product', 'clientName', 'channelId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Reminders Setup Started',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'RemindersSetupStepViewed' track call.
 *
 * @param RemindersSetupStepViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function remindersSetupStepViewed(
  props: RemindersSetupStepViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Reminders_Setup_Step_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user see a step of the Reminders Setup Wizard',
    properties: {
      clientName: {
        description: 'Which client was the user in when the step was viewed?',
        type: ['string'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the step.',
        type: ['string', 'null'],
      },
      placement: {
        description: 'Where in the product this event took place',
        type: ['string'],
      },
      stepNumber: { description: 'The number of the step', type: ['number'] },
    },
    required: ['organizationId', 'stepNumber'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Reminders Setup Step Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ReportChartNoteEdited' track call.
 *
 * @param ReportChartNoteEdited props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function reportChartNoteEdited(
  props: ReportChartNoteEdited,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Report_Chart_Note_Edited',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When is a report chart note added or edited?',
    properties: {
      module: {
        description: 'Human readable name of the module',
        type: ['string'],
      },
      organizationId: {
        description: 'The id of the Global organization that owns the channel.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event happened',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      reportId: { description: 'Id for the new report', type: ['string'] },
    },
    required: ['product'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Report Chart Note Edited',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ReportCreated' track call.
 *
 * @param ReportCreated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function reportCreated(
  props: ReportCreated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Report_Created',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      "A key action in Analyze, it's valuable to know how many and how often users are using this feature.",
    properties: {
      days: {
        description: 'Number of days the report is showing',
        type: ['integer'],
      },
      organizationId: {
        description: 'The id of the Global organization that owns the channel.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event happened',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      reportId: { description: 'Id for the new report', type: ['string'] },
      title: {
        description: 'User-generated name of the report',
        type: ['string'],
      },
    },
    required: ['title'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Report Created',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ReportExported' track call.
 *
 * @param ReportExported props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function reportExported(
  props: ReportExported,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Report_Exported',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user export a report?',
    properties: {
      days: {
        description: 'Number of days the report is showing',
        type: ['integer', 'null'],
      },
      description: {
        description: 'Written description, if any',
        type: ['string', 'null'],
      },
      hasLogo: {
        description: 'Did the user export the report with their logo added',
        type: ['boolean', 'null'],
      },
      numberOfModules: {
        description: 'How many modules on the report when exported',
        type: ['integer', 'null'],
      },
      organizationId: {
        description: 'The organization ID of the user that exported the report',
        type: ['string'],
      },
      product: {
        description: 'Which product did the user export a report from?',
        type: ['string'],
      },
      reportId: {
        description: 'Id for the new report',
        type: ['string', 'null'],
      },
    },
    required: ['product'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Report Exported',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ReportModuleAdded' track call.
 *
 * @param ReportModuleAdded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function reportModuleAdded(
  props: ReportModuleAdded,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Report_Module_Added',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      "Reports are made up of multiple modules. It's helpful to understand how many, and of which type.",
    properties: {
      channel: {
        description:
          'Channel of the page, if any (e.g., ""facebook"", ""instagram"", etc.)',
        type: ['string', 'null'],
      },
      isFirstModuleOnReport: {
        description: 'If this was the initial module added to a new report',
        type: ['boolean'],
      },
      module: {
        description: 'Human readable name of the module',
        type: ['string'],
      },
      moduleMetric: {
        description: 'Type of metric the module is showing, if any',
        type: ['string'],
      },
      modulePreviousPeriod: {
        description:
          'If the module has a previous period toggle, otherwise null',
        type: ['boolean'],
      },
      organizationId: {
        description: 'The id of the Global organization that owns the channel.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event happened',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      reportId: { description: 'Id for the new report', type: ['string'] },
    },
    required: ['module'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Report Module Added',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'SchedulingTypeChanged' track call.
 *
 * @param SchedulingTypeChanged props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function schedulingTypeChanged(
  props: SchedulingTypeChanged,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Scheduling_Type_Changed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User changed scheduling type for a post in Composer',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        pattern:
          'twitter|facebook|instagram|pinterest|linkedin|tiktok|googlebusiness|mastodon|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      clientName: {
        description:
          'Which client was the user in when they dragged the story? IE, `publishIos`, `publishWeb`, `publishAndroid`.',
        pattern: 'publishIos|publishWeb|publishAndroid',
        type: ['string'],
      },
      organizationId: {
        description: 'The Global organization ID.',
        type: ['string'],
      },
      schedulingType: {
        description: 'Scheduling Type that the update was changed to (target)',
        pattern: 'automatic|notification',
        type: ['string'],
      },
      updateType: {
        description: 'Type of the update, e.g. "post" (default) or "reel"',
        pattern:
          'post|story|reels|facebook_reel|short|whats_new|event|offer|thread',
        type: ['string'],
      },
    },
    required: [
      'channel',
      'clientName',
      'organizationId',
      'schedulingType',
      'updateType',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Scheduling Type Changed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ScreenViewed' track call.
 *
 * @param ScreenViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function screenViewed(
  props: ScreenViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Screen_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When did the user view this specific screen? This event is triggered when a user opens a new screen in the application that is considered a distinct screen.',
    properties: {
      channel: {
        description:
          'Channel of the page, if applicable (e.g., ""facebook"", ""instagram"", etc.)',
        type: ['string', 'null'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string', 'null'],
      },
      channelServiceId: {
        description: 'The id of the channel on the given service',
        type: ['string', 'null'],
      },
      channelType: {
        description: 'What is the type of channel? ex. "page", "group"',
        type: ['string', 'null'],
      },
      clientName: {
        description:
          'Which client was the user in when they copied the story note? IE, `publishIos`, `publishWeb`, `publishAndroid`.',
        type: ['string'],
      },
      name: {
        description:
          'Human readable name of the page (e.g., ""overview"", ""posts"", etc.)',
        type: ['string', 'null'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the screen.',
        type: ['string', 'null'],
      },
      product: {
        description:
          'The product in which the page view occurred (e.g. ""publish"", ""analyze"")',
        type: ['string'],
      },
    },
    required: ['clientName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Screen Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'SentPostsShareAgainClicked' track call.
 *
 * @param SentPostsShareAgainClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function sentPostsShareAgainClicked(
  props: SentPostsShareAgainClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Sent_Posts_Share_Again_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When did user click the Share Again button in Post analytics?',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelServiceId: {
        description: 'The id of the channel on the given service',
        type: ['string'],
      },
      channelType: {
        description:
          'The type of channel the post is created for, ex. "page", "group"',
        type: ['string'],
      },
      channelUsername: {
        description: 'What was the username of the channel?',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        type: ['string'],
      },
      organizationId: {
        description:
          'The ID of the global organization selected in the org switcher.',
        type: ['string'],
      },
      postId: {
        description: 'What is the id of the post that was shared again?',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occured',
        pattern: 'publish',
        type: ['string'],
      },
    },
    required: [
      'product',
      'channel',
      'channelId',
      'channelServiceId',
      'channelType',
      'postId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Sent Posts Share Again Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ShopGridLinkAdded' track call.
 *
 * @param ShopGridLinkAdded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function shopGridLinkAdded(
  props: ShopGridLinkAdded,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Shop_Grid_Link_Added',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user add a CTA to their shop grid page?',
    properties: {
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel",
        type: ['string'],
      },
      channelUsername: {
        description: 'What is the username of the channel?',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        type: ['string'],
      },
      linkColor: {
        description: 'What was the color of the CTA?',
        type: ['string'],
      },
      linkText: {
        description: 'What was the copy used in the CTA?',
        type: ['string'],
      },
      linkURL: {
        description: 'What was the URL linked to in the CTA?',
        type: ['string'],
      },
      organizationId: {
        description: 'The Global organization ID.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occurred',
        pattern: 'publish',
        type: ['string'],
      },
    },
    required: ['product', 'linkText', 'linkURL', 'linkColor', 'channelId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Shop Grid Link Added',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ShopGridLinkRemoved' track call.
 *
 * @param ShopGridLinkRemoved props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function shopGridLinkRemoved(
  props: ShopGridLinkRemoved,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Shop_Grid_Link_Removed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user remove a CTA from their shop grid page?',
    properties: {
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel",
        type: ['string'],
      },
      channelUsername: {
        description: 'What is the username of the channel?',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        type: ['string'],
      },
      linkText: {
        description: 'What was the copy used in the CTA?',
        type: ['string'],
      },
      linkURL: {
        description: 'What was the URL linked to in the CTA?',
        type: ['string'],
      },
      organizationId: {
        description: 'The Global organization ID.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occurred',
        pattern: 'publish',
        type: ['string'],
      },
    },
    required: ['product', 'linkText', 'linkURL', 'channelId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Shop Grid Link Removed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ShopGridPageLinkCopied' track call.
 *
 * @param ShopGridPageLinkCopied props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function shopGridPageLinkCopied(
  props: ShopGridPageLinkCopied,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Shop_Grid_Page_Link_Copied',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user copy the link to their Shop Grid page?',
    properties: {
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel",
        type: ['string'],
      },
      channelUsername: {
        description: 'What is the username of the channel?',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        type: ['string'],
      },
      organizationId: {
        description: 'The Global organization ID.',
        type: ['string'],
      },
      shopGridUrl: {
        description: 'What was the url of the Shop Grid page that was copied?',
        type: ['string'],
      },
    },
    required: ['channelId', 'shopGridUrl'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Shop Grid Page Link Copied',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ShopGridPagePreviewed' track call.
 *
 * @param ShopGridPagePreviewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function shopGridPagePreviewed(
  props: ShopGridPagePreviewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Shop_Grid_Page_Previewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When did the user preview the Shop Grid page by clicking the Preview button?',
    properties: {
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel",
        type: ['string'],
      },
      channelUsername: {
        description: 'What is the username of the channel?',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        type: ['string'],
      },
      organizationId: {
        description: 'The Global organization ID.',
        type: ['string'],
      },
    },
    required: ['channelId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Shop Grid Page Previewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ShopGridPostURLUpdated' track call.
 *
 * @param ShopGridPostURLUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function shopGridPostURLUpdated(
  props: ShopGridPostURLUpdated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Shop_Grid_Post_URL_Updated',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When did the user update the url of a post in the Shop Grid page?',
    properties: {
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel",
        type: ['string'],
      },
      channelUsername: {
        description: 'What is the username of the channel?',
        type: ['string'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        type: ['string'],
      },
      organizationId: {
        description: 'The Global organization ID.',
        type: ['string'],
      },
      postId: {
        description: 'The database id for the post object',
        type: ['string'],
      },
      url: {
        description: 'What was the url that was added to the Shop Grid post?',
        type: ['string'],
      },
    },
    required: ['channelId', 'postId', 'url'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Shop Grid Post URL Updated',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'SidebarAddChannelClicked' track call.
 *
 * @param SidebarAddChannelClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function sidebarAddChannelClicked(
  props: SidebarAddChannelClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Sidebar_Add_Channel_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When did the user click on the Add Channel button on the sidebar?',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      currentChannelQuantity: {
        description: "The number of channels connected to the user's account",
        type: ['number'],
      },
      hasPaymentDetails: {
        description:
          'At time of event, did the user have payment details saved?',
        type: ['boolean', 'null'],
      },
      isAtPlanLimit: {
        description: 'Is the user at their plan limit?',
        type: ['boolean', 'null'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
    },
    required: [
      'organizationId',
      'currentChannelQuantity',
      'isAtPlanLimit',
      'hasPaymentDetails',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Sidebar Add Channel Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'SidebarChannelsFiltered' track call.
 *
 * @param SidebarChannelsFiltered props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function sidebarChannelsFiltered(
  props: SidebarChannelsFiltered,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Sidebar_Channels_Filtered',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When a user filters channels in the sidebar using the search functionality',
    properties: {
      accountId: {
        description: 'The account ID of the user performing the search',
        type: ['string'],
      },
      channelCount: {
        description: 'The total number of channels on the account',
        type: ['number'],
      },
      channelIDs: {
        description: 'The channel ids that are available for the user',
        type: ['array'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      filteredChannelCount: {
        description: 'The number of channels after applying the filter',
        type: ['number'],
      },
      filteredChannelIDs: {
        description: 'The channel ids that were filtered by the user',
        type: ['array'],
      },
      organizationId: {
        description: 'The organization ID of the user performing the search',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern: 'publish|buffer|analyze|engage',
        type: ['string'],
      },
      searchQuery: {
        description: 'The search query entered by the user',
        type: ['string'],
      },
    },
    required: [
      'clientName',
      'product',
      'organizationId',
      'accountId',
      'searchQuery',
      'channelCount',
      'filteredChannelCount',
      'channelIDs',
      'filteredChannelIDs',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Sidebar Channels Filtered',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'SingleChannelOpened' track call.
 *
 * @param SingleChannelOpened props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function singleChannelOpened(
  props: SingleChannelOpened,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Single_Channel_Opened',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Single Channel view opened with filters',
    properties: {
      accountId: { description: 'The account ID', type: ['string'] },
      channelSelected: { description: 'Selected channel', type: ['string'] },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      tab: {
        description: 'Selected tab - queue/sent/approvals/drafts',
        type: ['string'],
      },
      tagsSelected: {
        description:
          'Selected tags in filter, if not defined - all tags selected',
        type: ['array'],
      },
    },
    required: ['organizationId', 'accountId', 'channelSelected', 'tab'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Single Channel Opened',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StartPageAvatarEdited' track call.
 *
 * @param StartPageAvatarEdited props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function startPageAvatarEdited(
  props: StartPageAvatarEdited,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Start_Page_Avatar_Edited',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user edits the avatar of their Start Page.',
    properties: {
      backgroundType: {
        description: "The background type (e.g. 'uploaded', 'unsplash')",
        type: ['string'],
      },
      clientName: {
        description: 'The client that the event occurred in',
        type: ['string'],
      },
      organizationId: {
        description: 'The active organization of the user that took the action',
        type: ['string'],
      },
      preSignup: {
        description:
          'Did this event occur before the user signed up for a Buffer account?',
        type: ['boolean'],
      },
      product: {
        description: 'Which product did the event take place in?',
        type: ['string'],
      },
      totalBlocks: {
        description: 'The total number of blocks currently in the Start Page',
        type: ['integer'],
      },
    },
    required: [
      'product',
      'organizationId',
      'totalBlocks',
      'backgroundType',
      'clientName',
      'preSignup',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Start Page Avatar Edited',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StartPageBackgroundEdited' track call.
 *
 * @param StartPageBackgroundEdited props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function startPageBackgroundEdited(
  props: StartPageBackgroundEdited,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Start_Page_Background_Edited',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user updates the background of their Start Page',
    properties: {
      backgroundType: {
        description: "The type of background (e.g. 'uploaded', 'unsplash')",
        type: ['string'],
      },
      clientName: {
        description: 'The client that the event occurred in',
        type: ['string'],
      },
      organizationId: {
        description: 'The active organization of the user that took the action',
        type: ['string'],
      },
      preSignup: {
        description:
          'Did this event occur before the user signed up for a Buffer account?',
        type: ['boolean'],
      },
      product: {
        description: 'Which product did the event take place in?',
        pattern: 'start_page',
        type: ['string'],
      },
      totalBlocks: {
        description: 'The total number of blocks currently in the Start Page',
        type: ['integer'],
      },
    },
    required: [
      'product',
      'organizationId',
      'totalBlocks',
      'backgroundType',
      'clientName',
      'preSignup',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Start Page Background Edited',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StartPageBanned' track call.
 *
 * @param StartPageBanned props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function startPageBanned(
  props: StartPageBanned,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Start_Page_Banned',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When Start Page is banned.',
    properties: {
      clientName: {
        description: 'The client that the event occurred in',
        type: ['string'],
      },
      organizationId: {
        description: 'The active organization of the user that took the action',
        type: ['string'],
      },
      preSignup: {
        description:
          'Did this event occur before the user signed up for a Buffer account?',
        type: ['boolean'],
      },
      product: {
        description: 'Which product did the event take place in?',
        type: ['string'],
      },
      totalBlocks: {
        description: 'The total number of blocks currently in the Start Page',
        type: ['integer'],
      },
      username: {
        description: 'The username selected for the Start Page',
        type: ['string'],
      },
    },
    required: [
      'product',
      'organizationId',
      'username',
      'clientName',
      'preSignup',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Start Page Banned',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StartPageBlockAdded' track call.
 *
 * @param StartPageBlockAdded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function startPageBlockAdded(
  props: StartPageBlockAdded,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Start_Page_Block_Added',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user adds a block to their Start Page',
    properties: {
      blockType: {
        description:
          "The type of the block that was added (e.g. 'link', 'video', 'sell', 'text', 'social links'... )",
        type: ['string'],
      },
      clientName: {
        description: 'The client that the event occurred in.',
        type: ['string'],
      },
      organizationId: {
        description: 'The active organization of the user that took the action',
        type: ['string'],
      },
      preSignup: {
        description:
          'Did this event occur before the user signed up for a Buffer account?',
        type: ['boolean'],
      },
      product: {
        description: 'Which product did the event take place in?',
        type: ['string'],
      },
      totalBlocks: {
        description: 'The total number of blocks currently in the Start Page',
        type: ['integer'],
      },
    },
    required: [
      'product',
      'organizationId',
      'totalBlocks',
      'blockType',
      'clientName',
      'preSignup',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Start Page Block Added',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StartPageBlockDeleted' track call.
 *
 * @param StartPageBlockDeleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function startPageBlockDeleted(
  props: StartPageBlockDeleted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Start_Page_Block_Deleted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user deletes a block from their Start Page',
    properties: {
      blockType: {
        description:
          "The type of the block that was added (e.g. 'link', 'video', 'sell', 'text', 'social links'... )",
        type: ['string'],
      },
      clientName: {
        description: 'The client that the event occurred in.',
        type: ['string'],
      },
      organizationId: {
        description: 'The active organization of the user that took the action',
        type: ['string'],
      },
      preSignup: {
        description:
          'Did this event occur before the user signed up for a Buffer account?',
        type: ['boolean'],
      },
      product: {
        description: 'Which product did the event take place in?',
        type: ['string'],
      },
      totalBlocks: {
        description: 'The total number of blocks currently in the Start Page',
        type: ['integer'],
      },
    },
    required: [
      'product',
      'organizationId',
      'totalBlocks',
      'blockType',
      'clientName',
      'preSignup',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Start Page Block Deleted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StartPageBlockHidden' track call.
 *
 * @param StartPageBlockHidden props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function startPageBlockHidden(
  props: StartPageBlockHidden,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Start_Page_Block_Hidden',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user hides one of the blocks in their Start Page.',
    properties: {
      blockType: {
        description:
          "The type of the block that was moved (e.g. 'link', 'video', 'sell', 'text', 'social links'... )",
        type: ['string'],
      },
      clientName: {
        description: 'The client that the event occurred in.',
        type: ['string'],
      },
      organizationId: {
        description: 'The active organization of the user that took the action',
        type: ['string'],
      },
      preSignup: {
        description:
          'Did this event occur before the user signed up for a Buffer account?',
        type: ['boolean'],
      },
      product: {
        description: 'Which product did the event take place in?',
        type: ['string'],
      },
      totalBlocks: {
        description: 'The total number of blocks currently in the Start Page',
        type: ['integer'],
      },
    },
    required: [
      'product',
      'organizationId',
      'totalBlocks',
      'blockType',
      'clientName',
      'preSignup',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Start Page Block Hidden',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StartPageBlockMadeVisible' track call.
 *
 * @param StartPageBlockMadeVisible props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function startPageBlockMadeVisible(
  props: StartPageBlockMadeVisible,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Start_Page_Block_Made_Visible',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When a user made one of the blocks visible in their Start Page.',
    properties: {
      blockType: {
        description:
          "The type of the block that was moved (e.g. 'link', 'video', 'sell', 'text', 'social links'... )",
        type: ['string'],
      },
      clientName: {
        description: 'The client that the event occurred in.',
        type: ['string'],
      },
      organizationId: {
        description: 'The active organization of the user that took the action',
        type: ['string'],
      },
      preSignup: {
        description:
          'Did this event occur before the user signed up for a Buffer account?',
        type: ['boolean'],
      },
      product: {
        description: 'Which product did the event take place in?',
        type: ['string'],
      },
      totalBlocks: {
        description: 'The total number of blocks currently in the Start Page',
        type: ['integer'],
      },
    },
    required: [
      'product',
      'organizationId',
      'totalBlocks',
      'blockType',
      'clientName',
      'preSignup',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Start Page Block Made Visible',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StartPageBlockMoved' track call.
 *
 * @param StartPageBlockMoved props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function startPageBlockMoved(
  props: StartPageBlockMoved,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Start_Page_Block_Moved',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user moves one of the blocks in their Start Page.',
    properties: {
      blockType: {
        description:
          "The type of the block that was moved (e.g. 'link', 'video', 'sell', 'text', 'social links'... )",
        type: ['string'],
      },
      clientName: {
        description: 'The client that the event occurred in.',
        type: ['string'],
      },
      organizationId: {
        description: 'The active organization of the user that took the action',
        type: ['string'],
      },
      preSignup: {
        description:
          'Did this event occur before the user signed up for a Buffer account?',
        type: ['boolean'],
      },
      product: {
        description: 'Which product did the event take place in?',
        type: ['string'],
      },
      totalBlocks: {
        description: 'The total number of blocks currently in the Start Page',
        type: ['integer'],
      },
    },
    required: [
      'product',
      'organizationId',
      'totalBlocks',
      'blockType',
      'clientName',
      'preSignup',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Start Page Block Moved',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StartPageCreated' track call.
 *
 * @param StartPageCreated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function startPageCreated(
  props: StartPageCreated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Start_Page_Created',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user creates a Start Page.',
    properties: {
      clientName: {
        description: 'The client that the event occurred in',
        type: ['string'],
      },
      defaultTemplate: {
        description: 'Name of the template the user started with in Start Page',
        type: ['string'],
      },
      organizationId: {
        description: 'The active organization of the user that took the action',
        type: ['string'],
      },
      preSignup: {
        description:
          'Did this event occur before the user signed up for a Buffer account?',
        type: ['boolean'],
      },
      product: {
        description: 'Which product did the event take place in?',
        type: ['string'],
      },
      totalStartPages: {
        description: 'The total number of Start Pages the user has created',
        type: ['integer'],
      },
      username: {
        description: 'The username selected for the Start Page',
        type: ['string'],
      },
    },
    required: [
      'product',
      'organizationId',
      'username',
      'clientName',
      'preSignup',
      'totalStartPages',
      'defaultTemplate',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Start Page Created',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StartPageDeleted' track call.
 *
 * @param StartPageDeleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function startPageDeleted(
  props: StartPageDeleted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Start_Page_Deleted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a Start Page is deleted.',
    properties: {
      clientName: {
        description: 'The client that the event occurred in',
        type: ['string'],
      },
      organizationId: {
        description: 'The active organization of the user that took the action',
        type: ['string'],
      },
      preSignup: {
        description:
          'Did this event occur before the user signed up for a Buffer account?',
        type: ['boolean'],
      },
      product: {
        description: 'Which product did the event take place in?',
        type: ['string'],
      },
      totalStartPages: {
        description: 'The total number of Start Pages the user has created',
        type: ['integer'],
      },
      userInitiatedAction: {
        description:
          'If the user initiated the action, could be a bot/script otherwise',
        type: ['boolean'],
      },
      username: {
        description: 'The username selected for the Start Page',
        type: ['string'],
      },
    },
    required: [
      'product',
      'organizationId',
      'username',
      'clientName',
      'preSignup',
      'totalStartPages',
      'userInitiatedAction',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Start Page Deleted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StartPageExplainerCTATapped' track call.
 *
 * @param StartPageExplainerCTATapped props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function startPageExplainerCTATapped(
  props: StartPageExplainerCTATapped,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Start_Page_Explainer_CTA_Tapped',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Which CTA did the user take action on during the explainer?',
    properties: {
      clientName: {
        description:
          'Which client was the user in when they performed the Getting Started action? IE, `publishIos` or `publishAndroid`.',
        pattern: 'publishIos|publishAndroid',
        type: ['string'],
      },
      cta: {
        description: 'The name of the CTA they took action on',
        pattern: 'getStartedNow|sendEmail|notRightNow',
        type: ['string'],
      },
      organizationId: {
        description:
          'The organization ID of the user that performed the Getting Started action.',
        type: ['string'],
      },
    },
    required: ['cta', 'clientName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Start Page Explainer CTA Tapped',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StartPageExplainerOpened' track call.
 *
 * @param StartPageExplainerOpened props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function startPageExplainerOpened(
  props: StartPageExplainerOpened,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Start_Page_Explainer_Opened',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user open the app tour?',
    properties: {
      clientName: {
        description:
          'What was the name of the client the user was in when they opened the Story in the native channel?',
        type: ['string'],
      },
      organizationId: {
        description: 'The global organization id that the channel belongs to.',
        type: ['string'],
      },
      placement: {
        description: 'Where did the user open the explainer from?',
        pattern: 'key_action_prompt|settings|getting_started',
        type: ['string'],
      },
      product: {
        description: 'In which product did this event occur?',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: ['product', 'clientName', 'placement'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Start Page Explainer Opened',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StartPageExplainerViewed' track call.
 *
 * @param StartPageExplainerViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function startPageExplainerViewed(
  props: StartPageExplainerViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Start_Page_Explainer_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a prospective user views a slide within the Onboarding.',
    properties: {
      clientName: {
        description:
          'The mobile client the user is on, `publishIos` or `publishAndroid`.',
        type: ['string'],
      },
      product: {
        description: 'In which product did this event occur?',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      slide: {
        description: 'Which slide did the user view?',
        pattern: 'step_1|step_2|step_3|step_4|step_5',
        type: ['string'],
      },
    },
    required: ['clientName', 'slide', 'product'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Start Page Explainer Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StartPageGIFSelected' track call.
 *
 * @param StartPageGIFSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function startPageGIFSelected(
  props: StartPageGIFSelected,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Start_Page_GIF_Selected',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user selects a GIF.',
    properties: {
      clientName: {
        description: 'The client that the event occurred in',
        type: ['string'],
      },
      gifId: {
        description: 'The unique name or url for the selected GIF',
        type: ['string'],
      },
      organizationId: {
        description: 'The active organization of the user that took the action',
        type: ['string'],
      },
      preSignup: {
        description:
          'Did this event occur before the user signed up for a Buffer account?',
        type: ['boolean'],
      },
      product: {
        description: 'Which product did the event take place in?',
        type: ['string'],
      },
      totalBlocks: {
        description: 'The total number of blocks currently in the Start Page',
        type: ['integer'],
      },
    },
    required: [
      'product',
      'organizationId',
      'totalBlocks',
      'gifId',
      'clientName',
      'preSignup',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Start Page GIF Selected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StartPageHeaderEdited' track call.
 *
 * @param StartPageHeaderEdited props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function startPageHeaderEdited(
  props: StartPageHeaderEdited,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Start_Page_Header_Edited',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user edits the header of their Start Page.',
    properties: {
      clientName: {
        description: 'The client that the event occurred in',
        type: ['string'],
      },
      headerLayout: {
        description: 'Unique ID for the chosen layout',
        type: ['string'],
      },
      organizationId: {
        description: 'The active organization of the user that took the action',
        type: ['string'],
      },
      product: {
        description: 'Which product did the event take place in?',
        type: ['string'],
      },
      themeID: {
        description: 'Unique ID for the start page theme',
        type: ['string'],
      },
      totalBlocks: {
        description: 'The total number of blocks currently in the Start Page',
        type: ['integer'],
      },
    },
    required: [
      'product',
      'organizationId',
      'totalBlocks',
      'clientName',
      'headerLayout',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Start Page Header Edited',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StartPagePlanLimitHit' track call.
 *
 * @param StartPagePlanLimitHit props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function startPagePlanLimitHit(
  props: StartPagePlanLimitHit,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Start_Page_Plan_Limit_Hit',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When a user wants to publish a Start Page but they hit their plan limit',
    properties: {
      clientName: {
        description: 'The client that the event occurred in',
        type: ['string'],
      },
      createdStartPages: {
        description: 'The total number of Start Pages the user has created',
        type: ['integer'],
      },
      defaultTemplate: {
        description: 'Name of the template the user started with in Start Page',
        type: ['string'],
      },
      organizationId: {
        description: 'The active organization of the user that took the action',
        type: ['string'],
      },
      product: {
        description: 'Which product did the event take place in?',
        type: ['string'],
      },
    },
    required: ['product', 'organizationId', 'clientName', 'createdStartPages'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Start Page Plan Limit Hit',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StartPagePostBlockedUserActionRequired' track call.
 *
 * @param StartPagePostBlockedUserActionRequired props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function startPagePostBlockedUserActionRequired(
  props: StartPagePostBlockedUserActionRequired,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Start_Page_Post_Blocked_User_Action_Required',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When a scheduled post cant trigger re-publishing the Start Page.',
    properties: {
      clientName: {
        description: 'The client that the event occurred in',
        type: ['string'],
      },
      content: { description: 'Content of the Post', type: ['string'] },
      organizationId: {
        description: 'The active organization of the user that took the action',
        type: ['string'],
      },
      product: {
        description: 'Which product did the event take place in?',
        type: ['string'],
      },
      scheduledAt: {
        description: 'The time when the post was supposed to go out',
        type: ['string'],
      },
      totalBlocks: {
        description: 'The total number of blocks currently in the Start Page',
        type: ['integer'],
      },
      username: {
        description: 'The username selected for the Start Page',
        type: ['string'],
      },
    },
    required: [
      'product',
      'organizationId',
      'username',
      'clientName',
      'scheduledAt',
      'content',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Start Page Post Blocked User Action Required',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StartPagePublished' track call.
 *
 * @param StartPagePublished props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function startPagePublished(
  props: StartPagePublished,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Start_Page_Published',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user publishes their Start Page.',
    properties: {
      clientName: {
        description: 'The client that the event occurred in',
        type: ['string'],
      },
      organizationId: {
        description: 'The active organization of the user that took the action',
        type: ['string'],
      },
      preSignup: {
        description:
          'Did this event occur before the user signed up for a Buffer account?',
        type: ['boolean'],
      },
      product: {
        description: 'Which product did the event take place in?',
        type: ['string'],
      },
      totalBlocks: {
        description: 'The total number of blocks currently in the Start Page',
        type: ['integer'],
      },
      username: {
        description: 'The username selected for the Start Page',
        type: ['string'],
      },
    },
    required: [
      'product',
      'organizationId',
      'username',
      'clientName',
      'preSignup',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Start Page Published',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StartPageStatisticsViewed' track call.
 *
 * @param StartPageStatisticsViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function startPageStatisticsViewed(
  props: StartPageStatisticsViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Start_Page_Statistics_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user loads the Statistics tab.',
    properties: {
      clientName: {
        description: 'The client that the event occurred in',
        type: ['string'],
      },
      organizationId: {
        description: 'The active organization of the user that took the action',
        type: ['string'],
      },
      preSignup: {
        description:
          'Did this event occur before the user signed up for a Buffer account?',
        type: ['boolean'],
      },
      product: {
        description: 'Which product did the event take place in?',
        type: ['string'],
      },
      totalBlocks: {
        description: 'The total number of blocks currently in the Start Page',
        type: ['integer'],
      },
    },
    required: ['product', 'organizationId', 'clientName', 'preSignup'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Start Page Statistics Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StartPageThemeEdited' track call.
 *
 * @param StartPageThemeEdited props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function startPageThemeEdited(
  props: StartPageThemeEdited,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Start_Page_Theme_Edited',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user edits the theme of their Start Page.',
    properties: {
      clientName: {
        description: 'The client that the event occurred in',
        type: ['string'],
      },
      organizationId: {
        description: 'The active organization of the user that took the action',
        type: ['string'],
      },
      preSignup: {
        description:
          'Did this event occur before the user signed up for a Buffer account?',
        type: ['boolean'],
      },
      product: {
        description: 'Which product did the event take place in?',
        type: ['string'],
      },
      themeId: {
        description: 'The unique identifier of the theme that was edited',
        type: ['string'],
      },
      totalBlocks: {
        description: 'The total number of blocks currently in the Start Page',
        type: ['integer'],
      },
    },
    required: [
      'product',
      'organizationId',
      'totalBlocks',
      'themeId',
      'clientName',
      'preSignup',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Start Page Theme Edited',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StartPageThemeSelected' track call.
 *
 * @param StartPageThemeSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function startPageThemeSelected(
  props: StartPageThemeSelected,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Start_Page_Theme_Selected',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user selects a theme for their Start Page.',
    properties: {
      clientName: {
        description: 'The client that the event occurred in',
        type: ['string'],
      },
      organizationId: {
        description: 'The active organization of the user that took the action',
        type: ['string'],
      },
      preSignup: {
        description:
          'Did this event occur before the user signed up for a Buffer account?',
        type: ['boolean'],
      },
      product: {
        description: 'Which product did the event take place in?',
        type: ['string'],
      },
      themeId: {
        description: 'The unique identifier of the theme that was selected',
        type: ['string'],
      },
      totalBlocks: {
        description: 'The total number of blocks currently in the Start Page',
        type: ['integer'],
      },
    },
    required: [
      'product',
      'organizationId',
      'totalBlocks',
      'themeId',
      'clientName',
      'preSignup',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Start Page Theme Selected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StartPageURLCopied' track call.
 *
 * @param StartPageURLCopied props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function startPageURLCopied(
  props: StartPageURLCopied,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Start_Page_URL_Copied',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user copies the URL for their Start Page.',
    properties: {
      clientName: {
        description: 'The client that the event occurred in',
        type: ['string'],
      },
      organizationId: {
        description: 'The active organization of the user that took the action',
        type: ['string'],
      },
      preSignup: {
        description:
          'Did this event occur before the user signed up for a Buffer account?',
        type: ['boolean'],
      },
      product: {
        description: 'Which product did the event take place in?',
        type: ['string'],
      },
      totalBlocks: {
        description: 'The total number of blocks currently in the Start Page',
        type: ['integer'],
      },
      url: { description: 'The url of the Start Page', type: ['string'] },
    },
    required: ['product', 'organizationId', 'url', 'clientName', 'preSignup'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Start Page URL Copied',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StartPageUnpublished' track call.
 *
 * @param StartPageUnpublished props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function startPageUnpublished(
  props: StartPageUnpublished,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Start_Page_Unpublished',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a Start Page is unpublished.',
    properties: {
      clientName: {
        description: 'The client that the event occurred in',
        type: ['string'],
      },
      organizationId: {
        description: 'The active organization of the user that took the action',
        type: ['string'],
      },
      preSignup: {
        description:
          'Did this event occur before the user signed up for a Buffer account?',
        type: ['boolean'],
      },
      product: {
        description: 'Which product did the event take place in?',
        type: ['string'],
      },
      totalBlocks: {
        description: 'The total number of blocks currently in the Start Page',
        type: ['integer'],
      },
      userInitiatedAction: {
        description:
          'If the user initiated the action, could be a bot/script otherwise',
        type: ['boolean'],
      },
      username: {
        description: 'The username selected for the Start Page',
        type: ['string'],
      },
    },
    required: [
      'product',
      'organizationId',
      'username',
      'clientName',
      'preSignup',
      'userInitiatedAction',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Start Page Unpublished',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StartPageUsernameConfirmed' track call.
 *
 * @param StartPageUsernameConfirmed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function startPageUsernameConfirmed(
  props: StartPageUsernameConfirmed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Start_Page_Username_Confirmed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user confirms a username for their Start Page.',
    properties: {
      clientName: {
        description: 'The client that the event occurred in',
        type: ['string'],
      },
      organizationId: {
        description: 'The active organization of the user that took the action',
        type: ['string'],
      },
      preSignup: {
        description:
          'Did this event occur before the user signed up for a Buffer account?',
        type: ['boolean'],
      },
      product: {
        description: 'Which product did the event take place in?',
        type: ['string'],
      },
      totalBlocks: {
        description: 'The total number of blocks currently in the Start Page',
        type: ['integer'],
      },
      username: {
        description: 'The username confirmed for the Start Page',
        type: ['string'],
      },
    },
    required: [
      'product',
      'organizationId',
      'username',
      'clientName',
      'preSignup',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Start Page Username Confirmed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StartPageUsernameSeleted' track call.
 *
 * @param StartPageUsernameSeleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function startPageUsernameSeleted(
  props: StartPageUsernameSeleted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Start_Page_Username_Seleted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user selects a username for their Start Page.',
    properties: {
      clientName: {
        description: 'The client that the event occurred in',
        type: ['string'],
      },
      organizationId: {
        description: 'The active organization of the user that took the action',
        type: ['string'],
      },
      preSignup: {
        description:
          'Did this event occur before the user signed up for a Buffer account?',
        type: ['boolean'],
      },
      product: {
        description: 'Which product did the event take place in?',
        type: ['string'],
      },
      totalBlocks: {
        description: 'The total number of blocks currently in the Start Page',
        type: ['integer'],
      },
      username: {
        description: 'The username selected for the Start Page',
        type: ['string'],
      },
    },
    required: [
      'product',
      'organizationId',
      'username',
      'clientName',
      'preSignup',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Start Page Username Seleted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StartPageUsernameUpdated' track call.
 *
 * @param StartPageUsernameUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function startPageUsernameUpdated(
  props: StartPageUsernameUpdated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Start_Page_Username_Updated',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user updates a username for their Start Page.',
    properties: {
      clientName: {
        description: 'The client that the event occurred in',
        type: ['string'],
      },
      organizationId: {
        description: 'The active organization of the user that took the action',
        type: ['string'],
      },
      preSignup: {
        description:
          'Did this event occur before the user signed up for a Buffer account?',
        type: ['boolean'],
      },
      product: {
        description: 'Which product did the event take place in?',
        type: ['string'],
      },
      totalBlocks: {
        description: 'The total number of blocks currently in the Start Page',
        type: ['integer'],
      },
      username: { description: 'The updated username', type: ['string'] },
    },
    required: [
      'product',
      'organizationId',
      'username',
      'clientName',
      'preSignup',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Start Page Username Updated',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StartTrialModalMainCtaClicked' track call.
 *
 * @param StartTrialModalMainCtaClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function startTrialModalMainCtaClicked(
  props: StartTrialModalMainCtaClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Start_Trial_Modal_Main_Cta_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user click the Start Trial Modal main cta?',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      clientName: {
        description:
          'Which client was the user in when they opened this modal?',
        type: ['string', 'null'],
      },
      cta: {
        description: 'The name of the CTA clicked.',
        type: ['string', 'null'],
      },
      isAtPlanLimit: {
        description: 'Is the user at their plan limit?',
        type: ['boolean', 'null'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that clicked the Start Trial main cta.',
        type: ['string', 'null'],
      },
      product: {
        description: 'Which product did the user click the main cta in?',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string', 'null'],
      },
      upgradePathName: {
        description: 'The name of the upgrade path',
        type: ['string', 'null'],
      },
    },
    required: [
      'organizationId',
      'product',
      'clientName',
      'cta',
      'upgradePathName',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Start Trial Modal Main Cta Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StartTrialModalViewed' track call.
 *
 * @param StartTrialModalViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function startTrialModalViewed(
  props: StartTrialModalViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Start_Trial_Modal_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user view the Start Trial modal?',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      clientName: {
        description:
          'Which client was the user in when they opened this modal?',
        type: ['string', 'null'],
      },
      cta: {
        description: 'The name of the CTA clicked.',
        type: ['string', 'null'],
      },
      isAtPlanLimit: {
        description: 'Is the user at their plan limit?',
        type: ['boolean', 'null'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the Start Trial modal.',
        type: ['string', 'null'],
      },
      product: {
        description: 'Which product did the user view the modal in?',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string', 'null'],
      },
      upgradePathName: {
        description: 'The name of the upgrade path',
        type: ['string', 'null'],
      },
    },
    required: [
      'organizationId',
      'product',
      'clientName',
      'cta',
      'upgradePathName',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Start Trial Modal Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StickerCopied' track call.
 *
 * @param StickerCopied props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function stickerCopied(
  props: StickerCopied,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Sticker_Copied',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'A user has copied the content of a sticker on the reminder post preview screen',
    properties: {
      channel: {
        description: 'The channel that the post was scheduled for',
        pattern: 'instagram|facebook',
        type: ['string'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishIos)',
        pattern: 'publishIos|publishAndroid',
        type: ['string'],
      },
      organizationId: {
        description: 'The global organization id that the channel belongs to',
        type: ['string'],
      },
      postId: {
        description: 'The post id for the reminder being shared',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occured',
        pattern: 'publish',
        type: ['string'],
      },
      sticker: {
        description: 'The post id for the reminder being shared',
        pattern:
          'link|text|music|location|shopping|first_comment|topics|caption|other',
        type: ['string'],
      },
    },
    required: [
      'product',
      'channel',
      'clientName',
      'organizationId',
      'postId',
      'sticker',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Sticker Copied',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StickerDeselected' track call.
 *
 * @param StickerDeselected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function stickerDeselected(
  props: StickerDeselected,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Sticker_Deselected',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User deselected a sticker in Composer/Reminders',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        pattern:
          'twitter|facebook|instagram|pinterest|linkedin|tiktok|googlebusiness|mastodon|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      clientName: {
        description:
          'Which client was the user in when they dragged the story? IE, `publishIos`, `publishWeb`, `publishAndroid`.',
        pattern: 'publishIos|publishWeb|publishAndroid',
        type: ['string'],
      },
      organizationId: {
        description: 'The Global organization ID.',
        type: ['string'],
      },
      sticker: {
        description: 'Name of the sticker selected',
        pattern: 'music|products|text|topics|link|other',
        type: ['string'],
      },
      updateType: {
        description: 'Type of the update, e.g. "post" (default) or "reel"',
        pattern:
          'post|story|reels|facebook_reel|short|whats_new|event|offer|thread',
        type: ['string'],
      },
    },
    required: [
      'channel',
      'clientName',
      'organizationId',
      'sticker',
      'updateType',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Sticker Deselected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StickerSelected' track call.
 *
 * @param StickerSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function stickerSelected(
  props: StickerSelected,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Sticker_Selected',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'User selected a sticker in Composer/Reminders',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        pattern:
          'twitter|facebook|instagram|pinterest|linkedin|tiktok|googlebusiness|mastodon|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      clientName: {
        description:
          'Which client was the user in when they dragged the story? IE, `publishIos`, `publishWeb`, `publishAndroid`.',
        pattern: 'publishIos|publishWeb|publishAndroid',
        type: ['string'],
      },
      organizationId: {
        description: 'The Global organization ID.',
        type: ['string'],
      },
      sticker: {
        description: 'Name of the sticker selected',
        pattern: 'music|products|text|topics|link|other',
        type: ['string'],
      },
      updateType: {
        description: 'Type of the update, e.g. "post" (default) or "reel"',
        pattern:
          'post|story|reels|facebook_reel|short|whats_new|event|offer|thread',
        type: ['string'],
      },
    },
    required: [
      'channel',
      'clientName',
      'organizationId',
      'sticker',
      'updateType',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Sticker Selected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StoriesInsightsMetricsCompared' track call.
 *
 * @param StoriesInsightsMetricsCompared props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function storiesInsightsMetricsCompared(
  props: StoriesInsightsMetricsCompared,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Stories_Insights_Metrics_Compared',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user compares metrics in Stories Insights.',
    properties: {
      channel: {
        description: 'The channel of the selected profile, ex. “instagram”.',
        type: ['string'],
      },
      channelId: {
        description:
          'The ID of the channel selected when the metrics were compared.',
        type: ['string'],
      },
      clientName: {
        description: 'The name of the client in which the action occurred.',
        type: ['string'],
      },
      metricOne: {
        description: 'The first metric selected in the comparison.',
        type: ['string'],
      },
      metricTwo: {
        description: 'The second metric selected in the comparison.',
        type: ['string'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that took the action.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred, ex. “analyze”.',
        type: ['string'],
      },
    },
    required: [
      'product',
      'organizationId',
      'clientName',
      'channel',
      'channelId',
      'metricOne',
      'metricTwo',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Stories Insights Metrics Compared',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StoriesInsightsSorted' track call.
 *
 * @param StoriesInsightsSorted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function storiesInsightsSorted(
  props: StoriesInsightsSorted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Stories_Insights_Sorted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user sorts the Stories Insights.',
    properties: {
      channel: {
        description: 'The channel of the selected profile, ex. “instagram”.',
        type: ['string'],
      },
      channelId: {
        description:
          'The ID of the channel selected when the story insights were sorted.',
        type: ['string'],
      },
      clientName: {
        description: 'The name of the client in which the action occurred.',
        type: ['string'],
      },
      metric: {
        description: 'What were the stories sorted by?',
        type: ['string'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that took the action.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred, ex. “analyze”.',
        type: ['string'],
      },
    },
    required: [
      'product',
      'organizationId',
      'clientName',
      'channel',
      'channelId',
      'metric',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Stories Insights Sorted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StoryDragged' track call.
 *
 * @param StoryDragged props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function storyDragged(
  props: StoryDragged,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Story_Dragged',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user drag and drop a story in the storygroup?',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel",
        type: ['string'],
      },
      clientId: {
        description:
          'What was the unique identifier of the client the user was in when they dragged the story?',
        type: ['string', 'null'],
      },
      clientName: {
        description:
          'Which client was the user in when they dragged the story? IE, `publishIos`, `publishWeb`, `publishAndroid`.',
        type: ['string'],
      },
      organizationId: {
        description: 'The Global organization ID.',
        type: ['string'],
      },
      product: {
        description: 'The product for which the storygroup was created',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: [
      'product',
      'channel',
      'channelId',
      'channelServiceId',
      'clientName',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Story Dragged',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StoryGroupComposerOpened' track call.
 *
 * @param StoryGroupComposerOpened props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function storyGroupComposerOpened(
  props: StoryGroupComposerOpened,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Story_Group_Composer_Opened',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user open the story group composer?',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "instagram"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel",
        type: ['string'],
      },
      clientId: {
        description:
          'What was the unique identifier of the client the user was in when they opened the story group composer?',
        type: ['string', 'null'],
      },
      clientName: {
        description:
          'Which client was the user in when they opened the story group composer? IE, `publishIos`, `publishWeb`, `publishAndroid`.',
        type: ['string'],
      },
      organizationId: {
        description: 'The Global organization ID.',
        type: ['string'],
      },
      product: {
        description: 'In which product did this event occur?',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: [
      'product',
      'channel',
      'channelId',
      'channelServiceId',
      'clientName',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Story Group Composer Opened',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StoryGroupCreated' track call.
 *
 * @param StoryGroupCreated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function storyGroupCreated(
  props: StoryGroupCreated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Story_Group_Created',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When did the user create a story group reminder? This event is triggered when the story group is originally created in the production database, which will differ from the timestamp of any updates to the story group or when the story group is sent.',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel",
        type: ['string'],
      },
      clientId: {
        description:
          'What was the unique identifier of the client the user was in when they created the story group?',
        type: ['string', 'null'],
      },
      clientName: {
        description:
          'Which client was the user in when they created the story group? IE, `publishIos`, `publishWeb`, `publishAndroid`.',
        type: ['string'],
      },
      cta: {
        description:
          'Which call to action did the user click to get to the story group composer?',
        type: ['string', 'null'],
      },
      ctaApp: {
        description:
          'Which product is the CTA located in? Examples would be either the product, like "publish".',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA? In many cases it makes sense to describe the intended result of the CTA, like `proTrial` or `publishPro`.',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be "menu" for the Pro trial CTA in Publish app shell menu.',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA? If setting the value for the first time, set to "1".',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'What app view is the CTA located in? Examples would be, "composer" or "analyticsOverview" for Publish.',
        type: ['string', 'null'],
      },
      imageCount: {
        description: 'The number of images included in the story group.',
        type: ['integer'],
      },
      mediaCount: {
        description: 'The number of media included in the story group.',
        type: ['integer'],
      },
      noteCount: {
        description: 'The number of notes included in the story group.',
        type: ['integer'],
      },
      organizationId: {
        description: 'The Global organization ID.',
        type: ['string'],
      },
      product: {
        description: 'The product for which the story was created',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      scheduledAt: {
        description: 'The time at which the reminder is scheduled to be sent.',
        type: ['string'],
      },
      storyGroupId: {
        description:
          'The unique identifier for this story group in our production database.',
        type: ['string'],
      },
      videoCount: {
        description: 'The number of videos included in the story group.',
        type: ['integer'],
      },
    },
    required: [
      'storyGroupId',
      'product',
      'channel',
      'channelId',
      'channelServiceId',
      'mediaCount',
      'imageCount',
      'videoCount',
      'noteCount',
      'scheduledAt',
      'clientName',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Story Group Created',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StoryGroupPreviewed' track call.
 *
 * @param StoryGroupPreviewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function storyGroupPreviewed(
  props: StoryGroupPreviewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Story_Group_Previewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When did the user Preview a story group reminder? This event is triggered each time the story group is previewed. There can be many preview events for a single story group.',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel",
        type: ['string'],
      },
      clientId: {
        description:
          'What was the unique identifier of the client the user was in when they previewed the story group?',
        type: ['string', 'null'],
      },
      clientName: {
        description:
          'Which client was the user in when they previewed the story group? IE, `publishIos`, `publishWeb`, `publishAndroid`.',
        type: ['string'],
      },
      cta: {
        description:
          'Which call to action did the user click to preview the story group?',
        type: ['string', 'null'],
      },
      ctaApp: {
        description:
          'Which product is the CTA located in? Examples would be either the product, like "publish".',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA? In many cases it makes sense to describe the intended result of the CTA, like `proTrial` or `publishPro`.',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be "menu" for the Pro trial CTA in Publish app shell menu.',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA? If setting the value for the first time, set to "1".',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'What app view is the CTA located in? Examples would be, "composer" or "analyticsOverview" for Publish.',
        type: ['string', 'null'],
      },
      imageCount: {
        description: 'The number of images included in the story group.',
        type: ['integer'],
      },
      mediaCount: {
        description: 'The number of media included in the story group.',
        type: ['integer'],
      },
      noteCount: {
        description: 'The number of notes included in the story group.',
        type: ['integer'],
      },
      organizationId: {
        description: 'The Global organization ID.',
        type: ['string'],
      },
      product: {
        description: 'The product for which the story group was created',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      scheduledAt: {
        description: 'The time at which the reminder is scheduled to be sent.',
        type: ['string'],
      },
      storyGroupId: {
        description:
          'The unique identifier for this story group in our production database.',
        type: ['string'],
      },
      videoCount: {
        description: 'The number of videos included in the story group.',
        type: ['integer'],
      },
    },
    required: [
      'product',
      'channel',
      'channelId',
      'channelServiceId',
      'mediaCount',
      'imageCount',
      'videoCount',
      'noteCount',
      'clientName',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Story Group Previewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StoryGroupUpdated' track call.
 *
 * @param StoryGroupUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function storyGroupUpdated(
  props: StoryGroupUpdated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Story_Group_Updated',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When did the user update a story group reminder? This event is triggered each time the story group is updated after it was originally created in the production database.',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel",
        type: ['string'],
      },
      clientId: {
        description:
          'What was the unique identifier of the client the user was in when they updated the story group?',
        type: ['string', 'null'],
      },
      clientName: {
        description:
          'Which client was the user in when they updated the story group? IE, `publishIos`, `publishWeb`, `publishAndroid`.',
        type: ['string'],
      },
      cta: {
        description:
          'Which call to action did the user click to get to the story group composer?',
        type: ['string', 'null'],
      },
      ctaApp: {
        description:
          'Which product is the CTA located in? Examples would be either the product, like "publish".',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA? In many cases it makes sense to describe the intended result of the CTA, like `proTrial` or `publishPro`.',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be "menu" for the Pro trial CTA in Publish app shell menu.',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA? If setting the value for the first time, set to "1".',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'What app view is the CTA located in? Examples would be, "composer" or "analyticsOverview" for Publish.',
        type: ['string', 'null'],
      },
      imageCount: {
        description: 'The number of images included in the story group.',
        type: ['integer'],
      },
      mediaCount: {
        description: 'The number of media included in the story group.',
        type: ['integer'],
      },
      noteCount: {
        description: 'The number of notes included in the story group.',
        type: ['integer'],
      },
      organizationId: {
        description: 'The Global organization ID.',
        type: ['string'],
      },
      product: {
        description: 'The product for which the story group was created',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      scheduledAt: {
        description: 'The time at which the reminder is scheduled to be sent.',
        type: ['string'],
      },
      storyGroupId: {
        description:
          'The unique identifier for this story group in our production database.',
        type: ['string'],
      },
      videoCount: {
        description: 'The number of videos included in the story group.',
        type: ['integer'],
      },
    },
    required: [
      'storyGroupId',
      'product',
      'channel',
      'channelId',
      'channelServiceId',
      'mediaCount',
      'imageCount',
      'videoCount',
      'noteCount',
      'scheduledAt',
      'clientName',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Story Group Updated',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StoryImageCropped' track call.
 *
 * @param StoryImageCropped props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function storyImageCropped(
  props: StoryImageCropped,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Story_Image_Cropped',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When did the user crop the image for this story? This event is triggered each time an image is cropped, so it is possible to have multiple events for one given story. The image preview crop is iOS specific.',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel",
        type: ['string'],
      },
      clientId: {
        description:
          'What was the unique identifier of the client the user was in when they cropped the Story image?',
        type: ['string', 'null'],
      },
      clientName: {
        description:
          'Which client was the user in when they cropped the image from the story preview? IE, `publishIos`, `publishWeb`, `publishAndroid`.',
        type: ['string'],
      },
      cropLocation: {
        description:
          'Where did the cropping take place? Was it in the composer or did it happen in the "time to post" screen?',
        type: ['string'],
      },
      cta: {
        description:
          'Which call to action did the user click to get to crop the image?',
        type: ['string', 'null'],
      },
      ctaApp: {
        description:
          'Which product is the CTA located in? Examples would be either the product, like "publish".',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA? In many cases it makes sense to describe the intended result of the CTA, like `proTrial` or `publishPro`.',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be "menu" for the Pro trial CTA in Publish app shell menu.',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA? If setting the value for the first time, set to "1".',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'What app view is the CTA located in? Examples would be, "composer" or "analyticsOverview" for Publish.',
        type: ['string', 'null'],
      },
      organizationId: {
        description: 'The Global organization ID.',
        type: ['string'],
      },
      product: {
        description: 'The product for which the story was created',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      scheduledAt: {
        description: 'The time at which the reminder is scheduled to be sent.',
        type: ['string'],
      },
      storyGroupId: {
        description:
          'The unique identifier for this story group in our production database.',
        type: ['string'],
      },
    },
    required: [
      'product',
      'channel',
      'channelId',
      'channelServiceId',
      'clientName',
      'cropLocation',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Story Image Cropped',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StoryImageDownloaded' track call.
 *
 * @param StoryImageDownloaded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function storyImageDownloaded(
  props: StoryImageDownloaded,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Story_Image_Downloaded',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user downloads an image from the stories creator.',
    properties: {
      clientName: {
        description: 'The client that the event occurred in',
        type: ['string'],
      },
      organizationId: {
        description: 'The active organization of the user that took the action',
        type: ['string'],
      },
      product: {
        description: 'Which product did the event take place in?',
        type: ['string'],
      },
    },
    required: ['properties'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Story Image Downloaded',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StoryNonconformingImageUploaded' track call.
 *
 * @param StoryNonconformingImageUploaded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function storyNonconformingImageUploaded(
  props: StoryNonconformingImageUploaded,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Story_Nonconforming_Image_Uploaded',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When did the user upload an image to a story, where the image did not conform to the 9:16 ratio required by Instagram?',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel",
        type: ['string'],
      },
      clientId: {
        description:
          'What was the unique identifier of the client the user was in when they uploaded a nonconforming image?',
        type: ['string', 'null'],
      },
      clientName: {
        description:
          'Which client was the user in when they uploaded a nonconforming image? IE, `publishIos`, `publishWeb`, `publishAndroid`.',
        type: ['string'],
      },
      cta: {
        description:
          'Which call to action did the user click to get to the Story composer?',
        type: ['string', 'null'],
      },
      ctaApp: {
        description:
          'Which product is the CTA located in? Examples would be either the product, like "publish".',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA? In many cases it makes sense to describe the intended result of the CTA, like `proTrial` or `publishPro`.',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be "menu" for the Pro trial CTA in Publish app shell menu.',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA? If setting the value for the first time, set to "1".',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'What app view is the CTA located in? Examples would be, "composer" or "analyticsOverview" for Publish.',
        type: ['string', 'null'],
      },
      imageAspectRatio: {
        description: 'The actual aspect ratio of the uploaded image.',
        type: ['string'],
      },
      organizationId: {
        description: 'The Global organization ID.',
        type: ['string'],
      },
      product: {
        description: 'The product for which the story was created',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      scheduledAt: {
        description: 'The time at which the reminder is scheduled to be sent.',
        type: ['string'],
      },
      storyGroupId: {
        description:
          'The unique identifier for this story group in our production database.',
        type: ['string'],
      },
    },
    required: [
      'product',
      'channel',
      'channelId',
      'channelServiceId',
      'clientName',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Story Nonconforming Image Uploaded',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StoryNoteAdded' track call.
 *
 * @param StoryNoteAdded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function storyNoteAdded(
  props: StoryNoteAdded,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Story_Note_Added',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user add a note to the story?',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel",
        type: ['string'],
      },
      characterCount: {
        description: 'How many characters are included in the note.',
        type: ['integer'],
      },
      clientId: {
        description:
          'What was the unique identifier of the client the user was in when they updated the story?',
        type: ['string', 'null'],
      },
      clientName: {
        description:
          'Which client was the user in when they added a note to the story? IE, `publishIos`, `publishWeb`, `publishAndroid`.',
        type: ['string'],
      },
      cta: {
        description:
          'Which call to action did the user click to get to the Story composer?',
        type: ['string', 'null'],
      },
      ctaApp: {
        description:
          'Which product is the CTA located in? Examples would be either the product, like "publish".',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA? In many cases it makes sense to describe the intended result of the CTA, like `proTrial` or `publishPro`.',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be "menu" for the Pro trial CTA in Publish app shell menu.',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA? If setting the value for the first time, set to "1".',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'What app view is the CTA located in? Examples would be, "composer" or "analyticsOverview" for Publish.',
        type: ['string', 'null'],
      },
      noteText: {
        description: 'What text value is included in the note field.',
        type: ['string'],
      },
      organizationId: {
        description: 'The Global organization ID.',
        type: ['string'],
      },
      product: {
        description: 'The product for which the story was created',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      storyGroupId: {
        description:
          'The unique identifier for this story group in our production database.',
        type: ['string'],
      },
    },
    required: [
      'product',
      'channel',
      'channelId',
      'channelServiceId',
      'clientName',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Story Note Added',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StoryNoteCopied' track call.
 *
 * @param StoryNoteCopied props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function storyNoteCopied(
  props: StoryNoteCopied,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Story_Note_Copied',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When did the user copy a note in one of the mobile apps? We use this as a proxy to let us know when the user actually posts the Story.',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel",
        type: ['string'],
      },
      clientId: {
        description:
          'What was the unique identifier of the client the user was in when they updated the story?',
        type: ['string', 'null'],
      },
      clientName: {
        description:
          'Which client was the user in when they copied the story note? IE, `publishIos`, `publishWeb`, `publishAndroid`.',
        type: ['string'],
      },
      organizationId: {
        description:
          'The organization ID of the user that copied the story note.',
        type: ['string'],
      },
      product: {
        description: 'The product for which the story was created',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: [
      'product',
      'channel',
      'channelId',
      'channelServiceId',
      'clientName',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Story Note Copied',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StoryOpenedInChannel' track call.
 *
 * @param StoryOpenedInChannel props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function storyOpenedInChannel(
  props: StoryOpenedInChannel,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Story_Opened_In_Channel',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When did the user open the Story in the channel (e.g. Instagram)? This event is triggered when a user taps "Open in Instagram".',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string'],
      },
      channelId: {
        description:
          'The database id of the channel for which the story was created.',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel.",
        type: ['string'],
      },
      clientId: {
        description:
          'What was the unique identifier of the client the user was in when they opened the Story in the native channel?',
        type: ['string', 'null'],
      },
      clientName: {
        description:
          'What was the name of the client the user was in when they opened the Story in the native channel?',
        type: ['string'],
      },
      product: {
        description: 'In which product did this event occur?',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      storyGroupId: {
        description:
          'The unique identifier for this story group in our production database.',
        type: ['string'],
      },
    },
    required: [
      'product',
      'clientName',
      'storyGroupId',
      'channel',
      'channelId',
      'channelServiceId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Story Opened In Channel',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StoryResumedInChannel' track call.
 *
 * @param StoryResumedInChannel props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function storyResumedInChannel(
  props: StoryResumedInChannel,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Story_Resumed_In_Channel',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When did the user choose to resume the story creating process in the channel?',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook"',
        type: ['string'],
      },
      channelId: {
        description: 'The database id for the channel document',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel",
        type: ['string'],
      },
      clientId: {
        description:
          'What was the unique identifier of the client the user was in when they updated the story?',
        type: ['string', 'null'],
      },
      clientName: {
        description:
          'Which client was the user in when they copied the story note? IE, `publishIos`, `publishWeb`, `publishAndroid`.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the story was created',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: [
      'product',
      'channel',
      'channelId',
      'channelServiceId',
      'clientName',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Story Resumed In Channel',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StreakButtonClicked' track call.
 *
 * @param StreakButtonClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function streakButtonClicked(
  props: StreakButtonClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Streak_Button_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user click a CTA on the streak widget?',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        pattern: 'publishWeb|publishIos|publishAndroid',
        type: ['string'],
      },
      cta: {
        description: 'The CTA string of the cta clicked',
        type: ['string'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that clicked the streak widget.',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      streakCount: {
        description: 'The number of days the user has posted in a row',
        type: ['number'],
      },
      streakStatus: {
        description: 'The status of the streak',
        type: ['string'],
      },
    },
    required: [
      'organizationId',
      'product',
      'cta',
      'streakCount',
      'streakStatus',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Streak Button Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StreakCountIncreased' track call.
 *
 * @param StreakCountIncreased props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function streakCountIncreased(
  props: StreakCountIncreased,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Streak_Count_Increased',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When an organization already in a streak increased their streak count',
    properties: {
      isNewMaxStreak: {
        description: 'Is this a new maximum streak length for the user?',
        type: ['boolean'],
      },
      maxStreakLength: {
        description: "What is this organization's maximum streak count?",
        type: ['number'],
      },
      newStreakCount: { description: 'The new streak count', type: ['number'] },
      organizationId: {
        description: 'The active organization of the user that took the action',
        type: ['string'],
      },
      streakStartDate: {
        description: 'When did the current streak start?',
        type: ['string', 'null'],
      },
      streakType: {
        description: 'The type of streak',
        pattern: 'posting',
        type: ['string'],
      },
    },
    required: [
      'organizationId',
      'streakType',
      'newStreakCount',
      'streakStartDate',
      'maxStreakLength',
      'isNewMaxStreak',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Streak Count Increased',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StreakCountReset' track call.
 *
 * @param StreakCountReset props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function streakCountReset(
  props: StreakCountReset,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Streak_Count_Reset',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When an organization loses a streak',
    properties: {
      organizationId: {
        description: 'The active organization of the user that took the action',
        type: ['string'],
      },
      previousStreakCount: {
        description: 'What was the streak count before it was reset?',
        type: ['number'],
      },
      resetMethod: {
        description:
          'Was the streak reset via the cron or by posting after midnight, before the cron processed this org?',
        pattern: 'cron|post',
        type: ['string'],
      },
      streakType: {
        description: 'The type of streak',
        pattern: 'posting',
        type: ['string'],
      },
      timezone: {
        description: "What is the organization's timezone?",
        type: ['string'],
      },
      wasMaxStreak: {
        description: "Was the most recent streak the organization's longest?",
        type: ['boolean'],
      },
    },
    required: [
      'organizationId',
      'streakType',
      'previousStreakCount',
      'wasMaxStreak',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Streak Count Reset',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StreakStarted' track call.
 *
 * @param StreakStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function streakStarted(
  props: StreakStarted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Streak_Started',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When an organization performed a streak action two days in a row, starting a new streak',
    properties: {
      organizationId: {
        description: 'The active organization of the user that took the action',
        type: ['string'],
      },
      streakType: {
        description: 'The type of streak',
        pattern: 'posting',
        type: ['string'],
      },
    },
    required: ['organizationId', 'streakType'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Streak Started',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StreakUpdated' track call.
 *
 * @param StreakUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function streakUpdated(
  props: StreakUpdated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Streak_Updated',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a streak was updated for an organization',
    properties: {
      newStreakCount: {
        description: 'The newly calculated streak value',
        type: ['number'],
      },
      newStreakStatus: {
        description: 'The newly calculated streak status (e.g. Hit, AtRisk)',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization to which the streak belongs',
        type: ['string'],
      },
      previousStreakCount: {
        description: 'The previous streak value',
        type: ['number'],
      },
      previousStreakStatus: {
        description: 'The previous streak status (e.g. OnTrack)',
        type: ['string'],
      },
      streakType: { description: 'The type of streak', type: ['string'] },
    },
    required: [
      'organizationId',
      'streakType',
      'newStreakCount',
      'newStreakStatus',
      'previousStreakCount',
      'previousStreakStatus',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Streak Updated',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'StripeCheckoutSessionCreated' track call.
 *
 * @param StripeCheckoutSessionCreated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function stripeCheckoutSessionCreated(
  props: StripeCheckoutSessionCreated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Stripe_Checkout_Session_Created',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When did the user clicked on the CTA to pay for a subscription and it opened Stripe Checkout?',
    properties: {
      channelQuantity: {
        description: 'Count of channels associated with this subscription.',
        type: ['number', 'null'],
      },
      cta: {
        description:
          'If the user navigated to this page from a CTA on another Buffer page, which call to action was it?',
        type: ['string', 'null'],
      },
      ctaApp: {
        description:
          'Which product or website is the CTA located in? Examples would be either the product, like `publish`, or the website, like `bufferSite`',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA?  In many cases it makes sense to describe the intended result of the CTA, like `proTrial` or `publishPro`',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in Publish app shell menu',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA?  If setting the value for the first time, set to `1`',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'What website page or app view is the CTA located?  Examples would be, `composer` or `analyticsOverview` for Publish, and `pricingPublish` for the Publish pricing page on the Buffer marketing site',
        type: ['string', 'null'],
      },
      initialChannelQuantity: {
        description:
          'What was the initial channel quantity shown on the plan selector when opened?',
        type: ['number', 'null'],
      },
      initialCycle: {
        description:
          'What was the initial billing cycle shown on the plan selector when opened?',
        type: ['string', 'null'],
      },
      initialSelectedPlan: {
        description:
          'What was the initial selected plan shown on the plan selector when opened?',
        type: ['string', 'null'],
      },
      isStripeCheckout: {
        description: 'Will the user be sent to Stripe Checkout for payment?',
        type: ['boolean', 'null'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the plan selector.',
        type: ['string', 'null'],
      },
      screenName: {
        description:
          'Human readable name of the section of the plan selector viewed (e.g., ""plans"", ""billing_info"", etc.)',
        type: ['string', 'null'],
      },
      selectedPlan: {
        description:
          'The selected plan of the user at time of Stripe Checkout creation.',
        type: ['string', 'null'],
      },
    },
    required: ['organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Stripe Checkout Session Created',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'SubscriptionAutomaticTaxUpdated' track call.
 *
 * @param SubscriptionAutomaticTaxUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function subscriptionAutomaticTaxUpdated(
  props: SubscriptionAutomaticTaxUpdated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Subscription_Automatic_Tax_Updated',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When was the subscription-level tax status changed?',
    properties: {
      customerId: {
        description: 'Unique identifier for the Stripe customer',
        type: ['string'],
      },
      customerTaxStatus: {
        description: "What is the Stripe customer's current tax status?",
        type: ['string'],
      },
      eventSource: {
        description: 'From which billing event was the tax status updated?',
        type: ['string'],
      },
      newAutomaticTaxStatus: {
        description:
          'Does the subscription now have automatic tax collection enabled?',
        type: ['boolean'],
      },
      organizationId: {
        description:
          'The ID of the organization for which the tax exempt was updated',
        type: ['string'],
      },
      previousAutomaticTaxStatus: {
        description:
          'Did the subscription previously have automoatic tax collection enabled?',
        type: ['boolean'],
      },
      stripeEventId: {
        description: 'Stripe event id from the webhook event',
        type: ['string'],
      },
      subscriptionId: {
        description:
          'Unique identifier for the Stripe subscription being updated',
        type: ['string'],
      },
    },
    required: [
      'organizationId',
      'stripeEventId',
      'customerId',
      'subscriptionId',
      'customerTaxStatus',
      'previousAutomaticTaxStatus',
      'newAutomaticTaxStatus',
      'eventSource',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Subscription Automatic Tax Updated',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'SubscriptionChannelQuantityChanged' track call.
 *
 * @param SubscriptionChannelQuantityChanged props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function subscriptionChannelQuantityChanged(
  props: SubscriptionChannelQuantityChanged,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Subscription_Channel_Quantity_Changed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When the number of channels associated with the subscription changes. This event will trigger each time a user adds or removes a channel during a paid subscription, but it is possible for multiple channel adds to be represented by one event as a result of a bulk add UI option.',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      changeValue: {
        description:
          'The absolute dollar amount per month that this channel change is worth. If the cost per channel per month is $10, and the user removed a channel, this value would be `10`. If the channel count went from 1 to 4 (as a result of a bulk add) and the subscription was for $5 per channel, this value would be `15`.',
        type: ['number'],
      },
      channelQuantity: {
        description:
          'Count of channels associated with this subscription after this change was made.',
        type: ['number'],
      },
      channelsAdded: {
        description: 'Channels added to the subscription',
        type: ['array'],
      },
      channelsRemoved: {
        description: 'Channels removed from the subscription',
        type: ['array'],
      },
      customerId: {
        description: 'Unique identifier for the billing gateway customer.',
        type: ['string'],
      },
      gateway: {
        description: 'What triggered this event? e.g. Stripe or RevenueCat',
        type: ['string'],
      },
      organizationId: {
        description: 'The ID of the organization whose plan changed.',
        type: ['string'],
      },
      planId: { description: 'Billing gateway plan id.', type: ['string'] },
      planName: { description: 'Human-readable plan name.', type: ['string'] },
      previousChannelQuantity: {
        description:
          'Count of channels associated with this subscription before this change was made.',
        type: ['number'],
      },
      product: {
        description: 'The product which the subscription is for.',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      quantityDiff: {
        description:
          'What is the difference in quantity between the current and previous quantity',
        type: ['number', 'null'],
      },
      revenueEffect: {
        description:
          'The type of impact to Buffer MRR as a result of this channel quantity change.',
        pattern: 'increase|decrease|none',
        type: ['string'],
      },
      stripeEventId: {
        description: 'Stripe event id from the webhook event.',
        type: ['string'],
      },
      subscriptionId: {
        description: 'Unique identifier for the subscription.',
        type: ['string'],
      },
    },
    required: ['organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Subscription Channel Quantity Changed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'SubscriptionDowngraded' track call.
 *
 * @param SubscriptionDowngraded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function subscriptionDowngraded(
  props: SubscriptionDowngraded,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Subscription_Downgraded',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Understand when a user modifies the plan or billing quantity of a paid subscription that results in a lower revenue amount or projected lower LTV',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      clientName: {
        description: 'The client in which the event occurred.',
        pattern:
          'publishWeb|publishIos|publishAndroid|core|bufferWeb|ios|android',
        type: ['string'],
      },
      cta: {
        description:
          'Which call to action did the user click to downgrade their paid subscription',
        type: ['string', 'null'],
      },
      ctaApp: {
        description:
          'Which product or website is the CTA located in? Examples would be either the product, like `publish`, or the website, like `bufferSite`',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA?  In many cases it makes sense to describe the intended result of the CTA, like `proTrial` or `publishPro`',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in Publish app shell menu',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA?  If setting the value for the first time, set to `1`',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'What website page or app view is the CTA located?  Examples would be, `composer` or `analyticsOverview` for Publish, and `pricingPublish` for the Publish pricing page on the Buffer marketing site',
        type: ['string', 'null'],
      },
      customerId: {
        description: 'Unique identifier for the Stripe customer',
        type: ['string'],
      },
      cycle: {
        description:
          'What was the payment interval for this subscription (month or year) after the downgrade event?',
        type: ['string'],
      },
      gateway: {
        description: 'What triggered this event? e.g. Stripe or RevenueCat',
        type: ['string'],
      },
      hasTaxId: {
        description: 'Does the user have a tax ID?',
        type: ['boolean', 'null'],
      },
      isAutomaticTaxEnabled: {
        description:
          'Is automatic tax collection in Stripe enabled for this user?',
        type: ['boolean', 'null'],
      },
      isPlanCycleChangeOnly: {
        description:
          'Was this subscription downgrade event the result of a change only to the billing cycle changing to a shorter duration?  For example, this would be true if the subscription plan changed from `Premium Business v1 - Yearly` to `Premium Business v1 - Monthly`.',
        type: ['boolean'],
      },
      isStripeCheckout: {
        description: 'Was the subscription downgraded via Stripe Checkout?',
        type: ['boolean', 'null'],
      },
      isTaxExempt: {
        description: 'Is the user tax exempt?',
        type: ['boolean', 'null'],
      },
      mrrValue: {
        description:
          'What was the monthly recurring value of the subscription after the downgrade event? If the billing cycle is yearly, this amount will be the annual amount divided by 12.',
        type: ['number'],
      },
      organizationId: {
        description:
          'The ID of the organization whose subscription was downgraded.',
        type: ['string'],
      },
      planId: {
        description:
          'What is the unique identifier in Stripe of the billing plan for the subscription after the downgrade event?',
        type: ['string'],
      },
      planName: {
        description:
          'What was the human-readable plan name for the subscription after the downgrade event?',
        type: ['string'],
      },
      previousBillingState: {
        description:
          "What was the user's previous billing state before making this change? (e.g. free, trial, paid)",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      previousCycle: {
        description:
          'What was the payment interval for this subscription (month or year) before the downgrade event?',
        type: ['string'],
      },
      previousMrrValue: {
        description:
          'What was the monthly recurring value of the subscription before the downgrade event? If the billing cycle is yearly, this amount will be the annual amount divided by 12.',
        type: ['number'],
      },
      previousPlanId: {
        description:
          'What is the unique identifier in Stripe of the billing plan for the subscription before the downgrade event?',
        type: ['string'],
      },
      previousPlanName: {
        description:
          'What was the human-readable plan name for the subscription before the downgrade event?',
        type: ['string'],
      },
      previousQuantity: {
        description: 'What was the previous quantity of the subscription?',
        type: ['number', 'null'],
      },
      product: {
        description: 'Which product was this subscription for?',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      quantity: {
        description: 'What is the current quantity of the subscription?',
        type: ['number', 'null'],
      },
      quantityDiff: {
        description:
          'What is the difference in quantity between the current and previous quantity',
        type: ['number', 'null'],
      },
      stripeEventId: {
        description:
          'What was the unique identifier for the downgrade event from the Stripe webhook?',
        type: ['string'],
      },
      subscriptionId: {
        description:
          'What was the unique identifier for the subscription in Stripe?',
        type: ['string'],
      },
    },
    required: ['product', 'subscriptionId', 'stripeEventId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Subscription Downgraded',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'SubscriptionEnded' track call.
 *
 * @param SubscriptionEnded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function subscriptionEnded(
  props: SubscriptionEnded,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Subscription_Ended',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      "When did the user's paid subscription end for this product and plan?",
    properties: {
      amount: { description: 'Cost of the plan after trial', type: ['number'] },
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      clientName: {
        description: 'The client in which the event occurred.',
        pattern:
          'publishWeb|publishIos|publishAndroid|core|bufferWeb|ios|android',
        type: ['string'],
      },
      customerId: {
        description: 'Unique identifier for the Stripe customer',
        type: ['string'],
      },
      cycle: {
        description: 'The subscription payment interval (month or year)',
        type: ['string'],
      },
      endDate: {
        description: 'End date of the subscription (ISO-8601)',
        type: ['string'],
      },
      eventTrigger: {
        description:
          'What caused this event to be triggered?  If this event was triggered via normal user behavior, the property value will be null.  Examples of values that might be populated in this property are `system_migration` and `bug_fix_backfill`',
        type: ['string', 'null'],
      },
      gateway: {
        description: 'What triggered this event? e.g. Stripe or RevenueCat',
        type: ['string'],
      },
      multiProductBundleName: {
        description:
          "What was the name of the multi product bundle that this subscription is associated with? For example, 'analyze_publish_oct2019'",
        type: ['string', 'null'],
      },
      organizationId: {
        description: 'The ID of the organization whose subscription ended.',
        type: ['string'],
      },
      planId: { description: 'Stripe plan id', type: ['string'] },
      planName: { description: 'Human-readable plan name', type: ['string'] },
      product: {
        description: 'The product which the subscription is for',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      revenue: {
        description:
          'MRR Value (if monthly then amount, if yearly, divide yearly amount by 12)',
        type: ['number'],
      },
      stripeEventId: {
        description: 'Stripe event id from the webhook event',
        type: ['string'],
      },
      subscriptionId: {
        description: 'Unique identifier for trial/subscription (Stripe sub id)',
        type: ['string'],
      },
    },
    required: ['product'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Subscription Ended',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'SubscriptionPlanChanged' track call.
 *
 * @param SubscriptionPlanChanged props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function subscriptionPlanChanged(
  props: SubscriptionPlanChanged,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Subscription_Plan_Changed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When the plan associated with the subscription changes.',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      cta: {
        description:
          'Which call to action did the user click to start their paid subscription',
        type: ['string', 'null'],
      },
      ctaApp: {
        description:
          'Which product or website is the CTA located in? Examples would be either the product, like `publish`, or the website, like `bufferSite`',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA?  In many cases it makes sense to describe the intended result of the CTA, like `proTrial` or `publishPro`',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in Publish app shell menu',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA?  If setting the value for the first time, set to `1`',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'What website page or app view is the CTA located?  Examples would be, `composer` or `analyticsOverview` for Publish, and `pricingPublish` for the Publish pricing page on the Buffer marketing site',
        type: ['string', 'null'],
      },
      customerId: {
        description: 'Unique identifier for the Stripe customer',
        type: ['string'],
      },
      cycle: {
        description: 'The subscription payment interval (month or year)',
        type: ['string'],
      },
      gateway: {
        description: 'What triggered this event? e.g. Stripe or RevenueCat',
        type: ['string'],
      },
      isStripeCheckout: {
        description: 'Was the subscription plan changed via Stripe Checkout?',
        type: ['boolean', 'null'],
      },
      organizationId: {
        description: 'The ID of the organization whose plan changed.',
        type: ['string'],
      },
      planId: { description: 'Stripe plan id', type: ['string'] },
      planName: { description: 'Human-readable plan name', type: ['string'] },
      previousCycle: {
        description: 'The billing interval of the previous plan.',
        type: ['string'],
      },
      previousPlanId: {
        description: 'Stripe plan id of the previous plan, if exists',
        type: ['string'],
      },
      previousPlanName: {
        description: 'Human-readable plan name of the previous plan, if exists',
        type: ['string'],
      },
      product: {
        description: 'The product which the subscription is for',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      stripeEventId: {
        description: 'Stripe event id from the webhook event',
        type: ['string'],
      },
      subscriptionId: {
        description: 'Unique identifier for the subscription',
        type: ['string'],
      },
    },
    required: ['product'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Subscription Plan Changed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'SubscriptionScheduleCreated' track call.
 *
 * @param SubscriptionScheduleCreated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function subscriptionScheduleCreated(
  props: SubscriptionScheduleCreated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Subscription_Schedule_Created',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When was a new subscription schedule created for a subscription?',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      customerId: {
        description: 'Unique identifier for the Stripe customer',
        type: ['string'],
      },
      organizationId: {
        description: 'The active organization of the user that took the action',
        type: ['string'],
      },
      stripeEventId: {
        description: 'Stripe event id from the webhook event',
        type: ['string'],
      },
      subscriptionId: {
        description: 'Unique identifier for trial/subscription (Stripe sub id)',
        type: ['string'],
      },
      subscriptionScheduleId: {
        description: 'Unique identifier for the subscription schedule',
        type: ['string'],
      },
    },
    required: [
      'organizationId',
      'subscriptionScheduleId',
      'subscriptionId',
      'customerId',
      'stripeEventId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Subscription Schedule Created',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'SubscriptionScheduleRemoved' track call.
 *
 * @param SubscriptionScheduleRemoved props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function subscriptionScheduleRemoved(
  props: SubscriptionScheduleRemoved,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Subscription_Schedule_Removed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When was a new subscription schedule removed for a subscription?',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      customerId: {
        description: 'Unique identifier for the Stripe customer',
        type: ['string'],
      },
      organizationId: {
        description: 'The active organization of the user that took the action',
        type: ['string'],
      },
      reason: {
        description:
          'Reason for schedule removal (customer-initiated or automatic due to upgrade?)',
        type: ['string'],
      },
      stripeEventId: {
        description: 'Stripe event id from the webhook event',
        type: ['string'],
      },
      subscriptionId: {
        description: 'Unique identifier for trial/subscription (Stripe sub id)',
        type: ['string'],
      },
      subscriptionScheduleId: {
        description: 'Unique identifier for the subscription schedule',
        type: ['string'],
      },
    },
    required: [
      'organizationId',
      'subscriptionScheduleId',
      'reason',
      'subscriptionId',
      'customerId',
      'stripeEventId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Subscription Schedule Removed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'SubscriptionScheduleUpdated' track call.
 *
 * @param SubscriptionScheduleUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function subscriptionScheduleUpdated(
  props: SubscriptionScheduleUpdated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Subscription_Schedule_Updated',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When was a new subscription schedule updated for a subscription?',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      customerId: {
        description: 'Unique identifier for the Stripe customer',
        type: ['string'],
      },
      effectiveDate: {
        description: 'When are the subscription changes set to take effect?',
        type: ['string'],
      },
      newBillingCycle: {
        description:
          'At the next subscription schedule phase, what is the new billing cycle?',
        type: ['string'],
      },
      newPlan: {
        description:
          'At the next subscription schedule phase, to what plan is the user moving?',
        type: ['string'],
      },
      newQuantity: {
        description:
          'At the next subscription schedule phase, what is the new channel count?',
        type: ['number'],
      },
      organizationId: {
        description: 'The active organization of the user that took the action',
        type: ['string'],
      },
      stripeEventId: {
        description: 'Stripe event id from the webhook event',
        type: ['string'],
      },
      subscriptionId: {
        description: 'Unique identifier for trial/subscription (Stripe sub id)',
        type: ['string'],
      },
      subscriptionScheduleId: {
        description: 'Unique identifier for the subscription schedule',
        type: ['string'],
      },
    },
    required: [
      'organizationId',
      'subscriptionScheduleId',
      'subscriptionId',
      'customerId',
      'stripeEventId',
      'newPlan',
      'newQuantity',
      'newBillingCycle',
      'effectiveDate',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Subscription Schedule Updated',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'SubscriptionStarted' track call.
 *
 * @param SubscriptionStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function subscriptionStarted(
  props: SubscriptionStarted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Subscription_Started',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When did the paid subscription start?  This event is triggered when the first invoice that is greater than $0 is paid for the given subscription.',
    properties: {
      amount: { description: 'Cost of the plan after trial', type: ['number'] },
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      channelsConnected: {
        description:
          'At the time of this event, which channels did the user have connected?',
        type: ['array'],
      },
      clientName: {
        description: 'The client in which the event occurred.',
        pattern:
          'publishWeb|publishIos|publishAndroid|core|bufferWeb|ios|android',
        type: ['string'],
      },
      cta: {
        description:
          'Which call to action did the user click to start their paid subscription',
        type: ['string', 'null'],
      },
      ctaApp: {
        description:
          'Which product or website is the CTA located in? Examples would be either the product, like `publish`, or the website, like `bufferSite`',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA?  In many cases it makes sense to describe the intended result of the CTA, like `proTrial` or `publishPro`',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in Publish app shell menu',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA?  If setting the value for the first time, set to `1`',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'What website page or app view is the CTA located?  Examples would be, `composer` or `analyticsOverview` for Publish, and `pricingPublish` for the Publish pricing page on the Buffer marketing site',
        type: ['string', 'null'],
      },
      customerId: {
        description: 'Unique identifier for the Stripe customer',
        type: ['string'],
      },
      cycle: {
        description: 'The subscription payment interval (month or year)',
        type: ['string'],
      },
      eventTrigger: {
        description:
          'What caused this event to be triggered?  If this event was triggered via normal user behavior, the property value will be null.  Examples of values that might be populated in this property are `system_migration` and `bug_fix_backfill`',
        type: ['string', 'null'],
      },
      gateway: {
        description: 'What triggered this event? e.g. Stripe or RevenueCat',
        type: ['string'],
      },
      hasTaxId: {
        description: 'Does the user have a tax ID?',
        type: ['boolean', 'null'],
      },
      isAutomaticTaxEnabled: {
        description:
          'Is automatic tax collection in Stripe enabled for this user?',
        type: ['boolean', 'null'],
      },
      isStripeCheckout: {
        description: 'Was the subscription started via Stripe Checkout?',
        type: ['boolean', 'null'],
      },
      isTaxExempt: {
        description: 'Is the user tax exempt?',
        type: ['boolean', 'null'],
      },
      multiProductBundleName: {
        description:
          "What was the name of the multi product bundle that this subscription is associated with? For example, 'analyze_publish_oct2019'",
        type: ['string', 'null'],
      },
      organizationId: {
        description:
          'The ID of the organization whose subscription was created.',
        type: ['string'],
      },
      planId: { description: 'Stripe plan id', type: ['string'] },
      planName: { description: 'Human-readable plan name', type: ['string'] },
      product: {
        description: 'The product which the subscription is for',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      revenue: {
        description:
          'MRR Value (if monthly then amount, if yearly, divide yearly amount by 12)',
        type: ['number'],
      },
      stripeEventId: {
        description: 'Stripe event id from the webhook event',
        type: ['string'],
      },
      subscriptionId: {
        description: 'Unique identifier for trial/subscription (Stripe sub id)',
        type: ['string'],
      },
      taxAmount: {
        description: 'How much tax was the user charged?',
        type: ['number', 'null'],
      },
      upgradePathName: {
        description: 'The name of the upgrade path',
        type: ['string', 'null'],
      },
      utmCampaign: {
        description:
          'What was the specific campaign (e.g. product launch, new feature, partnership, etc.) that resulted in a subscription start?',
        type: ['string', 'null'],
      },
      utmContent: {
        description:
          'What was the specific content or content that the subscription start is attributed to. For example a specific CTA or part of an email that is being A/B tested.',
        type: ['string', 'null'],
      },
      utmMedium: {
        description:
          'What is the medium of the link that was used to start a subscription? For example email, CPC, or other method of sharing.',
        type: ['string', 'null'],
      },
      utmSource: {
        description:
          'What is the source of traffic that resulted in a subscription start? For example search engine, newsletter, or other referral.',
        type: ['string', 'null'],
      },
      utmTerm: {
        description:
          'What is the paid search term or keyword used to drive user to the page on which the subscription was started? For example DTC, Instagram scheduling.',
        type: ['string', 'null'],
      },
      wasChargedTax: {
        description: 'Was the customer charged tax?',
        type: ['boolean', 'null'],
      },
    },
    required: ['product'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Subscription Started',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'SubscriptionUpgraded' track call.
 *
 * @param SubscriptionUpgraded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function subscriptionUpgraded(
  props: SubscriptionUpgraded,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Subscription_Upgraded',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'Understand when a user modifies the plan or billing quantity of a paid subscription that results in a higher revenue amount or projected higher LTV',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      channelsConnected: {
        description:
          'At the time of this event, which channels did the user have connected?',
        type: ['array'],
      },
      clientName: {
        description: 'The client in which the event occurred.',
        pattern:
          'publishWeb|publishIos|publishAndroid|core|bufferWeb|ios|android',
        type: ['string'],
      },
      cta: {
        description:
          'Which call to action did the user click to upgrade their paid subscription',
        type: ['string', 'null'],
      },
      ctaApp: {
        description:
          'Which product or website is the CTA located in? Examples would be either the product, like `publish`, or the website, like `bufferSite`',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA?  In many cases it makes sense to describe the intended result of the CTA, like `proTrial` or `publishPro`',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in Publish app shell menu',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA?  If setting the value for the first time, set to `1`',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'What website page or app view is the CTA located?  Examples would be, `composer` or `analyticsOverview` for Publish, and `pricingPublish` for the Publish pricing page on the Buffer marketing site',
        type: ['string', 'null'],
      },
      customerId: {
        description: 'Unique identifier for the Stripe customer',
        type: ['string'],
      },
      cycle: {
        description:
          'What was the payment interval for this subscription (month or year) after the upgrade event?',
        type: ['string'],
      },
      gateway: {
        description: 'What triggered this event? e.g. Stripe or RevenueCat',
        type: ['string'],
      },
      hasTaxId: {
        description: 'Does the user have a tax ID?',
        type: ['boolean', 'null'],
      },
      isAutomaticTaxEnabled: {
        description:
          'Is automatic tax collection in Stripe enabled for this user?',
        type: ['boolean', 'null'],
      },
      isPlanCycleChangeOnly: {
        description:
          'Was this subscription upgrade event the result of a change only to the billing cycle changing to a longer duration?  For example, this would be true if the subscription plan changed from `Premium Business v1 - Monthly` to `Premium Business v1 - Yearly`.',
        type: ['boolean'],
      },
      isStripeCheckout: {
        description: 'Was the subscription upgraded via Stripe Checkout?',
        type: ['boolean', 'null'],
      },
      isTaxExempt: {
        description: 'Is the user tax exempt?',
        type: ['boolean', 'null'],
      },
      mrrValue: {
        description:
          'What was the monthly recurring value of the subscription after the upgrade event? If the billing cycle is yearly, this amount will be the annual amount divided by 12.',
        type: ['number'],
      },
      organizationId: {
        description:
          'The ID of the organization whose subscription was upgraded.',
        type: ['string'],
      },
      planId: {
        description:
          'What is the unique identifier in Stripe of the billing plan for the subscription after the upgrade event?',
        type: ['string'],
      },
      planName: {
        description:
          'What was the human-readable plan name for the subscription after the upgrade event?',
        type: ['string'],
      },
      previousBillingState: {
        description:
          "What was the user's previous billing state before making this change? (e.g. free, trial, paid)",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      previousCycle: {
        description:
          'What was the payment interval for this subscription (month or year) before the upgrade event?',
        type: ['string'],
      },
      previousMrrValue: {
        description:
          'What was the monthly recurring value of the subscription before the upgrade event? If the billing cycle is yearly, this amount will be the annual amount divided by 12.',
        type: ['number'],
      },
      previousPlanId: {
        description:
          'What is the unique identifier in Stripe of the billing plan for the subscription was before the upgrade event?',
        type: ['string'],
      },
      previousPlanName: {
        description:
          'What was the human-readable plan name for the subscription before the upgrade event?',
        type: ['string'],
      },
      previousQuantity: {
        description: 'What was the previous quantity of the subscription?',
        type: ['number', 'null'],
      },
      product: {
        description: 'Which product was this subscription for?',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      quantity: {
        description: 'What is the current quantity of the subscription?',
        type: ['number', 'null'],
      },
      quantityDiff: {
        description:
          'What is the difference in quantity between the current and previous quantity',
        type: ['number', 'null'],
      },
      stripeEventId: {
        description:
          'What was the unique identifier for the upgrade event from the Stripe webhook?',
        type: ['string'],
      },
      subscriptionId: {
        description:
          'What was the unique identifier for the subscription in Stripe?',
        type: ['string'],
      },
      taxAmount: {
        description: 'How much tax was the user charged?',
        type: ['number', 'null'],
      },
      upgradePathName: {
        description: 'The name of the upgrade path',
        type: ['string', 'null'],
      },
      wasChargedTax: {
        description: 'Was the customer charged tax?',
        type: ['boolean', 'null'],
      },
    },
    required: ['product', 'subscriptionId', 'stripeEventId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Subscription Upgraded',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'SupportSatisfactionRatingSubmitted' track call.
 *
 * @param SupportSatisfactionRatingSubmitted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function supportSatisfactionRatingSubmitted(
  props: SupportSatisfactionRatingSubmitted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Support_Satisfaction_Rating_Submitted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'A customer submitted a satisfaction rating for a support conversation',
    properties: {
      assigneeRated: {
        description: 'The name of the advocate whose reply was rated',
        type: ['string', 'null'],
      },
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      comment: {
        description: 'The comment left by the customer',
        type: ['string', 'null'],
      },
      conversationId: {
        description:
          'The ID associated with the specific Help Scout conversation',
        type: ['string'],
      },
      conversationUrl: {
        description: 'A link to the specific Help Scout conversation',
        type: ['string', 'null'],
      },
      mailboxId: {
        description: 'The ID of the mailbox the conversation was created in',
        type: ['string'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the page.',
        type: ['string', 'null'],
      },
      rating: {
        description: 'The rating left by the customer',
        type: ['string', 'null'],
      },
      ratingId: {
        description: 'The ID associated with the specific Help Scout rating',
        type: ['string'],
      },
      threadId: {
        description: 'The ID associated with the specific Help Scout thread',
        type: ['string', 'null'],
      },
    },
    required: ['ratingId', 'conversationId', 'mailboxId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Support Satisfaction Rating Submitted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'SupportTicketCreated' track call.
 *
 * @param SupportTicketCreated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function supportTicketCreated(
  props: SupportTicketCreated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Support_Ticket_Created',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'A customer created a new support ticket',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      ticketAssigneeName: {
        description: 'The name of the advocate assigned to the ticket',
        type: ['string'],
      },
      ticketGroupName: {
        description:
          'The name of the group assigned to the ticket, ex. "Customer Engagement"',
        type: ['string'],
      },
      ticketId: {
        description:
          'The Id associated with the specific Zendesk support conversation',
        type: ['string'],
      },
      ticketPriority: {
        description: 'The urgency assigned to the ticket by the customer.',
        type: ['string'],
      },
      ticketSource: {
        description:
          'How the ticket was created, ex. "Web Widget", "Mail", "Ticket Form", ...',
        type: ['string'],
      },
      ticketSubject: {
        description: 'The subject message of the ticket',
        type: ['string'],
      },
      ticketTags: {
        description: 'A list of tags applied to the ticket',
        type: ['array'],
      },
      ticketUrl: {
        description: 'A link to the the specific Zendesk support conversation',
        type: ['string'],
      },
    },
    required: ['ticketId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Support Ticket Created',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'SupportTicketReplySent' track call.
 *
 * @param SupportTicketReplySent props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function supportTicketReplySent(
  props: SupportTicketReplySent,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Support_Ticket_Reply_Sent',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'A Customer Advocate replied to a Support Ticket',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      ticketAssigneeName: {
        description: 'The name of the advocate assigned to the ticket',
        type: ['string'],
      },
      ticketGroupName: {
        description:
          'The name of the group assigned to the ticket, ex. "Customer Engagement"',
        type: ['string'],
      },
      ticketId: {
        description:
          'The Id associated with the specific Zendesk support conversation',
        type: ['string'],
      },
      ticketSatisfactionComment: {
        description:
          'The comment a customer gave with their satisfaction rating',
        type: ['string'],
      },
      ticketSatisfactionRating: {
        description:
          'The satisfaction rating a customer gave from their support experience',
        type: ['string'],
      },
      ticketSource: {
        description:
          'How the ticket was created, ex. "Web Widget", "Mail", "Ticket Form", ...',
        type: ['string'],
      },
      ticketStatus: {
        description:
          'The current status of the ticket, ex. “open”, “pending”, “solved”',
        type: ['string'],
      },
      ticketSubject: {
        description: 'The subject message of the ticket',
        type: ['string'],
      },
      ticketTags: {
        description: 'A list of tags applied to the ticket',
        type: ['array'],
      },
      ticketUrl: {
        description: 'A link to the the specific Zendesk support conversation',
        type: ['string'],
      },
    },
    required: ['ticketId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Support Ticket Reply Sent',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'SupportTicketUpdated' track call.
 *
 * @param SupportTicketUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function supportTicketUpdated(
  props: SupportTicketUpdated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Support_Ticket_Updated',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'A ticket was updated modifying some of its information',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      ticketAssigneeName: {
        description: 'The name of the advocate assigned to the ticket',
        type: ['string'],
      },
      ticketGroupName: {
        description:
          'The name of the group assigned to the ticket, ex. "Customer Engagement"',
        type: ['string'],
      },
      ticketId: {
        description:
          'The Id associated with the specific Zendesk support conversation',
        type: ['string'],
      },
      ticketPriority: {
        description: '​​The urgency assigned to the ticket by the customer.',
        type: ['string'],
      },
      ticketSatisfactionComment: {
        description:
          'The comment a customer gave with their statifaction rating',
        type: ['string'],
      },
      ticketSatisfactionRating: {
        description:
          'The satisfaction rating a customer gave from their support experience',
        type: ['string'],
      },
      ticketSource: {
        description:
          'How the ticket was created, ex. "Web Widget", "Mail", "Ticket Form", ...',
        type: ['string'],
      },
      ticketStatus: {
        description:
          'The current status of the ticket, ex. “open”, “pending”, “solved”',
        type: ['string'],
      },
      ticketSubject: {
        description: 'The subject message of the ticket',
        type: ['string'],
      },
      ticketTags: {
        description: 'A list of tags applied to the ticket',
        type: ['array'],
      },
      ticketUpdatedBy: {
        description: 'Was the ticket updated by an agent or a customer?',
        pattern: 'agent|customer',
        type: ['string', 'null'],
      },
      ticketUrl: {
        description: 'A link to the the specific Zendesk support conversation',
        type: ['string'],
      },
    },
    required: ['ticketId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Support Ticket Updated',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'TagCreated' track call.
 *
 * @param TagCreated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function tagCreated(
  props: TagCreated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Tag_Created',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a new tag is created.',
    properties: {
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        type: ['string'],
      },
      organizationId: {
        description: 'The global organization ID that the tag was created for.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occurred',
        pattern: 'publish',
        type: ['string'],
      },
      publishOrganizationId: {
        description:
          'The ID of the Publish organization that the tag was created for.',
        type: ['string'],
      },
      tagColor: {
        description: 'The color selected for the tag.',
        type: ['string'],
      },
      tagId: { description: 'The ID of the new tag.', type: ['string'] },
      tagName: { description: 'The name given to the tag.', type: ['string'] },
    },
    required: [
      'product',
      'tagId',
      'tagName',
      'tagColor',
      'clientName',
      'organizationId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Tag Created',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'TagDeleted' track call.
 *
 * @param TagDeleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function tagDeleted(
  props: TagDeleted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Tag_Deleted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a tag is deleted.',
    properties: {
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        type: ['string'],
      },
      organizationId: {
        description: 'The global organization ID that the tag was created for.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occurred.',
        pattern: 'publish',
        type: ['string'],
      },
      publishOrganizationId: {
        description:
          'The ID of the Publish organization that the tag was created for.',
        type: ['string'],
      },
      tagColor: {
        description: 'The color selected for the tag.',
        type: ['string'],
      },
      tagId: {
        description: 'The unique ID assigned to the tag.',
        type: ['string'],
      },
      tagName: { description: 'The name given to the tag.', type: ['string'] },
    },
    required: [
      'product',
      'tagId',
      'tagName',
      'tagColor',
      'clientName',
      'organizationId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Tag Deleted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'TagEdited' track call.
 *
 * @param TagEdited props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function tagEdited(
  props: TagEdited,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Tag_Edited',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a tag is edited.',
    properties: {
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        type: ['string'],
      },
      organizationId: {
        description: 'The global organization ID that the tag was created for.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occurred',
        pattern: 'publish',
        type: ['string'],
      },
      publishOrganizationId: {
        description:
          'The ID of the Publish organization that the tag was created for.',
        type: ['string'],
      },
      tagColor: {
        description: 'The color selected for the tag.',
        type: ['string'],
      },
      tagId: { description: 'The ID of the tag.', type: ['string'] },
      tagName: { description: 'The name given to the tag.', type: ['string'] },
    },
    required: [
      'product',
      'tagId',
      'tagName',
      'tagColor',
      'clientName',
      'organizationId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Tag Edited',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'TagItemAdded' track call.
 *
 * @param TagItemAdded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function tagItemAdded(
  props: TagItemAdded,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Tag_Item_Added',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a new item is added to an existing tag.',
    properties: {
      addedFrom: {
        description:
          'Where was the item added to the tag from? Ex. Queue, tags',
        type: ['string'],
      },
      channel: {
        description:
          'The social network for which the item was created, ex. instagram.',
        type: ['string'],
      },
      channelId: {
        description: 'The ID of the channel for which the item was created.',
        type: ['string'],
      },
      channelType: {
        description:
          'The type of channel the item was created for, ex. group, page, business.',
        type: ['string'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        type: ['string'],
      },
      isDraft: {
        description: 'If the item added to the tag is a draft',
        type: ['boolean'],
      },
      isSent: { description: 'If is a sent post', type: ['boolean'] },
      itemId: {
        description: 'The id of the post or idea that was added to the tag.',
        type: ['string'],
      },
      itemType: {
        description:
          'The type of the item that was added to the tag, ex. post or idea.',
        pattern: 'post|idea',
        type: ['string'],
      },
      organizationId: {
        description: 'The global organization ID that the tag was created for.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occurred.',
        pattern: 'publish',
        type: ['string'],
      },
      publishOrganizationId: {
        description:
          'The ID of the Publish organization that the tag was created for.',
        type: ['string'],
      },
      tagId: { description: 'The ID of the tag.', type: ['string'] },
      tagName: { description: 'The name given to the tag.', type: ['string'] },
    },
    required: [
      'product',
      'itemType',
      'itemId',
      'tagId',
      'tagName',
      'clientName',
      'organizationId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Tag Item Added',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'TagItemRemoved' track call.
 *
 * @param TagItemRemoved props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function tagItemRemoved(
  props: TagItemRemoved,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Tag_Item_Removed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When an item is removed from an existing tag.',
    properties: {
      channel: {
        description:
          'The social network for which the item was created, ex. instagram.',
        type: ['string'],
      },
      channelId: {
        description: 'The ID of the channel for which the item was created.',
        type: ['string'],
      },
      channelType: {
        description:
          'The type of channel the item was created for, ex. group, page, business.',
        type: ['string'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        type: ['string'],
      },
      isDraft: {
        description: 'If the item removed from the tag is a draft',
        type: ['boolean'],
      },
      isSent: { description: 'If is a sent post', type: ['boolean'] },
      itemId: {
        description:
          'The id of the post or idea that was removed from the tag.',
        type: ['string'],
      },
      itemType: {
        description:
          'The type of the item that was removed from the tag, ex. post or idea.',
        pattern: 'post|idea',
        type: ['string'],
      },
      organizationId: {
        description: 'The global organization ID that the tag was created for.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occurred.',
        pattern: 'publish',
        type: ['string'],
      },
      publishOrganizationId: {
        description:
          'The ID of the Publish organization that the tag was created for.',
        type: ['string'],
      },
      removedFrom: {
        description: 'Where was the item removed from? Ex. Queue, tags',
        type: ['string'],
      },
      tagId: { description: 'The ID of the tag.', type: ['string'] },
      tagName: { description: 'The name given to the tag.', type: ['string'] },
    },
    required: [
      'product',
      'itemType',
      'itemId',
      'tagId',
      'tagName',
      'clientName',
      'organizationId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Tag Item Removed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'TagReportViewed' track call.
 *
 * @param TagReportViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function tagReportViewed(
  props: TagReportViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Tag_Report_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a report is viewed for an existing tag.',
    properties: {
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        type: ['string'],
      },
      organizationId: {
        description:
          'The ID of the global organization that the tag was created for.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occurred',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      publishOrganizationId: {
        description:
          'The ID of the Publish organization that the tag was created for.',
        type: ['string'],
      },
      tagId: { description: 'The ID of the tag.', type: ['string'] },
      tagName: { description: 'The name given to the tag.', type: ['string'] },
    },
    required: ['product', 'tagId', 'tagName', 'clientName', 'organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Tag Report Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'TaxExemptUpdated' track call.
 *
 * @param TaxExemptUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function taxExemptUpdated(
  props: TaxExemptUpdated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Tax_Exempt_Updated',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When was a tax exemption for a customer updated',
    properties: {
      customerId: {
        description: 'Unique identifier for the Stripe customer',
        type: ['string'],
      },
      organizationId: {
        description:
          'The ID of the organization for which the tax exempt was updated',
        type: ['string'],
      },
      previousTaxExempt: {
        description: 'The previous tax exempt status of the customer',
        pattern: 'none|exempt|reverse',
        type: ['string'],
      },
      stripeEventId: {
        description: 'Stripe event id from the webhook event',
        type: ['string'],
      },
      taxExempt: {
        description: 'The tax exempt status of the customer',
        pattern: 'none|exempt|reverse',
        type: ['string'],
      },
    },
    required: [
      'organizationId',
      'stripeEventId',
      'customerId',
      'previousTaxExempt',
      'taxExempt',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Tax Exempt Updated',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'TaxIDCreated' track call.
 *
 * @param TaxIDCreated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function taxIDCreated(
  props: TaxIDCreated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Tax_ID_Created',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When was a tax ID added for a customer',
    properties: {
      country: { description: 'The country of the tax ID', type: ['string'] },
      customerId: {
        description: 'Unique identifier for the Stripe customer',
        type: ['string'],
      },
      organizationId: {
        description:
          'The ID of the organization for which the tax ID was created',
        type: ['string'],
      },
      status: {
        description: 'The verification status of the tax ID',
        pattern: 'pending|verified|unverified|unavailable',
        type: ['string'],
      },
      stripeEventId: {
        description: 'Stripe event id from the webhook event',
        type: ['string'],
      },
      taxId: { description: 'The tax ID', type: ['string', 'null'] },
      taxType: { description: 'The type of tax ID', type: ['string'] },
    },
    required: [
      'organizationId',
      'stripeEventId',
      'customerId',
      'country',
      'taxType',
      'status',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Tax ID Created',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'TaxIDDeleted' track call.
 *
 * @param TaxIDDeleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function taxIDDeleted(
  props: TaxIDDeleted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Tax_ID_Deleted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When was a tax ID deleted for a customer',
    properties: {
      country: { description: 'The country of the tax ID', type: ['string'] },
      customerId: {
        description: 'Unique identifier for the Stripe customer',
        type: ['string'],
      },
      organizationId: {
        description:
          'The ID of the organization for which the tax ID was deleted',
        type: ['string'],
      },
      status: {
        description: 'The verification status of the tax ID',
        pattern: 'pending|verified|unverified|unavailable',
        type: ['string'],
      },
      stripeEventId: {
        description: 'Stripe event id from the webhook event',
        type: ['string'],
      },
      taxId: { description: 'The tax ID', type: ['string', 'null'] },
      taxType: { description: 'The type of tax ID', type: ['string'] },
    },
    required: [
      'organizationId',
      'stripeEventId',
      'customerId',
      'country',
      'taxType',
      'status',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Tax ID Deleted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'TaxIDUpdated' track call.
 *
 * @param TaxIDUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function taxIDUpdated(
  props: TaxIDUpdated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Tax_ID_Updated',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When was a tax ID updated for a customer',
    properties: {
      country: { description: 'The country of the tax ID', type: ['string'] },
      customerId: {
        description: 'Unique identifier for the Stripe customer',
        type: ['string'],
      },
      organizationId: {
        description:
          'The ID of the organization for which the tax ID was updated',
        type: ['string'],
      },
      status: {
        description: 'The verification status of the tax ID',
        pattern: 'pending|verified|unverified|unavailable',
        type: ['string'],
      },
      stripeEventId: {
        description: 'Stripe event id from the webhook event',
        type: ['string'],
      },
      taxId: { description: 'The tax ID', type: ['string', 'null'] },
      taxType: { description: 'The type of tax ID', type: ['string'] },
    },
    required: [
      'organizationId',
      'stripeEventId',
      'customerId',
      'country',
      'taxType',
      'status',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Tax ID Updated',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'TeamMemberAdded' track call.
 *
 * @param TeamMemberAdded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function teamMemberAdded(
  props: TeamMemberAdded,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Team_Member_Added',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When was the team member added to the organization?',
    properties: {
      organizationId: {
        description:
          'The id of the Global organization that the team member was added to.',
        type: ['string'],
      },
      organizationOwnerPlanId: {
        description:
          "Which plan was the organization's owner on at time of adding the team member?",
        type: ['string'],
      },
      organizationOwnerUserId: {
        description:
          "What was the Buffer user id of the organization's owner for the organization this team member was added to?",
        type: ['string', 'null'],
      },
      organizationPlanId: {
        description: 'Which plan was the organization subscribed to?',
        type: ['string', 'null'],
      },
      product: {
        description:
          'Which product was the team member added to for the organization?',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create|core',
        type: ['string'],
      },
      teamMemberEmail: {
        description:
          'What is the email address of the team member added to the organization?',
        type: ['string'],
      },
      teamMemberPlanId: {
        description:
          'Which plan was the team member on at time of being added to the organization?',
        type: ['string', 'null'],
      },
      teamMemberRole: {
        description:
          'Which organization role was the team member given at the time of being added?',
        type: ['string'],
      },
      teamMemberUserId: {
        description:
          'What was the Buffer user id of the team member added to the organization?',
        type: ['string'],
      },
    },
    required: ['organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Team Member Added',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'TeamMemberInviteLinkClicked' track call.
 *
 * @param TeamMemberInviteLinkClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function teamMemberInviteLinkClicked(
  props: TeamMemberInviteLinkClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Team_Member_Invite_Link_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'This event is triggered when the user clicks the link in the invite email.',
    properties: {
      organizationId: {
        description:
          'The id of the Global organization that the team member was invited to.',
        type: ['string'],
      },
      organizationOwnerPlanId: {
        description:
          "Which plan was the organization's owner on at time the invite link was clicked?",
        type: ['string'],
      },
      organizationOwnerUserId: {
        description:
          "What was the Buffer user id of the organization's owner for the organization this team member was invited to?",
        type: ['string', 'null'],
      },
      organizationPlanId: {
        description:
          'Which plan was the organization subscribed to when the team member was invited?',
        type: ['string', 'null'],
      },
      product: {
        description: 'Which product was the team member invited to?',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create|core',
        type: ['string'],
      },
      teamMemberEmail: {
        description:
          'What is the email address of the team member added to the organization?',
        type: ['string'],
      },
      teamMemberPlanId: {
        description:
          'Which plan was the team member on at time of clicking the invite link?',
        type: ['string', 'null'],
      },
      teamMemberRole: {
        description:
          'Which organization role was the team member given when invited?',
        type: ['string'],
      },
      teamMemberUserId: {
        description:
          'What was the Buffer user id of the team member that was invited?',
        type: ['string'],
      },
    },
    required: ['organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Team Member Invite Link Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'TeamMemberInvited' track call.
 *
 * @param TeamMemberInvited props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function teamMemberInvited(
  props: TeamMemberInvited,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Team_Member_Invited',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When was the team member invited to join the organization?',
    properties: {
      hasChannels: {
        description:
          'Does the user being invited already have channels connected?',
        type: ['boolean'],
      },
      isNewUser: {
        description:
          "A user is new if there isn't already an account associated with the email.",
        type: ['boolean'],
      },
      isUserAdmin: {
        description:
          'Is the user being invited to be an admin of the organization?',
        type: ['boolean'],
      },
      newUserPasswordResetLink: {
        description: 'The link a new user uses to set their Buffer password',
        type: ['string'],
      },
      organizationId: {
        description:
          'The id of the Global organization that the team member was added to.',
        type: ['string'],
      },
      organizationName: {
        description:
          'The name of the organization that the user is being invited to',
        type: ['string'],
      },
      organizationOwnerEmail: {
        description: 'The email address of the organization owner.',
        type: ['string'],
      },
      organizationPlanId: {
        description:
          'Which plan was the organization on at time of inviting the team member?',
        type: ['string'],
      },
      product: {
        description:
          'Which product was the team member added to for the organization?',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      teamMemberAccountId: {
        description:
          'What was the Buffer global account id of the team member added to the organization?',
        type: ['string'],
      },
      teamMemberEmail: {
        description:
          'What is the email address of the team member invited to the organization?',
        type: ['string'],
      },
      teamMemberPlanId: {
        description:
          'Which plan was the team member on at time of being invited to the organization?',
        type: ['string', 'null'],
      },
    },
    required: ['organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Team Member Invited',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'TeamMemberRemoved' track call.
 *
 * @param TeamMemberRemoved props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function teamMemberRemoved(
  props: TeamMemberRemoved,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Team_Member_Removed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When was the team member removed from the organization?',
    properties: {
      organizationId: {
        description:
          'The id of the Global organization that the team member was removed from.',
        type: ['string'],
      },
      organizationOwnerPlanId: {
        description:
          "Which plan was the organization's owner on at time of removing the team member?",
        type: ['string'],
      },
      organizationOwnerUserId: {
        description:
          "What was the Buffer user id of the organization's owner for the organization this team member was removed from?",
        type: ['string', 'null'],
      },
      organizationPlanId: {
        description:
          'Which plan was the organization subscribed to at the time the event was triggered?',
        type: ['string', 'null'],
      },
      product: {
        description:
          'Which product was the team member removed from for the organization?',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      teamMemberEmail: {
        description:
          'What is the email address of the team member removed from the organization?',
        type: ['string'],
      },
      teamMemberPlanId: {
        description:
          'Which plan was the team member on at time of being removed from the organization?',
        type: ['string', 'null'],
      },
      teamMemberRole: {
        description:
          'Which organization role was the team member given at the time of being added?',
        type: ['string'],
      },
      teamMemberUserId: {
        description:
          'What was the Buffer user id of the team member removed from the organization?',
        type: ['string'],
      },
    },
    required: ['properties'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Team Member Removed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'TemplateImageSelected' track call.
 *
 * @param TemplateImageSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function templateImageSelected(
  props: TemplateImageSelected,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Template_Image_Selected',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user select the image used within a template?',
    properties: {
      clientName: {
        description: 'The client in which the action occurred.',
        type: ['string'],
      },
      imageSource: {
        description: 'The source in which the image was selected.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred, ex. “remix”.',
        pattern: 'remix',
        type: ['string'],
      },
    },
    required: ['product', 'clientName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Template Image Selected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'TestEventDoNotUse' track call.
 *
 * @param TestEventDoNotUse props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function testEventDoNotUse(
  props: TestEventDoNotUse,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Test_Event_Do_Not_Use',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'This event is only for use while migrating the tracking plan - it acts as a place to test gh workflows based on event changes without needing to modify any active events.',
    properties: {
      anotherId: { description: 'A test id', type: ['number'] },
      anotherId2: { description: 'A test id', type: ['number'] },
      anotherId3: {
        description: 'A test id with an updated description',
        type: ['number'],
      },
      anotherId5: { description: 'A test id', type: ['number'] },
      anotherId6: { description: 'A test id', type: ['number'] },
      anotherId7: { description: 'A test id', type: ['number'] },
      anotherId8: { description: 'A test id', type: ['number'] },
      anotherId9: {
        description: 'A test id with an updated description',
        type: ['number'],
      },
      organizationId: {
        description:
          'The organization ID of the user that completed the action',
        type: ['string'],
      },
      someId: { description: 'A test id', type: ['string'] },
    },
    required: [
      'organizationId',
      'anotherId3',
      'anotherId5',
      'anotherId6',
      'anotherId7',
      'anotherId8',
      'anotherId9',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Test Event Do Not Use',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'TestEventNumberTwoDoNotUse' track call.
 *
 * @param TestEventNumberTwoDoNotUse props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function testEventNumberTwoDoNotUse(
  props: TestEventNumberTwoDoNotUse,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Test_Event_Number_Two_Do_Not_Use',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'This event is only for use while migrating the tracking plan - it acts as a place to test gh workflows based on event changes without needing to modify any active events.',
    properties: {
      anotherField: { description: 'Another test field', type: ['string'] },
      anotherField2: { description: 'Another test field', type: ['string'] },
      anotherField4: { description: 'Another test field', type: ['string'] },
      anotherField5: { description: 'Another test field', type: ['string'] },
      anotherField6: { description: 'Another test field', type: ['string'] },
      anotherField7: { description: 'Another test field', type: ['string'] },
      anotherField8: {
        description: 'Another test field that is now required',
        type: ['string'],
      },
      anotherField9: {
        description: 'Another test field that is now required',
        type: ['string'],
      },
      organizationId: {
        description:
          'The organization ID of the user that completed the action',
        type: ['string'],
      },
      organizationId2: {
        description:
          'The organization ID of the user that completed the action',
        type: ['string'],
      },
    },
    required: [
      'organizationId',
      'organizationId2',
      'anotherField',
      'anotherField2',
      'anotherField4',
      'anotherField5',
      'anotherField8',
      'anotherField9',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Test Event Number Two Do Not Use',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ThreadsAddThreadClicked' track call.
 *
 * @param ThreadsAddThreadClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function threadsAddThreadClicked(
  props: ThreadsAddThreadClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Threads_Add_Thread_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: "When a user clicks 'Add Thread' to start a new Thread post",
    properties: {
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern: 'publish|buffer|analyze|engage',
        type: ['string'],
      },
    },
    required: ['organizationId', 'clientName', 'product'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Threads Add Thread Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ThreadsAddTweetClicked' track call.
 *
 * @param ThreadsAddTweetClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function threadsAddTweetClicked(
  props: ThreadsAddTweetClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Threads_Add_Tweet_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      "When a user clicks 'Add Tweet' to add more Tweets to the Thread",
    properties: {
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern: 'publish|buffer|analyze|engage',
        type: ['string'],
      },
    },
    required: ['organizationId', 'clientName', 'product'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Threads Add Tweet Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ThreadsEditAttempted' track call.
 *
 * @param ThreadsEditAttempted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function threadsEditAttempted(
  props: ThreadsEditAttempted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Threads_Edit_Attempted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user attempts to edit a thread on mobile',
    properties: {
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishIos|publishAndroid',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern: 'publish|buffer|analyze|engage',
        type: ['string'],
      },
    },
    required: ['organizationId', 'clientName', 'product'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Threads Edit Attempted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'TieredPricingDisabled' track call.
 *
 * @param TieredPricingDisabled props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function tieredPricingDisabled(
  props: TieredPricingDisabled,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Tiered_Pricing_Disabled',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When was tiered pricing disabled for the user?',
    properties: {
      organizationId: {
        description: 'The global organization ID of the user.',
        type: ['string', 'null'],
      },
    },
    required: ['organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Tiered Pricing Disabled',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'TieredPricingEnabled' track call.
 *
 * @param TieredPricingEnabled props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function tieredPricingEnabled(
  props: TieredPricingEnabled,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Tiered_Pricing_Enabled',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When was tiered pricing enabled for the user?',
    properties: {
      organizationId: {
        description: 'The global organization ID of the user.',
        type: ['string', 'null'],
      },
    },
    required: ['organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Tiered Pricing Enabled',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'TourExited' track call.
 *
 * @param TourExited props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function tourExited(
  props: TourExited,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Tour_Exited',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user exit the app tour?',
    properties: {
      clientName: {
        description:
          'What was the name of the client the user was in when they opened the Story in the native channel?',
        type: ['string'],
      },
      organizationId: {
        description: 'The global organization id that the channel belongs to.',
        type: ['string'],
      },
      product: {
        description: 'In which product did this event occur?',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: ['product', 'clientName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Tour Exited',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'TourOpened' track call.
 *
 * @param TourOpened props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function tourOpened(
  props: TourOpened,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Tour_Opened',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user open the app tour?',
    properties: {
      clientName: {
        description:
          'What was the name of the client the user was in when they opened the Story in the native channel?',
        type: ['string'],
      },
      organizationId: {
        description: 'The global organization id that the channel belongs to.',
        type: ['string'],
      },
      placement: {
        description: 'Where did the user open the tour from?',
        pattern: 'before_auth|channel_connection|new_signup|empty_organization',
        type: ['string'],
      },
      product: {
        description: 'In which product did this event occur?',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: ['product', 'clientName', 'placement'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Tour Opened',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'TourScreenViewed' track call.
 *
 * @param TourScreenViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function tourScreenViewed(
  props: TourScreenViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Tour_Screen_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When did the user view this specific screen? This event is triggered when a user opens a new screen in the app tour that is considered a distinct screen.',
    properties: {
      clientName: {
        description:
          'What was the name of the client the user was in when they opened the Story in the native channel?',
        type: ['string'],
      },
      organizationId: {
        description: 'The global organization id that the channel belongs to.',
        type: ['string'],
      },
      product: {
        description: 'In which product did this event occur?',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      screenName: {
        description: 'What was the name of the screen that was viewed?',
        pattern:
          'composer|calendar|campaigns|overview|queue|collaboration|analytics|settings',
        type: ['string'],
      },
    },
    required: ['product', 'clientName', 'screenName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Tour Screen Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'TrialChannelQuantityChanged' track call.
 *
 * @param TrialChannelQuantityChanged props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function trialChannelQuantityChanged(
  props: TrialChannelQuantityChanged,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Trial_Channel_Quantity_Changed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When the number of channels associated with the trial changes. This event will trigger each time a user adds or removes a channel during a trial, but it is possible for multiple channel adds to be represented by one event as a result of a bulk add UI option.',
    properties: {
      changeValue: {
        description:
          'The absolute dollar amount per month that this channel change is worth. If the cost per channel per month is $10, and the user removed a channel, this value would be `10`. If the channel count went from 1 to 4 (as a result of a bulk add) and the plan being trialed is for $5 per channel, this value would be `15`.',
        type: ['number'],
      },
      channelQuantity: {
        description:
          'Count of channels associated with this trial after this change was made.',
        type: ['number'],
      },
      customerId: {
        description: 'Unique identifier for the billing gateway customer.',
        type: ['string'],
      },
      gateway: {
        description: 'What triggered this event? e.g. Stripe or RevenueCat',
        type: ['string'],
      },
      organizationId: {
        description: 'The ID of the organization whose plan changed.',
        type: ['string'],
      },
      planId: { description: 'Billing gateway plan id.', type: ['string'] },
      planName: { description: 'Human-readable plan name.', type: ['string'] },
      previousChannelQuantity: {
        description:
          'Count of channels associated with this trial before this change was made.',
        type: ['number'],
      },
      product: {
        description: 'The product which the trial is for.',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      revenueEffect: {
        description:
          'The type of expected impact to Buffer MRR as a result of this channel quantity change, should this trial eventually convert to a paid subscription.',
        pattern: 'increase|decrease|none',
        type: ['string'],
      },
      stripeEventId: {
        description: 'Stripe event id from the webhook event.',
        type: ['string'],
      },
      subscriptionId: {
        description: 'Unique identifier for the trial.',
        type: ['string'],
      },
    },
    required: ['organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Trial Channel Quantity Changed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'TrialEnded' track call.
 *
 * @param TrialEnded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function trialEnded(
  props: TrialEnded,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Trial_Ended',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      "When did the user's trial end for this product and plan?  This event is triggered when the trial end timestamp is reached; it is not user initiated.",
    properties: {
      amount: { description: 'Cost of the plan after trial', type: ['number'] },
      cancelled: { description: 'true || false', type: ['boolean'] },
      clientName: {
        description: 'The client in which the event occurred.',
        pattern:
          'publishWeb|publishIos|publishAndroid|core|bufferWeb|ios|android',
        type: ['string', 'null'],
      },
      customerId: {
        description: 'Unique identifier for the Stripe customer',
        type: ['string'],
      },
      cycle: {
        description: 'The subscription payment interval (month or year)',
        type: ['string'],
      },
      eventTrigger: {
        description:
          'What caused this event to be triggered?  Examples of values that might be populated in this property are `userCanceled`, `bufferCanceled`, `completedDuration`, `convertedEarly`, `startedNewTrial`, `userDeletedAccount`, `bufferDeletedAccount`, and `systemBackfill`',
        type: ['string', 'null'],
      },
      gateway: {
        description: 'What triggered this event? e.g. Stripe or RevenueCat',
        type: ['string'],
      },
      multiProductBundleName: {
        description:
          "What was the name of the multi product bundle that this trial is associated with? For example, 'analyze_publish_oct2019'",
        type: ['string', 'null'],
      },
      organizationId: {
        description: 'The ID of the organization whose trial ended.',
        type: ['string'],
      },
      planId: { description: 'Stripe plan id', type: ['string'] },
      planName: { description: 'Human-readable plan name', type: ['string'] },
      product: {
        description: 'The product which the trial is for',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      stripeEventId: {
        description: 'Stripe event id from the webhook event',
        type: ['string'],
      },
      subscriptionId: {
        description: 'Unique identifier for trial/subscription (Stripe sub id)',
        type: ['string'],
      },
      trialEndDate: {
        description: 'End date of the trial (ISO-8601)',
        type: ['string'],
      },
      trialStartDate: {
        description: 'Start date of the trial (ISO-8601)',
        type: ['string'],
      },
    },
    required: ['product'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Trial Ended',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'TrialExpiredModalMainCtaClicked' track call.
 *
 * @param TrialExpiredModalMainCtaClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function trialExpiredModalMainCtaClicked(
  props: TrialExpiredModalMainCtaClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Trial_Expired_Modal_Main_Cta_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user click the Trial Expired Modal main cta?',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      clientName: {
        description:
          'Which client was the user in when they opened this modal?',
        type: ['string', 'null'],
      },
      cta: {
        description: 'The name of the CTA clicked.',
        type: ['string', 'null'],
      },
      isAtPlanLimit: {
        description: 'Is the user at their plan limit?',
        type: ['boolean', 'null'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that clicked the Trial Expired Modal main cta.',
        type: ['string', 'null'],
      },
      product: {
        description: 'Which product did the user click the main cta in?',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string', 'null'],
      },
      upgradePathName: {
        description: 'The name of the upgrade path',
        type: ['string', 'null'],
      },
    },
    required: [
      'organizationId',
      'product',
      'clientName',
      'cta',
      'upgradePathName',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Trial Expired Modal Main Cta Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'TrialExpiredModalViewed' track call.
 *
 * @param TrialExpiredModalViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function trialExpiredModalViewed(
  props: TrialExpiredModalViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Trial_Expired_Modal_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user view the Trial Expired modal?',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      clientName: {
        description:
          'Which client was the user in when they opened this modal?',
        type: ['string', 'null'],
      },
      cta: {
        description: 'The name of the CTA clicked.',
        type: ['string', 'null'],
      },
      isAtPlanLimit: {
        description: 'Is the user at their plan limit?',
        type: ['boolean', 'null'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the Trial Expired modal.',
        type: ['string', 'null'],
      },
      product: {
        description: 'Which product did the user view the modal in?',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string', 'null'],
      },
      upgradePathName: {
        description: 'The name of the upgrade path',
        type: ['string', 'null'],
      },
    },
    required: [
      'organizationId',
      'product',
      'clientName',
      'cta',
      'upgradePathName',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Trial Expired Modal Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'TrialExtended' track call.
 *
 * @param TrialExtended props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function trialExtended(
  props: TrialExtended,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Trial_Extended',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When was this trial extended beyond the default trial duration?  This event is typically triggered a Buffer customer advocate giving additional trial duration to a user; it is not user initiated.',
    properties: {
      amount: { description: 'Cost of the plan after trial', type: ['number'] },
      customerId: {
        description: 'Unique identifier for the Stripe customer',
        type: ['string'],
      },
      cycle: {
        description: 'The subscription payment interval (month or year)',
        type: ['string'],
      },
      extendedTrialEndDate: {
        description: 'New end date of the trial (ISO-8601)',
        type: ['string'],
      },
      gateway: {
        description: 'What triggered this event? e.g. Stripe or RevenueCat',
        type: ['string'],
      },
      organizationId: {
        description: 'The ID of the organization whose trial ended.',
        type: ['string'],
      },
      planId: { description: 'Stripe plan id', type: ['string'] },
      planName: { description: 'Human-readable plan name', type: ['string'] },
      product: {
        description: 'The product which the trial is for',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      stripeEventId: {
        description: 'Stripe event id from the webhook event',
        type: ['string'],
      },
      subscriptionId: {
        description: 'Unique identifier for trial/subscription (Stripe sub id)',
        type: ['string'],
      },
    },
    required: ['organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Trial Extended',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'TrialPlanChanged' track call.
 *
 * @param TrialPlanChanged props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function trialPlanChanged(
  props: TrialPlanChanged,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Trial_Plan_Changed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When the plan associated with the trial changes. This will trigger when a trial is already in progress for the user but has not yet reached the trial duration end, and a trial for a different Buffer plan is initiated.',
    properties: {
      cta: {
        description:
          'Which call to action did the user click to start their trial?',
        type: ['string', 'null'],
      },
      ctaApp: {
        description:
          'Which product or website is the CTA located in? Examples would be either the product, like `publish`, or the website, like `bufferSite`',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA?  In many cases it makes sense to describe the intended result of the CTA, like `proTrial` or `publishPro`',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in Publish app shell menu',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA?  If setting the value for the first time, set to `1`',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'What website page or app view is the CTA located?  Examples would be, `composer` or `analyticsOverview` for Publish, and `pricingPublish` for the Publish pricing page on the Buffer marketing site',
        type: ['string', 'null'],
      },
      customerId: {
        description: 'Unique identifier for the billing gateway customer',
        type: ['string'],
      },
      cycle: {
        description:
          'The billing interval (month or year) of the plan being trialed',
        type: ['string'],
      },
      gateway: {
        description: 'What triggered this event? e.g. Stripe or RevenueCat',
        type: ['string'],
      },
      organizationId: {
        description: 'The ID of the organization whose plan changed.',
        type: ['string'],
      },
      planId: { description: 'Billing gateway plan id', type: ['string'] },
      planName: { description: 'Human-readable plan name', type: ['string'] },
      previousCycle: {
        description:
          'The billing interval (month or year) of the previous plan',
        type: ['string'],
      },
      previousPlanId: {
        description: 'Billing gateway plan id of the previous plan, if exists',
        type: ['string'],
      },
      previousPlanName: {
        description: 'Human-readable plan name of the previous plan, if exists',
        type: ['string'],
      },
      product: {
        description: 'The product which the trial is for',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      stripeEventId: {
        description: 'Stripe event id from the webhook event',
        type: ['string'],
      },
      subscriptionId: {
        description: 'Unique identifier for the trial',
        type: ['string'],
      },
    },
    required: ['organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Trial Plan Changed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'TrialSignupWelcomeModalViewed' track call.
 *
 * @param TrialSignupWelcomeModalViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function trialSignupWelcomeModalViewed(
  props: TrialSignupWelcomeModalViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Trial_Signup_Welcome_Modal_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user view the Trial Signup Welcome modal?',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      clientName: {
        description:
          'Which client was the user in when they opened this modal?',
        pattern: 'publishWeb|publishIos|publishAndroid|core|bufferWeb',
        type: ['string'],
      },
      isAtPlanLimit: {
        description: 'Is the user at their plan limit?',
        type: ['boolean', 'null'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the Trial Signup Welcome modal.',
        type: ['string', 'null'],
      },
    },
    required: ['organizationId', 'clientName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Trial Signup Welcome Modal Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'TrialStarted' track call.
 *
 * @param TrialStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function trialStarted(
  props: TrialStarted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Trial_Started',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When did the user start the trial for this product and specific plan?  This event is triggered when the user completes the trial start flow, or if when they complete a signup flow that has a trial included.',
    properties: {
      amount: { description: 'Cost of the plan after trial', type: ['number'] },
      clientName: {
        description: 'The client in which the event occurred.',
        pattern:
          'publishWeb|publishIos|publishAndroid|core|bufferWeb|ios|android',
        type: ['string', 'null'],
      },
      cta: {
        description:
          'Which call to action did the user click to start their trial',
        type: ['string', 'null'],
      },
      ctaApp: {
        description:
          'Which product or website is the CTA located in? Examples would be either the product, like `publish`, or the website, like `bufferSite`',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA?  In many cases it makes sense to describe the intended result of the CTA, like `proTrial` or `publishPro`',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in Publish app shell menu',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA?  If setting the value for the first time, set to `1`',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'What website page or app view is the CTA located?  Examples would be, `composer` or `analyticsOverview` for Publish, and `pricingPublish` for the Publish pricing page on the Buffer marketing site',
        type: ['string', 'null'],
      },
      customerId: {
        description: 'Unique identifier for the Stripe customer',
        type: ['string'],
      },
      cycle: {
        description: 'The subscription payment interval (month or year)',
        type: ['string'],
      },
      eventTrigger: {
        description:
          'What caused this event to be triggered?  If this event was triggered via normal user behavior, the property value will be null.  Examples of values that might be populated in this property are `system_migration` and `bug_fix_backfill`',
        type: ['string', 'null'],
      },
      gateway: {
        description: 'What triggered this event? e.g. Stripe or RevenueCat',
        type: ['string'],
      },
      multiProductBundleName: {
        description:
          "What was the name of the multi product bundle that this trial is associated with? For example, 'analyze_publish_oct2019'",
        type: ['string', 'null'],
      },
      organizationId: {
        description: 'The ID of the organization whose trial started.',
        type: ['string'],
      },
      planId: { description: 'Stripe plan id', type: ['string'] },
      planName: { description: 'Human-readable plan name', type: ['string'] },
      product: {
        description: 'The product which the trial is for',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      revenue: {
        description:
          'MRR Value (if monthly then amount, if yearly, divide yearly amount by 12)',
        type: ['number'],
      },
      stripeEventId: {
        description: 'Stripe event id from the webhook event',
        type: ['string'],
      },
      subscriptionId: {
        description: 'Unique identifier for trial/subscription (Stripe sub id)',
        type: ['string'],
      },
      trialAtSignup: {
        description: 'Was the trial started during the product signup?',
        type: ['boolean'],
      },
      trialEndDate: {
        description: 'End date of the trial (ISO-8601)',
        type: ['string'],
      },
      trialStartDate: {
        description: 'Start date of the trial (ISO-8601)',
        type: ['string'],
      },
      upgradePathName: {
        description: 'The name of the upgrade path',
        type: ['string', 'null'],
      },
      utmCampaign: {
        description:
          'What was the specific campaign (e.g. product launch, new feature, partnership, etc.) that resulted in a trial start?',
        type: ['string', 'null'],
      },
      utmContent: {
        description:
          'What was the specific content or content that the trial start is attributed to. For example a specific CTA or part of an email that is being A/B tested.',
        type: ['string', 'null'],
      },
      utmMedium: {
        description:
          'What is the medium of the link that was used to start a trial? For example email, CPC, or other method of sharing.',
        type: ['string', 'null'],
      },
      utmSource: {
        description:
          'What is the source of traffic that resulted in a trial start? For example search engine, newsletter, or other referral.',
        type: ['string', 'null'],
      },
      utmTerm: {
        description:
          'What is the paid search term or keyword used to drive the trial start? For example DTC, Instagram scheduling.',
        type: ['string', 'null'],
      },
    },
    required: ['product'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Trial Started',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'TrialWelcomeModalViewed' track call.
 *
 * @param TrialWelcomeModalViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function trialWelcomeModalViewed(
  props: TrialWelcomeModalViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Trial_Welcome_Modal_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user view the Trial Welcome modal?',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      clientName: {
        description:
          'Which client was the user in when they opened this modal?',
        type: ['string', 'null'],
      },
      cta: {
        description: 'The name of the CTA clicked.',
        type: ['string', 'null'],
      },
      isAtPlanLimit: {
        description: 'Is the user at their plan limit?',
        type: ['boolean', 'null'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the Trial Welcome modal.',
        type: ['string', 'null'],
      },
      product: {
        description: 'Which product did the user view the modal in?',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string', 'null'],
      },
      upgradePathName: {
        description: 'The name of the upgrade path',
        type: ['string', 'null'],
      },
    },
    required: ['organizationId', 'product', 'clientName', 'upgradePathName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Trial Welcome Modal Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'TwitterAuthorCreditInserted' track call.
 *
 * @param TwitterAuthorCreditInserted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function twitterAuthorCreditInserted(
  props: TwitterAuthorCreditInserted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Twitter_Author_Credit_Inserted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When did the user tap a button to insert a links Twitter author into the update text.',
    properties: {
      clientId: {
        description:
          'What was the unique identifier of the client the user was in when they updated the story?',
        type: ['string', 'null'],
      },
      clientName: {
        description:
          'Which client was the user in when they copied the story note? IE, `publishIos`, `publishWeb`, `publishAndroid`.',
        type: ['string'],
      },
      organizationId: {
        description:
          'The organization ID of the user that copied the story note.',
        type: ['string'],
      },
      product: {
        description: 'The product for which the story was created',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
    },
    required: ['product', 'clientName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Twitter Author Credit Inserted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'TwoFactorAuthenticationBackupUpdated' track call.
 *
 * @param TwoFactorAuthenticationBackupUpdated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function twoFactorAuthenticationBackupUpdated(
  props: TwoFactorAuthenticationBackupUpdated,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Two_Factor_Authentication_Backup_Updated',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user updates their two factor authentication backup.',
    properties: {
      clientName: {
        description: 'The name of the client that the event occurred in',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      method: {
        description: 'The type of two factor authentication that was enabled',
        pattern: 'sms|app|email',
        type: ['string'],
      },
      organizationId: {
        description:
          'The organization ID of the user that completed the action',
        type: ['string'],
      },
      product: {
        description:
          'Which product did the user complete this action in? (e.g. core)',
        type: ['string'],
      },
    },
    required: ['product', 'organizationId', 'clientName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Two Factor Authentication Backup Updated',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'TwoFactorAuthenticationDisabled' track call.
 *
 * @param TwoFactorAuthenticationDisabled props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function twoFactorAuthenticationDisabled(
  props: TwoFactorAuthenticationDisabled,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Two_Factor_Authentication_Disabled',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user disables two factor authentication',
    properties: {
      clientName: {
        description: 'The name of the client that the event occurred in',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      organizationId: {
        description:
          'The organization ID of the user that completed the action',
        type: ['string'],
      },
      product: {
        description:
          'Which product did the user complete this action in? (e.g. core)',
        type: ['string'],
      },
    },
    required: ['product', 'organizationId', 'clientName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Two Factor Authentication Disabled',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'TwoFactorAuthenticationEnabled' track call.
 *
 * @param TwoFactorAuthenticationEnabled props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function twoFactorAuthenticationEnabled(
  props: TwoFactorAuthenticationEnabled,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Two_Factor_Authentication_Enabled',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user enabled two factor authentication',
    properties: {
      clientName: {
        description: 'The name of the client that the event occurred in',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      method: {
        description: 'The type of two factor authentication that was enabled',
        pattern: 'sms|app|email',
        type: ['string'],
      },
      organizationId: {
        description:
          'The organization ID of the user that completed the action',
        type: ['string'],
      },
      product: {
        description:
          'Which product did the user complete this action in? (e.g. core)',
        type: ['string'],
      },
    },
    required: ['product', 'organizationId', 'clientName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Two Factor Authentication Enabled',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'URLPasted' track call.
 *
 * @param URLPasted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function uRLPasted(
  props: URLPasted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'URL_Pasted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user open the image editor in the remix app?',
    properties: {
      clientName: {
        description: 'The client in which the action occurred.',
        type: ['string'],
      },
      hasRatings: {
        description: 'If the url scraped contains ratings',
        type: ['boolean'],
      },
      product: {
        description: 'The product in which the event occurred, ex. “remix”.',
        pattern: 'remix',
        type: ['string'],
      },
      text: {
        description:
          'What was the text contained in the URL that is being shared?',
        type: ['string'],
      },
      url: {
        description: 'What was the url that was pasted?',
        type: ['string'],
      },
      urlType: {
        description:
          'What url type was pasted? ex. "product", "link" or "tweet" etc...',
        type: ['string'],
      },
    },
    required: ['product', 'clientName', 'url', 'urlType'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'URL Pasted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'UTMGeneratorCopyButtonClicked' track call.
 *
 * @param UTMGeneratorCopyButtonClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function uTMGeneratorCopyButtonClicked(
  props: UTMGeneratorCopyButtonClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'UTM_Generator_Copy_Button_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When a visitor clicks on the copy button within the UTM Generator.',
    properties: {
      utmCampaign: {
        description:
          'What was the specific campaign (e.g. product launch, new feature, partnership, etc.) that resulted in this video play?',
        type: ['string', 'null'],
      },
      utmContent: {
        description:
          'What was the specific content or content that this video play is attributed to. For example a specific CTA or part of an email that is being A/B tested.',
        type: ['string', 'null'],
      },
      utmMedium: {
        description:
          'What is the medium of the link that lead to this video play? For example email, CPC, or other method of sharing.',
        type: ['string', 'null'],
      },
      utmSource: {
        description:
          'What is the source of traffic that resulted in this video play? For example search engine, newsletter, or other referral.',
        type: ['string', 'null'],
      },
      utmTerm: {
        description:
          'What is the paid search term or keyword used to drive this video play? For example DTC, Instagram scheduling.',
        type: ['string', 'null'],
      },
    },
    required: ['utmSource'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'UTM Generator Copy Button Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'UnsupportedChannelSelectionAttempt' track call.
 *
 * @param UnsupportedChannelSelectionAttempt props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function unsupportedChannelSelectionAttempt(
  props: UnsupportedChannelSelectionAttempt,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Unsupported_Channel_Selection_Attempt',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When an unsupported channel type combination is selected in the composer.',
    properties: {
      channel: {
        description:
          'The channel service that triggered the unsupported selection event',
        pattern: 'twitter|facebook|instagram',
        type: ['string'],
      },
      channelIds: {
        description:
          'The ids of the selected channels for the network that has triggered the unsupported event, including the channel that was attempted for selection',
        type: ['array'],
      },
      channelTypes: {
        description:
          'The channel types of the selected channels for the network that has triggered the unsupported event, including the channel that was attempted for selection',
        type: ['array'],
      },
      clientName: {
        description: 'Which client was the user in when they took this action?',
        pattern: 'publishWeb|publishIos|publishAndroid',
        type: ['string'],
      },
      organizationId: {
        description:
          'The global organization id of the user that took this action',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred.',
        pattern: 'publish',
        type: ['string'],
      },
    },
    required: [
      'product',
      'organizationId',
      'channelIds',
      'clientName',
      'channelTypes',
      'channel',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Unsupported Channel Selection Attempt',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'UpgradePathViewed' track call.
 *
 * @param UpgradePathViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function upgradePathViewed(
  props: UpgradePathViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Upgrade_Path_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When a user clicks on a prompt to upgrade that leads to a plan selector.',
    properties: {
      atEventBillingCycle: {
        description:
          "At time of event, what was the user's billing cycle, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingGateway: {
        description:
          "At time of event, what was the user's billing gateway, if applicable?",
        type: ['string', 'null'],
      },
      atEventBillingPlan: {
        description: "At time of event, what was the user's plan?",
        type: ['string', 'null'],
      },
      atEventBillingState: {
        description:
          "At time of event, what was the user's billing status (e.g. free/trial/paid)?",
        pattern: 'free|trial|paid',
        type: ['string', 'null'],
      },
      atEventChannelQty: {
        description:
          'At time of event, how many channels is the user paying for?',
        type: ['number', 'null'],
      },
      atEventIsNewBuffer: {
        description: 'At time of event, was the user on New Buffer?',
        type: ['boolean', 'null'],
      },
      clientName: {
        description:
          'Which client was the user in when the Post was initially created?',
        type: ['string'],
      },
      organizationId: {
        description:
          'The organization ID of the user that viewed the upgrade path.',
        type: ['string'],
      },
      product: {
        description: 'Which product did the user view the upgrade path in?',
        pattern:
          'publish|analyze|engage|start_page|account|marketing|buffer|create',
        type: ['string'],
      },
      upgradePathName: {
        description: 'The name of the upgrade path',
        type: ['string'],
      },
    },
    required: ['product', 'upgradePathName', 'organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Upgrade Path Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'UpgradePlanOptionsScreenViewed' track call.
 *
 * @param UpgradePlanOptionsScreenViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function upgradePlanOptionsScreenViewed(
  props: UpgradePlanOptionsScreenViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Upgrade_Plan_Options_Screen_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When the user is presented with our plan upgrade view within the mobile apps.',
    properties: {
      channel: {
        description: 'The channel\'s service, ex. "facebook".',
        type: ['string'],
      },
      channelId: {
        description:
          'The database id of the channel for which the story was created.',
        type: ['string'],
      },
      channelServiceId: {
        description: "The service's own id for this channel.",
        type: ['string'],
      },
      channelType: {
        description: 'The type of channel ex. "page", "group".',
        type: ['string'],
      },
      clientName: {
        description:
          'The mobile client the user is on, `publishIos` or `publishAndroid`.',
        type: ['string'],
      },
      organizationId: {
        description:
          'The organization ID of the user that viewed the plan options.',
        type: ['string'],
      },
      plan: {
        description: 'The plan we proposed within the upgrade view.',
        type: ['string'],
      },
      prompt: {
        description:
          'The action the user took to produce the upgrade view, such as "profile_limit".',
        type: ['string', 'null'],
      },
    },
    required: ['clientName', 'plan'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Upgrade Plan Options Screen Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'UploadAttempted' track call.
 *
 * @param UploadAttempted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function uploadAttempted(
  props: UploadAttempted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Upload_Attempted',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user tries to upload a file.',
    properties: {
      clientName: {
        description: 'The name of the client that the event occurred in.',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the product solutions.',
        type: ['string'],
      },
      product: {
        description: 'The product in which this action occurred.',
        type: ['string'],
      },
      source: { description: 'The source of the upload.', type: ['string'] },
      trigger: {
        description: 'The trigger that caused the upload to start.',
        pattern: 'filePicker|dragAndDrop|integration|extension',
        type: ['string'],
      },
      uploadExtension: {
        description: 'The extension of the file that was uploaded.',
        type: ['string'],
      },
      uploadMediaType: {
        description: 'The media type of the file that was uploaded.',
        type: ['string'],
      },
      uploadSize: {
        description: 'The size of the file that was uploaded.',
        type: ['integer'],
      },
      uploadTrackingId: {
        description:
          'Internal ID of the file upload attempt. Used only to link tracking events.',
        type: ['string'],
      },
      uploaderId: {
        description: 'The ID of the uploader that was used to upload the file.',
        type: ['string'],
      },
    },
    required: [
      'product',
      'organizationId',
      'clientName',
      'uploaderId',
      'uploadTrackingId',
      'trigger',
      'source',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Upload Attempted',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'UploadCompleted' track call.
 *
 * @param UploadCompleted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function uploadCompleted(
  props: UploadCompleted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Upload_Completed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When an upload is completed successfully.',
    properties: {
      clientName: {
        description: 'The name of the client that the event occurred in.',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the product solutions.',
        type: ['string'],
      },
      product: {
        description: 'The product in which this action occurred.',
        type: ['string'],
      },
      source: { description: 'The source of the upload.', type: ['string'] },
      trigger: {
        description: 'The trigger that caused the upload to start.',
        pattern: 'filePicker|dragAndDrop|integration|extension',
        type: ['string'],
      },
      uploadDuration: {
        description: 'For video files only, the duration in milliseconds.',
        type: ['integer'],
      },
      uploadExtension: {
        description: 'The extension of the file that was uploaded.',
        type: ['string'],
      },
      uploadId: {
        description: 'Dopcument ID in the uploads collection.',
        type: ['string'],
      },
      uploadMediaType: {
        description: 'The media type of the file that was uploaded.',
        type: ['string'],
      },
      uploadSize: {
        description: 'The size of the file that was uploaded.',
        type: ['integer'],
      },
      uploadTrackingId: {
        description:
          'Internal ID of the file upload attempt. Used only to link tracking events.',
        type: ['string'],
      },
      uploaderId: {
        description: 'The ID of the uploader that was used to upload the file.',
        type: ['string'],
      },
    },
    required: [
      'product',
      'organizationId',
      'clientName',
      'uploaderId',
      'trigger',
      'source',
      'uploadId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Upload Completed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'UploadFailed' track call.
 *
 * @param UploadFailed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function uploadFailed(
  props: UploadFailed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Upload_Failed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When an upload is fails.',
    properties: {
      clientName: {
        description: 'The name of the client that the event occurred in.',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the product solutions.',
        type: ['string'],
      },
      product: {
        description: 'The product in which this action occurred.',
        type: ['string'],
      },
      source: { description: 'The source of the upload.', type: ['string'] },
      trigger: {
        description: 'The trigger that caused the upload to start.',
        pattern: 'filePicker|dragAndDrop|integration|extension',
        type: ['string'],
      },
      uploadError: {
        description: 'The error that caused the upload to fail.',
        type: ['string'],
      },
      uploadExtension: {
        description: 'The extension of the file that was uploaded.',
        type: ['string'],
      },
      uploadMediaType: {
        description: 'The media type of the file that was uploaded.',
        type: ['string'],
      },
      uploadSize: {
        description: 'The size of the file that was uploaded.',
        type: ['integer'],
      },
      uploadTrackingId: {
        description:
          'Internal ID of the file upload attempt. Used only to link tracking events.',
        type: ['string'],
      },
      uploaderId: {
        description: 'The ID of the uploader that was used to upload the file.',
        type: ['string'],
      },
    },
    required: [
      'product',
      'organizationId',
      'clientName',
      'uploaderId',
      'uploadTrackingId',
      'trigger',
      'source',
      'uploadError',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Upload Failed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'UploadStarted' track call.
 *
 * @param UploadStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function uploadStarted(
  props: UploadStarted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Upload_Started',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When an attempted upload passes validation and start the upload.',
    properties: {
      clientName: {
        description: 'The name of the client that the event occurred in.',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the product solutions.',
        type: ['string'],
      },
      product: {
        description: 'The product in which this action occurred.',
        type: ['string'],
      },
      source: { description: 'The source of the upload.', type: ['string'] },
      trigger: {
        description: 'The trigger that caused the upload to start.',
        pattern: 'filePicker|dragAndDrop|integration|extension',
        type: ['string'],
      },
      uploadExtension: {
        description: 'The extension of the file that was uploaded.',
        type: ['string'],
      },
      uploadMediaType: {
        description: 'The media type of the file that was uploaded.',
        type: ['string'],
      },
      uploadSize: {
        description: 'The size of the file that was uploaded.',
        type: ['integer'],
      },
      uploadTrackingId: {
        description:
          'Internal ID of the file upload attempt. Used only to link tracking events.',
        type: ['string'],
      },
      uploaderId: {
        description: 'The ID of the uploader that was used to upload the file.',
        type: ['string'],
      },
    },
    required: [
      'product',
      'organizationId',
      'clientName',
      'uploaderId',
      'uploadTrackingId',
      'trigger',
      'source',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Upload Started',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'UploadValidationFailed' track call.
 *
 * @param UploadValidationFailed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function uploadValidationFailed(
  props: UploadValidationFailed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Upload_Validation_Failed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When an attempted upload fails validation.',
    properties: {
      clientName: {
        description: 'The name of the client that the event occurred in.',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      organizationId: {
        description:
          'The global organization ID of the user that viewed the product solutions.',
        type: ['string'],
      },
      product: {
        description: 'The product in which this action occurred.',
        type: ['string'],
      },
      source: { description: 'The source of the upload.', type: ['string'] },
      trigger: {
        description: 'The trigger that caused the upload to start.',
        pattern: 'filePicker|dragAndDrop|integration|extension',
        type: ['string'],
      },
      uploadExtension: {
        description: 'The extension of the file that was uploaded.',
        type: ['string'],
      },
      uploadMediaType: {
        description: 'The media type of the file that was uploaded.',
        type: ['string'],
      },
      uploadSize: {
        description: 'The size of the file that was uploaded.',
        type: ['integer'],
      },
      uploadTrackingId: {
        description:
          'Internal ID of the file upload attempt. Used only to link tracking events.',
        type: ['string'],
      },
      uploadValidationError: {
        description: 'The validation error that caused the upload to fail.',
        type: ['string'],
      },
      uploaderId: {
        description: 'The ID of the uploader that was used to upload the file.',
        type: ['string'],
      },
    },
    required: [
      'product',
      'organizationId',
      'clientName',
      'uploaderId',
      'uploadTrackingId',
      'trigger',
      'source',
      'uploadValidationError',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Upload Validation Failed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'UploadcareEditClicked' track call.
 *
 * @param UploadcareEditClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function uploadcareEditClicked(
  props: UploadcareEditClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Uploadcare_Edit_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When a user clicks the Edit button from the Publish composer.',
    properties: {
      channel: {
        description:
          'The channel that the Edit was opened for (ex. instagram, pinterest). Some times it is not needed as in Ideas',
        pattern:
          'twitter|facebook|instagram|instagramStory|pinterest|googlebusiness|mastodon|linkedin|multiple|omni|youtube|startPage|threads|bluesky|tiktok',
        type: ['string'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      context: {
        description: 'The component where the integration was opened',
        pattern: 'ideasEditor|publishComposer',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern: 'publish|buffer|analyze|engage',
        type: ['string'],
      },
    },
    required: ['organizationId', 'clientName', 'product'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Uploadcare Edit Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'UploadcareIntegrationButtonClicked' track call.
 *
 * @param UploadcareIntegrationButtonClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function uploadcareIntegrationButtonClicked(
  props: UploadcareIntegrationButtonClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Uploadcare_Integration_Button_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When a user clicks the Uploadcare integration button from the Publish composer.',
    properties: {
      channel: {
        description:
          'The channel that the integration was opened for (ex. instagram, pinterest). Some times it is not needed as in Ideas',
        pattern:
          'twitter|facebook|instagram|instagramStory|pinterest|linkedin|tiktok|googlebusiness|mastodon|multiple|omni|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      context: {
        description: 'The component where the integration was opened',
        pattern: 'ideasEditor|publishComposer',
        type: ['string'],
      },
      integration: {
        description: 'What button was clicked',
        pattern: 'gdrive|gphoto|dropbox|onedrive|giphy',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern: 'publish|buffer|analyze|engage',
        type: ['string'],
      },
      source: {
        description:
          'Source of the click from AB Tests, either integrations bar or dropdown',
        pattern: 'bar|dropdown',
        type: ['string'],
      },
    },
    required: [
      'organizationId',
      'clientName',
      'product',
      'source',
      'integration',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Uploadcare Integration Button Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'UploadcareModalOpened' track call.
 *
 * @param UploadcareModalOpened props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function uploadcareModalOpened(
  props: UploadcareModalOpened,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Uploadcare_Modal_Opened',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When a user opens the Uploadcare modal via available integrations.',
    properties: {
      channel: {
        description:
          'The channel that the integration was opened for (ex. instagram, pinterest). Some times it is not needed as in Ideas',
        pattern:
          'twitter|facebook|instagram|instagramStory|pinterest|linkedin|tiktok|googlebusiness|mastodon|multiple|omni|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      context: {
        description: 'The component where the integration was opened',
        pattern: 'ideasEditor|publishComposer',
        type: ['string'],
      },
      integration: {
        description: 'What integration loaded the Uploadcare modal',
        pattern: 'gdrive|gphoto|dropbox|onedrive|giphy|edit',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern: 'publish|buffer|analyze|engage',
        type: ['string'],
      },
      source: {
        description:
          'Source of the click from AB Tests, either integrations bar or dropdown',
        pattern: 'bar|dropdown|edit',
        type: ['string'],
      },
    },
    required: [
      'organizationId',
      'clientName',
      'product',
      'source',
      'integration',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Uploadcare Modal Opened',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'UploadcareUploadFinished' track call.
 *
 * @param UploadcareUploadFinished props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function uploadcareUploadFinished(
  props: UploadcareUploadFinished,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Uploadcare_Upload_Finished',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When the upload within Uploadcare is finished and the media is attached to the Composer.',
    properties: {
      channel: {
        description:
          'The channel that the integration was opened for (ex. instagram, pinterest). Some times it is not needed as in Ideas',
        pattern:
          'twitter|facebook|instagram|instagramStory|pinterest|linkedin|tiktok|googlebusiness|mastodon|multiple|omni|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      context: {
        description: 'The component where the integration was opened',
        pattern: 'ideasEditor|publishComposer',
        type: ['string'],
      },
      integration: {
        description: 'What integration loaded the Uploadcare modal',
        pattern: 'gdrive|gphoto|dropbox|onedrive|giphy|edit',
        type: ['string'],
      },
      media: {
        description: 'Type of media',
        pattern: 'photo|video',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern: 'publish|buffer|analyze|engage',
        type: ['string'],
      },
      source: {
        description:
          'Source of the click from AB Tests, either integrations bar or dropdown',
        pattern: 'bar|dropdown|edit',
        type: ['string'],
      },
    },
    required: [
      'organizationId',
      'clientName',
      'product',
      'source',
      'integration',
      'media',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Uploadcare Upload Finished',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'UploadcareUploadStarted' track call.
 *
 * @param UploadcareUploadStarted props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function uploadcareUploadStarted(
  props: UploadcareUploadStarted,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Uploadcare_Upload_Started',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user starts the upload of the file to Uploadcare.',
    properties: {
      channel: {
        description:
          'The channel that the integration was opened for (ex. instagram, pinterest). Some times it is not needed as in Ideas',
        pattern:
          'twitter|facebook|instagram|instagramStory|pinterest|linkedin|tiktok|googlebusiness|mastodon|multiple|omni|startPage|youtube|threads|bluesky',
        type: ['string'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        pattern: 'publishWeb|publishIos|publishAndroid|core',
        type: ['string'],
      },
      context: {
        description: 'The component where the integration was opened',
        pattern: 'ideasEditor|publishComposer',
        type: ['string'],
      },
      integration: {
        description: 'What integration loaded the Uploadcare modal',
        pattern: 'gdrive|gphoto|dropbox|onedrive|giphy|edit',
        type: ['string'],
      },
      media: {
        description: 'Type of media',
        pattern: 'photo|video',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that took the action',
        type: ['string'],
      },
      product: {
        description:
          'The product in which the action occurred (ex. publish, buffer)',
        pattern: 'publish|buffer|analyze|engage',
        type: ['string'],
      },
      source: {
        description:
          'Source of the click from AB Tests, either integrations bar or dropdown',
        pattern: 'bar|dropdown|edit',
        type: ['string'],
      },
    },
    required: [
      'organizationId',
      'clientName',
      'product',
      'source',
      'integration',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Uploadcare Upload Started',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'VideoPlayed' track call.
 *
 * @param VideoPlayed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function videoPlayed(
  props: VideoPlayed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Video_Played',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description:
      'When did the user play this video on the Buffer owned website?',
    properties: {
      cta: {
        description:
          'Which call to action did the user click to play this video?',
        type: ['string', 'null'],
      },
      ctaApp: {
        description:
          'Which product or website is the CTA located in? Examples would be either the product, like `publish`, or the website, like `bufferSite`',
        type: ['string', 'null'],
      },
      ctaButton: {
        description:
          'What is the name or action of the CTA?  In many cases it makes sense to describe the intended result of the CTA, like `proTrial` or `publishPro`',
        type: ['string', 'null'],
      },
      ctaLocation: {
        description:
          'Where in the view is the CTA located? An example would be `menu` for the Pro trial CTA in Publish app shell menu',
        type: ['string', 'null'],
      },
      ctaVersion: {
        description:
          'What is the version number of the CTA?  If setting the value for the first time, set to `1`',
        type: ['string', 'null'],
      },
      ctaView: {
        description:
          'What website page or app view is the CTA located?  Examples would be, `composer` or `analyticsOverview` for Publish, and `pricingPublish` for the Publish pricing page on the Buffer marketing site',
        type: ['string', 'null'],
      },
      organizationId: {
        description:
          'The organization ID of the account that was logged in. This will be null if we can not identify the person playing the video as an existing Buffer user.',
        type: ['string', 'null'],
      },
      url: {
        description:
          'The full url of the page with the embedded video that was played',
        type: ['string'],
      },
      utmCampaign: {
        description:
          'What was the specific campaign (e.g. product launch, new feature, partnership, etc.) that resulted in this video play?',
        type: ['string', 'null'],
      },
      utmContent: {
        description:
          'What was the specific content or content that this video play is attributed to. For example a specific CTA or part of an email that is being A/B tested.',
        type: ['string', 'null'],
      },
      utmMedium: {
        description:
          'What is the medium of the link that lead to this video play? For example email, CPC, or other method of sharing.',
        type: ['string', 'null'],
      },
      utmSource: {
        description:
          'What is the source of traffic that resulted in this video play? For example search engine, newsletter, or other referral.',
        type: ['string', 'null'],
      },
      utmTerm: {
        description:
          'What is the paid search term or keyword used to drive this video play? For example DTC, Instagram scheduling.',
        type: ['string', 'null'],
      },
      videoTitle: {
        description:
          'What is the unique name of the video, by which we identify it internally at Buffer? For example, `multi-product explainer video`.',
        type: ['string'],
      },
    },
    required: ['videoTitle'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Video Played',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'ViewCampaignReportButtonClicked' track call.
 *
 * @param ViewCampaignReportButtonClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function viewCampaignReportButtonClicked(
  props: ViewCampaignReportButtonClicked,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'View_Campaign_Report_Button_Clicked',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When a user clicks the View Report button.',
    properties: {
      campaignColor: {
        description: 'The color selected for the campaign.',
        type: ['string'],
      },
      campaignId: {
        description: 'The ID of the new campaign.',
        type: ['string'],
      },
      campaignName: {
        description: 'The name given to the campaign.',
        type: ['string'],
      },
      clientName: {
        description:
          'The name of the client in which the action occurred (ex. publishWeb)',
        type: ['string'],
      },
      organizationId: {
        description:
          'The ID of the organization for which the campaign was created.',
        type: ['string'],
      },
      product: {
        description: 'The product in which the action occurred',
        pattern: 'publish',
        type: ['string'],
      },
    },
    required: [
      'product',
      'campaignId',
      'campaignName',
      'campaignColor',
      'clientName',
      'organizationId',
    ],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'View Campaign Report Button Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'WelcomeChecklistViewed' track call.
 *
 * @param WelcomeChecklistViewed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function welcomeChecklistViewed(
  props: WelcomeChecklistViewed,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Welcome_Checklist_Viewed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the user see the welcome checklist?',
    properties: {
      organizationId: {
        description:
          'The global organization ID of the user that viewed the plan selector.',
        type: ['string', 'null'],
      },
      qtyItemsCompleted: {
        description: 'How many checklist items has the user completed?',
        type: ['number', 'null'],
      },
    },
    required: ['organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Welcome Checklist Viewed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'WhatsNewDialogTapped' track call.
 *
 * @param WhatsNewDialogTapped props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function whatsNewDialogTapped(
  props: WhatsNewDialogTapped,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Whats_New_Dialog_Tapped',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'When did the open the Whats New Dialog CTA',
    properties: {
      clientName: {
        description: 'The client in which the action occurred.',
        type: ['string'],
      },
      feature: {
        description:
          'The feature shown within the Whats New Dialog, ex. "shopify"',
        type: ['string'],
      },
      product: {
        description: 'The product in which the event occurred, ex. “remix”.',
        pattern: 'remix',
        type: ['string'],
      },
    },
    required: ['product', 'feature', 'clientName'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Whats New Dialog Tapped',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'WidgetAdded' track call.
 *
 * @param WidgetAdded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function widgetAdded(
  props: WidgetAdded,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Widget_Added',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Which widget did the user add to their home screen',
    properties: {
      clientId: {
        description:
          'What was the unique identifier of the client the user was in when they added the widget?',
        type: ['string', 'null'],
      },
      clientName: {
        description:
          'Which client was the user in when they added the widget? IE, `publishIos` or `publishAndroid`.',
        pattern: 'publishIos|publishAndroid',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that added the widget.',
        type: ['string'],
      },
      widgetName: {
        description: 'The name of the widget the user added',
        pattern: 'queueCounts|upNext|queueCountsWithUpNext',
        type: ['string'],
      },
      widgetSize: {
        description: 'The size of the widget the user added',
        pattern: 'S|M|L|XL|AC|AR|AI',
        type: ['string', 'null'],
      },
    },
    required: ['widgetName', 'clientName', 'organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Widget Added',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'WidgetRemoved' track call.
 *
 * @param WidgetRemoved props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function widgetRemoved(
  props: WidgetRemoved,
  options?: Options,
  callback?: Callback,
): void {
  const schema = {
    $id: 'Widget_Removed',
    $schema: 'http://json-schema.org/draft-07/schema#',
    description: 'Which widget did the user remove from their home screen',
    properties: {
      clientId: {
        description:
          'What was the unique identifier of the client the user was in when they removed the widget?',
        type: ['string', 'null'],
      },
      clientName: {
        description:
          'Which client was the user in when they removed the widget? IE, `publishIos` or `publishAndroid`.',
        pattern: 'publishIos|publishAndroid',
        type: ['string'],
      },
      organizationId: {
        description: 'The organization ID of the user that removed the widget.',
        type: ['string'],
      },
      widgetName: {
        description: 'The name of the widget the user removed',
        pattern: 'queueCounts|upNext|queueCountsWithUpNext',
        type: ['string'],
      },
      widgetSize: {
        description: 'The size of the widget the user removed',
        pattern: 'S|M|L|XL|AC|AR|AI',
        type: ['string', 'null'],
      },
    },
    required: ['widgetName', 'clientName', 'organizationId'],
    type: 'object',
  }
  validateAgainstSchema(props, schema)

  const a = analytics()
  if (a) {
    a.track(
      'Widget Removed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}

const clientAPI = {
  /**
   * Updates the run-time configuration of this Typewriter client.
   *
   * @param {TypewriterOptions} options - the options to upsert
   *
   * @typedef {Object} TypewriterOptions
   * @property {AnyAnalytics} [analytics] - Underlying analytics instance where analytics
   * 		calls are forwarded on to. Defaults to window.analytics.
   * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
   * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
   * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
   * 		will be logged to stderr.
   */
  setTypewriterOptions,

  /**
   * Fires a 'AIAssistantUpgradeModalViewed' track call.
   *
   * @param AIAssistantUpgradeModalViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  aIAssistantUpgradeModalViewed,
  /**
   * Fires a 'AccountAvatarUpdated' track call.
   *
   * @param AccountAvatarUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  accountAvatarUpdated,
  /**
   * Fires a 'AccountCreated' track call.
   *
   * @param AccountCreated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  accountCreated,
  /**
   * Fires a 'AccountDeleted' track call.
   *
   * @param AccountDeleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  accountDeleted,
  /**
   * Fires a 'AccountLogin' track call.
   *
   * @param AccountLogin props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  accountLogin,
  /**
   * Fires a 'AccountLogout' track call.
   *
   * @param AccountLogout props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  accountLogout,
  /**
   * Fires a 'AccountNameUpdated' track call.
   *
   * @param AccountNameUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  accountNameUpdated,
  /**
   * Fires a 'AccountSignupAttempted' track call.
   *
   * @param AccountSignupAttempted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  accountSignupAttempted,
  /**
   * Fires a 'AccountSuspended' track call.
   *
   * @param AccountSuspended props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  accountSuspended,
  /**
   * Fires a 'AccountTimezoneUpdated' track call.
   *
   * @param AccountTimezoneUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  accountTimezoneUpdated,
  /**
   * Fires a 'AccountUnsuspended' track call.
   *
   * @param AccountUnsuspended props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  accountUnsuspended,
  /**
   * Fires a 'AddressUpdated' track call.
   *
   * @param AddressUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  addressUpdated,
  /**
   * Fires a 'AllChannelsOpened' track call.
   *
   * @param AllChannelsOpened props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  allChannelsOpened,
  /**
   * Fires a 'AllPostCommentsDismissed' track call.
   *
   * @param AllPostCommentsDismissed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  allPostCommentsDismissed,
  /**
   * Fires a 'AnalyticsAddChartToReportFailed' track call.
   *
   * @param AnalyticsAddChartToReportFailed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  analyticsAddChartToReportFailed,
  /**
   * Fires a 'AnalyticsChartRenderingFailed' track call.
   *
   * @param AnalyticsChartRenderingFailed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  analyticsChartRenderingFailed,
  /**
   * Fires a 'AnalyticsExportFailed' track call.
   *
   * @param AnalyticsExportFailed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  analyticsExportFailed,
  /**
   * Fires a 'AnalyticsRetrievalFailed' track call.
   *
   * @param AnalyticsRetrievalFailed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  analyticsRetrievalFailed,
  /**
   * Fires a 'ApplicationInstalled' track call.
   *
   * @param ApplicationInstalled props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  applicationInstalled,
  /**
   * Fires a 'ApplicationOpened' track call.
   *
   * @param ApplicationOpened props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  applicationOpened,
  /**
   * Fires a 'ApplicationUpdated' track call.
   *
   * @param ApplicationUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  applicationUpdated,
  /**
   * Fires a 'AuthorAttributionAdded' track call.
   *
   * @param AuthorAttributionAdded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  authorAttributionAdded,
  /**
   * Fires a 'AutoCropToggled' track call.
   *
   * @param AutoCropToggled props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  autoCropToggled,
  /**
   * Fires a 'AutoRenewalDisabled' track call.
   *
   * @param AutoRenewalDisabled props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  autoRenewalDisabled,
  /**
   * Fires a 'BannerDismissed' track call.
   *
   * @param BannerDismissed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  bannerDismissed,
  /**
   * Fires a 'BannerViewed' track call.
   *
   * @param BannerViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  bannerViewed,
  /**
   * Fires a 'BetaProgramDisenrolled' track call.
   *
   * @param BetaProgramDisenrolled props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  betaProgramDisenrolled,
  /**
   * Fires a 'BetaProgramEnrolled' track call.
   *
   * @param BetaProgramEnrolled props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  betaProgramEnrolled,
  /**
   * Fires a 'BillingPortalSessionCreated' track call.
   *
   * @param BillingPortalSessionCreated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  billingPortalSessionCreated,
  /**
   * Fires a 'BitlyAccountConnected' track call.
   *
   * @param BitlyAccountConnected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  bitlyAccountConnected,
  /**
   * Fires a 'BitlyAccountDisconnected' track call.
   *
   * @param BitlyAccountDisconnected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  bitlyAccountDisconnected,
  /**
   * Fires a 'CTAClicked' track call.
   *
   * @param CTAClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  cTAClicked,
  /**
   * Fires a 'CTAViewed' track call.
   *
   * @param CTAViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  cTAViewed,
  /**
   * Fires a 'CalendarCampaignsFiltered' track call.
   *
   * @param CalendarCampaignsFiltered props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  calendarCampaignsFiltered,
  /**
   * Fires a 'CalendarChannelsFiltered' track call.
   *
   * @param CalendarChannelsFiltered props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  calendarChannelsFiltered,
  /**
   * Fires a 'CalendarChannelsSelected' track call.
   *
   * @param CalendarChannelsSelected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  calendarChannelsSelected,
  /**
   * Fires a 'CalendarDatesChanged' track call.
   *
   * @param CalendarDatesChanged props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  calendarDatesChanged,
  /**
   * Fires a 'CalendarOpened' track call.
   *
   * @param CalendarOpened props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  calendarOpened,
  /**
   * Fires a 'CalendarViewSwitched' track call.
   *
   * @param CalendarViewSwitched props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  calendarViewSwitched,
  /**
   * Fires a 'CampaignCreated' track call.
   *
   * @param CampaignCreated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  campaignCreated,
  /**
   * Fires a 'CampaignDeleted' track call.
   *
   * @param CampaignDeleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  campaignDeleted,
  /**
   * Fires a 'CampaignEdited' track call.
   *
   * @param CampaignEdited props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  campaignEdited,
  /**
   * Fires a 'CampaignItemAdded' track call.
   *
   * @param CampaignItemAdded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  campaignItemAdded,
  /**
   * Fires a 'CampaignItemRemoved' track call.
   *
   * @param CampaignItemRemoved props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  campaignItemRemoved,
  /**
   * Fires a 'CampaignReportViewed' track call.
   *
   * @param CampaignReportViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  campaignReportViewed,
  /**
   * Fires a 'CampaignsFilterSelected' track call.
   *
   * @param CampaignsFilterSelected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  campaignsFilterSelected,
  /**
   * Fires a 'CancelPlanClicked' track call.
   *
   * @param CancelPlanClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  cancelPlanClicked,
  /**
   * Fires a 'CanvaButtonClicked' track call.
   *
   * @param CanvaButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  canvaButtonClicked,
  /**
   * Fires a 'CanvaButtonEditClicked' track call.
   *
   * @param CanvaButtonEditClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  canvaButtonEditClicked,
  /**
   * Fires a 'CanvaDesignClosed' track call.
   *
   * @param CanvaDesignClosed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  canvaDesignClosed,
  /**
   * Fires a 'CanvaDesignEditClosed' track call.
   *
   * @param CanvaDesignEditClosed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  canvaDesignEditClosed,
  /**
   * Fires a 'CanvaDesignEditOpened' track call.
   *
   * @param CanvaDesignEditOpened props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  canvaDesignEditOpened,
  /**
   * Fires a 'CanvaDesignOpened' track call.
   *
   * @param CanvaDesignOpened props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  canvaDesignOpened,
  /**
   * Fires a 'CanvaDesignPublished' track call.
   *
   * @param CanvaDesignPublished props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  canvaDesignPublished,
  /**
   * Fires a 'CanvaDesignRepublished' track call.
   *
   * @param CanvaDesignRepublished props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  canvaDesignRepublished,
  /**
   * Fires a 'CanvaImageAttached' track call.
   *
   * @param CanvaImageAttached props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  canvaImageAttached,
  /**
   * Fires a 'CanvaImageEditAttached' track call.
   *
   * @param CanvaImageEditAttached props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  canvaImageEditAttached,
  /**
   * Fires a 'CarouselEditAttempted' track call.
   *
   * @param CarouselEditAttempted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  carouselEditAttempted,
  /**
   * Fires a 'ChannelBackfillCompleted' track call.
   *
   * @param ChannelBackfillCompleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  channelBackfillCompleted,
  /**
   * Fires a 'ChannelCTAClicked' track call.
   *
   * @param ChannelCTAClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  channelCTAClicked,
  /**
   * Fires a 'ChannelCTAViewed' track call.
   *
   * @param ChannelCTAViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  channelCTAViewed,
  /**
   * Fires a 'ChannelConnected' track call.
   *
   * @param ChannelConnected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  channelConnected,
  /**
   * Fires a 'ChannelConnectionAborted' track call.
   *
   * @param ChannelConnectionAborted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  channelConnectionAborted,
  /**
   * Fires a 'ChannelConnectionAttempted' track call.
   *
   * @param ChannelConnectionAttempted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  channelConnectionAttempted,
  /**
   * Fires a 'ChannelConnectionCanceledByUser' track call.
   *
   * @param ChannelConnectionCanceledByUser props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  channelConnectionCanceledByUser,
  /**
   * Fires a 'ChannelConnectionFailed' track call.
   *
   * @param ChannelConnectionFailed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  channelConnectionFailed,
  /**
   * Fires a 'ChannelConnectionGoalModalViewed' track call.
   *
   * @param ChannelConnectionGoalModalViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  channelConnectionGoalModalViewed,
  /**
   * Fires a 'ChannelConnectionOnboardingModalViewed' track call.
   *
   * @param ChannelConnectionOnboardingModalViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  channelConnectionOnboardingModalViewed,
  /**
   * Fires a 'ChannelConnectionProfileSelectorViewed' track call.
   *
   * @param ChannelConnectionProfileSelectorViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  channelConnectionProfileSelectorViewed,
  /**
   * Fires a 'ChannelConnectionProfileTypeSelectionViewed' track call.
   *
   * @param ChannelConnectionProfileTypeSelectionViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  channelConnectionProfileTypeSelectionViewed,
  /**
   * Fires a 'ChannelConnectionStarted' track call.
   *
   * @param ChannelConnectionStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  channelConnectionStarted,
  /**
   * Fires a 'ChannelConversionAborted' track call.
   *
   * @param ChannelConversionAborted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  channelConversionAborted,
  /**
   * Fires a 'ChannelConversionAttempted' track call.
   *
   * @param ChannelConversionAttempted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  channelConversionAttempted,
  /**
   * Fires a 'ChannelConversionCanceledByUser' track call.
   *
   * @param ChannelConversionCanceledByUser props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  channelConversionCanceledByUser,
  /**
   * Fires a 'ChannelConversionDialogViewed' track call.
   *
   * @param ChannelConversionDialogViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  channelConversionDialogViewed,
  /**
   * Fires a 'ChannelConversionFailed' track call.
   *
   * @param ChannelConversionFailed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  channelConversionFailed,
  /**
   * Fires a 'ChannelConversionStarted' track call.
   *
   * @param ChannelConversionStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  channelConversionStarted,
  /**
   * Fires a 'ChannelConversionSuccess' track call.
   *
   * @param ChannelConversionSuccess props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  channelConversionSuccess,
  /**
   * Fires a 'ChannelDeselected' track call.
   *
   * @param ChannelDeselected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  channelDeselected,
  /**
   * Fires a 'ChannelDetailPanelViewed' track call.
   *
   * @param ChannelDetailPanelViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  channelDetailPanelViewed,
  /**
   * Fires a 'ChannelDisconnected' track call.
   *
   * @param ChannelDisconnected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  channelDisconnected,
  /**
   * Fires a 'ChannelLinkToFacebookPageAborted' track call.
   *
   * @param ChannelLinkToFacebookPageAborted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  channelLinkToFacebookPageAborted,
  /**
   * Fires a 'ChannelLinkToFacebookPageDialogViewed' track call.
   *
   * @param ChannelLinkToFacebookPageDialogViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  channelLinkToFacebookPageDialogViewed,
  /**
   * Fires a 'ChannelLinkToFacebookPageStarted' track call.
   *
   * @param ChannelLinkToFacebookPageStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  channelLinkToFacebookPageStarted,
  /**
   * Fires a 'ChannelLocked' track call.
   *
   * @param ChannelLocked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  channelLocked,
  /**
   * Fires a 'ChannelQueueEmptied' track call.
   *
   * @param ChannelQueueEmptied props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  channelQueueEmptied,
  /**
   * Fires a 'ChannelReconnected' track call.
   *
   * @param ChannelReconnected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  channelReconnected,
  /**
   * Fires a 'ChannelRefreshAttempted' track call.
   *
   * @param ChannelRefreshAttempted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  channelRefreshAttempted,
  /**
   * Fires a 'ChannelRefreshFailed' track call.
   *
   * @param ChannelRefreshFailed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  channelRefreshFailed,
  /**
   * Fires a 'ChannelRefreshStarted' track call.
   *
   * @param ChannelRefreshStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  channelRefreshStarted,
  /**
   * Fires a 'ChannelRefreshSuccessModalViewed' track call.
   *
   * @param ChannelRefreshSuccessModalViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  channelRefreshSuccessModalViewed,
  /**
   * Fires a 'ChannelSelected' track call.
   *
   * @param ChannelSelected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  channelSelected,
  /**
   * Fires a 'ChannelStorefrontMoreDetailsClicked' track call.
   *
   * @param ChannelStorefrontMoreDetailsClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  channelStorefrontMoreDetailsClicked,
  /**
   * Fires a 'ChannelStorefrontViewed' track call.
   *
   * @param ChannelStorefrontViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  channelStorefrontViewed,
  /**
   * Fires a 'ChannelTabFilterClicked' track call.
   *
   * @param ChannelTabFilterClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  channelTabFilterClicked,
  /**
   * Fires a 'ChannelUnauthorized' track call.
   *
   * @param ChannelUnauthorized props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  channelUnauthorized,
  /**
   * Fires a 'ChannelUnlockModalViewed' track call.
   *
   * @param ChannelUnlockModalViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  channelUnlockModalViewed,
  /**
   * Fires a 'ChannelUnlocked' track call.
   *
   * @param ChannelUnlocked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  channelUnlocked,
  /**
   * Fires a 'ChannelUpdated' track call.
   *
   * @param ChannelUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  channelUpdated,
  /**
   * Fires a 'ChannelsReordered' track call.
   *
   * @param ChannelsReordered props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  channelsReordered,
  /**
   * Fires a 'ChecklistItemClicked' track call.
   *
   * @param ChecklistItemClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  checklistItemClicked,
  /**
   * Fires a 'ChecklistModalViewed' track call.
   *
   * @param ChecklistModalViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  checklistModalViewed,
  /**
   * Fires a 'ChurnSurveyCTAClicked' track call.
   *
   * @param ChurnSurveyCTAClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  churnSurveyCTAClicked,
  /**
   * Fires a 'ChurnSurveyCompleted' track call.
   *
   * @param ChurnSurveyCompleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  churnSurveyCompleted,
  /**
   * Fires a 'ChurnSurveyViewed' track call.
   *
   * @param ChurnSurveyViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  churnSurveyViewed,
  /**
   * Fires a 'CommentDeleted' track call.
   *
   * @param CommentDeleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  commentDeleted,
  /**
   * Fires a 'CommentDismissed' track call.
   *
   * @param CommentDismissed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  commentDismissed,
  /**
   * Fires a 'CommentFailed' track call.
   *
   * @param CommentFailed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  commentFailed,
  /**
   * Fires a 'CommentMarkedAsSpam' track call.
   *
   * @param CommentMarkedAsSpam props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  commentMarkedAsSpam,
  /**
   * Fires a 'CommentReplied' track call.
   *
   * @param CommentReplied props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  commentReplied,
  /**
   * Fires a 'CommentSelected' track call.
   *
   * @param CommentSelected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  commentSelected,
  /**
   * Fires a 'CommunityJoined' track call.
   *
   * @param CommunityJoined props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  communityJoined,
  /**
   * Fires a 'ComponentRendered' track call.
   *
   * @param ComponentRendered props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  componentRendered,
  /**
   * Fires a 'ComposerCustomizerOpened' track call.
   *
   * @param ComposerCustomizerOpened props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  composerCustomizerOpened,
  /**
   * Fires a 'ComposerDiscarded' track call.
   *
   * @param ComposerDiscarded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  composerDiscarded,
  /**
   * Fires a 'ComposerMediaReordered' track call.
   *
   * @param ComposerMediaReordered props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  composerMediaReordered,
  /**
   * Fires a 'ComposerOpened' track call.
   *
   * @param ComposerOpened props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  composerOpened,
  /**
   * Fires a 'ComposerToolTipViewed' track call.
   *
   * @param ComposerToolTipViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  composerToolTipViewed,
  /**
   * Fires a 'ConfirmCancellationCTAClicked' track call.
   *
   * @param ConfirmCancellationCTAClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  confirmCancellationCTAClicked,
  /**
   * Fires a 'ConfirmCancellationViewed' track call.
   *
   * @param ConfirmCancellationViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  confirmCancellationViewed,
  /**
   * Fires a 'ConnectChannelUpgradeModalViewed' track call.
   *
   * @param ConnectChannelUpgradeModalViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  connectChannelUpgradeModalViewed,
  /**
   * Fires a 'ContentGenerationAccepted' track call.
   *
   * @param ContentGenerationAccepted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  contentGenerationAccepted,
  /**
   * Fires a 'ContentGenerationCancelled' track call.
   *
   * @param ContentGenerationCancelled props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  contentGenerationCancelled,
  /**
   * Fires a 'ContentGenerationFailed' track call.
   *
   * @param ContentGenerationFailed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  contentGenerationFailed,
  /**
   * Fires a 'ContentGenerationReceived' track call.
   *
   * @param ContentGenerationReceived props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  contentGenerationReceived,
  /**
   * Fires a 'ContentGenerationRequested' track call.
   *
   * @param ContentGenerationRequested props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  contentGenerationRequested,
  /**
   * Fires a 'ContentGroupCreated' track call.
   *
   * @param ContentGroupCreated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  contentGroupCreated,
  /**
   * Fires a 'ContentGroupDeleted' track call.
   *
   * @param ContentGroupDeleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  contentGroupDeleted,
  /**
   * Fires a 'ContentGroupMoved' track call.
   *
   * @param ContentGroupMoved props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  contentGroupMoved,
  /**
   * Fires a 'ContentGroupRenamed' track call.
   *
   * @param ContentGroupRenamed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  contentGroupRenamed,
  /**
   * Fires a 'CreditNoteCreated' track call.
   *
   * @param CreditNoteCreated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  creditNoteCreated,
  /**
   * Fires a 'CustomBitlyConnectButtonClicked' track call.
   *
   * @param CustomBitlyConnectButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  customBitlyConnectButtonClicked,
  /**
   * Fires a 'CustomBitlyDisconnectButtonClicked' track call.
   *
   * @param CustomBitlyDisconnectButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  customBitlyDisconnectButtonClicked,
  /**
   * Fires a 'CustomChannelSetupCompleted' track call.
   *
   * @param CustomChannelSetupCompleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  customChannelSetupCompleted,
  /**
   * Fires a 'CustomChannelSetupViewed' track call.
   *
   * @param CustomChannelSetupViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  customChannelSetupViewed,
  /**
   * Fires a 'DataIngestionFailed' track call.
   *
   * @param DataIngestionFailed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  dataIngestionFailed,
  /**
   * Fires a 'DateRangeSelected' track call.
   *
   * @param DateRangeSelected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  dateRangeSelected,
  /**
   * Fires a 'DialogViewed' track call.
   *
   * @param DialogViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  dialogViewed,
  /**
   * Fires a 'DisputeCreated' track call.
   *
   * @param DisputeCreated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  disputeCreated,
  /**
   * Fires a 'DraftApprovalNotified' track call.
   *
   * @param DraftApprovalNotified props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  draftApprovalNotified,
  /**
   * Fires a 'DraftApprovalRequiredNotified' track call.
   *
   * @param DraftApprovalRequiredNotified props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  draftApprovalRequiredNotified,
  /**
   * Fires a 'DraftApproved' track call.
   *
   * @param DraftApproved props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  draftApproved,
  /**
   * Fires a 'DraftCommentAdded' track call.
   *
   * @param DraftCommentAdded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  draftCommentAdded,
  /**
   * Fires a 'DraftCommentDeleted' track call.
   *
   * @param DraftCommentDeleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  draftCommentDeleted,
  /**
   * Fires a 'DraftDeleted' track call.
   *
   * @param DraftDeleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  draftDeleted,
  /**
   * Fires a 'DraftRejected' track call.
   *
   * @param DraftRejected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  draftRejected,
  /**
   * Fires a 'DraftRejectionNotified' track call.
   *
   * @param DraftRejectionNotified props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  draftRejectionNotified,
  /**
   * Fires a 'DraftSubmitted' track call.
   *
   * @param DraftSubmitted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  draftSubmitted,
  /**
   * Fires a 'DynamicNoticeActionPerformed' track call.
   *
   * @param DynamicNoticeActionPerformed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  dynamicNoticeActionPerformed,
  /**
   * Fires a 'DynamicNoticeViewed' track call.
   *
   * @param DynamicNoticeViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  dynamicNoticeViewed,
  /**
   * Fires a 'EcommercePlatformConnected' track call.
   *
   * @param EcommercePlatformConnected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  ecommercePlatformConnected,
  /**
   * Fires a 'EmailUpdated' track call.
   *
   * @param EmailUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  emailUpdated,
  /**
   * Fires a 'EmailVerificationInitiated' track call.
   *
   * @param EmailVerificationInitiated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  emailVerificationInitiated,
  /**
   * Fires a 'EmailVerified' track call.
   *
   * @param EmailVerified props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  emailVerified,
  /**
   * Fires a 'EngageDataDisabled' track call.
   *
   * @param EngageDataDisabled props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  engageDataDisabled,
  /**
   * Fires a 'EngageDisabled' track call.
   *
   * @param EngageDisabled props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  engageDisabled,
  /**
   * Fires a 'EngageReactivated' track call.
   *
   * @param EngageReactivated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  engageReactivated,
  /**
   * Fires a 'ExperimentEnrolled' track call.
   *
   * @param ExperimentEnrolled props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  experimentEnrolled,
  /**
   * Fires a 'ExperimentViewed' track call.
   *
   * @param ExperimentViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  experimentViewed,
  /**
   * Fires a 'FeatureFlipDisabled' track call.
   *
   * @param FeatureFlipDisabled props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  featureFlipDisabled,
  /**
   * Fires a 'FeatureFlipEnabled' track call.
   *
   * @param FeatureFlipEnabled props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  featureFlipEnabled,
  /**
   * Fires a 'FeatureFlipEvaluated' track call.
   *
   * @param FeatureFlipEvaluated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  featureFlipEvaluated,
  /**
   * Fires a 'FeedCollectionCreated' track call.
   *
   * @param FeedCollectionCreated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  feedCollectionCreated,
  /**
   * Fires a 'FeedItemInteracted' track call.
   *
   * @param FeedItemInteracted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  feedItemInteracted,
  /**
   * Fires a 'FeedSubscribed' track call.
   *
   * @param FeedSubscribed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  feedSubscribed,
  /**
   * Fires a 'FeedbackFormSubmitted' track call.
   *
   * @param FeedbackFormSubmitted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  feedbackFormSubmitted,
  /**
   * Fires a 'FirstPostCelebrationCtaClicked' track call.
   *
   * @param FirstPostCelebrationCtaClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  firstPostCelebrationCtaClicked,
  /**
   * Fires a 'FirstPostCelebrationModalViewed' track call.
   *
   * @param FirstPostCelebrationModalViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  firstPostCelebrationModalViewed,
  /**
   * Fires a 'FollowMastodonButtonClicked' track call.
   *
   * @param FollowMastodonButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  followMastodonButtonClicked,
  /**
   * Fires a 'FollowMastodonDismissed' track call.
   *
   * @param FollowMastodonDismissed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  followMastodonDismissed,
  /**
   * Fires a 'FreeToolRateLimitModalViewed' track call.
   *
   * @param FreeToolRateLimitModalViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  freeToolRateLimitModalViewed,
  /**
   * Fires a 'GettingStartedStepTapped' track call.
   *
   * @param GettingStartedStepTapped props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  gettingStartedStepTapped,
  /**
   * Fires a 'GlobalPlusButtonItemClicked' track call.
   *
   * @param GlobalPlusButtonItemClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  globalPlusButtonItemClicked,
  /**
   * Fires a 'GlobalPlusButtonOpened' track call.
   *
   * @param GlobalPlusButtonOpened props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  globalPlusButtonOpened,
  /**
   * Fires a 'HashtagGroupCreated' track call.
   *
   * @param HashtagGroupCreated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  hashtagGroupCreated,
  /**
   * Fires a 'HashtagGroupDeleted' track call.
   *
   * @param HashtagGroupDeleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  hashtagGroupDeleted,
  /**
   * Fires a 'HashtagGroupInserted' track call.
   *
   * @param HashtagGroupInserted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  hashtagGroupInserted,
  /**
   * Fires a 'HelpCenterArticleViewed' track call.
   *
   * @param HelpCenterArticleViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  helpCenterArticleViewed,
  /**
   * Fires a 'HelpScoutBeaconArticleViewed' track call.
   *
   * @param HelpScoutBeaconArticleViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  helpScoutBeaconArticleViewed,
  /**
   * Fires a 'HelpScoutBeaconChatStarted' track call.
   *
   * @param HelpScoutBeaconChatStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  helpScoutBeaconChatStarted,
  /**
   * Fires a 'HelpScoutBeaconClosed' track call.
   *
   * @param HelpScoutBeaconClosed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  helpScoutBeaconClosed,
  /**
   * Fires a 'HelpScoutBeaconEmailSent' track call.
   *
   * @param HelpScoutBeaconEmailSent props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  helpScoutBeaconEmailSent,
  /**
   * Fires a 'HelpScoutBeaconOpened' track call.
   *
   * @param HelpScoutBeaconOpened props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  helpScoutBeaconOpened,
  /**
   * Fires a 'HelpScoutBeaconSearchQueryPerformed' track call.
   *
   * @param HelpScoutBeaconSearchQueryPerformed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  helpScoutBeaconSearchQueryPerformed,
  /**
   * Fires a 'HomepageButtonClicked' track call.
   *
   * @param HomepageButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  homepageButtonClicked,
  /**
   * Fires a 'IdeaActionClicked' track call.
   *
   * @param IdeaActionClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  ideaActionClicked,
  /**
   * Fires a 'IdeaBatchDeleted' track call.
   *
   * @param IdeaBatchDeleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  ideaBatchDeleted,
  /**
   * Fires a 'IdeaComposerOpened' track call.
   *
   * @param IdeaComposerOpened props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  ideaComposerOpened,
  /**
   * Fires a 'IdeaCreated' track call.
   *
   * @param IdeaCreated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  ideaCreated,
  /**
   * Fires a 'IdeaDeleted' track call.
   *
   * @param IdeaDeleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  ideaDeleted,
  /**
   * Fires a 'IdeaDiscarded' track call.
   *
   * @param IdeaDiscarded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  ideaDiscarded,
  /**
   * Fires a 'IdeaEdited' track call.
   *
   * @param IdeaEdited props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  ideaEdited,
  /**
   * Fires a 'IdeaKept' track call.
   *
   * @param IdeaKept props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  ideaKept,
  /**
   * Fires a 'IdeaMoved' track call.
   *
   * @param IdeaMoved props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  ideaMoved,
  /**
   * Fires a 'IdeaShareSheetOpened' track call.
   *
   * @param IdeaShareSheetOpened props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  ideaShareSheetOpened,
  /**
   * Fires a 'IdeasAssistantCancelled' track call.
   *
   * @param IdeasAssistantCancelled props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  ideasAssistantCancelled,
  /**
   * Fires a 'IdeasAssistantInitiated' track call.
   *
   * @param IdeasAssistantInitiated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  ideasAssistantInitiated,
  /**
   * Fires a 'IdeasAssistantVariationAccepted' track call.
   *
   * @param IdeasAssistantVariationAccepted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  ideasAssistantVariationAccepted,
  /**
   * Fires a 'IdeasAssistantVariationErrored' track call.
   *
   * @param IdeasAssistantVariationErrored props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  ideasAssistantVariationErrored,
  /**
   * Fires a 'IdeasAssistantVariationProposed' track call.
   *
   * @param IdeasAssistantVariationProposed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  ideasAssistantVariationProposed,
  /**
   * Fires a 'IdeasAssistantVariationRequested' track call.
   *
   * @param IdeasAssistantVariationRequested props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  ideasAssistantVariationRequested,
  /**
   * Fires a 'IdeasFiltered' track call.
   *
   * @param IdeasFiltered props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  ideasFiltered,
  /**
   * Fires a 'IdeasOpenedDuringOnboarding' track call.
   *
   * @param IdeasOpenedDuringOnboarding props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  ideasOpenedDuringOnboarding,
  /**
   * Fires a 'ImageBackgroundColorSelected' track call.
   *
   * @param ImageBackgroundColorSelected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  imageBackgroundColorSelected,
  /**
   * Fires a 'ImageBackgroundIconTapped' track call.
   *
   * @param ImageBackgroundIconTapped props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  imageBackgroundIconTapped,
  /**
   * Fires a 'ImageBackgroundImageRemoved' track call.
   *
   * @param ImageBackgroundImageRemoved props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  imageBackgroundImageRemoved,
  /**
   * Fires a 'ImageBackgroundImageSelected' track call.
   *
   * @param ImageBackgroundImageSelected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  imageBackgroundImageSelected,
  /**
   * Fires a 'ImageCropped' track call.
   *
   * @param ImageCropped props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  imageCropped,
  /**
   * Fires a 'ImageSaved' track call.
   *
   * @param ImageSaved props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  imageSaved,
  /**
   * Fires a 'ImageShared' track call.
   *
   * @param ImageShared props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  imageShared,
  /**
   * Fires a 'ImageSizeSelected' track call.
   *
   * @param ImageSizeSelected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  imageSizeSelected,
  /**
   * Fires a 'ImageStyleResetTapped' track call.
   *
   * @param ImageStyleResetTapped props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  imageStyleResetTapped,
  /**
   * Fires a 'ImageTemplateSelected' track call.
   *
   * @param ImageTemplateSelected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  imageTemplateSelected,
  /**
   * Fires a 'InstagramAuthorizationExplainerSlideViewed' track call.
   *
   * @param InstagramAuthorizationExplainerSlideViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  instagramAuthorizationExplainerSlideViewed,
  /**
   * Fires a 'InstagramCaptionDotsInserted' track call.
   *
   * @param InstagramCaptionDotsInserted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  instagramCaptionDotsInserted,
  /**
   * Fires a 'InstagramGridPreviewIconClicked' track call.
   *
   * @param InstagramGridPreviewIconClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  instagramGridPreviewIconClicked,
  /**
   * Fires a 'InstagramGridPreviewPageLoaded' track call.
   *
   * @param InstagramGridPreviewPageLoaded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  instagramGridPreviewPageLoaded,
  /**
   * Fires a 'InstagramGridPreviewToggleView' track call.
   *
   * @param InstagramGridPreviewToggleView props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  instagramGridPreviewToggleView,
  /**
   * Fires a 'InstagramTagsSaved' track call.
   *
   * @param InstagramTagsSaved props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  instagramTagsSaved,
  /**
   * Fires a 'InstagramUserTagged' track call.
   *
   * @param InstagramUserTagged props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  instagramUserTagged,
  /**
   * Fires a 'IntegrationButtonClicked' track call.
   *
   * @param IntegrationButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  integrationButtonClicked,
  /**
   * Fires a 'IntegrationMediaAttached' track call.
   *
   * @param IntegrationMediaAttached props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  integrationMediaAttached,
  /**
   * Fires a 'IntegrationSearchRun' track call.
   *
   * @param IntegrationSearchRun props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  integrationSearchRun,
  /**
   * Fires a 'InvoicePaymentFailed' track call.
   *
   * @param InvoicePaymentFailed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  invoicePaymentFailed,
  /**
   * Fires a 'InvoicePaymentRefunded' track call.
   *
   * @param InvoicePaymentRefunded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  invoicePaymentRefunded,
  /**
   * Fires a 'InvoicePaymentSucceeded' track call.
   *
   * @param InvoicePaymentSucceeded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  invoicePaymentSucceeded,
  /**
   * Fires a 'InvoiceUpcoming' track call.
   *
   * @param InvoiceUpcoming props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  invoiceUpcoming,
  /**
   * Fires a 'JoinCommunityButtonClicked' track call.
   *
   * @param JoinCommunityButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  joinCommunityButtonClicked,
  /**
   * Fires a 'LinkShortened' track call.
   *
   * @param LinkShortened props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  linkShortened,
  /**
   * Fires a 'LinkShortenedClicked' track call.
   *
   * @param LinkShortenedClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  linkShortenedClicked,
  /**
   * Fires a 'LinkUnshortenedClicked' track call.
   *
   * @param LinkUnshortenedClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  linkUnshortenedClicked,
  /**
   * Fires a 'LiveChatCreated' track call.
   *
   * @param LiveChatCreated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  liveChatCreated,
  /**
   * Fires a 'LiveChatCustomerReplied' track call.
   *
   * @param LiveChatCustomerReplied props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  liveChatCustomerReplied,
  /**
   * Fires a 'LoginVerificationAbandoned' track call.
   *
   * @param LoginVerificationAbandoned props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  loginVerificationAbandoned,
  /**
   * Fires a 'LoginVerificationComplete' track call.
   *
   * @param LoginVerificationComplete props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  loginVerificationComplete,
  /**
   * Fires a 'LoginVerificationInitiated' track call.
   *
   * @param LoginVerificationInitiated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  loginVerificationInitiated,
  /**
   * Fires a 'MarketingEmailsDisabled' track call.
   *
   * @param MarketingEmailsDisabled props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  marketingEmailsDisabled,
  /**
   * Fires a 'MarketingEmailsEnabled' track call.
   *
   * @param MarketingEmailsEnabled props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  marketingEmailsEnabled,
  /**
   * Fires a 'MastodonChannelPromoDismissed' track call.
   *
   * @param MastodonChannelPromoDismissed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  mastodonChannelPromoDismissed,
  /**
   * Fires a 'MediaAdded' track call.
   *
   * @param MediaAdded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  mediaAdded,
  /**
   * Fires a 'MediaSourceSelected' track call.
   *
   * @param MediaSourceSelected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  mediaSourceSelected,
  /**
   * Fires a 'MediaValidationWarningMessageViewed' track call.
   *
   * @param MediaValidationWarningMessageViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  mediaValidationWarningMessageViewed,
  /**
   * Fires a 'MigrationCheckoutModalViewed' track call.
   *
   * @param MigrationCheckoutModalViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  migrationCheckoutModalViewed,
  /**
   * Fires a 'MigrationPageViewed' track call.
   *
   * @param MigrationPageViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  migrationPageViewed,
  /**
   * Fires a 'ModalDismissed' track call.
   *
   * @param ModalDismissed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  modalDismissed,
  /**
   * Fires a 'ModalPaymentOpened' track call.
   *
   * @param ModalPaymentOpened props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  modalPaymentOpened,
  /**
   * Fires a 'NPSSurveyCompleted' track call.
   *
   * @param NPSSurveyCompleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  nPSSurveyCompleted,
  /**
   * Fires a 'NoteAdded' track call.
   *
   * @param NoteAdded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  noteAdded,
  /**
   * Fires a 'NoteDeleted' track call.
   *
   * @param NoteDeleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  noteDeleted,
  /**
   * Fires a 'NoteEdited' track call.
   *
   * @param NoteEdited props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  noteEdited,
  /**
   * Fires a 'NoteFeedViewed' track call.
   *
   * @param NoteFeedViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  noteFeedViewed,
  /**
   * Fires a 'OnboardingNotificationPermissionSelected' track call.
   *
   * @param OnboardingNotificationPermissionSelected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  onboardingNotificationPermissionSelected,
  /**
   * Fires a 'OnboardingSkipped' track call.
   *
   * @param OnboardingSkipped props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  onboardingSkipped,
  /**
   * Fires a 'OnboardingSlideViewed' track call.
   *
   * @param OnboardingSlideViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  onboardingSlideViewed,
  /**
   * Fires a 'OnboardingSurveyCompleted' track call.
   *
   * @param OnboardingSurveyCompleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  onboardingSurveyCompleted,
  /**
   * Fires a 'OnboardingSurveySkipped' track call.
   *
   * @param OnboardingSurveySkipped props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  onboardingSurveySkipped,
  /**
   * Fires a 'OnboardingSurveyViewed' track call.
   *
   * @param OnboardingSurveyViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  onboardingSurveyViewed,
  /**
   * Fires a 'OnboardingTrialPromptViewed' track call.
   *
   * @param OnboardingTrialPromptViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  onboardingTrialPromptViewed,
  /**
   * Fires a 'OneBufferMigrated' track call.
   *
   * @param OneBufferMigrated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  oneBufferMigrated,
  /**
   * Fires a 'OrganizationCreated' track call.
   *
   * @param OrganizationCreated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  organizationCreated,
  /**
   * Fires a 'OrganizationDeleted' track call.
   *
   * @param OrganizationDeleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  organizationDeleted,
  /**
   * Fires a 'OrganizationSwitched' track call.
   *
   * @param OrganizationSwitched props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  organizationSwitched,
  /**
   * Fires a 'OrganizationTimezoneUpdated' track call.
   *
   * @param OrganizationTimezoneUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  organizationTimezoneUpdated,
  /**
   * Fires a 'OrganizationTokensExhausted' track call.
   *
   * @param OrganizationTokensExhausted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  organizationTokensExhausted,
  /**
   * Fires a 'OverviewAnalyticsDataExported' track call.
   *
   * @param OverviewAnalyticsDataExported props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  overviewAnalyticsDataExported,
  /**
   * Fires a 'OverviewAnalyticsMetricUpdated' track call.
   *
   * @param OverviewAnalyticsMetricUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  overviewAnalyticsMetricUpdated,
  /**
   * Fires a 'OverviewInsightsMetricSelected' track call.
   *
   * @param OverviewInsightsMetricSelected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  overviewInsightsMetricSelected,
  /**
   * Fires a 'OverviewInsightsPreviousPeriodToggled' track call.
   *
   * @param OverviewInsightsPreviousPeriodToggled props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  overviewInsightsPreviousPeriodToggled,
  /**
   * Fires a 'PabloImageDownloaded' track call.
   *
   * @param PabloImageDownloaded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  pabloImageDownloaded,
  /**
   * Fires a 'PabloImageShared' track call.
   *
   * @param PabloImageShared props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  pabloImageShared,
  /**
   * Fires a 'PageExported' track call.
   *
   * @param PageExported props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  pageExported,
  /**
   * Fires a 'PageViewed' track call.
   *
   * @param PageViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  pageViewed,
  /**
   * Fires a 'PasswordUpdated' track call.
   *
   * @param PasswordUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  passwordUpdated,
  /**
   * Fires a 'PastRemindersStoryGroupsFilterClicked' track call.
   *
   * @param PastRemindersStoryGroupsFilterClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  pastRemindersStoryGroupsFilterClicked,
  /**
   * Fires a 'PaymentMethodAdded' track call.
   *
   * @param PaymentMethodAdded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  paymentMethodAdded,
  /**
   * Fires a 'PaymentMethodExpiring' track call.
   *
   * @param PaymentMethodExpiring props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  paymentMethodExpiring,
  /**
   * Fires a 'PaymentMethodUpdated' track call.
   *
   * @param PaymentMethodUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  paymentMethodUpdated,
  /**
   * Fires a 'PaywallVideoPlayed' track call.
   *
   * @param PaywallVideoPlayed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  paywallVideoPlayed,
  /**
   * Fires a 'PlanLimitAlertViewed' track call.
   *
   * @param PlanLimitAlertViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  planLimitAlertViewed,
  /**
   * Fires a 'PlanSelectorChannelQuantityClicked' track call.
   *
   * @param PlanSelectorChannelQuantityClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  planSelectorChannelQuantityClicked,
  /**
   * Fires a 'PlanSelectorMainCtaClicked' track call.
   *
   * @param PlanSelectorMainCtaClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  planSelectorMainCtaClicked,
  /**
   * Fires a 'PlanSelectorPlanClicked' track call.
   *
   * @param PlanSelectorPlanClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  planSelectorPlanClicked,
  /**
   * Fires a 'PlanSelectorPlanCycleClicked' track call.
   *
   * @param PlanSelectorPlanCycleClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  planSelectorPlanCycleClicked,
  /**
   * Fires a 'PlanSelectorViewed' track call.
   *
   * @param PlanSelectorViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  planSelectorViewed,
  /**
   * Fires a 'PostCardActionClicked' track call.
   *
   * @param PostCardActionClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  postCardActionClicked,
  /**
   * Fires a 'PostCreated' track call.
   *
   * @param PostCreated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  postCreated,
  /**
   * Fires a 'PostDeleted' track call.
   *
   * @param PostDeleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  postDeleted,
  /**
   * Fires a 'PostDragged' track call.
   *
   * @param PostDragged props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  postDragged,
  /**
   * Fires a 'PostEdited' track call.
   *
   * @param PostEdited props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  postEdited,
  /**
   * Fires a 'PostFailed' track call.
   *
   * @param PostFailed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  postFailed,
  /**
   * Fires a 'PostFailedNotified' track call.
   *
   * @param PostFailedNotified props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  postFailedNotified,
  /**
   * Fires a 'PostInsightsOrderReversed' track call.
   *
   * @param PostInsightsOrderReversed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  postInsightsOrderReversed,
  /**
   * Fires a 'PostInsightsSorted' track call.
   *
   * @param PostInsightsSorted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  postInsightsSorted,
  /**
   * Fires a 'PostMadeDraft' track call.
   *
   * @param PostMadeDraft props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  postMadeDraft,
  /**
   * Fires a 'PostMadeDraftFailed' track call.
   *
   * @param PostMadeDraftFailed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  postMadeDraftFailed,
  /**
   * Fires a 'PostRescheduleFailed' track call.
   *
   * @param PostRescheduleFailed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  postRescheduleFailed,
  /**
   * Fires a 'PostRescheduled' track call.
   *
   * @param PostRescheduled props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  postRescheduled,
  /**
   * Fires a 'PostSavedToFinishLater' track call.
   *
   * @param PostSavedToFinishLater props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  postSavedToFinishLater,
  /**
   * Fires a 'PostSelected' track call.
   *
   * @param PostSelected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  postSelected,
  /**
   * Fires a 'PostSent' track call.
   *
   * @param PostSent props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  postSent,
  /**
   * Fires a 'PostSentNotified' track call.
   *
   * @param PostSentNotified props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  postSentNotified,
  /**
   * Fires a 'PostSharedNow' track call.
   *
   * @param PostSharedNow props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  postSharedNow,
  /**
   * Fires a 'PostStuckInProcessing' track call.
   *
   * @param PostStuckInProcessing props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  postStuckInProcessing,
  /**
   * Fires a 'PostSwapped' track call.
   *
   * @param PostSwapped props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  postSwapped,
  /**
   * Fires a 'PostValidationFailed' track call.
   *
   * @param PostValidationFailed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  postValidationFailed,
  /**
   * Fires a 'PostingDayToggled' track call.
   *
   * @param PostingDayToggled props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  postingDayToggled,
  /**
   * Fires a 'PostingGoalAchieved' track call.
   *
   * @param PostingGoalAchieved props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  postingGoalAchieved,
  /**
   * Fires a 'PostingGoalMissed' track call.
   *
   * @param PostingGoalMissed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  postingGoalMissed,
  /**
   * Fires a 'PostingGoalSet' track call.
   *
   * @param PostingGoalSet props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  postingGoalSet,
  /**
   * Fires a 'PostingGoalSkipped' track call.
   *
   * @param PostingGoalSkipped props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  postingGoalSkipped,
  /**
   * Fires a 'PostingPlanCreated' track call.
   *
   * @param PostingPlanCreated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  postingPlanCreated,
  /**
   * Fires a 'PostingScheduleCleared' track call.
   *
   * @param PostingScheduleCleared props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  postingScheduleCleared,
  /**
   * Fires a 'PostingTimeAdded' track call.
   *
   * @param PostingTimeAdded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  postingTimeAdded,
  /**
   * Fires a 'PostingTimeRemoved' track call.
   *
   * @param PostingTimeRemoved props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  postingTimeRemoved,
  /**
   * Fires a 'PostingTimeUpdated' track call.
   *
   * @param PostingTimeUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  postingTimeUpdated,
  /**
   * Fires a 'PostsLoaded' track call.
   *
   * @param PostsLoaded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  postsLoaded,
  /**
   * Fires a 'PostsReordered' track call.
   *
   * @param PostsReordered props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  postsReordered,
  /**
   * Fires a 'ProductSelected' track call.
   *
   * @param ProductSelected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  productSelected,
  /**
   * Fires a 'ProductSelectorViewed' track call.
   *
   * @param ProductSelectorViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  productSelectorViewed,
  /**
   * Fires a 'ProductSignup' track call.
   *
   * @param ProductSignup props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  productSignup,
  /**
   * Fires a 'ProductSolutionSelected' track call.
   *
   * @param ProductSolutionSelected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  productSolutionSelected,
  /**
   * Fires a 'PublishRevampToggle' track call.
   *
   * @param PublishRevampToggle props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  publishRevampToggle,
  /**
   * Fires a 'PushNotificationBounced' track call.
   *
   * @param PushNotificationBounced props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  pushNotificationBounced,
  /**
   * Fires a 'PushNotificationReceived' track call.
   *
   * @param PushNotificationReceived props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  pushNotificationReceived,
  /**
   * Fires a 'PushNotificationTapped' track call.
   *
   * @param PushNotificationTapped props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  pushNotificationTapped,
  /**
   * Fires a 'QuantityUpdateModalViewed' track call.
   *
   * @param QuantityUpdateModalViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  quantityUpdateModalViewed,
  /**
   * Fires a 'QueueShuffled' track call.
   *
   * @param QueueShuffled props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  queueShuffled,
  /**
   * Fires a 'QuickNavigatorActionSelected' track call.
   *
   * @param QuickNavigatorActionSelected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  quickNavigatorActionSelected,
  /**
   * Fires a 'QuickNavigatorOpened' track call.
   *
   * @param QuickNavigatorOpened props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  quickNavigatorOpened,
  /**
   * Fires a 'ReferralLinkCopied' track call.
   *
   * @param ReferralLinkCopied props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  referralLinkCopied,
  /**
   * Fires a 'ReferralPostStarted' track call.
   *
   * @param ReferralPostStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  referralPostStarted,
  /**
   * Fires a 'RefreshPinterestBoardsButtonClicked' track call.
   *
   * @param RefreshPinterestBoardsButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  refreshPinterestBoardsButtonClicked,
  /**
   * Fires a 'ReminderAppButtonTapped' track call.
   *
   * @param ReminderAppButtonTapped props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  reminderAppButtonTapped,
  /**
   * Fires a 'ReminderAppOpened' track call.
   *
   * @param ReminderAppOpened props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  reminderAppOpened,
  /**
   * Fires a 'ReminderCompleted' track call.
   *
   * @param ReminderCompleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  reminderCompleted,
  /**
   * Fires a 'ReminderCreated' track call.
   *
   * @param ReminderCreated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  reminderCreated,
  /**
   * Fires a 'ReminderDeleted' track call.
   *
   * @param ReminderDeleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  reminderDeleted,
  /**
   * Fires a 'RemindersPostPreviewViewClicked' track call.
   *
   * @param RemindersPostPreviewViewClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  remindersPostPreviewViewClicked,
  /**
   * Fires a 'RemindersSetupButtonClicked' track call.
   *
   * @param RemindersSetupButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  remindersSetupButtonClicked,
  /**
   * Fires a 'RemindersSetupCTAClicked' track call.
   *
   * @param RemindersSetupCTAClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  remindersSetupCTAClicked,
  /**
   * Fires a 'RemindersSetupCTAViewed' track call.
   *
   * @param RemindersSetupCTAViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  remindersSetupCTAViewed,
  /**
   * Fires a 'RemindersSetupCompleted' track call.
   *
   * @param RemindersSetupCompleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  remindersSetupCompleted,
  /**
   * Fires a 'RemindersSetupStarted' track call.
   *
   * @param RemindersSetupStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  remindersSetupStarted,
  /**
   * Fires a 'RemindersSetupStepViewed' track call.
   *
   * @param RemindersSetupStepViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  remindersSetupStepViewed,
  /**
   * Fires a 'ReportChartNoteEdited' track call.
   *
   * @param ReportChartNoteEdited props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  reportChartNoteEdited,
  /**
   * Fires a 'ReportCreated' track call.
   *
   * @param ReportCreated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  reportCreated,
  /**
   * Fires a 'ReportExported' track call.
   *
   * @param ReportExported props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  reportExported,
  /**
   * Fires a 'ReportModuleAdded' track call.
   *
   * @param ReportModuleAdded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  reportModuleAdded,
  /**
   * Fires a 'SchedulingTypeChanged' track call.
   *
   * @param SchedulingTypeChanged props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  schedulingTypeChanged,
  /**
   * Fires a 'ScreenViewed' track call.
   *
   * @param ScreenViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  screenViewed,
  /**
   * Fires a 'SentPostsShareAgainClicked' track call.
   *
   * @param SentPostsShareAgainClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  sentPostsShareAgainClicked,
  /**
   * Fires a 'ShopGridLinkAdded' track call.
   *
   * @param ShopGridLinkAdded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  shopGridLinkAdded,
  /**
   * Fires a 'ShopGridLinkRemoved' track call.
   *
   * @param ShopGridLinkRemoved props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  shopGridLinkRemoved,
  /**
   * Fires a 'ShopGridPageLinkCopied' track call.
   *
   * @param ShopGridPageLinkCopied props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  shopGridPageLinkCopied,
  /**
   * Fires a 'ShopGridPagePreviewed' track call.
   *
   * @param ShopGridPagePreviewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  shopGridPagePreviewed,
  /**
   * Fires a 'ShopGridPostURLUpdated' track call.
   *
   * @param ShopGridPostURLUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  shopGridPostURLUpdated,
  /**
   * Fires a 'SidebarAddChannelClicked' track call.
   *
   * @param SidebarAddChannelClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  sidebarAddChannelClicked,
  /**
   * Fires a 'SidebarChannelsFiltered' track call.
   *
   * @param SidebarChannelsFiltered props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  sidebarChannelsFiltered,
  /**
   * Fires a 'SingleChannelOpened' track call.
   *
   * @param SingleChannelOpened props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  singleChannelOpened,
  /**
   * Fires a 'StartPageAvatarEdited' track call.
   *
   * @param StartPageAvatarEdited props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  startPageAvatarEdited,
  /**
   * Fires a 'StartPageBackgroundEdited' track call.
   *
   * @param StartPageBackgroundEdited props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  startPageBackgroundEdited,
  /**
   * Fires a 'StartPageBanned' track call.
   *
   * @param StartPageBanned props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  startPageBanned,
  /**
   * Fires a 'StartPageBlockAdded' track call.
   *
   * @param StartPageBlockAdded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  startPageBlockAdded,
  /**
   * Fires a 'StartPageBlockDeleted' track call.
   *
   * @param StartPageBlockDeleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  startPageBlockDeleted,
  /**
   * Fires a 'StartPageBlockHidden' track call.
   *
   * @param StartPageBlockHidden props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  startPageBlockHidden,
  /**
   * Fires a 'StartPageBlockMadeVisible' track call.
   *
   * @param StartPageBlockMadeVisible props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  startPageBlockMadeVisible,
  /**
   * Fires a 'StartPageBlockMoved' track call.
   *
   * @param StartPageBlockMoved props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  startPageBlockMoved,
  /**
   * Fires a 'StartPageCreated' track call.
   *
   * @param StartPageCreated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  startPageCreated,
  /**
   * Fires a 'StartPageDeleted' track call.
   *
   * @param StartPageDeleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  startPageDeleted,
  /**
   * Fires a 'StartPageExplainerCTATapped' track call.
   *
   * @param StartPageExplainerCTATapped props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  startPageExplainerCTATapped,
  /**
   * Fires a 'StartPageExplainerOpened' track call.
   *
   * @param StartPageExplainerOpened props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  startPageExplainerOpened,
  /**
   * Fires a 'StartPageExplainerViewed' track call.
   *
   * @param StartPageExplainerViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  startPageExplainerViewed,
  /**
   * Fires a 'StartPageGIFSelected' track call.
   *
   * @param StartPageGIFSelected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  startPageGIFSelected,
  /**
   * Fires a 'StartPageHeaderEdited' track call.
   *
   * @param StartPageHeaderEdited props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  startPageHeaderEdited,
  /**
   * Fires a 'StartPagePlanLimitHit' track call.
   *
   * @param StartPagePlanLimitHit props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  startPagePlanLimitHit,
  /**
   * Fires a 'StartPagePostBlockedUserActionRequired' track call.
   *
   * @param StartPagePostBlockedUserActionRequired props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  startPagePostBlockedUserActionRequired,
  /**
   * Fires a 'StartPagePublished' track call.
   *
   * @param StartPagePublished props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  startPagePublished,
  /**
   * Fires a 'StartPageStatisticsViewed' track call.
   *
   * @param StartPageStatisticsViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  startPageStatisticsViewed,
  /**
   * Fires a 'StartPageThemeEdited' track call.
   *
   * @param StartPageThemeEdited props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  startPageThemeEdited,
  /**
   * Fires a 'StartPageThemeSelected' track call.
   *
   * @param StartPageThemeSelected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  startPageThemeSelected,
  /**
   * Fires a 'StartPageURLCopied' track call.
   *
   * @param StartPageURLCopied props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  startPageURLCopied,
  /**
   * Fires a 'StartPageUnpublished' track call.
   *
   * @param StartPageUnpublished props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  startPageUnpublished,
  /**
   * Fires a 'StartPageUsernameConfirmed' track call.
   *
   * @param StartPageUsernameConfirmed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  startPageUsernameConfirmed,
  /**
   * Fires a 'StartPageUsernameSeleted' track call.
   *
   * @param StartPageUsernameSeleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  startPageUsernameSeleted,
  /**
   * Fires a 'StartPageUsernameUpdated' track call.
   *
   * @param StartPageUsernameUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  startPageUsernameUpdated,
  /**
   * Fires a 'StartTrialModalMainCtaClicked' track call.
   *
   * @param StartTrialModalMainCtaClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  startTrialModalMainCtaClicked,
  /**
   * Fires a 'StartTrialModalViewed' track call.
   *
   * @param StartTrialModalViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  startTrialModalViewed,
  /**
   * Fires a 'StickerCopied' track call.
   *
   * @param StickerCopied props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  stickerCopied,
  /**
   * Fires a 'StickerDeselected' track call.
   *
   * @param StickerDeselected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  stickerDeselected,
  /**
   * Fires a 'StickerSelected' track call.
   *
   * @param StickerSelected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  stickerSelected,
  /**
   * Fires a 'StoriesInsightsMetricsCompared' track call.
   *
   * @param StoriesInsightsMetricsCompared props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  storiesInsightsMetricsCompared,
  /**
   * Fires a 'StoriesInsightsSorted' track call.
   *
   * @param StoriesInsightsSorted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  storiesInsightsSorted,
  /**
   * Fires a 'StoryDragged' track call.
   *
   * @param StoryDragged props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  storyDragged,
  /**
   * Fires a 'StoryGroupComposerOpened' track call.
   *
   * @param StoryGroupComposerOpened props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  storyGroupComposerOpened,
  /**
   * Fires a 'StoryGroupCreated' track call.
   *
   * @param StoryGroupCreated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  storyGroupCreated,
  /**
   * Fires a 'StoryGroupPreviewed' track call.
   *
   * @param StoryGroupPreviewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  storyGroupPreviewed,
  /**
   * Fires a 'StoryGroupUpdated' track call.
   *
   * @param StoryGroupUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  storyGroupUpdated,
  /**
   * Fires a 'StoryImageCropped' track call.
   *
   * @param StoryImageCropped props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  storyImageCropped,
  /**
   * Fires a 'StoryImageDownloaded' track call.
   *
   * @param StoryImageDownloaded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  storyImageDownloaded,
  /**
   * Fires a 'StoryNonconformingImageUploaded' track call.
   *
   * @param StoryNonconformingImageUploaded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  storyNonconformingImageUploaded,
  /**
   * Fires a 'StoryNoteAdded' track call.
   *
   * @param StoryNoteAdded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  storyNoteAdded,
  /**
   * Fires a 'StoryNoteCopied' track call.
   *
   * @param StoryNoteCopied props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  storyNoteCopied,
  /**
   * Fires a 'StoryOpenedInChannel' track call.
   *
   * @param StoryOpenedInChannel props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  storyOpenedInChannel,
  /**
   * Fires a 'StoryResumedInChannel' track call.
   *
   * @param StoryResumedInChannel props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  storyResumedInChannel,
  /**
   * Fires a 'StreakButtonClicked' track call.
   *
   * @param StreakButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  streakButtonClicked,
  /**
   * Fires a 'StreakCountIncreased' track call.
   *
   * @param StreakCountIncreased props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  streakCountIncreased,
  /**
   * Fires a 'StreakCountReset' track call.
   *
   * @param StreakCountReset props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  streakCountReset,
  /**
   * Fires a 'StreakStarted' track call.
   *
   * @param StreakStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  streakStarted,
  /**
   * Fires a 'StreakUpdated' track call.
   *
   * @param StreakUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  streakUpdated,
  /**
   * Fires a 'StripeCheckoutSessionCreated' track call.
   *
   * @param StripeCheckoutSessionCreated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  stripeCheckoutSessionCreated,
  /**
   * Fires a 'SubscriptionAutomaticTaxUpdated' track call.
   *
   * @param SubscriptionAutomaticTaxUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  subscriptionAutomaticTaxUpdated,
  /**
   * Fires a 'SubscriptionChannelQuantityChanged' track call.
   *
   * @param SubscriptionChannelQuantityChanged props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  subscriptionChannelQuantityChanged,
  /**
   * Fires a 'SubscriptionDowngraded' track call.
   *
   * @param SubscriptionDowngraded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  subscriptionDowngraded,
  /**
   * Fires a 'SubscriptionEnded' track call.
   *
   * @param SubscriptionEnded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  subscriptionEnded,
  /**
   * Fires a 'SubscriptionPlanChanged' track call.
   *
   * @param SubscriptionPlanChanged props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  subscriptionPlanChanged,
  /**
   * Fires a 'SubscriptionScheduleCreated' track call.
   *
   * @param SubscriptionScheduleCreated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  subscriptionScheduleCreated,
  /**
   * Fires a 'SubscriptionScheduleRemoved' track call.
   *
   * @param SubscriptionScheduleRemoved props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  subscriptionScheduleRemoved,
  /**
   * Fires a 'SubscriptionScheduleUpdated' track call.
   *
   * @param SubscriptionScheduleUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  subscriptionScheduleUpdated,
  /**
   * Fires a 'SubscriptionStarted' track call.
   *
   * @param SubscriptionStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  subscriptionStarted,
  /**
   * Fires a 'SubscriptionUpgraded' track call.
   *
   * @param SubscriptionUpgraded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  subscriptionUpgraded,
  /**
   * Fires a 'SupportSatisfactionRatingSubmitted' track call.
   *
   * @param SupportSatisfactionRatingSubmitted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  supportSatisfactionRatingSubmitted,
  /**
   * Fires a 'SupportTicketCreated' track call.
   *
   * @param SupportTicketCreated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  supportTicketCreated,
  /**
   * Fires a 'SupportTicketReplySent' track call.
   *
   * @param SupportTicketReplySent props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  supportTicketReplySent,
  /**
   * Fires a 'SupportTicketUpdated' track call.
   *
   * @param SupportTicketUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  supportTicketUpdated,
  /**
   * Fires a 'TagCreated' track call.
   *
   * @param TagCreated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  tagCreated,
  /**
   * Fires a 'TagDeleted' track call.
   *
   * @param TagDeleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  tagDeleted,
  /**
   * Fires a 'TagEdited' track call.
   *
   * @param TagEdited props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  tagEdited,
  /**
   * Fires a 'TagItemAdded' track call.
   *
   * @param TagItemAdded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  tagItemAdded,
  /**
   * Fires a 'TagItemRemoved' track call.
   *
   * @param TagItemRemoved props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  tagItemRemoved,
  /**
   * Fires a 'TagReportViewed' track call.
   *
   * @param TagReportViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  tagReportViewed,
  /**
   * Fires a 'TaxExemptUpdated' track call.
   *
   * @param TaxExemptUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  taxExemptUpdated,
  /**
   * Fires a 'TaxIDCreated' track call.
   *
   * @param TaxIDCreated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  taxIDCreated,
  /**
   * Fires a 'TaxIDDeleted' track call.
   *
   * @param TaxIDDeleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  taxIDDeleted,
  /**
   * Fires a 'TaxIDUpdated' track call.
   *
   * @param TaxIDUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  taxIDUpdated,
  /**
   * Fires a 'TeamMemberAdded' track call.
   *
   * @param TeamMemberAdded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  teamMemberAdded,
  /**
   * Fires a 'TeamMemberInviteLinkClicked' track call.
   *
   * @param TeamMemberInviteLinkClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  teamMemberInviteLinkClicked,
  /**
   * Fires a 'TeamMemberInvited' track call.
   *
   * @param TeamMemberInvited props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  teamMemberInvited,
  /**
   * Fires a 'TeamMemberRemoved' track call.
   *
   * @param TeamMemberRemoved props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  teamMemberRemoved,
  /**
   * Fires a 'TemplateImageSelected' track call.
   *
   * @param TemplateImageSelected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  templateImageSelected,
  /**
   * Fires a 'TestEventDoNotUse' track call.
   *
   * @param TestEventDoNotUse props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  testEventDoNotUse,
  /**
   * Fires a 'TestEventNumberTwoDoNotUse' track call.
   *
   * @param TestEventNumberTwoDoNotUse props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  testEventNumberTwoDoNotUse,
  /**
   * Fires a 'ThreadsAddThreadClicked' track call.
   *
   * @param ThreadsAddThreadClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  threadsAddThreadClicked,
  /**
   * Fires a 'ThreadsAddTweetClicked' track call.
   *
   * @param ThreadsAddTweetClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  threadsAddTweetClicked,
  /**
   * Fires a 'ThreadsEditAttempted' track call.
   *
   * @param ThreadsEditAttempted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  threadsEditAttempted,
  /**
   * Fires a 'TieredPricingDisabled' track call.
   *
   * @param TieredPricingDisabled props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  tieredPricingDisabled,
  /**
   * Fires a 'TieredPricingEnabled' track call.
   *
   * @param TieredPricingEnabled props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  tieredPricingEnabled,
  /**
   * Fires a 'TourExited' track call.
   *
   * @param TourExited props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  tourExited,
  /**
   * Fires a 'TourOpened' track call.
   *
   * @param TourOpened props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  tourOpened,
  /**
   * Fires a 'TourScreenViewed' track call.
   *
   * @param TourScreenViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  tourScreenViewed,
  /**
   * Fires a 'TrialChannelQuantityChanged' track call.
   *
   * @param TrialChannelQuantityChanged props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  trialChannelQuantityChanged,
  /**
   * Fires a 'TrialEnded' track call.
   *
   * @param TrialEnded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  trialEnded,
  /**
   * Fires a 'TrialExpiredModalMainCtaClicked' track call.
   *
   * @param TrialExpiredModalMainCtaClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  trialExpiredModalMainCtaClicked,
  /**
   * Fires a 'TrialExpiredModalViewed' track call.
   *
   * @param TrialExpiredModalViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  trialExpiredModalViewed,
  /**
   * Fires a 'TrialExtended' track call.
   *
   * @param TrialExtended props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  trialExtended,
  /**
   * Fires a 'TrialPlanChanged' track call.
   *
   * @param TrialPlanChanged props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  trialPlanChanged,
  /**
   * Fires a 'TrialSignupWelcomeModalViewed' track call.
   *
   * @param TrialSignupWelcomeModalViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  trialSignupWelcomeModalViewed,
  /**
   * Fires a 'TrialStarted' track call.
   *
   * @param TrialStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  trialStarted,
  /**
   * Fires a 'TrialWelcomeModalViewed' track call.
   *
   * @param TrialWelcomeModalViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  trialWelcomeModalViewed,
  /**
   * Fires a 'TwitterAuthorCreditInserted' track call.
   *
   * @param TwitterAuthorCreditInserted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  twitterAuthorCreditInserted,
  /**
   * Fires a 'TwoFactorAuthenticationBackupUpdated' track call.
   *
   * @param TwoFactorAuthenticationBackupUpdated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  twoFactorAuthenticationBackupUpdated,
  /**
   * Fires a 'TwoFactorAuthenticationDisabled' track call.
   *
   * @param TwoFactorAuthenticationDisabled props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  twoFactorAuthenticationDisabled,
  /**
   * Fires a 'TwoFactorAuthenticationEnabled' track call.
   *
   * @param TwoFactorAuthenticationEnabled props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  twoFactorAuthenticationEnabled,
  /**
   * Fires a 'URLPasted' track call.
   *
   * @param URLPasted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  uRLPasted,
  /**
   * Fires a 'UTMGeneratorCopyButtonClicked' track call.
   *
   * @param UTMGeneratorCopyButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  uTMGeneratorCopyButtonClicked,
  /**
   * Fires a 'UnsupportedChannelSelectionAttempt' track call.
   *
   * @param UnsupportedChannelSelectionAttempt props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  unsupportedChannelSelectionAttempt,
  /**
   * Fires a 'UpgradePathViewed' track call.
   *
   * @param UpgradePathViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  upgradePathViewed,
  /**
   * Fires a 'UpgradePlanOptionsScreenViewed' track call.
   *
   * @param UpgradePlanOptionsScreenViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  upgradePlanOptionsScreenViewed,
  /**
   * Fires a 'UploadAttempted' track call.
   *
   * @param UploadAttempted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  uploadAttempted,
  /**
   * Fires a 'UploadCompleted' track call.
   *
   * @param UploadCompleted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  uploadCompleted,
  /**
   * Fires a 'UploadFailed' track call.
   *
   * @param UploadFailed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  uploadFailed,
  /**
   * Fires a 'UploadStarted' track call.
   *
   * @param UploadStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  uploadStarted,
  /**
   * Fires a 'UploadValidationFailed' track call.
   *
   * @param UploadValidationFailed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  uploadValidationFailed,
  /**
   * Fires a 'UploadcareEditClicked' track call.
   *
   * @param UploadcareEditClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  uploadcareEditClicked,
  /**
   * Fires a 'UploadcareIntegrationButtonClicked' track call.
   *
   * @param UploadcareIntegrationButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  uploadcareIntegrationButtonClicked,
  /**
   * Fires a 'UploadcareModalOpened' track call.
   *
   * @param UploadcareModalOpened props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  uploadcareModalOpened,
  /**
   * Fires a 'UploadcareUploadFinished' track call.
   *
   * @param UploadcareUploadFinished props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  uploadcareUploadFinished,
  /**
   * Fires a 'UploadcareUploadStarted' track call.
   *
   * @param UploadcareUploadStarted props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  uploadcareUploadStarted,
  /**
   * Fires a 'VideoPlayed' track call.
   *
   * @param VideoPlayed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  videoPlayed,
  /**
   * Fires a 'ViewCampaignReportButtonClicked' track call.
   *
   * @param ViewCampaignReportButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  viewCampaignReportButtonClicked,
  /**
   * Fires a 'WelcomeChecklistViewed' track call.
   *
   * @param WelcomeChecklistViewed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  welcomeChecklistViewed,
  /**
   * Fires a 'WhatsNewDialogTapped' track call.
   *
   * @param WhatsNewDialogTapped props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  whatsNewDialogTapped,
  /**
   * Fires a 'WidgetAdded' track call.
   *
   * @param WidgetAdded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  widgetAdded,
  /**
   * Fires a 'WidgetRemoved' track call.
   *
   * @param WidgetRemoved props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  widgetRemoved,
}

export default new Proxy<typeof clientAPI>(clientAPI, {
  get(target, method) {
    if (typeof method === 'string' && target.hasOwnProperty(method)) {
      return target[method as keyof typeof clientAPI]
    }

    return () => {
      console.warn(`⚠️  You made an analytics call (${String(
        method,
      )}) that can't be found. Either:
         a) Re-generate your typewriter client: \`npx typewriter\`
         b) Add it to your Tracking Plan: https://app.segment.com/segment-oscb/protocols/tracking-plans/rs_1zTHJU9fd5mt7cndWnd4PgJbMCE`)
      const a = analytics()
      if (a) {
        a.track(
          'Unknown Analytics Call Fired',
          {
            method,
          },
          withTypewriterContext(),
        )
      }
    }
  },
})
