import React from 'react'

import {
  ArrowDownUpIcon,
  Avatar,
  BufferIcon,
  Button,
  Card,
  ChannelAvatar,
  ChannelIcon,
  type ChannelType,
  CheckIcon,
  Dialog,
  Flex,
  Heading,
  SuccessDialog,
  Text,
  WarningIcon,
} from '@buffer-mono/popcorn'

import styles from './NewChannelRefreshSuccess.module.css'
import { useTranslation } from '../../../../../../common/i18n'
import { getServiceName } from '../../../../../../exports/Orchestrator/channelConnections/utils'
import type { Service } from '../../../../../../exports/Orchestrator/channelConnections/types'
import capitalize from 'lodash/capitalize'
import type { Channel } from '../../../../../../common/types'
import { Trans } from 'react-i18next'

type NewChannelRefreshSuccessDialogProps = {
  handleOnDismissModal: () => void
  usersChannelList: Channel[]
  socialChannelIds: string[]
  intendedChannelIdForRefresh?: string | null
  CTACopy: string
  handleCreatePostCTA: (args: {
    socialChannelId: string
    handleOnDismissModal: () => void
  }) => void
}

const DEFAULT_AVATAR = 'https://s3.amazonaws.com/buffer-ui/Default+Avatar.png'

function handleForceRefresh(intendedChannelToRefresh: Channel): void {
  if (window.appshell?.actions) {
    const { actions } = window.appshell || {}

    actions.connectChannel({
      // TODO: when selectedService in appshell uses the gql type, this error will be fixed
      // eslint-disable-next-line
      // @ts-expect-error
      selectedService: intendedChannelToRefresh.service,
      isRefreshingConnection: true,
      cta: 'forceRefresh',
      name: intendedChannelToRefresh.name,
      selectedRefreshChannelId: intendedChannelToRefresh.id,
      authMethod: intendedChannelToRefresh.apiVersion || undefined,
      skipChannelCheck: true,
    })
  }
}

const NewChannelRefreshSuccessDialog = ({
  handleOnDismissModal,
  usersChannelList,
  socialChannelIds,
  intendedChannelIdForRefresh,
  CTACopy,
  handleCreatePostCTA,
}: NewChannelRefreshSuccessDialogProps): JSX.Element => {
  const { t } = useTranslation()
  const refreshedChannels = usersChannelList.filter((channel) =>
    socialChannelIds.includes(channel.id),
  )
  const serviceName = getServiceName(refreshedChannels[0].service as Service)

  const wrongChannelRefreshed =
    intendedChannelIdForRefresh &&
    !socialChannelIds.includes(intendedChannelIdForRefresh)

  if (wrongChannelRefreshed) {
    const channel = refreshedChannels[0]
    const intendedChannelToRefresh = usersChannelList.find(
      (channel) => channel.id === intendedChannelIdForRefresh,
    ) as Channel

    return (
      <Dialog open={true} onOpenChange={handleOnDismissModal}>
        <Dialog.Content className={styles.dialog} size="xlarge">
          <Flex gap="sm" align="center" justify="center">
            <BufferIcon />
            <ArrowDownUpIcon className={styles.arrowsIcon} color="subtle" />
            <ChannelIcon
              className={styles.channelIcon}
              type={channel.service as ChannelType}
              color="inverted"
              size="xsmall"
            />
          </Flex>
          <Dialog.CloseButton onClick={handleOnDismissModal} />
          <Dialog.Header className={styles.header}>
            <div className={styles.warningIconWrapper}>
              <WarningIcon
                color="warning"
                size="medium"
                className={styles.warningIcon}
              />
            </div>
            <Dialog.Title>
              {t('refreshSuccessModal.wrongChannel.title', {
                serviceName,
              })}
            </Dialog.Title>
            <Dialog.Description>
              <Trans
                i18nKey="refreshSuccessModal.wrongChannel.description"
                values={{
                  serviceName,
                  accountName: intendedChannelToRefresh.name,
                }}
                components={{
                  bold: <span className={styles.channelName} />,
                }}
              />
            </Dialog.Description>
          </Dialog.Header>
          <Dialog.Body className={styles.body}>
            <Card className={styles.card}>
              <ChannelAvatar
                alt={intendedChannelToRefresh.name}
                channel={intendedChannelToRefresh.service as ChannelType}
                src={intendedChannelToRefresh.avatar}
              />
              <div className={styles.channelInfo}>
                <Text weight="bold">{intendedChannelToRefresh.name}</Text>
                <Text color="subtle">
                  {capitalize(intendedChannelToRefresh.type)}
                </Text>
              </div>
              <Button
                onClick={(): void => {
                  handleForceRefresh(intendedChannelToRefresh)
                }}
              >
                {t('refreshSuccessModal.wrongChannel.refresh')}
              </Button>
            </Card>
            <div className={styles.footerInfo}>
              <span>You are currently signed in as</span>
              <Avatar alt={channel.name} src={channel.avatar} size="xsmall" />
              <span className={styles.channelName}>{channel.name}</span>
              <span>on</span>
              <ChannelIcon
                className={styles.channelIcon}
                type={channel.service as ChannelType}
                color="inverted"
                size={12}
              />
            </div>
          </Dialog.Body>

          <Dialog.Separator />
          <Dialog.Footer>
            <Button variant="secondary" onClick={handleOnDismissModal}>
              {t('refreshSuccessModal.wrongChannel.dismiss')}
            </Button>
          </Dialog.Footer>
        </Dialog.Content>
      </Dialog>
    )
  }

  return (
    <SuccessDialog open={true} onOpenChange={handleOnDismissModal}>
      <SuccessDialog.Content className={styles.content}>
        <div className={styles.logoAvatarWrapper}>
          <Card className={styles.logoWrapper}>
            <BufferIcon size="large" color="brand" />
          </Card>
          <div className={styles.checkIconWrapper}>
            <CheckIcon
              color="inverted"
              size="small"
              className={styles.checkIcon}
            />
          </div>
          <div className={styles.avatarChannelIconWrapper}>
            {[...refreshedChannels].reverse().map((channel) => (
              <ChannelAvatar
                className={styles.avatar}
                key={channel.id}
                src={channel?.avatar || DEFAULT_AVATAR}
                alt={channel?.name}
                size="large"
                channel={channel?.service as ChannelType}
              />
            ))}
          </div>
        </div>
        <SuccessDialog.Title className={styles.title}>
          <Heading size="large">
            {t('refreshSuccessModal.title', {
              count: refreshedChannels.length,
              serviceName,
            })}
          </Heading>
        </SuccessDialog.Title>
        <SuccessDialog.Description className={styles.description}>
          <Text>
            {t('refreshSuccessModal.description', {
              count: refreshedChannels.length,
              serviceName,
            })}
          </Text>
        </SuccessDialog.Description>
        <SuccessDialog.Actions>
          <Button
            id="create_a_post"
            className={styles.button}
            variant="primary"
            size="large"
            onClick={(): void =>
              handleCreatePostCTA({
                socialChannelId:
                  intendedChannelIdForRefresh || socialChannelIds[0],
                handleOnDismissModal,
              })
            }
          >
            {CTACopy}
          </Button>
        </SuccessDialog.Actions>
      </SuccessDialog.Content>
    </SuccessDialog>
  )
}

export default NewChannelRefreshSuccessDialog
