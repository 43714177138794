import React from 'react'
import {
  AlertDialog,
  Button,
  Card,
  ChannelAvatar,
  type ChannelType,
  DropdownMenu,
  ExternalLinkIcon,
  FacebookIcon,
  Flex,
  LogOutIcon,
  PencilIcon,
  RefreshCwIcon,
  SettingsIcon,
  Text,
  toast,
  TrashIcon,
} from '@buffer-mono/popcorn'
import { getStartPageUrl } from '@buffer-mono/app-shell/src/common/utils/urls'
import { Status } from '../util/getChannelStatus'
import { AccountType } from '@buffer-mono/app-shell/src/common/events/types'
import { BufferTrackerReact } from '@buffer-mono/tracking-plan'
import {
  InstagramAuthMethod,
  Service,
} from '@buffer-mono/app-shell/src/exports/Orchestrator/channelConnections/types'
import { type ChannelMask, GetChannels } from '../hooks/useChannelsForSettings'
import { graphql } from '~publish/graphql'
import { getChannelName } from '../../../helpers/channels'

import style from './ChannelDropdownItems.module.css'
import { useMutation } from '@apollo/client'
import { logError } from '~publish/legacy/utils/logError'
import { isStartPage } from '../util/isStartPage'
import usePrivilege from '../hooks/usePrivilege'
import { Link } from 'react-router-dom'

const RefreshConnectionMenuItem = ({
  channel,
  refreshChannelConnection,
}: {
  channel: ChannelMask
  refreshChannelConnection: (cta: string) => void
}): JSX.Element => {
  const canManageChannels = usePrivilege('canManageChannels')

  return (
    <DropdownMenu.Item
      onClick={(): void => {
        canManageChannels &&
          refreshChannelConnection(
            `publish-channels-menuItem-${channel.service.toLowerCase()}-1`,
          )
      }}
      disabled={!canManageChannels}
    >
      <RefreshCwIcon />
      Refresh Connection
    </DropdownMenu.Item>
  )
}

export const RemoveChannelsMutation = graphql(/* GraphQL */ `
  mutation removeChannels($channelIds: [String!]) {
    channelsRemove(channelIds: $channelIds)
  }
`)

const DisconnectChannelAlertDialog = ({
  channel,
  children,
}: {
  channel: ChannelMask
  children: React.ReactNode
}): JSX.Element => {
  const isChannelStartPage = isStartPage({ channel })
  const [executeRemoveChannels, { loading }] = useMutation(
    RemoveChannelsMutation,
  )

  const successMessage = isChannelStartPage
    ? 'Channel deleted successfully!'
    : 'Channel disconnected successfully!'

  const errorMessage = isChannelStartPage
    ? 'Failed to delete channel'
    : 'Failed to disconnect channel'

  const handleConfirmDisconnect = async (): Promise<void> => {
    try {
      const { data, errors } = await executeRemoveChannels({
        variables: {
          channelIds: [channel.id],
        },
        refetchQueries: [GetChannels],
      })

      if (data?.channelsRemove) {
        toast.success(successMessage)
      }

      if (errors?.length) {
        toast.error(errorMessage)
        const unexpectedError = new Error(
          errors?.[0]?.message || 'Unexpected API response',
        )
        logError(unexpectedError, { metaData: { data, errors } })
      }
    } catch (e) {
      toast.error(errorMessage)
      logError(e as Error, {
        metaData: { variables: { channel } },
      })
    }
  }

  return (
    <AlertDialog>
      <AlertDialog.Trigger>{children}</AlertDialog.Trigger>
      <AlertDialog.Content>
        <AlertDialog.Title>
          {isChannelStartPage ? 'Delete Start Page' : 'Disconnect Channel'}
        </AlertDialog.Title>
        <AlertDialog.Description className={style.body}>
          {isChannelStartPage ? (
            <Text>
              Your Start Page will be immediately deleted and your data will be
              lost. Visitors will no longer be able to access your page using
              the link.
            </Text>
          ) : (
            <>
              <Text>
                You will no longer be able to post to this account, all existing
                posts, analytics, tags, and{' '}
                <Text weight="bold">
                  data will be deleted and cannot be recovered. This action
                  cannot be undone.
                </Text>
              </Text>

              <Text>
                Having issues with your connection? We advise using the refresh
                connection option.
              </Text>
            </>
          )}

          <Flex as="section">
            <Card padding="small" className={style.channelCard}>
              <ChannelAvatar
                src={channel.avatar}
                alt={channel.displayName || channel.name}
                channel={channel.service as ChannelType}
                size="small"
              />
              <Flex direction="column">
                <Text weight="bold">{channel.name}</Text>
                <Text color="subtle">
                  {isChannelStartPage ? 'Start Page' : channel.descriptor}
                </Text>
              </Flex>
            </Card>
          </Flex>
        </AlertDialog.Description>
        <AlertDialog.Separator />
        <AlertDialog.Actions>
          <AlertDialog.Cancel>
            <Button variant="tertiary" size="large">
              Cancel
            </Button>
          </AlertDialog.Cancel>
          <AlertDialog.Action>
            <Button
              variant="critical"
              size="large"
              loading={loading}
              onClick={(): void => {
                handleConfirmDisconnect()
              }}
            >
              {isChannelStartPage ? 'Confirm and Delete' : 'Disconnect Channel'}
            </Button>
          </AlertDialog.Action>
        </AlertDialog.Actions>
      </AlertDialog.Content>
    </AlertDialog>
  )
}

const StartPageMenuItems = ({
  channel,
  status,
}: {
  channel: ChannelMask
  status: Status
}): JSX.Element => {
  const canManageChannels = usePrivilege('canManageChannels')
  return (
    <>
      <DropdownMenu.Item
        onClick={(): void => {
          canManageChannels &&
            window.location.assign(getStartPageUrl(`${channel.id}`))
        }}
        disabled={!canManageChannels}
      >
        <PencilIcon />
        Edit Start Page
      </DropdownMenu.Item>
      {status !== Status.Locked && (
        <DropdownMenu.Item
          onClick={(): void => {
            canManageChannels &&
              window.location.assign(getStartPageUrl(`${channel.id}/settings`))
          }}
          disabled={!canManageChannels}
        >
          <LogOutIcon />
          Unpublish Channel
        </DropdownMenu.Item>
      )}
    </>
  )
}

const FacebookGroupMenuItems = ({
  channel,
}: {
  channel: ChannelMask
}): JSX.Element => {
  const canManageChannels = usePrivilege('canManageChannels')
  return (
    <>
      <DropdownMenu.Item
        onClick={(): void => {
          const { MODALS, actions } = window?.appshell || {}
          canManageChannels &&
            actions.openModal(MODALS.facebookSelectAccountType, {
              accountType: AccountType.group,
              isRefreshingConnection: true,
              channelId: channel.id,
              channelName: channel.name,
              channelUrl:
                channel.metadata?.__typename === 'FacebookMetadata'
                  ? channel.metadata.locationData?.location ?? undefined
                  : undefined,
              channelAvatar: channel.avatar,
            })
        }}
        disabled={!canManageChannels}
      >
        <PencilIcon />
        Edit Group
      </DropdownMenu.Item>
    </>
  )
}

const InstagramPersonalProfileMenuItems = ({
  channel,
}: {
  channel: ChannelMask
}): JSX.Element => {
  const { MODALS, actions } = window?.appshell || {}
  const canManageChannels = usePrivilege('canManageChannels')
  return (
    <>
      <DropdownMenu.Item
        onClick={(): void => {
          canManageChannels &&
            actions.openModal(MODALS.instagramSelectAccountType, {
              accountType: AccountType.personal,
              isRefreshingConnection: true,
              channelId: channel.id,
              channelName: channel.name,
              channelAvatar: channel.avatar,
            })
        }}
        disabled={!canManageChannels}
      >
        <PencilIcon />
        Edit Personal Account
      </DropdownMenu.Item>
      <DropdownMenu.Item
        onClick={(): void => {
          canManageChannels &&
            BufferTrackerReact.channelConversionStarted({
              channel: 'instagram',
              clientName: 'publishWeb',
              cta: 'publish-channels-menuItem-switchToAProfessionalAccount-1',
              organizationId: channel.organizationId,
            })

          canManageChannels &&
            actions.connectChannel({
              selectedService: Service.instagram,
              customChannelIdToConvert: channel.id,
              authMethod: InstagramAuthMethod.instagramLogin,
              cta: 'account-channels-menuItem-switchToAProfessionalAccount-1',
            })
        }}
        disabled={!canManageChannels}
      >
        <RefreshCwIcon />
        Switch to Professional Account
      </DropdownMenu.Item>
    </>
  )
}

const InstagramLinkFacebookPageMenuItem = ({
  channel,
}: {
  channel: ChannelMask
}): JSX.Element => {
  const { MODALS, actions } = window?.appshell || {}
  const canManageChannels = usePrivilege('canManageChannels')

  return (
    <DropdownMenu.Item
      onClick={(): void => {
        canManageChannels &&
          actions.openModal(MODALS.instagramFacebookReAuth, {
            channelId: channel.id,
            channelType: channel.type,
          })
      }}
      disabled={!canManageChannels}
    >
      <FacebookIcon />
      Link Account to Facebook Page
    </DropdownMenu.Item>
  )
}

interface ChannelDropdownItemProps {
  channel: ChannelMask
  status: Status
  refreshChannelConnection: (cta: string) => void
  showSettingsLink: boolean
}

const ChannelDropdownItems = ({
  channel,
  status,
  refreshChannelConnection,
  showSettingsLink,
}: ChannelDropdownItemProps): JSX.Element => {
  const canManageChannels = usePrivilege('canManageChannels')

  const isFacebookGroup =
    channel.service === 'facebook' && channel.type === 'group'
  const isInstagramPersonalProfile =
    channel.service === 'instagram' && channel.type === 'profile'
  const isChannelStartPage = isStartPage({ channel })
  const isInstagramLinkFacebookPageChannel =
    channel.service === 'instagram' && channel.apiVersion === 'instagramLogin'
  const shouldShowExternalLink = !!channel.externalLink

  const disconnectChannel = canManageChannels ? (
    <DisconnectChannelAlertDialog channel={channel}>
      <DropdownMenu.Item
        variant="critical"
        onSelect={(e): void => e.preventDefault()}
      >
        <TrashIcon />
        {isChannelStartPage ? 'Delete Start Page' : 'Disconnect Channel'}
      </DropdownMenu.Item>
    </DisconnectChannelAlertDialog>
  ) : (
    <DropdownMenu.Item onSelect={(e): void => e.preventDefault()} disabled>
      <TrashIcon />
      {isChannelStartPage ? 'Delete Start Page' : 'Disconnect Channel'}
    </DropdownMenu.Item>
  )

  const externalLink = (
    <DropdownMenu.Item asChild>
      <a
        href={channel.externalLink ?? undefined}
        target="_blank"
        rel="noopener noreferrer"
      >
        <ExternalLinkIcon />
        {isChannelStartPage
          ? 'View Start Page'
          : `View on ${getChannelName(channel.service)}`}
      </a>
    </DropdownMenu.Item>
  )

  const shouldOnlyShowDisconnect =
    status === Status.Locked &&
    !isChannelStartPage &&
    !isFacebookGroup &&
    !isInstagramPersonalProfile

  // Special cases for locked channels with only a disconnect option
  if (shouldOnlyShowDisconnect) {
    if (shouldShowExternalLink) {
      return (
        <>
          {externalLink}
          <DropdownMenu.Separator />
          {disconnectChannel}
        </>
      )
    }
    return disconnectChannel
  }

  return (
    <>
      {shouldShowExternalLink && externalLink}
      {showSettingsLink && (
        <DropdownMenu.Item asChild>
          <Link to={`/settings/channels/${channel.id}`}>
            <SettingsIcon />
            Channel Settings
          </Link>
        </DropdownMenu.Item>
      )}
      {isChannelStartPage && (
        <StartPageMenuItems channel={channel} status={status} />
      )}
      {isFacebookGroup && <FacebookGroupMenuItems channel={channel} />}
      {isInstagramPersonalProfile && (
        <InstagramPersonalProfileMenuItems channel={channel} />
      )}
      {isInstagramLinkFacebookPageChannel && (
        <InstagramLinkFacebookPageMenuItem channel={channel} />
      )}
      {status !== Status.Locked &&
        !isChannelStartPage &&
        !isFacebookGroup &&
        !isInstagramPersonalProfile && (
          <RefreshConnectionMenuItem
            channel={channel}
            refreshChannelConnection={refreshChannelConnection}
          />
        )}
      <DropdownMenu.Separator />
      {disconnectChannel}
    </>
  )
}

export default ChannelDropdownItems
