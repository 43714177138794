import React, { useEffect } from 'react'

import { EmptyState, SimpleSpinner } from '@buffer-mono/popcorn'

import { usePostComposer } from '~publish/hooks/usePostComposer'
import { useQueryParam } from '~publish/hooks/useQueryParam'
import { SEGMENT_NAMES } from '~publish/legacy/constants'
import { history } from '~publish/legacy/store'
import { calendarPage } from '~publish/legacy/routes'
import { useSelectedTags } from '~publish/hooks/useSelectedTags'
import { useCurrentOrganization } from '~publish/legacy/accountContext'

/**
 * New Post Page
 * This page technically doesn't do anything
 * However it is used as a url which can be redirected/navigated to in order to automatically open the Composer
 * with the option to pass pre selected channels to open the Composer with.
 *
 * The main use case for this is the Global Plus button and keyboard shortcuts
 * This route/page only renders for a moment
 * and then the user is taken to the calendar page with the composer opened
 *
 * pre selecting channels with the composer can be done by passing channel[]=channelid
 * inside the query params. If no ids are provided, the composer preselects all available channels by default
 */
export const NewPostPage = (): JSX.Element => {
  const { createNewPostInComposer } = usePostComposer()

  const channels = useCurrentOrganization()?.channels
  const channelsLength = channels?.length || 0
  const [channelIdsFromParams] = useQueryParam<string[]>('channelId')
  const [textFromParams] = useQueryParam<string>('text')
  const [pictureFromParams] = useQueryParam<string>('picture')
  const [ctaFromParams] = useQueryParam<string>('cta')
  const selectedTags = useSelectedTags()

  // We want to redirect behind the scenes to the calendar page when the composer is opened
  // This is a workaround for a "lazy loading" experience behind the composer
  useEffect(() => {
    const observer = new MutationObserver(() => {
      if (document.getElementById('composer-root')) {
        history.push(calendarPage.route)
      }
    })

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    })

    return () => observer.disconnect()
  }, [])

  useEffect(() => {
    // Check that channels have loaded in case we need to open the composer with all channels
    if (!channels && !channelsLength) return
    // If there are channelIds from params, use them, otherwise use all channels if there are less than 3
    const selectedProfileIds =
      channelIdsFromParams || (channelsLength > 3 ? [] : 'all')
    createNewPostInComposer({
      cta:
        (ctaFromParams as CTA) || (SEGMENT_NAMES.CALENDAR_CREATE_A_POST as CTA),
      channels: selectedProfileIds,
      prefillPostData: {
        tags: selectedTags,
        text: textFromParams,
        media: pictureFromParams
          ? {
              thumbnail: pictureFromParams,
              source: pictureFromParams,
              picture: pictureFromParams,
              // TODO: Review if we should use the actual width and height of the image
              // This is currently hardcoded because the types are mandatory and we don't have a way to get the actual width and height of the image
              width: '',
              height: '',
              progress: '100',
              uploaded: 'true',
            }
          : undefined,
      },
    })
  }, [
    channelIdsFromParams,
    createNewPostInComposer,
    selectedTags,
    textFromParams,
    pictureFromParams,
    channels,
    channelsLength,
    ctaFromParams,
  ])

  return (
    <EmptyState size="medium">
      <EmptyState.Icon>
        <SimpleSpinner size="medium" />
      </EmptyState.Icon>
      <EmptyState.Heading>Setting up your post...</EmptyState.Heading>
    </EmptyState>
  )
}
