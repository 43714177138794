import React, { useState, useEffect, useCallback } from 'react'
import {
  Button,
  Card,
  ChannelAvatar,
  EmptyState,
  Flex,
  IconButton,
  ListIcon,
  PencilIcon,
  PlusIcon,
  SimpleSpinner,
  Text,
  Heading,
  CriticalIcon,
  Badge,
  type ChannelType,
} from '@buffer-mono/popcorn'

import { useAppDispatch } from '~publish/legacy/store'
import { PageLayout } from '~publish/components/PageLayout'
import { useChannels } from '~publish/components/PublishSidebar/useChannels'

import { ChannelGroupDialog } from './ChannelGroupDialog'
import { DeleteGroupButton } from './DeleteGroupButton'
import { getGroups } from './thunks'

import styles from './ChannelGroups.module.css'

export type ChannelGroup = {
  id: string
  name: string
  profile_ids: string[]
}

export const ChannelGroups = (): JSX.Element => {
  const [error, setError] = useState<string | null>(null)
  const [channelGroups, setChannelGroups] = useState<ChannelGroup[]>([])
  const [loading, setLoading] = useState(false)

  const dispatch = useAppDispatch()
  const { channels } = useChannels()

  const fetchProfileGroups = useCallback(async () => {
    try {
      setError(null)
      setLoading(true)
      const actionResult = await dispatch(getGroups())
      const groups = actionResult.payload as ChannelGroup[]
      setChannelGroups(groups)
    } catch (err) {
      setError(
        err instanceof Error ? err.message : 'Failed to fetch channel groups',
      )
    } finally {
      setLoading(false)
    }
  }, [dispatch])

  useEffect(() => {
    fetchProfileGroups()
  }, [fetchProfileGroups])

  if (error) {
    return (
      <EmptyState size="xlarge" variant="critical">
        <EmptyState.Icon>
          <CriticalIcon />
        </EmptyState.Icon>
        <EmptyState.Heading>Failed to load</EmptyState.Heading>
        <EmptyState.Description>
          An error happened, please let our team know about it.{' '}
          <Text color="critical">{error}</Text>
        </EmptyState.Description>
        <EmptyState.Actions>
          <Button onClick={fetchProfileGroups} size="large">
            Try Again
          </Button>
        </EmptyState.Actions>
      </EmptyState>
    )
  }

  if (loading)
    return (
      <div className={styles.loading}>
        <SimpleSpinner size="large" />
      </div>
    )

  if (!channelGroups || channelGroups.length === 0)
    return (
      <EmptyState>
        <EmptyState.Icon>
          <ListIcon />
        </EmptyState.Icon>
        <EmptyState.Heading>No channel groups found</EmptyState.Heading>
        <EmptyState.Description>
          Create a channel group to manage your channels
        </EmptyState.Description>
        <EmptyState.Actions>
          <ChannelGroupDialog onSaved={fetchProfileGroups}>
            <Button size="large">
              <PlusIcon /> New Group
            </Button>
          </ChannelGroupDialog>
        </EmptyState.Actions>
      </EmptyState>
    )

  return (
    <PageLayout>
      <PageLayout.Header>
        <PageLayout.HeaderRow>
          <PageLayout.Title>Channel Groups</PageLayout.Title>
          <PageLayout.Actions>
            <ChannelGroupDialog onSaved={fetchProfileGroups}>
              <Button size="large">
                <PlusIcon /> New Group
              </Button>
            </ChannelGroupDialog>
          </PageLayout.Actions>
        </PageLayout.HeaderRow>
      </PageLayout.Header>

      <PageLayout.Container className={styles.container}>
        {channelGroups.map((group: ChannelGroup) => (
          <Card key={group.id} id={group.id} className={styles.card}>
            <div className={styles.header}>
              <div className={styles.titleWrapper}>
                <Heading size="small">{group.name}</Heading>
                <Badge size="small">{group.profile_ids.length}</Badge>
              </div>
              <div className={styles.actions}>
                <ChannelGroupDialog
                  key={group.id}
                  channelGroup={group}
                  onSaved={fetchProfileGroups}
                >
                  <IconButton label="Edit" tooltip="Edit" variant="tertiary">
                    <PencilIcon />
                  </IconButton>
                </ChannelGroupDialog>
                <DeleteGroupButton
                  groupId={group.id}
                  onDeleted={fetchProfileGroups}
                />
              </div>
            </div>
            <div className={styles.channels}>
              {group.profile_ids?.map((profileId) => {
                const channel = channels.find((c) => c.id === profileId)
                if (!channel) return null
                return (
                  <Flex
                    id={profileId}
                    key={profileId}
                    direction="row"
                    align="center"
                    gap="sm"
                  >
                    <ChannelAvatar
                      src={channel.avatar}
                      alt={channel.name}
                      channel={channel.service as ChannelType}
                      size="small"
                    />
                    <Text>{channel.name}</Text>
                  </Flex>
                )
              })}
            </div>
          </Card>
        ))}
      </PageLayout.Container>
    </PageLayout>
  )
}
