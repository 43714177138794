import { nanoid } from 'nanoid/non-secure'

import { MediaType, UploadStatus } from '../constants'
import { getFileNameFromPath, getMimeAndExtensionFromName } from '../utils'
import { UploadMetadata } from '../values/UploadMetadata'
import { UploadProgress } from '../values/UploadProgress'
import { UploadSource } from '../values/UploadSource'
import type { VideoMetadata } from '../values/VideoMetadata'

type Modify<T, R> = Omit<T, keyof R> & R

type ImageDimensions = {
  width: number
  height: number
} | null

export type Upload = {
  id: string
  name: string
  status: UploadStatus
  type: string
  extension: string
  mediaType: MediaType
  uploaderId: string
  progress: UploadProgress
  size: number
  // TODO UPLOADS: consider removing these optional fields until they are mandatory because different
  //               statuses have different fields and we can avoid the Omit calls below
  uploadId?: string
  dimensions?: ImageDimensions
  videoMetadata?: VideoMetadata
  thumbnailUrl?: string
  bucket?: string
  key?: string
  url?: string
  alt?: string
  error?: string
  meta: UploadMetadata
}

export type FailedUpload = Omit<Upload, 'status' | 'error'> & {
  status: UploadStatus.Failed
  error: string
}

export type S3Upload = Omit<Upload, 'status' | 'bucket' | 'key' | 'url'> & {
  status: UploadStatus.UploadedToS3
  url: string
  key: string
  bucket?: string
}

export type VideoUpload = Modify<
  Upload,
  {
    url: string
    uploadId: string
    mediaType: MediaType.video
    thumbnailUrl: string
    videoMetadata: VideoMetadata
  }
>

export type ImageUpload = Modify<
  Upload,
  {
    url: string
    uploadId: string
    mediaType: MediaType.image
    dimensions: ImageDimensions
  }
>

export type DocumentUpload = Modify<
  Upload,
  {
    url: string
    uploadId: string
    mediaType: MediaType.document
    details: {
      title: string
      pageCount: number | null
      fileSizeBytes?: number
    }
    thumbnailUrl?: string
  }
>

export type GifUpload = Modify<
  ImageUpload,
  {
    mediaType: MediaType.gif
    thumbnailUrl: string
  }
>

export type BufferUpload =
  | VideoUpload
  | ImageUpload
  | GifUpload
  | DocumentUpload

export const Upload = {
  // no new method because we create uploads from UppyFiles
  // check UppyFile.toUpload method

  newFromMedia(
    media: {
      id: string
      url: string
      alt?: string
      thumbnailUrl?: string
      size?: number
      source?: UploadSource
    },
    meta: UploadMetadata,
  ): Upload {
    const id = media.id || nanoid()
    const name = getFileNameFromPath(media.url)
    const {
      mimeType = '',
      mediaType,
      extension,
    } = getMimeAndExtensionFromName(name)

    if (media.source) {
      meta.source = UploadSource.new(media.source)
    }

    return {
      id,
      name,
      url: media.url,
      alt: media.alt,
      thumbnailUrl: media.thumbnailUrl,
      status: UploadStatus.Preloaded,
      type: mimeType,
      extension,
      mediaType,
      uploaderId: meta.uploaderId,
      progress: UploadProgress.new(0),
      size: media.size || 0,
      uploadId: id,
      meta: UploadMetadata.new(meta),
    }
  },

  getSource(upload: Upload): UploadSource | undefined {
    return upload.meta.source
  },

  isCompleted(upload?: Upload): upload is BufferUpload {
    const completedStatuses = [UploadStatus.Completed, UploadStatus.Preloaded]

    return !!upload && completedStatuses.includes(upload.status)
  },

  isPending(upload: Upload): boolean {
    const uploadingStatuses = [
      UploadStatus.Added,
      UploadStatus.Uploading,
      UploadStatus.UploadedToS3,
    ]

    return uploadingStatuses.includes(upload.status)
  },

  isImage(upload: Upload): upload is ImageUpload {
    return upload.mediaType === MediaType.image
  },

  isDocumentUpload(upload: Upload): upload is DocumentUpload {
    return upload.mediaType === MediaType.document
  },

  isVideo(upload: Upload): upload is VideoUpload {
    return upload.mediaType === MediaType.video
  },

  hasThumbnail(upload?: Upload): upload is VideoUpload | GifUpload {
    return !!upload && !!upload.thumbnailUrl
  },

  isGif(upload: Upload): upload is GifUpload {
    return upload.mediaType === MediaType.gif
  },

  getCanvaId(upload: Upload): string | undefined {
    return UploadSource.isCanva(upload.meta.source)
      ? upload.meta.source.id
      : undefined
  },

  getKey(upload: Upload): string {
    if (upload.key === undefined) throw new Error('Undefined upload.key')

    return upload.key
  },

  getPercentage(upload: Upload | Upload[]): number {
    return UploadProgress.getPercentage(
      Array.isArray(upload) ? upload.map((u) => u.progress) : upload.progress,
    )
  },

  getThumbnailUrl(upload: Upload): string {
    if (Upload.isVideo(upload) || Upload.isGif(upload))
      return upload.thumbnailUrl

    if (Upload.isDocumentUpload(upload)) {
      return upload.thumbnailUrl || upload.url
    }

    // @todo figure out a default thumbnail URL
    return upload.url as string
  },

  toMedia(upload: Upload): {
    id: string
    url: string
    alt?: string
    thumbnailUrl: string
    size: number
    type: MediaType
    source?: UploadSource
  } {
    return {
      // TODO: Review the Upload type and make sure that the optional properties are required
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'string | undefined'
      id: upload.uploadId,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'string | undefined'
      url: upload.url,
      alt: upload.alt,
      thumbnailUrl: Upload.getThumbnailUrl(upload),
      size: upload.size,
      // Todo fixme when MediaType is consistent.
      // @see https://buffer.slack.com/archives/C03U5TUMTJ8/p1664528869646179
      type: upload.mediaType.toLowerCase() as MediaType,
      source: upload.meta?.source,
    }
  },

  shouldReplace(newUpload: Upload, existingUpload: Upload): boolean {
    const hasCanvaId = Upload.getCanvaId(newUpload) !== undefined
    const sameCanvaId =
      Upload.getCanvaId(newUpload) === Upload.getCanvaId(existingUpload)
    const sameId = newUpload.id === existingUpload.id

    return hasCanvaId && sameCanvaId && !sameId
  },

  canAddDescription(upload: Upload): upload is ImageUpload | GifUpload {
    return Upload.isImage(upload) || Upload.isGif(upload)
  },
}
