import React from 'react'
import { Popover } from '@buffer-mono/popcorn'

import { getFragmentData, type FragmentType } from '~publish/gql'
import { CalendarPostCard_Post } from '../fragments/CalendarPostCard_Post'

import { useLoadCalendarPostCard } from '../hooks/useLoadCalendarPostCard'
import { CalendarPostCard } from '../CalendarPostCard'

import styles from './CalendarPostPopover.module.css'

type CalendarPostPopoverProps = {
  children: React.ReactNode
  post: FragmentType<typeof CalendarPostCard_Post>
  isOpen: boolean
  onOpenChange: (open: boolean) => void
}

export const CalendarPostPopover = ({
  children,
  post: postFragment,
  isOpen,
  onOpenChange,
}: CalendarPostPopoverProps): JSX.Element => {
  const calendarPost = getFragmentData(CalendarPostCard_Post, postFragment)
  const { data } = useLoadCalendarPostCard(calendarPost.id, {
    skip: !isOpen,
  })

  const closePopoverWhenComposerOpensOrLinkClicks = (
    evt: React.MouseEvent<HTMLElement>,
  ): void => {
    // If the click is on a link, close the popover
    if (evt.target instanceof HTMLAnchorElement) {
      onOpenChange(false)
      return
    }

    // Look for the closest ancestor that has the aria-controls attribute set to "composer-root"
    const openComposerTrigger = (evt.target as Element)?.closest(
      '[aria-controls="composer-root"]',
    )
    if (openComposerTrigger) {
      onOpenChange(false)
    }
  }

  return (
    <Popover open={isOpen} onOpenChange={onOpenChange}>
      <Popover.Trigger>{children}</Popover.Trigger>
      <Popover.Content
        side="right"
        align="start"
        className={styles.popoverContent}
        onClick={closePopoverWhenComposerOpensOrLinkClicks}
      >
        <CalendarPostCard post={data?.post} partialData={postFragment} />
      </Popover.Content>
    </Popover>
  )
}
