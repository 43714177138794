import AwsS3 from '@uppy/aws-s3'
import Uppy from '@uppy/core'
import type { UppyFile } from '@uppy/core'

import type {
  S3PreSignedURLInput,
  UserS3UploadSignatureResponse,
} from './getS3Signature'
import { isValidUploadType, UploadType } from './getS3Signature'
import logger from './logger'

const uppyLogger = {
  debug: logger.extend('uppy').extend('debug'),
  warn: logger.extend('uppy').extend('warn'),
  error: logger.extend('uppy').extend('error'),
}

export function createUppy({
  id,
  organizationId,
  getS3Signature,
}: {
  id: string
  organizationId: string
  userId: string
  getS3Signature: ({
    organizationId,
    fileName,
    mimeType,
    uploadType,
  }: S3PreSignedURLInput) => Promise<UserS3UploadSignatureResponse>
}): Uppy {
  const uppyInstance = new Uppy({
    id,
    meta: { uploaderId: id },
    autoProceed: true,
    logger: uppyLogger,
    locale: {
      strings: {
        noDuplicates: "Cannot add '%{fileName}', it already exists",
      },
    },
  })

  type UploadParameters = {
    method?: string
    url: string
    fields?: { [type: string]: string }
    headers?: { [type: string]: string }
  }

  const aws3Config = {
    timeout: 60 * 1000,
    limit: 10,
    allowedMetaFields: [
      'ogranizationId',
      'accountId',
      'uploaderId',
      'trackingId',
    ],
    async getUploadParameters(file: UppyFile): Promise<UploadParameters> {
      const mimeType = file.type || ''

      const s3UploadSignatureUrl = await getS3Signature({
        organizationId,
        fileName: file.name,
        mimeType,
        // the `id` for `createUppy` and the `Uploader` are of type `string`
        // because for the ideas composer, we pass the `id` as a string with the value `ideas`
        // For other instances of `Uploader`, the `id` is a value of UpdateType (post, channel avatar, account avatar)
        // So, we need to check if the `id` is a valid `UploadType` and if it is, we use it, otherwise we use `UploadType.postAsset`
        // Ideally, we should pass the UploadType when instantiating the `Uploader` but that would require a lot of changes
        // This PR already improve things and create a clean contract with the `getS3Signature` function
        uploadType: isValidUploadType(id)
          ? UploadType[id]
          : UploadType.postAsset,
      })

      const { key, url } = s3UploadSignatureUrl

      return {
        method: 'PUT',
        url,
        fields: {
          key,
        },
        headers: {
          'content-type': mimeType,
        },
      }
    },
  }

  uppyInstance.use(AwsS3, aws3Config)

  return uppyInstance
}
