import Bugsnag from '@bugsnag/browser'
import type { Event } from '@bugsnag/browser'
import debug from 'debug'

interface LogErrorOptions {
  context?: string
  metaData?: Record<string, unknown>
  beforeSend?: (event: Event) => Event
}

const logger = debug('uploader')

export function logError(error: Error, opts: LogErrorOptions = {}): void {
  const options = {
    context: 'composer',
    ...opts,
  }

  Bugsnag.notify(error, (event) => {
    event.context = options.context
    if (options.metaData) {
      event.addMetadata('metaData', options.metaData)
    }
    if (options.beforeSend) {
      event = options.beforeSend(event)
    }
  })

  logger(error, options)
}

export default logger
