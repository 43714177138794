import React from 'react'
import clsx from 'clsx'

import {
  isUnixEpochDate,
  isValidDateTime,
} from '~publish/helpers/dateFormatters'
import {
  ClockIcon,
  Flex,
  Heading,
  SimpleSpinner,
  TentativeTimeIcon,
  Text,
  Tooltip,
  WarningIcon,
} from '@buffer-mono/popcorn'

import { PostingTime } from '~publish/components/PostingTime'

import styles from './PostingTimeline.module.css'
import type {
  NotificationStatus,
  PostStatus,
  SchedulingType,
} from '~publish/gql/graphql'
import { ApprovalBadge, DraftBadge } from '../PostStatusBadge'
import { PostNotifyBadge } from '../PostNotifyBadge'

/**
 * Component to display a timeline of posts.
 */
type PostingTimelineProps = React.ComponentPropsWithoutRef<'div'> & {
  as?: 'div' | 'main'
}

const PostingTimeline = React.forwardRef<HTMLDivElement, PostingTimelineProps>(
  ({ children, as: As = 'div', ...props }, ref) => {
    return (
      <As className={styles.timeline} ref={ref} {...props}>
        {children}
      </As>
    )
  },
)

PostingTimeline.displayName = 'PostingTimeline'

/**
 * Component to display a timeline entry, which is a container for a post with time to the side
 */
const PostingTimelineEntry = ({
  children,
  ...props
}: React.ComponentPropsWithoutRef<'div'>): JSX.Element => {
  return (
    <div className={styles.wrapper} {...props}>
      {children}
    </div>
  )
}

/**
 * Component to display a label for a post in the timeline, for any custom labels
 */
const PostingTimelineLabel = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element => (
  <div className={styles.labelContainer}>
    <div className={styles.label}>{children}</div>
  </div>
)

const CustomScheduleLabel = (): JSX.Element => {
  return (
    <Tooltip content="Posting time was set manually and is not determined by the channel’s posting schedule">
      <Text className={styles.extraLabel} size="sm" color="subtle">
        <ClockIcon size="xsmall" /> Custom
      </Text>
    </Tooltip>
  )
}

const TentativePostLabel = (): JSX.Element => {
  return (
    <Tooltip content="Once scheduled, this post will be published at this time">
      <Text className={styles.extraLabel} size="sm" color="subtle">
        <TentativeTimeIcon size="xsmall" /> Tentative
      </Text>
    </Tooltip>
  )
}

const TentativeOverduePostLabel = (): JSX.Element => {
  return (
    <Tooltip content="This post's tentative publish time has passed.">
      <Text className={styles.extraLabel} size="sm" color="critical">
        <WarningIcon size="xsmall" /> Sending...
      </Text>
    </Tooltip>
  )
}

const OverduePostLabel = (): JSX.Element => {
  return (
    <Tooltip content="This post's due date has passed, reschedule it to set publishing time">
      <Text className={styles.extraLabel} size="sm" color="critical">
        <WarningIcon size="xsmall" /> Sending...
      </Text>
    </Tooltip>
  )
}

type PostingTimelineTimeLabelProps = React.ComponentPropsWithoutRef<'div'> & {
  date?: string
  includeDate?: boolean
  customScheduled?: boolean
  overdue?: boolean
  tentative?: boolean
  disabled?: boolean
  status?: PostStatus
  notificationStatus?: NotificationStatus | null
  schedulingType?: SchedulingType | null
}

const PostingTimelineSchedulingLabel = ({
  customScheduled = false,
  overdue = false,
  tentative = false,
}: Pick<
  PostingTimelineTimeLabelProps,
  'customScheduled' | 'overdue' | 'tentative'
>): JSX.Element | null => {
  if (tentative && overdue) {
    return <TentativeOverduePostLabel />
  }
  if (tentative) {
    return <TentativePostLabel />
  }
  if (overdue) {
    return <OverduePostLabel />
  }
  if (customScheduled) {
    return <CustomScheduleLabel />
  }
  return null
}

/**
 * Component to display a time label for a post in the timeline with specirfic labels for different states
 */
const PostingTimelineTimeLabel = ({
  date,
  includeDate = false,
  customScheduled = false,
  overdue = false,
  tentative = false,
  disabled = false,
  status,
  notificationStatus,
  schedulingType,
}: PostingTimelineTimeLabelProps): JSX.Element => {
  const hasTime = date && isValidDateTime(date) && !isUnixEpochDate(date)

  return (
    <div className={styles.labelContainer}>
      <Flex direction="column" gap="xs" className={styles.label}>
        {hasTime ? (
          <PostingTime date={date} includeDate={includeDate} muted={disabled} />
        ) : (
          <Text color="subtle" weight="medium">
            No time
          </Text>
        )}
        <PostingTimelineSchedulingLabel
          customScheduled={customScheduled}
          overdue={status !== 'sent' && overdue}
          tentative={tentative}
        />
        {status === 'needs_approval' && <ApprovalBadge />}
        {status === 'draft' && <DraftBadge />}
        <PostNotifyBadge
          schedulingType={schedulingType}
          status={status}
          notificationStatus={notificationStatus}
        />
      </Flex>
    </div>
  )
}

/**
 * Component to display the content of timeline entry, like the post or slot
 */
const PostingTimelineContent = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element => <div className={styles.content}>{children}</div>

const PostingTimelineHeader = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element => {
  return (
    <Heading size="small" as="h3" className={styles.header}>
      {children}
    </Heading>
  )
}

/**
 * Component to display a date header for a section of the timeline
 */
const PostingTimelineDateHeader = ({ date }: { date: string }): JSX.Element => {
  return (
    <Heading size="small" as="h3" className={styles.header}>
      {date}
    </Heading>
  )
}

type PostingTimelineLoadingProps = React.ComponentPropsWithoutRef<'div'> & {
  className?: string
}

/**
 * Component to display a loading state for the timeline, usually for infinite scroll UI
 */
const PostingTimelineLoading = React.forwardRef<
  HTMLDivElement,
  PostingTimelineLoadingProps
>(({ className, ...props }: PostingTimelineLoadingProps, ref) => {
  return (
    <div
      className={clsx(styles.loading, className)}
      role="progressbar"
      ref={ref}
      {...props}
    >
      <SimpleSpinner size="small" />
    </div>
  )
})

PostingTimelineLoading.displayName = 'PostingTimeline.Loading'

const PostingTimelineObject = Object.assign(PostingTimeline, {
  Entry: PostingTimelineEntry,
  Label: PostingTimelineLabel,
  TimeLabel: PostingTimelineTimeLabel,
  Content: PostingTimelineContent,
  Header: PostingTimelineHeader,
  Loading: PostingTimelineLoading,
  DateHeader: PostingTimelineDateHeader,
})

export { PostingTimelineObject as PostingTimeline }
