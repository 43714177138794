import React, { useEffect } from 'react'
import { useReward } from 'react-rewards'
import { useChecklistCompleteModalViewed } from '../../../../common/hooks/useSegmentTracking'
import { useUser } from '../../../../common/context/User'
import Button from '@bufferapp/ui/Button'
import Text from '@bufferapp/ui/Text'

import {
  Container,
  ConfettiContainer,
  ImageWrapper,
  TextWrapper,
  ButtonWrapper,
} from './style'
import { HighFiveImage } from './data/high-five-image'
import useDismissObject from '../../../../common/hooks/useDismissObject'
import type { ModalProps } from '../../../../common/types'

const ChecklistCompleteContent = ({ closeAction }: ModalProps): JSX.Element => {
  const user = useUser()
  const { reward } = useReward('confetti-container', 'confetti', {
    spread: 110,
    elementCount: 180,
    startVelocity: 50,
    lifetime: 1000,
    colors: ['#5E68C2', '#FF3363', '#FFBB00', '#2AFF28', '#2AB6FF', '#EF0583'],
  })

  const { onDismissObject } = useDismissObject({
    organizationId: user?.currentOrganization?.id || '',
    dismissedObject: {
      id: 'welcome_checklist_complete',
    },
  })

  useEffect(() => {
    onDismissObject()
    useChecklistCompleteModalViewed({
      user,
    })
    reward()
  }, [])
  return (
    <>
      <ImageWrapper>
        <HighFiveImage />
      </ImageWrapper>
      <TextWrapper>
        <Text type="h2">
          Great! You are a Buffer super user{' '}
          <span role="img" aria-label="heart eye emoji">
            😍
          </span>
        </Text>
        <Text type="p">
          You have successfully finished your Buffer checklist
        </Text>
      </TextWrapper>
      <ButtonWrapper>
        <Button type="secondary" onClick={closeAction} label="Take Me Back" />
      </ButtonWrapper>
      <ConfettiContainer id="confetti-container" />
    </>
  )
}

const ChecklistComplete = ({ closeAction }: ModalProps): JSX.Element => {
  return (
    <Container>
      <ChecklistCompleteContent closeAction={closeAction} />
    </Container>
  )
}

export default ChecklistComplete
