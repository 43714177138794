import { getFileExtension } from '../utils/getFileExtension'
import type { UploaderRestrictions } from '../values/UploaderRestrictions'

export type FileRejection = {
  message: string
  file: File
}

type FileValidationResult = {
  validFiles: File[]
  invalidFiles: Array<FileRejection>
}

type FileValidationOptions = {
  fileRestrictions: UploaderRestrictions
  currentFileCount?: number
}

const getHumanReadableSize = (bytes: number | null): string => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']

  if (bytes === 0 || bytes == null) {
    return 'n/a'
  }

  const i = Math.floor(Math.log(bytes) / Math.log(1024))

  if (i === 0) {
    return `${bytes} ${sizes[i]}`
  }

  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`
}

export const validateFile = async (
  file: File,
  { fileRestrictions: { uploadConfig } }: FileValidationOptions,
): Promise<File | FileRejection> => {
  const fileFormat = getFileExtension(file.name).toUpperCase()

  if (!uploadConfig.has(fileFormat)) {
    const acceptedFilesText = Array.from(uploadConfig.keys()).join(', ')
    const message = `We can't quite use that type of file (${fileFormat.toUpperCase()}). Could you try one of the following instead: ${acceptedFilesText}?`

    return {
      message,
      file,
    }
  }

  const maxSize = uploadConfig.get(fileFormat)?.maxSize || 0

  if (file.size > maxSize) {
    return {
      message: `We can't upload ${
        file.name
      } because it's too large. Try a file smaller than ${getHumanReadableSize(
        maxSize,
      )}.`,
      file,
    }
  }

  return file
}

export const validateFilesForUploads = async (
  files: File[],
  validationOptions: FileValidationOptions,
): Promise<FileValidationResult> => {
  const validFiles: FileValidationResult['validFiles'] = []
  const invalidFiles: FileValidationResult['invalidFiles'] = []
  const maxNumberOfFiles =
    validationOptions.fileRestrictions?.maxNumberOfFiles || 10
  const currentFileCount = validationOptions.currentFileCount || 0
  const totalFiles = currentFileCount + files.length
  const totalAllowedToUpload = maxNumberOfFiles - currentFileCount

  if (totalFiles > maxNumberOfFiles) {
    const removedFiles = files.splice(totalAllowedToUpload)
    invalidFiles.push({
      message: `We can only upload ${maxNumberOfFiles} files in total.`,
      file: removedFiles[0],
    })
  }

  const filesToUpload = files.splice(0, totalAllowedToUpload)
  const validationResults = await Promise.all(
    filesToUpload.map((file) => validateFile(file, validationOptions)),
  )

  validationResults.forEach((result) => {
    if (result instanceof File) {
      validFiles.push(result)
    } else {
      invalidFiles.push(result)
    }
  })

  return {
    validFiles,
    invalidFiles,
  }
}
