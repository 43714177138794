import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit'
import type { ModalData } from '../../../common/events/types'
import { modalPriorities, type MODALS } from '../../../common/types'

export type ModalsState = {
  modal: string | null
  data: ModalData | null
}

const initialState: ModalsState = {
  modal: null,
  data: null,
}

type ShowModalProps = {
  currentModalKey: string | null
  newModalKey: string
}

const shouldShowNewModal = ({
  currentModalKey,
  newModalKey,
}: ShowModalProps): boolean => {
  const newPriority = modalPriorities[newModalKey as MODALS]
  const currentPriority = currentModalKey
    ? modalPriorities[currentModalKey as MODALS]
    : 0

  return newPriority >= currentPriority
}

export const modalsSlice = createSlice<
  ModalsState,
  SliceCaseReducers<ModalsState>,
  'modals'
>({
  name: 'modals',
  initialState,
  reducers: {
    showModal: (
      state,
      action: PayloadAction<{ modalKey: string; modalData?: ModalData }>,
    ) => {
      // Only show the modal if the new modal has a higher priority
      if (
        shouldShowNewModal({
          currentModalKey: state.modal,
          newModalKey: action.payload.modalKey,
        })
      ) {
        return {
          ...state,
          modal: action.payload.modalKey,
          data: action.payload.modalData ?? null,
        }
      }
      return state
    },
    closeModal: (state) => {
      return {
        ...state,
        modal: null,
        data: null,
      }
    },
  },
})

export const { showModal, closeModal } = modalsSlice.actions

export default modalsSlice.reducer
