import React, { useEffect, useState } from 'react'
import { Status } from '../util/getChannelStatus'
import {
  Button,
  ChannelAvatar,
  type ChannelType,
  CheckboxCard,
  CheckboxCardGroup,
  CriticalIcon,
  Dialog,
  EmptyState,
  Flex,
  LoadingIcon,
  LockOpenIcon,
  Text,
  toast,
  useIsMobileScreenSize,
  WarningIcon,
} from '@buffer-mono/popcorn'
import { getStartPageUrl } from '@buffer-mono/app-shell/src/common/utils/urls'

import { isStartPage } from '../util/isStartPage'
import { graphql } from '~publish/graphql'
import { useCurrentOrganization } from '~publish/legacy/accountContext'
import {
  type ChannelMask,
  GetChannels,
  useChannelsForSettings,
} from '../hooks/useChannelsForSettings'
import { logError } from '~publish/legacy/utils/logError'
import { useMutation } from '@apollo/client'

import style from './ChannelStatusSection.module.css'
import usePrivilege from '../hooks/usePrivilege'

export const LockChannelsMutation = graphql(/* GraphQL */ `
  mutation ChannelsLock(
    $organizationId: String
    $channelIdsToLock: [ID!]
    $channelIdsToUnlock: [ID!]
  ) {
    channelsLock(
      organizationId: $organizationId
      channelIdsToLock: $channelIdsToLock
      channelIdsToUnlock: $channelIdsToUnlock
    )
  }
`)

const UnlockChannelDialog = ({
  children,
}: {
  children: JSX.Element
}): JSX.Element => {
  const organization = useCurrentOrganization()
  const organizationId = organization?.id
  const {
    channels,
    loading: loadingChannels,
    error: errorLoadingChannels,
  } = useChannelsForSettings({
    organizationId,
  })

  const [executeLockChannels, { loading: loadingResult }] =
    useMutation(LockChannelsMutation)

  const [isOpen, setIsOpen] = useState(false)
  const [updatedChannels, setUpdatedChannels] = useState<
    ChannelMask[] | undefined
  >(channels)

  // Ensure state is fresh on each open
  useEffect(() => {
    if (isOpen) {
      setUpdatedChannels(channels)
    }
  }, [channels, isOpen])

  const handleChannelSelection = ({
    currentChannel,
    updatedChannels,
  }: {
    currentChannel?: ChannelMask
    updatedChannels?: ChannelMask[]
  }): void => {
    const channelsWithUpdatedLockStates = updatedChannels?.map((channel) => {
      if (channel.id === currentChannel?.id) {
        return {
          ...currentChannel,
          isLocked: !channel.isLocked,
        }
      } else {
        return channel
      }
    })
    setUpdatedChannels(channelsWithUpdatedLockStates)
  }

  const handleConfirmChannelLock = async (): Promise<void> => {
    try {
      const { data, errors } = await executeLockChannels({
        variables: {
          organizationId,
          channelIdsToLock: updatedChannels
            ?.filter((c: ChannelMask) => c.isLocked)
            .map((c: ChannelMask) => c.id),
          channelIdsToUnlock: updatedChannels
            ?.filter((c: ChannelMask) => !c.isLocked)
            .map((c: ChannelMask) => c.id),
        },
        refetchQueries: [GetChannels],
      })

      if (data?.channelsLock) {
        toast.success(`You're all set! Your channels have been updated.`, {})
      }

      if (errors?.length) {
        toast.error('Failed to update channels.')
        const unexpectedError = new Error(
          errors?.[0]?.message || 'Unexpected API response',
        )
        logError(unexpectedError, { metaData: { data, errors } })
      }
    } catch (e) {
      toast.error('Failed to update channels.')
      logError(e as Error, {
        metaData: { variables: { channels, updatedChannels } },
      })
    }
  }

  const numberOfUnlockedChannels =
    updatedChannels?.filter((channel) => !channel.isLocked).length || 0
  const channelLimit = organization?.limits.channels ?? 0
  const isOverChannelLimit = numberOfUnlockedChannels > channelLimit
  const noChannelsHaveBeenUpdated =
    JSON.stringify(updatedChannels) === JSON.stringify(channels)

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Trigger>{children}</Dialog.Trigger>
      <Dialog.Content>
        {loadingChannels ? (
          <EmptyState>
            <EmptyState.Icon>
              <LoadingIcon />
            </EmptyState.Icon>
            <EmptyState.Heading>Loading Channels</EmptyState.Heading>
            <Dialog.Close>
              <Button variant="secondary">Close</Button>
            </Dialog.Close>
          </EmptyState>
        ) : errorLoadingChannels ? (
          <EmptyState variant="critical">
            <EmptyState.Icon>
              <CriticalIcon />
            </EmptyState.Icon>
            <EmptyState.Heading>Failed to load channels</EmptyState.Heading>
            <EmptyState.Description>
              Something went wrong. Please try again later.
            </EmptyState.Description>
            <Dialog.Close>
              <Button variant="secondary">Close</Button>
            </Dialog.Close>
          </EmptyState>
        ) : (
          <>
            <Dialog.Header>
              <Dialog.Title>Select Channels to Keep</Dialog.Title>
              <Dialog.Description>
                Your current plan supports up to {channelLimit} channels. Please
                select which {channelLimit} channels you&apos;d like to keep
                connected to Buffer. All others will be saved but locked. Check
                out our channel limits guide for more details.
              </Dialog.Description>
            </Dialog.Header>
            <Dialog.Body>
              <Flex align="center" justify="between">
                <Text>{channels?.length} Channels</Text>
                <Text>
                  <i>{numberOfUnlockedChannels} Selected</i>
                </Text>
              </Flex>
              <CheckboxCardGroup className={style.checkboxGroup}>
                {channels?.map((channel) => {
                  return (
                    <CheckboxCard
                      key={channel.id}
                      onCheckedChange={(): void =>
                        handleChannelSelection({
                          currentChannel: channel,
                          updatedChannels,
                        })
                      }
                      defaultChecked={!channel.isLocked}
                    >
                      <Flex gap="sm" align="center">
                        <ChannelAvatar
                          size="small"
                          alt={channel.name}
                          src={channel.avatar}
                          name={channel.name}
                          channel={channel.service as ChannelType}
                        />
                        <Text>{channel.name || channel.displayName}</Text>
                      </Flex>
                    </CheckboxCard>
                  )
                })}
              </CheckboxCardGroup>
              {isOverChannelLimit ? (
                <Flex
                  gap="sm"
                  align="center"
                  justify="end"
                  className={style.warningText}
                >
                  <WarningIcon color="warning" />
                  <Text color="warning">
                    A maximum number of {channelLimit} channels may be selected.
                  </Text>
                </Flex>
              ) : null}
            </Dialog.Body>
            <Dialog.Footer>
              <Dialog.Close>
                <Button variant="secondary">Cancel</Button>
              </Dialog.Close>
              <Dialog.Close>
                <Button
                  variant="primary"
                  onClick={(): void => {
                    handleConfirmChannelLock()
                  }}
                  disabled={
                    noChannelsHaveBeenUpdated ||
                    isOverChannelLimit ||
                    loadingResult ||
                    numberOfUnlockedChannels === 0
                  }
                >
                  Save
                </Button>
              </Dialog.Close>
            </Dialog.Footer>
          </>
        )}
      </Dialog.Content>
    </Dialog>
  )
}

interface ChannelStatusSectionProps {
  channel: ChannelMask
  status: Status
  refreshChannelConnection: (cta: string) => void
}
const ChannelStatusSection = ({
  channel,
  status,
  refreshChannelConnection,
}: ChannelStatusSectionProps): JSX.Element | null => {
  const canManageChannels = usePrivilege('canManageChannels')
  const isChannelStartPage = isStartPage({ channel })
  const isMobile = useIsMobileScreenSize()

  if (status === Status.Locked && !isChannelStartPage) {
    return (
      <UnlockChannelDialog>
        <Button variant="secondary" size="medium" disabled={!canManageChannels}>
          <LockOpenIcon />
          Unlock
        </Button>
      </UnlockChannelDialog>
    )
  }

  if (status === Status.Locked && isChannelStartPage) {
    return (
      <Button
        variant="secondary"
        size="medium"
        onClick={(): void => {
          window.location.assign(getStartPageUrl(channel.id))
        }}
        disabled={!canManageChannels}
        aria-label={`Publish to connect ${channel.name || channel.displayName}`}
      >
        {isMobile ? 'Publish' : 'Publish to Connect'}
      </Button>
    )
  }

  if (status === Status.RequiresRefreshing) {
    return (
      <>
        <CriticalIcon color="critical" />
        {!isMobile && <Text color="critical">Requires refreshing</Text>}
        <Button
          variant="secondary"
          size="medium"
          onClick={(): void => {
            refreshChannelConnection(
              `publish-channels-channelRefreshButton-${channel.service.toLowerCase()}-1`,
            )
          }}
          disabled={!canManageChannels}
        >
          Refresh
        </Button>
      </>
    )
  }

  return null
}

export default ChannelStatusSection
