import React, { useState, useMemo, useRef, useEffect } from 'react'

import {
  Flex,
  Button,
  CoachMark,
  CheckIcon,
  toast,
  NewBadge,
} from '@buffer-mono/popcorn'

import { useSelectedChannel } from '~publish/pages/Channel/ChannelContext'
import { useDismissableBanner } from '~publish/hooks/useDismissableBanner'

import { useUpdateChannelPostingGoal } from '../../hooks/useUpdateChannelPostingGoal'

import styles from './MatchPostingTimesWithGoal.module.css'

type ScheduleDay = {
  paused?: boolean
  times?: unknown[]
}

export function MatchPostingTimesWithGoal(): JSX.Element {
  const channelData = useSelectedChannel()
  const {
    postingGoal,
    id: channelId,
    postingSchedule,
  } = channelData ?? {
    postingGoal: undefined,
    id: undefined,
    postingSchedule: undefined,
  }
  const [isLoadingRecommendedTimes, setIsLoadingRecommendedTimes] =
    useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [isFadingOut, setIsFadingOut] = useState(false)
  const fadeOutTimerRef = useRef<NodeJS.Timeout | null>(null)
  const [updateChannelPostingGoal] = useUpdateChannelPostingGoal()
  const goal = postingGoal?.goal ?? 0

  // Clear timer on unmount
  useEffect(() => {
    return () => {
      if (fadeOutTimerRef.current) {
        clearTimeout(fadeOutTimerRef.current)
      }
    }
  }, [])

  const activeSlotCount = useMemo(
    () => countActiveSlots(postingSchedule),
    [postingSchedule],
  )

  const shouldShowFeature = goal > 0 && goal > activeSlotCount

  const handleAddRecommendedTimes = async (): Promise<void> => {
    if (!channelId) {
      toast.error(
        'An error occurred while adding recommended posting times. Please try again later.',
      )
      return
    }
    // Wait 300ms before showing the loading state, if the request is fast, the loading state will not be shown
    const timer = setTimeout(() => setIsLoadingRecommendedTimes(true), 300)

    try {
      const { success, error } = await updateChannelPostingGoal({
        variables: {
          input: {
            channelId,
            goal,
            scheduleUpdateMode: 'add',
          },
        },
      })

      clearTimeout(timer)
      setIsLoadingRecommendedTimes(false)
      if (success) {
        setShowConfirmation(true)

        // Start fade out after 3.5 seconds
        fadeOutTimerRef.current = setTimeout(() => {
          setIsFadingOut(true)

          // Hide message after animation completes
          fadeOutTimerRef.current = setTimeout(() => {
            setShowConfirmation(false)
            setIsFadingOut(false)
          }, 500)
        }, 3500)
      } else {
        toast.error(
          `An error occurred while adding recommended posting times: ${error}`,
        )
      }
    } catch (error) {
      if (error instanceof Error) {
        toast.error(
          `An error occurred while adding recommended posting times: ${error.message}`,
        )
      } else {
        toast.error(
          `An error occurred while adding recommended posting times. Please try again later.`,
        )
      }
      setIsLoadingRecommendedTimes(false)
    }
  }

  if (showConfirmation) {
    return (
      <Flex
        gap="xs"
        align="center"
        className={`${styles.successMessage} ${
          isFadingOut ? styles.fadeOut : ''
        }`}
      >
        <CheckIcon color="success" /> Posting Times Added
      </Flex>
    )
  }
  if (shouldShowFeature) {
    return (
      <FeatureCoachMark>
        <Button
          variant="secondary"
          onClick={handleAddRecommendedTimes}
          loading={isLoadingRecommendedTimes}
        >
          {isLoadingRecommendedTimes
            ? 'Finding Posting Times'
            : 'Add Posting Times to Match Goal'}
        </Button>
      </FeatureCoachMark>
    )
  }

  return <></>
}

function FeatureCoachMark({
  children,
}: {
  children: React.ReactNode
}): JSX.Element {
  const banner = useDismissableBanner('match-posting-times-coachmark', {
    trackDismiss: true,
  })
  if (!banner.isActive) return <>{children}</>
  return (
    <CoachMark>
      <CoachMark.Overlay>
        {children}
        <CoachMark.Spotlight
          style={
            {
              '--spotlight-left': '0',
              '--spotlight-top': '5px',
            } as React.CSSProperties
          }
        />
      </CoachMark.Overlay>
      <CoachMark.Content
        side="left"
        style={
          {
            '--coach-mark-max-width': '360px',
          } as React.CSSProperties
        }
      >
        <CoachMark.Title>
          <NewBadge /> Match Posting Times With Your Goal
        </CoachMark.Title>
        <CoachMark.Description>
          We can add recommended times based on Buffer data to match your goal.
          Existing posting times and scheduled posts won't be affected.
        </CoachMark.Description>
        <CoachMark.Footer>
          <CoachMark.Dismiss>
            <Button variant="primary" size="small" onClick={banner.dismiss}>
              Got it
            </Button>
          </CoachMark.Dismiss>
        </CoachMark.Footer>
      </CoachMark.Content>
    </CoachMark>
  )
}

/**
 * Counts the number of active posting slots in a schedule
 *
 * @param schedule - Array of schedule days, where each day may have paused status and times
 * @returns The total number of active (non-paused) posting slots across all days
 *
 * @example
 * const schedule = [
 *   { paused: false, times: [1, 2] },
 *   { paused: true, times: [3, 4] }
 * ];
 * countActiveSlots(schedule); // Returns 2
 */
function countActiveSlots(schedule: ScheduleDay[] | undefined): number {
  if (!schedule) return 0
  return schedule.reduce((count: number, day: ScheduleDay) => {
    if (!day.paused && day.times) {
      return count + day.times.length
    }
    return count
  }, 0)
}
