import React, { useEffect } from 'react'
import { useReward } from 'react-rewards'
import { useSplitEnabled } from '@buffer-mono/features'

import {
  BufferIcon,
  Button,
  ChannelAvatar,
  ChannelIcon,
  type ChannelType,
  ChevronDownIcon,
  DropdownMenu,
  Flex,
  Label,
  RepostIcon,
  SimpleSpinner,
  SuccessDialog,
} from '@buffer-mono/popcorn'

import { getPublishUrl, isInPublish } from '../../../../common/utils/urls'

import { useUser } from '../../../../common/context/User'
import { Service } from '../../../../exports/Orchestrator/channelConnections/types'
import trackChannelConnectionModalViewed from '../../../../tracking/ChannelConnectionModal/trackChannelConnectionModalViewed'
import useChannels from '../../hooks/useChannels'
import { cleanupUrlParams } from '../../utils'
import { MODAL_ACTIONS } from '../../../../common/events/modalEvents'

import {
  constructBodtTextWithGrammer,
  constructTitleWithGrammer,
} from './utils'

import { NewChannelConnectionOnboardingContent } from './components/NewChannelConnectionOnboardingContent'
import trackCTAClicked from '../../../../tracking/UpgradePaths/trackCTAClicked'
import { useModalManager } from '../../../ModalManager/hooks/useModalManager'

import styles from './ChannelConnectionOnboarding.module.css'

function handleCreatePostCTA({
  socialChannelId,
  handleOnDismissModal,
}: {
  socialChannelId: string
  handleOnDismissModal: () => void
}): void {
  const cta = 'connectChannelOnboarding-createAPostCta'
  handleOnDismissModal()
  const channelsQuery = `channelId[]=${socialChannelId}`
  const newPostRoute = `post/new?${channelsQuery}&cta=${cta}`

  const isInPublishApp = isInPublish()

  // If we are in publish and have access to openComposer. Open the Composer directly
  if (window.__openComposer && isInPublishApp) {
    const preSelectedChannels = [socialChannelId]
    window.__openComposer({
      channels: preSelectedChannels,
      cta: `publish-modal-${cta}-1`,
    })
  } else {
    const redirectUrl = getPublishUrl(newPostRoute)
    window.location.assign(redirectUrl)
  }
}

function handleConfigurePostingPlanCTA({
  handleOnDismissModal,
  profileId,
}: {
  handleOnDismissModal: ({
    shouldUseTracking,
  }: {
    shouldUseTracking: boolean
  }) => void
  profileId: string
}): void {
  handleOnDismissModal({ shouldUseTracking: false })
  MODAL_ACTIONS.openModal('postingPlanConfiguration', {
    channelId: profileId,
  })
}

const ChannelConnectionOnboardingContent = (): JSX.Element => {
  const user = useUser()
  const { modalData, dismissModal: handleOnDismissModal } = useModalManager()

  const { unlockedChannels = [] } = useChannels()

  const { reward } = useReward('confetti-container', 'confetti', {
    spread: 110,

    elementCount: 180,
    startVelocity: 50,
    lifetime: 1000,
    colors: ['#5E68C2', '#FF3363', '#FFBB00', '#2AFF28', '#2AB6FF', '#EF0583'],
  })

  const title = constructTitleWithGrammer(unlockedChannels.length)
  const bodyText = constructBodtTextWithGrammer(
    unlockedChannels[0].service as Service,
    unlockedChannels.length,
  )

  const postingSchedualButtonText = 'Create Your Posting Plan'

  const postingScheduleAction = ({
    profileId,
  }: {
    profileId: string
  }): void => {
    const ctaButton = postingSchedualButtonText.replace(/\s/g, '')
    trackCTAClicked({
      user,
      payload: {
        ctaView: 'connectChannelOnboarding',
        ctaLocation: 'celebrationModal',
        ctaButton,
        ctaVersion: '1',
        upgradePathName: modalData?.upgradePathName || '',
      },
    })
    handleConfigurePostingPlanCTA({
      handleOnDismissModal,
      profileId,
    })
  }

  useEffect(() => {
    reward()
    cleanupUrlParams()
  }, [])

  // Currently only displays for Instagram channel connections.
  // Once all flows are moved over, it will handle all channels.
  const { isEnabled: isInstagramLoginEnabled } =
    useSplitEnabled('instagram-login')

  const isInstagramChannel = unlockedChannels[0].service === Service.instagram

  if (isInstagramLoginEnabled && isInstagramChannel) {
    return <NewChannelConnectionOnboardingContent />
  }

  const closeModal = (): void => {
    handleOnDismissModal()
  }

  const createPostButton = (
    <Button
      className={styles.styledButton}
      variant="primary"
      size="large"
      onClick={(): void =>
        handleCreatePostCTA({
          socialChannelId: unlockedChannels[0].id,
          handleOnDismissModal,
        })
      }
    >
      Create a Post
    </Button>
  )

  return (
    <SuccessDialog open={true} onOpenChange={closeModal}>
      <SuccessDialog.Content className={styles.dialog}>
        <Flex direction="column" align="center" justify="center" gap="md">
          <Flex
            direction="row"
            gap="md"
            justify="center"
            align="center"
            className={styles.socialIconsWrapper}
          >
            <BufferIcon size="medium" />
            <RepostIcon size="medium" color="subtle" />
            <ChannelIcon
              color="branded"
              size="medium"
              type={unlockedChannels[0].service as ChannelType}
            />
          </Flex>
        </Flex>
        <Flex direction="column" align="center" justify="center" gap="xs">
          <SuccessDialog.Title> {title}</SuccessDialog.Title>
          <SuccessDialog.Description>{bodyText}</SuccessDialog.Description>
        </Flex>
        <SuccessDialog.Actions>
          {createPostButton}
          {unlockedChannels.length === 1 && (
            <Button
              className={styles.styledButton}
              variant="tertiary"
              size="large"
              onClick={(): void =>
                postingScheduleAction({
                  profileId: unlockedChannels[0].id,
                })
              }
            >
              {postingSchedualButtonText}
            </Button>
          )}
          {unlockedChannels.length > 1 && (
            <DropdownMenu
              className={styles.styledDropdownMenu}
              trigger={
                <Button
                  className={styles.styledButton}
                  variant="secondary"
                  size="large"
                >
                  {postingSchedualButtonText} for <ChevronDownIcon />
                </Button>
              }
            >
              {unlockedChannels.map((channel) => (
                <DropdownMenu.Item
                  className={styles.styledDropdownMenuItem}
                  key={channel.id}
                  onClick={(): void =>
                    postingScheduleAction({
                      profileId: channel.id,
                    })
                  }
                >
                  <Flex
                    key={channel.id}
                    className={styles.channelCard}
                    gap="md"
                    align="center"
                  >
                    <ChannelAvatar
                      alt={channel.name}
                      channel={channel.service as ChannelType}
                      src={channel.avatar}
                      size="small"
                    />
                    <Flex direction="column" className={styles.channelInfo}>
                      <Label>{channel.name}</Label>
                    </Flex>
                  </Flex>
                </DropdownMenu.Item>
              ))}
            </DropdownMenu>
          )}
        </SuccessDialog.Actions>
        <div id="confetti-container" className={styles.confettiContainer} />
      </SuccessDialog.Content>
    </SuccessDialog>
  )
}

const ChannelConnectionOnboardingModal = (): JSX.Element => {
  const user = useUser()
  const { modalData } = useModalManager()

  useEffect(() => {
    if (user) {
      const trackingPayload = {
        ctaButton: modalData?.ctaButton || '',
        ctaView: modalData?.ctaView || '',
        ctaLocation: modalData?.ctaLocation || '',
        ctaVersion: modalData?.ctaVersion,
        upgradePathName: modalData?.upgradePathName || '',
      }
      trackChannelConnectionModalViewed({
        payload: trackingPayload,
        user,
        isReconnecting: false,
      })
    }
  }, [
    modalData?.ctaButton,
    modalData?.ctaLocation,
    modalData?.ctaVersion,
    modalData?.ctaView,
    modalData?.upgradePathName,
    user,
  ])

  if (!user?.currentOrganization?.billing || !user?.currentOrganization?.id) {
    return (
      <Flex justify="center" data-testid="channel-connection-onboarding-loader">
        <SimpleSpinner size="medium" />
      </Flex>
    )
  }

  return <ChannelConnectionOnboardingContent />
}

export default ChannelConnectionOnboardingModal
