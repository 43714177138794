import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Dialog,
  Button,
  Text,
  RefreshCwIcon,
  Card,
  ChannelAvatar,
  type ChannelType,
} from '@buffer-mono/popcorn'

import type { Service } from '../../types'
import { resetChannelConnectionsState } from '../../../store/channelConnectionsReducer'

import styles from './MultipleChannelsWarningModal.module.css'
import capitalize from 'lodash/capitalize'
import { getServiceName } from '../../utils'
import type { Channel } from '../../../../../common/types'

const MultipleChannelsWarningModal = ({
  channel,
  onContinue,
}: {
  channel: Channel
  onContinue: () => void
}): JSX.Element => {
  const [open, setOpen] = useState(true)
  const dispatch = useDispatch()

  const handleClose = (): void => {
    setOpen(false)
    dispatch(resetChannelConnectionsState({}))
  }

  const handleContinue = (): void => {
    setOpen(false)
    onContinue()
  }

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <Dialog.Content size="xlarge">
        <Dialog.CloseButton onClick={handleClose} />
        <Dialog.Header className={styles.header}>
          <div className={styles.iconWrapper}>
            <RefreshCwIcon className={styles.icon} size="large" />
          </div>
          <Dialog.Title>
            Quick check: Are you signed in to the right{' '}
            {getServiceName(channel.service as Service)} account?
          </Dialog.Title>
          <Dialog.Description>
            <Text color="subtle">
              We can only refresh the account you&apos;re currently signed in to
              on {getServiceName(channel.service as Service)}
            </Text>
          </Dialog.Description>
        </Dialog.Header>
        <Dialog.Body className={styles.body}>
          <Card className={styles.card}>
            <ChannelAvatar
              alt={channel.name}
              channel={channel.service as ChannelType}
              src={channel.avatar}
            />
            <div className={styles.channelInfo}>
              <Text weight="bold">{channel.name}</Text>
              <Text color="subtle">{capitalize(channel.type)}</Text>
            </div>
            <Button onClick={handleContinue}>Yes, Refresh Now</Button>
          </Card>
        </Dialog.Body>
        <Dialog.Separator />
        <Dialog.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Refresh Later
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  )
}

export default MultipleChannelsWarningModal
