import React from 'react'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import clsx from 'clsx'
import {
  ChannelAvatar,
  VisuallyHidden,
  IconButton,
  PlusIcon,
  Text,
  LockIcon,
  WarningIcon,
  NewBadge,
  Flex,
  GripVerticalIcon,
  Sidebar,
  UnstyledButton,
  Tooltip,
  CriticalIcon,
  type ChannelType,
} from '@buffer-mono/popcorn'
import { useSortable } from '@dnd-kit/sortable'

import { useOrganizationId } from '~publish/legacy/accountContext'
import { usePostCounts } from '~publish/pages/AllChannels/PostList/usePostCounts'
import useDraggingCursor from '~publish/hooks/useDraggingCursor'
import { formatCompactNumber } from '~publish/helpers/numberFormatters'
import {
  channel as channelRoute,
  newCalendarSingleChannel,
} from '~publish/legacy/routes'
import { SEGMENT_NAMES } from '~publish/legacy/constants'
import {
  parseQueryParams,
  type QueryParamValue,
  serializeQueryParams,
} from '~publish/hooks/useQueryParam'
import { isFacebookGroup } from '~publish/helpers/channels'

import { NewPostComposerTrigger } from '../../NewPostComposerTrigger'

import type { ChannelNavChannel } from '../useChannels'

import styles from './ChannelNav.module.css'

interface ChannelNavItemProps
  extends Omit<React.ComponentProps<typeof Sidebar.ListItem>, 'children'> {
  channel: ChannelNavChannel
  dragged?: boolean
  dragDisabled?: boolean
  disabled?: boolean
}

function requiresRefreshing(channel: ChannelNavChannel): boolean {
  return channel.service === 'twitter' && channel.apiVersion !== '2'
}

export function ChannelNavItem({
  channel,
  dragDisabled,
  dragged,
  style,
  className,
}: ChannelNavItemProps): JSX.Element {
  const url = useChannelPageRoute(channel.id, { keepSearch: true })
  const showNewPostButton =
    !channel.isLocked && !channel.isDisconnected && !requiresRefreshing(channel)
  const serverUrl = isFacebookGroup(channel) ? null : channel.serverUrl
  const additionalInfo = channel.locationData?.location ?? serverUrl

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    setActivatorNodeRef,
  } = useSortable({ id: channel.id, disabled: dragDisabled })

  useDraggingCursor(isDragging)

  const isDragShadow = !dragged && isDragging

  return (
    <Sidebar.ListItem
      id={channel.id}
      ref={setNodeRef}
      style={
        {
          '--translate-x': transform?.x ? `${transform.x}px` : undefined,
          '--translate-y': transform?.y ? `${transform.y}px` : undefined,
          '--scale-x': transform?.scaleX ? `${transform.scaleX}` : undefined,
          '--scale-y': transform?.scaleY ? `${transform.scaleY}` : undefined,
          transition,
          ...style,
        } as React.CSSProperties
      }
      className={clsx(
        styles.channel,
        {
          [styles.dragging]: dragged,
          [styles.dragShadow]: isDragShadow,
        },
        className,
      )}
    >
      {!dragDisabled && !isDragShadow && (
        <UnstyledButton
          className={styles.dragHandle}
          {...attributes}
          {...listeners}
          ref={setActivatorNodeRef}
        >
          <GripVerticalIcon />
        </UnstyledButton>
      )}
      <Sidebar.Button
        as={NavLink}
        to={url}
        size="large"
        prefix={
          <ChannelAvatar
            alt={channel.service}
            channel={channel.service as ChannelType}
            src={channel.avatar}
            size="small"
          />
        }
        suffix={<ChannelSuffix channel={channel} />}
      >
        <Flex direction="column" gap="2xs">
          <Text
            className={styles.channelName}
            weight="medium"
            data-testid="channel-name"
            lineHeight="tight"
          >
            {channel.name}
          </Text>
          {additionalInfo && (
            <Text
              size="sm"
              color="subtle"
              className={styles.channelName}
              lineHeight="tight"
            >
              {additionalInfo}
            </Text>
          )}
        </Flex>
      </Sidebar.Button>
      {showNewPostButton && (
        <NewPostComposerTrigger
          channels={[channel.id]}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error - NewPostComposerTrigger has a more strict type for CTA, we keep the old CTA for now, just to keep consistency on the reports
          cta={SEGMENT_NAMES.PROFILE_SUGGESTION_SIDEBAR_NEW_POST}
        >
          <IconButton
            label="New post"
            variant="secondary"
            tooltip="New post"
            size="small"
            className={styles.newPostButton}
          >
            <PlusIcon />
          </IconButton>
        </NewPostComposerTrigger>
      )}
    </Sidebar.ListItem>
  )
}

function ChannelSuffix({
  channel,
}: {
  channel: ChannelNavChannel
}): JSX.Element {
  const organizationId = useOrganizationId() ?? ''

  const { counts } = usePostCounts({
    organizationId,
    channelIds: [channel.id],
  })

  const scheduledPostsCount = counts?.queue
  const sentPostsCount = counts?.sent
  const countLimit = counts?.limit

  if (channel.isDisconnected) {
    return (
      <Tooltip content="This channel is disconnected">
        <WarningIcon size="small" color="critical" />
      </Tooltip>
    )
  }

  if (channel.isLocked) {
    return (
      <Tooltip content="This channel is locked">
        <LockIcon size="small" />
      </Tooltip>
    )
  }

  if (requiresRefreshing(channel)) {
    return (
      <Tooltip content="This channel requires refreshing">
        <CriticalIcon size="small" color="critical" />
      </Tooltip>
    )
  }

  if (scheduledPostsCount === 0 && sentPostsCount === 0) {
    return <NewBadge size="medium" className={styles.newBadge} />
  }

  return (
    <span>
      {formatCompactNumber(scheduledPostsCount, countLimit)}
      <VisuallyHidden> scheduled posts</VisuallyHidden>
    </span>
  )
}

function useChannelPageRoute(id: string, { keepSearch = false } = {}): string {
  const params = useParams<
    { id: string; granularity: 'week' | 'month' } | { id: string }
  >()
  const currentSearch = parseQueryParams(useLocation().search)
  const currentRoute =
    'granularity' in params ? newCalendarSingleChannel : channelRoute

  const search: Record<string, QueryParamValue> = {}
  for (const { param, values: possibleValues } of currentRoute.queryParams) {
    const value = currentSearch[param]
    if (possibleValues && possibleValues.includes(value as any)) {
      search[param] = value
    } else if (!possibleValues && value) {
      search[param] = value
    }
  }

  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- We know params is of the proper type because we're getting the params from the current route
    currentRoute.buildPathname({ ...params, id } as any) +
    (keepSearch ? `?${serializeQueryParams(search)}` : '')
  )
}
