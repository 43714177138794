import {
  type AnyAction,
  type CombinedState,
  type EnhancedStore,
  type ThunkDispatch,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit'
import {
  type TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from 'react-redux'

import componentReducer from './componentReducer'
import channelConnectionsReducer from './channelConnectionsReducer'
import modalsReducer from './modalsReducer'
import type { OrchestratorRootState } from '../../../common/events/types'

export const storeReducers = combineReducers({
  components: componentReducer,
  channelConnections: channelConnectionsReducer,
  modals: modalsReducer,
})
const store = configureStore({
  reducer: storeReducers,
}) as EnhancedStore<OrchestratorRootState>

export type RootState = ReturnType<typeof store.getState>
export type AppStore = typeof store
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = (): ThunkDispatch<
  CombinedState<RootState>,
  undefined,
  AnyAction
> => useDispatch<AppDispatch>()

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default store
